import { Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CandidateService } from 'src/app/services/candidate.service';
import { AuthService } from 'src/app/services/auth.services';
import * as URL from './../../constants/url';
import { Input } from '@angular/core';
import {
  faPlus,
  faSearch,
  faSort,
  faEllipsisH,
  faColumns
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from 'src/app/services/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AssociateCandidateComponent } from 'src/app/features/associate-candidate/associate-candidate.component';
import { PositionService } from 'src/app/services/position.service';
import { ToastService } from '../toast/toast-service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-detail-header',
  templateUrl: './detail-header.component.html',
  styleUrls: ['./detail-header.component.css']
})
export class DetailHeaderComponent implements OnInit, OnChanges {
  @Input() clientsList: any = [];
  @Input() positionList = [];
  @Input() positionType: any;
  @Input() clientNumbers: any;
  @Input() navlink: any;
  @Input() UsersList: any;
  @Input() UsersCount: any;
  @Input() positionCount: any;
  @Input() clientCount: any;
  @Output() tabAction = new EventEmitter();
  @Output() availibilityAction = new EventEmitter();
  @Output() clientAction = new EventEmitter();
  @Output() userAction = new EventEmitter();
  @Output() searchText = new EventEmitter();
  current_tab: any = '';
  userRole: any;
  currentPosition: any;
  currentUserRoleCode: any;
  currentClient: any;
  logo: any;
  activeClientName: any;
  selectedCandidate: any;
  faPlus = faPlus;
  faSearch = faSearch;
  faEllipsisH = faEllipsisH;
  CurrentpageURL: any;
  query: any;
  AllTableData: any = [];
  draftedPositionList: any = [];
  allClientsData = [];
  showInactive: boolean = false;
  showActive: boolean = false;
  userspage: boolean = false;
  current_tab_cal: any = 1;
  selectPosition:any;
  loggeduserdata: any;
  constructor(private candidateService: CandidateService,
    private authService: AuthService,
    private modalService: NgbModal,
    private clientservice: ClientService,
    private router: Router,
    private positionService: PositionService,
    private toastService: ToastService,
    private userService: UserService,
    private activatedRoute : ActivatedRoute
  ) { }

  ngOnInit(): void {
    let userdata: any = localStorage.getItem('currentUser');
    let clientData: any = localStorage.getItem('clientData');
    clientData = JSON.parse(clientData);
    this.loggeduserdata = JSON.parse(userdata);
    if (userdata) {
      this.userRole = this.loggeduserdata?.role;
    }
    this.currentPosition = localStorage.getItem('currentPosition');
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    this.currentClient = localStorage.getItem('clientId');
    this.CurrentpageURL = window.location.href;
    console.log(this.CurrentpageURL)
    this.userspage = (this.CurrentpageURL.indexOf('view-all-users')) > -1 && (this.currentUserRoleCode == '/ca' || this.currentUserRoleCode == '/sm' || this.currentUserRoleCode == '/htm');
    this.activeClientName = clientData.client_name;
    this.logo = clientData.client_logo;
    const openPositionId =  this.activatedRoute.snapshot.params['positionId'];
    this.getPositionHeaderDetails(openPositionId)
    
    // this.candidateService.getSelectedCandidate().subscribe((val: any) => {
    //   if (val.id) {
    //     // this.getPositionsbyID(val.id)
    //     if (val.logo && val.logo !== 'None' && val.logo !== 'null') {
    //       this.logo = val.logo;
    //     } else {
    //       this.logo = URL.imgBaseUrl + './../../../assets/images/no-image.jpg';
    //     }
    //     this.activeClientName = val.company_name;
    //     this.selectedCandidate = val?.open_position_data?.filter((el: any) => el?.id == this.currentPosition)[0];
    //   }
    // });
  }
  getPositionHeaderDetails(openPositionId:any){
    if(openPositionId){
      this.selectPosition = {
        'position_title':'',
        'position_filled':'',
        'no_of_open_positions':'',
        'target_deadline':'',
        'deadline':''
      }
      this.positionService.getPositionDataByPositionId(openPositionId).subscribe((val:any)=>{
        this.selectPosition.position_title = val.data?.position_title
        this.selectPosition.position_filled = val.data?.position_filled
        this.selectPosition.no_of_open_positions = val.data?.no_of_open_positions
        this.selectPosition.target_deadline = val.data?.target_deadline
        this.selectPosition.deadline = val.data?.deadline
        this.activeClientName =  val.data?.client_name;
        this.logo =  val.data?.client_logo;
      })
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.navlink?.currentValue == 'Insights') {
      const openPositionId =  this.activatedRoute.snapshot.params['positionId'];
      this.router.navigate([this.authService.currentUserRoleCode + '/position-details/'+ openPositionId]);
      this.current_tab = 'Insights'
    }
    if (changes?.navlink?.currentValue == 'Calendar'){
        this.current_tab = 'Calendar'
    }
    if (changes?.navlink?.currentValue == 'progress-summary'){
      this.current_tab = 'progress-summary'
  }
  }
  searchInput(e: any) {
    this.searchText.emit(e.target.value);
  }
  goToInvite() {
    this.userAction.emit();
    this.router.navigate([this.authService.currentUserRoleCode + '/view-all-users/invite-users']);
  }
  goToAllUsers() {
    this.router.navigate([this.authService.currentUserRoleCode + '/view-all-users']);
  }
  changeTab(str: any) {
    this.current_tab = ''
    if (str == 'progress-summary' || str == 'Insights' || str == 'Calendar') {
      const openPositionId = this.activatedRoute.snapshot.params['positionId'];
      this.router.navigate([this.authService.currentUserRoleCode + '/position-details/'+ openPositionId]);
      this.candidateService.setpositionPageSection(str)
      
    }
    if (str == 'add-candidate') {
      const openPositionId = this.activatedRoute.snapshot.params['positionId'];
      this.router.navigate([this.authService.currentUserRoleCode + '/add-new-candidate/'+openPositionId]);
    }
    this.current_tab = str
    this.tabAction.emit(str);
  }
  addAvailability(num: any) {
    this.current_tab_cal = num
    this.availibilityAction.emit(num);
  }

  openSm(content: any) {
    this.modalService.open(content, { size: 'md' });
  }
  analyseData() {
    this.showActive = false;
    this.showInactive = false;
    for (let i = 0; i < this.clientsList.length; i++) {
      if (this.clientsList[i].status == 'inactive') {
        this.showActive = true;
      }
      if (this.clientsList[i].status == 'active') {
        this.showInactive = true;
      }
    }
  }
  deleteClient() {
    if (this.clientsList.length > 0) {
      let clObj: any = { 'clients': [] };
      for (let i = 0; i < this.clientsList.length; i++) {
        clObj.clients.push(this.clientsList[i].id)
      }
      this.clientservice
        .deleteClientMethod(clObj)
        .subscribe((client: any) => {
          if (client) {
            this.clientsList = [];
            this.modalService.dismissAll();
            this.clientAction.emit()
            alert('Client deleted successfully');

          }
        });
    }
  }
  editClient() {
    if (this.clientsList.length > 0) {
      localStorage.setItem('ediClientId', this.clientsList[0].id);
      this.router.navigate([this.authService.currentUserRoleCode + '/dashboard/addClient']);
    }
  }
  suspendClient() {
    if (this.clientsList.length > 0) {
      let clObj: any = { 'clients': [] };
      for (let i = 0; i < this.clientsList.length; i++) {
        clObj.clients.push(this.clientsList[i].id)
      }
      this.clientservice.suspendClientMethod(clObj).subscribe((client: any) => {
        if (client) {
          this.clientsList = [];
          this.clientAction.emit()
          alert('Client suspended successfully');
          // window.location.reload();

        }
      });
    }
  }
  activateClient() {
    if (this.clientsList.length > 0) {
      let clObj: any = { 'clients': [] };
      for (let i = 0; i < this.clientsList.length; i++) {
        clObj.clients.push(this.clientsList[i].id)
      }

      this.clientservice.activateClientMethod(clObj).subscribe((client: any) => {
        if (client) {
          this.clientsList = [];
          this.clientAction.emit()
          alert('Client activated successfully');

          // window.location.reload();
        }
      });
    }
  }
  editPosition() {
    if (this.positionList.length == 1) {
      localStorage.setItem('editPosition', this.positionList[0]);
      //  setTimeout(() => {this.router.navigate([this.authService.currentUserRoleCode + '/new-position']);}, 2000);
      this.router.navigate([this.authService.currentUserRoleCode + '/new-position']);
    }
  }
  clonePosition() {
    if (this.positionList.length == 1) {
      // console.log(this.positionList[0])
      this.positionService.clonePosition(this.positionList[0]).subscribe(res => {
        if (res) {
          this.showSuccess('Position cloned successfully');
          window.location.reload();
        }
      })
    }
  }
  editMulPositions(str: any) {
    let ob: any = {
      ids: this.positionList,
      status: str
    }
    this.positionService.editMultiplePositions(ob).subscribe((res: any) => {
      this.showSuccess('Positions updated successfully');
      window.location.reload();
    })
  }
  archivePosition() {
    const openPositionId = localStorage.getItem('currentPosition');
    this.positionService.archivePosition(openPositionId).subscribe(res => {
      this.showSuccess('Position Archived successfully.');
      this.router.navigate([this.authService.currentUserRoleCode + '/view-all-positions']);
    });
  }
  editPositionSummary() {
    const openPositionId: any = localStorage.getItem('currentPosition');
    localStorage.setItem('editPosition', openPositionId);
    this.router.navigate([this.authService.currentUserRoleCode + '/new-position']);
  }
  draftPosition() {

  }
  completePosition() {

  }
  trashPosition() {
    this.positionService.trashPosition(this.currentPosition, true).subscribe((client: any) => {
      if (client) {
        this.showSuccess('Position trashed successfully.');
        // this.modalService.dismissAll();
        client.isDelete = true;
        this.positionService.setIsPositionUpdated(client);
        this.router.navigate([this.authService.currentUserRoleCode + '/view-all-positions']);
      }
    });

  }
  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }
  emitAction(item: any) {

  }
  isActive(item: any) {

  }

  openAssociateCandidate() {
    const modalRef = this.modalService.open(AssociateCandidateComponent, { size: 'xl' });
    modalRef.componentInstance.name = 'associate';
  }
  deleteUser() {
    let ids: any = [];
    this.UsersList.forEach((item, index, arr) => {
      ids.push(item.email)
    })
    ids = ids.toString();
    this.userService.deleteUsers(ids).subscribe((res: any) => {
      if (res) {
        this.showSuccess('User Deleted Succesfully')
        setTimeout(() => {
          window.location.reload()
        }, 2000);
      }
    })
  }
  checkCurrentUser():boolean{
    for (let i=0;i<this.UsersList?.length;i++){
      if(this.UsersList[i]?.email == this.loggeduserdata?.email)
        return false;
    }
    return true;
  }
}
