import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import {
  faLock,
  faPen,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastService } from 'src/app/core/toast/toast-service';
import { PositionService } from 'src/app/services/position.service';

import { AssociateCandidateComponent } from '../associate-candidate/associate-candidate.component';
import { SetAvailabilityComponent } from '../set-availability/set-availability.component';
import { HiringTeamService } from '../../services/hiringteam.service';
import * as Url from './../../constants/url';
import { AuthService } from 'src/app/services/auth.services';
import { UserService } from 'src/app/services/user.service';
import { CanComponentDeactivate } from 'src/app/core/_guards/form.guard';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-create-qorums-support',
  templateUrl: './create-qorums-support.component.html',
  styleUrls: ['./create-qorums-support.component.css'],
})
export class CreateQorumsSupportComponent implements OnInit,CanComponentDeactivate {
  accountForm!: FormGroup;
  initialFormValue:any;
  accountList: any[] = [];
  public userRole: string = '';
  public faTrash = faTrash;
  public faEdit = faPen;
  public currentAccount: string = '';
  public showUpdateBtn: boolean = false;
  public faPlus = faPlus;
  public faCalendar = faCalendarAlt;  
  public faLock = faLock;
  public passwordForm!: FormGroup;
  showError: boolean = false;
  userName: string = '';
  public imageURL: string = '';
  public imageBaseUrl: any;
  public associatedClients: any;
  @ViewChild('fileInput') fileInput: ElementRef = {} as ElementRef;
  formHasErrors: boolean = false;
;
  currentUserRoleCode: any;
  showPassword :boolean= false;
  isUppercaseValid: boolean = false;
  isLowecaseValid: boolean = false;
  isNumberValid : boolean= false;
  isSymbolValid: boolean = false;
  showProfilePhoto : boolean =true;

  constructor(
    private fb: FormBuilder,
    private positionService: PositionService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private hiringTeamService: HiringTeamService,
    private authService: AuthService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    this.imageBaseUrl = Url.imgBaseUrl;
    let uerdata: any = localStorage.getItem('currentUser');
    uerdata = JSON.parse(uerdata);
    if (uerdata) {
      this.userRole = uerdata?.role;
    }
    this.createForm();
    this.createpForm();
    this.getAllAccount();
  }  

  createpForm() {
    this.passwordForm = this.fb.group({
      username:[''],
      new_password:['', Validators.required],
      re_password:['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[\/~`!@#$%^&*()_+\-=\[\]{}|\\:;"'<>,.?\//])(?!.*\s)/),
      Validators.minLength(8),Validators.maxLength(16)]],
    })
  }
  openforPassword(password: any, username: any) {
    this.userName = username;    
    this.modalService.open(password, { size: 'md' });
  }

  changePassword() {
    // Encrypt Password
    this.passwordForm.controls.new_password.setValue(this.userService.encryptPassword(this.passwordForm.value.new_password));
    
    let payload = this.passwordForm.value;    
    payload['username'] = this.userName;
    delete payload['re_password'];
    this.hiringTeamService.changePassword(payload).subscribe(value => {
      if (value) { 
        this.showSuccess('Password changed Successfully');
        this.modalService.dismissAll();
        this.passwordForm.reset();
      } else {
        this.showDanger('Change Password failed, please try again.');
      }
    })
  }

  checkPasswords() { 
    let pass = this.passwordForm.controls['new_password'].value;
    let confirmPass = this.passwordForm.controls['re_password'].value
    if(pass === confirmPass) {
      this.showError = false;
    } else {
      this.showError = true;
    }
    
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if(this.isFormChanged()){
      return confirm('You have unsaved changes. Do you really want to leave?');
    }
    return true;
  }
  isFormChanged(): boolean {
    return JSON.stringify(this.accountForm.value) !== JSON.stringify(this.initialFormValue);
  }

  createForm() {
    this.accountForm = this.fb.group({
      first_name: ['', [Validators.required,Validators.pattern('[a-zA-Z]*$')]],
      last_name: ['', [Validators.required,Validators.pattern('[a-zA-Z]*$')]],
      phone_number: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
      skype_id: [''],
      email: ['',[Validators.required, Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$')],
      ],
      username: ['', Validators.required],
      /*validation for password*/
      password: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[\/~`!@#$%^&*()_+\-=\[\]{}|\\:;"'<>,.?\//])(?!.*\s)/),
      Validators.minLength(8),Validators.maxLength(16)]],
      profile_photo: [null],
      profile_photo_deleted: [false]
    });
    this.initialFormValue=this.accountForm.value;
  }

  getAllAccount() {
    this.positionService.getAllAccounts().subscribe((val: any) => {
      if (val) {
        this.accountList = val;
      }
    });
  }

  Save() {
    if(this.accountForm.invalid){
      alert("Please fill all the required fields")
      this.formHasErrors = true;
      return;
    }
    this.formHasErrors = false;
    // Encrypt Password
    this.accountForm.controls.password.setValue(this.userService.encryptPassword(this.accountForm.value.password));

    this.positionService
      .createAccount(this.accountForm.value)
      .subscribe((data) => {
        if (data) {
          this.accountForm?.reset();
          this.showProfilePhoto =  true;
          this.showSuccess('Account created Successfully');
          this.getAllAccount();
          window.location.reload();
        } else {
          this.showDanger('Account failed, please try again.');
        }
      });
  }

  editAccount(username: string) {
    this.positionService.getAccountById(username).subscribe((val: any) => {
      if(val){
        this.showUpdateBtn = true;
        this.accountForm.controls['first_name'].setValue(val?.first_name);
        this.accountForm.controls['last_name'].setValue(val?.last_name);
        this.accountForm.controls['phone_number'].setValue(val?.phone_number);
        this.accountForm.controls['email'].setValue(val?.email);
        this.accountForm.controls['skype_id'].setValue(val?.skype_id);
        this.accountForm.controls['username'].setValue(val?.username);
        this.accountForm.removeControl('password');
        this.accountForm.controls['profile_photo'].setValue(val?.profile_photo !== 'None' ? val?.profile_photo : null);
        this.imageURL = val?.profile_photo && val?.profile_photo !== 'None' ? Url.imgBaseUrl + val?.profile_photo : '';
        this.associatedClients = val?.clients;
        // this.accountForm.get("password")?.clearValidators();
        // this.accountForm.get("password")?.updateValueAndValidity();
        this.initialFormValue=this.accountForm.value;
        if(val?.profile_photo && val?.profile_photo !== 'None'){
          this.showProfilePhoto = false;
        }
        else
          this.showProfilePhoto = true;
      }      
    });
  }

  editselAccount() {
    if(this.accountForm.invalid){
      alert("Please fill all the required fields")
      this.formHasErrors = true;
      return;
    }
    this.formHasErrors = false;
    this.positionService
      .updateAccount(this.accountForm.value)
      .subscribe((data) => {
        if (data) {
          this.accountForm.reset();
          this.imageURL = '';
          this.showProfilePhoto =true;
          this.showUpdateBtn = false;
          this.accountForm.controls.profile_photo.patchValue(null);
          this.showSuccess('Account update Successfully');
          this.ngOnInit();
          window.location.reload();
          this.fileInput.nativeElement.value = "";
        } else {
          this.showDanger('update account failed, please try again.');
        }
      });
  }

  deleteCandidate() {
    this.positionService.deleteAccount(this.currentAccount).subscribe((val:any) => {
      if(val){
        this.showSuccess('Account deleted successfully');
        this.modalService.dismissAll();
        this.getAllAccount();
      } else {
        this.showDanger('Delete account failed');
      }
    })
  }

  openSm(content: any, username: string) {
    this.currentAccount = username;
    this.modalService.open(content, { size: 'sm' });
  }

  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

  openAssociateCandidate() {
    const modalRef = this.modalService.open(AssociateCandidateComponent);
    modalRef.componentInstance.name = 'associate';
  }

  openSetAvailability() {
    const modalRef = this.modalService.open(SetAvailabilityComponent, { size: 'lg' });
    modalRef.componentInstance.name = 'scheduler';
  }

  showPreview(event: any) {
    const reader = new FileReader();
    
    if(event.target.files && event.target.files.length) {
      // this.accountService.leavePage = true;
      const [file] = event.target.files;
      this.accountForm.controls['profile_photo_deleted'].setValue(false);
      this.accountForm.controls['profile_photo'].setValue(event.target.files[0]);
      reader.readAsDataURL(file);
      this.accountForm.controls.profile_photo.patchValue(file);
      reader.onload = () => {
   
        this.imageURL = reader.result as string;
   
      };
      this.showProfilePhoto = false;
    }
  }

  deleteProfilePic(element: any) {
    this.accountForm.controls['profile_photo_deleted'].setValue(true);
    //element.value = '';
    this.accountForm.controls['profile_photo'].setValue(null);
    this.imageURL = '';
    this.showProfilePhoto = true;
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  
  onPasswordChange() {
    const enteredPassword = this.accountForm.controls.password.value || this.passwordForm.controls.password.value;
    if (enteredPassword) {
      this.isUppercaseValid = /[A-Z]/.test(enteredPassword);
      this.isLowecaseValid = /[a-z]/.test(enteredPassword);
      this.isNumberValid = /\d/.test(enteredPassword);
      // this.isSymbolValid = /(?=.*?[^0-9A-Za-z])/.test(enteredPassword);
      this.isSymbolValid = /^(?=.*[A-Z])(?=.*[\/~`!@#$%^&*()_+\-=\[\]{}|\\:;"'<>,.?\//])(?!.*\s)/.test(enteredPassword);
    } else {
      this.isUppercaseValid = false;
      this.isLowecaseValid = false;
      this.isNumberValid = false;
      this.isSymbolValid = false;
    }
  }
}
