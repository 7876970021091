import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-macros',
  templateUrl: './macros.component.html',
  styleUrls: ['./macros.component.css']
})
export class MacrosComponent implements OnInit {
  copyStatus='Copied to clipboard';
macros = [
  {name:'{{user_name}}', title:'Name of the user to whom mail is sent', copied:false},
  {name:'{{position_title}}', title:'Name of the position', copied: false},
  {name:'{{candidate_name}}', title:'Name of the candidate', copied: false},
  {name:'{{username}}', title:'Login username', copied: false},
  {name:'{{password}}', title:'Password', copied: false},
  {name:'{{company}}', title:'Name of the company', copied: false},
  {name:'{{link}}', title:'Link of something mostly of Interview', copied: false},
  {name:'{{manager}}', title:'Name of the Client HR/Hiring Manager', copied: false},
  {name:'{{manager_contact}}', title:'Contact of the Client HR/Hiring Manager', copied: false},
  {name:'{{manager_email}}', title:'Email of the Client HR/Hiring Manager', copied: false},
  {name:'{{htm_name}}', title:'Name of the Interviewer', copied: false},
  {name:'{{hiring_team_name}}', title:'Hiring Group name', copied: false},
  {name:'{{senior_manager_name}}', title:'Senior Manager Name', copied: false}
];
  constructor() { }

  ngOnInit(): void {
  }

  copyToClipboard(text: string) {
    this.macros.map((macro) => macro.copied = false)
    navigator.clipboard.writeText(text).then(
      () => {
        console.log(`Copied - ${text}`);
        this.macros.map((macro) => {
          if(macro.name === text) {
            macro.copied = true;
            setTimeout(() => {
              macro.copied = false;
            }, 2000);
          }
        }
        );
      },
      (err) => {
        console.error('Async: Could not copy text: ', err);
      }
    );
  }

}

// MACROS Used:


                                    
// {{user_name}}
//  - Name of the user to whom mail is sent
                                    
// {{position_title}}
//  - Name of the position
                                    
// {{candidate_name}}
//  - Name of the candidate
                                    
// {{username}}
//  - Login username
                                    
// {{password}}
//  - Password
                                    
// {{company}}
//  - Name of the company
                                    
// {{link}}
//  - Link of something mostly of Interview
                                    
// {{manager}}
//  - Name of the Client HR/Hiring Manager
                                    
// {{manager_contact}}
//  - Contact of the Client HR/Hiring Manager
                                    
// {{manager_email}}
//  - Email of the Client HR/Hiring Manager
                                    
// {{htm_name}}
//  - Name of the Interviewer
                                    
// {{hiring_team_name}}
//  - Hiring Group name
                                    
// {{senior_manager_name}}
//  - Senior Manager Name
