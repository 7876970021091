import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})

export class SearchFilterPipe implements PipeTransform {

  transform(value: any, args?: any, args1?: any): any {
      if(!value)return null;
      if(!args)return value;

      args = args.toLowerCase();

      return value.filter(function(data:any){
        if(args1){
          return JSON.stringify(data[args1]).toLowerCase().indexOf(args.toLowerCase()) >= 0;
        } else {
          return JSON.stringify(data).toLowerCase().indexOf(args.toLowerCase()) >= 0;
        }
      });
  }

}