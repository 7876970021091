import { environment } from 'src/environments/environment';

// Staging - Copy ( For Training Purposes )
// export const baseUrl = 'http://18.219.86.184/api/';
// export const imgBaseUrl = 'http://18.219.86.184/api/';
// export const uiBaseUrl = 'http://18.219.86.184/';
// export const uiBaseUrl = 'http://18.219.86.184/'; 

//old staging
// export const baseUrl = 'http://3.142.223.181/api/';
// export const imgBaseUrl = '';
// // export const imgBaseUrl = 'http://3.142.223.181/api/';
// export const uiBaseUrl = 'http://3.142.223.181/';
// export const uiBaseUrl = 'http://localhost:4200/'; 

// Staging IPs
// export const baseUrl = 'https://io.qorums.ai/api/';
// export const imgBaseUrl = '';
// export const uiBaseUrl = 'https://io.qorums.ai/';
// export const CLIENT_ID = 'flVWwohfE5jsVwsjJk9F4O1GbCw7LZpz';

// Production - App pwd:  ATBBMqdtWyBDJY9VgEdLM94jes4V185EA4F7
// export const baseUrl = 'https://app.qorums.com/api/';
// export const imgBaseUrl = '';
// export const uiBaseUrl = 'https://app.qorums.com/';
// export const CLIENT_ID = 'y4Jvd6uJ2rYXcsVQacSvtzPUufue5Shr';
//Staging URLS
// export const baseUrl = 'https://io.qorums.ai/api/';
// export const imgBaseUrl = '';
// export const uiBaseUrl = 'https://io.qorums.ai/';
// export const CLIENT_ID = 'flVWwohfE5jsVwsjJk9F4O1GbCw7LZpz'; //Client_id for cronofy calendar staging
//staging ends

// Production
export const baseUrl = `${environment.apiUrl}api/`;
export const imgBaseUrl = '';
export const uiBaseUrl = environment.apiUrl;
export const CLIENT_ID = environment.croCLId; //Client_id for cronofy calendar

//Prod ends

export const loginUrl = baseUrl + 'dashboard-api/login';
export const cognitoLoginUrl = baseUrl + 'dashboard-api/cognito-login';
export const termconditionUrl = baseUrl + 'dashboard-api/updated-tnc-status';
// export const AMListUrl = baseUrl + 'dashboard-api/all-account-managers';
// export const hiringGroupUrl = baseUrl + 'dashboard-api/get-hiring-group';
// export const candidateUrl = baseUrl + 'dashboard-api/cadidate-data';
// export const updateCandidateBackground = baseUrl + 'dashboard-api/update-candidate-details';
export const getCandidateById = baseUrl + 'dashboard-api/candidate-basic-details';
// export const getCandidateSummaryId = baseUrl + 'dashboard-api/get-candidate-summary';
// export const positionTitleUrl = baseUrl + 'dashboard-api/position-list';
export const getDataFromJD = baseUrl + 'openposition/get-position-info-from-jd';
export const getSingleDataFromJD = baseUrl + 'openposition/get-single-skillset';
// export const positionSkillsetUrl = baseUrl + 'dashboard-api/qualifying-questions-list';
export const gethiringTeamListUrl = baseUrl + 'dashboard-api/hiring-group'; //this
// export const getHiringTeamDetailUrl = baseUrl + 'dashboard-api/get-hiring-group';
// export const getHiringManagerDetailUrl = baseUrl + 'dashboard-api/hiring-manager';
// export const getDepartByClientUrl = baseUrl + 'dashboard-api/get-departments';
// export const departmentUrl = baseUrl + 'dashboard-api/departments';
// export const getDepartmentByIdUrl = baseUrl + 'dashboard-api/get-department-by-id';
export const getCandidateScoreUrl = baseUrl + 'dashboard-api/get-thumbs-by-hm';
export const getAllHiringMemberUrl = baseUrl + 'dashboard-api/all-hiring-manager';
export const HiringMemberUrl = baseUrl + 'dashboard-api/hiring-manager';
export const HiringMemberData = baseUrl + 'dashboard-api/hiring-members';
export const getHiringMembersUrl = baseUrl + 'dashboard-api/get-manager-and-member';
export const sentMailUrl = baseUrl + 'dashboard-api/send-mail';
export const hmUrl = baseUrl + 'dashboard-api/change-candidate-status';
export const accountUrl = baseUrl + 'dashboard-api/account-manager';
export const getAllAccountUrl = baseUrl + 'dashboard-api/all-account-managers';
export const positiontoAssociateUrl = baseUrl + 'dashboard-api/get-candidate-to-associate';
export const associateCandidate = baseUrl + 'dashboard-api/associate-candidate';
export const setAvailability = baseUrl + 'dashboard-api/employee-schedule';
export const changepasswordUrl = baseUrl + 'dashboard-api/change-password';
export const duplicateOpenPosition = baseUrl + 'dashboard-api/duplicate-op';
export const getNotification = baseUrl + 'dashboard-api/get-notification';
export const setNotification = baseUrl + 'dashboard-api/save-response';
export const getDashboardData = baseUrl + 'dashboard-api/get-dashboard-data-view';
export const getClientAdminDashboard = baseUrl + 'dashboard-api/client-admin-dashboard';
export const withdrawHiringMember = baseUrl + 'dashboard-api/withdraw-hiring-member';
export const restoreHiringMember = baseUrl + 'dashboard-api/restore-hiring-member';
export const getAllScheduleTemplate = baseUrl + 'dashboard-api/all-schedule-template';
export const resumeParser = baseUrl + 'openposition/get-candidate-from-resume';
export const getScheduleTemplateById = baseUrl + 'dashboard-api/schedule-template';
export const htmWeigtage = baseUrl + 'dashboard-api/htm-weightage';
export const getHtmCalendar = baseUrl + 'dashboard-api/get-htm-calendar';
export const generateMeetingUrl = baseUrl + 'dashboard-api/generate-meeting';
export const sendEmailToHtm = baseUrl + 'dashboard-api/send-email-to-htm';
export const archiveOpenPosition = baseUrl + 'dashboard-api/archieve-op';
export const cloneOpenPosition = baseUrl + 'openposition/clone-position';
export const scheduleTemplate = baseUrl + 'dashboard-api/schedule-template';
export const appNotifications = baseUrl + 'notification-api/app-notification';
export const websocket = 'wss://app.qorums.com/ws/ac';
export const selectedAnalytics = baseUrl + 'dashboard-api/selected-analytics';
export const getSeniorManagers = baseUrl + 'dashboard-api/all-senior-managers';
export const seniorManagers = baseUrl + 'dashboard-api/senior-managers';
export const getUserProfile = baseUrl + 'dashboard-api/user-profile';
export const protip = baseUrl + 'dashboard-api/pro-tip';
export const selectHiringGroup = baseUrl + 'dashboard-api/select-hiring-group';
export const getQorumsDashboard = baseUrl + 'dashboard-api/qorums-dashboard';
// export const getLinkedinData = baseUrl + 'dashboard-api/get-linkedin-data';
export const getZohoMeeting = baseUrl + 'dashboard-api/get-zoho-code';
export const offerCandidate = baseUrl + 'dashboard-api/offer-candidate';
export const getCandidateInterviewsList = baseUrl + 'dashboard-api/get-interviews-list';
export const getAvailableOpenPositionsForCandidate = baseUrl + 'dashboard-api/get-position-to-associate';
export const updateInterviewResponse = baseUrl + 'dashboard-api/update-interview-response';
export const getIntervieweSlots = baseUrl + 'dashboard-api/get-interviewer-slots';
export const candidateSchedule = baseUrl + 'dashboard-api/candidate-schedule';
export const setCandidateAvailability = baseUrl + 'dashboard-api/candidate-schedule';
export const getCandidateCalendar = baseUrl + 'dashboard-api/get-candidate-calendar';
export const getClientSummary = baseUrl + 'dashboard-api/get-client-summary';
export const proMarketSendMail = baseUrl + 'dashboard-api/pro-marketting/send-mail';
export const detachCandidateFromOpenPosition = baseUrl + 'dashboard-api/delete-candidate';
export const getCaSmHmForOpenPosition = baseUrl + 'dashboard-api/get-all-members';
export const compareCandidateVoting = baseUrl + 'dashboard-api/compare-candidate';
export const getEvaluateCommentByPosition = baseUrl + 'dashboard-api/evalate-comments';
export const candidateResetPwd = baseUrl + 'dashboard-api/candidate-reset-password';
export const endMeeting = baseUrl + 'dashboard-api/end-meeting';
export const requestPasswordReset = baseUrl + 'dashboard-api/request-password-reset';
export const resetPassword = baseUrl + 'dashboard-api/forgot-password';
export const updateSortedData = baseUrl + 'openposition/table-sort';
export const getTempPositionSummary = baseUrl + 'dashboard-api/get-temp-position-summary';
export const updateDarkMode = baseUrl + 'dashboard-api/update-dark-mode';
export const getArchivedPosition = baseUrl + 'dashboard-api/get-archived-position';
export const getFitAnalysis = baseUrl + 'dashboard-api/get-fit-analysis';
export const getClientDashboard = baseUrl + 'dashboard-api/get-client-dashboard'
export const associateCandidateToOpenPosition = baseUrl + 'dashboard-api/request-position-association';
export const createMultiroleUser = baseUrl + 'dashboard-api/create-multirole-user';
export const getAllUsers = baseUrl + 'dashboard-api/get-all-users';
export const getHtmAudit = baseUrl + 'dashboard-api/get-htm-audit';
export const getSingleHtmAudit = baseUrl + 'dashboard-api/get-single-htm-audit';
export const getCandidateAssociateData = baseUrl + 'dashboard-api/get-candidate-associate-data';
export const fetchProfileImage = baseUrl + 'dashboard-api/fetch-profile-image';
export const allEmailTemplate = baseUrl + 'dashboard-api/all-email-template';
export const emailTemplate = baseUrl + 'dashboard-api/email-template';
export const getHtmAvailabilities = baseUrl + 'dashboard-api/get-htm-availabilities';
export const changeUserRole = baseUrl + 'dashboard-api/change-user-role';
export const trashOpenPosition = baseUrl + 'dashboard-api/trash-open-position';
export const listAllPackages = baseUrl + 'dashboard-api/list-packages';
export const getCurrentHtmData = baseUrl + 'dashboard-api/get-current-htm-data';
export const updatePackages = baseUrl + 'dashboard-api/packages';
export const packageInfo = baseUrl + 'dashboard-api/client-package';
export const extraPackageInfo = baseUrl + 'dashboard-api/extra-accounts';
export const generateClientOTP = baseUrl + 'dashboard-api/generate-otp';
export const billingInfo = baseUrl + 'dashboard-api/user-billing';
export const clientList = baseUrl + 'dashboard-api/list-clients';
export const calAddAmount = baseUrl + 'dashboard-api/calculate-additional-amt';
export const confPayment = baseUrl + 'dashboard-api/confirm-payment';
export const invoiceDetails = baseUrl + 'dashboard-api/invoice-list';
export const printInvoice = baseUrl + 'dashboard-api/generate-invoice';
export const candLogInfo = baseUrl + 'dashboard-api/reset-candidate-creds';
export const signInClient = baseUrl + 'dashboard-api/signup-client';
export const initialPayment = baseUrl + 'dashboard-api/initial-payment';
export const getQuestionsBySkill = baseUrl + 'dashboard-api/get-multiple-questions';
export const getQuestionBySkill = baseUrl + 'dashboard-api/get-single-question';
export const allPositions = baseUrl + 'dashboard-api/get-all-position';
export const getSkillsetsUrl = baseUrl + 'openposition/get-skillsets';
export const allUsersList = baseUrl + 'dashboard-api/users-list';
export const inviteUsers = baseUrl + 'dashboard-api/invite-user';
export const getInvitedUser = baseUrl + 'dashboard-api/get-invited-user';
export const getSignupUserData = baseUrl + 'dashboard-api/get-signup-data';
export const signupUserOne = baseUrl + 'dashboard-api/signup-user-s1';
export const signupUserTwo = baseUrl + 'dashboard-api/signup-user-s2';
export const signupUserThree = baseUrl + 'dashboard-api/signup-user-s3';
export const deleteUsers = baseUrl + 'dashboard-api/delete-users';
export const remindUsers = baseUrl + 'dashboard-api/re-invite-user';
//New
export const allClients = baseUrl + 'clients/get-all-client';
export const createClientUrl = baseUrl + 'clients/client-data';
export const deleteClientUrl = baseUrl + 'clients/client-data';
export const createPositionUrl = baseUrl + 'openposition/open-position';
export const getPositionByIdUrl = baseUrl + 'openposition/get-open-positions';
export const getFilteredPosbyIdURL = baseUrl + 'openposition/get-limited-opdata'
export const getPositionSummary = baseUrl + 'openposition/get-position-summary';
export const selectedClient = baseUrl + 'clients/get-client-positions';
export const deleteClient = baseUrl + 'clients/delete-clients';
export const suspendClient = baseUrl + 'clients/suspend-clients';
export const activateClient = baseUrl + 'clients/activate-clients';
export const companyInfo = baseUrl + 'clients/basic-client-details';
export const positionsCandidateUrl = baseUrl + 'openposition/all-candidate-feedback';
export const getPositionData = baseUrl + 'openposition/get-all-opdata';
export const autoSavePosition = baseUrl + 'openposition/temp-candidate-marks';
export const advanceSearchUrl = baseUrl + 'candidates/search-candidates';
export const allcandidateUrl = baseUrl + 'candidates/get-all-candidates';
export const getSubmitcandidateURL = baseUrl + 'candidates/get-candidate-for-submission';
export const getSubmitcandidateDataURL = baseUrl + 'openposition/get-submitted-candidates';
export const postSubmitcandidateDataURL = baseUrl + 'openposition/submit-candidate';
export const MailFireCancelWithOutSignupPath = baseUrl + 'dashboard-api/send-abandoned-mail'
export const getScheduleCode = baseUrl + 'schedules/get-auth-code'
export const getCronofyCalender = baseUrl + 'schedules/list-calendars'
export const getCronofyElementToken = baseUrl + 'schedules/get-element-token'
export const getAccessConfirmation = baseUrl + 'schedules/check-access-token'
export const getUserCalendar = baseUrl + 'schedules/get-user-calendar'
export const getOpenPositionCalendar = baseUrl + 'schedules/get-openposition-schedules'
export const sendInterviewEmail = baseUrl + 'schedules/set-interviews'
export const getCandidateActions = baseUrl + 'candidates/get-candidate-actions'
export const getRatingUrl = baseUrl + 'openposition/single-candidate-marks';
export const getPositionsForCalendar = baseUrl + 'schedules/list-calendar-position'
export const postRatingUrl = baseUrl + 'openposition/single-candidate-marks';
export const getInterviewHTMDetails = baseUrl + 'openposition/get-interview-details';
export const postInterviewStatus = baseUrl + 'openposition/mark-interview-complete';
export const withdrawUrl = baseUrl + 'openposition/withdraw-candidate';
export const unWithdrawUrl = baseUrl + 'openposition/unwithdraw-candidate';
export const hireCandidate = baseUrl + 'openposition/hire-candidate';
export const editMultiplePositions = baseUrl + 'openposition/edit-positions';
export const listNavigators = baseUrl + 'dashboard-api/list-navigator';
export const postNavigators = baseUrl + 'dashboard-api/navigator';
export const getNavigatorStep = baseUrl + 'dashboard-api/user-navigator';
export const updateNavMode = baseUrl + 'dashboard-api/update-navigator-mode';
export const qBrainComments = baseUrl + 'openposition/get-qsummary'
export const candidatePositionData = baseUrl + 'candidates/get-candidate-active-positions'
export const candidateScheduleCalendar = baseUrl + 'schedules/get-candidate-schedules'
export const updateCandidateBg = baseUrl + 'candidates/update-candidate';
export const upcomingHtmInterviews = baseUrl + 'schedules/get-team-member-interviews'
export const positionPrivacyData = baseUrl + 'openposition/public-fields'
export const updateJobPosting = baseUrl + 'openposition/toggle-job-position'
export const JobBoardPositions = baseUrl + 'job-board/open-positions'
export const JobBoardTitles = baseUrl + 'job-board/job-titles'