import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as urls from './../constants/url';
import { UserData } from './../models/auth.interface';
import * as CryptoJS from 'crypto-js';

@Injectable({ providedIn: 'root' })
export class UserService {
    private userLoginData = new BehaviorSubject<boolean>(true);
    private userDetailsUpdated = new Subject();
    private proTipUpdated = new BehaviorSubject<any>(null);
    private userAnalyticsData = new BehaviorSubject<any>(null);
    private encryptionKey = '+KbPeShVmYq3t6w9z$C&F)J@McQfTjWn';
    private tabCloseSubject = new Subject<void>();


    // getTabCloseObservable(): Observable<void> {
    //     return this.tabCloseSubject.asObservable();
    //   }
    // public url: string;
    // public uri = environment.localurl;
    constructor(private http: HttpClient) {
        window.addEventListener('beforeunload', () => {
            this.tabCloseSubject.next();
        });
    }

    getAll(url: any) {
        return this.http.get<UserData[]>(url);
    }

    getById(id: number, url: any) {
        return this.http.get(url`${id}`);
    }

    delete(id: number, url: any) {
        return this.http.delete(url`${id}`);
    }
    deleteUsers(id: any) {
        const payload = new HttpParams().set('user_ids', id);
        return this.http.delete(urls.deleteUsers, { params: payload });
    }

    setLogin(data: any) {
        this.userLoginData.next(data);
    }
    getLogin() {
        return this.userLoginData.asObservable();
    }

    getEncryptionKey() {
        return this.encryptionKey;
    }

    encryptPassword(pwd: string) {
        let _key = CryptoJS.enc.Utf8.parse(this.encryptionKey);
        let _iv = CryptoJS.enc.Utf8.parse(this.encryptionKey);
        let encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(pwd), _key, {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    }

    getAppNotifications(username: any) {
        return this.http.get(urls.appNotifications + '/' + username).pipe();
    }

    deleteAppNotifications(username: any, ids: any) {
        return this.http.post(urls.appNotifications + '/' + username, ids).pipe();
    }

    getUserAnalytics() {
        return this.http.get(urls.selectedAnalytics).pipe();
    }

    saveAnalyticsData(data: any) {
        return this.http.post(urls.selectedAnalytics, data).pipe();
    }

    getUserProfile(profile_id: any) {
        return this.http.get(urls.getUserProfile + '/' + profile_id).pipe();
    }

    updateUserProfile(profile_id: any, data: any) {
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(urls.getUserProfile + '/' + profile_id, formData, { headers: headers }).pipe();
    }

    setuserDetailsUpdated(url: any) {
        this.userDetailsUpdated.next(url);
    }

    getuserDetailsUpdated() {
        return this.userDetailsUpdated as Observable<any>;
    }

    updateProTip(data: any) {
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(urls.protip, formData, { headers: headers }).pipe();
    }

    getProTip() {
        return this.http.get(urls.protip).pipe();
    }

    setproTipUpdated(proTip: any) {
        this.proTipUpdated.next(proTip);
    }

    getproTipUpdated() {
        return this.proTipUpdated as Observable<any>;
    }

    getPackageInfo(id: any) {
        return this.http.get(urls.packageInfo + '/' + id).pipe();
    }
    getExtraPackageInfo(id: any) {
        return this.http.get(urls.extraPackageInfo + '/' + id).pipe();
    }

    calAdditionalAmt(data: any) {
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(urls.calAddAmount, formData, { headers: headers }).pipe();
    }
    confPackPayment(data: any) {
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(urls.confPayment, formData, { headers: headers }).pipe();
    }

    getAccLevelOTP(id: any) {
        //return this.http.get(urls.generateClientOTP + '/' + id).pipe();
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(urls.generateClientOTP + '/' + id, { headers: headers }).pipe();
    }

    saveAccLevelInfo(id: any, data: any) {
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.put(urls.packageInfo + '/' + id, formData, { headers: headers }).pipe();
    }

    getBillingInfo() {
        return this.http.get(urls.billingInfo).pipe();
    }
    getBillingInfoForSA(id: any) {
        return this.http.get(urls.billingInfo + "?client=" + id).pipe();
    }
    saveBillingInfo(data: any) {
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(urls.billingInfo, formData, { headers: headers }).pipe();
    }

    saveBillingInfoForSA(data: any, id: any) {
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(urls.billingInfo + "?client=" + id, formData, { headers: headers }).pipe();
    }

    getCompanyInfo() {
        return this.http.get(urls.companyInfo).pipe();
    }

    getCompInfoForSA(id: any) {
        return this.http.get(urls.companyInfo + "?client=" + id).pipe();
    }

    saveCompInfoFormForSA(data: any, id: any) {
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.put(urls.companyInfo + "?client=" + id, formData, { headers: headers }).pipe();
    }
    saveCompanyInfoForm(data: any) {
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.put(urls.companyInfo, formData, { headers: headers }).pipe();
    }
    getInvoice(id: any) {
        return this.http.get(urls.invoiceDetails + '?client=' + id).pipe();
    }
    printInvoice(id: any) {
        const httpOptions = {
            responseType: 'blob' as 'json'
        };

        return this.http.get(urls.printInvoice + '/' + id, httpOptions);
    }
    getClientList() {
        return this.http.get(urls.clientList).pipe();
    }
    getPackageDetails() {
        return this.http.get(urls.listAllPackages).pipe();
    }
    updatePackageDetails(Id: any, data: any) {
        let id = Id;
        const d = JSON.parse(data)
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.put(urls.updatePackages + '?id=' + id, d, { headers: headers }).pipe();
    }

    setUserAnalyticsData(data: any) {
        this.userAnalyticsData.next(data);
    }

    getUserAnalyticsData() {
        return this.userAnalyticsData as Observable<any>;
    }

    setDarkMode(profileId: any, isDarkMode: any) {

        return this.http.post(urls.updateDarkMode + '/' + profileId, { dark_mode: isDarkMode }).pipe();
    }

    createUser(payload: any) {
        const formData = new FormData();
        const Payload = payload;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(urls.createMultiroleUser, formData, { headers: headers }).pipe();
    }

    updateUser(payload: any) {
        const formData = new FormData();
        const Payload = payload;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.put(urls.createMultiroleUser, formData, { headers: headers }).pipe();
    }

    deleteUser(username: any) {
        return this.http.delete(urls.createMultiroleUser + '?username=' + username).pipe();
    }

    getAllUsers() {
        return this.http.get(urls.getAllUsers).pipe();
    }
    getUsersListforTable() {
        return this.http.get(urls.allUsersList).pipe();
    }
    inviteUsers(data: any) {
        return this.http.post(urls.inviteUsers, data).pipe();
    }
    getInvitedUsersData(token: any) {
        return this.http.get(urls.getInvitedUser + '/' + token).pipe();
    }
    getSignUpUser(token: any) {

        return this.http.get(urls.getSignupUserData + '/' + token).pipe();
    }
    signUpUserOne(payload: any, token: any) {
        const formData = new FormData();
        const Payload = payload;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(urls.signupUserOne + '/' + token, formData, { headers: headers }).pipe();
    }
    signUpUserTwo(payload: any, token: any) {
        const formData = new FormData();
        const Payload = payload;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(urls.signupUserTwo + '/' + token, formData, { headers: headers }).pipe();
    }
    signUpUserThree(payload: any, token: any) {

        return this.http.post(urls.signupUserThree + '/' + token, payload).pipe();
    }

    searchUsers(searchKey: any, filter: any) {
        return this.http.get(urls.getAllUsers + '?search=' + searchKey + '&type=' + filter.toString()).pipe();
    }

    getUserById(id: any) {
        return this.http.get(urls.createMultiroleUser + '?id=' + id).pipe();
    }

    // connectZohoChatbot() {
    //     if(document.querySelector('script#zohoChatbot')) {
    //         return;
    //     }
    //     const isLoggedIn = JSON.parse(localStorage.getItem('currentUser') || '')?.token;
    //     if(isLoggedIn) {
    //         const head = document.getElementsByTagName('head')[0];
    //         const script = document.createElement('script');
    //         script.setAttribute("id", "zohoChatbot");
    //         script.innerHTML = 'var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode:"siqbffbfee7f5ecf2f359104c6b497061e70005145b59047fba1131093810542fad", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.createElement("<div id=\'zsiqwidget\'></div>");';
    //         head.insertBefore(script, head.firstChild);
    //     } else {
    //         const element = document.querySelector('#zohoChatbot');
    //         if(element && element.parentElement) {
    //             element.parentElement.removeChild(element);
    //         }
    //     }
    // }

    changeUserRole(role: any) {
        return this.http.post(urls.changeUserRole, { role: role }).pipe();
    }


    MailFireCancelWithOutSignupPostMethod(id: any): Observable<any> {
        return this.http.post(urls.MailFireCancelWithOutSignupPath + '/' + id, '');
    }
    getScheduleCode(token: any, r_url: any) {
        return this.http.get(urls.getScheduleCode + '/' + token + '?redirect_uri=' + r_url).pipe();
    }
    getCronofyCalender() {
        return this.http.get(urls.getCronofyCalender).pipe();
    }
    getAccessConfirmation() {
        return this.http.get(urls.getAccessConfirmation).pipe();
    }
    getCronofyElementToken(origin: any) {
        const params = new HttpParams().set('origin', origin);
        return this.http.get(urls.getCronofyElementToken, { params }).pipe();
    }
    getUserCalendar(month: any, year: any, op_id?: any) {
        if (op_id)
            return this.http.get(urls.getUserCalendar + '?month=' + month + '&year=' + year + '&op_id=' + op_id).pipe();
        else {
            return this.http.get(urls.getUserCalendar + '?month=' + month + '&year=' + year).pipe();
        }
    }
    getPositionsForCalendar(month: any, year: any) {
        return this.http.get(urls.getPositionsForCalendar + '?month=' + month + '&year=' + year).pipe();
    }
    getActivePositionsForCalendar() {
        return this.http.get(urls.getPositionsForCalendar).pipe();
    }
    getUpcomingInterviews() {
        return this.http.get(urls.upcomingHtmInterviews).pipe();
    }
    getPositionPrivacyData(c_id: number) {
        return this.http.get(urls.positionPrivacyData + '?client=' + c_id).pipe();
    }
    updatePositionPrivacyData(payload: any) {
        return this.http.put(urls.positionPrivacyData, payload).pipe();
    }
    getNavigatorList() {
        return this.http.get(urls.listNavigators).pipe();
    }
    postNavigator(payload: any) {
        return this.http.post(urls.postNavigators + '/' + payload.step_id, payload).pipe();
    }
    getNavigatorStep() {
        return this.http.get(urls.getNavigatorStep).pipe();
    }
    updateNavMode(payload: any) {
        return this.http.post(urls.updateNavMode, payload).pipe();
    }
}
