<div class="row">
    <div class="col-md-12">
        <div class="widget-col createPosition">
            <h2 *ngIf="!isSettingsPage" class="widget-title"><span *ngIf="showUpdateBtn">Edit Client</span><span
                    *ngIf="!showUpdateBtn">Add Client</span></h2>
            <div class="widget-box">
                <div class="widget-inner-box">
                    <div class="form-section">
                        <form id="addCandidate" [formGroup]="clientForm">

                            <fieldset class="scheduler-border">
                                <legend class="pt-4">Company Contact</legend>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Full Name<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control"
                                            formControlName="company_contact_full_name">
                                        <div *ngIf="clientForm.controls['company_contact_full_name'].invalid && (clientForm.controls['company_contact_full_name'].dirty || clientForm.controls['company_contact_full_name'].touched) || formHasError"
                                            class="alert">
                                            <div
                                                *ngIf="clientForm.controls['company_contact_full_name'].errors?.required">
                                                Please enter name.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Phone<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="company_contact_phone">
                                        <div *ngIf="clientForm.controls['company_contact_phone'].invalid && (clientForm.controls['company_contact_phone'].dirty || clientForm.controls['company_contact_phone'].touched) || formHasError"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['company_contact_phone'].errors?.required">
                                                Please enter Phone number.
                                            </div>
                                            <div *ngIf="clientForm.controls['company_contact_phone'].errors?.pattern">
                                                Phone number must be exactly 10 digit & only number is allowed.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Email<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="company_contact_email">
                                        <div *ngIf="clientForm.controls['company_contact_email'].invalid && (clientForm.controls['company_contact_email'].dirty || clientForm.controls['company_contact_email'].touched) || formHasError"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['company_contact_email'].errors?.required">
                                                Please enter email address.
                                            </div>
                                            <div *ngIf="clientForm.controls['company_contact_email'].errors?.pattern">
                                                Invalid Email, please try again.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="scheduler-border">
                                <legend class="pt-4">Company Details</legend>
                                <div *ngIf="userRole ==='Superuser' || userRole ==='Account Manager' "
                                    class="form-group row">
                                    <label class="col-sm-3 col-form-label">Package<small>*</small></label>
                                    <div class="col-sm-9">
                                        <select class="form-control" formControlName="package"
                                            (change)="onPackageChange($event)">
                                            <option value="">Please select</option>
                                            <option *ngFor="let pack of packageData" [value]="pack.id">{{pack.name}}
                                                Account - ${{pack.price}}</option>
                                        </select>
                                        <div *ngIf="clientForm.controls['package'].invalid && (clientForm.controls['package'].dirty || clientForm.controls['package'].touched) || formHasError"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['package'].errors?.required">
                                                Please select a package
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row"
                                    *ngIf="(userRole ==='Superuser' || userRole ==='Account Manager') && showExpiry  ">
                                    <label class="col-sm-3 col-form-label">Expiry Date<small>*</small></label>


                                    <div class="col-sm-4 row ml-0">
                                        <div class="pos-relative">
                                            <input class="form-control date-input mb-2 mr-sm-2" placeholder="dd-mm-yyyy"
                                                name="dp" ngbDatepicker #d3="ngbDatepicker" formControlName="trial_expired"
                                                container="body" id="inlineFormInputName3" readonly />
                                            <button class="btn btn-outline-secondary datepicker-btn" (click)="d3.toggle()"
                                                type="button">
                                                <fa-icon [icon]="faCalendar"></fa-icon>
                                            </button>
                                        </div>
                                        <div *ngIf="clientForm.controls['trial_expired'].invalid && (clientForm.controls['trial_expired'].dirty || clientForm.controls['trial_expired'].touched) || formHasError" class="alert">
                                                <div *ngIf="clientForm.controls['trial_expired'].errors?.required">
                                                    Please select an package expiry date
                                                </div>
                                            </div>
                                    </div>


                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Company Name<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="company_name">
                                        <div *ngIf="clientForm.controls['company_name'].invalid && (clientForm.controls['company_name'].dirty || clientForm.controls['company_name'].touched) || formHasError"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['company_name'].errors?.required">
                                                Please enter company name
                                            </div>
                                            <!-- Display error message if spaces are present -->
                                            <div *ngIf="clientForm.controls['company_name'].errors?.noSpaces">
                                                Spaces are not allowed.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Company Website</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="company_website">
                                        <div *ngIf="clientForm.controls['company_website'].errors?.pattern"
                                            class="alert">
                                            Please enter valid url.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Company LinkedIn</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="company_linkedin">
                                        <div *ngIf="clientForm.controls['company_linkedin'].errors?.pattern"
                                            class="alert">
                                            Please enter valid LinkedIn url.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Address Line 1<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="addr_line_1">
                                        <div *ngIf="clientForm.controls['addr_line_1'].invalid && (clientForm.controls['addr_line_1'].dirty || clientForm.controls['addr_line_1'].touched)  || formHasError"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['addr_line_1'].errors?.required">
                                                Please enter your Address.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Address Line 2</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="addr_line_2">

                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">City<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="city">
                                        <div *ngIf="clientForm.controls['city'].invalid && (clientForm.controls['city'].dirty || clientForm.controls['city'].touched) || formHasError"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['city'].errors?.required">
                                                Please enter city.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">State<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="state">
                                        <div *ngIf="clientForm.controls['state'].invalid && (clientForm.controls['state'].dirty || clientForm.controls['state'].touched) || formHasError"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['state'].errors?.required">
                                                Please enter state.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Zip Code<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="pincode">
                                        <div *ngIf="clientForm.controls['pincode'].invalid && (clientForm.controls['pincode'].dirty || clientForm.controls['pincode'].touched) || formHasError"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['pincode'].errors?.required">
                                                Please enter Zip code.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Upload logo image</label>
                                    <div class="col-sm-9">
                                        <div *ngIf="imageURL == '' || imageURL == 'None'">
                                            <input type="file" style="width: 250px; float: left;"
                                                class="form-control-file" (change)="showPreview($event)">
                                            <input type="hidden" name="fileHidden" formControlName="logo" />
                                        </div>
                                        <div class="imageLogoPreview"
                                            *ngIf="imageURL && imageURL !== '' && imageURL !== 'None'">
                                            <span id="x" (click)="removeLogo()">
                                                x
                                            </span>
                                            <img [src]="imageURL" [alt]="clientForm.value.name">
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset class="scheduler-border">
                                <legend class="pt-4">Client Admin</legend>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">First Name<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="ca_first_name">
                                        <div *ngIf="clientForm.controls['ca_first_name'].invalid && (clientForm.controls['ca_first_name'].dirty || clientForm.controls['ca_first_name'].touched) || formHasError"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['ca_first_name'].errors?.required">
                                                Please enter first name
                                            </div>
                                            <div *ngIf="clientForm.controls['ca_first_name'].errors?.pattern">
                                                Please enter valid name, only alphabets are allowed.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Last Name<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="ca_last_name">
                                        <div *ngIf="clientForm.controls['ca_last_name'].invalid && (clientForm.controls['ca_last_name'].dirty || clientForm.controls['ca_last_name'].touched) || formHasError"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['ca_last_name'].errors?.required">
                                                Please enter last name
                                            </div>
                                            <!-- <div *ngIf="clientForm.controls['ca_last_name'].errors?.pattern">
                                                Please enter valid name, only alphabets are allowed.
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Phone<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="key_contact_phone_no">
                                        <div *ngIf="clientForm.controls['key_contact_phone_no'].invalid && (clientForm.controls['key_contact_phone_no'].dirty || clientForm.controls['key_contact_phone_no'].touched) || formHasError"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['key_contact_phone_no'].errors?.required">
                                                Please enter phone
                                            </div>
                                            <div *ngIf="clientForm.controls['key_contact_phone_no'].errors?.pattern">
                                                Phone number must be exactly 10 digit & only number is allowed.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">LinkedIn Address</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="key_contact_skype_id">
                                        <!-- <div *ngIf="clientForm.controls['key_contact_skype_id'].invalid && (clientForm.controls['key_contact_skype_id'].dirty || clientForm.controls['key_contact_skype_id'].touched)" class="alert">
                                            <div *ngIf="clientForm.controls['key_contact_skype_id'].errors?.required">
                                                Please enter LinkedIn address
                                            </div>
                                        </div> -->
                                        <div *ngIf="clientForm.controls['key_contact_skype_id'].errors?.pattern"
                                            class="alert">
                                            Please enter valid LinkedIn url.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Job Title<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="job_title">
                                        <div *ngIf="clientForm.controls['job_title'].invalid && (clientForm.controls['job_title'].dirty || clientForm.controls['job_title'].touched) || formHasError"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['job_title'].errors?.required">
                                                Please enter job title
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Email<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input [readonly]="showUpdateBtn || isSettingsPage" type="text" class="form-control" formControlName="key_username">
                                        <div *ngIf="!isSettingsPage && clientForm.controls['key_username'].invalid && (clientForm.controls['key_username'].dirty || clientForm.controls['key_username'].touched) || formHasError" class="alert">
                                            <div *ngIf="clientForm.controls['key_username'].errors?.required">
                                                Please enter Email
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div> -->
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Email<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input
                                            [readonly]="showUpdateBtn && clientForm.controls['key_contact_email'].value !== ''"
                                            type="text" class="form-control" formControlName="key_contact_email">
                                        <div *ngIf="!isSettingsPage && clientForm.controls['key_contact_email'].invalid && (clientForm.controls['key_contact_email'].dirty || clientForm.controls['key_contact_email'].touched) || formHasError"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['key_contact_email'].errors?.required">
                                                Please enter email
                                            </div>
                                            <div *ngIf="clientForm.controls['key_contact_email'].errors?.pattern">
                                                Invalid Email, please try again.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="!showUpdateBtn && !isSettingsPage">
                                    <label class="col-sm-3 col-form-label">Password<small>*</small></label>
                                    <!-- <div class="col-sm-9">
                                        <input type="password" class="form-control" formControlName="key_password">
                                        <div *ngIf="!isSettingsPage && clientForm.controls['key_password'].invalid && (clientForm.controls['key_password'].dirty || clientForm.controls['key_password'].touched)" class="alert">
                                            <div *ngIf="clientForm.controls['key_password'].errors?.required">
                                                Please enter password
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-9">
                                        <input type="{{ showPassword ? 'text' : 'password' }}"
                                            class="form-control input-password" formControlName="key_password"
                                            (input)="onPasswordChange()"
                                            [ngStyle]="{'color': clientForm.controls.key_password.errors? 'red' : 'green'}">
                                        <button type="button" (click)="togglePasswordVisibility()"
                                            class="password-toggle">
                                            <i class="fas"
                                                [ngClass]="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                        </button>
                                    </div>
                                    <div class="col-sm-9 offset-3">
                                        <div *ngIf="!isSettingsPage  && clientForm.controls['key_password'].invalid && (clientForm.controls['key_password'].dirty || clientForm.controls['key_password'].touched) || formHasError"
                                            class="alert">
                                            <div
                                                *ngIf="clientForm.controls['key_password'].errors?.required && !clientForm.controls['key_password'].value ">
                                                Please enter password
                                            </div>
                                        </div>
                                        <div *ngIf="!isSettingsPage  && clientForm.controls['key_password']?.errors && clientForm.controls['key_password'].value && !(clientForm.controls['key_password']?.hasError('maxlength'))"
                                            class="password-valid-pts">
                                            Password must contain at least one of each of the following characters:
                                            <div [ngStyle]="{'color': isUppercaseValid ? 'green' : 'red'}">
                                                <span>- Uppercase letters: A-Z.</span>
                                            </div>
                                            <div [ngStyle]="{'color': isLowecaseValid ? 'green' : 'red'}">
                                                <span>- Lowercase letters: a-z.</span>
                                            </div>
                                            <div [ngStyle]="{'color': isNumberValid ? 'green' : 'red'}">
                                                <span>- Numbers: 0-9.</span>
                                            </div>
                                            <div [ngStyle]="{'color': isSymbolValid ? 'green' : 'red'}">
                                                <span>- Symbols: ~`! @#$%^&*()_-+={{'{'}}[{{'}'}}]|\:;"'<,>.?/</span>
                                            </div>
                                        </div>
                                        <div *ngIf="(clientForm.controls['key_password']?.hasError('minlength') || clientForm.controls['key_password']?.hasError('maxlength')) && (clientForm.controls['key_password'].dirty || clientForm.controls['key_password'].touched)"
                                            class="alert">
                                            <span>Password should contain min 8 and max 16 charaters</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Upload profile picture</label>
                                    <div class="col-sm-9">
                                        <div *ngIf="caProfilePic == '' || caProfilePic == 'None'">
                                            <input type="file" style="width: 250px; float: left;"
                                                class="form-control-file" (change)="showCAPreview($event)">
                                            <input type="hidden" name="fileHidden" formControlName="ca_profile_pic" />
                                        </div>
                                        <div class=" profileImagePreview"
                                            *ngIf="caProfilePic && caProfilePic !== '' && caProfilePic !== 'None'">
                                            <span id="x" (click)="removePhoto()">
                                                x
                                            </span>
                                            <img [src]="caProfilePic" [alt]="clientForm.value.name">
                                        </div>
                                    </div>
                                </div>
                                <button *ngIf="showUpdateBtn" class="btn btn-primary" style="float: right;"
                                    (click)="openforPassword(password)">Change Password</button>
                            </fieldset>
                            <fieldset class="scheduler-border">
                                <legend class="pt-4">Coordinator Contact</legend>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">First Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="hr_first_name">
                                        <div *ngIf="clientForm.controls['hr_first_name'].errors?.pattern" class="alert">
                                            Please enter valid name, only alphabets are allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Last Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="hr_last_name">
                                        <div *ngIf="clientForm.controls['hr_last_name'].errors?.pattern" class="alert">
                                            Please enter valid name, only alphabets are allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Phone</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="hr_contact_phone_no">
                                        <div *ngIf="clientForm.controls['hr_contact_phone_no'].errors?.pattern"
                                            class="alert">
                                            Phone number must be exactly 10 digit & only number is allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">LinkedIn Address</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="hr_contact_skype_id">
                                        <div *ngIf="clientForm.controls['hr_contact_skype_id'].errors?.pattern"
                                            class="alert">
                                            Please enter valid LinkedIn url.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Email</label>
                                    <div class="col-sm-9">
                                        <input
                                            [readonly]="isSettingsPage && clientForm.controls['hr_contact_email'].value !== ''"
                                            type="text" class="form-control" formControlName="hr_contact_email">
                                        <div *ngIf="clientForm.controls['hr_contact_email'].errors?.pattern"
                                            class="alert">
                                            Invalid Email, please try again.
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="scheduler-border">
                                <legend class="pt-4">CTO</legend>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">First Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="cto_first_name">
                                        <div *ngIf="clientForm.controls['cto_first_name'].errors?.pattern"
                                            class="alert">
                                            Please enter valid name, only alphabets are allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Last Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="cto_last_name">
                                        <div *ngIf="clientForm.controls['cto_last_name'].errors?.pattern" class="alert">
                                            Please enter valid name, only alphabets are allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Phone</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="cto_phone_no">
                                        <div *ngIf="clientForm.controls['cto_phone_no'].errors?.pattern" class="alert">
                                            Phone number must be exactly 10 digit & only number is allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">LinkedIn Address</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="cto_skype_id">
                                        <div *ngIf="clientForm.controls['cto_skype_id'].errors?.pattern" class="alert">
                                            Please enter valid LinkedIn url.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Email</label>
                                    <div class="col-sm-9">
                                        <input
                                            [readonly]="isSettingsPage && clientForm.controls['cto_email'].value !== ''"
                                            type="text" class="form-control" formControlName="cto_email">
                                        <div *ngIf="clientForm.controls['cto_email'].errors?.pattern" class="alert">
                                            Invalid Email, please try again.
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="scheduler-border">
                                <legend class="pt-4">Billing Contact</legend>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">First Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="billing_first_name">
                                        <div *ngIf="clientForm.controls['billing_first_name'].errors?.pattern"
                                            class="alert">
                                            Please enter valid name, only alphabets are allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Last Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="billing_last_name">
                                        <div *ngIf="clientForm.controls['billing_last_name'].errors?.pattern"
                                            class="alert">
                                            Please enter valid name, only alphabets are allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Phone</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="billing_phone_no">
                                        <div *ngIf="clientForm.controls['billing_phone_no'].errors?.pattern"
                                            class="alert">
                                            Phone number must be exactly 10 digit & only number is allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Email</label>
                                    <div class="col-sm-9">
                                        <input
                                            [readonly]="isSettingsPage && clientForm.controls['billing_email'].value !== ''"
                                            type="text" class="form-control" formControlName="billing_email">
                                        <div *ngIf="clientForm.controls['billing_email'].errors?.pattern" class="alert">
                                            Invalid Email, please try again.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Address Line 1</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="billing_addr_line_1">

                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Address Line 2</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="billing_addr_line_2">

                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">City</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="billing_city">

                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">State</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="billing_state">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Zip Code</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="billing_pincode">
                                    </div>
                                </div>
                            </fieldset>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Special requirements</label>
                                <div class="col-sm-9">
                                    <textarea class="form-control" formControlName="special_req"></textarea>
                                </div>
                            </div>

                        </form>
                        <div class="d-inline float-right my-3">
                            <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
                            <button type="submit" class="btn btn-primary ml-2" *ngIf="!showUpdateBtn"
                                (click)="saveClient(clientForm.value)">Save</button>
                            <button type="submit" class="btn btn-primary ml-2" *ngIf="showUpdateBtn"
                                (click)="updateClient()">Update Client</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #password let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Change Password</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="passwordForm">
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">New Password <small>*</small></label>
                <div class="col-sm-8">
                    <!-- <input type="{{ showPassword ? 'text' : 'password' }}" class="form-control input-password" autocomplete="new_password" formControlName="new_password" (input)="onPasswordChange()" [ngStyle]="{'color': passwordForm.controls.new_password.errors? 'red' : 'green'}"> -->
                    <input type="{{ showPassword ? 'text' : 'password' }}" class="form-control input-password"
                        autocomplete="new_password" formControlName="new_password">
                    <button type="button" (click)="togglePasswordVisibility()" class="password-toggle">
                        <i class="fas" [ngClass]="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                    </button>
                </div>
                <!-- <div class="col-sm-12"> -->
                <div class="col-sm-8 offset-4">
                    <div *ngIf="passwordForm.controls['new_password'].invalid && (passwordForm.controls['new_password'].dirty || passwordForm.controls['new_password'].touched)"
                        class="alert">
                        <div
                            *ngIf="passwordForm.controls['new_password'].errors?.required && !passwordForm.controls['new_password'].value">
                            Please enter password
                        </div>
                    </div>
                    <!-- <div *ngIf="passwordForm.controls['new_password']?.errors && passwordForm.controls['new_password'].value && !(passwordForm.controls['new_password']?.hasError('maxlength'))" class="password-valid-pts">
                        Password must contain at least one of each of the following characters:
                        <div [ngStyle]="{'color': isUppercaseValid ? 'green' : 'red'}">
                            <span>- Uppercase letters: A-Z.</span>
                        </div>
                        <div [ngStyle]="{'color': isLowecaseValid ? 'green' : 'red'}">
                            <span>- Lowercase letters: a-z.</span>
                        </div>
                        <div [ngStyle]="{'color': isNumberValid ? 'green' : 'red'}">
                            <span>- Numbers: 0-9.</span>
                        </div>
                        <div [ngStyle]="{'color': isSymbolValid ? 'green' : 'red'}">
                        <span>- Symbols: ~`! @#$%^&*()_-+={{'{'}}[{{'}'}}]|\:;"'<,>.?/</span>
                        </div>
                    </div> -->
                    <div *ngIf="(passwordForm.controls['new_password']?.hasError('minlength') || passwordForm.controls['new_password']?.hasError('maxlength')) && (passwordForm.controls['new_password'].dirty || passwordForm.controls['new_password'].touched ) && !passSubmitted"
                        class="alert">
                        <span>Password should contain min 8 and max 16 characters</span>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Re-Password
                    <small>*</small>
                </label>
                <div class="col-sm-8">
                    <input type="password" class="form-control" formControlName="re_password" (blur)="checkPasswords()">
                    <div *ngIf="passwordForm.controls['re_password'].invalid && (passwordForm.controls['re_password'].dirty || passwordForm.controls['re_password'].touched)"
                        class="alert">
                        <div *ngIf="passwordForm.controls['re_password'].errors?.required">
                            Re-Password is required.
                        </div>
                    </div>
                    <label *ngIf="showError" class="alert">Password not matching, try again</label>
                </div>
            </div>
        </form>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <!-- <button type="button" class="btn btn-primary" [disabled]="passwordForm.invalid || showError" (click)="changePassword()">Change Password</button> -->
        <button type="button" class="btn btn-primary" [disabled]="showError" (click)="changePassword()">Change
            Password</button>
    </div>
</ng-template>