import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.services';
import { ToastService } from 'src/app/core/toast/toast-service';
import { DashboardService } from 'src/app/services/dashboard.service';
@Component({
  selector: 'app-invite-users',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.css']
})
export class InviteUsersComponent implements OnInit {

  userEmail: any
  createUsersForm !: FormGroup;
  clientId: any;
  clientList: any;
  currentUserRoleCode: any;
  constructor(private userserice: UserService,
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private toastService: ToastService,
    private dashboardsService: DashboardService) { }

  ngOnInit(): void {
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    this.clientId = localStorage.getItem('clientId')
    if(this.currentUserRoleCode == '/sa')
      this.getClientList();
    this.createForm();
  }

  getClientList() {
    this.dashboardsService.getClientList().subscribe((val: any) => {
      if (val) {
        this.clientList = val.clients;
      }
    });
  }
  createForm() {
    this.createUsersForm = this.fb.group({
      client: [this.clientId, Validators.required],
      email: ['', [
        Validators.required,
        // Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$'),
        Validators.email
      ]],
      note: [''],
      role: ['is_candidate', Validators.required],
    });
  }
  roleChanged(str: string) {
    this.createUsersForm.controls['role'].setValue(str)
  }
  @ViewChild('option1Ref') option1Ref!: ElementRef<HTMLInputElement>;
  inviteUsers() {
    this.userserice.inviteUsers(this.createUsersForm.value).subscribe((val: any) => {
      if (val) {
        this.createForm()
        this.option1Ref.nativeElement.checked = true;
        this.showSuccess('Invitation Sent')
      }
      else {
        this.showDanger('Something went wrong')
      }
    })
  }

  goBack() {
    this.router.navigate([this.authService.currentUserRoleCode + '/view-all-users']).then(() => {
      // window.location.reload();
    });
  }
  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }
  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }
}
