import { Injectable} from '@angular/core';
import { NgbDateAdapter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap'
/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        month : parseInt(date[0], 10),
        day : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? ('0'+date.month).slice(-2) + this.DELIMITER + ('0'+date.day).slice(-2) + this.DELIMITER + date.year : null;
  }
}