import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { faEdit } from '@fortawesome/free-regular-svg-icons';
import {
  faCoffee,
  faPen,
  faPlus,
  faSearch,
  faTrash,
  faLightbulb,
  faTrashRestore,
  faClone
} from '@fortawesome/free-solid-svg-icons';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Client } from 'src/app/models/dashboard.interface';
import { ClientService } from 'src/app/services/client.service';

import * as Url from './../../constants/url';
import { DashboardService } from '../../services/dashboard.service';
import { ToastService } from '../../core/toast/toast-service';
import { CandidateService } from 'src/app/services/candidate.service';
import { AuthService } from 'src/app/services/auth.services';
import { PositionService } from 'src/app/services/position.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  public clientList: Client[] = [];
  public firstClient: any;
  public searchFilter: any = '';
  faLightbulb = faLightbulb;
  faTrashRestore = faTrashRestore;
  faClone= faClone;
  query: any;
  selected: any;
  faSearch = faSearch;
  faPlus = faPlus;
  faEdit = faPen;
  faDelete = faTrash;
  public logo: any;
  isshowSearch: boolean = false;
  selectedClientId: number = 0;
  public userRole: string = '';
  currentUserRoleCode: any;
  public positionList: any[] = [];
  currentClient: any;
  archivedPositionList: any;
  draftedPositionList: any;
  constructor(
    private dashboardsService: DashboardService,
    private modalService: NgbModal,
    private router: Router,
    private clientservice: ClientService,
    private toastService: ToastService,
    private candidatesService: CandidateService,
    private authService: AuthService,
    private positionService: PositionService
  ) {
    // this.router.routeReuseStrategy.shouldReuseRoute = function() {
    //   return false;
    // };
  }

  ngOnInit(): void {
    let uerdata:any = localStorage.getItem('currentUser');
    uerdata = JSON.parse(uerdata);
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    this.currentClient = uerdata?.client_id;;
    if(uerdata) {
      this.userRole = uerdata?.role;
    }
    //this.getClientList();
    this.candidatesService.setSelectedCandidate({});
  }

  getClientList() {
    this.dashboardsService.getClientList().subscribe((val: any) => {
      if (val) {
        this.clientList = val.clients;
        if (this.clientList?.length > 0) {
          this.firstClient = this.clientList[0];
          localStorage.setItem('activeClient', val.active_clients);
          localStorage.setItem('openPosition', val.total_open_positions);
          this.isActive(this.firstClient);
          this.logo = Url.imgBaseUrl + this.firstClient.logo;
          this.collectPositionFromClients();
        }
      }
    });
  }

  collectPositionFromClients(isDuplicateOrDelete?:any) {
    this.clientList.map((el: any) => {
      if (this.currentClient == el.id) {
        if (el.open_position_data.length > 0) {
          if(isDuplicateOrDelete) {
            this.positionList[0] = el.open_position_data;
          } else {
            this.positionList.push(el.open_position_data);
          }
          // this.positionList = el.open_position_data;
        }
        this.draftedPositionList = el.drafted_position_data;
        this.archivedPositionList = el.archieved_position_data;
      } else if((this.userRole === 'Hiring Manager') || (this.userRole === 'Hiring Member') || (this.userRole === 'Client Admin')){
        if(isDuplicateOrDelete) {
          this.positionList[0] = el.open_position_data;
        } else {
          this.positionList.push(el.open_position_data);
        }
      }
    });

    // if (this.positionList.length > 0) { 
    //   this.firstClient = this.positionList[0][0];
    //   // this.select(this.firstClient);
    //   if(this.userRole === 'Hiring Manager' || this.userRole === 'Hiring Member' || this.userRole === 'Superuser' || this.userRole === 'Account Manager') {
    //     if(this.router.url.indexOf('/candidateProfile/') === -1) {
    //     // this.select(this.firstClient);
    //     this.candidatesService.setSelectedCandidate(this.firstClient);
    //     }
    //   }
    //   this.isActive(this.firstClient);
    // }
  }
  select(item: any) {
    if (item === undefined){
      this.logo = Url.imgBaseUrl + this.firstClient.logo;
    }
    if(this.currentClient) {
      localStorage.setItem('clientId', this.currentClient);
    } else {
      localStorage.setItem('clientId', item.id);
    }
    this.candidatesService.setSelectedCandidate({});
    this.router.navigate([this.authService.currentUserRoleCode + '/position']);
  }

  selectPosition(item: any) { 
    this.selected = item;
    localStorage.setItem('clientId', this.currentClient);
    localStorage.setItem('currentPosition', item?.id);
    localStorage.setItem('currentPositionName', item?.position_title);
    localStorage.setItem('useitOnceCandidateassociated', JSON.stringify(item));
    this.candidatesService.setSelectedCandidate(this.selected);
    this.router.navigate([this.authService.currentUserRoleCode + '/position/clientPosition']);
  }
  isActive(item: any) {
    return this.selected === item;
  }

  editClient(id: any) {
    localStorage.setItem('ediClientId', id);
    this.router.navigate([this.authService.currentUserRoleCode + '/dashboard/addClient']);
  }
  deleteClient(id?: any) {
    if (this.selectedClientId > 0) {
      this.clientservice
        .deleteClient(this.selectedClientId)
        .subscribe((client) => {
          if (client) {
            // this.showSuccess();
            this.modalService.dismissAll();
            alert('Client deleted successfully');
            location.reload();
          }
        });
    }
  }

  showSuccess() {
    this.toastService.show('Client deleted successfully.', {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showSearch() {
    this.isshowSearch = !this.isshowSearch;
  }

  openSm(content: any, id: any) {
    this.selectedClientId = id;
    this.modalService.open(content, { size: 'sm' });
  }

  createPosition(e: any){
    e.stopPropagation();
    localStorage.removeItem('editPosition');
    this.router.navigate([this.authService.currentUserRoleCode + '/position/createPosition1']);
  }

  editPosition(id: any, hiringGroupId: any) {
    localStorage.setItem('editPosition', id);
    localStorage.setItem('hiringGroupId', hiringGroupId);
    // setTimeout(() => {
      this.router.navigate([this.authService.currentUserRoleCode + '/position/createPosition1']);
    // }, 500);
  }

  restoreOpenPosition(op_id: any) {
    this.positionService.restorePosition(op_id).subscribe(res => {
      this.toastService.show('Position restored successfully.', {
        classname: 'bg-success text-light',
        delay: 5000,
      });
    });
    this.modalService.dismissAll();
    this.getClientList();
  }

  duplicatePosition(id: any) {
    this.positionService.duplicatePosition(id).subscribe((res: any) => {
      if (res.msg === 'success') {
        this.getClientList();
        this.showCloneSuccess();
        this.modalService.dismissAll();
      }
    })
  }

  showCloneSuccess() {
    this.toastService.show('Position added in Draft Positions.', {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }
}
