import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AuthService } from './../../services/auth.services';
import { LoadingService } from 'src/app/services/loading.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private totalRequests = 0;
    constructor(private authService: AuthService, private loadingService: LoadingService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.includes('vimeo.com')) {
            return next.handle(request).pipe(
                finalize(() => {
                    this.totalRequests--;
                    if (this.totalRequests === 0) {
                        this.authService.setUserLogin(false);
                        this.loadingService.setLoading(false);
                    }
                })
            );
        }
        this.totalRequests++;
        setTimeout(() => {
            if (!getExemptedApi(request.url)) this.loadingService.setLoading(true);
            this.authService.setUserLogin(true);
        }, 0);
        const ssoTokenId = localStorage.getItem('id_token_sso');
        // add authorization header with jwt token if available 
        const currentUser = this.authService.currentUserValue;
        if ((currentUser && currentUser.token) || ssoTokenId) {
            const tokenInitializer = ssoTokenId ? 'Bearer' : 'Token';
            if (ssoTokenId) {
                request = request.clone({
                    setHeaders: {
                        Authorization: tokenInitializer + ' ' + ssoTokenId
                    }
                });
            } else {
                request = request.clone({
                    setHeaders: {
                        Authorization: tokenInitializer + ` ${currentUser.token}`
                    }
                });
            }
        }
        const dateTimeFormat = new Intl.DateTimeFormat();
        const resolvedOptions = dateTimeFormat.resolvedOptions();
        const timeZone = resolvedOptions.timeZone;
        request = request.clone({
            setHeaders: {
                timezone: timeZone
            }
        });

        return next.handle(request).pipe(
            finalize(() => {
                this.totalRequests--;
                if (this.totalRequests === 0) {
                    this.authService.setUserLogin(false);
                    this.loadingService.setLoading(false);
                }
            })
        );
    }
}


function getExemptedApi(url: string) {
    return urls.some((u) => url.includes(u));
}

const urls = [
    'temp-candidate-marks',
]