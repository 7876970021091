import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import * as Url from './../constants/url';

@Injectable({ providedIn: 'root' })

export class PositionService {

    public uploadedDocument = new BehaviorSubject<any>(null);
    requestedPositionList = new BehaviorSubject<any>(null);
    private isPositionUpdated = new BehaviorSubject<any>(null);
    public isPositionUpdated$: Observable<any> = this.isPositionUpdated.asObservable();
    constructor(private http: HttpClient) { }
    getPositionInfoFromJD(jd: any) {
        return this.http.post(Url.getDataFromJD, jd);
    }
    getSingleDataFromJD(jd: any) {
        return this.http.post(Url.getSingleDataFromJD, jd);
    }

    getSuggestedQuestions(data: string) {
        return this.http.post(Url.getQuestionsBySkill, {
            skill: data
        })
    }
    getSingleQuestionBy(data: string) {
        return this.http.post(Url.getQuestionBySkill, {
            skill: data
        })
    }

    getSkillsets(jd: any) {
        return this.http.post(Url.getSkillsetsUrl, jd)
    }

    createPosition(data: any) {
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            if (key === 'stages') {
                formData.append('stages', JSON.stringify(Payload[key]))
            } else if (key === 'htm_deadlines') {
                formData.append('htm_deadlines', JSON.stringify(Payload[key]))
            } else if (key.startsWith('skillsets')) {
                formData.append(key, JSON.stringify(Payload[key]))
            } else {
                formData.append(key, Payload[key]);
            }
        });
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(Url.createPositionUrl, formData, { headers: headers }).pipe();
    }

    updatePosition(data: any) {
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            if (key === 'stages') {
                formData.append('stages', JSON.stringify(Payload[key]))
            } else if (key === 'htm_deadlines') {
                formData.append('htm_deadlines', JSON.stringify(Payload[key]))
            } else if (key.startsWith('skillsets')) {
                formData.append(key, JSON.stringify(Payload[key]))
            } else {
                formData.append(key, Payload[key]);
            }
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.put(Url.createPositionUrl, formData, { headers: headers }).pipe();
    }

    getPositionById(id: any) {
        return this.http.get(Url.getPositionByIdUrl + '/' + id).pipe();
    }
    getFilteredPosbyId(id: any) {
        return this.http.get(Url.getFilteredPosbyIdURL + '/' + id).pipe();
    }

    deletePosition(id: any) {
        let params = new HttpParams().set('op_id', id);
        return this.http.delete(Url.createPositionUrl, { params }).pipe();
    }

    trashPosition(id: any, isTrashed: boolean) {
        let params = new HttpParams().set('op_id', id);
        return this.http.post(Url.trashOpenPosition + '/' + id, { trashed: isTrashed }).pipe();
    }

    createAccount(data: any) {
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(Url.accountUrl, formData, { headers: headers }).pipe();
    }

    updateAccount(data: any) {
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.put(Url.accountUrl, formData, { headers: headers }).pipe();
    }

    getAllAccounts() {
        return this.http.get(Url.getAllAccountUrl).pipe();
    }

    getAccountById(username: any) {
        let params = new HttpParams().set('username', username);
        return this.http.get(Url.accountUrl, { params }).pipe();
    }

    deleteAccount(username: any) {
        let params = new HttpParams().set('username', username);
        return this.http.delete(Url.accountUrl, { params }).pipe();
    }

    getAllSeniorManagers() {
        return this.http.get(Url.getSeniorManagers).pipe();
    }

    createSeniorManager(data: any) {
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(Url.seniorManagers, formData, { headers: headers }).pipe();
    }

    updateSeniorManager(data: any) {
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.put(Url.seniorManagers, formData, { headers: headers }).pipe();
    }

    getSeniorManagerById(username: any) {
        let params = new HttpParams().set('username', username);
        return this.http.get(Url.seniorManagers, { params }).pipe();
    }

    deleteSeniorManager(username: any) {
        let params = new HttpParams().set('username', username);
        return this.http.delete(Url.seniorManagers, { params }).pipe();
    }

    duplicatePosition(id: any) {
        return this.http.post(Url.duplicateOpenPosition + '/' + id, {}).pipe();
    }

    getNotificationsByPosition(id: any) {
        return this.http.get(Url.getNotification + '/' + id).pipe();
    }

    saveNotificationData(id: any, payload: any) {
        return this.http.post(Url.setNotification + '/' + id, payload).pipe();
    }

    saveUploadDocument(doc: any) {
        this.uploadedDocument.next(doc);
    }

    getUploadDocument() {
        return this.uploadedDocument.asObservable();
    }

    getAllScheduleTemplate() {
        return this.http.get(Url.getAllScheduleTemplate).pipe();
    }

    getScheduleTemplateById(id: number) {
        return this.http.get(Url.getScheduleTemplateById + '?id=' + id).pipe();
    }

    getHtmWeigtage(group_id: any, member_id: any) {
        return this.http.get(Url.htmWeigtage + '/' + group_id + '/' + member_id).pipe();
    }

    saveHtmWeigtage(group_id: any, member_id: any, formData: any) {
        return this.http.put(Url.htmWeigtage + '/' + group_id + '/' + member_id, formData).pipe();
    }

    archivePosition(op_id: any) {
        return this.http.post(Url.archiveOpenPosition + '/' + op_id, {}).pipe();
    }
    clonePosition(op_id: any) {
        return this.http.post(Url.cloneOpenPosition + '/' + op_id, {}).pipe();
    }
    restorePosition(op_id: any) {
        return this.http.put(Url.archiveOpenPosition + '/' + op_id, {}).pipe();
    }

    createTemplate(data: any) {
        return this.http.post(Url.scheduleTemplate, data).pipe();
    }

    updateTemplate(data: any) {
        return this.http.put(Url.scheduleTemplate, data).pipe();
    }

    deleteTemplate(id: any) {
        return this.http.delete(Url.scheduleTemplate + '?id=' + id).pipe();
    }

    getDashboardDataByPositionId(op_id: any) {
        return this.http.get(Url.getArchivedPosition + '/' + op_id).pipe();
    }

    getFitAnalysisByPositionId(op_id: any) {
        return this.http.get(Url.getFitAnalysis + '/' + op_id).pipe();
    }

    getPositionDataByPositionId(op_id: any) {
        return this.http.get(Url.getPositionData + '/' + op_id).pipe();
    }

    autoSavePosition(interviewId: number, data: any): Observable<any> {
        return this.http.post(Url.autoSavePosition + '/' + interviewId, data);
    }
    getAtoSavePosition(interviewId: number): Observable<any> {
        return this.http.get(Url.autoSavePosition + '/' + interviewId);
    }

    saveRequestedPositionList(data: any) {
        this.requestedPositionList.next(data);
    }

    getRequestedPositionList() {
        return this.requestedPositionList.asObservable();
    }

    setIsPositionUpdated(data: any) {
        this.isPositionUpdated.next(data);
    }

    getMultipleQuestions(payload: object) {
        return this.http.post(Url.getQuestionsBySkill, payload);
    }

    getSingleQuestions(payload: object) {
        return this.http.post(Url.getQuestionBySkill, payload).pipe();
    }
    editMultiplePositions(payload: any) {
        return this.http.post(Url.editMultiplePositions, payload).pipe();
    }
    resumeParser(data: any): Observable<any> {
        // debugger;
        return this.http.post(Url.resumeParser, data);
    }
    fetchLocations(q: string) {
        return this.http.get(`https://nominatim.openstreetmap.org/search?format=json&q={${q}}`).pipe();
    }
}