import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import * as Url from './../../app/constants/url';
@Injectable({ providedIn: 'root' })
export class WebSocketService {
  private subject!: WebSocketSubject<any>;
  notificationsSubject: Subject<any> = new Subject<any>();
  currentUser: any;
  constructor(
  ) { }
  public connect() {
    let u:any = localStorage.getItem('currentUser')
    this.currentUser = JSON.parse(u);
    this.subject = webSocket({
      url: Url.websocket + '/' + this.currentUser?.username + '/',
      openObserver: {
        next: () => {
          // console.log('Websocket connected');
        }
      },
      closeObserver: {
        next: () => {
          // console.log('Websocket disconnected');
        }
      }
    });
    this.subject.subscribe(
      msg => {
        console.log('message received: ' + JSON.stringify(msg));
        this.setWebsocketNotifications(msg);
      },
      err => {
        // console.log(err)
      },
      () => console.log('complete')
    );
  }

  public setWebsocketNotifications(msg: any) {
    this.notificationsSubject.next(msg);
  }
  public getWebsocketNotifications() {
    return this.notificationsSubject as Observable<any>;
  }

}
