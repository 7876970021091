<main id="positions-content">
    <app-header style="z-index: 99;position: relative;  "></app-header>
    <div class="widget-wrappers">
        <app-detail-header [clientsList]="selectedClients" [clientCount]="clientCount" [clientNumbers]="passClientData"
            (clientAction)="clientActionFunc()" (searchText)="searchText($event)"></app-detail-header>

        <div class="page-body ">
            <div class="sub-navbar">
                <ul class="mt-4 mb-2">
                    <li (click)="clickPos('All')" class="" [ngClass]="status1 ? 'show' : 'hide'">All <span
                            class="count-span"> {{allClientsData?.length > 9 ? allClientsData?.length : '0' +
                            allClientsData?.length}} </span></li>
                    <li (click)="clickPos('Active')" class="" [ngClass]="status6 ? 'show' : 'hide'">Active <span
                            class="count-span"> {{activeClients?.length > 9 ? activeClients?.length : '0' +
                            activeClients?.length}} </span></li>
                    <li (click)="clickPos('Hold')" class="" [ngClass]="status7 ? 'show' : 'hide'"> <span
                            class="hold-client mr-2"></span>On Hold <span class="count-span"> {{onholdClients?.length > 9
                            ? onholdClients?.length : '0' + onholdClients?.length}} </span></li>
                    <li (click)="clickPos('Inactive')" class="" [ngClass]="status8 ? 'show' : 'hide'"><span
                            class="inactive-client mr-2"></span>Inactive <span class="count-span">
                            {{inactiveClients?.length > 9 ? inactiveClients?.length : '0' + inactiveClients?.length}}
                        </span></li>
                    <li (click)="clickPos('Trial')" class="" [ngClass]="status2 ? 'show' : 'hide'">Trial <span
                            class="count-span"> {{trialPackage?.length > 9 ? trialPackage?.length : '0' +
                            trialPackage?.length}} </span></li>
                    <li (click)="clickPos('Starter')" class="" [ngClass]="status3 ? 'show' : 'hide'">Starter <span
                            class="count-span"> {{starterPackage?.length > 9 ? starterPackage?.length : '0' +
                            starterPackage?.length}} </span></li>
                    <li (click)="clickPos('Growth')" class="" [ngClass]="status4 ? 'show' : 'hide'">Growth <span
                            class="count-span"> {{growthPackage?.length > 9 ? growthPackage?.length : '0' +
                            growthPackage?.length}} </span></li>
                    <li (click)="clickPos('Enterprise')" class="" [ngClass]="status5 ? 'show' : 'hide'">Enterprise <span
                            class="count-span"> {{enterprisePackage?.length > 9 ? enterprisePackage?.length : '0' +
                            enterprisePackage?.length}} </span></li>
                    <li style="float: right;">
                        <div class="dropdown">
                            <span id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="true">
                                Choose Columns <fa-icon class="ml-2" [icon]="faColumns"
                                    [styles]="{'stroke': 'var(--primary-200)', 'color': 'var(--primary-200)','font-size': '20px'}"></fa-icon>
                            </span>
                            <ul class="dropdown-menu p-3 col-ul" aria-labelledby="dropdownMenuButton2">
                                <h5>Select Columns</h5>
                                <li class="dropdown-item">
                                    <input class="c-checkbox" type="checkbox" id='col1'
                                        [(ngModel)]="clientscheckboxes[0].checked" (change)="selectColumns(0,$event)">
                                    <label for="col1"> Client Admin</label>
                                </li>
                                <li class="dropdown-item">
                                    <input class="c-checkbox" type="checkbox" id='col2'
                                        [(ngModel)]="clientscheckboxes[1].checked" (change)="selectColumns(1,$event)">
                                    <label for="col2">Subscription</label>
                                </li>
                                <li class="dropdown-item">
                                    <input class="c-checkbox" type="checkbox" id='col3'
                                        [(ngModel)]="clientscheckboxes[2].checked" (change)="selectColumns(2,$event)">
                                    <label for="col3"> Payment</label>
                                </li>
                                <li class="dropdown-item">
                                    <input class="c-checkbox" type="checkbox" id='col4'
                                        [(ngModel)]="clientscheckboxes[3].checked" (change)="selectColumns(3,$event)">
                                    <label for="col4"> No. of Candidates</label>
                                </li>
                                <li class="dropdown-item">
                                    <input class="c-checkbox" type="checkbox" id='col5'
                                        [(ngModel)]="clientscheckboxes[4].checked" (change)="selectColumns(4,$event)">
                                    <label for="col5"> Interviews Conducted</label>
                                </li>
                                <li class="dropdown-item">
                                    <input class="c-checkbox" type="checkbox" id='col6'
                                        [(ngModel)]="clientscheckboxes[5].checked" (change)="selectColumns(5,$event)">
                                    <label for="col6"> No. of Open Positions</label>
                                </li>
                                <li class="dropdown-item">
                                    <input class="c-checkbox" type="checkbox" id='col7'
                                        [(ngModel)]="clientscheckboxes[6].checked" (change)="selectColumns(6,$event)">
                                    <label for="col7">Last Upgraded Date</label>
                                </li>
                                <li class="dropdown-item">
                                    <input class="c-checkbox" type="checkbox" id='col8'
                                        [(ngModel)]="clientscheckboxes[7].checked" (change)="selectColumns(7,$event)">
                                    <label for="col8">Payment Due Date</label>
                                </li>
                                <li (click)="colClicked()" class="dropdown-item">
                                    <input class="c-checkbox" type="checkbox" id='col9'
                                        [(ngModel)]="clientscheckboxes[8].checked" (change)="selectColumns(8,$event)">
                                    <label for="col9"> Phone Number</label>
                                </li>
                                <li *ngIf="total_counter >5" class="dropdown-item"><span style="color: red;">*Choose
                                        Maximun 5 columns</span></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="widget-box">
                <div class="custom-scroll-bar" style="height: 700px;">
                    <table class="table table-striped table-bordered">
                        <tbody>
                            <tr style="background-color: #EBEBEB;">
                                <td [class.active-sorted-table]="sortedTable==='company_name'" scope="col">
                                    <input class="c-checkbox" type="checkbox" id='client' [(ngModel)]="selectAll"
                                        (change)="selectAllItems()">
                                    <!-- <input class="c-checkbox mr-3" type="checkbox" id="position"  [(ngModel)]="selectAll" (change)="selectAllItems()"> -->
                                    CLIENT NAME
                                    <span class="float-right cursor-pointer"
                                        (click)="sortTable('company_name')"><fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon>
                                    </span>
                                </td>
                                <td [class.active-sorted-table]="sortedTable==='client_admin'"
                                    *ngIf="clientscheckboxes[0].checked" scope="col">CLIENT ADMIN <span
                                        class="float-right cursor-pointer" (click)="sortTable('client_admin')"><fa-icon
                                            [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <td [class.active-sorted-table]="sortedTable==='subscription'"
                                    *ngIf="clientscheckboxes[1].checked" scope="col">SUBSCRIPTION <span
                                        class="float-right cursor-pointer" (click)="sortTable('subscription')"> <fa-icon
                                            [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <td [class.active-sorted-table]="sortedTable==='payment'"
                                    *ngIf="clientscheckboxes[2].checked" scope="col">
                                    PAYMENT <span class="float-right cursor-pointer"
                                        (click)="sortTable('payment')"><fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <td [class.active-sorted-table]="sortedTable==='no_of_candidates'"
                                    *ngIf="clientscheckboxes[3].checked" scope="col">NO. OF CANDIDATES <span
                                        class="float-right cursor-pointer"
                                        (click)="sortTable('no_of_candidates')"><fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <td [class.active-sorted-table]="sortedTable==='interviews_conducted'"
                                    *ngIf="clientscheckboxes[4].checked" scope="col">INTERVIEWS CONDUCTED <span
                                        (click)="sortTable('interviews_conducted')"
                                        class="float-right cursor-pointer"><fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>

                                <td [class.active-sorted-table]="sortedTable==='no_of_position'"
                                    *ngIf="clientscheckboxes[5].checked" scope="col">No. of Open Positions<span
                                        (click)="sortTable('no_of_position')"
                                        class="float-right cursor-pointer"><fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <td [class.active-sorted-table]="sortedTable==='last_updated'"
                                    *ngIf="clientscheckboxes[6].checked" scope="col">Last Upgraded Date <span
                                        (click)="sortTable('last_updated')" class="float-right cursor-pointer"><fa-icon
                                            [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <td [class.active-sorted-table]="sortedTable==='payment_due'"
                                    *ngIf="clientscheckboxes[7].checked" scope="col">
                                    Payment Due Date <span class="float-right cursor-pointer"
                                        (click)="sortTable('payment_due')"><fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <td [class.active-sorted-table]="sortedTable==='phone_no'"
                                    *ngIf="clientscheckboxes[8].checked" scope="col">
                                    Phone Number<span class="float-right cursor-pointer"
                                        (click)="sortTable('phone_no')"><fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>

                            </tr>



                            <tr *ngFor="let pos of clientsData | searchFilter: query : 'company_name' ; let i = index ">
                                <th style="text-align: left;">
                                    <div class="client-text ">
                                        <input class="c-checkbox" (change)="clientSelect(pos,clientsItem[i])"
                                            [checked]="clientsItem[i]" [(ngModel)]="clientsItem[i]" type="checkbox"
                                            id="client" [name]="pos.id" [value]="clientsItem[i]">
                                        <img class="client-logo"
                                            [src]="pos.logo && pos.logo !== 'None' ? imgBaseUrl + pos.logo : './../../../assets/images/no-image.jpg'">
                                        <span class="ml-3">
                                            <span *ngIf="pos.status == 'hold'" class="hold-client mr-2"></span>
                                            <span *ngIf="pos.status == 'inactive'" class="inactive-client  mr-2"></span>
                                            <span *ngIf="pos.status != 'hold' && pos.status != 'inactive'"
                                                class="  mr-3"></span>
                                            <a class="pos-title" href="javascript:void(0)"
                                                (click)="selectClient(pos)">{{transformText(pos.company_name)|
                                                titlecase}} </a>
                                        </span>
                                    </div>
                                </th>
                                <td *ngIf="clientscheckboxes[0].checked">{{pos.client_admin | titlecase}}</td>
                                <td *ngIf="clientscheckboxes[1].checked">{{pos.subscription || 'Trial'}}</td>
                                <td *ngIf="clientscheckboxes[2].checked">{{'$' + pos.payment}}</td>
                                <td *ngIf="clientscheckboxes[3].checked">{{pos.no_of_candidates}}</td>
                                <td *ngIf="clientscheckboxes[4].checked">{{pos.interviews_conducted}}</td>

                                <td *ngIf="clientscheckboxes[5].checked">{{pos.no_of_position}}</td>
                                <td *ngIf="clientscheckboxes[6].checked">{{pos.last_updated | formatDate}}</td>
                                <td *ngIf="clientscheckboxes[7].checked">{{pos.payment_due | formatDate}}</td>
                                <td *ngIf="clientscheckboxes[8].checked">{{pos.phone_no}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</main>