import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import * as CronofyElements from "cronofy-elements";
import * as Url from './../../constants/url';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.services';
import {
  faUserPlus,
  faFlagCheckered,
  faHourglassStart,
  faCalendarCheck,
  faComments,
  faExclamationCircle,
  faCarrot,
  faCamera
} from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import * as urls from './../../constants/url';
import { CandidateService } from 'src/app/services/candidate.service';
@Component({
  selector: 'app-my-calender',
  templateUrl: './my-calender.component.html',
  styleUrls: ['./my-calender.component.css']
})
export class MyCalenderComponent implements OnInit {
  faUserPlus = faUserPlus;
  faFlagCheckered = faFlagCheckered;
  faHourglassStart = faHourglassStart;
  faCalendarCheck = faCalendarCheck;
  faComments = faComments;
  faExclamationCircle = faExclamationCircle;
  faCalendar = faCalendarAlt;
  faCarrot = faCarrot;
  faCamera = faCamera;
  calend: any;
  cal_sub: any;
  response: any;
  token: any
  currentUserRoleCode: any;
  REDIRECT_URI: any;
  ori: any;
  showAvailabilityCalendar: boolean = false;
  showOurCalendar: boolean = true;
  calendarData: any;
  viewDate: Date = new Date();
  htmCalendarData: any;
  posCalendarData: any;
  showHtmInfo: boolean = false;
  cl_Id: any;
  showAvalibilityViewer: boolean = false;
  constructor(private userService: UserService,
    private router: Router,
    private authService: AuthService,
    private candidateService: CandidateService) { }

  ngOnInit(): void {
    // this.getMyCalendarData()
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    var y = window.location.href;
    this.ori = urls.uiBaseUrl
    this.cl_Id = urls.CLIENT_ID
    this.ori = this.ori.substring(0, this.ori.length - 1);
    this.REDIRECT_URI = this.ori + '/ca/my-calender';
    localStorage.removeItem("currentPosition");
    if (y.includes("my-calender?")) {
      let ind1 = y.indexOf("=");
      let ind2 = y.indexOf("&");
      this.token = y.substring(ind1 + 1, ind2);
      this.getAuthCalendar()
    }
    // else{
    //   this.userService.getCronofyElementToken(this.ori).subscribe((data:any)=>{
    //     this.response = data.data.element_token;
    //     this.showCronofyCalendar(this.response.token)
    //   })
    // }
  }

  getAuthCalendar() {
    this.userService.getScheduleCode(this.token, this.REDIRECT_URI).subscribe((res: any) => {
      this.userService.getCronofyCalender().subscribe((val: any) => {
        this.calend = val.calendars;
        this.cal_sub = val.sub;
        console.log("This is sub**********" + this.cal_sub)

        this.userService.getCronofyElementToken(this.ori).subscribe((data: any) => {
          this.response = data.data.element_token;
          this.showCronofyCalendar(this.response.token)
        })
      })
    })
  }

  showCronofyCalendar(token: any) {
    CronofyElements.AvailabilityRules({
      element_token: token,
      target_id: "cronofy-availability-rules",
      availability_rule_id: "work_hours",
      config: {
        start_time: "08:00",
        end_time: "18:00",
        duration: 60
      },
      styles: {
        colors: {
          available: "white",
          unavailable: "grey"
        },
        prefix: "custom-name"
      },
      tzid: "Etc/UTC"
    })


  }
  showCronofyAvailibityViewer(token: any) {
    CronofyElements.AvailabilityViewer({
      element_token: token,
      target_id: "cronofy-availability-viewer",
      availability_query: {
        participants: [
          {
            members: [
              {
                sub: this.cal_sub,
                managed_availability: true
              }
            ],
            required: "all"
          },
        ],
        required_duration: { minutes: 30 },
        query_periods: [
          { start: "2024-08-13T09:00:00Z", end: "2024-08-30T17:00:00Z" }
        ],
        response_format: "slots"
      },
      config: {
        start_time: "09:00",
        end_time: "19:00",
        interval: 30,
        mode: 'no_confirm',
        allow_expansion:true
      },
      styles: {
        prefix: "custom-name"
      },
      callback: notification => console.log("callback", notification),
      tzid : "Etc/UTC"
    });
  }
  availAction(num: any) {
    if (num == 1) {
      this.goToOurCalendar()
    }
    if (num == 2) {
      this.manageAvalibility();
    }
    if (num == 3) {
      this.myAvalibilityViewer();
    }
  }
  htmDataFromCalendar(data: any) {
    this.posCalendarData = null;
    if (data === 'clear') {
      this.showHtmInfo = false;
      this.htmCalendarData = null;
      return
    }
    this.showHtmInfo = true;
    this.htmCalendarData = data
  }
  posDateDataFromCalendar(data: any) {
    this.htmCalendarData = null;
    if (data === 'clear') {
      this.showHtmInfo = false;
      this.posCalendarData = null;
      return
    }
    this.showHtmInfo = true;
    this.posCalendarData = data;
  }
  giveMeRole(str: any) {
    if (str == '"is_sm"') {
      return 'SM'
    }
    if (str == '"is_ca"') {
      return 'CA'
    }
    if (str == '"is_htm"') {
      return 'HTM'
    }
    else {
      return "NA"
    }
  }
  myAvalibilityViewer() {
    this.userService.getAccessConfirmation().subscribe((msg: any) => {
      if (msg.msg == 'invalid token') {
        let CLIENT_ID = this.cl_Id;
        let u = this.ori
        let REDIRECT_URI = u + '/ca/my-calender';
        let SCOPE = 'read_write';
        var url = 'https://app.cronofy.com/oauth/authorize?response_type=code&client_id=' + CLIENT_ID +
          '&redirect_uri=' + REDIRECT_URI + '&scope=' + SCOPE
        window.open(url, "_blank")
        this.showAvailabilityCalendar = false;
        this.showOurCalendar = false
        this.showAvalibilityViewer = true
      }
      else {
        this.showAvailabilityCalendar = false;
        this.showOurCalendar = false
        this.showAvalibilityViewer = true
        this.userService.getCronofyElementToken(this.ori).subscribe((data: any) => {
          this.response = data.data.element_token;
          this.cal_sub = this.response.subs[0]
          console.log("This is sub**********" + this.cal_sub)
          this.showCronofyAvailibityViewer(this.response.token)
        })
      }
    })
  }
  manageAvalibility() {
    this.userService.getAccessConfirmation().subscribe((msg: any) => {
      if (msg.msg == 'invalid token') {
        let CLIENT_ID = this.cl_Id;
        let u = this.ori
        let REDIRECT_URI = u + '/ca/my-calender';
        let SCOPE = 'read_write';
        var url = 'https://app.cronofy.com/oauth/authorize?response_type=code&client_id=' + CLIENT_ID +
          '&redirect_uri=' + REDIRECT_URI + '&scope=' + SCOPE
        window.open(url, "_blank")
        this.showAvailabilityCalendar = true;
        this.showOurCalendar = false
        this.showAvalibilityViewer = false
      }
      else {
        this.showAvailabilityCalendar = true;
        this.showOurCalendar = false
        this.showAvalibilityViewer = false
        this.userService.getCronofyElementToken(this.ori).subscribe((data: any) => {
          this.response = data.data.element_token;
          this.cal_sub = this.response.subs[0]
          console.log("This is sub**********" + this.cal_sub)
          this.showCronofyCalendar(this.response.token)
        })
      }
    })

  }
  goToOurCalendar() {
    this.showAvailabilityCalendar = false;
    this.showOurCalendar = true;
    this.showAvalibilityViewer = false
  }
  addGoogleAccount() {
    let CLIENT_ID = this.cl_Id;
    let u = this.ori
    let REDIRECT_URI = u + '/ca/my-calender';
    let SCOPE = 'read_write';
    var url = 'https://app.cronofy.com/oauth/authorize?response_type=code&client_id=' + CLIENT_ID +
      '&provider_name=google&redirect_uri=' + REDIRECT_URI + '&scope=' + SCOPE
    window.open(url, "_top")
    this.showAvailabilityCalendar = true;
    this.showOurCalendar = false
  }
  addAppleAccount() {
    let CLIENT_ID = this.cl_Id;
    let u = this.ori
    let REDIRECT_URI = u + '/ca/my-calender';
    let SCOPE = 'read_write';
    var url = 'https://app.cronofy.com/oauth/authorize?response_type=code&client_id=' + CLIENT_ID +
      '&provider_name=apple&redirect_uri=' + REDIRECT_URI + '&scope=' + SCOPE
    window.open(url, "_top")
    this.showAvailabilityCalendar = true;
    this.showOurCalendar = false
  }
  addOffice365Account() {
    let CLIENT_ID = this.cl_Id;
    let u = this.ori
    let REDIRECT_URI = u + '/ca/my-calender';
    let SCOPE = 'read_write';
    var url = 'https://app.cronofy.com/oauth/authorize?response_type=code&client_id=' + CLIENT_ID +
      '&provider_name=office365&redirect_uri=' + REDIRECT_URI + '&scope=' + SCOPE
    window.open(url, "_top")
    this.showAvailabilityCalendar = true;
    this.showOurCalendar = false
  }
  addExchangeAccount() {
    let CLIENT_ID = this.cl_Id;
    let u = this.ori
    let REDIRECT_URI = u + '/ca/my-calender';
    let SCOPE = 'read_write';
    var url = 'https://app.cronofy.com/oauth/authorize?response_type=code&client_id=' + CLIENT_ID +
      '&provider_name=exchange&redirect_uri=' + REDIRECT_URI + '&scope=' + SCOPE
    window.open(url, "_top")
    this.showAvailabilityCalendar = true;
    this.showOurCalendar = false
  }
  addOutlookAccount() {
    let CLIENT_ID = this.cl_Id;
    let u = this.ori
    let REDIRECT_URI = u + '/ca/my-calender';
    let SCOPE = 'read_write';
    var url = 'https://app.cronofy.com/oauth/authorize?response_type=code&client_id=' + CLIENT_ID +
      '&provider_name=live_connect&redirect_uri=' + REDIRECT_URI + '&scope=' + SCOPE
    window.open(url, "_top")
    this.showAvailabilityCalendar = true;
    this.showOurCalendar = false
  }
  isDateToday(d: string) {
    var inputDate = new Date(d);
    var todaysDate = new Date();
    if (inputDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
      return true
    }
    return false
  }
  startInterview() {
    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree([this.authService.currentUserRoleCode, 'interview-screen', this.htmCalendarData.position_id, this.htmCalendarData.candidate_id, this.htmCalendarData.id])
    // );
    // window.open(url, "_blank");
    // this.candidateService.setCandidateData(cand);
    // const op_id = this.activatedRoute.snapshot.params['positionId'];
    const interviewId = this.htmCalendarData.id;
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['interview', interviewId])
    );
    window.open(url, "_blank");
  }
}
