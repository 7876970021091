<footer class="footer">
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-6">
                <ul class="footer-menu float-left">
                    <li><a class="savebtn btn" routerLink="/dashboard">Dashboard</a></li>
                    <li *ngIf="(userRole =='Superuser')"><a class="savebtn btn" routerLink="/candidateHome">Candidates</a></li>
                    <li *ngIf="(userRole =='Superuser')"><a class="savebtn btn" routerLink="/dashboard/createAccount">Account</a></li>
                    <li *ngIf="(userRole =='Superuser') || (userRole =='Hiring Manager')"><a class="savebtn btn" routerLink="/dashboard/hiringDepartment">Hiring Team</a></li>
                    <li *ngIf="(userRole =='Superuser') || (userRole =='Hiring Manager')"><a class="savebtn btn" routerLink="/dashboard/htm">HTM</a></li>
                    <li *ngIf="(userRole =='Superuser') || (userRole =='Hiring Manager')"><a class="savebtn btn" routerLink="/dashboard/department">Department</a></li>
                    <li *ngIf="(userRole =='Superuser') || (userRole =='Hiring Manager')"><a class="savebtn btn" routerLink="/dashboard/htm">HTM</a></li>
                    <li *ngIf="(userRole =='Superuser') || (userRole =='Hiring Manager')"><a class="savebtn btn" routerLink="/dashboard/department">Department</a></li>
                </ul>
            </div> -->
            <!--<div class="col-md-6 text-right">
                <ul class="footer-menu">
                     <li><a href=""><i class="fa fa-handshake-o"></i></a></li>
                    <li><a href=""><i class="fa fa-calendar"></i></a></li>
                    <li><a href=""><i class="fa fa-pencil"></i></a></li>
                    <li><a href=""><i class="fa fa-search"></i></a></li>
                    <li><a class="addLink" href=""><i class="fa fa-plus"></i></a></li> 
                    <li><a class="savebtn btn" routerLink="/dashboard/createPosition2">Next</a></li>
                </ul>
            </div>-->
        </div>
    </div>
</footer>