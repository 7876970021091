import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import {
  faCalendarAlt,
  faEnvelope,
  faEye
} from '@fortawesome/free-solid-svg-icons';
import { faListAlt, faPlus, faStar, faThumbsUp, faThumbsDown, faHandPointDown, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/core/toast/toast-service';
import { AuthService } from 'src/app/services/auth.services';

import { CandidateService } from 'src/app/services/candidate.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { PositionService } from 'src/app/services/position.service';
import { UserService } from 'src/app/services/user.service';

import { AssociateCandidateComponent } from '../associate-candidate/associate-candidate.component';
import { SetAvailabilityComponent } from '../set-availability/set-availability.component';
import * as Url from './../../constants/url';
@Component({
  selector: 'app-clients-position',
  templateUrl: './clients-position.component.html',
  styleUrls: ['./clients-position.component.css'],
})
export class ClientsPositionComponent implements OnInit {
  public selectedCandidate: any = {};
  public hrGroupList: any = {};
  public candidateList: any[] = [];
  public faThumbup = faThumbsUp;
  public faThumbdown = faThumbsDown;
  public faThumbhold = faHandPointDown;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  public SelCandidate: any;
  public SelMember: any;
  public showTopCandidate: boolean = true;
  public candidateAverageData: any = {};
  public noData: boolean = false;
  public memberThumbVal: string = '';
  public candidateDetailData: any = {};
  public faEnvelope = faEnvelope;
  public faListAlt = faListAlt;
  public faStar = faStar;
  public faPlus = faPlus;
  public faEye = faEye;
  public userRole: string = '';
  public faCalendar = faCalendarAlt;
  public showSummary: boolean = false;
  public imgBaseUrl = Url.imgBaseUrl;
  public openPositionAvailable = false;
  currentUserRoleCode: any;
  subscription$: any;
  subscription2$: any;
  showDashboard = true;
  isHiringManager: any;

  cheveron1Show = true;
  cheveron2Show = true;
  cheveron3Show = true;
  cheveron4Show = true;
  cheveron5Show = true;
  cheveron6Show = true;
  cheveron7Show = true;
  cheveron8Show = true;
  candidateScore: any;
  currentPosition: any;
  barChartData: any;
  barChartColors = ['#a7a1a1','#c16060', '#2f76d1', '#ec9a25', '#25c4ec', '#252bec', '#000000', '#ec25ce', '#b0bbde', '#009688', '#9c27b0', '#ffc107', '#766943', '#795548', '#d18a85', '#d1b86b'];
  @ViewChild("modalRoot") modalRoot!: TemplateRef<any>;
  quesAnsData: any;
  selectedQuesData: any;
  selectedHtmsObj:any = [];
  activeCandidateList: any[] = [];
  @ViewChild('content') deletePosModal: any;
  constructor(
    private candidateService: CandidateService, 
    private router: Router, 
    private modalService: NgbModal, 
    private dashboardsService: DashboardService, 
    private authService: AuthService, 
    private toastService: ToastService,
    private userService: UserService,
    private positionService: PositionService) {}

  ngOnInit(): void {
    let uerdata:any = localStorage.getItem('currentUser');
    this.isHiringManager = localStorage.getItem('is_hiring_manager') === 'true';
    this.currentPosition = localStorage.getItem('currentPosition');
    uerdata = JSON.parse(uerdata);
    if(uerdata) {
      this.userRole = uerdata?.role;
      if(this.userRole === 'Hiring Member'){
        this.showSummary = true;
        this.updateProTip();
      }
    }
    this.hrGroupList = [];
    this.currentUserRoleCode = this.authService.currentUserRoleCode;

    this.subscription2$ = this.candidateService.getSelectedCandidate().subscribe((val) => {
      if (val.id) {
        //changes to show dashboard
        this.showDashboard = true;
        this.hideProgress();
        this.openPositionAvailable = false;
        this.currentPosition = localStorage.getItem('currentPosition');
        this.selectedCandidate =  val.client ? val : val?.open_position_data?.filter((el: any) => el?.id == this.currentPosition)[0];
        if(val.open_positions || val.client) {
          this.currentPosition = localStorage.getItem('currentPosition');
          if(this.currentPosition) {
            this.getPositionsCandidates(this.currentPosition);
          }
        }
        setTimeout(() => {
          this.currentPosition = localStorage.getItem('currentPosition'); 
        }, 500);
      }
    });
  }

  openDashboard() {
    this.showDashboard = true;
  }

  showProgress(){
    // if(this.userRole !== 'Human Resource') {
      this.showSummary = !this.showSummary;
      this.updateProTip();
    // }
  }

  showProgressEvent(event: boolean){
    this.showSummary = event;
    this.updateProTip();
  }

  updateProTip() {
    this.userService.getProTip().subscribe((res: any) => {
      this.userService.setproTipUpdated(null);
      this.userService.setproTipUpdated(res.data);
    });
  }

  hideProgress(){
    this.showSummary = false;
    this.showTopCandidate = true;
    this.updateProTip();
  }

  activeCandidate(item: any) {
    this.SelCandidate = item;
    this.candidateAverageData = this.SelCandidate.marks;
    this.candidateAverageData['final_avg_marks'] =
    this.SelCandidate.final_avg_marks;
    this.showTopCandidate = false;
    this.noData = false;
    this.SelMember = null;
    this.selectedHtmsList = [];
    if(this.userRole !=='Superuser' && this.userRole !=='Client Admin' && this.userRole !=='Senior Manager' && this.userRole !=='Account Manager' && this.userRole !=='Hiring Manager' && this.userRole !=='Hiring Team Member' && this.userRole !=='Hiring Member' && this.userRole !=='Human Resource' ) {
      this.candidateDetails(item);
    }
  }

  selectCandidate(e: any) {
    this.activeCandidate(e);
  }

  candidateDetails(item: any){ 
    if(item){
      if(item.requested || item.withdrawed) {
        return;
      }
      this.candidateService.setCandidateData(item);
      if(this.userRole === 'Hiring Member') {
        this.router.navigate([this.authService.currentUserRoleCode + '/position/candidateProfile/' + item.op_id + '/' + item.candidate_id]);
      } else {
        this.router.navigate([this.authService.currentUserRoleCode + '/position/candidateProfile']);
      }
    }
  }
  selectedHtmsList: any = [];
  activeMember(item: any) {
    this.noData = false;
    this.SelMember = item;
    if(this.selectedHtmsList.includes(item.id)) {
      const htmIndex = this.selectedHtmsList.indexOf(item.id);
      this.selectedHtmsList.splice(htmIndex, 1);
      this.selectedHtmsObj = this.selectedHtmsObj.filter((el: any) => el.id !== item.id);
    } else {
      this.selectedHtmsList.push(item.id);
      this.selectedHtmsObj.push(item);
    }
    if(this.selectedHtmsObj.length === 1) {
      this.SelMember = this.selectedHtmsObj[0];
    }
    this.memberThumbVal = '';
    if(this.SelCandidate?.candidate_id){
      const op_id = localStorage.getItem('currentPosition');
      if(this.selectedHtmsList.length === 1) {
        this.candidateService.getCandidateScore(this.SelCandidate?.candidate_id, this.selectedHtmsList[0], op_id)
        .subscribe((val: any) => {
          if (val.marks_given || val.marks_given === undefined) {
            this.candidateScore = val;
            this.candidateAverageData = val.marks;
            this.memberThumbVal = val.flag ? val.flag : '';
            this.noData = false;
            // if (!this.candidateAverageData['final_avg_marks']) {
              // this.candidateAverageData['final_avg_marks'] = val.final_avg_marks;
            // }
          } else {
            this.noData = true;
            this.candidateAverageData = {};
          }
        });
      } else {
        this.candidateService.compareCandidateVoting(op_id, this.SelCandidate.candidate_id, this.selectedHtmsList).subscribe((res: any) => {
          this.quesAnsData = res.quesAnsData;
          this.initiateGoogleChart(res.voting_data);
        });
      }
    }    
  }

  getPositionsCandidates(id: any) {
    this.candidateService.getPositionsCandidates(id).subscribe((val: any) => {
      if (val) {
        this.candidateList = val.sort((el1: any, el2: any) => el1.withdrawed - el2.withdrawed);
        this.candidateList = val.sort(function(x: any, y:any) { return x.requested - y.requested });
        this.activeCandidateList = val.filter((el: any) => !el.withdrawed);
      }
    });
  }

  openAssociateCandidate() {
    const modalRef = this.modalService.open(AssociateCandidateComponent, { size: 'xl' });
    modalRef.componentInstance.name = 'associate';
  }

  openSetAvailability() {
    const modalRef = this.modalService.open(SetAvailabilityComponent, { size: 'lg' });
    modalRef.componentInstance.name = 'scheduler';
  }

  ngOnDestroy() {
    if(this.subscription$) {
      this.subscription$.unsubscribe();
    }
    if(this.subscription2$) {
      this.subscription2$.unsubscribe();
    }
  }

  withdrawHTM(member: any) {
    const group_id = this.hrGroupList[0].group_id;
    this.candidateService.withdrawHTM(group_id, member.id).subscribe((res: any) => {
      this.showSuccess('Hiring team member withdrawed successfully');
    });
  }

  restoreHTM(member: any) {
    const group_id = this.hrGroupList[0].group_id;
    this.candidateService.restoreHTM(group_id, member.id).subscribe((res: any) => {
      this.showSuccess('Hiring team member restored successfully');
    });
  }

  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  openHtmWeightage(htm: any) {
    localStorage.setItem('selectedHtmId', htm?.id);
    this.router.navigate([this.authService.currentUserRoleCode + '/position/htmWeightage'], { state: { htm: htm, canditate: this.candidateList }});
  }

  archivePosition() {
    const openPositionId = localStorage.getItem('currentPosition');
    this.positionService.archivePosition(openPositionId).subscribe(res => {
      this.toastService.show('Position marked as on hold successfully.', {
        classname: 'bg-success text-light',
        delay: 5000,
      });
      window.location.reload();
    });
  }

  
  initiateGoogleChart(chartData: any) {
    this.barChartData = {
      chartType: 'BarChart',  // or chartType: 'PieChart'
      dataTable: 
      chartData,
      // [
      //   ['Skill', 'Average', 'Nelly Korda Votes', 'Justin Thomas Votes', 'Nigel Ng Votes'],
      //   ['Python', 8.1, 7.2, 5.4, 6.8],
      //   ['React', 5.4, 5.1, 4.2, 4.5],
      //   ['AWS', 6.3, 7.2, 3.4, 6.8],
      //   ['Citrix', 8.2, 7.1, 5.4, 6.7],
      // ],
      //firstRowIsData: true,
      options: {
        title: 'Voting Analysis',
        height: 700,
        legend: { position: 'top', maxLines: 3, fontSize: 11 },
        colors: this.barChartColors,
        vAxis: {title: 'Required Skills', titleTextStyle: {color: '#FF0000'}},
        hAxis: {
          title: '',
          titleTextStyle: {fontSize: 11},
          ticks: [{v:0, f:'Poor'}, {v:2.5, f:'Below Average'}, {v:5, f:'Ability To Learn'}, {v:7.5, f:'Above Average'}, {v:10, f:'Excellent'}],
          viewWindow:{
              max:10,
              min:0
          }
        }
      }
    };
  }

  chartSelect(e: any) {
    console.log(e);
    if(e.column === 1) {
      this.selectedQuesData = this.quesAnsData.filter((el: any) => el.quesFor === e.selectedRowValues[0])[0];
      const modalRef = this.modalService.open(this.modalRoot, { size: 'lg' });
    }
  }

  mouseOver(e: any) {
    console.log(e);
  }

  mouseOut(e: any) {
    console.log(e);
  }

  actionEmitter(e:any) {
    if(e === 'accociate-candidate') {
      this.openAssociateCandidate();
    } else if(e ==='3-col-page') {
      this.showProgress();
    } else if(e ==='edit') {
      this.editPosition(this.selectedCandidate.id, this.selectedCandidate.hiring_group);
    } else if(e ==='clone') {
      this.duplicatePosition(this.selectedCandidate.id, this.selectedCandidate.hiring_group);
    } else if(e ==='delete') {
      this.openSm(this.deletePosModal,this.selectedCandidate.id);
    } else if(e ==='hold') {
      this.archivePosition();
    } else if(e === 'vote') {
      // this.candidateDetails(this.candidateList[0]);
      if(this.userRole === 'Hiring Manager') {
        this.router.navigate([this.authService.currentUserRoleCode + '/position/candidateProfile/' + this.candidateList[0].op_id + '/' + this.candidateList[0].candidate_id]);
      } else {
        this.router.navigate([this.authService.currentUserRoleCode + '/position/candidateProfile']);
      }
    } else if(e === 'schedule') {
      this.openHtmWeightage(this.hrGroupList[0]?.hiring_manager);
    }
  }

  editPosition(id: any, hiringGroupId: any) {
    localStorage.setItem('editPosition', id);
    localStorage.setItem('hiringGroupId', hiringGroupId);
    // setTimeout(() => {
      this.router.navigate([this.authService.currentUserRoleCode + '/position/createPosition1'], { queryParams: {id: id}});
    // }, 500);
  }

  duplicatePosition(id: any, hiringGroupId: any) {
    this.positionService.duplicatePosition(id).subscribe((res: any) => {
      res.isCloned = true;
      const data = res;
      this.positionService.setIsPositionUpdated(data);
      this.showCloneSuccess();
      this.modalService.dismissAll();
      localStorage.setItem('editPosition', res.id);
      localStorage.setItem('hiringGroupId', res.group_id);
      this.router.navigate([this.authService.currentUserRoleCode + '/position/createPosition1'], { queryParams: {id: res.id}});
    })
  }

  showCloneSuccess() {
    this.toastService.show('Position added in Draft Positions.', {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  openSm(content: any, id: any) {
    this.currentPosition = id;
    this.modalService.open(content, { size: 'md' });
  }

  trashPosition() {
    this.positionService.trashPosition(this.currentPosition, true).subscribe((client: any) => {
      if (client) {
        this.showSuccess('Position trashed successfully.');
        this.modalService.dismissAll();
        client.isDelete = true;
        this.positionService.setIsPositionUpdated(client);
      }
    });
  }
}
