import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

import { faCalendar, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faExclamation, faCalendarDay, faCalendarTimes, faCalendarWeek, faPlus, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AssociateCandidateComponent } from '../associate-candidate/associate-candidate.component';
import { SetAvailabilityComponent } from '../set-availability/set-availability.component';
import { faStar, faShieldAlt, faArchive, faEdit, faThumbsUp, faThumbsDown, faHandPointDown, faTrash, faClock, faHandsHelping, faInfo, faChartLine, faTimes, faEye, faUsers, faThLarge, faSearchPlus, faDollarSign, faHandPaper, faPeopleArrows, faIdCard, faExclamationCircle, faPencilAlt, faPhoneAlt, faFile, faDownload, faSave } from '@fortawesome/free-solid-svg-icons';
import { DashboardService } from 'src/app/services/dashboard.service';
import { getClientAdminDashboard, getDashboardData, imgBaseUrl } from 'src/app/constants/url';
import { AuthService } from 'src/app/services/auth.services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CandidateService } from 'src/app/services/candidate.service';
import { ToastService } from 'src/app/core/toast/toast-service';
import { UserService } from 'src/app/services/user.service';
import { NgxMasonryComponent } from 'ngx-masonry';
import * as Url from './../../constants/url';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import { PositionService } from '../../services/position.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sub-dashboard',
  templateUrl: './sub-dashboard.component.html',
  styleUrls: ['./sub-dashboard.component.css']
})
export class SubDashboardComponent implements OnInit {
  //TODO: to make dynamic
  showFirstCardInfo = false;
  enabledCards: any = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  detailedInfoCardIndex: any = [];

  @ViewChild(NgxMasonryComponent) masonry!: NgxMasonryComponent;

  @Input() item: any;
  @Input() count: any;
  public imgBaseUrl = imgBaseUrl;
  public userRole: string = '';
  public faPlus = faPlus;
  public faCalendar = faCalendarAlt;
  public faHandsHelping = faHandsHelping;
  public activeClient: any = 0;
  public openPosition: any = 0;
  public dashboardData: any;
  faTrash = faTrash;
  faEnvelope = faEnvelope;
  faInfo = faInfo;
  faEye = faEye;
  faTimes = faTimes;
  faChartLine = faChartLine;
  faUsers = faUsers;
  faSearchPlus = faSearchPlus;
  faThLarge = faThLarge;
  faDollarSign = faDollarSign;
  faHandPaper = faHandPaper;
  faPeopleArrows = faPeopleArrows;
  faIdCard = faIdCard;
  faEdit = faEdit;
  faArchive = faArchive;
  faExclamation = faExclamation;
  faShieldAlt = faShieldAlt;
  faStar = faStar;
  faExclamationCircle = faExclamationCircle;
  faPencilAlt = faPencilAlt;
  faPhoneAlt = faPhoneAlt;
  faFile = faFile;
  faDownload = faDownload;
  faSave = faSave;
  candidateData: any;
  linkedInData: any;
  public imageBaseUrl = Url.imgBaseUrl;
  toggleResume = false;
  toggleReference = false;
  calendarData: any;
  minValue: number = 500;
  maxValue: number = 500000;
  options: Options = {
    floor: 0,
    ceil: 500000,
    step: 100,
    enforceStep: false,
    enforceRange: false,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min Salary:</b> $' + (isNaN(value) ? 0 : value);
        case LabelType.High:
          return '<b>Max Salary:</b> $' + (isNaN(value) ? 0 : value);
        default:
          return '$' + (isNaN(value) ? 0 : value);
      }
    }
  };
  public offerAcceptedChart: any = {
    chartType: "ColumnChart",
    dataTable: [

    ],
    options: {
      chart: {
        title: 'Offers Accepted last 12 months',
        // subtitle: 'Sales, Expenses, and Profit: 2014-2017'
      },
      legend: { position: 'none' }
    }
  };


  public activeClientsChart: any = {
    chartType: "ColumnChart",
    dataTable: [

    ],
    options: {
      chart: {
        title: 'Active clients last 12 months',
        // subtitle: 'Sales, Expenses, and Profit: 2014-2017'
      },
      legend: { position: 'none' }
    }
  };

  public likedCandidateChart: any = {
    chartType: "ColumnChart",
    dataTable: [

    ],
    options: {
      chart: {
        title: 'Liked candidates last 12 months',
        // subtitle: 'Sales, Expenses, and Profit: 2014-2017'
      },
      legend: { position: 'none' }
    }
  };

  public passedCandidateChart: any = {
    chartType: "ColumnChart",
    dataTable: [

    ],
    options: {
      chart: {
        title: 'Passed candidates last 12 months',
        // subtitle: 'Sales, Expenses, and Profit: 2014-2017'
      },
      legend: { position: 'none' }
    }
  };

  public totalHiresChart: any = {
    chartType: "ColumnChart",
    dataTable: [

    ],
    options: {
      chart: {
        title: 'Total hires last 3 months',
        // subtitle: 'Sales, Expenses, and Profit: 2014-2017'
      },
      legend: { position: 'none' }
    }
  };

  public openPositionsChart: any = {
    chartType: "ColumnChart",
    dataTable: [

    ],
    options: {
      chart: {
        title: 'Position opened last 12 months',
        // subtitle: 'Sales, Expenses, and Profit: 2014-2017'
      },
      legend: { position: 'none' }
    }
  };

  public interviewScheduledChart: any = {
    chartType: "ColumnChart",
    dataTable: [

    ],
    options: {
      chart: {
        title: 'Interview scheduled last 12 months',
        // subtitle: 'Sales, Expenses, and Profit: 2014-2017'
      },
      legend: { position: 'none' }
    }
  };

  public totalCandidatesChart: any = {
    chartType: "ColumnChart",
    dataTable: [

    ],
    options: {
      chart: {
        title: 'Candidates added last 12 months',
        // subtitle: 'Sales, Expenses, and Profit: 2014-2017'
      },
      legend: { position: 'none' }
    }
  };

  public scheduledTimeframeBar = {
    chartType: "Bar",
    dataTable: [
      ['', 'Planned', 'Actual'],
      ['10-20 days', 100, 70],
      ['21-30 days', 270, 220],
      ['31-40 days', 60, 90],
      ['>51 days', 30, 80]
    ],
    options: {
      chart: {
        title: 'Scheduled Timeframe for Last 90 Days',
        // subtitle: 'Sales, Expenses, and Profit: 2014-2017'
      },
      legend: { position: 'none' }
    }
  };
  faThumbsUp = faThumbsUp;
  faThumbsDown = faThumbsDown;
  faHandPointDown = faHandPointDown;
  faClock = faClock;
  currentUserRoleCode: any;
  totalPipelineCandidate: any;
  mailForm!: FormGroup;
  pieChartData: any = [];
  positionFilledPieChartData: any = [];
  htmLoadBarChartData: any = [];
  userAnalytics: any;
  closeResult: any;
  isCandidateBackgroundEditMode = false;
  username: any;
  imageURL: any;
  documentLinks: any;
  referenceLinks: any;
  allInterviews: any;
  pendingInterviews: any = [];
  isPendingCandidateApproval = false;
  selectedPositionDetail: any;
  requestedPositionList: any;
  loggedInCandidateId: any;
  candidatePositionSpecificData: any;
  activeClientDetails: any;
  scheduledInterviews: any;
  pendingActions: any;
  remainingInterviews: any;
  remainingPositions: any;
  constructor(
    private modalService: NgbModal,
    private dashboardService: DashboardService,
    private authService: AuthService,
    private fb: FormBuilder,
    private candidateService: CandidateService,
    private toastService: ToastService,
    private userService: UserService,
    private positionService: PositionService,
    private router: Router,
    private renderer: Renderer2
  ) {
  }

  ngOnInit(): void {
    let uerdata: any = localStorage.getItem('currentUser');
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    this.createForm();
    if (location.href.indexOf('position/clientPosition') > -1 && (this.currentUserRoleCode === '/sa' || this.currentUserRoleCode === '/am')) {
      this.getDashboardData(true);
    } else if (this.currentUserRoleCode === '/ca' || this.currentUserRoleCode === '/sa' || this.currentUserRoleCode === '/sm' || this.currentUserRoleCode === '/am') {
      this.getDashboardData();
    }
    setTimeout(() => {
      this.activeClient = localStorage.getItem('activeClient');
      this.openPosition = localStorage.getItem('openPosition');
    }, 2000);

    uerdata = JSON.parse(uerdata);
    if (uerdata) {
      this.username = uerdata?.username;
      this.userRole = uerdata?.role;
    }
    this.getUserAnalytics();
    this.userService.getUserAnalyticsData().subscribe((res: any) => {
      this.userAnalytics = res;
      this.masonry?.reloadItems();
      this.masonry?.layout();
    });
    if (uerdata?.role === 'Candidate') {
      this.loggedInCandidateId = uerdata?.candidate_id;
      this.getCandidateById(this.loggedInCandidateId);
      this.getCandidateCalendar(this.loggedInCandidateId);
    }
    this.candidateService.getSelectedCandidate().subscribe((pos) => {
      if (this.userRole === 'Candidate') {
        this.getCandidatePendingActions();
        // this.refreshCandidateData(pos);
        if (Object.keys(pos).length !== 0) {
          this.selectedPositionDetail = pos;
          this.selectedPositionDetail.members.sort((el1: any, el2: any) => {
            return el2.isHod - el1.isHod
          });
          this.activeClientDetails = null;
          this.activeClientDetails = JSON.parse(localStorage.getItem('activeClientDetails') || "");
        }
        if (pos.isPendingCandidateApproval) {
          this.isPendingCandidateApproval = true;
        } else {
          this.isPendingCandidateApproval = false;
        }
        if (pos.id && this.loggedInCandidateId) {
          this.candidateService.getCandidateAssociateData(pos.id, this.loggedInCandidateId).subscribe((res: any) => {
            this.candidatePositionSpecificData = res.data;
          });
        }
        this.pendingInterviews = this.allInterviews?.filter((el: any) => !el.accepted);
        this.scheduledInterviews = this.allInterviews?.filter((el: any) => el.accepted && this.selectedPositionDetail?.id === el.op_id);
      }
    });

    this.candidateService.getAllInterviews().subscribe((res: any) => {
      if (res.length) {
        this.allInterviews = res;
        this.pendingInterviews = this.allInterviews.filter((el: any) => !el.accepted);
        this.scheduledInterviews = this.allInterviews.filter((el: any) => el.accepted && this.selectedPositionDetail?.id === el.op_id);
      }
    });

    this.positionService.getRequestedPositionList().subscribe((res: any) => {
      this.requestedPositionList = res || [];
    });
  }

  getCandidatePendingActions() {
    this.candidateService.getCandidatePendingActions().subscribe((res: any) => {
      this.pendingActions = res.data;
      this.remainingInterviews = this.pendingActions.filter((el: any) => el.action_type == 'confirm_interview')
      this.remainingPositions = this.pendingActions.filter((el: any) => el.action_type == 'position_request')
      console.log(this.remainingPositions)
    })
  }
  startInterview(link: any) {
    localStorage.setItem('interviewLink', link)
    // this.router.navigate([]).then(result => { 
    //    window.open(this.authService.currentUserRoleCode + '/interview-screen',"_blank"); 

    //   }); 
    window.open(link, "_blank");

  }

  refreshCandidateData(pos: any) {

  }

  openAssociateCandidate() {
    const modalRef = this.modalService.open(AssociateCandidateComponent);
    modalRef.componentInstance.name = 'associate';
  }

  openSetAvailability() {
    const modalRef = this.modalService.open(SetAvailabilityComponent, { size: 'lg' });
    modalRef.componentInstance.name = 'scheduler';
  }

  getDashboardData(isPositionDashboard?: any) {
    // const url = (this.currentUserRoleCode == '/ca' || this.currentUserRoleCode == '/hm') ? getClientAdminDashboard : getDashboardData
    const clientId = isPositionDashboard ? localStorage.getItem('clientId') : null;
    this.dashboardService.getDashboardData(clientId).subscribe((res: any) => {
      this.dashboardData = res;
      if (res.candidate_pipeline) {
        this.totalPipelineCandidate = res.candidate_pipeline.reduce((r: any, d: any) => r + d.total_candidates, 0);
        res.candidate_pipeline.forEach((el: any) => {
          this.pieChartData.push({
            chartType: 'PieChart',
            dataTable: [
              ['', ''],
              ['Likes', el.likes_count],
              ['Passes', el.pass_count],
            ],
            options: { 'title': 'Likes and Passes count' },
          });
        });
      }
      if (res.position_filled) {
        res.position_filled.forEach((el: any) => {
          this.positionFilledPieChartData.push({
            chartType: 'PieChart',
            dataTable: [
              ['', ''],
              ['Hired', el.hired],
              ['Open', el.open],
            ],
            options: { 'title': el.op_name },
          });
        });
      }
      if (res.hiring_group_load?.length) {
        this.htmLoadBarChartData.push({
          chartType: 'BarChart',
          dataTable: [
            ['', '']
          ]
        });
        res.hiring_group_load.forEach((element: any) => {
          this.htmLoadBarChartData[0].dataTable.push(
            [element.hiring_team_name, element.load]
          );
        });
      }
      res.op_by_client?.unshift(['', 'Positions %']);
      if (res?.['offers-accepted']?.['chart-data']) {
        this.offerAcceptedChart.dataTable.push(['Month', 'Count']);
        res['offers-accepted']['chart-data'].forEach((element: any) => {
          this.offerAcceptedChart.dataTable.push([element.month, element.count]);
        });
      }
      if (res?.['active-clients']?.['chart-data']) {
        this.activeClientsChart.dataTable.push(['Month', 'Count']);
        res['active-clients']['chart-data'].forEach((element: any) => {
          this.activeClientsChart.dataTable.push([element.month, element.count]);
        });
      }
      if (res?.['liked-candidates']?.['chart-data']) {
        this.likedCandidateChart.dataTable.push(['Month', 'Count']);
        res['liked-candidates']['chart-data'].forEach((element: any) => {
          this.likedCandidateChart.dataTable.push([element.month, element.count]);
        });
      }
      if (res?.['passed-candidates']?.['chart-data']) {
        this.passedCandidateChart.dataTable.push(['Month', 'Count']);
        res['passed-candidates']['chart-data'].forEach((element: any) => {
          this.passedCandidateChart.dataTable.push([element.month, element.count]);
        });
      }
      if (res?.['total-hires-this-quater']?.['chart-data']) {
        this.totalHiresChart.dataTable.push(['Month', 'Count']);
        res['total-hires-this-quater']['chart-data'].forEach((element: any) => {
          this.totalHiresChart.dataTable.push([element.month, element.count]);
        });
      }
      if (res?.['open-position-data']?.['chart-data']) {
        this.openPositionsChart.dataTable.push(['Month', 'Count']);
        res['open-position-data']['chart-data'].forEach((element: any) => {
          this.openPositionsChart.dataTable.push([element.month, element.count]);
        });
      }
      if (res?.['interview-scheduled']?.['chart-data']) {
        this.interviewScheduledChart.dataTable.push(['Month', 'Count']);
        res['interview-scheduled']['chart-data'].forEach((element: any) => {
          this.interviewScheduledChart.dataTable.push([element.month, element.count]);
        });
      }
      if (res?.['total-candidates']?.['chart-data']) {
        this.totalCandidatesChart.dataTable.push(['Month', 'Count']);
        res['total-candidates']['chart-data'].forEach((element: any) => {
          this.totalCandidatesChart.dataTable.push([element.month, element.count]);
        });
      }
      this.masonry?.reloadItems();
      this.masonry?.layout();
      // this.openPositionsByClientBar.dataTable = res.op_by_client;
    });
  }
  offerAcceptedChartData(data: any) {
    this.offerAcceptedChart.dataTable.push(['Month', 'Count'])
    data.forEach((element: any) => {
      this['offerAcceptedChart'].dataTable.push([element.month, element.count]);
    });
  }
  createForm() {
    this.mailForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
      subject: ['', Validators.required],
      message: [''],
    });
  }

  openSm(content: any, head: any) {
    this.mailForm.controls.email.patchValue(head.email)
    this.modalService.open(content, { size: 'md' });
  }

  sentMail() {
    this.candidateService
      .sendMail(this.mailForm.value)
      .subscribe((val: any) => {
        if (val.error) {
          this.showDanger(val.error);
          this.mailForm.reset();
          this.modalService.dismissAll();
        } else {
          this.showSuccess('Mail sent successfully');
          this.mailForm.reset();
          this.modalService.dismissAll();
        }
      });
  }

  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

  showAdditionalInfo(i: any) {
    this.detailedInfoCardIndex.push(i);
    // setTimeout(() => {
    this.masonry.reloadItems();
    this.masonry.layout();
    // }, 300);
  }
  hideAdditionalInfo(i: any) {
    this.detailedInfoCardIndex = this.detailedInfoCardIndex.filter((e: any) => e !== i);
    this.masonry.reloadItems();
    this.masonry.layout();
  }

  getUserAnalytics() {
    this.userService.getUserAnalytics().subscribe((res: any) => {
      this.userAnalytics = res;
    });
  }

  openAnalyticsModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
    });
  }

  toggleSelection(menu: any) {
    this.userAnalytics.filter((el: any) => el.label === menu.label)[0].isSelected = !menu.isSelected;
  }

  saveAnalyticsData() {
    this.userService.saveAnalyticsData(this.userAnalytics).subscribe((res: any) => {
      this.showSuccess('Dashboard saved successfully!')
    });
  }

  getCssIcon(iconName: any) {
    switch (iconName) {
      case 'open-position':
        return faSearchPlus;
      case 'liked-candidate':
        return faThumbsUp;
      case 'passed-candidates':
        return faThumbsDown;
      case 'interview-not-scheduled':
        return faClock;
      case 'interview-scheduled':
        return faCalendar;
      case 'offer-accepted':
        return faHandsHelping;
      case 'offer-pending':
        return faDollarSign;
      case 'position-stalled':
        return faHandPaper;
      case 'canddidates-in-process':
        return faPeopleArrows;
      case 'hires-this-month':
        return faIdCard;
      default:
        return faClock;
    }
  }

  isAnalyticsSelected(name: any) {
    return this.userAnalytics?.find((el: any) => el.class === name)?.isSelected;
  }

  getCandidateById(id: any) {
    this.candidateService.getCandidateById(id).subscribe((res: any) => {
      this.candidateData = res;
      this.createCandidateForm();
      if (res.skype_id) {
        // this.fetchLinkedinProfile(res.skype_id);
      }
    });
  }

  // fetchLinkedinProfile(url: any) {
  //   this.candidateService.fetchLinkedinProfile(url).subscribe((res: any) => {
  //     this.linkedInData = res;
  //   }, error => {
  //     // this.showDanger('Error while fetching profile details');
  //   });
  // }

  fetchProfileImage() {
    this.candidateService.fetchProfileImage(this.candidateData.candidate_id).subscribe((res: any) => {
      if (res.msg) {
        this.showDanger(res.msg)
      } else {
        this.linkedInData = res;
      }
    }, error => {
      // this.showDanger('Error while fetching profile details');
    });
  }

  getCandidateSalary(currency: any, salaryRange: any) {
    // return salaryRange && salaryRange !== 'null' && salaryRange !== 'Not Specified' ? (currency || '$') + (salaryRange.split(',')[0] || 0) + `-` + (currency || '$') + (salaryRange.split(',')[1] || 500000) : 'N/A';
    if (salaryRange && salaryRange !== 'null' && salaryRange !== 'Not Specified') {
      if (salaryRange.split(',')[1])
        return salaryRange && salaryRange !== 'null' && salaryRange !== 'Not Specified' ? (currency || '$') + (salaryRange.split(',')[0] || 0) + `-` + (currency || '$') + (salaryRange.split(',')[1] || '') : 'N/A';
      else {
        return (currency || '$') + (salaryRange.split(',')[0] || 0);
      }
    }
  }

  toggleBackgroundEdit() {
    this.isCandidateBackgroundEditMode = !this.isCandidateBackgroundEditMode
  }
  candidateForm!: FormGroup;
  createCandidateForm() {
    this.candidateForm = this.fb.group({
      candidate_id: [this.candidateData.candidate_id],
      name: [this.candidateData.name],
      last_name: [this.candidateData.last_name],
      work_auth: [this.candidateData.work_auth],
      nickname: [this.candidateData.nickname],
      job_title: [this.candidateData.job_title],
      personal_notes: [this.candidateData.personal_notes],
      desired_work_location: [this.candidateData.desired_work_location],
      email: [this.candidateData.email],
      phone_number: [this.candidateData.phone_number],
      location: [this.candidateData.location],
      // additional_info: [this.candidateData.additional_info],
      // documents: [],
      currency: [this.candidateData.currency],
      salaryRange: [this.candidateData.salaryRange],
      profile_photo: [''],
      profile_photo_deleted: [false],
      skype_id: [this.candidateData.skype_id],
      documents: [this.candidateData.documents],
      references: [this.candidateData.references]
    });
    this.documentLinks = this.candidateData?.documents;
    this.referenceLinks = this.candidateData?.references;
    this.minValue = parseInt(this.candidateData?.salaryRange?.split(',')[0] || 0);
    this.maxValue = parseInt(this.candidateData?.salaryRange?.split(',')[1] || 500000);
    this.candidateForm.controls.salaryRange.reset([this.minValue, this.maxValue]);
  }

  showPreview(event: any) {

    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      // this.accountService.leavePage = true;
      const [file] = event.target.files;
      this.candidateForm.controls.profile_photo_deleted.patchValue(false);
      this.candidateForm.controls['profile_photo'].patchValue(event.target.files[0]);
      reader.readAsDataURL(file);

      reader.onload = () => {

        this.imageURL = reader.result as string;

      };

    }
  }

  documents: string[] = [];

  onFileChange(event: any) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.documents.push(event.target.files[i]);
    }
  }

  deleteDoc(doc: any) {
    this.documentLinks.splice(this.documentLinks.indexOf(doc), 1)
  }

  references: string[] = [];
  onReferenceChange(event: any) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.references.push(event.target.files[i]);
    }
  }

  deleteRef(doc: any) {
    this.referenceLinks.splice(this.referenceLinks.indexOf(doc), 1)
  }

  cancelCandidateUpdate() {
    this.isCandidateBackgroundEditMode = false;
    this.toggleReference = false;
    this.toggleResume = false;
  }

  getCandidateCalendar(canId: any) {
    this.candidateService.getCandidateCalendar(canId).subscribe((res: any) => {
      this.calendarData = res.data;
    });
  }

  approveOpenPosition(pos_id: any) {
    this.candidateService.associateCandidateToOpenPosition(this.candidateData.candidate_id, pos_id, true).subscribe((res: any) => {
      this.showSuccess("Position added successfully");
      window.location.reload();
    });
  }

  rejectOpenPosition(pos_id: any) {
    this.candidateService.associateCandidateToOpenPosition(this.candidateData.candidate_id, pos_id, false).subscribe((res: any) => {
      this.showSuccess("Position removed successfully");
      window.location.reload();
    });
  }

  decodeUrl(str: any) {
    return str.replace(/%20/g, " ");
  }

  navigateToCandidateDash() {
    if (this.userRole === 'Candidate') {
      window.location.reload();
    }
  }

  // to mm/dd/yyyy
  formatDate(date: string) {
    return (new Date(date).getMonth() + 1) + '/' + new Date(date).getDate() + '/' + new Date(date).getFullYear();
  }

}