<!-- <div *ngIf="chartData?.dates && !isProgressSummary" class="row align-items-end p-4">
    
    <div class="col-sm-10 chart-cont" *ngIf="chartData?.dates">
        <div class="row">
            <div class="htms-list" *ngFor="let htm of chartData.htms">
                <figure><img class="htm-profile-pic" [ngStyle]="{'border': '6px solid ' + htm.color}" [src]="htm.profile_pic && htm.profile_pic !== 'None' && htm.profile_pic !== 'null' ? imageBaseUrl + htm.profile_pic : './../../assets/images/big-default-avatar.png'" /></figure>
            </div>
        </div>
        <div class="chart-bar" *ngFor="let data of chartData.dates | keyvalue">
            <div class="bar" [ngStyle]="{'background-color': getBackgroundColor(data.value)}">
                <div class="bar-slice" *ngFor="let deadline of getDeadlines(data.value)" [ngStyle]="{'background-color': getHtmBackground(deadline), 'height': getBarHeight(data.value)}"></div>
            </div>
            <div class="date" [ngStyle]="{'background-color': getDateColor(data.value)}">{{data.key}}</div>
        </div>
    </div>
    <div class="chart-bar arrow-right" style="bottom: 0;">
        <span class="completion-date">Target Completion {{targetDeadlineDate}}</span>
    </div>
</div>

<div *ngIf="chartData?.dates && isProgressSummary" class="row align-items-end p-4">
    
    <div class="col-sm-10 chart-cont" *ngIf="chartData?.dates">
        <div class="chart-bar-cont" [ngStyle]="{'margin-top': (maxDeadlineInASingleDay * 4) + 'em'}">
            <ng-container *ngFor="let data of chartData.dates | keyvalue; let i = index;">
                <div class="chart-bar">
                    
                        <div class="htms-deadline-list" *ngFor="let htm of getDeadlines(data.value); let i = index;">
                            <figure><img class="htm-profile-pic-progress-summary" [ngStyle]="{'border': '4px solid ' + getHtmBackground(htm.htmId), 'bottom':((i+1) * 4) + 'em'}" [src]="htm.htmProfPic && htm.htmProfPic !== 'None' && htm.htmProfPic !== 'null' ? imageBaseUrl + htm.htmProfPic : './../../assets/images/big-default-avatar.png'" /></figure>
                        </div>
                   
                    <div class="bar" [ngStyle]="{'background-color': getBackgroundColor(data.value)}" [ngbTooltip]="tipContent" [container]="'body'">
                        <div class="interview-slice" *ngFor="let interview of getInterviews(data.value)" [ngStyle]="{'background-color': interview.htmColorCode, 'height': getInterviewBarHeight(data.value)}">
                            
                        </div>
                        <ng-template #tipContent>
                            <div class="interview-details">
                                
                                <p class="candidate-name" *ngFor="let interview of getInterviews(data.value)">{{interview.candidateName}} w/ {{interview.htmName}} - {{interview.time}}</p>
                                <p class="candidate-name" *ngIf="getInterviews(data.value)?.length === 0">No interview scheduled for the day</p>
                            </div>
                        </ng-template>
                    </div>
                    <div class="date" [ngStyle]="{'background-color': getDateColor(data.value)}">{{data.key}}</div>
                </div>

            </ng-container>
        
        </div>
    </div>
    <div class="chart-bar arrow-right">
        <span class="completion-date">Target Completion {{targetDeadlineDate}}</span>
    </div>
</div>
  

<div style="position: relative;" class="legends row">
    <div class="legend pl-4">
        <p><span class="square kick-off"></span>Kick-off Deadline {{kickoffDate}}</p>
    </div>
    <div class="legend">
        <p><span class="square sourcing"></span>Sourcing Deadline {{sourcingDeadlineDate}}</p>
    </div>
</div>
 -->


<!-- MA's Code  -->

<div class="timeline-box">
<div *ngIf="chartData?.dates && !isProgressSummary && kickoffDate && sourcingDeadlineDate && targetDeadlineDate" class="timeline-container row justify-content-center p-5 ">
    <div class="timeline">
        <div class="start-date" [style.left.%]="calculateKickoffPosition()">{{ kickoffDate}}</div>
        <div class="middle-date" [style.left.%]="calculateSourcingPosition()">{{ sourcingDeadlineDate}}</div>
        <div class="end-date" [style.left.%]="calculateTargetPosition()">{{ targetDeadlineDate}}</div>
    </div>
</div>
<div *ngIf="chartData?.dates && isProgressSummary && kickoffDate && sourcingDeadlineDate && targetDeadlineDate" class="timeline-container row justify-content-center p-5 ">
    <div class="timeline">
      <div class="start-date" [style.left.%]="calculateKickoffPosition()">{{ kickoffDate}}</div>
      <div class="middle-date" [style.left.%]="calculateSourcingPosition()">{{ sourcingDeadlineDate}}</div>
      <div class="end-date" [style.left.%]="calculateTargetPosition()">{{ targetDeadlineDate}}</div>
        <div *ngFor="let checkpoint1 of chartData.htm_deadlines; let i= index" class="checkpoint1" [style.left.%]="calculatePosition(checkpoint1.deadline)">
            {{ getHtmDeadlineDateForScreen(checkpoint1.deadline) }}
            <div class="htmPhoto" *ngIf="!(i>0 && isPrevious(checkpoint1,i))" [ngbTooltip]="htm1Content" container="body">
                <img class="candidate-profile-pic  " [src]="checkpoint1.profile_pic && checkpoint1.profile_pic !== 'None' && checkpoint1.profile_pic !== 'null' ? imageBaseUrl + checkpoint1.profile_pic: './../../assets/images/big-default-avatar.png'" />
                <div class="vl2"></div>
            </div>
            <ng-template #htm1Content>
                <div class="interview-details d-flex">
                    <span><p class="candidate-name">{{checkpoint1.htm_name}}</p></span>
                </div>
            </ng-template>
            <div class="htm1Photo" *ngIf="i>0 && isPrevious(checkpoint1,i) "  [ngbTooltip]="htm2Content" container="body">
                <figure><img class="candidate-profile-pic" [src]="checkpoint1.profile_pic && checkpoint1.profile_pic !== 'None' && checkpoint1.profile_pic !== 'null' ? imageBaseUrl + checkpoint1.profile_pic: './../../assets/images/big-default-avatar.png'" /></figure>
                <div class="vl3"></div>
            </div>
            <ng-template #htm2Content>
                <div class="interview-details d-flex">
                    <span><p class="candidate-name">{{checkpoint1.htm_name}}</p></span>
                </div>
            </ng-template>
        </div>
        
        <div class="checkpoint3" style="word-wrap: break-word;" *ngFor="let d of allDates;" [style.left.%]="calculatePosition(d.date)">
           <span> {{d.difference +" days"}}</span>
            <div class="vl1"></div>
        </div>
        
      <div *ngFor="let checkpoint2 of interviewDates" [ngbTooltip]="tipsContent" class="checkpoint2" [style.left.%]="calculatePosition(checkpoint2.date)">
          {{ checkpoint2.details.length }}
          <ng-template #tipsContent>
            <p>{{checkpoint2.date+"/"+getYear()}}</p>
            <div class="interview-details d-flex"  *ngFor="let detail of checkpoint2.details">
                <img class="candidate-profile-pic border" [src]="detail.candidateProfilePic && detail.candidateProfilePic !== 'None' && detail.candidateProfilePic !== 'null' ? imageBaseUrl + detail.candidateProfilePic : './../../assets/images/big-default-avatar.png'" />
                    <span><p class="candidate-name p-0 m-0">{{detail.candidateName}}</p>
                    <p class="candidate-name" >@{{detail.time}}</p></span>
            </div>
        </ng-template>
        
        </div>
        
       
        
    </div>
</div>
<div class="legends row" *ngIf="this.showLegends">
    <div class="legend col-sm-4 pl-4">
        <p><span class="square kick-off"></span>Kick-off Deadline: {{kickoffDate  || 'NA'}}</p>
    </div>
    <div class="legend col-sm-4">
        <p><span class="square sourcing"></span>Sourcing Deadline: {{sourcingDeadlineDate  || 'NA'}}</p>
    </div>
    <div class="legend col-sm-4">
        <p><span class="square target"></span>Target Completion Date: {{targetDeadlineDate  || 'NA'}}</p>
    </div>
</div>
</div>