import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from "@angular/core";

import * as Url from './../constants/url';

@Injectable({ providedIn: 'root'})


export class ClientService{
    constructor(private http: HttpClient){}

    createCLient(payload: any){
        const formData = new FormData();
        const Payload = payload;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(Url.createClientUrl, formData, {headers: headers}).pipe()
    }
    signInCLient(payload: any){
        const formData = new FormData();
        const Payload = payload;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(Url.signInClient, formData, {headers: headers}).pipe()
    }
    saveClientPackage(id:any,data:any,token:any){
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Authorization', 'Token '+token);
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(Url.packageInfo + '/' + id, formData, {headers: headers}).pipe();
    }
    deleteClient(id: any){
        const payload = new HttpParams().set('id', id);
        return this.http.delete(Url.deleteClientUrl, {params: payload}).pipe();
    }
    deleteClientMethod(data:any){
        
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(Url.deleteClient, data, {headers: headers}).pipe();
    }
    suspendClientMethod(data:any){
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(Url.suspendClient, data, {headers: headers}).pipe();
    }
    activateClientMethod(data:any){
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(Url.activateClient, data, {headers: headers}).pipe();
    }

    getClientbyId(id: any){
        const params = new HttpParams().set('id', id);
        return this.http.get(Url.createClientUrl, {params}).pipe();
    }

    updateClient(data: any){
        const formData = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.put(Url.createClientUrl, formData, {headers: headers}).pipe();
    }

    getClientSummary() {
        return this.http.get(Url.getClientSummary).pipe();
    }
    getAllClients(){
        return this.http.get(Url.allClients).pipe();
    }
}
