import { Component, Input, OnInit } from '@angular/core';
import { ViewChild } from "@angular/core";

import { PositionService } from 'src/app/services/position.service';

import { CandidateService } from './../../services/candidate.service';


@Component({
  selector: 'app-summary-timeline-chart',
  templateUrl: './summary-timeline-chart.component.html',
  styleUrls: ['./summary-timeline-chart.component.css']
})
export class SummaryTimelineChartComponent implements OnInit {
  public timelineChartData:any =  {};
  public options: any = {};
  subscriptions$: any;
  currentPosition: any;
  @Input() isCreatePosition: any;
  constructor( private candidateService: CandidateService, private positionService: PositionService) { }

  ngOnInit(): void {
    this.subscriptions$ = this.candidateService.getSelectedCandidate().subscribe((val) => {
      if (val.id && val.open_positions) { 
        this.currentPosition = localStorage.getItem('currentPosition');
        this.getPositionById(this.currentPosition);            
      }
      if(this.isCreatePosition) {
        const stagesData = JSON.parse(localStorage.getItem('form2Data') || '');
        this.timelineChartData = {
          chartType: 'Timeline',            
          dataTable: [
            ['Name', 'From', 'To']
          ]            
        };
        stagesData?.stages.forEach((element: any) => {
          this.timelineChartData.dataTable.push([element.label, new Date(element.startDate), new Date(element.endDate)]);
        });
        // let color1 = '#2196f3', color2= '#2196f3', color3= '#2196f3', color4= '#2196f3', color5= '#2196f3', color6= '#2196f3', color7= '#2196f3', color8 = '#2196f3';

        let g1 = new Date();

        this.options = {
          // colors: [color1, color2, color3, color4, color5, color6, color7, color8],
          colors: [],
          height: 380,
          width: '100%',
          hAxis: {
            format: 'MM/dd' + '-' + 'EEE'
         },
         timeline: { groupByRowLabel: false },
         axes: {             
          x: { 
            0: { side: 'top'} 
          }                     
        }   
        };
        this.options.colors = [];
        stagesData?.stages.forEach((element: any) => {
          let colorStr = g1.getTime() > new Date(element.startDate).getTime() && g1.getTime() > (new Date(element.endDate).getTime() + 86400000) && !element.completed ? '#ff0000' :
          g1.getTime() < new Date(element.startDate).getTime() && g1.getTime() < (new Date(element.endDate).getTime() + 86400000) ? '#2196f3'
          : g1.getTime() > new Date(element.startDate).getTime() && g1.getTime() < (new Date(element.endDate).getTime() + 86400000) ? '#f1b238' : '#4caf50';
          this.options.colors.push(colorStr);
        });
        
        this.timelineChartData.options = this.options;
      }
    });    
  }  


  getPositionById(id: any){    
    if(id){
      this.positionService.getPositionById(id).subscribe((val: any) => {
        if(val){
          this.timelineChartData = {
            chartType: 'Timeline',            
            dataTable: [
              ['Name', 'From', 'To'], 
              // [ 'Kick-off Call' ,new Date(val?.data?.kickoff_start_date), new Date(val?.data?.kickoff_completion_date) ],
              // [ val?.data?.hiring_team_interviews_days_text || 'Untitle' ,  new Date(val?.data?.hiring_team_interviews_start_date),  new Date(val?.data?.hiring_team_interviews_days) ],
              // [ val?.data?.short_list_selection_days_text || 'Untitle',  new Date(val?.data?.short_list_selection_start_date),  new Date(val?.data?.short_list_selection_days) ],
              // [ val?.data?.second_round_interview_days_text || 'Untitle' , new Date(val?.data?.second_round_interview_start_date),  new Date(val?.data?.second_round_interview_days) ],
              // [ val?.data?.final_selection_days_text || 'Untitle' , new Date(val?.data?.final_selection_start_date),  new Date(val?.data?.final_selection_days) ],
              // [ val?.data?.make_offer_days_text || 'Untitle' ,  new Date(val?.data?.make_offer_start_date),  new Date(val?.data?.make_offer_days) ],
              // [ val?.data?.decision_days_text || 'Untitle' , new Date(val?.data?.decision_days_start_date),  new Date(val?.data?.decision_days) ],
              // [ 'Final Round' , new Date(val?.data?.final_round_completetion_date),  new Date(val?.data?.final_round_completetion_date) ]
            ]            
          };
          val?.data?.stages.forEach((element: any) => {
            this.timelineChartData.dataTable.push([element.label, new Date(element.startDate), new Date(element.endDate)]);
          });
          // let color1 = '#2196f3', color2= '#2196f3', color3= '#2196f3', color4= '#2196f3', color5= '#2196f3', color6= '#2196f3', color7= '#2196f3', color8 = '#2196f3';

          let g1 = new Date();
          
          // // color1
          // if((g1.getTime() > new Date(val?.data?.kickoff_start_date).getTime()) && (g1.getTime() < new Date(val?.data?.kickoff_completion_date).getTime())){
          //   color1 = '#ff0000';
          // } else if((g1.getTime() > new Date(val?.data?.kickoff_start_date).getTime()) && (g1.getTime() > new Date(val?.data?.kickoff_completion_date).getTime())){
          //   if(val?.data?.kickoff_start_date_completed === false) {
          //     color1 = '#ff0000';
          //   } else {
          //     color1 = '#4caf50';
          //   }
          // } else if((g1.getTime() < new Date(val?.data?.kickoff_start_date).getTime()) && (g1.getTime() < new Date(val?.data?.kickoff_completion_date).getTime())){
          //   color1 = '#2196f3';
          // }

          // // color2
          // if((g1.getTime() > new Date(val?.data?.hiring_team_interviews_start_date).getTime()) && (g1.getTime() < new Date(val?.data?.hiring_team_interviews_days).getTime())){
          //   color2 = '#ff0000';
          // } else if((g1.getTime() > new Date(val?.data?.hiring_team_interviews_start_date).getTime()) && (g1.getTime() > new Date(val?.data?.hiring_team_interviews_days).getTime())){
          //   if(val?.data?.hiring_team_interviews_days_completed === false) {
          //     color2 = '#ff0000';
          //   } else {
          //     color2 = '#4caf50';
          //   }
          // } else if((g1.getTime() < new Date(val?.data?.hiring_team_interviews_start_date).getTime()) && (g1.getTime() < new Date(val?.data?.hiring_team_interviews_days).getTime())){
          //   color2 = '#2196f3';
          // }

          // // color3
          // if((g1.getTime() > new Date(val?.data?.short_list_selection_start_date).getTime()) && (g1.getTime() < new Date(val?.data?.short_list_selection_days).getTime())){
          //   color3 = '#ff0000';
          // } else if((g1.getTime() > new Date(val?.data?.short_list_selection_start_date).getTime()) && (g1.getTime() > new Date(val?.data?.short_list_selection_days).getTime())){
          //   if(val?.data?.short_list_selection_days_completed === false) {
          //     color3 = '#ff0000';
          //   } else {
          //     color3 = '#4caf50';
          //   }
          // } else if((g1.getTime() < new Date(val?.data?.short_list_selection_start_date).getTime()) && (g1.getTime() < new Date(val?.data?.short_list_selection_days).getTime())){
          //   color3 = '#2196f3';
          // }

          // // color4
          // if((g1.getTime() > new Date(val?.data?.second_round_interview_start_date).getTime()) && (g1.getTime() < new Date(val?.data?.second_round_interview_days).getTime())){
          //   color4 = '#ff0000';
          // } else if((g1.getTime() > new Date(val?.data?.second_round_interview_start_date).getTime()) && (g1.getTime() > new Date(val?.data?.second_round_interview_days).getTime())){
          //   if(val?.data?.second_round_interview_days_completed === false) {
          //     color4 = '#ff0000';
          //   } else {
          //     color4 = '#4caf50';
          //   }
          // } else if((g1.getTime() < new Date(val?.data?.second_round_interview_start_date).getTime()) && (g1.getTime() < new Date(val?.data?.second_round_interview_days).getTime())){
          //   color4 = '#2196f3';
          // }

          // // color5
          // if((g1.getTime() > new Date(val?.data?.final_selection_start_date).getTime()) && (g1.getTime() < new Date(val?.data?.final_selection_days).getTime())){
          //   color5 = '#ff0000';
          // } else if((g1.getTime() > new Date(val?.data?.final_selection_start_date).getTime()) && (g1.getTime() > new Date(val?.data?.final_selection_days).getTime())){
          //   if(val?.data?.final_selection_days_completed === false) {
          //     color5 = '#ff0000';
          //   } else {
          //     color5 = '#4caf50';
          //   }
          // } else if((g1.getTime() < new Date(val?.data?.final_selection_start_date).getTime()) && (g1.getTime() < new Date(val?.data?.final_selection_days).getTime())){
          //   color5 = '#2196f3';
          // }

          // // color6
          // if((g1.getTime() > new Date(val?.data?.make_offer_start_date).getTime()) && (g1.getTime() < new Date(val?.data?.make_offer_days).getTime())){
          //   color6 = '#ff0000';
          // } else if((g1.getTime() > new Date(val?.data?.make_offer_start_date).getTime()) && (g1.getTime() > new Date(val?.data?.make_offer_days).getTime())){
          //   if(val?.data?.make_offer_days_completed === false) {
          //     color6 = '#ff0000';
          //   } else {
          //     color6 = '#4caf50';
          //   }
          // } else if((g1.getTime() < new Date(val?.data?.make_offer_start_date).getTime()) && (g1.getTime() < new Date(val?.data?.make_offer_days).getTime())){
          //   color6 = '#2196f3';
          // }

          // // color7
          // if((g1.getTime() > new Date(val?.data?.decision_days_start_date).getTime()) && (g1.getTime() < new Date(val?.data?.decision_days).getTime())){
          //   color7 = '#ff0000';
          // } else if((g1.getTime() > new Date(val?.data?.decision_days_start_date).getTime()) && (g1.getTime() > new Date(val?.data?.decision_days).getTime())){
          //   if(val?.data?.decision_days_completed === false) {
          //     color7 = '#ff0000';
          //   } else {
          //     color7 = '#4caf50';
          //   }
          // } else if((g1.getTime() < new Date(val?.data?.decision_days_start_date).getTime()) && (g1.getTime() < new Date(val?.data?.decision_days).getTime())){
          //   color7 = '#2196f3';
          // }

          // // color8
          // if(g1.getTime() < new Date(val?.data?.final_round_completetion_date).getTime()){
          //   color8 = '#ff0000';
          // } else if(g1.getTime() > new Date(val?.data?.final_round_completetion_date).getTime()){
          //   if(val?.data?.final_round_start_date_completed === false) {
          //     color8 = '#ff0000';
          //   } else {
          //     color8 = '#4caf50';
          //   }
          // } else if(g1.getTime() < new Date(val?.data?.final_round_completetion_date).getTime()){
          //   color8 = '#2196f3';
          // }

          this.options = {
            // colors: [color1, color2, color3, color4, color5, color6, color7, color8],
            colors: [],
            height: 380,
            width: '100%',
            hAxis: {
              format: 'MM/dd' + '-' + 'EEE'
           },
           timeline: { groupByRowLabel: false },
           axes: {             
            x: { 
              0: { side: 'top'} 
            }                     
          }   
          };
          this.options.colors = [];
          val?.data?.stages.forEach((element: any) => {
            let colorStr = g1.getTime() > new Date(element.startDate).getTime() && g1.getTime() > (new Date(element.endDate).getTime() + 86400000) && !element.completed ? '#ff0000' :
            g1.getTime() < new Date(element.startDate).getTime() && g1.getTime() < (new Date(element.endDate).getTime() + 86400000) ? '#2196f3'
            : g1.getTime() > new Date(element.startDate).getTime() && g1.getTime() < (new Date(element.endDate).getTime() + 86400000) ? '#f1b238' : '#4caf50';
            this.options.colors.push(colorStr);
          });
          
          this.timelineChartData.options = this.options;
        }
      });
    }
  }

  ngOnDestroy() {
    if(this.subscriptions$) {
      this.subscriptions$.unsubscribe();
    }
  }
  
}

