<div class="modal-header">
    <h4 class="modal-title">Set Availability</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="setForm">
        <table class="table table">
            <tr>
                <th width="30%">Select Date</th>
                <th width="70%">Select Time</th>
            </tr>
            <ng-container formArrayName="availableDays">
                <!-- <tr> -->
                <tr *ngFor="let days of getAvailableDays().controls; let dayIndex=index">
                    <ng-container [formGroupName]="dayIndex">
                        <td>
                            <div class="form-group" style="margin-top: 18px;">
                                <div class="input-group">
                                    <input class="form-control" [minDate]="today" placeholder="yyyy-mm-dd" name="dp" formControlName="date" ngbDatepicker #d="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><fa-icon [icon]="faCalendar"></fa-icon></button>
                                    </div>
                                    <button type="button" class="btn btn-primary margin5" (click)="addDays()"><fa-icon [icon]="faPlus"></fa-icon></button>
                                    <button type="button" class="btn btn-warning margin5" (click)="removeDays(dayIndex)"><fa-icon [icon]="faTrash"></fa-icon></button>
                                </div>
                            </div>
                        </td>
                        <td class="secheduler" formArrayName='hours'>
                            <ng-container *ngFor="let hour of getDaysHours(dayIndex).controls; let hourInx = index;">
                                <div class="row">
                                    <div class="col-sm-5">
                                        <label>From</label>
                                    </div>
                                    <div class="col-sm-7">
                                        <label>To</label>
                                    </div>
                                </div>
                                <div [formGroupName]="hourInx">
                                    <ngb-timepicker [readonlyInputs]="true" [meridian]="meridian" [minuteStep]="minuteStep" formControlName="startTime"></ngb-timepicker>
                                    <ngb-timepicker [readonlyInputs]="true" [meridian]="meridian" [minuteStep]="minuteStep" formControlName="endTime"></ngb-timepicker>
                                    <div class="buttons-ctnr">
                                        <button type="button" (click)="addDayHour(dayIndex)" class="btn btn-primary margin5" style="margin: 18px 2px;"><fa-icon [icon]="faPlus"></fa-icon></button>
                                        <button type="button" (click)="removeDayHour(dayIndex, hourInx)" *ngIf="hourInx > 0" class="btn btn-warning margin5" style="margin: 18px 2px;"><fa-icon [icon]="faTrash"></fa-icon></button>
                                    </div>
                                </div>
                                <hr *ngIf="hourInx !== getDaysHours(dayIndex).controls.length - 1" />
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
        </table>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="!isSetFormValid()" *ngIf="!showUpdateBtn" (click)="setAvailability()">Set Availability</button>
    <button type="button" class="btn btn-primary" [disabled]="!isSetFormValid()" *ngIf="showUpdateBtn" (click)="setAvailability()">Update Availability</button>
    <button type="button" class="btn btn-primary" *ngIf="scheduleData?.schedule_exists" (click)="viewAvailability()">View Your Schedule</button>
</div>