import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from 'src/app/core/header/header.component';
import {
  faPlus
  , faEllipsisH, faSort, faColumns, faSearch
} from '@fortawesome/free-solid-svg-icons';
import { ClientService } from 'src/app/services/client.service';
import * as URL from './../../constants/url';
import { AuthService } from 'src/app/services/auth.services';
import { Router } from '@angular/router';
import { DetailHeaderComponent } from 'src/app/core/detail-header/detail-header.component';
@Component({
  selector: 'app-all-clients',
  templateUrl: './all-clients.component.html',
  styleUrls: ['./all-clients.component.css']
})
export class AllClientsComponent implements OnInit {
  faEllipsisH = faEllipsisH;
  faSort = faSort;
  faPlus = faPlus;
  faColumns = faColumns;
  faSearch = faSearch
  clientsData: any;
  public imgBaseUrl = URL.imgBaseUrl;
  currentUserRoleCode: any;
  status1: boolean = true;
  status2: boolean = false;
  status3: boolean = false;
  status4: boolean = false;
  status5: boolean = false;
  clientsItem: any = [];
  selectAll: boolean = false;
  query: any
  trialPackage: any;
  starterPackage: any;
  growthPackage: any;
  enterprisePackage: any;
  allClientsData: any;
  col_CA: boolean = true;
  col_subscription: boolean = true;
  col_payment: boolean = true;
  col_candidates: boolean = true;
  col_OpenPositions: boolean = false;
  col_interviews: boolean = true;
  col_upgradedDate: boolean = false;
  col_paymentDueDate: boolean = false;
  col_phoneNos: boolean = false;
  total_counter: number = 5;
  selectedClients: any = [];
  passClientData: any = {};
  activeClients: any;
  onholdClients: any;
  inactiveClients: any;
  status6: boolean = false;
  status7: boolean = false;
  status8: boolean = false;
  sortKey: any;
  reverse: any;
  currentTab: any = 'All';
  sortedTable: any;
  clientscheckboxes = [
    { label: 'col_CA', checked: true },
    { label: 'col_subscription', checked: true },
    { label: 'col_payment', checked: true },
    { label: 'col_candidates', checked: true },
    { label: 'col_interviews', checked: true },
    { label: 'col_OpenPositions', checked: false },
    { label: 'col_upgradedDate', checked: false },
    { label: 'col_paymentDueDate', checked: false },
    { label: 'col_phoneNos', checked: false },

  ];
  showWarning: boolean = false;
  clientCount: any = {
    active: '',
    inactive: ''
  };
  constructor(private clientService: ClientService,
    private authService: AuthService,
    private router: Router) { }

  ngOnInit(): void {
    this.currentTab = 'All';
    this.getAllClients();
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
  }
  sortTable(column: any): void {
    this.selectAll = false;
    for (let i = 0; i < this.clientsData.length; i++) {
      this.clientsItem[i] = false;
    }
    if (this.sortKey == column) {
      // If the same column is clicked again, reverse the order
      this.reverse = !this.reverse;
    } else {
      // If a new column is clicked, set the column and set ascending order
      this.sortKey = column;
      this.reverse = false;
    }
    if (column.startsWith('reverse-')) {
      this.reverse = !this.reverse;
      column = column.replace('reverse-', '');
    }
    if(column=='last_updated' || column=='payment_due'){
      this.clientsData.sort((a: any, b: any) => {
        var x:any = new Date(a[column]);
        var y:any = new Date(b[column]);
        return this.reverse ? y-x : x-y;
      });
    }
    else{
      this.clientsData.sort((a: any, b: any) => {
        var x = a[column];
        var y = b[column];
        if(column=='no_of_candidates' || column=='interviews_conducted' || column=='no_of_position'){
          return this.reverse ? y-x : x-y;
        }
        if (column == 'subscription') {
          if (x) { }
          else {
            x = 'Trial'
          }
          if (y) { }
          else {
            y = 'Trial'
          }
        }
        x = x.toString();
        y = y.toString();
        return this.reverse ? y.localeCompare(x) : x.localeCompare(y);
      });
    }
    this.setSortedTable(column)
  }
  setSortedTable(column: any) {
    this.sortedTable = column
    if (this.reverse) {
      this.sortedTable = "reverse-" + column
    }
    this.authService.updateSortedData({ table: "clients", column: this.sortedTable }).subscribe((data: any) => {
      // console.log(data)
      if (this.sortedTable.startsWith('reverse-')) {
        this.sortedTable = this.sortedTable.replace('reverse-', '');
      }
    })
  }
  getAllClients() {
    this.clientService.getAllClients().subscribe((data: any) => {
      this.clientsData = data.clients;
      this.allClientsData = this.clientsData
      this.trialPackage = this.clientsData.filter((el: any) => el.subscription == null)
      this.starterPackage = this.clientsData.filter((el: any) => el.subscription == 'Starter')
      this.growthPackage = this.clientsData.filter((el: any) => el.subscription == 'Growth')
      this.enterprisePackage = this.clientsData.filter((el: any) => el.subscription == 'Enterprise')
      this.activeClients = this.clientsData.filter((el: any) => el.status == 'active')
      this.onholdClients = this.clientsData.filter((el: any) => el.status == 'hold')
      this.inactiveClients = this.clientsData.filter((el: any) => el.status == 'inactive')
      this.passClientData.ac_count = this.activeClients.length;
      this.passClientData.inac_count = this.inactiveClients.length
      this.clickPos(this.currentTab);
      this.clientCount.active = this.activeClients.length
      this.clientCount.inactive = this.inactiveClients.length
      this.sortedTable = data.sort_by
      this.sortTable(this.sortedTable)
    })
  }

  selectColumns(i: number, e: any) {
    // [disabled]="!col_CA && (total_counter >4)"
    // if(this.total_counter>4){
    //   e.target.checked = false;
    //   return;
    // }
    let counter = 0
    for (let j = 0; j < this.clientscheckboxes.length; j++) {
      if (this.clientscheckboxes[j].checked) {
        counter++;
      }
    }
    if (e.target.checked)
      this.total_counter = counter - 1;
    else
      this.total_counter = counter;
    if (this.clientscheckboxes[i].checked) {
      this.total_counter++;
    } else {
      this.total_counter--;
    }

    if (this.total_counter > 5) {
      this.showWarning = true;
      e.target.checked = false;
      this.clientscheckboxes[i].checked = false;
    } else {
      // e.target.checked = true;
      this.showWarning = false;
    }

  }
  colClicked() {

  }
  searchText(text: any) {
    this.query = text;
  }

  clientActionFunc() {
    this.getAllClients();
  }
  selectClient(item: any) {
    let ob:any={
      'client_logo':item.logo,
      'client_name':item.company_name,
      'clientId':item.id
    }
    localStorage.setItem('clientData',JSON.stringify(ob))
    localStorage.setItem('clientId', item.id);
    this.router.navigate([this.authService.currentUserRoleCode + '/position']);
  }
  transformText(value: any) {
    const limit = 20;
    return value.length > limit ? value.split('').slice(0, limit).join('') + '...' : value;
  }
  clientSelect(item: any, value: boolean) {
    if (value) {
      this.selectedClients.push(item)
    }
    else {
      let i = 0;
      for (i = 0; i < this.selectedClients.length; i++) {
        if (this.selectedClients[i].id == item.id) {
          break;
        }
      }
      this.selectedClients.splice(i, 1);
    }
  }
  selectAllItems() {
    this.col_CA
    for (let i = 0; i < this.clientsData.length; i++) {
      this.clientsItem[i] = this.selectAll;
      this.clientSelect(this.clientsData[i], this.selectAll)
    }
  }
  clickPos(name: any) {
    for (let i = 0; i < this.clientsData.length; i++) {
      this.clientsItem[i] = false;
    }
    this.selectedClients = []
    this.selectAll = false;
    this.currentTab = name;
    this.status1 = false;
    this.status2 = false;
    this.status3 = false;
    this.status4 = false;
    this.status5 = false;
    this.status6 = false;
    this.status7 = false;
    this.status8 = false;
    this.passClientData.isInactive = false;
    if (name == 'All') {
      this.clientsData = this.allClientsData;
      this.status1 = true;
    }
    if (name == 'Trial') {
      this.clientsData = this.trialPackage;
      this.status2 = true;
    }
    if (name == 'Starter') {
      this.clientsData = this.starterPackage;
      this.status3 = true;
    }
    if (name == 'Growth') {
      this.clientsData = this.growthPackage;
      this.status4 = true;
    }
    if (name == 'Enterprise') {
      this.clientsData = this.enterprisePackage;
      this.status5 = true;
    }

    if (name == 'Active') {
      this.clientsData = this.activeClients;
      this.status6 = true;
    }
    if (name == 'Hold') {
      this.clientsData = this.onholdClients;
      this.status7 = true;
    }
    if (name == 'Inactive') {
      this.clientsData = this.inactiveClients;
      this.status8 = true;
      this.passClientData.isInactive = true;
    }
  }
  emitAction(item: any) {

  }

  isActive(item: any) {
    return 1;
  }
}
