import { Component, Input, OnInit } from '@angular/core';
import { HeaderComponent } from 'src/app/core/header/header.component';
import { AuthService } from 'src/app/services/auth.services';
import { CandidateService } from 'src/app/services/candidate.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as URL from './../../constants/url';
import {
  faMinus,
  faPlus,
  faEnvelope,
  faTrophy,
  faExclamation, faCheckCircle, faBalanceScale, faBars, faEllipsisH
} from '@fortawesome/free-solid-svg-icons';
import { HiringTeamService } from 'src/app/services/hiringteam.service';
import { PositionService } from 'src/app/services/position.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/core/toast/toast-service';
import { Router,ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-position-details',
  templateUrl: './position-details.component.html',
  styleUrls: ['./position-details.component.css']
})
export class PositionDetailsComponent implements OnInit {
  currentUserRoleCode: any;
  userRole: any;
  currentClient: any;
  logo: any;
  activeClientName: any;
  selectedCandidate: any;
  currentPosition: any;
  candidateList: any;
  hrGroupList: any;
  currentPositionData: any;
  public imgBaseUrl = URL.imgBaseUrl;
  faPlus = faPlus;
  faMinus = faMinus;
  faEnvelope = faEnvelope
  faTrophy = faTrophy
  faExclamation = faExclamation
  faCheckCircle = faCheckCircle
  faBalanceScale = faBalanceScale;
  faBars = faBars;
  faEllipsisH = faEllipsisH;
  daysToDeadline: any;
  percentageCompletion: any;
  deadlineDatePercentage: any;
  kickoffDate: any;
  sourcingDeadlineDate: any;
  targetDeadlineDate: any;
  selectedCan: any;
  alreadySelectedCand: any;
  showProgressSummary: boolean = false
  showInsights: boolean = false;
  selectedHtmArr: any = [];
  selectedHtm: any;
  selectedHtmId: any;
  allPositionData: any;
  candidateDataAgainstPosition: any;
  navLinkAction: any = ''
  public mailForm!: FormGroup;
  currentHTMWeightage: any;
  currentHTMname: any;
  CurrentpageURL: any;
  showProgressTable: boolean = true;
  showCalendarPage: boolean = false;
  selectedHtmArrForCalendar: any = [];
  skillData: any = '';
  ;
  constructor(private authService: AuthService,
    private candidateService: CandidateService,
    private hiringTeamService: HiringTeamService,
    private positionService: PositionService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastService: ToastService,
    private router : Router,
    private activatedRoute : ActivatedRoute) { }

  ngOnInit(): void {
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    let uerdata: any = localStorage.getItem('currentUser');
    uerdata = JSON.parse(uerdata);
    if (uerdata) {
      this.userRole = uerdata?.role;
    }
    this.currentClient = localStorage.getItem('clientId');
    // this.currentPosition = localStorage.getItem('currentPosition');
    this.currentPosition = this.activatedRoute.snapshot.params['positionId'];
    localStorage.setItem('currentPosition', this.currentPosition);
    this.navLinkAction = '';
    this.getPositionSummary()
    this.showProgressSummary = true;
    this.createMailForm();
    this.CurrentpageURL = window.location.href;
    this.getCandDetails();
    this.getPositionsCandidates(this.currentPosition);
    this.positionService.getPositionDataByPositionId(this.currentPosition).subscribe(res => {
      this.allPositionData = res;
      this.daysToDeadline = this.allPositionData?.data.position_completion?.daysToDeadline;
      this.percentageCompletion = this.allPositionData?.data.position_completion?.percentageCompletion;
      this.deadlineDatePercentage = this.daysToDeadline > 0 ? 100 - ((this.daysToDeadline / 30) * 100) : 100;
      this.allPositionData.data?.members.forEach((item) => {
        this.selectedHtmArrForCalendar.push(item.id);
      })
      console.log(this.allPositionData.data?.members)
      this.getPageSection()
    });
    this.selectedHtmArr = []
    
  }
  getPageSection(){
    this.navLinkAction = 'progress-summary';
    this.candidateService.getpositionPageSection().subscribe(val=>{
      // this.navLinkAction = val
      if(val=='Calendar' || val =='Insights')
        this.actionEmitter(val)
    })
  }
  getCandDetails() {
    this.candidateService.getSelectedCandidate().subscribe((val: any) => {
      if (val.id) {
        if (val.logo && val.logo !== 'None' && val.logo !== 'null') {
          this.logo = URL.imgBaseUrl + val.logo;
        } else {
          this.logo = URL.imgBaseUrl + './../../../assets/images/no-image.jpg';
        }
        this.activeClientName = val.company_name;
        this.selectedCandidate = val?.open_position_data?.filter((el: any) => el?.id == this.currentPosition)[0];
        if (this.selectedCandidate?.hiring_group !== undefined) {
          this.getPositionsCandidates(this.selectedCandidate.id);
        }
      }
    });
  }
  insightsAction(e: any) {
    this.getPositionsCandidates(this.currentPosition);
    this.positionService.getPositionDataByPositionId(this.currentPosition).subscribe(res => {
      this.allPositionData = res;
      this.daysToDeadline = this.allPositionData?.data.position_completion?.daysToDeadline;
      this.percentageCompletion = this.allPositionData?.data.position_completion?.percentageCompletion;
      this.deadlineDatePercentage = this.daysToDeadline > 0 ? 100 - ((this.daysToDeadline / 30) * 100) : 100;
      this.candidateDataAgainstPosition = this.allPositionData.data.candidates_data.find((el: any) => el.candidate_id === this.selectedCan.candidate_id);
    });
  }
  selectCan(can: any) {
    // || can.withdrawed
    if (can.requested) {
      return;
    }
    this.navLinkAction = 'Insights';
    this.selectedCan = can;
    this.showProgressSummary = false;
    this.showCalendarPage = false;
    this.showInsights = true;
    this.candidateDataAgainstPosition = this.allPositionData.data.candidates_data.find((el: any) => el.candidate_id === this.selectedCan.candidate_id);
    this.modifyInsights()
  }
  selectCandidateFromSummary(e: any) {
    this.selectCan(e);
  }
  selectHtm(htm: any) {
    const index = this.selectedHtmArr?.indexOf(htm.id);
    if (index > -1) {
      this.selectedHtmArr.splice(index, 1);
    } else {
      this.selectedHtmArr.push(htm.id);
    }
    this.selectedHtm = htm;
    this.selectedHtmId = htm?.id;
    this.modifyInsights()
  }

  modifyInsights() {
    if (this.selectedHtmArr.length < 2 && this.selectedHtmArr.length > 0) {
      const tempdata = this.candidateDataAgainstPosition.marks_by_htms.find((el: any) => el.htm == this.selectedHtmArr[0])
      if (tempdata) {
        this.skillData = { ...tempdata, showGraph: false }
      } else {
        this.skillData = { showGraph: false }
      }
    }
    if (this.selectedHtmArr.length <= 0) {
      this.skillData = { showGraph: false }
    }
    if (this.selectedHtmArr.length > 1) {
      const graphData = convertData(this.candidateDataAgainstPosition, this.selectedHtmArr, this.allPositionData.data?.members)
      if (graphData.length < 2) {
        this.skillData = { showGraph: true, graphData: [], showNoData: true }
      } else {
        this.skillData = { showGraph: true, graphData, showNoData: false }
      }
      console.log(graphData)
    }
  }

  getPositionsCandidates(id: any) {
    this.candidateService.getPositionsCandidates(id).subscribe((val: any) => {
      if (val) {
        if (val) {
          this.candidateList = val.sort(function (x: any, y: any) { return x.requested - y.requested });
          this.candidateList = val.sort(function (x: any, y: any) { return x.withdrawed - y.withdrawed });
          // this.getPageSection()
        }
      }
    });
  }

  emitAction(item: any) {

  }

  isActive(item: any) {
    return 1;
  }

  getPositionSummary() {
    this.kickoffDate = null;
    this.sourcingDeadlineDate = null;
    this.targetDeadlineDate = null;
    // const op_id = localStorage.getItem('currentPosition');
    const op_id = this.activatedRoute.snapshot.params['positionId'];
    this.hiringTeamService.getPositionSummary(op_id).subscribe((res: any) => {
      this.kickoffDate = res.full_kickoff
      this.sourcingDeadlineDate = res.full_sourcing
      this.targetDeadlineDate = res.full_target
    })
  }
  formatDate(inputDate: any) {
    // Create a new Date object from the input string
    var date = new Date(inputDate);

    // Define months array to convert numeric month to its name
    var months = [
      "January", "February", "March", "April",
      "May", "June", "July", "August",
      "September", "October", "November", "December"
    ];

    // Extract day, month, and year from the Date object
    var day: any = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    var month = months[date.getMonth()];
    var year = date.getFullYear();

    // Create the formatted date string
    var formattedDate = month +' '+ day + ', ' + year;

    return formattedDate;
  }
  openSm(content: any, head: any) {
    this.mailForm.controls.email.patchValue(head.email)
    this.modalService.open(content, { size: 'md' });
  }

  openWeightingModal(weight: any, htm: any) {
    this.currentHTMname = htm.name;
    this.currentHTMWeightage = htm.weightage;
    this.modalService.open(weight, { size: 'md' });
  }

  createMailForm() {
    this.mailForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
      subject: ['', Validators.required],
      message: [''],
    });
  }

  sentMail() {
    this.candidateService
      .sendMail(this.mailForm.value)
      .subscribe((val: any) => {
        if (val.error) {
          this.showDanger(val.error);
          this.mailForm.reset();
          this.modalService.dismissAll();
        } else {
          this.showSuccess('Mail sent successfully');
          this.mailForm.reset();
          this.modalService.dismissAll();
        }
      });
  }
  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }
  actionEmitter(e: any) {
    this.selectedHtmArr = []
    if (e == 'progress-summary') {
      this.navLinkAction = 'progress-summary';
      this.showProgressTable = true;
      this.showCalendarPage = false;
      this.selectedCan = ''
      this.showProgressSummary = true;
      this.showInsights = false;
      this.getCandDetails()
      this.insightsAction('');
    }
    if (e == 'Calendar') {
      this.navLinkAction = 'Calendar';
      this.showCalendarPage = true;
      this.showProgressTable = true;
      this.showInsights = false;
      this.showProgressSummary = false;
      this.selectedCan = '';
    }
    if (e == 'Insights') {
      this.showProgressTable = true;
      this.showCalendarPage = false;
      this.showProgressSummary = false;
      this.selectCan(this.candidateList[0]);
    }
    if (e == 'add-candidate') {
      this.navLinkAction = '';
      this.showProgressTable = false;
      this.showCalendarPage = false;
      this.showProgressSummary = false;

    }
  }

}


function convertData(obj: any, htmArray: any, htmData: any) {
  let result: any = [];
  let skills: any = ['skills'];
  let skillset: any = [];
  let marks: any = [];

  //Get htms from selected htms from htmArray
  htmArray.forEach(htm => {
    const obj_marks_by_htm = obj.marks_by_htms.find(htmObj => htmObj.htm === htm);
    if (obj_marks_by_htm) {
      skills.push(htm);
    }
  });

  //Get skillset
  if (obj.marks_by_htms.length === 0) {
    return result;
  }
  obj.marks_by_htms[0].marks.forEach(mark => {
    skillset.push(mark.skillset_name);
  })

  //Get marks
  for (let i = 1; i < skills.length; i++) {
    const obj_marks_by_htm = obj.marks_by_htms.find(htmObj => htmObj.htm === skills[i])
    skillset.forEach(skill => {
      const mark = obj_marks_by_htm.marks.find(mark => mark.skillset_name === skill);
      const marksIndex = marks.findIndex(mark => mark[0] === skill);
      if (marksIndex !== -1) {
        marks[marksIndex].push(mark ? mark.skillset_marks : 0);
      } else {
        marks.push([skill, mark ? mark.skillset_marks : 0]);
      }
    })

  }

  // get htm names
  for (let i = 1; i < skills.length; i++) {
    const htmIndex = htmData.findIndex(htm => htm.id === skills[i]);
    if (htmIndex !== -1) {
      skills[i] = htmData[htmIndex].name;
    }
  }

  // add average marks
  skills.splice(1, 0, 'Average');
  marks.forEach(mark => {
    const average = mark.slice(1).reduce((a, b) => a + b) / (mark.length - 1);
    mark.splice(1, 0, average);

  })

  result.push(skills);
  result.push(...marks);
  return result;
}