import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Router } from '@angular/router';
import { NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ToastService } from 'src/app/core/toast/toast-service';
import { AuthService } from 'src/app/services/auth.services';
import { DashboardService } from 'src/app/services/dashboard.service';
import { HiringTeamService } from 'src/app/services/hiringteam.service';
import { UserService } from 'src/app/services/user.service';
import { ClientService } from 'src/app/services/client.service'
import * as Url from './../../constants/url';
import { EmailEditorComponent } from 'angular-email-editor';
declare const callStripe: any;
// declare var StripeCheckout: StripeCheckoutStatic;   
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  settingsForm!: FormGroup;
  proTipForm!: FormGroup;
  globalPricingForm!: FormGroup;
  companyInfoForm!: FormGroup;
  companyContactForm!: FormGroup;
  accLevelForm!: FormGroup;
  saAccLevelForm!: FormGroup;
  billingInfoForm!: FormGroup
  // accountDetailsForm!: FormGroup;
  faInfo = faInfo;
  imageURL: string = '';
  faTrash = faTrash;
  userRole: any;
  currentUserRoleCode: any;
  userData: any;
  activeIds: any = '';
  public passwordForm!: FormGroup;
  public faCalendar = faCalendarAlt;
  showError = false;
  panels = ['First', 'Second', 'Third'];
  candidateAddedForSM = '';
  candidateAddedForCan = '';
  interviewSetForCan = '';
  goldenGloveEmail = '';
  htmAddedEmail = '';
  canAddedEmailToHtm = '';
  interviewReminderEmail = '';
  passwordResetEmail = '';
  positionCreatedEmail = '';
  qorumsWelcomeEmail = '';
  userCreatedEmail = '';
  candidateAddedForSMObj: any = '';
  candidateAddedForCanObj: any = '';
  interviewSetForCanObj: any = '';
  goldenGloveEmailObj: any = '';
  htmAddedEmailObj: any = '';
  canAddedEmailToHtmObj: any = '';
  interviewReminderEmailObj: any = '';
  passwordResetEmailObj: any = '';
  positionCreatedEmailObj: any = '';
  qorumsWelcomeEmailObj: any = '';
  userCreatedEmailObj: any = '';
  packageDetails: any = [];
  startPrice: any;
  growthPrice: any;
  enterPrice: any;
  otpNum: any;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  allEmailTemplates: any;
  initialUserDetailsFormValue: any;
  initialProTipFormValue: any;
  isPanelOpen: boolean = false;
  openPanelId: any = "userDetails";
  showUserDetails = true;
  showProTipForm = false;
  showEmailTempForm = false;
  showAccDetails = false;
  showBillingDetails = false;
  showGlobalPricing = false;
  showClientAccounts = false;
  emailTemplateType = 'all';
  billingSectionType = 'all';
  accountDetailsSection = 'all';
  clientDetails = 'all';
  ascendingOrder: boolean = true;
  currentSortKey: any = '';
  sortedSampleBills: any = [];
  invoices: any = [];
  showPassword: boolean = false;
  isUppercaseValid: boolean = false;
  isLowecaseValid: boolean = false;
  isNumberValid: boolean = false;
  isSymbolValid: boolean = false;
  billingAddress: boolean = false;
  selectedOption: any = 'Starter'

  curr_charge: any = 0;


  // dummy bills accordiaon data for sorting
  sampleBills: any = [
    { invoiceNumber: 'Invoice#0093', date: 'Apr 2020', status: 'Failed', period: 'June 12-14,2020', amount: '$7626.00' },
    { invoiceNumber: 'Invoice#0094', date: 'May 2020', status: 'Success', period: 'June 15-18,2020', amount: '$7678.01' },
    { invoiceNumber: 'Invoice#0091', date: 'Aug 2020', status: 'Success', period: 'June 11,2020', amount: '$7678.00' },
    { invoiceNumber: 'Invoice#0096', date: 'Jan 2020', status: 'Success', period: 'June 1-7,2020', amount: '$7687.00' },

  ];
  showCardDetails: boolean = false;
  showClientDetails: any = [];
  selectedClientId: any;
  lastFourDigits: any;
  clientList: any;
  clientIdForSA: any;
  handler: any;
  isDownload: boolean = true;
  selectedInvoice: any;
  logoImageURL: string = '';

  stateList: any = [
    { name: "Alabama", abbreviation: "AL" },
    { name: "Alaska", abbreviation: "AK" },
    { name: "Arizona", abbreviation: "AZ" },
    { name: "Arkansas", abbreviation: "AR" },
    { name: "California", abbreviation: "CA" },
    { name: "Colorado", abbreviation: "CO" },
    { name: "Connecticut", abbreviation: "CT" },
    { name: "Delaware", abbreviation: "DE" },
    { name: "Florida", abbreviation: "FL" },
    { name: "Georgia", abbreviation: "GA" },
    { name: "Hawaii", abbreviation: "HI" },
    { name: "Idaho", abbreviation: "ID" },
    { name: "Illinois", abbreviation: "IL" },
    { name: "Indiana", abbreviation: "IN" },
    { name: "Iowa", abbreviation: "IA" },
    { name: "Kansas", abbreviation: "KS" },
    { name: "Kentucky", abbreviation: "KY" },
    { name: "Louisiana", abbreviation: "LA" },
    { name: "Maine", abbreviation: "ME" },
    { name: "Maryland", abbreviation: "MD" },
    { name: "Massachusetts", abbreviation: "MA" },
    { name: "Michigan", abbreviation: "MI" },
    { name: "Minnesota", abbreviation: "MN" },
    { name: "Mississippi", abbreviation: "MS" },
    { name: "Missouri", abbreviation: "MO" },
    { name: "Montana", abbreviation: "MT" },
    { name: "Nebraska", abbreviation: "NE" },
    { name: "Nevada", abbreviation: "NV" },
    { name: "New Hampshire", abbreviation: "NH" },
    { name: "New Jersey", abbreviation: "NJ" },
    { name: "New Mexico", abbreviation: "NM" },
    { name: "New York", abbreviation: "NY" },
    { name: "North Carolina", abbreviation: "NC" },
    { name: "North Dakota", abbreviation: "ND" },
    { name: "Ohio", abbreviation: "OH" },
    { name: "Oklahoma", abbreviation: "OK" },
    { name: "Oregon", abbreviation: "OR" },
    { name: "Pennsylvania", abbreviation: "PA" },
    { name: "Rhode Island", abbreviation: "RI" },
    { name: "South Carolina", abbreviation: "SC" },
    { name: "South Dakota", abbreviation: "SD" },
    { name: "Tennessee", abbreviation: "TN" },
    { name: "Texas", abbreviation: "TX" },
    { name: "Utah", abbreviation: "UT" },
    { name: "Vermont", abbreviation: "VT" },
    { name: "Virginia", abbreviation: "VA" },
    { name: "Washington", abbreviation: "WA" },
    { name: "West Virginia", abbreviation: "WV" },
    { name: "Wisconsin", abbreviation: "WI" },
    { name: "Wyoming", abbreviation: "WY" }
  ];
  logoImageName: string = '';
  showLogo: boolean = true;
  showProfilePhoto: boolean = true;
  showExpiryDate: boolean = false;
  current_charge_tooltip: any;
  future_charge_tooltip: any;
  caTrialSelected: boolean = false;
  caTrialExpiryDate: any;
  companyInfoFormHasError: boolean = false;
  compContactForSAHasError: boolean = false;
  companyContFormHasError: boolean = false;
  settingFormHasError: boolean = false;
  showNavigator: boolean = false;
  navigatorType: string;
  navigators: any = [];
  @ViewChild(EmailEditorComponent)
  private emailEditor: EmailEditorComponent;
  showopenPositionDetails: boolean;
  posPrivacyData: any;
  candidateSignupEmail: any;
  inviteUsersEmail: any;
  inviteCandidateEmail: any;
  reinviteUserEmail: any;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private clientService: ClientService,
    private toastService: ToastService,
    private hiringTeamService: HiringTeamService,
    private modalService: NgbModal,
    private dashboardService: DashboardService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    this.getGlobalPricingFormData();
    let uerdata: any = localStorage.getItem('currentUser');
    uerdata = JSON.parse(uerdata);
    this.userData = uerdata;
    if (uerdata) {
      this.userRole = uerdata?.role;
    }
    this.createForm();
    this.createpForm();
    if (this.userRole !== 'Client Admin' && this.userRole !== 'Account Manager') {
      this.getUserProfile(uerdata);
    }
    if (this.userRole === 'Superuser' || this.userRole === 'Account Manager') {
      this.getNavigatorList()
      this.createProTipForm();
      this.getProTipFormData();
      this.createGlobalPricingForm();
      this.getClientList();
      this.createCompanyInfoForm();
      this.createCompanyContactForm();
      this.createBillingInfoForm();
      this.createSAQSAccLevelForm();

    }
    if (this.userRole === 'Client Admin') {
      this.createCompanyInfoForm();
      this.getCompanyInfoFormData()
      this.createCompanyContactForm();
      this.getCompanyContact()
      this.createAccLevelForm();
      this.createBillingInfoForm();
      this.getBillingInfo();
      this.getUserProfile(uerdata);
      this.createInvoices();
      this.getPositionToggleData();
    }

    this.dashboardService.getAllEmailTemplates().subscribe((res: any) => {
      this.allEmailTemplates = res;
      this.candidateAddedForSMObj = this.allEmailTemplates?.data?.filter((el: any) => el.name === 'Candidate Added Email for Senior Manager')[0];
      this.candidateAddedForSM = this.candidateAddedForSMObj?.content;

      this.candidateAddedForCanObj = this.allEmailTemplates?.data?.filter((el: any) => el.name === 'Candidate Added Email for Candidate')[0];
      this.candidateAddedForCan = this.candidateAddedForCanObj?.content;

      this.interviewSetForCanObj = this.allEmailTemplates?.data?.filter((el: any) => el.name === 'Interview Set Email for Candidate')[0];
      this.interviewSetForCan = this.interviewSetForCanObj?.content;

      this.goldenGloveEmailObj = this.allEmailTemplates?.data?.filter((el: any) => el.name === 'Golden Glove Email')[0];
      this.goldenGloveEmail = this.goldenGloveEmailObj?.content;

      this.htmAddedEmailObj = this.allEmailTemplates?.data?.filter((el: any) => el.name === 'HTM Added Email to Candidate')[0];
      this.htmAddedEmail = this.htmAddedEmailObj?.content;

      this.canAddedEmailToHtmObj = this.allEmailTemplates?.data?.filter((el: any) => el.name === 'Candidate Added Email to HTM')[0];
      this.canAddedEmailToHtm = this.canAddedEmailToHtmObj?.content;

      this.interviewReminderEmailObj = this.allEmailTemplates?.data?.filter((el: any) => el.name === 'Interview Reminder to Candidate')[0];
      this.interviewReminderEmail = this.interviewReminderEmailObj?.content;

      this.passwordResetEmailObj = this.allEmailTemplates?.data?.filter((el: any) => el.name === 'Password Reset')[0];
      this.passwordResetEmail = this.passwordResetEmailObj?.content;

      this.positionCreatedEmailObj = this.allEmailTemplates?.data?.filter((el: any) => el.name === 'Position Created')[0];
      this.positionCreatedEmail = this.positionCreatedEmailObj?.content;

      this.qorumsWelcomeEmailObj = this.allEmailTemplates?.data?.filter((el: any) => el.name === 'Qorums Welcome Mail to CA')[0];
      this.qorumsWelcomeEmail = this.qorumsWelcomeEmailObj?.content;

      this.userCreatedEmailObj = this.allEmailTemplates?.data?.filter((el: any) => el.name === 'User created')[0];
      this.userCreatedEmail = this.userCreatedEmailObj?.content;

      this.candidateSignupEmail = this.allEmailTemplates?.data?.filter((el: any) => el.name === 'Candidate Sign-up Confirmation')[0];
      this.inviteUsersEmail = this.allEmailTemplates?.data?.filter((el: any) => el.name === 'Invite User')[0];
      this.inviteCandidateEmail = this.allEmailTemplates?.data?.filter((el: any) => el.name === 'Invite Candidate')[0];
      this.reinviteUserEmail = this.allEmailTemplates?.data?.filter((el: any) => el.name === 'Re-Invite User')[0];
    });

    this.sortedSampleBills = [...this.sampleBills];
  }

  getClientList() {
    this.userService.getClientList().subscribe((res: any) => {
      this.clientList = res?.data;
      this.activeIds = this.clientList[0]?.id
    })
  }

  trialSelected() {
    this.showExpiryDate = true;
    this.saAccLevelForm.controls["package_id"]?.setValue(0);
  }
  createInvoices(id?: any) {
    var c_id;
    if (id) {
      c_id = id;
    }
    else
      c_id = JSON.parse(localStorage.getItem('clientId') || '');
    this.userService.getInvoice(c_id).subscribe((res?: any) => {
      console.log(res?.data)
      this.invoices = res?.data;
      console.log(this.invoices)

    })
  }

  selectInvoice(e: any) {
    this.selectedInvoice = '';
    console.log(e.target.value)
    this.isDownload = false;
    this.selectedInvoice = e.target.value;
  }
  downloadInvoice() {
    this.userService.printInvoice(this.selectedInvoice).subscribe((data: any) => {

      const blob = new Blob([data], { type: 'application/pdf' });

      var downloadURL = window.URL.createObjectURL(blob);
      window.open(downloadURL);
      // var link = document.createElement('a');
      // link.href = downloadURL;
      // link.download = "help.pdf";
      // link.click();

    });
  }
  takeAction(payModal: any, key: any, skey: any) {
    const pUrl = Url.uiBaseUrl + 'thankyou'
    this.modalService.open(payModal, { size: 'md' });
    callStripe(key, skey, pUrl);
  }
  createProTipForm() {
    this.proTipForm = this.fb.group({
      dashboard_protip: [''],
      new_position_protip: [''],
      candidate_protip: [''],
      candidate_adv_search_protip: [''],
      team_members_protip: [''],
      hiring_teams_protip: [''],
      senior_manager_protip: [''],
      settings_protip: [''],
      support_protip: [''],
      candidate_dash_protip: [''],
      client_protip: [''],
      position_protip: [''],
      three_col_page_protip: [''],
      qorums_overview: [''],
      managing_users: [''],
      hiring_process: [''],
      managing_candidates: [''],
      managing_positions: ['']
    });
  }

  getProTipFormData() {
    this.userService.getProTip().subscribe((res: any) => {
      for (var key in res?.data) {
        if (res?.data.hasOwnProperty(key)) {
          this.proTipForm.controls[key]?.setValue(res?.data[key]);
        }
      }
      this.initialProTipFormValue = JSON.stringify(this.proTipForm.value);
    });
  }

  createSAQSAccLevelForm() {
    this.saAccLevelForm = this.fb.group({
      client: [''],
      package_id: [''],
      package1: [''],
      pack_sm: [''],
      pack_hm: [''],
      pack_htm: [''],
      senior_managers: [''],
      sm_price: [''],
      hiring_managers: [''],
      hm_price: [''],
      hiring_team_members: [''],
      htm_price: [''],
      contributors: [''],
      tc_price: [''],
      add_sm: ['0', Validators.required],
      add_hm: ['0', Validators.required],
      add_htm: ['0', Validators.required],
      add_sm_price: ['0'],
      add_hm_price: ['0'],
      add_htm_price: ['0'],
      current_charges: [''],
      additional_charges: [''],
      future_charges: [''],
      trial_expired: ['']
    })
  }

  getSaAccLevelFormData(id: any) {
    this.getGlobalPricingFormData()
    var x = this.packageDetails;
    this.createSAQSAccLevelForm()
    this.userService.getPackageInfo(id).subscribe((res: any) => {
      for (var key in res?.data) {
        if (res?.data.hasOwnProperty(key)) {
          this.saAccLevelForm.controls[key]?.setValue(res?.data[key]);
        }
      }
      if (res?.data?.package_id !== 0) {
        this.saAccLevelForm.controls['package1']?.setValue(res?.data['package']);
      }
      else {
        this.saAccLevelForm.controls['package1']?.setValue('Trial')
        this.trialSelected()
        let d = this.saAccLevelForm.value.trial_expired.split("-");
        let newDate = d[1] + '-' + d[2] + '-' + d[0];
        // data.trial_expired = this.saAccLevelForm.value.trial_expired.split("-").reverse().join("-");
        this.saAccLevelForm.controls['trial_expired'].setValue(newDate)
      }

      // set dynamic tooltip data
      if (res?.data?.package_id) {
        this.current_charge_tooltip = this.packageDetails.find((el: any) => el.id == res?.data?.package_id)?.current_charge_tooltip;
        this.future_charge_tooltip = this.packageDetails.find((el: any) => el.id == res?.data?.package_id)?.future_charge_tooltip;
      }

      this.saAccLevelForm.controls['current_charges']?.setValue(res?.data.overall_price);
      // this.saAccLevelForm.controls['future_charges']?.setValue(res?.data.overall_price);
      for (let i = 0; i < this.packageDetails.length; i++) {
        if (this.packageDetails[i].name == res?.data['package']) {
          this.saAccLevelForm.controls["pack_sm"]?.setValue(this.packageDetails[i].senior_managers);
          this.saAccLevelForm.controls["pack_hm"]?.setValue(this.packageDetails[i].hiring_managers);
          this.saAccLevelForm.controls["pack_htm"]?.setValue(this.packageDetails[i].hiring_team_members);
        }
      }
      console.log(this.saAccLevelForm.value)
      if (this.saAccLevelForm.value.package_id !== 0) {
        this.userService.getExtraPackageInfo(this.saAccLevelForm.value.package_id).subscribe((r: any) => {
          for (var key in r?.data) {
            if (r?.data.hasOwnProperty(key)) {
              this.saAccLevelForm.controls[key]?.setValue(r?.data[key]);
            }
          }

          console.log(this.saAccLevelForm.value)
        })
      }

    });
  }

  cancelPackageChangesForSA() {
    this.getSaAccLevelFormData(this.clientIdForSA)
    this.showExpiryDate = false;
  }

  packValueChanged(e: any) {

    if (e.target.value) {
      if (e.target.getAttribute('formControlName') == "add_sm") {
        let data: any = {};
        data.client = this.clientIdForSA;
        data.package_id = this.saAccLevelForm.value.package_id
        data.ext_senior_managers = this.saAccLevelForm.value.add_sm;
        data.ext_hiring_managers = this.saAccLevelForm.value.add_hm;
        data.ext_hiring_team_members = this.saAccLevelForm.value.add_htm;
        const amount = (parseInt(e.target.value)) * parseInt(this.saAccLevelForm.value.sm_price)
        this.saAccLevelForm.controls["add_sm_price"]?.setValue(amount);
        this.userService.calAdditionalAmt(data).subscribe((res: any) => {
          this.saAccLevelForm.controls["additional_charges"]?.setValue(res.additional_amt)
          this.saAccLevelForm.controls['future_charges']?.setValue(res.future_charge)

        })
      }

      if (e.target.getAttribute('formControlName') == "add_hm") {
        let data: any = {};
        data.client = this.clientIdForSA;
        data.package_id = this.saAccLevelForm.value.package_id
        data.ext_senior_managers = this.saAccLevelForm.value.add_sm;
        data.ext_hiring_managers = this.saAccLevelForm.value.add_hm;
        data.ext_hiring_team_members = this.saAccLevelForm.value.add_htm;
        const amount = (parseInt(e.target.value)) * parseInt(this.saAccLevelForm.value.hm_price)
        this.saAccLevelForm.controls["add_hm_price"]?.setValue(amount);
        this.userService.calAdditionalAmt(data).subscribe((res: any) => {
          this.saAccLevelForm.controls["additional_charges"]?.setValue(res.additional_amt)
          this.saAccLevelForm.controls['future_charges']?.setValue(res.future_charge)

        })
      }

      if (e.target.getAttribute('formControlName') == "add_htm") {
        let data: any = {};
        data.client = this.clientIdForSA;
        data.package_id = this.saAccLevelForm.value.package_id
        data.ext_senior_managers = this.saAccLevelForm.value.add_sm;
        data.ext_hiring_managers = this.saAccLevelForm.value.add_hm;
        data.ext_hiring_team_members = this.saAccLevelForm.value.add_htm;
        const amount = (parseInt(e.target.value)) * parseInt(this.saAccLevelForm.value.htm_price)
        this.saAccLevelForm.controls["add_htm_price"]?.setValue(amount);
        this.userService.calAdditionalAmt(data).subscribe((res: any) => {
          this.saAccLevelForm.controls["additional_charges"]?.setValue(res.additional_amt)
          this.saAccLevelForm.controls['future_charges']?.setValue(res.future_charge)

        })
      }

    }

  }

  createAccLevelForm() {
    this.accLevelForm = this.fb.group({
      client: [''],
      package_id: [''],
      package1: [''],
      pack_sm: [''],
      pack_hm: [''],
      pack_htm: [''],
      senior_managers: [''],
      sm_price: [''],
      hiring_managers: [''],
      hm_price: [''],
      hiring_team_members: [''],
      htm_price: [''],
      contributors: [''],
      tc_price: [''],
      add_sm: ['0', Validators.required],
      add_hm: ['0', Validators.required],
      add_htm: ['0', Validators.required],
      add_sm_price: ['0'],
      add_hm_price: ['0'],
      add_htm_price: ['0'],
      current_charges: [''],
      additional_charges: ['0'],
      future_charges: [''],
      trial_expired: [''],
    })
    this.getGlobalPricingFormData()
    this.getAccLevelFormData();
  }

  PackChanged(e: any) {
    //console.log(e.target.value)
    this.caTrialChanged(false);
    for (let i = 0; i < this.packageDetails.length; i++) {
      if (this.packageDetails[i].name == e.target.value) {
        this.accLevelForm.controls["sm_price"]?.setValue(this.packageDetails[i].extra_price.sm_price);
        this.accLevelForm.controls["hm_price"]?.setValue(this.packageDetails[i].extra_price.hm_price);
        this.accLevelForm.controls["htm_price"]?.setValue(this.packageDetails[i].extra_price.htm_price);
        this.accLevelForm.controls["tc_price"]?.setValue(this.packageDetails[i].extra_price.tc_price);
        this.accLevelForm.controls["package_id"]?.setValue(this.packageDetails[i].id);

        this.accLevelForm.controls["pack_sm"]?.setValue(this.packageDetails[i].senior_managers);
        this.accLevelForm.controls["pack_hm"]?.setValue(this.packageDetails[i].hiring_managers);
        this.accLevelForm.controls["pack_htm"]?.setValue(this.packageDetails[i].hiring_team_members);
      }
    }
    this.accLevelForm.controls["add_sm"]?.setValue(0);
    this.accLevelForm.controls["add_hm"]?.setValue(0);
    this.accLevelForm.controls["add_htm"]?.setValue(0);
    this.accLevelForm.controls["add_sm_price"]?.setValue(0);
    this.accLevelForm.controls["add_hm_price"]?.setValue(0);
    this.accLevelForm.controls["add_htm_price"]?.setValue(0);
    this.accLevelForm.controls["additional_charges"]?.setValue(0)
    this.accLevelForm.controls['future_charges']?.setValue('');
  }
  getAccLevelFormData() {
    var c_id = JSON.parse(localStorage.getItem('clientId') || '');
    var x = this.packageDetails;
    this.userService.getPackageInfo(c_id).subscribe((res: any) => {
      for (var key in res?.data) {
        if (res?.data.hasOwnProperty(key)) {
          this.accLevelForm.controls[key]?.setValue(res?.data[key]);
        }
      }
      if (res?.data?.package_id !== 0) {
        this.accLevelForm.controls['package1']?.setValue(res?.data['package']);
        this.caTrialChanged(false)
      }
      else {
        this.accLevelForm.controls['package1']?.setValue('Trial')
        this.accLevelForm.controls['trial_expired'].setValue(res?.data['trial_expired'])
        this.caTrialChanged(true)
        this.caTrialExpiryDate = this.accLevelForm.value.trial_expired

      }

      //this.accLevelForm.controls['package1']?.setValue(res?.data['package']);
      this.accLevelForm.controls['current_charges']?.setValue(res?.data.overall_price);
      // this.accLevelForm.controls['future_charges']?.setValue(diff+parseInt(this.accLevelForm.value.additional_charges));
      for (let i = 0; i < this.packageDetails.length; i++) {
        if (this.packageDetails[i].name == res?.data['package']) {
          this.accLevelForm.controls["pack_sm"]?.setValue(this.packageDetails[i].senior_managers);
          this.accLevelForm.controls["pack_hm"]?.setValue(this.packageDetails[i].hiring_managers);
          this.accLevelForm.controls["pack_htm"]?.setValue(this.packageDetails[i].hiring_team_members);
        }
      }
      if (this.accLevelForm.value.package_id !== 0) {
        this.userService.getExtraPackageInfo(this.accLevelForm.value.package_id).subscribe((r: any) => {
          for (var key in r?.data) {
            if (r?.data.hasOwnProperty(key)) {
              this.accLevelForm.controls[key]?.setValue(r?.data[key]);
            }
          }
          console.log(this.accLevelForm.value)
        })
      }
    });
  }

  caTrialChanged(item: boolean) {
    this.caTrialSelected = item;
  }

  valueChanged(e: any) {
    if (e.target.value) {

      if (e.target.getAttribute('formControlName') == "add_sm") {
        let data: any = {};
        const c_id = JSON.parse(localStorage.getItem('clientId') || '')
        data.client = c_id;
        data.package_id = this.accLevelForm.value.package_id
        data.ext_senior_managers = this.accLevelForm.value.add_sm;
        data.ext_hiring_managers = this.accLevelForm.value.add_hm;
        data.ext_hiring_team_members = this.accLevelForm.value.add_htm;
        const amount = parseInt(e.target.value) * parseInt(this.accLevelForm.value.sm_price)
        this.accLevelForm.controls["add_sm_price"]?.setValue(amount);
        this.userService.calAdditionalAmt(data).subscribe((res: any) => {
          console.log(res.additional_amt)
          this.accLevelForm.controls["additional_charges"]?.setValue(res.additional_amt)
          this.accLevelForm.controls['future_charges']?.setValue(res.future_charge || 'NA')
        })
      }

      if (e.target.getAttribute('formControlName') == "add_hm") {
        let data: any = {};
        const c_id = JSON.parse(localStorage.getItem('clientId') || '')
        data.client = c_id;
        data.package_id = this.accLevelForm.value.package_id
        data.ext_hiring_managers = this.accLevelForm.value.add_hm;
        data.ext_senior_managers = this.accLevelForm.value.add_sm;
        data.ext_hiring_team_members = this.accLevelForm.value.add_htm;

        const amount = parseInt(e.target.value) * parseInt(this.accLevelForm.value.hm_price)
        this.accLevelForm.controls["add_hm_price"]?.setValue(amount);
        this.userService.calAdditionalAmt(data).subscribe((res: any) => {
          console.log(res.additional_amt)
          this.accLevelForm.controls["additional_charges"]?.setValue(res.additional_amt)
          this.accLevelForm.controls['future_charges']?.setValue(res.future_charge || 'NA')
        })
      }

      if (e.target.getAttribute('formControlName') == "add_htm") {
        let data: any = {};
        const c_id = JSON.parse(localStorage.getItem('clientId') || '')
        data.client = c_id;
        data.package_id = this.accLevelForm.value.package_id
        data.ext_senior_managers = this.accLevelForm.value.add_sm;
        data.ext_hiring_managers = this.accLevelForm.value.add_hm;
        data.ext_hiring_team_members = this.accLevelForm.value.add_htm;
        const amount = parseInt(e.target.value) * parseInt(this.accLevelForm.value.htm_price)
        this.accLevelForm.controls["add_htm_price"]?.setValue(amount);
        this.userService.calAdditionalAmt(data).subscribe((res: any) => {
          console.log(res.additional_amt)
          this.accLevelForm.controls["additional_charges"]?.setValue(res.additional_amt)
          this.accLevelForm.controls['future_charges']?.setValue(res.future_charge || 'NA')
        })
      }
    }
  }

  packChangedForSA(e: any) {
    //console.log(e.target.value)
    this.showExpiryDate = false;
    for (let i = 0; i < this.packageDetails.length; i++) {
      if (this.packageDetails[i].name == e.target.value) {
        this.saAccLevelForm.controls["sm_price"]?.setValue(this.packageDetails[i].extra_price.sm_price);
        this.saAccLevelForm.controls["hm_price"]?.setValue(this.packageDetails[i].extra_price.hm_price);
        this.saAccLevelForm.controls["htm_price"]?.setValue(this.packageDetails[i].extra_price.htm_price);
        this.saAccLevelForm.controls["tc_price"]?.setValue(this.packageDetails[i].extra_price.tc_price);
        this.saAccLevelForm.controls["package_id"]?.setValue(this.packageDetails[i].id);

        this.saAccLevelForm.controls["pack_sm"]?.setValue(this.packageDetails[i].senior_managers);
        this.saAccLevelForm.controls["pack_hm"]?.setValue(this.packageDetails[i].hiring_managers);
        this.saAccLevelForm.controls["pack_htm"]?.setValue(this.packageDetails[i].hiring_team_members);

        // set dynamic tooltip data
        this.current_charge_tooltip = this.packageDetails[i].current_charge_tooltip;
        this.future_charge_tooltip = this.packageDetails[i].future_charge_tooltip;
      }
    }
    this.saAccLevelForm.controls["add_sm"]?.setValue(0);
    this.saAccLevelForm.controls["add_hm"]?.setValue(0);
    this.saAccLevelForm.controls["add_htm"]?.setValue(0);
    this.saAccLevelForm.controls["add_sm_price"]?.setValue(0);
    this.saAccLevelForm.controls["add_hm_price"]?.setValue(0);
    this.saAccLevelForm.controls["add_htm_price"]?.setValue(0);
    this.saAccLevelForm.controls["additional_charges"]?.setValue(0)
    this.saAccLevelForm.controls['future_charges']?.setValue('');

  }

  accLevelOTP(id: any) {
    this.otpNum = ""
    this.userService.getAccLevelOTP(id).subscribe((val: any) => {
      this.showSuccess('OTP Sent succesfully');
    });
  }

  saveAccLevelForm(payModal: any) {
    const c_id = JSON.parse(localStorage.getItem('clientId') || '')
    var data: any = {};
    data.otp = parseInt(this.otpNum);
    data.package_id = parseInt(this.accLevelForm.value.package_id)
    data.ext_senior_managers = parseInt(this.accLevelForm.value.add_sm)
    data.ext_hiring_managers = parseInt(this.accLevelForm.value.add_hm)
    data.ext_hiring_team_members = parseInt(this.accLevelForm.value.add_htm)
    //data.ext_contributors=parseInt(this.accLevelForm.value.contributors)
    data.ext_price = parseInt(this.accLevelForm.value.additional_charges)
    this.userService.saveAccLevelInfo(c_id, data).subscribe((val: any) => {
      if (data.ext_price > 0) {
        const key = val.key;
        const skey = val.payment_secret
        const pUrl = Url.uiBaseUrl + 'thankyou'
        this.modalService.dismissAll();
        this.modalService.open(payModal, { size: 'md' });
        callStripe(key, skey, pUrl);
      }
      else {
        this.modalService.dismissAll();
        this.showSuccess('Package data updated successfully');
      }
    });
  }
  saveSaAccLevelForm(payModal: any) {
    //this.modalService.dismissAll();
    // this.openPayGateModal(payModal)
    if (this.saAccLevelForm.value.package_id !== 0) {
      var data: any = {};
      data.otp = parseInt(this.otpNum);
      data.package_id = parseInt(this.saAccLevelForm.value.package_id)
      data.ext_senior_managers = parseInt(this.saAccLevelForm.value.add_sm)
      data.ext_hiring_managers = parseInt(this.saAccLevelForm.value.add_hm)
      data.ext_hiring_team_members = parseInt(this.saAccLevelForm.value.add_htm)
      //data.ext_contributors=parseInt(this.saAccLevelForm.value.contributors)
      data.ext_price = parseInt(this.saAccLevelForm.value.additional_charges)
      this.userService.saveAccLevelInfo(this.clientIdForSA, data).subscribe((val: any) => {
        this.showSuccess('Data saved succesfully');
        this.modalService.dismissAll();
      });
    }
    else {
      var data: any = {};
      data.otp = parseInt(this.otpNum);
      data.package_id = parseInt(this.saAccLevelForm.value.package_id)
      let d = this.saAccLevelForm.value.trial_expired.split("-");
      let newDate = d[2] + '-' + d[0] + '-' + d[1];
      // data.trial_expired = this.saAccLevelForm.value.trial_expired.split("-").reverse().join("-");
      data.trial_expired = newDate;
      this.userService.saveAccLevelInfo(this.clientIdForSA, data).subscribe((val: any) => {
        this.showSuccess('Data saved succesfully');
        this.modalService.dismissAll();
      });
    }
  }

  createBillingInfoForm() {
    this.billingInfoForm = this.fb.group({
      billing_contact: [''],
      id: [''],
      addr_line_1: [''],
      addr_line_2: [''],
      city: [''],
      state: [''],
      pincode: [''],
      card_number: ['', Validators.pattern('[0-9]{16}$')],
      name_on_card: [''],
      exp_date: ['', Validators.pattern('(0[1-9]|1[0-2])\/([0-9]{2})$')],
      security: ['', Validators.pattern('[0-9]{3}$')],
    })


  }

  getBillingInfo() {
    this.userService.getBillingInfo().subscribe((res: any) => {
      for (var key in res?.data) {
        if (res?.data.hasOwnProperty(key)) {
          this.billingInfoForm.controls[key]?.setValue(res?.data[key]);
        }
      }
      this.billingInfoForm.controls['billing_contact'].setValue(this.companyContactForm.value.billing_first_name + ' ' + this.companyContactForm.value.billing_last_name)
      console.log(this.billingInfoForm.value)
      if (this.billingInfoForm.value.card_number)
        this.lastFourDigits = '**** **** **** ' + this.billingInfoForm.value.card_number.substr(-4)
      else {
        this.lastFourDigits = ''
        this.showCardDetails = true;
      }
    })
  }

  selectAddress(e: any) {
    this.billingAddress = !this.billingAddress;
    if (this.billingAddress) {
      this.billingInfoForm.controls['addr_line_1'].setValue(this.companyInfoForm.value.addr_line_1)
      this.billingInfoForm.controls['addr_line_2'].setValue(this.companyInfoForm.value.addr_line_2)
      this.billingInfoForm.controls['city'].setValue(this.companyInfoForm.value.city)
      this.billingInfoForm.controls['state'].setValue(this.companyInfoForm.value.state)
      this.billingInfoForm.controls['pincode'].setValue(this.companyInfoForm.value.pincode)
    }
    else {
      this.getBillingInfo()
    }
  }

  selectAddressForSA(e: any) {
    this.billingAddress = !this.billingAddress;
    if (this.billingAddress) {
      this.billingInfoForm.controls['addr_line_1'].setValue(this.companyInfoForm.value.addr_line_1)
      this.billingInfoForm.controls['addr_line_2'].setValue(this.companyInfoForm.value.addr_line_2)
      this.billingInfoForm.controls['city'].setValue(this.companyInfoForm.value.city)
      this.billingInfoForm.controls['state'].setValue(this.companyInfoForm.value.state)
      this.billingInfoForm.controls['pincode'].setValue(this.companyInfoForm.value.pincode)
    }
    else {
      this.getBillingInfoForSA(this.clientIdForSA)
    }
  }

  saveBillingInfo() {
    this.userService.saveBillingInfo(this.billingInfoForm.value).subscribe((val: any) => {
      this.showSuccess('Package data updated successfully');
      this.getBillingInfo()
    });
  }
  createCompanyContactForm() {
    this.companyContactForm = this.fb.group({
      company_contact_full_name: ['', Validators.required],
      company_contact_phone: ['', Validators.required],
      company_contact_email: ['', Validators.required],
      // company_name: ['', Validators.required],
      company_website: ['', Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')],
      company_linkedin: ['', Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*')],
      ca_first_name: ['', [Validators.required, Validators.pattern('[a-zA-Z]*$')]],

      ca_last_name: ['', Validators.required],
      key_contact_phone_no: [
        0,
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
      key_contact_skype_id: ['', Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*')],
      key_contact_email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
      key_username: ['', Validators.required],
      // key_password: ['', Validators.required],
      ca_profile_pic: [null],
      job_title: ['', Validators.required],
      hr_first_name: ['', Validators.pattern('[a-zA-Z]*$')],
      hr_last_name: [''],
      hr_contact_phone_no: ['', Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      hr_contact_skype_id: ['', Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*')],
      hr_contact_email: ['', Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$')],
      hr_username: [''],
      hr_password: [''],
      cto_first_name: ['', Validators.pattern('[a-zA-Z]*$')],
      cto_last_name: [''],
      cto_phone_no: ['', Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      cto_skype_id: ['', Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*')],
      cto_email: ['', Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$')],
      cto_username: [''],
      cto_password: [''],
      billing_first_name: ['', Validators.pattern('[a-zA-Z]*$')],
      billing_last_name: [''],
      billing_phone_no: ['', Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      billing_email: ['', Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$')],
      special_req: [''],
      logo: [null],
      id: [0],
      name: '',
      open_positions: [0],
      disabled: [false],
    });
  }

  getCompanyContact() {
    var c_id = JSON.parse(localStorage.getItem('clientId') || '');
    if (this.userRole === 'Superuser') {
      this.dashboardService.getClientList().subscribe((res: any) => {
        c_id = res?.clients[0].id;
      })
    }
    this.clientService.getClientbyId(c_id).subscribe((res: any) => {
      for (var key in res?.client) {
        if (res?.client.hasOwnProperty(key)) {
          this.companyContactForm.controls[key]?.setValue(res?.client[key]);
        }
      }
      console.log(this.companyContactForm.value)
      this.billingInfoForm.controls['billing_contact'].setValue(this.companyContactForm.value.billing_first_name + ' ' + this.companyContactForm.value.billing_last_name)

    })
  }

  saveCompanyContact() {
    if (this.companyContactForm.invalid) {
      alert("Kindly fill all the mandatory fields")
      this.companyContFormHasError = true;
      return;
    }
    this.companyContFormHasError = false;
    var c_id = JSON.parse(localStorage.getItem('clientId') || '');
    this.companyContactForm.value.id = c_id;
    this.clientService.updateClient(this.companyContactForm.value).subscribe(
      (res: any) => {
        if (res) {
          this.showSuccess('Client Updated successfully.');
          // this.router.navigate([this.authService.currentUserRoleCode + '/dashboard']);
          this.getBillingInfo();
        }
      },
      (error) => {
        this.showDanger('Error:' + error.error);
      }
    );
  }

  createCompanyInfoForm() {
    this.companyInfoForm = this.fb.group({
      company_name: ['', Validators.required],
      company_website: [''],
      company_linkedin: [''],
      addr_line_1: ['', Validators.required],
      addr_line_2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      pincode: ['', Validators.required],
      logo: [null]
    })

  }

  getCompanyInfoFormData() {
    this.userService.getCompanyInfo().subscribe((res: any) => {
      for (var key in res) {
        if (res?.hasOwnProperty(key)) {
          this.companyInfoForm.controls[key]?.setValue(res?.[key] || '');
        }
        if (key === 'logo') {
          if (res[key]) {
            this.companyInfoForm.controls['logo']?.setValue(res[key]);
            var index = res[key].lastIndexOf("/") + 1;
            this.logoImageName = res[key].substr(index);
            if (res[key] && res[key] !== 'None') {
              this.logoImageURL = Url.imgBaseUrl + res[key];
            }
            this.showLogo = true;
          }
          else {
            this.showLogo = false;
          }
        }
      }
      console.log("company form" + this.companyInfoForm.value.company_name)
      // this.initialProTipFormValue = JSON.stringify(this.proTipForm.value);
    });
  }

  saveCompanyInfoForm() {
    if (this.companyInfoForm.invalid) {
      alert("Kindly fill all the mandatory fields")
      this.companyInfoFormHasError = true;
      return;
    }
    this.companyInfoFormHasError = false;
    this.userService.saveCompanyInfoForm(this.companyInfoForm.value).subscribe((val: any) => {
      this.showSuccess('Company Information updated successfully');

    });
  }

  createGlobalPricingForm() {
    this.globalPricingForm = this.fb.group({
      id: [''],
      name: [''],
      package_level: [''],
      package_price: [''],
      current_charge_tooltip: [''],
      future_charge_tooltip: [''],
      senior_manager_cost: [''],
      senior_managers: [''],
      senior_manager_count: [''],
      hiring_manager_cost: [''],
      hiring_managers: [''],
      hiring_manager_count: [''],
      htm_cost: [''],
      hiring_team_members: [''],
      htm_count: [''],
    });
    this.getGlobalPricingFormData();
  }

  getGlobalPricingFormData() {

    this.startPrice = 0;
    this.growthPrice = 0;
    this.enterPrice = 0;
    this.userService.getPackageDetails().subscribe((res: any) => {

      this.packageDetails = res?.data
      for (let i = 0; i < this.packageDetails.length; i++) {
        if (this.packageDetails[i].name == 'Starter') {
          this.startPrice = this.packageDetails[i].price;
        }
        if (this.packageDetails[i].name == 'Growth') {
          this.growthPrice = this.packageDetails[i].price;
        }
        if (this.packageDetails[i].name == 'Enterprise') {
          this.enterPrice = this.packageDetails[i].price;
        }
      }

    })
  }

  saveGlobalPricingForm() {
    let data: any = { extra_price: {} };
    // let data = this.globalPricingForm.value;
    data.id = this.globalPricingForm.value.id
    data.name = this.globalPricingForm.value.name
    data.price = this.globalPricingForm.value.package_price
    data.current_charge_tooltip = this.globalPricingForm.value.current_charge_tooltip
    data.future_charge_tooltip = this.globalPricingForm.value.future_charge_tooltip
    data.senior_managers = this.globalPricingForm.value.senior_managers
    data.hiring_managers = this.globalPricingForm.value.hiring_managers
    data.hiring_team_members = this.globalPricingForm.value.hiring_team_members
    data.extra_price.sm_price = this.globalPricingForm.value.senior_manager_cost
    data.extra_price.sm_count = this.globalPricingForm.value.senior_manager_count
    data.extra_price.hm_price = this.globalPricingForm.value.hiring_manager_cost
    data.extra_price.hm_count = this.globalPricingForm.value.hiring_manager_count
    data.extra_price.htm_price = this.globalPricingForm.value.htm_cost
    data.extra_price.htm_count = this.globalPricingForm.value.htm_count
    // let data1:any=[];
    // data1[this.globalPricingForm.value.id-1]=data;
    var data1 = JSON.stringify(data)
    this.userService.updatePackageDetails(this.globalPricingForm.value.id, data1).subscribe((val: any) => {
      this.showSuccess('Package data updated successfully');
      this.createGlobalPricingForm();
      // this.globalPricingForm.controls['package_level'].setValue(data.id)
    });
  }
  getSelectedPackage(e: any) {

    if (e.target.value) {
      // this.selectedClient = event.target.value;
      // this.getHirimembers(event.target.value);
      for (let i = 0; i < this.packageDetails.length; i++) {
        if (this.packageDetails[i].id == e.target.value) {
          this.globalPricingForm.controls['id'].setValue(this.packageDetails[i].id)
          this.globalPricingForm.controls['name'].setValue(this.packageDetails[i].name)
          this.globalPricingForm.controls['package_price'].setValue(this.packageDetails[i].price)
          this.globalPricingForm.controls['current_charge_tooltip'].setValue(this.packageDetails[i].current_charge_tooltip)
          this.globalPricingForm.controls['future_charge_tooltip'].setValue(this.packageDetails[i].future_charge_tooltip)
          this.globalPricingForm.controls['senior_managers'].setValue(this.packageDetails[i].senior_managers)
          this.globalPricingForm.controls['hiring_managers'].setValue(this.packageDetails[i].hiring_managers)
          this.globalPricingForm.controls['hiring_team_members'].setValue(this.packageDetails[i].hiring_team_members)
          this.globalPricingForm.controls['senior_manager_cost'].setValue(this.packageDetails[i].extra_price.sm_price)
          this.globalPricingForm.controls['senior_manager_count'].setValue(this.packageDetails[i].extra_price.sm_count)
          this.globalPricingForm.controls['hiring_manager_cost'].setValue(this.packageDetails[i].extra_price.hm_price)
          this.globalPricingForm.controls['hiring_manager_count'].setValue(this.packageDetails[i].extra_price.hm_count)
          this.globalPricingForm.controls['htm_cost'].setValue(this.packageDetails[i].extra_price.htm_price)
          this.globalPricingForm.controls['htm_count'].setValue(this.packageDetails[i].extra_price.htm_count)
        }
      }
    }
    else {
      this.globalPricingForm.controls['id'].setValue('')
      this.globalPricingForm.controls['name'].setValue('')
      this.globalPricingForm.controls['package_price'].setValue('')
      this.globalPricingForm.controls['current_charge_tooltip'].setValue('')
      this.globalPricingForm.controls['future_charge_tooltip'].setValue('')
      this.globalPricingForm.controls['senior_managers'].setValue('')
      this.globalPricingForm.controls['hiring_managers'].setValue('')
      this.globalPricingForm.controls['hiring_team_members'].setValue('')
      this.globalPricingForm.controls['senior_manager_cost'].setValue('')
      this.globalPricingForm.controls['senior_manager_count'].setValue('')
      this.globalPricingForm.controls['hiring_manager_cost'].setValue('')
      this.globalPricingForm.controls['hiring_manager_count'].setValue('')
      this.globalPricingForm.controls['htm_cost'].setValue('')
      this.globalPricingForm.controls['htm_count'].setValue('')
    }
  }


  getUserProfile(userdata: any) {
    this.userService.getUserProfile(userdata.profile_id).subscribe((data: any) => {
      if (data) {
        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            if (key === 'profile_photo') {

              this.settingsForm.controls['profile_photo']?.setValue(data[key]);
              if (data[key] && data[key] !== 'None') {
                this.imageURL = Url.imgBaseUrl + data[key];
                this.showProfilePhoto = true;
              } else {
                this.imageURL = Url.imgBaseUrl + './../../../assets/images/big-default-avatar.png';
                this.showProfilePhoto = false
              }
            } else {
              this.settingsForm.controls[key]?.setValue(data[key]);
            }
          }
        }
        const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
        currentUser.profile_photo = data.profile_photo;
        currentUser.first_name = data.first_name;
        currentUser.last_name = data.last_name;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.userService.setuserDetailsUpdated(data);
        this.initialUserDetailsFormValue = JSON.stringify(this.settingsForm.value);
      }
    });
  }

  createForm() {
    this.settingsForm = this.fb.group({
      first_name: ['', [Validators.required, Validators.pattern('[a-zA-Z]*$')]],
      last_name: ['', [Validators.required, Validators.pattern('[a-zA-Z]*$')]],
      phone_number: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
      email: [''],
      username: [this.userData.username, Validators.required],
      // password: ['', Validators.required],
      skype_id: [''],
      profile_photo: [null],
      profile_photo_deleted: [false]
    });
    this.initialUserDetailsFormValue = JSON.stringify(this.settingsForm.value);
  }

  showPreview(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      // this.accountService.leavePage = true;
      const [file] = event.target.files;
      this.settingsForm.controls['profile_photo_deleted'].setValue(false);
      this.settingsForm.controls['profile_photo'].setValue(event.target.files[0]);
      reader.readAsDataURL(file);
      this.settingsForm.controls.profile_photo.patchValue(file);
      reader.onload = () => {
        this.imageURL = reader.result as string;
      };
      this.showProfilePhoto = true;
    }
  }

  showPreview1(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.companyInfoForm.controls['logo'].setValue(event.target.files[0]);
      reader.readAsDataURL(file);
      this.companyInfoForm.controls.logo.patchValue(file);
      reader.onload = () => {
        this.logoImageURL = reader.result as string;
        this.logoImageName = event.target.files[0].name
      };
      this.showLogo = true;
    }
  }

  deleteProfilePic(element: any) {
    this.settingsForm.controls['profile_photo_deleted'].setValue(true);
    element.value = '';
    this.settingsForm.controls['profile_photo'].setValue(null);
    this.imageURL = '';
  }

  updateSettings() {
    if (this.settingsForm.invalid) {
      alert("Kindly fill all the mandatory fields")
      this.settingFormHasError = true;
      return;
    }
    this.settingFormHasError = false;
    this.userService.updateUserProfile(this.userData.profile_id, this.settingsForm.value).subscribe((data: any) => {
      this.showSuccess('Profile updated successfully!');
      this.getUserProfile(this.userData);
    })
  }

  updateProTip() {
    this.userService.updateProTip(this.proTipForm.value).subscribe((res: any) => {
      this.showSuccess('Pro tip updated successfully!');
      localStorage.setItem('protip', JSON.stringify(res.data));
      this.userService.setproTipUpdated(res.data);
      this.initialProTipFormValue = JSON.stringify(this.proTipForm.value);
    });
  }

  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

  createpForm() {
    this.passwordForm = this.fb.group({
      username: [''],
      new_password: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[\/~`!@#$%^&*()_+\-=\[\]{}|\\:;"'<>,.?\//])(?!.*\s)/),
      Validators.minLength(8), Validators.maxLength(16)]],
      re_password: ['', Validators.required],
    })
  }

  openAccLevelModal(confirm: any) {
    if (this.userRole === 'Client Admin') {
      const c_id = JSON.parse(localStorage.getItem('clientId') || '');
      this.accLevelOTP(c_id)
    }

    if (this.userRole === 'Superuser' || this.userRole === 'Account Manager') {
      this.accLevelOTP(this.clientIdForSA)
    }
    this.modalService.open(confirm, { size: 'md' });
  }

  openPayGateModal(payModal: any) {

    this.modalService.open(payModal, { size: 'md' });
    callStripe();
  }

  updateCard() {
    this.showCardDetails = true;
  }
  openforPassword(password: any) {
    this.modalService.open(password, { size: 'md' });
  }

  changePassword() {
    let payload = this.passwordForm.value;
    payload['new_password'] = this.userService.encryptPassword(this.passwordForm.value.new_password)
    // this.passwordForm.controls.new_password.setValue(this.userService.encryptPassword(this.passwordForm.value.new_password));
    payload['username'] = this.userData.username;
    delete payload['re_password'];
    this.hiringTeamService.changePassword(payload).subscribe(value => {
      if (value) {
        this.showSuccess('Password changed Successfully');
        this.modalService.dismissAll();
        this.passwordForm.reset();
      } else {
        this.showDanger('Change Password failed, please try again.');
      }
    })
  }

  checkPasswords() {
    let pass = this.passwordForm.controls['new_password'].value;
    let confirmPass = this.passwordForm.controls['re_password'].value;
    if (pass === confirmPass) {
      this.showError = false;
    } else {
      this.showError = true;
    }
  }

  public toggleAccordian(props: NgbPanelChangeEvent): void {
    this.openPanelId = props.panelId;
    this.showUserDetails = false;
    this.showEmailTempForm = false;
    this.showProTipForm = false;
    this.showAccDetails = false;
    this.showBillingDetails = false;
    this.showGlobalPricing = false;
    this.showClientAccounts = false;
    this.showNavigator = false
    this.showopenPositionDetails = false;
    this.selectedClientId = undefined;
    if (props.panelId === 'userDetails') {// && props.nextState
      this.showUserDetails = props.nextState;
    }
    if (props.panelId === 'showAccDetails') {
      this.showAccDetails = props.nextState;
    }
    if (props.panelId === 'billingDetails') {
      this.showBillingDetails = props.nextState;
    }
    if (props.panelId === 'emailTempDetails') {
      this.showEmailTempForm = props.nextState;
    }
    if (props.panelId === 'navigatorDetails') {
      this.showNavigator = props.nextState;
    }
    if (props.panelId === 'openPositionDetails') {
      this.showopenPositionDetails = props.nextState;
    }
    if (props.panelId === 'proTipDetails') {
      this.showProTipForm = props.nextState;
    }
    if (props.panelId === 'gloabal_pricing_setting') {
      this.showGlobalPricing = props.nextState;
    }
    if (props.panelId === 'clientAccounts') {
      this.showUserDetails = props.nextState;
      this.showClientAccounts = props.nextState;
    }
    if (props.nextState == false) {
      this.showUserDetails = true;
    }
  }

  public ClientAccountToggle(props: NgbPanelChangeEvent): void {
    this.showUserDetails = false;
    this.showExpiryDate = false;
    const id = parseInt(props.panelId)
    this.openPanelId = parseInt(props.panelId);
    this.showClientAccounts = true;
    if (parseInt(props.panelId) === id) {
      this.selectedClientId = id;
      this.getCompInfoForSA(id);
      this.getCompContactForSA(id);
      this.getSaAccLevelFormData(id);
      this.getBillingInfoForSA(id);
      this.createInvoices(id)
    }
    if (props.nextState == false) {
      this.showUserDetails = true;
      this.showClientAccounts = false;
    }
  }

  getBillingInfoForSA(id: any) {
    this.clientIdForSA = id;
    this.showCardDetails = false;
    //commented till card billing services are inactive
    // this.userService.getBillingInfoForSA(id).subscribe((res: any) => {
    //   for (var key in res?.data) {
    //     if (res?.data.hasOwnProperty(key)) {
    //       this.billingInfoForm.controls[key]?.setValue(res?.data[key]);
    //     }
    //   }
    //   this.billingInfoForm.controls['billing_contact'].setValue(this.companyContactForm.value.billing_first_name + ' ' + this.companyContactForm.value.billing_last_name)
    //   console.log(this.billingInfoForm.value)
    //   if (this.billingInfoForm.value.card_number)
    //     this.lastFourDigits = '**** **** **** ' + this.billingInfoForm.value.card_number.substr(-4)
    //   else {
    //     this.lastFourDigits = ''
    //     this.showCardDetails = true;
    //   }
    // })
  }
  saveBillingInfoForSA() {
    this.userService.saveBillingInfoForSA(this.billingInfoForm.value, this.clientIdForSA).subscribe((val: any) => {
      this.showSuccess('Package data updated successfully');

    });
  }

  getCompInfoForSA(id: any) {
    this.clientIdForSA = id;
    this.logoImageURL = ''
    this.logoImageName = ''
    this.userService.getCompInfoForSA(id).subscribe((res: any) => {
      for (var key in res) {
        if (res?.hasOwnProperty(key)) {
          this.companyInfoForm.controls[key]?.setValue(res?.[key] || '');
        }
        if (key === 'logo') {
          if (res[key]) {
            this.companyInfoForm.controls['logo']?.setValue(res[key]);
            var index = res[key].lastIndexOf("/") + 1;
            this.logoImageName = res[key].substr(index);
            if (res[key] && res[key] !== 'None') {
              this.logoImageURL = Url.imgBaseUrl + res[key];
            }
            this.showLogo = true;
          }
          else {
            this.showLogo = false;
          }
        }
      }
      console.log("company form" + this.companyInfoForm.value.company_name)
    });
  }
  removeLogo() {
    this.companyInfoForm.controls['logo']?.setValue(null);
    this.showLogo = false;
  }
  removePhoto() {
    this.settingsForm.controls['profile_photo'].setValue(null)
    this.showProfilePhoto = false;
  }
  saveCompInfoFormForSA() {
    if (this.companyInfoForm.invalid) {
      alert("Kindly fill all the mandatory fields")
      this.companyInfoFormHasError = true;
      return;
    }
    this.companyInfoFormHasError = false;
    this.userService.saveCompInfoFormForSA(this.companyInfoForm.value, this.clientIdForSA).subscribe((val: any) => {
      this.showSuccess('Company Information updated successfully');

    });
  }

  getCompContactForSA(id: any) {
    this.clientIdForSA = id;
    this.clientService.getClientbyId(id).subscribe((res: any) => {
      for (var key in res?.client) {
        if (res?.client.hasOwnProperty(key)) {
          this.companyContactForm.controls[key]?.setValue(res?.client[key]);
        }
      }
      console.log(this.companyContactForm.value)
      this.billingInfoForm.controls['billing_contact'].setValue(this.companyContactForm.value.billing_first_name + ' ' + this.companyContactForm.value.billing_last_name)

    })
  }
  saveCompContactForSA() {
    if (this.companyContactForm.invalid) {
      alert("Please fill all the mandatory fields")
      this.compContactForSAHasError = true;
      return;
    }
    this.compContactForSAHasError = false;
    this.companyContactForm.value.id = this.clientIdForSA;
    this.clientService.updateClient(this.companyContactForm.value).subscribe(
      (res: any) => {
        if (res) {
          this.showSuccess('Client Updated successfully.');
          this.getBillingInfoForSA(this.clientIdForSA);
        }
      },
      (error) => {
        this.showDanger('Error:' + error.error);
      }
    );
  }

  cancelClicked() {
    window.location.reload();
  }

  updateEmailTemplate(tempName: any, content: any) {
    let candidateAddedForSMObj = this.allEmailTemplates?.data?.filter((el: any) => el.name === tempName)[0];
    candidateAddedForSMObj.content = content;
    this.dashboardService.updateEmailTemplateById(candidateAddedForSMObj?.id, candidateAddedForSMObj).subscribe((res: any) => {
      this.showSuccess('Template updated successfully!');
    }, (error: any) => {
      this.showDanger('Something went wrong!');
    });
  }
  getNavigatorList() {
    this.userService.getNavigatorList().subscribe((res: any) => {
      this.navigators = res.data.sort(function (a, b) { return a.step_id - b.step_id });
    })
  }
  updateNavigator(nav: any) {
    this.userService.postNavigator(nav).subscribe(res => {
      console.log(res)
    })
  }

  filterNavigatorSection(type: string) {
    this.navigatorType = type;
  }
  filterEmailTemplate(type: string) {
    this.emailTemplateType = type;
  }

  filterBillingSection(type: string) {
    this.billingSectionType = type;
    this.getBillingInfo();
  }

  filterAccDetailsSection(type: string) {
    this.accountDetailsSection = type;
  }

  filterClientDetails(type: string) {
    this.clientDetails = type;
    // if(type=="billingDetails")
    this.getBillingInfoForSA(this.clientIdForSA)
  }
  getTypeSelected(type: any) {
    return type === this.emailTemplateType || this.emailTemplateType === 'all';
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.userRole === 'Superuser' || this.userRole === 'Account Manager') {
      if (this.initialUserDetailsFormValue !== JSON.stringify(this.settingsForm.value) ||
        this.initialProTipFormValue !== JSON.stringify(this.proTipForm.value)) {
        return confirm('You have unsaved changes. Do you really want to leave?');
      }
    }
    return true;
  }
  checkFormChanges(){
    return this.initialUserDetailsFormValue == JSON.stringify(this.settingsForm.value)
  }

  sortAccordions(sortKey: string) {
    if (this.currentSortKey === '') {
      this.ascendingOrder = !this.ascendingOrder;
    } else {
      this.ascendingOrder = true;
    }
    this.currentSortKey = sortKey;
    this.sortedSampleBills = this.sampleBills.sort((a: any, b: any) => {
      const aValue = a[sortKey];
      const bValue = b[sortKey];
      console.log(aValue, bValue);

      if (sortKey === 'date') {
        const date1 = this.parseDate(aValue);
        const date2 = this.parseDate(bValue);
        if (this.ascendingOrder) {
          return date1.getTime() - date2.getTime();
        } else {
          return date2.getTime() - date1.getTime();
        }
      } else {
        if (this.ascendingOrder) {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      }
    });
    this.currentSortKey = '';
  }
  parseDate(dateString: string): Date {
    const [month, year] = dateString.split(' ');
    const monthIndex = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].indexOf(month);
    const date = new Date(Number(year), monthIndex, 1);
    return date;
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  onPasswordChange() {
    const enteredPassword = this.passwordForm.controls.new_password.value;
    if (enteredPassword) {
      this.isUppercaseValid = /[A-Z]/.test(enteredPassword);
      this.isLowecaseValid = /[a-z]/.test(enteredPassword);
      this.isNumberValid = /\d/.test(enteredPassword);
      this.isSymbolValid = /^(?=.*[A-Z])(?=.*[\/~`!@#$%^&*()_+\-=\[\]{}|\\:;"'<>,.?\//])(?!.*\s)/.test(enteredPassword);
    } else {
      this.isUppercaseValid = false;
      this.isLowecaseValid = false;
      this.isNumberValid = false;
      this.isSymbolValid = false;
    }
  }

  loadStripe() {

    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      s.onload = () => {
        this.handler = (<any>window).StripeCheckout.configure({
          key: 'pk_test_51HxRkiCumzEESdU2Z1FzfCVAJyiVHyHifo0GeCMAyzHPFme6v6ahYeYbQPpD9BvXbAacO2yFQ8ETlKjo4pkHSHSh00qKzqUVK9',
          locale: 'auto',
          token: function (token: any) {
            // You can access the token ID with `token.id`.
            // Get the token ID to your server-side code for use.
            console.log(token)
            alert('Payment Success!!');
          }
        });
      }

      window.document.body.appendChild(s);
    }
  }
  editorLoaded() {
    console.log('editorLoaded');
    // load the design json here
    // this.emailEditor.editor.loadDesign({});
  }

  // called when the editor has finished loading
  editorReady() {
    console.log('editorReady');
  }

  exportHtml(type: string) {
    this.emailEditor.exportHtml((data: any) => {
      console.log('exportHtml', data)
      // this.updateEmailTemplate(type, data.html)
    }
    );
    this.emailEditor.saveDesign((design) => {
      console.log('saveDesign', design);
    })
  }
  emailEdited(event: any) {
    console.log(event)
    this.updateEmailEditorTemplate(event)
  }
  updateEmailEditorTemplate(obj: any) {
    let candidateAddedForSMObj = this.allEmailTemplates?.data?.filter((el: any) => el.name === obj.name)[0];
    candidateAddedForSMObj.content = obj.data.html;
    candidateAddedForSMObj.design = obj.design
    this.dashboardService.updateEmailTemplateById(candidateAddedForSMObj?.id, candidateAddedForSMObj).subscribe((res: any) => {
      this.showSuccess('Template updated successfully!');
    }, (error: any) => {
      this.showDanger('Something went wrong!');
    });
  }
  getPositionToggleData() {
    let c_id = JSON.parse(localStorage.getItem('clientId') || '');
    c_id = parseInt(c_id)
    this.userService.getPositionPrivacyData(c_id).subscribe((res: any) => {
      this.posPrivacyData = res
    })
  }
  togglePositionPrivacy(str: string) {
    let c_id = JSON.parse(localStorage.getItem('clientId') || '');
    c_id = parseInt(c_id)
    this.posPrivacyData[str] = !this.posPrivacyData[str]
    this.posPrivacyData.client = c_id
    this.userService.updatePositionPrivacyData(this.posPrivacyData).subscribe()
  }
}
