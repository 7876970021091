<div class="content main-content" [ngClass]='{"activeLoader": loadingService.isLoading$ | async}'>
    <router-outlet></router-outlet>
</div>
<!-- <app-footer *ngIf="isUserLogedin === true"></app-footer> -->
<app-toasts aria-live="polite" aria-atomic="false"></app-toasts>
<simple-notifications></simple-notifications>
<ng-container *ngIf="loadingService.isLoading$ | async">
    <div class="loader">
        <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</ng-container>
<app-navigator></app-navigator>