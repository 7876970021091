<div class="widget-wrapper">
    <div class="row">
        <div class="col-md-12">
            <div class="widget-col">
                <h2 class="widget-title">Qorums Support</h2>
                <div class="widget-box">

                    <div class="widget-innerbox">
                        <div class="form-section">
                            <form [formGroup]="accountForm">
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">First Name
                                        <small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="first_name">
                                        <div *ngIf="accountForm.controls['first_name'].invalid && (accountForm.controls['first_name'].dirty || accountForm.controls['first_name'].touched) || formHasErrors"
                                            class="alert">
                                            <div *ngIf="accountForm.controls['first_name'].errors?.required">
                                                First Name is required.
                                            </div>
                                            <div *ngIf="accountForm.controls['first_name'].errors?.pattern">
                                                Please enter valid name, only alphabets are allowed.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Last Name
                                        <small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="last_name">
                                        <div *ngIf="accountForm.controls['last_name'].invalid && (accountForm.controls['last_name'].dirty || accountForm.controls['last_name'].touched) || formHasErrors"
                                            class="alert">
                                            <div *ngIf="accountForm.controls['last_name'].errors?.required">
                                                Last Name is required.
                                            </div>
                                            <div *ngIf="accountForm.controls['last_name'].errors?.pattern">
                                                Please enter valid name, only alphabets are allowed.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Phone
                                        <small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="phone_number">
                                        <div *ngIf="accountForm.controls['phone_number'].invalid && (accountForm.controls['phone_number'].dirty || accountForm.controls['phone_number'].touched) || formHasErrors"
                                            class="alert">
                                            <div *ngIf="accountForm.controls['phone_number'].errors?.required">
                                                Phone number is required.
                                            </div>
                                            <div *ngIf="accountForm.controls['phone_number'].errors?.pattern">
                                                Phone number must be exactly 10 digit & only number is allowed.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Skype Address</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="skype_id">
                                    </div>
                                </div> -->
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Email
                                        <small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="email">
                                        <div *ngIf="accountForm.controls['email'].invalid && (accountForm.controls['email'].dirty || accountForm.controls['email'].touched) || formHasErrors"
                                            class="alert">
                                            <div *ngIf="accountForm.controls['email'].errors?.required">
                                                Email is required.
                                            </div>
                                            <div *ngIf="accountForm.controls['email'].errors?.pattern">
                                                Invalid Email, please try again.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Username
                                        <small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" [readonly]="showUpdateBtn" class="form-control"
                                            formControlName="username" autocomplete="new-username">
                                        <div *ngIf="accountForm.controls['username'].invalid && (accountForm.controls['username'].dirty || accountForm.controls['username'].touched) || formHasErrors"
                                            class="alert">
                                            <div *ngIf="accountForm.controls['username'].errors?.required">
                                                Username is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="!showUpdateBtn">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Password
                                        <small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="{{ showPassword ? 'text' : 'password' }}"
                                            class="form-control input-password" formControlName="password"
                                            autocomplete="new-password" (input)="onPasswordChange()"
                                            [ngStyle]="{'color': accountForm.controls.password.errors? 'red' : 'green'}">
                                        <button type="button" (click)="togglePasswordVisibility()"
                                            class="password-toggle">
                                            <i class="fas"
                                                [ngClass]="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                        </button>
                                    </div>
                                    <div class="col-sm-9 offset-3">
                                        <div *ngIf="accountForm.controls['password'].invalid && (accountForm.controls['password'].dirty || accountForm.controls['password'].touched)  || formHasErrors"
                                            class="alert">
                                            <div
                                                *ngIf="accountForm.controls['password'].errors?.required && !accountForm.controls['password'].value">
                                                Please enter password
                                            </div>
                                        </div>
                                        <div *ngIf="accountForm.controls['password']?.errors && accountForm.controls['password'].value && !(accountForm.controls['password']?.hasError('maxlength'))"
                                            class="password-valid-pts">
                                            Password must contain at least one of each of the following characters:
                                            <div [ngStyle]="{'color': isUppercaseValid ? 'green' : 'red'}">
                                                <span>- Uppercase letters: A-Z.</span>
                                            </div>
                                            <div [ngStyle]="{'color': isLowecaseValid ? 'green' : 'red'}">
                                                <span>- Lowercase letters: a-z.</span>
                                            </div>
                                            <div [ngStyle]="{'color': isNumberValid ? 'green' : 'red'}">
                                                <span>- Numbers: 0-9.</span>
                                            </div>
                                            <div [ngStyle]="{'color': isSymbolValid ? 'green' : 'red'}">
                                                <span>- Symbols: ~`! @#$%^&*()_-+={{'{'}}[{{'}'}}]|\:;"'<,>.?/</span>
                                            </div>
                                        </div>
                                        <div *ngIf="(accountForm.controls['password']?.hasError('minlength') || accountForm.controls['password']?.hasError('maxlength')) && (accountForm.controls['password'].dirty || accountForm.controls['password'].touched)"
                                            class="alert">
                                            <span>Password should contain min 8 and max 16 charaters</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Upload Profile Picture</label>
                                    <div class="col-sm-9">
                                        <div *ngIf="showProfilePhoto">
                                            <input #fileInput type="file" style="width: 250px; float: left;"
                                                class="form-control-file" (change)="showPreview($event)">
                                            <input type="hidden" name="fileHidden" formControlName="profile_photo" />
                                        </div>
                                        <div *ngIf="!showProfilePhoto">
                                            <div class="imagePreview"
                                                *ngIf="imageURL && imageURL !== '' && imageURL !== 'None'">
                                                <img [src]="imageURL" [alt]="'Profile Image'">
                                                <span class="bin">
                                                    <a class="ml-4" *ngIf="imageURL && imageURL !== ''"
                                                        href="javascript:void(0)" (click)="deleteProfilePic(fileInput)">
                                                        <fa-icon [icon]="faTrash"></fa-icon>
                                                    </a>
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="showUpdateBtn && associatedClients?.length">
                                    <label class="col-sm-3 col-form-label">Associated Clients</label>
                                    <span *ngFor="let client of associatedClients" class="client-logo">
                                        <img class="img-fluid client-img" height="60px" width="60px"
                                            [src]="client.logo && client.logo !== 'None' ? imageBaseUrl + client.logo : './../../../assets/images/big-default-avatar.png'" />
                                        {{client?.company_name}}
                                    </span>
                                </div>
                                <button type="button" *ngIf="!showUpdateBtn" (click)="Save()"
                                    class="btn btn-primary f-right">Save</button>
                                <button type="button" *ngIf="showUpdateBtn" (click)="editselAccount()"
                                    class="btn btn-primary f-right">Update</button>
                                <a style="margin-right:10px;" href="javascript:void(0)" type="button"
                                    [routerLink]="currentUserRoleCode + '/dashboard'"
                                    class="btn btn-secondary f-right">Cancel</a>
                                <br /><br />
                            </form>
                        </div>
                    </div>

                    <div class="search-results-section">
                        <ul class="search-results-list" *ngIf="accountList.length > 0">
                            <li class="userInfo" *ngFor="let item of accountList">
                                <a href="javascript:void(0)">
                                    <figure><img class="candidate-profile-pic"
                                            [src]="item.profile_photo && item.profile_photo !== 'None' ? imageBaseUrl + item.profile_photo : './../../assets/images/big-default-avatar.png'" />
                                    </figure>
                                    <span class="user-info">
                                        <span class="name">{{item.name}}</span>
                                        <span class="name-role">{{item.email}}</span>
                                    </span>
                                </a>
                                <span class="iconHolder">
                                    <fa-icon [icon]="faLock" class="fa fa-pencil"
                                        (click)="openforPassword(password, item?.username)">
                                    </fa-icon>
                                    <fa-icon [icon]="faEdit" (click)="editAccount(item?.username)"></fa-icon>
                                    <fa-icon (click)="openSm(content, item?.username)"
                                        [icon]="faTrash"></fa-icon></span>
                            </li>
                        </ul>

                        <ul class="search-results-list" *ngIf="accountList.length < 1">
                            <li class="userInfo" style="max-width:100%"><a href="javascript:void(0)">Data not
                                    available.</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #password let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Change Password</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="passwordForm">
            <!-- <div class="form-group row">
                <label class="col-sm-4 col-form-label">New Password
                    <small>*</small>
                </label>
                <div class="col-sm-8">
                    <input type="password" class="form-control" formControlName="new_password">
                    <div *ngIf="passwordForm.controls['new_password'].invalid && (passwordForm.controls['new_password'].dirty || passwordForm.controls['new_password'].touched)"
                        class="alert">
                        <div *ngIf="passwordForm.controls['new_password'].errors?.required">
                            Password is required.
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">New Password <small>*</small></label>
                <div class="col-sm-8">
                    <input type="{{ showPassword ? 'text' : 'password' }}" class="form-control input-password"
                        autocomplete="new_password" formControlName="new_password" (input)="onPasswordChange()"
                        [ngStyle]="{'color': passwordForm.controls.new_password.errors? 'red' : 'green'}">
                    <button type="button" (click)="togglePasswordVisibility()" class="password-toggle">
                        <i class="fas" [ngClass]="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                    </button>
                </div>
                <!-- <div class="col-sm-12"> -->
                <div class="col-sm-8 offset-4">
                    <div *ngIf="passwordForm.controls['new_password'].invalid && (passwordForm.controls['new_password'].dirty || passwordForm.controls['new_password'].touched)"
                        class="alert">
                        <div
                            *ngIf="passwordForm.controls['new_password'].errors?.required && !passwordForm.controls['new_password'].value">
                            Please enter password
                        </div>
                    </div>
                    <div *ngIf="passwordForm.controls['new_password']?.errors && passwordForm.controls['new_password'].value && !(passwordForm.controls['new_password']?.hasError('maxlength'))"
                        class="password-valid-pts">
                        Password must contain at least one of each of the following characters:
                        <div [ngStyle]="{'color': isUppercaseValid ? 'green' : 'red'}">
                            <span>- Uppercase letters: A-Z.</span>
                        </div>
                        <div [ngStyle]="{'color': isLowecaseValid ? 'green' : 'red'}">
                            <span>- Lowercase letters: a-z.</span>
                        </div>
                        <div [ngStyle]="{'color': isNumberValid ? 'green' : 'red'}">
                            <span>- Numbers: 0-9.</span>
                        </div>
                        <div [ngStyle]="{'color': isSymbolValid ? 'green' : 'red'}">
                            <span>- Symbols: ~`! @#$%^&*()_-+={{'{'}}[{{'}'}}]|\:;"'<,>.?/</span>
                        </div>
                    </div>
                    <div *ngIf="(passwordForm.controls['new_password']?.hasError('minlength') || passwordForm.controls['new_password']?.hasError('maxlength')) && (passwordForm.controls['new_password'].dirty || passwordForm.controls['new_password'].touched)"
                        class="alert">
                        <span>Password should contain min 8 and max 16 characters</span>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Re-Password
                    <small>*</small>
                </label>
                <div class="col-sm-8">
                    <input type="password" class="form-control" formControlName="re_password" (blur)="checkPasswords()">
                    <div *ngIf="passwordForm.controls['re_password'].invalid && (passwordForm.controls['re_password'].dirty || passwordForm.controls['re_password'].touched)"
                        class="alert">
                        <div *ngIf="passwordForm.controls['re_password'].errors?.required">
                            Re-Password is required.
                        </div>
                    </div>
                    <label *ngIf="showError" class="alert">Password not matching, try again</label>
                </div>
            </div>
        </form>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="passwordForm.invalid || showError"
            (click)="changePassword()">Change Password</button>
    </div>
</ng-template>


<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Delete Account</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Are you want to delete Account?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="deleteCandidate()">Yes</button>
    </div>
</ng-template>