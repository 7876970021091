<div class="loggeduser-row">
    <div class="fl-left">
    </div>
    <div class="fl-right loggedInfo">
        <div class="userlogo dropdown" [ngClass]="status ? 'show' : 'hide'">
            <ng-container *ngIf="userData['name']">
                <div class="cursor-pointer nav-icons" [routerLink]="currentUserRoleCode + '/dashboard'"
                    [ngClass]='{"activeLink": homeUrlTest.includes("dashboard/details")}'
                    *ngIf="userRole !='htm' && userRole !='hm' && userRole !='can' && !(homeUrl.includes('interview'))">
                    <fa-icon title="Dashboard" class="fa header-icons-top mr-0 my-1" [icon]="faTachometerAlt"></fa-icon>
                    <span>Dashboard</span>
                </div>

                <div class="cursor-pointer nav-icons" (click)="reloadPage()"
                    [ngClass]='{"activeLink": homeUrlTest.includes("candidate-dashboard")}'
                    *ngIf="userRole =='can' && !(homeUrl.includes('interview'))">
                    <fa-icon title="Dashboard" class="fa header-icons-top mr-0 my-1" [icon]="faTachometerAlt"></fa-icon>
                    <span>Home</span>
                </div>
                <div class="cursor-pointer nav-icons" [routerLink]="currentUserRoleCode + '/dashboard/htm-dashboard'"
                    [ngClass]='{"activeLink": homeUrlTest.includes("htm-dashboard")}'
                    *ngIf="userRole =='htm' && !(homeUrl.includes('interview'))">
                    <fa-icon title="Dashboard" class="fa header-icons-top mr-0 my-1" [icon]="faTachometerAlt"></fa-icon>
                    <span>Home</span>
                </div>
                <div class="cursor-pointer nav-icons" *ngIf="userRole =='sa' || userRole =='am'"
                    title="View All Clients" id="headers-all-clients" [ngbPopover]="viewClients" [autoClose]="'true'"
                    [ngClass]='{"activeLink": homeUrlTest.includes("/sa/view-all-clients") || homeUrlTest.includes("/sa/dashboard/addClient") }'>
                    <fa-icon class="fa header-icons-top mr-0 my-1 " [icon]="faListUl">
                    </fa-icon>
                    <span>Clients</span>
                </div>
                <div class="cursor-pointer nav-icons" [routerLink]="currentUserRoleCode + '/view-all-positions'"
                    [ngClass]='{"activeLink": homeUrlTest.includes("view-all-positions")|| homeUrlTest.includes("new-position") }'
                    *ngIf="(userRole =='ca' || userRole =='sm' || userRole =='hm' || userRole =='htm' ) && !(homeUrl.includes('sa/dashboard')) && !(homeUrl.includes('interview')) ">
                    <fa-icon title="View All Positions" id="headers-all-positions"
                        class="fa header-icons-top mr-0 my-1 " [icon]="faListUl"></fa-icon>
                    <span>Positions</span>
                </div>

                <!-- <fa-icon title="Hiring Teams" class="fa header-icons-top mr-2"  [icon]="faUserCog" [routerLink]="currentUserRoleCode + '/dashboard/hiringDepartment'" *ngIf="(userRole =='sm') || (userRole =='am') || (userRole =='sa') || (userRole =='hm') || (userRole =='htm') || (userRole =='Client Admin')"></fa-icon> -->
                <div class="cursor-pointer nav-icons" [routerLink]="currentUserRoleCode + '/view-all-users'"
                    [ngClass]='{"activeLink": homeUrlTest.includes("view-all-users")  }'
                    *ngIf="(userRole =='ca' || userRole =='sa' || userRole =='sm' || userRole =='hm' || userRole =='am') && !(homeUrl.includes('interview')) ">
                    <fa-icon title="All Users" id="headers-all-users" class="fa header-icons-top  mr-0 my-1"
                        [icon]="faUsers"></fa-icon>
                    <span>Users</span>
                </div>
                <div class="cursor-pointer nav-icons" (click)="openMyCalendar()"
                    [ngClass]="{'activeLink': homeUrlTest.includes('my-calender')}"
                    *ngIf="userRole !=='sa' && userRole !=='can' && userRole !=='htm' && !(homeUrl.includes('interview'))">
                    <fa-icon title="My Calendar" class="fa header-icons-top  mr-0 my-1" [icon]="faCalendarAlt">
                    </fa-icon>
                    <span>My Calendar</span>
                </div>
            </ng-container>
            <div class="cursor-pointer nav-icons" *ngIf="currentUserData?.roles.length > 1"
                [routerLink]="['/select-role']">
                <fa-icon [icon]="faRandom" class="fa header-icons-top " title="Toggle Role"></fa-icon>
            </div>
            <!-- <fa-icon *ngIf="userData['name'] && userRole === 'sa'" [routerLink]="currentUserRoleCode + '/all-users'" [icon]="faUserPlus" class="fa header-icons-top mr-2"></fa-icon> -->
            <div class="cursor-pointer nav-icons" (click)="helpIframe.show();iframeLoaded()"
                *ngIf="userData['name'] && userRole !=='can' && !(homeUrl.includes('interview'))">
                <fa-icon title="Help" [icon]="faQuestion" class="fa header-icons-top mr-0 my-1"></fa-icon>
                <span>Help</span>
            </div>

            <div class="cursor-pointer nav-icons" (click)="helpIconForCandidate()"
                *ngIf="userData['name'] && userRole ==='can' && !(homeUrl.includes('interview'))">
                <fa-icon title="Help" [icon]="faQuestion" class="fa header-icons-top mr-0 my-1"></fa-icon>
                <span>Help</span>
            </div>
            <div class="cursor-pointer nav-icons" (click)="showNotifications()"
                *ngIf="userData['name'] && !(homeUrl.includes('interview'))">
                <span [autoClose]="'outside'" [ngbPopover]="popContent" [popoverTitle]="popTitle">
                    <fa-icon title="Notifications" [icon]="faBell" [ngClass]="{'unread': isNewNotification}"
                        class="fa fa-bell header-icons-top notification-icon mr-0 my-1"></fa-icon>
                    <span>Notifications</span>
                </span>
            </div>
            <a class="mx-2 q-logo-img cursor-pointer" *ngIf="isUserLogedin && (userRole !== 'hm' && userRole !== 'htm')"
                (click)="showMenu()"><img width="100px;" src="./../../../assets/images/logo-qorums-r.png" /></a>
            <a class="mx-2 q-logo-img cursor-pointer" *ngIf="isUserLogedin && (userRole === 'hm' || userRole === 'htm')"
                (click)="showMenu()"><img width="100px;" src="./../../../assets/images/logo-qorums-r.png" /></a>
            <a class="u-logo" [routerLink]="currentUserRoleCode + '/login'" *ngIf="!isUserLogedin">Login</a>
            <button *ngIf="userRole && userRole==='user'" class="dropdown-toggle" type="button" id="dropdownMenuButton"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <fa-icon [icon]="faBars" class="fa fa-bars" (click)="showMenu()"></fa-icon>
                <fa-icon [icon]="faTime" class="fa fa-times" (click)="showMenu()"></fa-icon>
            </button>
            <span class="user-role" *ngIf="!profileImage && userRole && userRole!=='user'"
                (click)="showMenu()">{{userRole | uppercase}}</span>
            <!-- <span class="pr-pic-span"><img *ngIf="profileImage"   [src]="profileImage" alt="profile picture" /></span> -->
            <ul class="dropdown-menu dark-mode-optn" [ngClass]="status ? 'show' : 'hide'"
                aria-labelledby="dropdownMenuButton">
                <fa-icon [icon]="faTime" class="fa fa-times" (click)="showMenu()"></fa-icon>
                <li class="dropdown-item">
                    <span *ngIf="!profileImage && userRole && userRole!=='user'" class="user-dp"
                        (click)="navigateToSettings()">{{userRole | uppercase}}</span>
                    <img *ngIf="profileImage" (click)="showMenu()" class="user-prof-pic"
                        src="{{profileImage!=='null'?profileImage : './../../../assets/images/big-default-avatar.png'}}"
                        alt="profile picture" />
                    <div>
                        <span class="user-name">{{first_name}} {{last_name}}</span><br />
                        <span class="user-role px-0">{{role !== 'Account Manager' ? role : 'Qorums Support'}}</span>
                    </div>
                </li>
                <li class="dropdown-item dark-mode-optn mt-3">
                    <span class="dark-mode-text">Dark Mode</span>
                    <ui-switch style="float: right;" color="var(--primary)" labelOn="On" labelOff="Off"
                        (change)="toggleDarkMode($event)" [checked]="isDarkMode"></ui-switch>
                </li>
                <li *ngIf="(userRole!=='sa' && userRole!=='can')" class="dropdown-item ">
                    <span class="dark-mode-text">Navigator</span>
                    <ui-switch style="float: right;" color="var(--primary)" labelOn="On" labelOff="Off"
                        (change)="switchNavToggle()" [checked]="isNavigator"></ui-switch>
                </li>
                <hr class="m-0" />
                <li class="dropdown-item "><a href="javascript:void(0)" title="Settings" class="p-0"
                        *ngIf="isUserLogedin" (click)="navigateToSettings()">Settings</a></li>
                <li class="dropdown-item logout-btn"><a href="javascript:void(0)" title="Log Out" class="p-0"
                        (click)="logOut()" *ngIf="isUserLogedin">Logout</a></li>
                <hr class="m-0" />
                <li class="dropdown-item mt-2 mb-0 "> <span class="copyright-content"><a
                            href="https://www.qorums.com/legals/privacy-policy" target="_blank"
                            class="p-0 copyright-content">Privacy Policy</a></span> <span
                        class="copyright-content ml-4"><a href="https://www.qorums.com/legals/terms-of-service"
                            target="_blank">Terms of Service</a></span></li>
                <li class="dropdown-item mt-0"><span class="copyright-content">Copyright Qorums Inc. Version 2.92 -
                        August 08, 2024.</span></li>
            </ul>
            <div *ngIf="status" class="dropdown-backdrop" (click)="showMenu()">
            </div>
        </div>
    </div>
</div>
<ng-template #popContent>
    <div class="all-notifications" *ngIf="allNotifications?.length">
        <p class="notification-data" *ngFor="let notification of allNotifications;">
            {{notification?.message}}
            <a href="javascript:void(0)" class="delete-notification" (click)="deleteNotification(notification?.id)">
                <fa-icon [icon]="faTrash"></fa-icon>
            </a>
        </p>
    </div>
    <div *ngIf="allNotifications?.length===0">
        <p class="notification-data">No new notification to show</p>
    </div>
</ng-template>
<ng-template #popTitle><b>All Notifications</b></ng-template>

<ng-template #AnalyticsContent>
    <div>
        <div *ngFor="let menu of userAnalytics" class="menu-block form-check form-switch" [ngClass]="menu.class">
            <fa-icon [icon]="getCssIcon(menu.class)"></fa-icon>
            <label [for]="menu.class">{{menu.label}}</label>
            <input class="form-check-input" type="checkbox" [id]="menu.class" (change)="toggleSelection($event, menu)"
                [checked]="menu.isSelected" />
            <!-- <a class="selection-button savebtn btn" (click)="toggleSelection(menu)">{{menu.isSelected ? 'Deselect': 'Select'}}</a> -->
        </div>
    </div>
</ng-template>

<ng-template #AnalyticsTitle>
    <b>All Widgets</b>
</ng-template>

<ng-template #usersPopup>
    <b>Users</b>
</ng-template>
<ng-template #usersPopupContent>
    <fieldset class="p-2">
        <!-- <p class="popup-navigation-link" [routerLink]="currentUserRoleCode + '/dashboard/hiringDepartment'"  *ngIf="(userRole =='sm') || (userRole =='am') || (userRole =='sa') || (userRole =='hm') || (userRole =='htm') || (userRole =='Client Admin') || (userRole =='ca')" id="headers-hiring-teams"><fa-icon title="Hiring Teams" class="fa header-icon mr-2"  [icon]="faUserCog"></fa-icon>Hiring Teams</p> -->
        <p class="popup-navigation-link" [routerLink]="currentUserRoleCode + '/view-all-users'"
            *ngIf="userRole =='ca' || userRole =='sa' || userRole =='sm' || userRole =='hm' || userRole =='am'"
            id="headers-users"><fa-icon title="All Users" class="fa header-icon mr-2" [icon]="faUsers"></fa-icon>Users
        </p>
        <p class="popup-navigation-link" [routerLink]="currentUserRoleCode + '/candidateHome'"
            *ngIf="userRole !== 'can' && userRole !== 'htm' && userRole !== 'hr'" id="headers-candidates"><fa-icon
                title="Candidates" class="fa header-icon mr-2" [icon]="faUserTie"></fa-icon>Candidates
        </p>
        <p class="popup-navigation-link" [routerLink]="currentUserRoleCode + '/dashboard/createQorumsSupport'"
            *ngIf="userRole == 'sa'" id="headers-qorums-support"><fa-icon title="Qorums Support"
                class="fa header-icon mr-2" [icon]="faPeopleCarry"></fa-icon>Qorums Support</p>
    </fieldset>
</ng-template>

<ng-template #viewClients>
    <fieldset class="p-2">
        <p class="popup-navigation-link" [routerLink]="currentUserRoleCode + '/view-all-positions'"
            *ngIf="(userRole =='ca' || userRole =='sa' || userRole =='sm' || userRole =='hm' || userRole =='htm' || userRole =='am') && !(homeUrl.includes('sa/dashboard')) && !(homeUrl.includes('view-all-clients')) && !(homeUrl.includes('interview')) && !(homeUrl.includes('view-all-users'))"
            id="headers-all-positions"><fa-icon title="All Users" class="fa header-icon mr-2"
                [icon]="faUsers"></fa-icon>View All Positions</p>
        <p class="popup-navigation-link" [routerLink]="currentUserRoleCode + '/view-all-clients'"
            id="headers-hiring-teams"><fa-icon title="Hiring Teams" class="fa header-icon mr-2"
                [icon]="faUserCog"></fa-icon>View All Clients</p>
        <p class="popup-navigation-link" [routerLink]="currentUserRoleCode + '/dashboard/addClient'" id="headers-users">
            <fa-icon title="All Users" class="fa header-icon mr-2" [icon]="faUsers"></fa-icon>Add a
            Client
        </p>

    </fieldset>
</ng-template>

<ng-template #PlugContent>
    <div *ngIf="seeAllConnectors" class="row no-gutters">
        <div *ngFor="let con of atsConnectors" class="menu-block col-md-6" (click)="openConnector(con)">
            <img [src]="con.url" height="40" width="40" />
            <label class="con-label">{{con?.label}}</label>
            <!-- <a class="selection-button savebtn btn" (click)="toggleSelection(menu)">{{menu.isSelected ? 'Deselect': 'Select'}}</a> -->
        </div>
    </div>
    <div *ngIf="!seeAllConnectors" class="row no-gutters">
        <div class="menu-block col-md-6">
            <div class="pt-4">
                <img [src]="selectedConnector?.url" height="40" width="40" />
                <label class="con-label">{{selectedConnector?.label}}</label>
            </div>
        </div>
        <div class="menu-block col-md-6">
            <a class="w-100 text-center btn btn-primary con-btn" [href]="selectedConnector.href" target="_blank">
                <span class="d-block">Instructions </span>
                <small>(Opens in a new browser tab)</small>
            </a>
            <button class="w-100 text-center btn btn-primary con-btn">
                Enter your Key
            </button>
            <button (click)="seeAllConnectors=true" class="w-100 text-center btn btn-primary con-btn">
                See all Connections
            </button>
        </div>
    </div>
</ng-template>

<ng-template #PlugTitle>
    <b>All Connections <a class="float-right" target="_blank" href="https://help.qorums.com/api">View All</a></b>
</ng-template>


<ng-template #helpAndResources>
    <div class="row no-gutters">
        <div class="menu-block help-block col-12">
            <!-- <a href="#"><fa-icon [icon]="faHandshake"></fa-icon>Support</a> -->
            <a href="http://help.qorums.com/" target="_blank"><fa-icon [icon]="faInfoCircle"></fa-icon>FAQs and
                Videos</a>
            <!-- <a href="#" target="_blank"><fa-icon [icon]="faRocket"></fa-icon>Onboarding Help</a> -->
            <a href="http://help.qorums.com/" target="_blank"><img width="25" src="./../../../assets/images/QU-icon.png"
                    class="mr-2" alt="Qorums University" />Qorums University</a>

        </div>
    </div>
</ng-template>

<ng-template #helpAndResourcesTitle>
    <b>Resources</b>
</ng-template>
<app-modal #helpIframe class="modal-demo" [backdrop]="false" [maximizable]="true">
    <ng-container class="app-modal-header">Help</ng-container>
    <ng-container class="app-modal-body">
        <iframe (load)="iframeLoaded()" [class.d-none]="isLoading" *ngIf="!isLoading && protipData.indexOf('http') > -1"
            [src]="protipData | safe" width="100%" height="100%" frameborder="0" webkitallowfullscreen
            mozallowfullscreen allowfullscreen></iframe>

        <div class="spinner-border text-primary" style="width: 4rem; height: 4rem;" role="status" *ngIf="isLoading">
            <span class="sr-only">Loading...</span>
        </div>
    </ng-container>
</app-modal>
<app-navigator></app-navigator>