import { Component, ElementRef, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import {
  faLightbulb
} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-protip',
  templateUrl: './protip.component.html',
  styleUrls: ['./protip.component.css']
})
export class ProtipComponent implements OnInit {
  protipData: any;
  faLightbulb = faLightbulb;
  constructor(
    private router: Router,
    private userService: UserService,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    this.userService.getproTipUpdated().subscribe((res: any) => {
      setTimeout(() => {
        this.setProTipData(res);
        this.addUniqueClassToBody();
      }, 1000);
      
    });
    this.router.events.subscribe((event: any) => {
      console.log(event);
      if (event instanceof NavigationEnd) {
        this.setProTipData();
        this.addUniqueClassToBody();
      }
    });
  }

  addUniqueClassToBody() {
    const htmlEl = document.getElementsByTagName('html');
    htmlEl[0].className = '';
    const currentRoute: any = this.router.url;
    const bodyEl = document.getElementsByTagName('body');
    // bodyEl[0].className = '';
    switch(true) {
      case(currentRoute.indexOf('settings') !== -1):
        bodyEl[0].classList.add('settings-page');
        break;
      case(currentRoute.indexOf('candidateHome') !== -1):
        bodyEl[0].classList.add('candidate-page');
        break;
      case(currentRoute.indexOf('dashboard/hiringDepartment') !== -1):
        bodyEl[0].classList.add('hiring-team-page');
        break;
      case (currentRoute.indexOf('dashboard/htm') !== -1):
        bodyEl[0].classList.add('htm-page');
        break;
      case (currentRoute.indexOf('dashboard/createAccount') !== -1):
        bodyEl[0].classList.add('senior-manager-page');
        break;
      case (currentRoute.indexOf('dashboard/addClient') !== -1):
        bodyEl[0].classList.add('add-client-page');
        break;
      case (currentRoute.indexOf('/createQorumsSupport') !== -1):
        bodyEl[0].classList.add('support-page');
        break;
      case (currentRoute.indexOf('dashboard') !== -1):
        bodyEl[0].classList.add('dashboard-page');
        break;
      case (currentRoute.indexOf('position/createPosition') !== -1):
        bodyEl[0].classList.add('position-page');
        break;
      case (currentRoute.indexOf('position') !== -1):
        bodyEl[0].classList.add('position-page');
        break;
      case (currentRoute.indexOf('position') !== -1):
        bodyEl[0].classList.add('position-page');
        break;
    }
  }

  setProTipData(data?: any) {
    if(!data) {
      data = JSON.parse(localStorage.getItem('protip') || '');
    }
    const currentRoute: any = this.router.url;
    const protipData = 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.';
    switch(true) {
      case(currentRoute.indexOf('settings') !== -1):
        this.protipData = data.settings_protip || protipData;
        break;
      case(currentRoute.indexOf('candidateHome/advanceSearch') !== -1):
        this.protipData = data.candidate_adv_search_protip || protipData;
        break;
      case(currentRoute.indexOf('candidateHome') !== -1):
        this.protipData = data.candidate_protip || protipData;
        break;
      case(currentRoute.indexOf('dashboard/hiringDepartment') !== -1):
        this.protipData = data.hiring_teams_protip || protipData;
        break;
      case (currentRoute.indexOf('dashboard/htm') !== -1):
        this.protipData = data.team_members_protip || protipData;
        break;
      case (currentRoute.indexOf('dashboard/createAccount') !== -1):
        this.protipData = data.senior_manager_protip || protipData;
        break;
      case (currentRoute.indexOf('dashboard/addClient') !== -1):
        this.protipData = data.client_protip || protipData;
        break;
      case (currentRoute.indexOf('/createQorumsSupport') !== -1):
        this.protipData = data.support_protip || protipData;
        break;
      case (currentRoute.indexOf('dashboard') !== -1):
        this.protipData = data.dashboard_protip || protipData;
        break;
      case (currentRoute.indexOf('position/createPosition') !== -1 || currentRoute.indexOf('position/hiringTeam') !== -1):
        this.protipData = data.new_position_protip || protipData;
        break;
      case (currentRoute.indexOf('position/clientPosition') !== -1 && document.getElementById('showSummary') !== null):
        this.protipData = data.three_col_page_protip || protipData;
        break;
      case (currentRoute.indexOf('position/clientPosition') !== -1):
        this.protipData = data.position_protip || protipData;
        break;
      case (currentRoute.indexOf('/can/dashboard') !== -1):
        this.protipData = data.candidate_dash_protip || protipData;
        break;
      default:
        this.protipData = protipData;
        break;
    }
  }
}
