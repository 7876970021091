<!-- <span class="action-bar"></span>
<fa-icon class="action-icon" title="Edit" (click)="emitAction('edit')" [icon]="faEdit" *ngIf="(userRole =='Senior Manager') || (userRole =='Account Manager') || (userRole =='Superuser') || (userRole =='Hiring Manager') || (userRole =='Client Admin')"></fa-icon>
<fa-icon class="action-icon" title="Add Candidate"  [icon]="faUserPlus" (click)="emitAction('accociate-candidate')" *ngIf="(userRole =='Senior Manager') || (userRole =='Superuser') || isHiringManager || (userRole =='Client Admin')"></fa-icon>
<fa-icon class="action-icon" title="Hold" (click)="emitAction('hold')" [icon]="faArchive" *ngIf="(userRole =='Senior Manager') || (userRole =='Account Manager') || (userRole =='Superuser') || (userRole =='Hiring Manager') || (userRole =='Client Admin')"></fa-icon>
<fa-icon class="action-icon" title="Clone" (click)="emitAction('clone')" [icon]="faClone" *ngIf="(userRole =='Senior Manager') || (userRole =='Account Manager') || (userRole =='Superuser') || (userRole =='Hiring Manager') || (userRole =='Client Admin')|| (userRole =='Human Resource')"></fa-icon>
<fa-icon class="action-icon" title="Trash" (click)="emitAction('delete')" [icon]="faDelete" *ngIf="(userRole =='Senior Manager') || (userRole =='Account Manager') || (userRole =='Superuser') || (userRole =='Hiring Manager') || (userRole =='Client Admin') || (userRole =='Hiring Manager')"></fa-icon>
<span class="action-bar" *ngIf="!isHiringManager"></span>
<fa-icon class="action-icon" title="Summary"  [icon]="faEye" (click)="emitAction('3-col-page')" *ngIf="(userRole =='Senior Manager') || (userRole =='Account Manager') || (userRole =='Superuser') || isHiringManager || (userRole =='Client Admin') || userRole === 'Human Resource'"></fa-icon>
<fa-icon class="action-icon" title="Vote"  [icon]="faVoteYea" (click)="emitAction('vote')" *ngIf="(userRole =='Superuser' || userRole =='Client Admin' || userRole =='Senior Manager' || userRole =='Account Manager' || userRole =='Hiring Member' || userRole =='Hiring Manager'  || userRole =='Human Resource')"></fa-icon>
<fa-icon class="action-icon" title="Schedule Interviews"  [icon]="faCalendarAlt" (click)="emitAction('schedule')" *ngIf="(userRole =='Superuser' || userRole =='Senior Manager' || userRole =='Account Manager' || userRole =='Hiring Manager' || isHiringManager || userRole =='Client Admin' || userRole =='Human Resource')"></fa-icon>
<span class="action-bar"></span> -->
<!-- <div>
    <span>Progress Summary</span>
    <span>View Interview Schedule</span>
    <span>View Position Details</span>
    <span>Edit Position</span>
    <span>Add Candidate</span>
</div> -->

<nav class="navbar navbar-expand-lg navbar-light bg-dark" style="height: 45px;border-radius: 10px ">
    <a class="nav-link text-light link-size" href="#" style="padding-top: 0.3rem;" [routerLink]="currentUserRoleCode + '/position/view-all-positions'">View All Positions</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item active" >
          <a class="nav-link text-light link-size" (click)="onClick()" href="javascript:void(0)">Progress Summary <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item" >
          <a class="nav-link text-light link-size" (click)="emitAction('schedule')" href="javascript:void(0)">View Interview Schedule</a>
        </li>
        <li class="nav-item" >
          <a class="nav-link text-light link-size" (click)="emitAction('3-col-page')"   href="javascript:void(0)">View Position Details</a>
        </li>
        <li class="nav-item" [ngClass]="{activeLink: isActive('edit')}">
            <a class="nav-link text-light link-size" (click)="emitAction('edit')" href="javascript:void(0)">Edit Position</a>
        </li>
         <li class="nav-item" [ngClass]="{activeLink: isActive('accociate-candidate')}">
            <a id="add-candidate" class="nav-link text-light link-size" (click)="emitAction('accociate-candidate')" href="javascript:void(0)">Add Candidate</a>
        </li>
        <li class="nav-item" [ngClass]="{activeLink: isActive('hold')}">
            <a class="nav-link text-light link-size" (click)="emitAction('hold')" href="javascript:void(0)">Place Position on Hold</a>
        </li>
        <li class="nav-item" [ngClass]="{activeLink: isActive('clone')}">
            <a class="nav-link text-light link-size" (click)="emitAction('clone')" href="javascript:void(0)">Clone Position</a>
        </li>
      </ul>
    </div>
  </nav>
