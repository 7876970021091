import {
  Component, OnInit, ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  Input,
  HostListener,
  ElementRef,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as Url from './../../constants/url';
import { HiringTeamService } from 'src/app/services/hiringteam.service';
import { ToastService } from 'src/app/core/toast/toast-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.services';
import SwiperCore, { Pagination, Navigation } from "swiper";
SwiperCore.use([Pagination, Navigation]);
import { timeZone } from './../../constants/timeZones';

import {
  faUserPlus,
  faFlagCheckered,
  faHourglassStart,
  faCalendarCheck,
  faComments,
  faExclamationCircle,
  faLessThan,
  faGreaterThan,
  faInfoCircle,
  faCarrot,
  faUser,
  faGlobe,
  faVideo, faClock, faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';
import { CandidateService } from 'src/app/services/candidate.service';
const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

const pad = (i: number): string => i < 10 ? `0${i}` : `${i}`;

@Component({
  selector: 'app-angular-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './angular-calendar.component.html',
  styleUrls: ['./angular-calendar.component.css']
})
export class AngularCalendarComponent implements OnInit, OnChanges {

  public today = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
  public yesterday = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
  public imgBaseUrl = Url.imgBaseUrl;
  faCalendar = faCalendarAlt;
  @ViewChild('modalContent', { static: true }) modalContent!: TemplateRef<any>;

  @Input() hrGroupList: any;
  @Input() calendarData: any;
  @Input() selectedHtm: any;
  @Input() candidateList: any;
  @Input() activeClientName: any = 'Qorums Client';
  @Input() selectedHtmArr: any;
  @Input() selectedCan: any;
  @Output() calendarUpdated = new EventEmitter();
  @Output() interviewPageOpened = new EventEmitter();
  @Output() candId = new EventEmitter();
  @Output() HtmData = new EventEmitter();
  @Output() posDateData = new EventEmitter();
  @Input() htmMembers: any;
  minuteStep = 30;
  emailForm!: FormGroup;
  faInfoCircle = faInfoCircle;
  faCarrot = faCarrot;
  faLessThan = faLessThan;
  faGreaterThan = faGreaterThan;
  faUserPlus = faUserPlus;
  faFlagCheckered = faFlagCheckered;
  faHourglassStart = faHourglassStart;
  faCalendarCheck = faCalendarCheck;
  faComments = faComments;
  faExclamationCircle = faExclamationCircle;
  faUser = faUser;
  faGlobe = faGlobe;
  faVideo = faVideo;
  faClock = faClock;
  faCheckCircle = faCheckCircle;
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData!: {
    action: string;
    event: CalendarEvent;
  };

  selectedEmailAddress: any = [];
  zoomUrl: any;

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh = new Subject<void>();

  events: CalendarEvent[] = [
    
  ];

  activeDayIsOpen: boolean = true;
  mailBody: any = '';
  selectedCandidates: any = [];
  isHr: any;
  meetingPwd: any;
  currentPosition: any;
  isInterviewPage = false;
  timeChipsArr: any;
  selectedSlotIndex: any;
  isViewSchedule = false;
  scheduleId: any;
  isEditSchedule = false;
  emailFormHasError: boolean = false;
  positionList: any;
  currentpageURL: string = '';
  toHTMIds: any[];
  option1: boolean;
  option2: boolean;
  showHtmCalWarning: boolean;
  dateError: boolean = false;
  timeZones: any;
  candForInterview: any = {};
  htmNames: any = [];
  currentPositionName: any;
  op_idFromDropDown: any;

  constructor(
    private modal: NgbModal,
    private fb: FormBuilder,
    private htmService: HiringTeamService,
    private modelOpenService: ModalService,
    private toastService: ToastService,
    private hiringTeamService: HiringTeamService,
    private userService: UserService,
    private modalService: NgbModal,
    private candidateService: CandidateService) {

  }

  ngOnInit(): void {
    this.createCalendar();
    this.currentpageURL = window.location.href;
    if (this.candidateList?.length>0)
      this.createEmailForm()
    this.timeZones = timeZone;
    this.currentPositionName = localStorage.getItem('currentPositionName');
    this.currentPosition = localStorage.getItem('currentPosition');
    if (!this.currentPosition) {
      this.getMyCalendarData();
      this.getPositionsForCalendar();
    }

    this.isHr = localStorage.getItem('is_hr') === 'true' ? true : false;
  }

  toggleModal() {
    this.modelOpenService.openModal();
  }
  getPositionsForCalendar() {
    const month = ("0" + (this.viewDate.getMonth() + 1)).slice(-2);
    const year = this.viewDate.getFullYear();
    this.userService.getPositionsForCalendar(month, year).subscribe((res: any) => {
      console.log(res.data);
      this.positionList = res.data
    })
  }
  getMyCalendarData() {
    const month = ("0" + (this.viewDate.getMonth() + 1)).slice(-2);
    const year = this.viewDate.getFullYear();
    this.userService.getUserCalendar(month, year).subscribe((res: any) => {
      this.calendarData = res.data;
      this.createCalendar();
    })
  }
  onPositionSelect(e: any) {
    this.HtmData.emit('clear')
    this.posDateData.emit('clear')
    if(e.target.value==0){
      return
    }
    else if(e.target.value=='all'){
      this.getMyCalendarData()
      this.op_idFromDropDown = null;
    }
    else
    {
      this.getMyCalendarDataForPosition(e.target.value)
      this.op_idFromDropDown = e.target.value
    }
  }
  getMyCalendarDataForPosition(op_id: any) {
    const month = ("0" + (this.viewDate.getMonth() + 1)).slice(-2);
    const year = this.viewDate.getFullYear();
    this.userService.getUserCalendar(month, year, op_id).subscribe((res: any) => {
      this.calendarData = res.data;
      this.createCalendar();
    })
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
    let d = new Date()
    d.setHours(0, 0, 0, 0)
    // if(!events[0].cssClass?.includes('icon-calendar-scheduled')){
    //   this.posDateData.emit(events[0].meta)
    // }
  }
  eventClick(event:CalendarEvent){
    this.HtmData.emit(event.meta.info)
    if(!event.cssClass?.includes('icon-calendar-scheduled')){
      this.posDateData.emit(event.meta)
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: any): void {
    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
    const cssClass: string = event?.cssClass;
    if (cssClass?.indexOf('past-date') > -1) {//cssClass === 'icon-calendar-scheduled' || 
      return;
    }
    if (cssClass?.indexOf('icon-calendar-scheduled') > -1) {
      this.isViewSchedule = true;
      this.scheduleId = event.meta.id;
      this.isEditSchedule = event.meta.isEditable;
    } else {
      this.isViewSchedule = false;
      this.scheduleId = null;
    }
    this.isInterviewPage = true;
    this.interviewPageOpened.emit(this.isInterviewPage);
    if (!this.isViewSchedule) {
      this.patchInterviewFormData(event);
    } else {
      this.patchViewScheduleFormData(event);
    }
  }

  patchInterviewFormData(e: any) {
    let toEmails = '';
    this.emailForm.controls.interviewType.patchValue('');
    this.emailForm.controls.mailBody.patchValue('');
    this.emailForm.controls.mailBody.updateValueAndValidity();
    this.hrGroupList[0].members.forEach((htm: any, index: any) => {
      if (this.selectedHtmArr.indexOf(htm.id) > -1) {
        toEmails += htm.email + ',';
      }
    });
    this.emailForm.controls.toEmails.patchValue(toEmails.substring(0, toEmails.length - 1));
    if (e) {
      this.emailForm.controls.date.patchValue(parseInt(e.start.getMonth() + 1) + '-' + e.start.getDate() + '-' + e.start.getFullYear());
      this.addTimeChips(e);
    } else {
      this.emailForm.controls.date.patchValue((this.viewDate.getMonth() + 1) + '-' + this.viewDate.getDate() + '-' + this.viewDate.getFullYear());
      this.timeChipsArr = [];
    }
  }

  patchViewScheduleFormData(e: any) {
    this.htmService.getScheduledInterviewData(this.scheduleId).subscribe((res: any) => {
      this.emailForm.controls.toEmails.patchValue(res.to_email);
      this.emailForm.controls.subject.patchValue(res.subject);
      this.emailForm.controls.date.patchValue(res.date);
      this.emailForm.controls.startTime.patchValue({ hour: parseInt(res.startTime.hour), minute: parseInt(res.startTime.minute) });
      this.emailForm.controls.endTime.patchValue({ hour: parseInt(res.endTime.hour), minute: parseInt(res.endTime.minute) });
      this.emailForm.controls.mailBody.patchValue(res.body);
      this.mailBody = res.body;
      this.emailForm.controls.interviewType.patchValue(res.interview_type);
      this.emailForm.controls.meetingKey.clearValidators();
      this.emailForm.controls.meetingKey.updateValueAndValidity();
      this.emailForm.updateValueAndValidity();
      this.candId.emit(res.candidate_id)
    });
  }

  addTimeChips(e: any) {
    this.timeChipsArr = [];
    this.selectedSlotIndex = null;
    this.emailForm.controls.startTime.patchValue('');
    this.emailForm.controls.endTime.patchValue('');
    Object.keys(this.calendarData).forEach((key, index) => {
      if (this.convertToDate(key).getTime() === e.start.getTime()) {
        this.calendarData[key].availabilities.forEach((element: any) => {
          element.avails.hours.forEach((hour: any) => {
            // if(hour.startTime.split(':')[1].length === 1) {
            //   hour.startTime += '0';
            // }
            // if(hour.endTime.split(':')[1].length === 1) {
            //   hour.endTime += '0';
            // }
            this.timeChipsArr.push({ startTime: hour.startTime, endTime: hour.endTime });
          });
        });
      }
    });
    console.log(this.timeChipsArr);
  }

  selectTime(slot: any, index: any) {
    this.selectedSlotIndex = index;
    this.emailForm.controls.startTime.patchValue({ hour: parseInt(slot.startTime.split(':')[0]), minute: parseInt(slot.startTime.split(':')[1]) });
    this.emailForm.controls.endTime.patchValue({ hour: parseInt(slot.endTime.split(':')[0]), minute: parseInt(slot.endTime.split(':')[1]) });
    console.log(slot);
  }

  convertToDate(dateString: any) {
    //  Convert a "dd/MM/yyyy" string into a Date object
    let d = dateString.split("-");
    let dat = new Date(d[1] + '/' + d[0] + '/' + d[2]);
    return dat;
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    if(localStorage.getItem('currentPosition'))
    {
      // this.getHtmAvailabilities();
      this.getMyCalendarDataForPosition(localStorage.getItem('currentPosition'))
    }
    else
    {
      if(this.op_idFromDropDown){
        this.getMyCalendarDataForPosition(this.op_idFromDropDown)
      }
      else{
        this.getMyCalendarData()
      }
    }
  }
  formatTime(time: any) {
    if (time.split(':')[1] === '0') {
      time += '0';
    }
    if (parseInt(time.split(':')[0]) === 0) {
      const hour = 12;
      const min = time.split(':')[1];
      return hour + ':' + min + 'AM';
    } else if (parseInt(time.split(':')[0]) < 12) {
      return time + 'AM';
    } else if (parseInt(time.split(':')[0]) === 12) {
      return time + 'PM';
    } else {
      const hour = pad(parseInt(time.split(':')[0]) - 12);
      const min = time.split(':')[1];
      return hour + ':' + min + 'PM';
    }
  }
  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

  createCalendar() {
    this.events = [];
    for (const date in this.calendarData) {
      if (this.calendarData.hasOwnProperty(date)) {
        const d = date.split("-");
        const dateObj = new Date(d[2] + '/' + d[1] + '/' + d[0]);
        let d1 = new Date();
        d1.setHours(0, 0, 0, 0);
        // commented to hide black calender//////////////////////////////////////////////////////////////////////////////////////////
        // if(this.calendarData[date].availabilities.length > 0) {
        //   this.calendarData[date].availabilities.forEach((htm: any, index: any) => {
        //     if(htm?.avails?.hours?.length > 0) {
        //       this.events.push({
        //         start: startOfDay(dateObj),
        //         title: this.availabilityTooltipData(this.calendarData[date].availabilities),
        //         cssClass: dateObj < d1 ? 'icon-calendar past-date' : 'icon-calendar',
        //       });
        //     }
        //   });
        // }
        if (this.calendarData[date].scheduled.length > 0) {
          const scheduledData = this.getDinctinctObjectFromArray(this.calendarData[date].scheduled);
          scheduledData.forEach((element: any) => {
            this.events.push({
              start: startOfDay(dateObj),
              title: this.scheduledTooltipData(element),

              cssClass: dateObj < d1 ? 'icon-calendar-scheduled past-date' : 'icon-calendar-scheduled',
              meta: {
                id: element.id,
                isEditable: element.created_by_current,
                info: element
              }
            });
          });
        }
        if (this.calendarData[date].kickoff_date) {
          const currentPositionName = localStorage.getItem('currentPositionName');
          let pos:string = this.calendarData[date].position_title ?  this.calendarData[date]?.position_title : currentPositionName
          this.events.push({
            start: startOfDay(dateObj),
            title: "Position -"+pos+ "<br>Kickoff Date",
            cssClass: 'kickoff-icon',
            meta:{
              position:pos,
              date:date,
              icon:'kickoff-icon',
              event:'Kickoff'
            }
          });
        }
        if (this.calendarData[date].source_date) {
          const currentPositionName = localStorage.getItem('currentPositionName');
          let pos:string = this.calendarData[date].position_title ? this.calendarData[date]?.position_title : currentPositionName
          this.events.push({
            start: startOfDay(dateObj),
            title: "Position -"+ pos + "<br>Sourcing Date",
            cssClass: 'source-icon',
            meta:{
              position:pos,
              date:date,
              icon:'source-icon',
              event:'Sourcing'
            }
          });
        }
        if (this.calendarData[date].target_deadline) {
          const currentPositionName = localStorage.getItem('currentPositionName');
          let pos:string = this.calendarData[date].position_title ? this.calendarData[date]?.position_title : currentPositionName
          this.events.push({
            start: startOfDay(dateObj),
            title: "Position -"+pos+ "<br>Target Deadline Date",
            cssClass: 'deadline-icon',
            meta:{
              position:pos,
              date:date,
              icon:'deadline-icon',
              event:'Target'
            }
          });
        }
        if (this.calendarData[date].deadlines) {
          const currentPositionName = localStorage.getItem('currentPositionName');
          this.calendarData[date].deadlines.forEach((item: any) => {
            let pos:string = item?.position_title ? item?.position_title : currentPositionName
            this.events.push({
              start: startOfDay(dateObj),
              title: item.msg,
              cssClass: 'htm-deadline-icon',
              meta:{
                position:pos,
                date:date,
                photo:item.profile_photo,
                icon:'htm-deadline-icon',
                htm_name:item.htm_name
              }
            });
          })
        }
        if (this.calendarData[date].interview_done) {
          this.calendarData[date].interview_done.forEach((item: any) => {
            this.events.push({
              start: startOfDay(dateObj),
              title: item.msg,
              cssClass: 'interview-completed',

            });
          })
        }
      }
    }
    this.refresh.next();
  }

  scheduledTooltipData(scheduledData: any) {
    return '<ul><li>' + scheduledData?.candidate + ' has interview scheduled with ' + scheduledData?.interviewer_names + ' on ' + scheduledData?.sep_date + ' ' + scheduledData?.sep_time + ' ' + scheduledData?.long_timezone +'</li></ul>';
  }

  getDinctinctObjectFromArray(data: any) {
    let resArr: any = [];
    data.filter((item: any) => {
      var i = resArr.findIndex((x: any) => (x.name == item.name && x.date == item.date && x.amt == item.amt));
      if (i <= -1) {
        resArr.push(item);
      }
    });
    return resArr;
  }

  availabilityTooltipData(htmAvilabilities: any) {
    let tooltipText = 'Please click on the calendar icon to create an interview for this date. <br /> <br /> Availability: <br />'
    htmAvilabilities.forEach((element: any) => {
      element.avails?.hours.forEach((hour: any, index: any) => {
        if (index === 0) {
          tooltipText += this.getHtmNameFromId(element.htm) + ': <br/>';
        }
        tooltipText += this.formatTime(hour.startTime) + ' - ' + this.formatTime(hour.endTime);
        tooltipText += '</br>'
      });
    });
    return tooltipText;
  }

  getHtmNameFromId(htmId: any) {
    return this.hrGroupList[0].members.find((el: any) => el.id == htmId).name;
  }

  ngOnChanges() {
    this.createCalendar();
    // this.getHtmAvailabilities();
    this.getMyCalendarDataForPosition(localStorage.getItem('currentPosition'))
    // this.generateMailBody();
    // this.generateTOMail();
  }

  generateTOMail() {
    let toEmails = ''
    this.emailForm.controls.htms.setValue(this.selectedHtmArr);
    this.hrGroupList[0].members.forEach((htm: any, index: any) => {
      if (this.selectedHtmArr.indexOf(htm.id) > -1) {
        toEmails += htm.email + ',';
      }
    });
    this.emailForm.controls.toEmails.patchValue(toEmails.substring(0, toEmails.length - 1));
  }
  // getHtmAvailabilities() {
  //   this.currentPosition = localStorage.getItem('currentPosition');
  //   const month = ("0" + (this.viewDate.getMonth() + 1)).slice(-2);
  //   const year = this.viewDate.getFullYear();
  //   if (this.currentPosition) {
  //     this.hiringTeamService.getOpenPositionCalendar(month, year, this.currentPosition, this.selectedHtmArr).subscribe((res: any) => {
  //       this.calendarData = res.data;
  //       if (this.calendarData) {
  //         this.createCalendar();
  //       }
  //     });
  //   }
  // }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (event.target.classList.contains('send-mail')) {
      this.emailForm.controls.toEmails.patchValue('');
      this.emailForm.controls.mailBody.patchValue('');
      this.mailBody = '';
      this.selectedCandidates = [];
      this.selectedEmailAddress = [];
      this.zoomUrl = null;
      this.modal.open(this.modalContent, { size: 'xl', windowClass: "email-modal", backdrop: 'static' });
    } else if (event.target.classList.contains('show-all-htm')) {
      this.events = [];
      this.calendarData.forEach((htmData: any) => {
        htmData.availability.forEach((day: any) => {
          day.hours.forEach((hour: any) => {
            const startHour = parseInt(hour.startTime.split(':')[0]);
            const endHour = parseInt(hour.endTime.split(':')[0]);
            this.events.push({
              start: addHours(startOfDay(new Date(day.date?.replace(/-/g, '\/').replace(/T.+/, ''))), startHour),
              end: addHours(startOfDay(new Date(day.date?.replace(/-/g, '\/').replace(/T.+/, ''))), endHour),
              // title: this.hrGroupList[0].members.filter((htm: any) => htm.id === htmData.htm_id)[0].name + ' ('+ this.formatTime(hour.startTime) + '-' +this.formatTime(hour.endTime) + ')',
              title: day.htm_created || day.scheduled ?
                day.htm_name + '<br />' + day.time + '<br />' + (day.position_name || day.posititon) + '<br />' + (day.candidate_name || '')
                : day.htm_name + ' (' + this.formatTime(hour.startTime) + '-' + this.formatTime(hour.endTime) + ')' + '<br />' + (day.candidate_name || ''),
              color: {
                primary: day.htm_created || day.scheduled ? 'red' : htmData.color,
                secondary: '#EEEEEE',
              },
              actions: this.actions,
            });
          });
        });
      });
    }
  }
  openInterviewModal(interviewPage: any) {
    this.createEmailForm()
    this.selectedHtmArr = [];
    this.toHTMIds = [];
    this.htmNames =[];
    this.option1 = true;
    this.option2 = false;
    this.showHtmCalWarning = false
    this.modalService.open(interviewPage, { size: 'lg' });
  }
  giveMeRole(roles: any): any {
    if (roles[0] == "is_htm") {
      return 'HM'
    }
    if (roles[0] == "is_sm") {
      return 'SM'
    }
    if (roles[0] == "is_ca") {
      return 'CA'
    }
    else {
      return 'NA'
    }
  }
  createEmailForm() {
    this.emailForm = this.fb.group({
      toEmails: ['', Validators.required],
      ccEmails: [''],
      subject: ['Your interview with ' + this.activeClientName, Validators.required],
      mailBody: [this.mailBody, Validators.required],
      date: ['', Validators.required],
      timezone: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      interviewType: [''],
      candidate: ['', Validators.required],
      htms: [''],
      zoom_link: [''],
      specific: [true],
      meetingKey: ['', Validators.required],
      conference_id: [''],
      op_id: [localStorage.getItem('currentPosition')],
      client_id: [this.candidateList[0].client_id],
      next_days: [''],
      duration: [''],
      long_timezone:['']
    });
  }

  toggleZohoInterview(type?: any) {
    if (type) {
      if (type === 'zoho') {
        this.emailForm.controls.meetingKey.addValidators(Validators.required);
        this.emailForm.controls.meetingKey.updateValueAndValidity();
        const payload = {
          presenter: JSON.parse(localStorage.getItem('currentUser') || '')?.email,
          start: this.emailForm.controls.date.value,
          subject: this.emailForm.value.subject,
          toEmails: this.emailForm.value.toEmails,
          timezone: this.emailForm.value.timezone,
          date: this.emailForm.value.date,
          startTime: this.emailForm.value.startTime,
          endTime: this.emailForm.value.endTime
        }
        this.htmService.generateMeetingUrl(payload).subscribe((res: any) => {
          this.zoomUrl = res?.meeting_link;
          this.emailForm.controls.conference_id.patchValue(res.conference_id);
          this.emailForm.controls.meetingKey.patchValue(res.meeting_link);
          this.emailForm.controls.mailBody.patchValue(this.generateMailBody());
        });
      } else {
        this.emailForm.controls.meetingKey.clearValidators();
        this.emailForm.controls.meetingKey.updateValueAndValidity();
        this.zoomUrl = null;
        this.emailForm.controls.mailBody.patchValue(this.generateMailBody());
      }
    }
    // let candidateNames = '';
    // // const candidateList = this.selectedCandidates.filter((el: any) => el.candidate_id);
    // const candidateList = this.candForInterview
    // candidateNames = this.candForInterview.name
    // // candidateList.forEach((can: any, index: any) => {
    // //   candidateNames += can.name + ' ' + can.last_name;
    // //   if (candidateList.length - 1 !== index) {
    // //     candidateNames += ', ';
    // //   }
    // // });
    // const currentPositionName = localStorage.getItem('currentPositionName');
    // if (!type) {
    //   type = this.emailForm.controls.interviewType.value;
    // }
    // switch (type) {
    //   case 'zoho':
    //     this.emailForm.controls.subject.patchValue(this.activeClientName + ' QVideo Interview - ' + currentPositionName + ' - ' + candidateNames);
    //     return;
    //   case 'in-person':
    //     this.emailForm.controls.subject.patchValue(this.activeClientName + ' In-Person Interview - ' + currentPositionName + ' - ' + candidateNames);
    //     return;
    //   case 'phone':
    //     this.emailForm.controls.subject.patchValue(this.activeClientName + ' Phone Interview - ' + currentPositionName + ' - ' + candidateNames);
    //     return;
    // }
  }

  selectTimeZone(e: any) {
    if (e.target.value != "Open this select menu"){
      let obj = this.timeZones.find(o => o.display === e.target.value);
      this.emailForm.controls['timezone'].setValue(obj?.value)
      this.emailForm.controls['long_timezone'].setValue(e.target.value)
    }
    else
      {this.emailForm.controls['timezone'].setValue('')
      this.emailForm.controls['long_timezone'].setValue('')}

  }
  selectInterviewDuration(e: any) {
    if (e.target.value != "Open this select menu")
      this.emailForm.controls['duration'].setValue(e.target.value)
    else
      this.emailForm.controls['duration'].setValue('')
  }
  onAvailableTime(e: any) {
    if (e.target.value != "Open this select menu")
      this.emailForm.controls['next_days'].setValue(e.target.value)
    else
      this.emailForm.controls['next_days'].setValue('')
  }
  openFile(file: any) {
    window.open(file, '_blank')
  }
  generateMailBody() {

    if (this.isViewSchedule) {
      return;
    }
    let mailBody = ``;

    if (this.zoomUrl) {
      mailBody += `\nYour QVideo interview link is: ` + this.zoomUrl + `\n\n`;
    }
    let candidateContent = '';
    this.selectedCan = this.candForInterview
    if (this.selectedCan) {
      candidateContent += this.selectedCan.name || 'N/A';
      // if(htm.linkedin_id && htm.linkedin_id !== '') {
      candidateContent += `\nLinkedIn: ` + (this.selectedCan.skype_id || 'N/A');
      // }
      if (this.selectedCan.phone_number && this.selectedCan.phone_number !== '') {
        candidateContent += `\nPhone number: ` + this.selectedCan.phone_number + `\n\n`;
      }
    }
    mailBody += candidateContent;
    // this.emailForm.controls['mailBody'].patchValue('mailBody');
    return mailBody;
  }
  optionChanges(op: any) {
    if (op == 1) {
      this.option1 = true;
      this.option2 = false;
      this.showHtmCalWarning = false
      this.emailForm.controls['specific'].setValue(true)
    }
    if (op == 2) {
      this.option1 = false;
      this.option2 = true;
      this.emailForm.controls['specific'].setValue(false)
      this.emailForm.controls.date.clearValidators();
      this.emailForm.controls.date.updateValueAndValidity();
      this.emailForm.controls.endTime.clearValidators();
      this.emailForm.controls.endTime.updateValueAndValidity();
      this.emailForm.controls.startTime.clearValidators();
      this.emailForm.controls.startTime.updateValueAndValidity();
      this.emailForm.controls.meetingKey.clearValidators();
      this.emailForm.controls.mailBody.setValue('')
    }
    this.selectedHtmArr = []
    this.toHTMIds = []
    this.htmNames =[]
    this.candForInterview = ''
    this.emailForm.controls.toEmails.patchValue(this.selectedHtmArr.toString());
    this.emailForm.controls.htms.patchValue(this.toHTMIds);
  }
  checkTime() {
    let d1 = new Date(this.emailForm.value.date)
    d1.setHours(this.emailForm.value.startTime.split(":")[0])
    d1.setMinutes(this.emailForm.value.startTime.split(":")[1]);
    let d2 = new Date(this.emailForm.value.date)
    d2.setHours(this.emailForm.value.endTime.split(":")[0])
    d2.setMinutes(this.emailForm.value.endTime.split(":")[1])
    let d3 = new Date()
    if (this.emailForm.value.startTime == this.emailForm.value.endTime) {
      this.dateError = true
      return true
    }
    if (d1 < d3) {
      this.dateError = true
      return true
    }
    if (d2 < d1) {
      this.dateError = true
      return true
    }
    else {
      this.dateError = false
      return false
    }
  }

  sendEmail(successMsg: any) {
    if (this.option1 && this.checkTime()) {
      setTimeout(() => { this.dateError = false }, 3000)
      return

    }
    this.emailForm.controls.zoom_link.patchValue(this.zoomUrl);
    this.emailForm.controls.duration.patchValue(parseInt(this.emailForm.value.duration));
    this.emailForm.controls.next_days.patchValue(parseInt(this.emailForm.value.next_days));
    this.candidateService.sendInterviewMail(this.emailForm.value).subscribe((res: any) => {
      this.modalService.dismissAll();
      this.modalService.open(successMsg, { size: 'md' });
    })
  }
  isIntToday(cand: any) {
    cand.formated_date
    var inputDate = new Date(cand.formated_date);
    var todaysDate = new Date();
    if (inputDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
      return true;
    }
    else
      return false
  }
  selectCandForInterview(htm: any) {
    this.candForInterview = htm
    this.emailForm.controls.candidate.patchValue(this.candForInterview.candidate_id);
  }
  selectHTMForInterview(htm: any) {
    if (this.option2) {
      if (!htm.has_cronofy_calendar) {
        this.showHtmCalWarning = true;
        return;
      }
    }
    this.showHtmCalWarning = false
    if (this.selectedHtmArr.indexOf(htm.email) > -1) {
      const index = this.selectedHtmArr.indexOf(htm.email);
      this.selectedHtmArr.splice(index, 1); // 2nd parameter means remove one item only
      this.toHTMIds.splice(index, 1);
      this.htmNames.splice(index, 1);
    }
    else {
      this.selectedHtmArr.push(htm.email)
      this.htmMembers.forEach((htms: any, index: any) => {
        if (htm.email == htms.email) {
          this.toHTMIds.push(htms.id);
          this.htmNames.push(htms.name)
        }
      });
    }
    this.emailForm.controls.toEmails.patchValue(this.selectedHtmArr.toString());
    this.emailForm.controls.htms.patchValue(this.toHTMIds);
  }
  show12Format(hr:any){
    return hr % 12 || 12;
  }
  showTimeSuffix(hr:any){
    return hr >= 12 ? "PM" : "AM";
  }
}
