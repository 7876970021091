import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  faCalendarAlt,
  faEnvelope,
  faFile,
  faHandPointDown,
  faListAlt,
  faThumbsDown,
  faThumbsUp,
} from '@fortawesome/free-regular-svg-icons';
import { faPlus, faStar, faUserAlt } from '@fortawesome/free-solid-svg-icons';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AssociateCandidateComponent } from '../associate-candidate/associate-candidate.component';
import { SetAvailabilityComponent } from '../set-availability/set-availability.component';
import { CandidateService } from '../../services/candidate.service';
import { ToastService } from '../../core/toast/toast-service';
import { AuthService } from 'src/app/services/auth.services';

@Component({
  selector: 'app-candidate-details',
  templateUrl: './candidate-details.component.html',
  styleUrls: ['./candidate-details.component.css'],
})
export class CandidateDetailsComponent implements OnInit {
  public selectedCandidate: any = {};
  public candidateList: any[] = [];
  public faThumbup = faThumbsUp;
  public faThumbdown = faThumbsDown;
  public faThumbhold = faHandPointDown;
  public SelCandidate: any;
  public SelMember: any;
  public showTopCandidate: boolean = true;
  public candidateAverageData: any = {};
  public noData: boolean = false;
  public memberThumbVal: string = '';
  public showCandidateDetails: boolean = false;
  public candidateDetailData: any = {};
  public userRole: string = '';
  public faPlus = faPlus;
  public faCalendar = faCalendarAlt;
  currentUserRoleCode: any;
  constructor(
    private candidateService: CandidateService, private modalService: NgbModal, private authService: AuthService
  ) {}

  ngOnInit(): void {
    let uerdata:any = localStorage.getItem('currentUser');
    uerdata = JSON.parse(uerdata);
    if(uerdata) {
      this.userRole = uerdata?.role;
    }
    this.candidateService.getCandidateData().subscribe((val) => {
      if (val && Object.keys(val).length) {
        this.candidateDetailData = val;
        // this.candidateDetailData.comments?.replace("\n", "<br>");
      } else {
        this.candidateDetailData = undefined;
      }
    });
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
  }

  candidateDetails() {
    this.candidateService
      .getPositionsCandidates(this.candidateDetailData.op_id)
      .subscribe((values: any) => {
        if (values) {
          values.filter((val: any) => {
            if (this.candidateDetailData?.candidate_id === val.candidate_id) {
              this.candidateService.setCandidateData(val);
            }
          });
        }
      });
  }

  openAssociateCandidate() {
    const modalRef = this.modalService.open(AssociateCandidateComponent);
    modalRef.componentInstance.name = 'associate';
  }

  openSetAvailability() {
    const modalRef = this.modalService.open(SetAvailabilityComponent, { size: 'lg' });
    modalRef.componentInstance.name = 'scheduler';
  }
}
