import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {


  private homeUrl = new BehaviorSubject<string>('');

  constructor(private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.homeUrl.next(event.urlAfterRedirects);
    });
  }

  getHomeUrl() {
    return this.homeUrl.asObservable();
  }
}
