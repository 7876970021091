import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.services';
import { UserService } from 'src/app/services/user.service';
import {
  faPlus,
  faEdit,
  faTrash,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import * as Url from './../../constants/url';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/core/toast/toast-service';
@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.css']
})
export class AllUsersComponent implements OnInit {
  allUsers: any[] = [];
  immutableAllUsersData: any;
  currentUserRoleCode: any;
  userRole: any;
  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
  faTimes = faTimes;
  imageBaseUrl: string;
  currentUser: any;
  searchKey = '';

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private toastService: ToastService
  ) {
    this.imageBaseUrl = Url.imgBaseUrl;
  }

  ngOnInit(): void {
    let uerdata: any = localStorage.getItem('currentUser');
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    uerdata = JSON.parse(uerdata);
    if (uerdata) {
      this.userRole = uerdata?.role;
    }
    this.getAllUsers();
  }

  getAllUsers() {
        this.userService.getAllUsers().subscribe((res: any) => {
      this.immutableAllUsersData = JSON.parse(JSON.stringify(res.data));
      this.allUsers = res.data;
           // this.allUsers = [{"username":"abc04","id":69,"first_name":"abc","last_name":"04","phone_number":"9443455334","skype_id":"","job_title":"abc 04 title","email":"sdfs@ds.dd","profile_photo":"/api/media/Screenshot%20from%202023-07-11%2001-30-04.png","roles":["is_ca","is_sm"]}];
      // this.allUsers.map((element: any) => {
      //   element.allRoles = '';
      //   element.roles.forEach((role: any, index: any) => { 
      //     if(role === 'is_ca') {
      //       element.allRoles += 'Client Admin';
      //     } 
      //     if(role === 'is_sm') {
      //       element.allRoles += 'Senior Manager';
      //     }
      //     if(role === 'is_htm' || role === 'is_he') {
      //       element.allRoles += 'Hiring Team Member';
      //     }
      //     if(index + 1 !== element.roles.length) {
      //       element.allRoles += ', ';
      //     }
      //   });
        
      // });
    });
  }

  editUser(user: any) {
    this.router.navigate([this.currentUserRoleCode + '/create-user'],{ queryParams: { id: user.id}})
  }

  openSm(content: any, user: string) {
    this.currentUser = user;
    this.modalService.open(content, { size: 'sm' });
  }

  deleteUser() {
    this.userService.deleteUser(this.currentUser.username).subscribe((res: any) => {
      this.showSuccess('User deleted successfully!');
      this.modalService.dismissAll();
      this.getAllUsers();
    });
  }

  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

  searchFilter: any=[];
  filterSearch(type: string) {
    if(this.searchFilter.indexOf(type) === -1) {
    this.searchFilter.push(type);
    } else {
      const index = this.searchFilter.indexOf(type);
      this.searchFilter.splice(index, 1);
    }
    this.searchUser();
  }

  searchUser() {
    // this.allUsers = this.immutableAllUsersData.filter((el: any) => {
    //   if(el?.first_name?.indexOf(this.searchKey) > -1 || 
    //     el?.last_name?.indexOf(this.searchKey) > -1 || 
    //     el?.job_title?.indexOf(this.searchKey) > -1 ||
    //     el?.username?.indexOf(this.searchKey) > -1) {
    //       return true;
    //     }
    //     return false;
    // });
    if(this.searchKey !== '' || this.searchFilter.length > 0) {
      this.userService.searchUsers(this.searchKey, this.searchFilter).subscribe((res: any) => {
        this.immutableAllUsersData = JSON.parse(JSON.stringify(res.data));
        this.allUsers = res.data;
      });
    } else {
      this.userService.getAllUsers().subscribe((res: any) => {
        this.immutableAllUsersData = JSON.parse(JSON.stringify(res.data));
        this.allUsers = res.data;
      });
    }
  }
}
