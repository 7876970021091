// modal.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    private modalSubject = new Subject<any>();
    modalState = this.modalSubject.asObservable();

    constructor() { }

    openModal() {
        this.modalSubject.next({ action: 'open' });
    }

    closeModal() {
        this.modalSubject.next({ action: 'close' });
    }
}