import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faUserPlus, faEye, faClone, faTrash, faArchive, faEdit, faVoteYea, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth.services';

@Component({
  selector: 'position-actions',
  templateUrl: './position-actions.component.html',
  styleUrls: ['./position-actions.component.css']
})
export class PositionActionsComponent implements OnInit {
  @Input() positionData: any;
  @Output() actionEmitter = new EventEmitter();
  isHiringManager: any;
  currentPosition: any;
  userRole: any;
  faUserPlus = faUserPlus;
  faEye = faEye;
  faClone = faClone;
  faDelete = faTrash;
  faEdit = faEdit;
  faArchive = faArchive;
  faVoteYea = faVoteYea;
  faCalendarAlt = faCalendarAlt;
  currentItem: string = '';
  currentUserRoleCode: any;
  
  constructor(private authService:AuthService,
              private router: Router) { }

  ngOnInit(): void {
    let uerdata:any = localStorage.getItem('currentUser');
    this.isHiringManager = localStorage.getItem('is_hiring_manager') === 'true';
    this.currentPosition = localStorage.getItem('currentPosition');
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    uerdata = JSON.parse(uerdata);
    if(uerdata) {
      this.userRole = uerdata?.role;
    }
  }

  emitAction(action: string) {
    this.currentItem = action;
    this.actionEmitter.emit(action);
  }
  isActive(item:string){
    return this.currentItem == item;
  }
  onClick(){
   // this.router.navigate([this.authService.currentUserRoleCode + '/position/clientPosition']);
    window.location.reload();
  }

}
