<div class="row">
    <div class="col-md-12">
        <div class="widget-col">
            <div class="widget-box" style="height: auto; padding:0px;">
                <div class="pro-summary-box">
                    <!-- [hidden]="(currentUser.role === 'Hiring Member' || currentUser.role === 'Hiring Manager') && isHiringManager === 'false'" -->
                    <div class="summary-table">
                        <table class="table g-4 summary-table" *ngIf="ht_data.length > 0"
                            [ngClass]="{'table-overflow-x': ht_data.length > 6}">
                            <tr *ngFor="let can of candidateList; let i = index"
                                [ngStyle]="{'background-color': can.withdrawed || can.requested ? '#bed2e3' : '#ffffff'}">

                                <ng-container *ngFor="let head of ht_data; let j = index">
                                    <td [style.width]="tabWidth" class="align-middle table-td"
                                        *ngIf="can?.flag_by_hiring_manager?.length > 0  "
                                        [ngStyle]="{'background-color': can?.flag_by_hiring_manager[j]?.flag == 'Golden Glove' ? 'var(--secondary-light)' : '#ffffff'}">
                                        <div class="table-box"
                                            *ngIf="can?.flag_by_hiring_manager[j]?.flag == 'Interview Confirmed'">
                                            <span class="marks-flag i-s-confirm">
                                                <fa-icon
                                                    [styles]="{'stroke': 'var(--primary-200)', 'color': 'var(--primary-200)', 'font-size': '30px'}"
                                                    [icon]="faCalendarAlt"></fa-icon>
                                            </span>

                                            <span class="info">
                                                <span class="box-heading">
                                                    <p class="mb-0">Interview CONFIRMED</p>
                                                </span>
                                                <span style="color: #666666;">
                                                    <p class="mb-0"> Date: {{can?.flag_by_hiring_manager[j]?.date}}
                                                    </p>
                                                    <p class="mb-0">Time:
                                                        {{can?.flag_by_hiring_manager[j]?.time}}<br>
                                                        ({{can?.flag_by_hiring_manager[j]?.long_timezone}})
                                                    </p>
                                                </span>

                                            </span>
                                        </div>
                                        <div class="table-box"
                                            *ngIf="can?.flag_by_hiring_manager[j]?.flag == 'Interview Started'">
                                            <span class="marks-flag i-s-confirm">
                                                <fa-icon
                                                    [styles]="{'stroke': 'var(--primary-200)', 'color': 'var(--primary-200)', 'font-size': '30px'}"
                                                    [icon]="faCalendarAlt"></fa-icon>
                                            </span>

                                            <span class="info">
                                                <span class="box-heading">
                                                    <p class="mb-0">Interview Started</p>
                                                </span>
                                                <span style="color: #666666;"
                                                    *ngIf="can?.flag_by_hiring_manager[j]?.button_data.type=='zoho'">
                                                    <p class="mb-0">Time:
                                                        {{can?.flag_by_hiring_manager[j]?.time}}<br>
                                                        ({{can?.flag_by_hiring_manager[j]?.long_timezone}})
                                                    </p>
                                                    <button class="btn btn-primary" [disabled]="isDisableInt(can)"
                                                        style="box-shadow: 0px 3px var(--primary-400);"
                                                        (click)="startInterview(can,j)">Start QVideo</button>
                                                </span>
                                                <span style="color: #666666;"
                                                    *ngIf="can?.flag_by_hiring_manager[j]?.button_data.type=='phone'">
                                                    <p class="mb-0">Time:
                                                        {{can?.flag_by_hiring_manager[j]?.time}}<br>
                                                        ({{can?.flag_by_hiring_manager[j]?.long_timezone}})
                                                    </p>
                                                    <button class="btn btn-primary"
                                                        style="box-shadow: 0px 3px var(--primary-400);">Start Phone
                                                        Interview</button>
                                                </span>
                                                <span style="color: #666666;"
                                                    *ngIf="can?.flag_by_hiring_manager[j]?.button_data.type=='in-person'">
                                                    <p class="mb-0">Time:
                                                        {{can?.flag_by_hiring_manager[j]?.time}}<br>
                                                        ({{can?.flag_by_hiring_manager[j]?.long_timezone}})
                                                    </p>
                                                    <button class="btn btn-primary"
                                                        style="box-shadow: 0px 3px var(--primary-400);">Start In
                                                        Person
                                                        Interview</button>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="table-box"
                                            *ngIf="can?.flag_by_hiring_manager[j]?.flag == 'Interview NOT CONFIRMED'">
                                            <span class="marks-flag i-s-confirm">
                                                <fa-icon
                                                    [styles]="{'stroke': '#FE0000', 'color': '#FE0000', 'font-size': '30px'}"
                                                    [icon]="faCalendarAlt"></fa-icon>
                                            </span>

                                            <span class="info">
                                                <span class="box-heading">
                                                    <p class="mb-0">Interview NOT CONFIRMED</p>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="table-box"
                                            *ngIf="can?.flag_by_hiring_manager[j]?.flag == 'Interview Today'">
                                            <span class="marks-flag i-s-confirm">
                                                <fa-icon
                                                    [styles]="{'stroke': 'var(--primary-200)', 'color': 'var(--primary-200)', 'font-size': '30px'}"
                                                    [icon]="faCalendarAlt"></fa-icon>
                                            </span>

                                            <span class="info">
                                                <span style="font-weight: 600;">
                                                    <p class="mb-0">Interview Today</p>
                                                </span>
                                                <span style="color: #666666;"
                                                    *ngIf="can?.flag_by_hiring_manager[j]?.button_data.type=='zoho'">
                                                    <p class="mb-0">Time:
                                                        {{can?.flag_by_hiring_manager[j]?.time}}<br>
                                                        ({{can?.flag_by_hiring_manager[j]?.long_timezone}})
                                                    </p>
                                                    <button class="btn btn-primary" [disabled]="isDisableInt(can)"
                                                        style="box-shadow: 0px 3px var(--primary-400);"
                                                        (click)="startInterview(can,j)">Start QVideo</button>
                                                </span>
                                                <span style="color: #666666;"
                                                    *ngIf="can?.flag_by_hiring_manager[j]?.button_data.type=='phone'">
                                                    <p class="mb-0">Time:
                                                        {{can?.flag_by_hiring_manager[j]?.time}}<br>
                                                        ({{can?.flag_by_hiring_manager[j]?.long_timezone}})
                                                    </p>
                                                    <button class="btn btn-primary"
                                                        style="box-shadow: 0px 3px var(--primary-400);">Start Phone
                                                        Interview</button>
                                                </span>
                                                <span style="color: #666666;"
                                                    *ngIf="can?.flag_by_hiring_manager[j]?.button_data.type=='in-person'">
                                                    <p class="mb-0">Time:
                                                        {{can?.flag_by_hiring_manager[j]?.time}}<br>
                                                        ({{can?.flag_by_hiring_manager[j]?.long_timezone}})
                                                    </p>
                                                    <button class="btn btn-primary"
                                                        style="box-shadow: 0px 3px var(--primary-400);">Start In
                                                        Person
                                                        Interview</button>
                                                </span>

                                            </span>
                                        </div>
                                        <div class="table-box"
                                            *ngIf="can?.flag_by_hiring_manager[j]?.flag == 'Not Given'">
                                            <span class="marks-flag i-s-confirm" style="background-color: #FFCC66;"
                                                *ngIf="can?.flag_by_hiring_manager[j]?.flag == 'Not Given'">
                                                <fa-icon
                                                    [styles]="{'stroke': '#CC6601', 'color': '#CC6601', 'font-size': '30px'}"
                                                    [icon]="faExclamation"></fa-icon>
                                            </span>

                                            <span class="info"
                                                *ngIf="can?.flag_by_hiring_manager[j]?.flag == 'Not Given'">
                                                <span class="box-heading">
                                                    <p class="mb-0">Interview NOT Scheduled</p>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="table-box"
                                            *ngIf="can?.flag_by_hiring_manager[j]?.flag == 'Interview Missed'">
                                            <span class="marks-flag i-s-confirm" style="background-color: #FE0000;"
                                                *ngIf="can?.flag_by_hiring_manager[j]?.flag == 'Interview Missed'">
                                                <fa-icon
                                                    [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '30px'}"
                                                    [icon]="faExclamation"></fa-icon>
                                            </span>

                                            <span class="info"
                                                *ngIf="can?.flag_by_hiring_manager[j]?.flag == 'Interview Missed'">
                                                <span class="box-heading">
                                                    <p class="mb-0">Interview Missed.</p>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="table-box"
                                            *ngIf="can?.flag_by_hiring_manager[j]?.flag == 'Complete Evaluation'">
                                            <span class="marks-flag i-s-confirm" style="background-color: #c7fe00;"
                                                *ngIf="can?.flag_by_hiring_manager[j]?.flag == 'Complete Evaluation'">
                                                <fa-icon
                                                    [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '30px'}"
                                                    [icon]="faExclamation"></fa-icon>
                                            </span>

                                            <span class="info"
                                                *ngIf="can?.flag_by_hiring_manager[j]?.flag == 'Complete Evaluation'">
                                                <span class="box-heading">
                                                    <p class="mb-0 eval-text">Complete Evaluation</p>
                                                </span>

                                                <button class="btn btn-primary btn-sm" [disabled]="isDisableInt(can)"
                                                    style="box-shadow: 0px 3px var(--primary);"
                                                    (click)="openEvaluationPopup(can)">Complete Evaluation</button>
                                            </span>
                                        </div>
                                        <div class="table-box"
                                            *ngIf="can?.flag_by_hiring_manager[j]?.flag == 'Evaluation Pending'">
                                            <span class="marks-flag i-s-confirm" style="background-color: #c7fe00;"
                                                *ngIf="can?.flag_by_hiring_manager[j]?.flag == 'Evaluation Pending'">
                                                <fa-icon
                                                    [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '30px'}"
                                                    [icon]="faExclamation"></fa-icon>
                                            </span>
                                            <span class="info"
                                                *ngIf="can?.flag_by_hiring_manager[j]?.flag == 'Evaluation Pending'">
                                                <span class="box-heading">
                                                    <p class="mb-0">Evaluation Pending</p>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="table-box"
                                            *ngIf="currentUser.role !== 'Human Resource' && can?.flag_by_hiring_manager[j]?.flag == 'Golden Glove'">
                                            <span class="marks-flag i-s-confirm" style="background-color: #FBCD3C;">
                                                <fa-icon
                                                    [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '30px'}"
                                                    [icon]="faThumbup"></fa-icon>
                                            </span>

                                            <span class="info">
                                                <span class="box-heading">
                                                    <p class="mb-0">Golden Glove</p>
                                                    <p class="mb-0">Interview Completed</p>
                                                    <p class="mb-0">Score: {{can?.flag_by_hiring_manager[j]?.marks}}
                                                    </p>
                                                </span>
                                            </span>
                                        </div>

                                        <div class="table-box"
                                            *ngIf="currentUser.role !== 'Human Resource' && can?.flag_by_hiring_manager[j]?.flag == 'Thumbs Up'">
                                            <span class="marks-flag i-s-confirm" style="background-color: #00AF78;">
                                                <fa-icon
                                                    [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '30px'}"
                                                    [icon]="faThumbup"></fa-icon>
                                            </span>

                                            <span class="info">
                                                <span class="box-heading">
                                                    <p class="mb-0">Has Potential</p>

                                                </span>
                                                <span style="color: #666666;">
                                                    <p class="mb-0">Score: {{can?.flag_by_hiring_manager[j]?.marks}}
                                                    </p>
                                                </span>
                                            </span>
                                        </div>

                                        <div class="table-box"
                                            *ngIf="currentUser.role !== 'Human Resource' && can?.flag_by_hiring_manager[j]?.flag == 'Thumbs Down'">
                                            <span class="marks-flag i-s-confirm" style="background-color: #FE0000;">
                                                <fa-icon
                                                    [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '30px'}"
                                                    [icon]="faThumbdown"></fa-icon>
                                            </span>

                                            <span class="info">
                                                <span class="box-heading">
                                                    <p class="mb-0">Does not have Potential</p>

                                                </span>
                                                <span style="color: #666666;">
                                                    <p class="mb-0">Score: {{can?.flag_by_hiring_manager[j]?.marks}}
                                                    </p>
                                                </span>
                                            </span>
                                        </div>

                                    </td>
                                </ng-container>

                            </tr>
                        </table>
                        <label *ngIf="ht_data.length < 1">Hiring Team Members data not available.</label>
                    </div>
                    <!-- *ngIf="currentUser.role === 'Hiring Member' || currentUser.role === 'Hiring Manager'" -->
                    <div class="summary-table"
                        [hidden]="(currentUser.role === 'Hiring Member' || currentUser.role === 'Hiring Manager') && isHiringManager === 'true'"
                        *ngIf="false">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="widget-area">
                                    <h6>Interviews to complete</h6>
                                    <span class="widget-point">{{openPositionDetail?.interviews_to_complete ||
                                        0}}</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="widget-area">
                                    <h6>Position Specifications</h6>
                                    <p *ngIf="openPositionDetail?.decumentation.length == 0">No documents uploaded for
                                        this position</p>
                                    <p *ngFor="let doc of openPositionDetail?.decumentation">
                                        <fa-icon [icon]="faFile"></fa-icon>
                                        <a class="ml-3 mt-2" [href]="imageBaseUrl + doc" target="_blank">{{doc}}</a>
                                    </p>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="widget-area voting-history">
                                    <h6>Voting History</h6>
                                    <div class="row justify-content-end">
                                        <div class="like col-4 d-flex">
                                            <span class="widget-point">{{openPositionDetail?.voting_history_likes ||
                                                0}}</span>
                                            <span class="marks-flag thumbs-up">
                                                <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}"
                                                    [icon]="faThumbup" size="sm"></fa-icon>
                                            </span> Like
                                        </div>
                                        <div class="dislike col-4 d-flex">
                                            <span class="widget-point">{{openPositionDetail?.voting_history_passes ||
                                                0}}</span>
                                            <span class="marks-flag thumbs-down">
                                                <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}"
                                                    [icon]="faThumbdown" size="sm"></fa-icon>
                                            </span> Pass
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- //summary-table-->
                    <!-- *ngIf="(currentUser.role === 'Hiring Member' || currentUser.role === 'Hiring Manager') && isHiringManager === 'false'" -->
                    <div *ngIf="false" class="row mb-5">
                        <div class="col-sm-12">
                            <div class="widget-area htm-summary">
                                <h6>Summary</h6>
                                <div class="gray-area" *ngIf="candidateList.length > 0">
                                    <ul class="widget-user-list">
                                        <ng-container *ngFor="let can of candidateList; let i = index">
                                            <li *ngIf="!can.withdrawed" class="userInfo" (click)="showListPage(can)">
                                                <a href="javascript:void(0)">
                                                    <figure>
                                                        <!-- <img class="candidate-profile-pic img-fluid" height="40px" width="40px" [src]="can.profile_photo && can.profile_photo !== 'None' ? baseUrl + can.profile_photo : './../../../assets/images/big-default-avatar.png'" /> -->
                                                        <img *ngIf="can.profile_photo || (!can.profile_photo && !can.profile_pic_url)"
                                                            class="prof-pic"
                                                            src="{{can.profile_photo && can.profile_photo !== 'None' ? baseUrl + can.profile_photo : './../../assets/images/big-default-avatar.png'}}"
                                                            alt="Profile photo" />
                                                        <img *ngIf="can.profile_pic_url" class="prof-pic"
                                                            [src]="can.profile_pic_url" alt="Profile photo" />
                                                    </figure>
                                                    <span class="user-info">
                                                        <span class="name">{{ can?.name }} {{ can?.last_name }}</span>
                                                        <!-- <div class="float-right">
                                                            <span class="scroll_point">{{can?.final_avg_marks | number : '1.1-1'}}</span>
                                                            <span class="scroll_name">Score</span>
                                                        </div> -->
                                                        <!-- <span class="marks-flag i-s-confirm" style="background-color: #FBCD3C;"  > 
                                                            <fa-icon [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '30px'}" [icon]="faThumbup" ></fa-icon>
                                                        </span> -->
                                                        <div class="float-right candidate-summary-icons mr-4">
                                                            <span class="marks-flag thumbs-down new-width"
                                                                *ngIf="can?.flag === 'Thumbs Down'">
                                                                <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}"
                                                                    [icon]="faThumbdown" size="lg"></fa-icon>
                                                            </span>
                                                            <span class="marks-flag thumbs-up new-width"
                                                                *ngIf="can?.flag === 'Thumbs Up'">
                                                                <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}"
                                                                    [icon]="faThumbup" size="lg"></fa-icon>
                                                            </span>
                                                            <span class="marks-flag new-width"
                                                                *ngIf="can?.flag === 'Golden Glove'">
                                                                <fa-icon
                                                                    [styles]="{'stroke': '#fff', 'color': '#ffd700', 'font-size': '35px'}"
                                                                    [icon]="faThumbup" size="lg"></fa-icon>
                                                            </span>
                                                            <span class="marks-flag hold new-width"
                                                                [ngbTooltip]="interviewTimeTooltip"
                                                                (mouseenter)="getInterviewTime(can?.flag_by_hiring_manager[0])"
                                                                *ngIf="can?.flag_by_hiring_manager[0]?.flag === 'Interview Scheduled'">
                                                                <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}"
                                                                    [icon]="faCalendarAlt" size="lg"></fa-icon>
                                                            </span>
                                                            <span class="marks-flag not-given new-width"
                                                                [ngbTooltip]="'Interview not scheduled, please check.'"
                                                                *ngIf="(can?.flag === 'Not Given' || !can?.flag) && (can?.flag_by_hiring_manager[0]?.flag !== 'Interview Scheduled' || !can?.flag_by_hiring_manager[0]?.flag)">
                                                                <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}"
                                                                    [icon]="faExclamation"></fa-icon>
                                                            </span>
                                                            <!-- <span class="marks-flag hold" *ngIf="can?.flag === 'Hold'">
                                                                <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}" [icon]="faThumbup" size="lg"></fa-icon>
                                                            </span> -->
                                                            <!-- <img *ngIf="can?.flag === 'Thumbs Up'" _ngcontent-giv-c131="" height="55px" width="55px" src="./../../../assets/images/thumbsup.png">
                                                            <img *ngIf="can?.flag === 'Hold'" _ngcontent-giv-c131="" height="55px" width="55px" src="./../../../assets/images/hold.png">
                                                            <img *ngIf="can?.flag === 'Thumbs Down'" _ngcontent-giv-c131="" height="55px" width="55px" src="./../../../assets/images/thumbsdowm.png"> -->
                                                        </div>
                                                    </span>
                                                </a>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                                <div *ngIf="candidateList.length === 0">
                                    No candidate information available
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="task-progress-table m-1 widget-inner-box row">
                        <div *ngIf="onHoverHtm" class="deadline-wrapper">
                            
                            <div *ngIf="daysToDeadline !== null" [attr.data-before-content]="daysToDeadline > 0 ? daysToDeadline+ ' days to completion' : 'Deadline over'" class="deadline-progress" [ngStyle]="{'background': 'radial-gradient(closest-side, white 59%, transparent 60% 100%), conic-gradient(var(--primary) '+deadlineDatePercentage+'%, #b5b5b5 0)'}">
                                <progress value="{{deadlineDatePercentage}}" min="0" max="100" style="visibility:hidden;height:0;width:0;">{{deadlineDatePercentage}}%</progress>
                            </div>
                            <div class="legends">
                                <div class="legend ">
                                    <p><span class="square kick-off"></span>Kick-off Deadline: {{kickoffDate  || 'NA'}} </p>
                                </div>
                                <div class="legend ">
                                    <p><span class="square sourcing"></span>Sourcing Deadline: {{sourcingDeadlineDate  || 'NA'}} </p>
                                </div>
                                <div class="legend ">
                                    <p><span class="square target"></span>Target Completion Date: {{targetDeadlineDate  || 'NA'}} </p>
                                </div>
                            </div>
                        </div>
                        <div class="chart-wrapper">
                            <app-htm-timeline-chart></app-htm-timeline-chart>
                        </div>
                    </div> -->

                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Send Mail</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="mailForm">
            <div class="form-group row">
                <label for="input-jobtitle" class="col-sm-3 col-form-label">To <small>*</small></label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="email">
                    <div *ngIf="mailForm.controls['email'].invalid && (mailForm.controls['email'].dirty || mailForm.controls['email'].touched)"
                        class="alert">
                        <div *ngIf="mailForm.controls['email'].errors?.required">
                            Email is required.
                        </div>
                        <div *ngIf="mailForm.controls['email'].errors?.pattern">
                            Invalid Email, please try again.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="input-jobtitle" class="col-sm-3 col-form-label">Subject <small>*</small></label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="subject">
                    <div *ngIf="mailForm.controls['subject'].invalid && (mailForm.controls['subject'].dirty || mailForm.controls['subject'].touched)"
                        class="alert">
                        <div *ngIf="mailForm.controls['subject'].errors?.required">
                            Subject is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="input-jobtitle" class="col-sm-3 col-form-label">Body</label>
                <div class="col-sm-9">
                    <textarea class="form-control" formControlName="message"></textarea>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="mailForm.invalid" (click)="sentMail()">Send</button>
    </div>
</ng-template>

<ng-template #weighting let-modal>
    <div class="modal-header">

        <div class="row" style="padding: 1em ">
            <div class="col-sm-3 modal-pic-box">
                <figure>
                    <img class="candidate-profile-pic img-fluid weighting-prof-pric" height="40px" width="40px"
                        [src]="htm_details.photo && htm_details.photo !== 'None' && htm_details.photo !== 'null' ? imageBaseUrl + htm_details.photo : './../../assets/images/big-default-avatar.png'" />
                </figure>
            </div>
            <div class="col-sm-9">
                <h4 class="modal-title">{{htm_details.name}} </h4>
                <h5 class="modal-title">Job Title: {{htm_details.job_title || 'NA'}}</h5>
                <h5 class="modal-title">Phone:{{htm_details.phone || 'NA'}}</h5>
                <h5 class="modal-title">Email:{{htm_details.email || 'NA'}} </h5>
                <h5 class="modal-title">Roles: <span *ngFor="let role of htm_details.roles">{{role === 'is_htm' ? '
                        Hiring Team Member -' : role === 'is_sm' ? ' Senior Manager -' : role === 'is_ca' ?
                        ' Client Admin ' : ''}}</span></h5>
            </div>

        </div>

        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="skillSetForm">
            <h4 class="modal-title" style="margin-bottom: -0.7em;margin-left: 0.8em;">Weighting </h4>
            <table class=" weighting-table">
                <tr>
                    <th colspan="2"></th>
                    <th style="text-align: end;padding-right: 2.6em;">Importance</th>
                </tr>
                <tr *ngFor="let item of [].constructor(skill_no.length); let i=index;">
                    <td style="width: 15%; font-weight: 600;">
                        Skill {{i+1}}
                    </td>
                    <td style="width: 15%;">
                        {{skill_no[i]}}
                    </td>
                    <td style="width: 60%;">
                        <div style="display: flex;align-items: center; justify-content: end; margin-right: 2em">
                            <span class="minus-icon"
                                (click)="dereaseWeightage('init_qualify_ques_'+(i+1)+'_weightage')">
                                <fa-icon [icon]="faMinus" class="fa fa-minus"></fa-icon>
                            </span>
                            <span
                                class="skill-score">{{skillSetForm.controls['init_qualify_ques_'+(i+1)+'_weightage'].value}}</span>
                            <span class="plus-icon"
                                (click)="increaseWeightage('init_qualify_ques_'+(i+1)+'_weightage')">
                                <fa-icon [icon]="faPlus" class="fa fa-plus"></fa-icon>
                            </span>
                        </div>
                    </td>
                </tr>
            </table>
            <br>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button id="update-weighting" type="button" class="btn btn-primary" (click)="saveHtmWeightage()">Update</button>
    </div>
</ng-template>
<ng-template #example>
    <div class="notification" *ngIf="notificationData && notificationData[0]">
        <p>{{notificationData[0].Text}} completed?</p>
        <div class="btn-container">
            <button class="btn btn-primary mr-3" (click)="closeNotification(true)">Yes</button>
            <button class="btn btn-danger" (click)="closeNotification(false)">No</button>
        </div>
    </div>
</ng-template>
<ng-template #interviewTimeTooltip>
    <p *ngIf="interviewTime">
        Date: {{interviewTime?.date}} <br />
        Time: {{interviewTime?.time}}
    </p>
</ng-template>
<ng-template #evaluationForm>
    <div class="m-5">
        <form [formGroup]="ratingForm">
            <h6>Choose the topics to evaluate</h6>
            <div formArrayName="marks" *ngFor="let skill of getRatingFormMarksFields(); let i=index"
                class="form-group row score-slider-row mt-3 eva-slider">
                <ng-container [formGroupName]="i">
                    <label class="col-sm-4 px-0 d-flex">
                        <input (change)="onChangeReadOnly($event, i)" class="mr-2 checks"
                            [checked]="!evaluationSlider[i].sliderOptions.disabled" type="checkbox"
                            [disabled]="getCheckbox(i)" />
                        {{getRatingFormMarksFields()[i].get('skillset_name')?.value }}
                    </label>
                    <div class="col-sm-8">
                        <ngx-slider [(value)]="evaluationSlider[i].sliderValue"
                            [options]="evaluationSlider[i].sliderOptions"
                            (valueChange)="onSliderValueChange($event, i)"></ngx-slider>
                    </div>
                    <div [hidden]="evaluationSlider[i].sliderOptions.disabled" class="col-sm-9 mt-3 pl-3">
                        <div class="row pl-4">
                            <div class="col-sm-12 ">
                                <textarea readonly class="form-control d-none"
                                    placeholder="Suggested question"></textarea>
                                <div class="suggested-ques">
                                    Suggested Questions : <span
                                        *ngIf="evaluationSlider[i].skillset_questions===''"></span>
                                    <p *ngIf="candidateMarks[i].skillset_questions.length<1">No questions available/p>
                                    <p *ngFor="let question of candidateMarks[i].skillset_questions; let j = index">
                                        {{j+1}} - {{question.text}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                Notes<small>*</small>
                                <div>
                                    <textarea class="form-control" formControlName="skillset_ans"
                                        placeholder=" Notes*"></textarea>
                                    <p class="text-danger text-end" *ngIf="getErrorIfNotesEmpty(i)">
                                        Notes required</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="form-group row candidate-vote">
                <label for="input-password " class="col-sm-2 col-form-label text-left">Comments /
                    Feedback:
                </label>
                <textarea class="form-control col-sm-10" formControlName="feedback"></textarea>
                <p class="text-danger text-end"
                    *ngIf="ratingForm.controls.feedback.hasError('required') && ratingForm.controls.feedback.touched">
                    Please add feedback</p>
            </div>
            <div class="form-group row candidate-vote">
                <label for="input-password " class="col-sm-2 col-form-label text-left">Vote for this
                    candidate:<small>*</small> </label>
                <div class="col-sm-10">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label liked voteButton1" (click)="getSelectedRating('thumbs_up')"
                            [ngClass]="{'voted1': ratingForm.controls['thumbs_up'].value === true}">
                            <input for="candidate-like" class="ml-2 vote-icon" type="radio" name="candidate-vote"
                                value="thumbs_up">
                            <span id="candidate-like" class="like-icon">
                                <span class="marks-flag thumbs-up"
                                    [ngClass]="{'input-checked' :ratingForm.controls['thumbs_up'].value === true}">
                                    <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}" [icon]="faThumbsUp"
                                        size="xs"
                                        [ngClass]="{'voted2': ratingForm.controls['thumbs_up'].value === true}"></fa-icon>
                                </span>
                            </span>
                            <span>Candidate has potential</span>
                        </label>
                        <label class="form-check-label ml-3 passed  voteButton2"
                            (click)="getSelectedRating('thumbs_down')"
                            [ngClass]="{'votedd': ratingForm.controls['thumbs_down'].value === true}">
                            <input for="candidate-pass" class="ml-2 vote-icon" type="radio" name="candidate-vote"
                                value="thumbs_down">
                            <span id="candidate-pass" class="pass-icon">
                                <span class="marks-flag thumbs-down"
                                    [ngClass]="{'input-checked' :ratingForm.controls['thumbs_down'].value === true}">
                                    <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}" [icon]="faThumbsDown"
                                        size="xs"
                                        [ngClass]="{'voted3': ratingForm.controls['thumbs_down'].value === true}"></fa-icon>
                                </span>
                            </span>
                            <span>Candidate is not suitable</span>
                        </label>
                        <label class="form-check-label ml-3 liked golden  voteButton3"
                            (click)="getSelectedRating('golden_gloves')"
                            [ngClass]="{'votedg': ratingForm.controls['golden_gloves'].value === true}">
                            <input for="candidate-pass" class="ml-2 vote-icon" type="radio" name="candidate-vote"
                                value="golden_gloves">
                            <span id="candidate-pass" class="golden-icon">
                                <span class="marks-flag thumbs-up"
                                    [ngClass]="{'input-checked' :ratingForm.controls['golden_gloves'].value === true}">
                                    <fa-icon [styles]="{'stroke': '#fff', 'color': '#ffd700', 'font-size': '35px'}"
                                        [icon]="faThumbsUp" size="xs"
                                        [ngClass]="{'voted4': ratingForm.controls['golden_gloves'].value === true}"></fa-icon>
                                </span>
                            </span>
                            <span>Award the golden glove</span>
                        </label>
                    </div>
                    <div>
                        <p *ngIf="alertMsg"><small>Please vote and fill marks and notes for all the
                                skills.</small></p>
                    </div>
                </div>
            </div>
            <div class="errors text-danger text-end">
                <p *ngIf="ratingForm.errors">{{ratingForm.errors.message}}</p>
            </div>
            <button type="button" class="btn btn-primary float-right mb-3" (click)="saveRating()">Save</button>
        </form>
    </div>
</ng-template>