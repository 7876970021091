import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewBeforeRenderEvent,
  CalendarView,
} from 'angular-calendar';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

import {
  faUserPlus,
  faFlagCheckered,
  faHourglassStart,
  faCalendarAlt,
  faCalendarCheck,
  faComments,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import { HiringTeamService } from 'src/app/services/hiringteam.service';
const pad = (i: number): string => i < 10 ? `0${i}` : `${i}`;

@Component({
  selector: 'app-htm-audit-trail-calendar',
  templateUrl: './htm-audit-trail-calendar.component.html',
  styleUrls: [
    './htm-audit-trail-calendar.component.css'
  ]
})
export class HtmAuditTrailCalendarComponent {
  @Input() selectedHtm: any;
  @Output() auditData: any = new EventEmitter();
  faUserPlus = faUserPlus;
  faFlagCheckered = faFlagCheckered;
  faHourglassStart = faHourglassStart;
  faCalendarAlt = faCalendarAlt;
  faCalendarCheck = faCalendarCheck;
  faComments = faComments;
  faExclamationCircle = faExclamationCircle;
  @ViewChild('modalContent', { static: true }) modalContent!: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData!: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [
    {
      start: subDays(startOfDay(new Date()), 1),
      end: addDays(new Date(), 1),
      title: 'A 3 day event',
      color: colors.red,
      actions: this.actions,
      allDay: true,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
    },
    {
      start: startOfDay(new Date()),
      title: 'An event with no end date',
      color: colors.yellow,
      actions: this.actions,
    },
    {
      start: subDays(endOfMonth(new Date()), 3),
      end: addDays(endOfMonth(new Date()), 3),
      title: 'A long event that spans 2 months',
      color: colors.blue,
      allDay: true,
    },
    {
      start: addHours(startOfDay(new Date()), 2),
      end: addHours(new Date(), 2),
      title: 'A draggable and resizable event',
      color: colors.yellow,
      actions: this.actions,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
    },
  ];

  activeDayIsOpen: boolean = true;
  calendarData: any;

  constructor(
    private modal: NgbModal,
    private htmService: HiringTeamService
  ) {
    if(this.selectedHtm) {
      this.getSingleHtmAuditData();
    } else {
      this.getHtmAuditData();
    }
  }

  ngOnChanges() {
    console.log('Selected HTM -->', this.selectedHtm);
    if(this.selectedHtm) {
      this.getSingleHtmAuditData();
    } else {
      this.getHtmAuditData();
    }
  }

  getHtmAuditData() {
    this.htmService.getHtmAudit(localStorage.getItem('currentPosition')).subscribe((res: any) => {
      this.mapCalendar(res);
    });
  }

  getSingleHtmAuditData() {
    this.htmService.getSingleHtmAudit(localStorage.getItem('currentPosition'), this.selectedHtm?.id).subscribe((res: any) => {
      this.mapCalendar(res);
      this.auditData.emit(res);
    });
  }

  mapCalendar(res: any) {
    this.calendarData = res.data;
      this.events = [];
      for (const date in this.calendarData){
        if (this.calendarData.hasOwnProperty(date)) {
          // console.log("Key is " + date + ", value is " + this.calendarData[date]);
          const d = date.split("-");
          const dateObj = new Date(d[2] + '/' + d[1] + '/' + d[0]);
          this.calendarData[date].dateObj = dateObj;
          if(Object.keys(this.calendarData)[0] === date) {
            this.viewDate = dateObj;
          }
          if(this.calendarData[date].icons.length > 0) {
            this.calendarData[date].icons.forEach((icon: any) => {
              this.events.push({
                start: startOfDay(dateObj),
                // end: addHours(startOfDay(new Date(day.date?.replace(/-/g, '\/').replace(/T.+/, ''))), endHouthisr),
                title: icon.name !== 'availability' ? icon.msg: this.availabilityTooltipData(icon.availability),
                color: {
                  primary: icon.color,
                  secondary: '#EEEEEE',
                },
                cssClass: icon.name + ' ' + (icon.color_name || 'gray'),
                meta: icon.color
                // actions: this.actions,
              });
            });
          }
        }
      }
  }
  
  availabilityTooltipData(date: any) {
    let tooltipText = 'Availability - '
    date.forEach((element: any) => {
      element.hours.forEach((hour: any, index: any) => {
        tooltipText += this.formatTime(hour.startTime) + ',';
      });
    });
    return tooltipText;
  }

  formatTime(time: any) {
    if(time.split(':')[1] === '0') {
      time += '0';
    }
    if(parseInt(time.split(':')[0]) === 0) {
      const hour = 12;
      const min = time.split(':')[1];
      return hour + ':' + min + 'AM';
    } else if(parseInt(time.split(':')[0]) < 12) {
      return time + 'AM';
    } else if(parseInt(time.split(':')[0]) === 12) {
      return time + 'PM';
    } else {
      const hour = pad(parseInt(time.split(':')[0]) - 12);
      const min = time.split(':')[1];
      return hour + ':' + min + 'PM';
    }
  }
  
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    renderEvent.body.forEach((day) => {
      const d1 = day.date.getDate();
      const m1 = day.date.getMonth();
      const y1 = day.date.getFullYear()
      for (const date in this.calendarData){
        if (this.calendarData.hasOwnProperty(date)) {
          const d2 = this.calendarData[date].dateObj.getDate();
          const m2 = this.calendarData[date].dateObj.getMonth();
          const y2 = this.calendarData[date].dateObj.getFullYear()
         if(d1 === d2 && m1 === m2 && y1 === y2) {
          day.cssClass = 'bg-grey';
         }
        }
      }
    });
  }
}
