import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as url from "./../constants/url";
import { AuthService } from "./auth.services";

@Injectable({ providedIn: 'root'})

export class DashboardService {
    constructor(private http: HttpClient, private authService: AuthService){}

    getClientList(){
        return this.http.get(url.allClients).pipe();
    }
    getSelectClientData(id:any){
        return this.http.get(url.selectedClient+'/'+id).pipe();
    }
    getAllPositionData(id:any){
        return this.http.get(url.allPositions+'/'+id).pipe();
    }

    getDashboardData(clientId?: any) {
        const dashUrl = clientId ? url.getClientDashboard + '/' + clientId : url.getQorumsDashboard;
        return this.http.get(dashUrl).pipe();
    }

    getAllEmailTemplates() {
        return this.http.get(url.allEmailTemplate).pipe();
    }

    updateEmailTemplateById(id: any, payload: any) {
        return this.http.put(url.emailTemplate + '?id=' + id, payload).pipe();
    }
    getEmailTemplateById(id:any){
        return this.http.get(url.emailTemplate + '?id=' + id).pipe();
    }
    updateJobPosting(id:any, data:any){
        return this.http.put(url.updateJobPosting + '/' +id,data);
    }
    getJobBoardPosition(payload:any,pg:any){
        return this.http.post(url.JobBoardPositions + '?page=' + pg, payload)
    }
    getJobTitles(){
        return this.http.get(url.JobBoardTitles).pipe();
    }
}