<main id="landingpage">
    <!-- <app-header></app-header> -->
    <div class="widget-wrapper">
        <div class="landingpage">
            <div class="login-page">
                <a class="u-logo" href="javascript:void(0);"><img width="600" src="./../../../assets/images/quorums-logo.png" /></a>
                <div class="form-section">
                    <form id="login-form" [formGroup]="resetPasswordForm">
                        <div class="form-group row">
                            <h5 style="padding-left: 8em;">Please provide your new password to reset it.</h5>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Password</label>
                            <div class="col-sm-8">
                                <input type="password" class="form-control" formControlName="password" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Confirm Password</label>
                            <div class="col-sm-8">
                                <input type="password" class="form-control" formControlName="confPassword" />
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="resetPasswordForm.errors?.mismatch && (resetPasswordForm.controls['password'].dirty || resetPasswordForm.controls['confPassword'].touched)">
                            <label class="col-form-label col-sm-4"></label>
                            <div class="alert col-sm-offset-4 col-sm-8 py-0" style="font-size: 14px;color: red;">
                                Confirm password should match with your password.
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-form-label col-sm-4"></label>
                            <div class="col-sm-8">
                                <button type="submit" class="btn btn-primary" (click)="resetPassword()" [disabled]="resetPasswordForm.errors?.mismatch && (resetPasswordForm.controls['password'].dirty || resetPasswordForm.controls['confPassword'].touched)">Reset Password</button>&nbsp;
                                <a href="javascript:void(0)" href="/login" class="forgot-password">Get me back to login</a>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</main>
