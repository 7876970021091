<div class="error" *ngIf="pendingInterviewAcceptance">Interview is not yet scheduled or candidate is yet to accept the
    interview. Once the candidate accepts the interview then you can give the assessment.</div>
<div class="profileContent">
    <div class="zoom-links mb-5" *ngIf="zoom_links?.length">
        <a class="btn btn-primary mr-2" href="javascript:void(0)" (click)="fetchZohoMeeting(link); modalRoot.show()"
            *ngFor="let link of zoom_links; let i = index">
            <fa-icon [icon]="faVideo"></fa-icon>
            <p class="m-0">{{link.date}} - {{link.time}}</p>
        </a>
        <!-- [ngClass]="{'disabled': link.disabled}" -->
    </div>
    <div class="voting-section mt-0" [ngClass]="{'pending-interview-acceptance': pendingInterviewAcceptance}">

        <form *ngIf="candidateMarks" id="votingbox" [formGroup]="ratingForm" [ngClass]="{'view-only': !showSaveButton}">
            <small>An asterisk (*) indicates a required field</small>
            <!-- <h3>{{candidateData?.name}}</h3> -->
            <div *ngIf="candidateMarks.init_qualify_ques_1_text != ''" class="form-group row score-slider-row mt-3 ">
                <label class="col-sm-3 col-form-label">
                    {{candidateMarks.init_qualify_ques_1_text}}<small>*</small>
                    <input (change)="onChangeReadOnly($event, 'options1')" class="ml-2 checks" type="checkbox"
                        formControlName="criteria_1_checked" />
                </label>
                <div class="col-sm-8">
                    <ngx-slider [(value)]="inputVal1" (userChangeEnd)="calculateOverallScore()" [options]="options1"
                        formControlName="criteria_1_marks"></ngx-slider>
                </div>
                <div class="col-sm-1 candidate-score">
                    <fa-icon *ngIf="!cheveron1Show"
                        [ngClass]="{'disabled': !ratingForm.controls['criteria_1_checked'].value}"
                        (click)="cheveron1Show = !cheveron1Show" class="chevron-icon"
                        [styles]="{'stroke': '#000', 'color': '#000'}" [icon]="faChevronDown" size="lg"></fa-icon>
                    <fa-icon *ngIf="cheveron1Show" (click)="cheveron1Show = !cheveron1Show" class="chevron-icon"
                        [styles]="{'stroke': '#000', 'color': '#000'}" [icon]="faChevronUp"
                        [ngClass]="{'disabled': !showSaveButton}" size="lg"></fa-icon>
                    <span class="scroll_point range-val"
                        *ngIf="!ratingForm.controls['criteria_1_checked'].value && userRole !='Hiring Member'">{{inputVal1
                        || 'N/A'}}</span>
                    <span class="scroll_point range-val"
                        *ngIf="ratingForm.controls['criteria_1_checked'].value && userRole !='Hiring Member'">{{inputVal1}}</span>
                </div>

                <div [hidden]="!cheveron1Show" class="col-sm-9 offset-sm-3 mt-3">
                    <div class="row">
                        <div class="col-sm-6">
                            <textarea readonly class="form-control d-none" placeholder="Suggested question"
                                formControlName="init_qualify_ques_suggestion_1"></textarea>
                            <div class="suggested-ques">
                                <ol>
                                    <li *ngFor="let question of suggestedQuestions[0]">{{question}}</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <textarea class="form-control" placeholder="Notes * (minimum 30 characters)"
                                formControlName="suggestion_1"></textarea>
                            <p class="alert p-0" style="color: red;"
                                *ngIf="ratingForm.controls['suggestion_1'].errors?.required">This field is mandatory</p>
                            <p class="alert p-0" style="color: red;"
                                *ngIf="ratingForm.controls['suggestion_1'].errors?.minlength">Please enter minimum 30
                                characters</p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="candidateMarks.init_qualify_ques_2_text != ''" class="form-group row score-slider-row">
                <label class="col-sm-3 col-form-label">
                    {{candidateMarks.init_qualify_ques_2_text}}<small>*</small>
                    <input (change)="onChangeReadOnly($event, 'options2')" class="ml-2" type="checkbox"
                        formControlName="criteria_2_checked" />
                </label>
                <div class="col-sm-8">
                    <ngx-slider [(value)]="inputVal2" (userChangeEnd)="calculateOverallScore()" [options]="options2"
                        formControlName="criteria_2_marks"></ngx-slider>
                </div>
                <div class="col-sm-1 candidate-score">
                    <fa-icon *ngIf="!cheveron2Show"
                        [ngClass]="{'disabled': !ratingForm.controls['criteria_2_checked'].value}"
                        (click)="cheveron2Show = !cheveron2Show" class="chevron-icon"
                        [styles]="{'stroke': '#000', 'color': '#000'}" [icon]="faChevronDown" size="lg"></fa-icon>
                    <fa-icon *ngIf="cheveron2Show" (click)="cheveron2Show = !cheveron2Show" class="chevron-icon"
                        [styles]="{'stroke': '#000', 'color': '#000'}" [icon]="faChevronUp"
                        [ngClass]="{'disabled': !showSaveButton}" size="lg"></fa-icon>
                    <span class="scroll_point range-val"
                        *ngIf="!ratingForm.controls['criteria_2_checked'].value && userRole !='Hiring Member'">{{inputVal2
                        || 'N/A'}}</span>
                    <span class="scroll_point range-val"
                        *ngIf="ratingForm.controls['criteria_2_checked'].value && userRole !='Hiring Member'">{{inputVal2}}</span>
                </div>

                <div [hidden]="!cheveron2Show" class="col-sm-9 offset-sm-3 mt-3">
                    <div class="row">
                        <div class="col-sm-6">
                            <textarea readonly class="form-control d-none" placeholder="Suggested question"
                                formControlName="init_qualify_ques_suggestion_2"></textarea>
                            <div class="suggested-ques">
                                <ol>
                                    <li *ngFor="let question of suggestedQuestions[1]">{{question}}</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <textarea class="form-control" placeholder="Notes * (minimum 30 characters)"
                                formControlName="suggestion_2"></textarea>
                            <p class="alert p-0" style="color: red;"
                                *ngIf="ratingForm.controls['suggestion_2'].errors?.required">This field is mandatory</p>
                            <p class="alert p-0" style="color: red;"
                                *ngIf="ratingForm.controls['suggestion_2'].errors?.minlength">Please enter minimum 30
                                characters</p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="candidateMarks.init_qualify_ques_3_text != ''" class="form-group row score-slider-row">
                <label class="col-sm-3 col-form-label">
                    {{candidateMarks.init_qualify_ques_3_text}}<small>*</small>
                    <input (change)="onChangeReadOnly($event, 'options3')" class="ml-2" type="checkbox"
                        formControlName="criteria_3_checked" />
                </label>
                <div class="col-sm-8">
                    <ngx-slider [(value)]="inputVal3" (userChangeEnd)="calculateOverallScore()" [options]="options3"
                        formControlName="criteria_3_marks"></ngx-slider>
                </div>
                <div class="col-sm-1 candidate-score">
                    <fa-icon *ngIf="!cheveron3Show"
                        [ngClass]="{'disabled': !ratingForm.controls['criteria_3_checked'].value}"
                        (click)="cheveron3Show = !cheveron3Show" class="chevron-icon"
                        [styles]="{'stroke': '#000', 'color': '#000'}" [icon]="faChevronDown" size="lg"></fa-icon>
                    <fa-icon *ngIf="cheveron3Show" (click)="cheveron3Show = !cheveron3Show" class="chevron-icon"
                        [styles]="{'stroke': '#000', 'color': '#000'}" [icon]="faChevronUp"
                        [ngClass]="{'disabled': !showSaveButton}" size="lg"></fa-icon>
                    <span class="scroll_point range-val"
                        *ngIf="!ratingForm.controls['criteria_3_checked'].value && userRole !='Hiring Member'">{{inputVal3
                        || 'N/A'}}</span>
                    <span class="scroll_point range-val"
                        *ngIf="ratingForm.controls['criteria_3_checked'].value && userRole !='Hiring Member'">{{inputVal3}}</span>
                </div>

                <div [hidden]="!cheveron3Show" class="col-sm-9 offset-sm-3 mt-3">
                    <div class="row">
                        <div class="col-sm-6">
                            <textarea readonly class="form-control d-none" placeholder="Suggested question"
                                formControlName="init_qualify_ques_suggestion_3"></textarea>
                            <div class="suggested-ques">
                                <ol>
                                    <li *ngFor="let question of suggestedQuestions[2]">{{question}}</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <textarea class="form-control" placeholder="Notes * (minimum 30 characters)"
                                formControlName="suggestion_3"></textarea>
                            <p class="alert p-0" style="color: red;"
                                *ngIf="ratingForm.controls['suggestion_3'].errors?.required">This field is mandatory</p>
                            <p class="alert p-0" style="color: red;"
                                *ngIf="ratingForm.controls['suggestion_3'].errors?.minlength">Please enter minimum 30
                                characters</p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="candidateMarks.init_qualify_ques_4_text != ''" class="form-group row score-slider-row">
                <label class="col-sm-3 col-form-label">
                    {{candidateMarks.init_qualify_ques_4_text}}
                    <input (change)="onChangeReadOnly($event, 'options4')" class="ml-2" type="checkbox"
                        formControlName="criteria_4_checked" />
                </label>
                <div class="col-sm-8">
                    <ngx-slider [(value)]="inputVal4" (userChangeEnd)="calculateOverallScore()" [options]="options4"
                        formControlName="criteria_4_marks"></ngx-slider>
                </div>
                <div class="col-sm-1 candidate-score">
                    <fa-icon *ngIf="!cheveron4Show"
                        [ngClass]="{'disabled': !ratingForm.controls['criteria_4_checked'].value}"
                        (click)="cheveron4Show = !cheveron4Show" class="chevron-icon"
                        [styles]="{'stroke': '#000', 'color': '#000'}" [icon]="faChevronDown" size="lg"></fa-icon>
                    <fa-icon *ngIf="cheveron4Show" (click)="cheveron4Show = !cheveron4Show" class="chevron-icon"
                        [styles]="{'stroke': '#000', 'color': '#000'}" [icon]="faChevronUp"
                        [ngClass]="{'disabled': !showSaveButton}" size="lg"></fa-icon>
                    <span class="scroll_point range-val"
                        *ngIf="!ratingForm.controls['criteria_4_checked'].value && userRole !='Hiring Member'">{{inputVal4
                        || 'N/A'}}</span>
                    <span class="scroll_point range-val"
                        *ngIf="ratingForm.controls['criteria_4_checked'].value && userRole !='Hiring Member'">{{inputVal4}}</span>
                </div>

                <div [hidden]="!cheveron4Show" class="col-sm-9 offset-sm-3 mt-3">
                    <div class="row">
                        <div class="col-sm-6">
                            <textarea readonly class="form-control d-none" placeholder="Suggested question"
                                formControlName="init_qualify_ques_suggestion_4"></textarea>
                            <div class="suggested-ques">
                                <ol>
                                    <li *ngFor="let question of suggestedQuestions[3]">{{question}}</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <textarea class="form-control" placeholder="Notes * (minimum 30 characters)"
                                formControlName="suggestion_4"></textarea>
                            <p class="alert p-0" style="color: red;"
                                *ngIf="ratingForm.controls['suggestion_4'].errors?.required">This field is mandatory</p>
                            <p class="alert p-0" style="color: red;"
                                *ngIf="ratingForm.controls['suggestion_4'].errors?.minlength">Please enter minimum 30
                                characters</p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="candidateMarks.init_qualify_ques_5_text != ''" class="form-group row score-slider-row">
                <label class="col-sm-3 col-form-label">
                    {{candidateMarks.init_qualify_ques_5_text}}
                    <input (change)="onChangeReadOnly($event, 'options5')" class="ml-2" type="checkbox"
                        formControlName="criteria_5_checked" />
                </label>
                <div class="col-sm-8">
                    <ngx-slider [(value)]="inputVal5" (userChangeEnd)="calculateOverallScore()" [options]="options5"
                        formControlName="criteria_5_marks"></ngx-slider>
                </div>
                <div class="col-sm-1 candidate-score">
                    <fa-icon *ngIf="!cheveron5Show"
                        [ngClass]="{'disabled': !ratingForm.controls['criteria_5_checked'].value}"
                        (click)="cheveron5Show = !cheveron5Show" class="chevron-icon"
                        [styles]="{'stroke': '#000', 'color': '#000'}" [icon]="faChevronDown" size="lg"></fa-icon>
                    <fa-icon *ngIf="cheveron5Show" (click)="cheveron5Show = !cheveron5Show" class="chevron-icon"
                        [styles]="{'stroke': '#000', 'color': '#000'}" [icon]="faChevronUp"
                        [ngClass]="{'disabled': !showSaveButton}" size="lg"></fa-icon>
                    <span class="scroll_point range-val"
                        *ngIf="!ratingForm.controls['criteria_5_checked'].value && userRole !='Hiring Member'">{{inputVal5
                        || 'N/A'}}</span>
                    <span class="scroll_point range-val"
                        *ngIf="ratingForm.controls['criteria_5_checked'].value && userRole !='Hiring Member'">{{inputVal5}}</span>
                </div>

                <div [hidden]="!cheveron5Show" class="col-sm-9 offset-sm-3 mt-3">
                    <div class="row">
                        <div class="col-sm-6">
                            <textarea readonly class="form-control d-none" placeholder="Suggested question"
                                formControlName="init_qualify_ques_suggestion_5"></textarea>
                            <div class="suggested-ques">
                                <ol>
                                    <li *ngFor="let question of suggestedQuestions[4]">{{question}}</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <textarea class="form-control" placeholder="Notes * (minimum 30 characters)"
                                formControlName="suggestion_5"></textarea>
                            <p class="alert p-0" style="color: red;"
                                *ngIf="ratingForm.controls['suggestion_5'].errors?.required">This field is mandatory</p>
                            <p class="alert p-0" style="color: red;"
                                *ngIf="ratingForm.controls['suggestion_5'].errors?.minlength">Please enter minimum 30
                                characters</p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="candidateMarks.init_qualify_ques_6_text != ''" class="form-group row score-slider-row">
                <label class="col-sm-3 col-form-label">
                    {{candidateMarks.init_qualify_ques_6_text}}
                    <input (change)="onChangeReadOnly($event, 'options6')" class="ml-2" type="checkbox"
                        formControlName="criteria_6_checked" />
                </label>
                <div class="col-sm-8">
                    <ngx-slider [(value)]="inputVal6" (userChangeEnd)="calculateOverallScore()" [options]="options6"
                        formControlName="criteria_6_marks"></ngx-slider>
                </div>
                <div class="col-sm-1 candidate-score">
                    <fa-icon *ngIf="!cheveron6Show"
                        [ngClass]="{'disabled': !ratingForm.controls['criteria_6_checked'].value}"
                        (click)="cheveron6Show = !cheveron6Show" class="chevron-icon"
                        [styles]="{'stroke': '#000', 'color': '#000'}" [icon]="faChevronDown" size="lg"></fa-icon>
                    <fa-icon *ngIf="cheveron6Show" (click)="cheveron6Show = !cheveron6Show" class="chevron-icon"
                        [styles]="{'stroke': '#000', 'color': '#000'}" [icon]="faChevronUp"
                        [ngClass]="{'disabled': !showSaveButton}" size="lg"></fa-icon>
                    <span class="scroll_point range-val"
                        *ngIf="!ratingForm.controls['criteria_6_checked'].value && userRole !='Hiring Member'">{{inputVal6
                        || 'N/A'}}</span>
                    <span class="scroll_point range-val"
                        *ngIf="ratingForm.controls['criteria_6_checked'].value && userRole !='Hiring Member'">{{inputVal6}}</span>
                </div>

                <div [hidden]="!cheveron6Show" class="col-sm-9 offset-sm-3 mt-3">
                    <div class="row">
                        <div class="col-sm-6">
                            <textarea readonly class="form-control d-none" placeholder="Suggested question"
                                formControlName="init_qualify_ques_suggestion_6"></textarea>
                            <div class="suggested-ques">
                                <ol>
                                    <li *ngFor="let question of suggestedQuestions[5]">{{question}}</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <textarea class="form-control" placeholder="Notes * (minimum 30 characters)"
                                formControlName="suggestion_6"></textarea>
                            <p class="alert p-0" style="color: red;"
                                *ngIf="ratingForm.controls['suggestion_6'].errors?.required">This field is mandatory</p>
                            <p class="alert p-0" style="color: red;"
                                *ngIf="ratingForm.controls['suggestion_6'].errors?.minlength">Please enter minimum 30
                                characters</p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="candidateMarks.init_qualify_ques_7_text != ''" class="form-group row score-slider-row">
                <label class="col-sm-3 col-form-label">
                    {{candidateMarks.init_qualify_ques_7_text}}
                    <input (change)="onChangeReadOnly($event, 'options7')" class="ml-2" type="checkbox"
                        formControlName="criteria_7_checked" />
                </label>
                <div class="col-sm-8">
                    <ngx-slider [(value)]="inputVal7" (userChangeEnd)="calculateOverallScore()" [options]="options7"
                        formControlName="criteria_7_marks"></ngx-slider>
                </div>
                <div class="col-sm-1 candidate-score">
                    <fa-icon *ngIf="!cheveron7Show"
                        [ngClass]="{'disabled': !ratingForm.controls['criteria_7_checked'].value}"
                        (click)="cheveron7Show = !cheveron7Show" class="chevron-icon"
                        [styles]="{'stroke': '#000', 'color': '#000'}" [icon]="faChevronDown" size="lg"></fa-icon>
                    <fa-icon *ngIf="cheveron7Show" (click)="cheveron7Show = !cheveron7Show" class="chevron-icon"
                        [styles]="{'stroke': '#000', 'color': '#000'}" [icon]="faChevronUp"
                        [ngClass]="{'disabled': !showSaveButton}" size="lg"></fa-icon>
                    <span class="scroll_point range-val"
                        *ngIf="!ratingForm.controls['criteria_7_checked'].value && userRole !='Hiring Member'">{{inputVal7
                        || 'N/A'}}</span>
                    <span class="scroll_point range-val"
                        *ngIf="ratingForm.controls['criteria_7_checked'].value && userRole !='Hiring Member'">{{inputVal7}}</span>
                </div>

                <div [hidden]="!cheveron7Show" class="col-sm-9 offset-sm-3 mt-3">
                    <div class="row">
                        <div class="col-sm-6">
                            <textarea readonly class="form-control d-none" placeholder="Suggested question"
                                formControlName="init_qualify_ques_suggestion_7"></textarea>
                            <div class="suggested-ques">
                                <ol>
                                    <li *ngFor="let question of suggestedQuestions[6]">{{question}}</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <textarea class="form-control" placeholder="Notes * (minimum 30 characters)"
                                formControlName="suggestion_7"></textarea>
                            <p class="alert p-0" style="color: red;"
                                *ngIf="ratingForm.controls['suggestion_7'].errors?.required">This field is mandatory</p>
                            <p class="alert p-0" style="color: red;"
                                *ngIf="ratingForm.controls['suggestion_7'].errors?.minlength">Please enter minimum 30
                                characters</p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="candidateMarks.init_qualify_ques_8_text != ''" class="form-group row score-slider-row">
                <label class="col-sm-3 col-form-label">
                    {{candidateMarks.init_qualify_ques_8_text}}
                    <input (change)="onChangeReadOnly($event, 'options8')" class="ml-2" type="checkbox"
                        formControlName="criteria_8_checked" />
                </label>
                <div class="col-sm-8">
                    <ngx-slider [(value)]="inputVal8" (userChangeEnd)="calculateOverallScore()" [options]="options8"
                        formControlName="criteria_8_marks"></ngx-slider>
                </div>
                <div class="col-sm-1 candidate-score">
                    <fa-icon *ngIf="!cheveron8Show"
                        [ngClass]="{'disabled': !ratingForm.controls['criteria_8_checked'].value}"
                        (click)="cheveron8Show = !cheveron8Show" class="chevron-icon"
                        [styles]="{'stroke': '#000', 'color': '#000'}" [icon]="faChevronDown" size="lg"></fa-icon>
                    <fa-icon *ngIf="cheveron8Show" (click)="cheveron8Show = !cheveron8Show" class="chevron-icon"
                        [styles]="{'stroke': '#000', 'color': '#000'}" [icon]="faChevronUp"
                        [ngClass]="{'disabled': !showSaveButton}" size="lg"></fa-icon>
                    <span class="scroll_point range-val"
                        *ngIf="!ratingForm.controls['criteria_8_checked'].value && userRole !='Hiring Member'">{{inputVal8
                        || 'N/A'}}</span>
                    <span class="scroll_point range-val"
                        *ngIf="ratingForm.controls['criteria_8_checked'].value && userRole !='Hiring Member'">{{inputVal8}}</span>
                </div>

                <div [hidden]="!cheveron8Show" class="col-sm-9 offset-sm-3 mt-3">
                    <div class="row">
                        <div class="col-sm-6">
                            <textarea readonly class="form-control d-none" placeholder="Suggested question"
                                formControlName="init_qualify_ques_suggestion_8"></textarea>
                            <div class="suggested-ques">
                                <ol>
                                    <li *ngFor="let question of suggestedQuestions[7]">{{question}}</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <textarea class="form-control" placeholder="Notes * (minimum 30 characters)"
                                formControlName="suggestion_8"></textarea>
                            <p class="alert p-0" style="color: red;"
                                *ngIf="ratingForm.controls['suggestion_8'].errors?.required">This field is mandatory</p>
                            <p class="alert p-0" style="color: red;"
                                *ngIf="ratingForm.controls['suggestion_8'].errors?.minlength">Please enter minimum 30
                                characters</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row score-slider-row">
                <label class="col-sm-3 col-form-label">Comments / Feedback</label>
                <div class="col-sm-9">
                    <textarea class="form-control" formControlName="feedback" [readonly]="!showSaveButton"></textarea>
                </div>
            </div>
            <div class="form-group row candidate-vote">
                <label for="input-password" class="col-sm-3 col-form-label">Vote for this candidate:<small>*</small>
                </label>
                <div class="col-sm-9">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label liked voteButton1" (click)="getSelectedRating('thumbs_up')"
                            [ngClass]="{'voted1': ratingForm.controls['thumbs_up'].value === true}">
                            <input for="candidate-like" class="ml-2 vote-icon" type="radio" name="candidate-vote"
                                value="thumbs_up">
                            <span id="candidate-like" class="like-icon">
                                <span class="marks-flag thumbs-up"
                                    [ngClass]="{'input-checked' :ratingForm.controls['thumbs_up'].value === true}">
                                    <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}" [icon]="faThumbup" size="xs"
                                        [ngClass]="{'voted2': ratingForm.controls['thumbs_up'].value === true}"></fa-icon>
                                </span>
                            </span>
                            <span>Candidate has potential</span>
                        </label>
                        <label class="form-check-label ml-3 passed  voteButton2"
                            (click)="getSelectedRating('thumbs_down')"
                            [ngClass]="{'votedd': ratingForm.controls['thumbs_down'].value === true}">
                            <input for="candidate-pass" class="ml-2 vote-icon" type="radio" name="candidate-vote"
                                value="thumbs_down">
                            <span id="candidate-pass" class="pass-icon">
                                <span class="marks-flag thumbs-down"
                                    [ngClass]="{'input-checked' :ratingForm.controls['thumbs_down'].value === true}">
                                    <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}" [icon]="faThumbdown"
                                        size="xs"
                                        [ngClass]="{'voted3': ratingForm.controls['thumbs_down'].value === true}"></fa-icon>
                                </span>
                            </span>
                            <span>Candidate is not suitable</span>
                        </label>
                        <label class="form-check-label ml-3 liked golden  voteButton3"
                            (click)="getSelectedRating('golden_gloves')"
                            [ngClass]="{'votedg': ratingForm.controls['golden_gloves'].value === true}">
                            <input for="candidate-pass" class="ml-2 vote-icon" type="radio" name="candidate-vote"
                                value="golden_gloves">
                            <span id="candidate-pass" class="golden-icon">
                                <span class="marks-flag thumbs-up"
                                    [ngClass]="{'input-checked' :ratingForm.controls['golden_gloves'].value === true}">
                                    <fa-icon [styles]="{'stroke': '#fff', 'color': '#ffd700', 'font-size': '35px'}"
                                        [icon]="faThumbup" size="xs"
                                        [ngClass]="{'voted4': ratingForm.controls['golden_gloves'].value === true}"></fa-icon>
                                </span>
                            </span>
                            <span>Award the golden glove</span>
                        </label>
                    </div>
                    <!-- <div class="form-check form-check-inline">
                        <input class="ml-2" (change)="getSelectedRating($event)" type="radio" name="candidate-vote" value="hold" [checked]="ratingForm.controls['hold'].value === true">
                        <label class="form-check-label" for="candidate-hold"><span class="like-icon">
                            <span class="marks-flag hold">
                                <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}" [icon]="faThumbup" size="xs"></fa-icon>
                            </span>
                        </span> Hold</label>
                    </div> -->
                    <!-- <div class="form-check form-check-inline">
                        
                        
                    </div> -->
                    <p class="alert" style="color: red;"
                        *ngIf="submit && !ratingForm.controls['thumbs_down'].value && !ratingForm.controls['thumbs_up'].value">
                        This field is mandatory</p>
                </div>
            </div>
            <!-- <a class="savebtn btn" *ngIf="showSaveButton" (click)="saveRating()" [ngClass]="{'disabled': ratingForm.invalid  || isVoteInvalid()}">Save</a> -->
            <button type="button" *ngIf="showSaveButton" class="btn btn-primary float-right mb-3" (click)="saveRating()"
                [ngClass]="{'disabled': ratingForm.invalid  || isVoteInvalid() || compulsaryMarksGiven()}">Save</button>
            <button type="button" class="btn btn-secondary float-right mb-3 mr-2" (click)="goBack()">Back</button>
        </form>
    </div>
</div>


<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">QVideo Meeting</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <!-- <iframe id="zohoMeeting" width="450" height="450" [src]="meetingUrl | safe"></iframe> -->
        <iframe *ngIf="meetingUrl" allow="camera;microphone;fullscreen;autoplay" style="width: 100%;height: 600px;"
            [src]="meetingUrl | safe"></iframe>
    </div>
    <!-- <div class="modal-footer">
		<button type="button" class="btn btn-outline-dark" (click)="modal.close('Leave click')">Leave</button>
	</div> -->
</ng-template>

<app-modal #modalRoot class="modal-demo" [backdrop]="false" [maximizable]="true">
    <ng-container class="app-modal-header">QVideo Meeting</ng-container>
    <ng-container class="app-modal-body">
        <iframe *ngIf="meetingUrl" allow="camera;microphone;fullscreen;autoplay" style="width: 100%;height: 600px;"
            [src]="meetingUrl | safe"></iframe>
    </ng-container>

</app-modal>