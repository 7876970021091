<div class="pageMainSection">
    <!-- <app-navbar></app-navbar> -->
    <!-- <nav class="page-sidebar" style="height: 100%;" *ngIf="userRole === 'Superuser' || userRole === 'Account Manager'">
        <div class="leftActionBar">
            <span><strong>Clients</strong></span>
            <a class="searchIcon" href="javascript:void(0)" (click)="showSearch()">
                <fa-icon [icon]="faSearch"></fa-icon>
            </a>
            <a *ngIf="userRole =='Superuser'" class="addLink" [routerLink]="currentUserRoleCode + '/dashboard/addClient'">
                <fa-icon [icon]="faPlus"></fa-icon>
            </a>
        </div>
        <div class="leftActionBar" style="padding: 10px;" *ngIf="isshowSearch">
            <input type="text" style="margin-bottom: 0!important;" class="form-control mb-4" placeholder="Search" [(ngModel)]="query" id="listSearch">
        </div>
        <div class="page-sidebar-wrapper">
            <ul class="nav nav-sidebar" *ngIf="clientList.length > 0">
                <li *ngFor="let item of clientList | searchFilter: query:'company_name'">
                    <a href="javascript:void(0)" (click)="select(item)" [ngClass]="{active: isActive(item)}">
                        {{item.company_name}} 
                    </a>
                    <fa-icon *ngIf="userRole =='Superuser'" [icon]="faEdit" (click)="editClient(item.id)" style="right:5px; top:12px;"></fa-icon>
                    <fa-icon *ngIf="userRole =='Superuser'" (click)="openSm(content, item.id)" [icon]="faDelete" style="right:35px; top:12px;"></fa-icon>
                </li>
            </ul>
        </div>
    </nav>

    <nav class="page-sidebar" style="height: 100%;" *ngIf="userRole !== 'Superuser' && userRole !== 'Account Manager'">
        <div class="leftActionBar" [ngClass]="{'p-2': userRole === 'Hiring Member'}">
            <span><strong>{{userRole === 'Hiring Member' ? 'Positions to vote on' : 'Open Positions'}}</strong></span>
            <a class="searchIcon" href="javascript:void(0)" (click)="showSearch()">
                <fa-icon [icon]="faSearch"></fa-icon>
            </a>
            <a *ngIf="(userRole =='Client Admin')" class="addLink ml-2" href="javascript:void(0)" (click)="createPosition($event)">
                <fa-icon [icon]="faPlus"></fa-icon>
            </a>
        </div>
        <div class="leftActionBar" style="padding: 10px;" *ngIf="isshowSearch">
            <input type="text" style="margin-bottom: 0!important;" class="form-control mb-4" placeholder="Search" [(ngModel)]="query" id="listSearch">
        </div>
        <div class="page-sidebar-wrapper" style="padding: 0.5rem;height: 100%;">
            <ngb-accordion [destroyOnHide]="false" activeIds="openPositions">
                <ngb-panel id="openPositions">
                    <ng-template ngbPanelTitle>
                        <span><strong>{{userRole === 'Hiring Member' ? 'Positions to vote on' : 'Open Positions'}}</strong></span>
                        <a class="searchIcon pl-2" href="javascript:void(0)" (click)="showSearch()">
                            <fa-icon [icon]="faSearch"></fa-icon>
                        </a>
                        <a *ngIf="(userRole =='Superuser') || (userRole =='Account Manager')" class="addLink ml-2" href="javascript:void(0)" (click)="createPosition($event)">
                            <fa-icon [icon]="faPlus"></fa-icon>
                        </a>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <ul class="nav nav-sidebar" *ngIf="positionList">
                            <li *ngFor="let item of positionList[0] | searchFilter: query : 'position_title' ; let i = index" [ngClass]="{active: isActive(item)}">
                                <a href="javascript:void(0)" (click)="select(item)">
                                    {{item.position_title}}
                                    <button type="button" class="menu-button-cntr" tooltipClass="custom-tooltip" [ngbTooltip]="openPositionmenuButtons" triggers="click:blur" aria-haspopup="true" aria-expanded="false" aria-label="Menu" aria-disabled="false">
                                        <svg viewBox="0 0 20 20" fill="currentColor" width="28" height="28" role="img" aria-hidden="true"><path d="M6 10.5C6 11.3284 5.32843 12 4.5 12 3.67157 12 3 11.3284 3 10.5 3 9.67157 3.67157 9 4.5 9 5.32843 9 6 9.67157 6 10.5zM11.8333 10.5C11.8333 11.3284 11.1618 12 10.3333 12 9.50492 12 8.83334 11.3284 8.83334 10.5 8.83334 9.67157 9.50492 9 10.3333 9 11.1618 9 11.8333 9.67157 11.8333 10.5zM17.6667 10.5C17.6667 11.3284 16.9951 12 16.1667 12 15.3383 12 14.6667 11.3284 14.6667 10.5 14.6667 9.67157 15.3383 9 16.1667 9 16.9951 9 17.6667 9.67157 17.6667 10.5z" fill="currentColor"></path></svg>
                                    </button>
                                    <ng-template #openPositionmenuButtons>
                                        <fa-icon title="Clone" *ngIf="(userRole =='Superuser') || (userRole =='Account Manager') || (userRole =='Client Admin')" (click)="duplicatePosition(item.id)" [icon]="faClone"></fa-icon>
                                        <fa-icon title="Trash" *ngIf="(userRole =='Superuser') || (userRole =='Account Manager') || (userRole =='Client Admin')" (click)="openSm(content, item.id)" [icon]="faDelete"></fa-icon>
                                        <fa-icon title="Edit" *ngIf="(userRole =='Superuser') || (userRole =='Account Manager') || (userRole =='Client Admin')" [icon]="faEdit" (click)="editPosition(item.id, item.hiring_group)"></fa-icon>
                                    </ng-template>
                                </a>
                            </li>
                        </ul>
                        <p *ngIf="positionList[0] && positionList[0].length < 1">
                            No position available!
                        </p>
                    </ng-template>
                </ngb-panel>

                <ngb-panel id="draftPosition">
                    <ng-template ngbPanelTitle>
                        <span><strong class="mt-4">Draft Positions</strong></span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <ul class="nav nav-sidebar" *ngIf="draftedPositionList?.length">
                            <li *ngFor="let item of draftedPositionList | searchFilter: query : 'position_title' ; let i = index">
                                <a href="javascript:void(0)">
                                    {{item.position_title}}
                                    <button type="button" class="menu-button-cntr" tooltipClass="custom-tooltip" [ngbTooltip]="draftPositionmenuButtons" triggers="click:blur" aria-haspopup="true" aria-expanded="false" aria-label="Menu" aria-disabled="false">
                                        <svg viewBox="0 0 20 20" fill="currentColor" width="28" height="28" role="img" aria-hidden="true"><path d="M6 10.5C6 11.3284 5.32843 12 4.5 12 3.67157 12 3 11.3284 3 10.5 3 9.67157 3.67157 9 4.5 9 5.32843 9 6 9.67157 6 10.5zM11.8333 10.5C11.8333 11.3284 11.1618 12 10.3333 12 9.50492 12 8.83334 11.3284 8.83334 10.5 8.83334 9.67157 9.50492 9 10.3333 9 11.1618 9 11.8333 9.67157 11.8333 10.5zM17.6667 10.5C17.6667 11.3284 16.9951 12 16.1667 12 15.3383 12 14.6667 11.3284 14.6667 10.5 14.6667 9.67157 15.3383 9 16.1667 9 16.9951 9 17.6667 9.67157 17.6667 10.5z" fill="currentColor"></path></svg>
                                    </button>
                                    <ng-template #draftPositionmenuButtons>
                                        <fa-icon title="Trash" *ngIf="(userRole =='Superuser') || (userRole =='Account Manager') || (userRole =='Client Admin')" (click)="openSm(content, item.id)" [icon]="faDelete"></fa-icon>
                                        <fa-icon title="Edit" class="edit-position" *ngIf="(userRole =='Superuser') || (userRole =='Account Manager') || (userRole =='Client Admin')" [icon]="faEdit" (click)="editPosition(item.id, item.hiring_group)"></fa-icon>
                                    </ng-template>
                                </a>
                            </li>
                        </ul>
                        <p *ngIf="draftedPositionList?.length < 1">
                            No draft position available!
                        </p>
                    </ng-template>
                </ngb-panel>

                <ngb-panel id="archivedPosition">
                    <ng-template ngbPanelTitle>
                        <span><strong class="mt-4">Archived Positions</strong></span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <ul class="nav nav-sidebar" *ngIf="archivedPositionList?.length">
                            <li *ngFor="let item of archivedPositionList | searchFilter: query : 'position_title' ; let i = index">
                                <a href="javascript:void(0)">
                                    {{item.position_title}}
                                    <button type="button" class="menu-button-cntr" tooltipClass="custom-tooltip" [ngbTooltip]="draftPositionmenuButtons" triggers="click:blur" aria-haspopup="true" aria-expanded="false" aria-label="Menu" aria-disabled="false">
                                        <svg viewBox="0 0 20 20" fill="currentColor" width="28" height="28" role="img" aria-hidden="true"><path d="M6 10.5C6 11.3284 5.32843 12 4.5 12 3.67157 12 3 11.3284 3 10.5 3 9.67157 3.67157 9 4.5 9 5.32843 9 6 9.67157 6 10.5zM11.8333 10.5C11.8333 11.3284 11.1618 12 10.3333 12 9.50492 12 8.83334 11.3284 8.83334 10.5 8.83334 9.67157 9.50492 9 10.3333 9 11.1618 9 11.8333 9.67157 11.8333 10.5zM17.6667 10.5C17.6667 11.3284 16.9951 12 16.1667 12 15.3383 12 14.6667 11.3284 14.6667 10.5 14.6667 9.67157 15.3383 9 16.1667 9 16.9951 9 17.6667 9.67157 17.6667 10.5z" fill="currentColor"></path></svg>
                                    </button>
                                    <ng-template #draftPositionmenuButtons>
                                        <fa-icon title="Edit" class="edit-position" *ngIf="(userRole =='Superuser') || (userRole =='Account Manager') || (userRole =='Client Admin')" [icon]="faEdit" (click)="editPosition(item.id, item.hiring_group)"></fa-icon>
                                        <fa-icon title="Restore" *ngIf="(userRole =='Superuser') || (userRole =='Account Manager') || (userRole =='Client Admin')" (click)="restoreOpenPosition(item.id)" [icon]="faTrashRestore"></fa-icon>
                                    </ng-template>
                                </a>
                            </li>
                        </ul>
                        <p *ngIf="archivedPositionList?.length < 1">
                            No archived position available!
                        </p>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <div class="pro-tip card mt-5" style="padding: 16px;background: #eee;">
                <h4><span class="tube-icon"><fa-icon style="font-size: 30px;color: var(--primary);" [icon]="faLightbulb"></fa-icon></span><span>Pro Tip</span></h4>
                <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
            </div>
        </div>
    </nav> -->
    <!-- //SIDEBAR COLUMN -->

    <main id="main-content">

        <app-header style="z-index: 99;position: relative;  "></app-header>

        <div class="widget-wrapper">
            <router-outlet></router-outlet>
        </div>

    </main>
    <!-- //MAIN COLUMN -->

</div>


<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Delete Client</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Are you want to delete client?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="deleteClient()">Yes</button>
    </div>
</ng-template>