import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import {
  faBars,
  faHamburger,
  faTimes,
  faBell,
  faTrash,
  faCog,
  faThLarge,
  faSearchPlus,
  faClock,
  faCalendar,
  faHandsHelping,
  faCalendarAlt,
  faDollarSign,
  faHandPaper,
  faPeopleArrows,
  faIdCard,
  faThumbsUp,
  faThumbsDown,
  faPlug,
  faUsers,
  faShieldAlt,
  faExclamationCircle,
  faExclamation,
  faComments,
  faInfoCircle,
  faRocket,
  faCommentAlt,
  faHandshake,
  faMoon,
  faQuestionCircle,
  faQuestion,
  faUserPlus,
  faUserCog,
  faTachometerAlt,
  faUserTie,
  faPeopleCarry,
  faCalendarPlus,
  faRandom,
  faListUl,
  faEnvelope,
  faPhone
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SetAvailabilityComponent } from 'src/app/features/set-availability/set-availability.component';
import { UserService } from 'src/app/services/user.service';
import { WebSocketService } from 'src/app/services/websocket.service';
import { ToastService } from '../toast/toast-service';
import * as Url from './../../constants/url';
import { AuthService } from './../../services/auth.services';
import { NavigationService } from 'src/app/services/navigation.service';
import { Subscription } from 'rxjs';
import { NavigatorComponent } from '../navigator/navigator.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @ViewChild(NavigatorComponent) navigatorComponent!: NavigatorComponent;
  public userData: any = {};
  faLinkedin = faLinkedin;
  faEnvelope = faEnvelope;
  faPhone = faPhone;
  faUserPlus = faUserPlus;
  faHamburger = faHamburger;
  faBars = faBars;
  faCalendarAlt = faCalendarAlt;
  faTime = faTimes;
  faBell = faBell;
  faListUl = faListUl;
  faTrash = faTrash;
  faUserTie = faUserTie;
  faPeopleCarry = faPeopleCarry;
  faCalendarPlus = faCalendarPlus;
  faRandom = faRandom;
  status: boolean = false;
  isUserLogedin = false;
  currentUserRoleCode: any;
  allNotifications: any;
  isNewNotification = false;
  faCog = faCog;
  faThLarge = faThLarge;
  faUserCog = faUserCog;
  faUsers = faUsers;
  userRole = '';
  faPlug = faPlug;
  faTachometerAlt = faTachometerAlt;
  userAnalytics: any;
  profileImage: any;
  last_name: any;
  first_name: any;
  role: any;
  seeAllConnectors = true;
  faQuestionCircle = faQuestionCircle;
  faQuestion = faQuestion;
  @ViewChild("helpIframe") helpIframe!: TemplateRef<any>;
  isLoading = true;
  atsConnectors = [
    {
      label: 'ADP',
      url: './../../../assets/images/ATS_logos/adp.jpeg',
      href: 'https://help.merge.dev/en/articles/5944400-how-do-i-set-up-adp-workforce-now-partner-credentials'
    },
    {
      label: 'BambooHR',
      url: './../../../assets/images/ATS_logos/bamboo.png',
      href: 'https://www.workato.com/integrations/bamboohr?utm_source=cpc&utm_medium=adwords&utm_campaign=bamboohr&utm_content=bamboohr%20integration&utm_term=bamboohr%20integration&matchtype=p&device=c&_bt=578287545093&_bk=bamboohr%20integration&_bm=p&_bn=g&_bg=87738041642&ref=trial&gclid=Cj0KCQiAqOucBhDrARIsAPCQL1bLCV84G3JnM5u5daVAp-Hip9-O4Ubjt6leEBQLIjGJzq9IR0jrzFEaAiN1EALw_wcB'
    },
    {
      label: 'BreezyHR',
      url: './../../../assets/images/ATS_logos/breezy.png',
      href: 'https://qorums.com/breezyhr-api-instructions/'
    },
    {
      label: 'Bullhorn',
      url: './../../../assets/images/ATS_logos/bullhorn.png',
      href: 'https://supportforums.bullhorn.com/viewtopic.php?t=34553'
    },
    {
      label: 'CATS',
      url: './../../../assets/images/ATS_logos/cats.png',
      href: 'https://zapier.com/apps/cats/integrations/zapier'
    },
    {
      label: 'Covenia',
      url: './../../../assets/images/ATS_logos/covenia.png',
      href: 'https://zapier.com/apps/convenia/integrations/code'
    },
    {
      label: 'Crelate',
      url: './../../../assets/images/ATS_logos/crelate.png',
      href: 'https://zapier.com/apps/crelate/integrations/webhook'
    }, {
      label: 'Greenhouse',
      url: './../../../assets/images/ATS_logos/greenhouse.png',
      href: 'https://support.greenhouse.io/hc/en-us/articles/360020089431-Zapier-integration#:~:text=When%20you%20start%20creating%20a,account%20will%20be%20successfully%20connected.'
    },
    {
      label: 'Hibob',
      url: './../../../assets/images/ATS_logos/hibob.png',
      href: 'https://help.hibob.com/hc/en-us/articles/4409792611601-Connecting-Bob-to-Zapier'
    },
    {
      label: 'iCIMS',
      url: './../../../assets/images/ATS_logos/icims.png',
      href: 'https://help.hackerearth.com/hc/en-us/articles/360004722753-Generating-your-iCIMS-API-key-and-API-secret'
    },
    {
      label: 'JobAdder',
      url: './../../../assets/images/ATS_logos/jobadder.png',
      href: 'https://qorums.com/api-instructions-for-jobadder/'
    },
    {
      label: 'Jobvite',
      url: './../../../assets/images/ATS_logos/jobvite.png',
      href: 'https://help.hireez.com/hc/en-us/articles/4406931348123-How-to-Integrate-with-Jobvite'
    },
    {
      label: 'Lever',
      url: './../../../assets/images/ATS_logos/lever.png',
      href: 'https://help.lever.co/hc/en-us/articles/360042364412-Generating-and-using-API-credentials'
    },
    {
      label: 'Recruitee',
      url: './../../../assets/images/ATS_logos/recruitee.png',
      href: 'https://support.recruitee.com/en/articles/1066282-api-documentation'
    },
    {
      label: 'SAP',
      url: './../../../assets/images/ATS_logos/SAP.png',
      href: 'https://blogs.sap.com/2020/08/11/successfactors-integrations-beginners-guide-api-user-creation-and-connectivity/#:~:text=Step%201%3A%20Add%20Timer%20Event,identified%20using%20SAP%20HELP%20Documentation'
    },
    {
      label: 'Taleo',
      url: './../../../assets/images/ATS_logos/taleo.png',
      href: 'https://tbe.taleo.net//products/TBE_API_Guide.pdf'
    },
    {
      label: 'Wizehire',
      url: './../../../assets/images/ATS_logos/wizehire.png',
      href: 'https://zapier.com/apps/wizehire/integrations/code'
    },
    {
      label: 'Workable',
      url: './../../../assets/images/ATS_logos/workable.png',
      href: 'https://help.workable.com/hc/en-us/articles/115015785428-How-do-I-generate-or-revoke-an-API-key-access-token-'
    },
    {
      label: 'Workday',
      url: './../../../assets/images/ATS_logos/workday.png',
      href: 'https://developer.workday.com/about'
    },
    {
      label: 'Zoho Recruit',
      url: './../../../assets/images/ATS_logos/zoho_recruit.png',
      href: 'https://qorums.com/zoho-recruit-api-instructions/'
    }
  ]
  selectedConnector: any;
  faComments = faComments; currentUserData: any;
  protipData: any;
  homeUrl: string = '';
  faInfoCircle = faInfoCircle;
  faRocket = faRocket;
  faCommentAlt = faCommentAlt;
  faHandsHelping = faHandsHelping;
  faHandshake = faHandshake;
  faMoon = faMoon;
  isDarkMode = false;
  selectedIcon: string | null = null;

  homeUrlTest: string = '';
  private homeUrlSubscription: Subscription;
  isNavigator: boolean = true;
  timeoutId: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private toastService: ToastService,
    private websocketService: WebSocketService,
    private modalService: NgbModal,
    private navigationService: NavigationService
  ) {

  }

  ngOnInit(): void {
    let currUser = localStorage.getItem('currentUser');
    if (!currUser) return
    let data = JSON.parse(currUser);
    this.isDarkMode = data.dark_mode; 
    let nav = localStorage.getItem('isNavigator');
    this.isNavigator = (nav === 'true')
    this.currentUserData = data;
    this.getUserDetails();
    this.userService.getuserDetailsUpdated().subscribe((res: any) => {
      console.log(res)
      this.getUserDetails();
    });
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    this.userRole = this.currentUserRoleCode.split('/')[1];
    this.homeUrl = this.router.url;
    var x = this.homeUrl.includes('sa/dashboard')
    // if(y.includes("thankyou"))
    this.authService.getUserLogin().subscribe(val => {
      if (val) {
        localStorage.setItem('isUserLogedin', JSON.stringify(val));
        this.isUserLogedin = val;
      } else {
        let userLogedin = localStorage.getItem('isUserLogedin');
        if (userLogedin == 'true') {
          this.isUserLogedin = JSON.parse(userLogedin);
        }
      }
    });
    if (localStorage.getItem('isUserLogedin') == 'true') {
      this.showNotifications(true);
    }
    this.websocketService.getWebsocketNotifications().subscribe((res: any) => {
      this.isNewNotification = true;
    });
    this.userService.getproTipUpdated().subscribe((res: any) => {
      setTimeout(() => {
        this.setProTipData(res);
      }, 1000);
    });
    this.router.events.subscribe((event: any) => {
      // console.log(event);
      if (event instanceof NavigationEnd) {
        this.setProTipData();
      }
    });

    this.homeUrlSubscription = this.navigationService.getHomeUrl().subscribe(url => {
      this.homeUrlTest = url;
    });

  }
  ngOnDestroy() {
    if (this.homeUrlSubscription) {
      this.homeUrlSubscription.unsubscribe();
    }
  }

  selectIcon(iconName: string): void {
    this.selectedIcon = iconName;
  }

  setProTipData(data?: any) {
    if (!data) {
      let d:any = localStorage.getItem('protip')
      data = JSON.parse(d);
    }
    const currentRoute: any = this.router.url;
    const protipData = 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.';
    switch (true) {
      case (currentRoute.indexOf('settings') !== -1):
        this.protipData = data.settings_protip || protipData;
        break;
      // case (currentRoute.indexOf('candidateHome/advanceSearch') !== -1):
      //   this.protipData = data.candidate_adv_search_protip || protipData;
      //   break;
      // case (currentRoute.indexOf('candidate-dashboard') !== -1):
      //   this.protipData = data.candidate_protip || protipData;
      //   break;
      // case (currentRoute.indexOf('dashboard/hiringDepartment') !== -1):
      //   this.protipData = data.hiring_teams_protip || protipData;
      //   break;
      case (currentRoute.indexOf('htm-dashboard') !== -1):
        this.protipData = data?.team_members_protip || protipData;
        break;
      // case (currentRoute.indexOf('dashboard/createAccount') !== -1):
      //   this.protipData = data.senior_manager_protip || protipData;
      //   break;
      case (currentRoute.indexOf('dashboard/addClient') !== -1):
        this.protipData = data.client_protip || protipData;
        break;
      case (currentRoute.indexOf('/new-position') !== -1):
        this.protipData = data.new_position_protip || protipData;
        break;
      case (currentRoute.indexOf('position/clientPosition') !== -1):
        this.protipData = data?.position_protip || protipData;
        break;
      case (currentRoute.indexOf('candidate-dashboard') !== -1):
        this.protipData = data.candidate_dash_protip || protipData;
        break;
      case (currentRoute.indexOf('/dashboard/details') !== -1):
        this.protipData = data.qorums_overview || protipData;
        break;
      case (currentRoute.indexOf('/view-all-users') !== -1):
        this.protipData = data.managing_users || protipData;
        break;
      case (currentRoute.indexOf('/position-details/add-new-candidate') !== -1):
        this.protipData = data.managing_candidates || protipData;
        break;
      case (currentRoute.indexOf('/position-details') !== -1):
        this.protipData = data.hiring_process || protipData;
        break;
      case (currentRoute.indexOf('/view-all-positions') !== -1):
        this.protipData = data.managing_positions || protipData;
        break;
      default:
        this.protipData = protipData;
        break;
    }
  }

  getUserDetails() {
    let data = localStorage.getItem('currentUser');
    if (data) {
      this.userData = JSON.parse(data);

      this.profileImage = !this.userData.profile_photo || this.userData.profile_photo != 'None' ? Url.imgBaseUrl + this.userData.profile_photo : '';
      this.first_name = this.userData.first_name;
      this.last_name = this.userData.last_name;
      this.role = this.userData.role;
      if (this.role === 'Hiring Member') {
        this.role = 'Hiring Team Member';
      }
    }
  }

  showMenu() {
    this.status = !this.status;
  }
  logOut() {
    this.authService.setUserLogin(false);
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  showNotifications(isAfterLogin?: any) {
    this.userService.getAppNotifications(this.userData.username).subscribe((res: any) => {
      if (!isAfterLogin) {
        this.allNotifications = res?.notifications;
        this.isNewNotification = false;
      } else {
        this.isNewNotification = res?.new_notification;
      }
    });
  }

  deleteNotification(id: any) {
    const notifications = { notifications: [id] };
    this.userService.deleteAppNotifications(this.userData.username, notifications).subscribe((res: any) => {
      this.showSuccess('Notification deleted successfully!');
      this.allNotifications = this.allNotifications?.filter((el: any) => el.id !== id);
    });
  }

  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

  navigateToSettings() {
    this.showMenu()
    this.router.navigate([this.currentUserRoleCode + '/dashboard/settings']);
  }
  switchNavToggle() { 
    this.isNavigator = !this.isNavigator;
    const obj = {
      'navigator': this.isNavigator
    }
    this.userService.updateNavMode(obj).subscribe(res => {
      this.authService.setNavMode(this.isNavigator)
    })
    if(this.isNavigator){
      this.openNavigator()
    }
  }
  openNavigator(){
    if(this.isNavigator)
      {
        this.navigatorComponent.closeNavigatorModal();
        this.navigatorComponent.getNavigatorList();
        this.navigatorComponent.getNavigatorStep();
        this.navigatorComponent.openNavigatorModal();
      }
  }
  reloadPage() {
    this.router.navigate([this.currentUserRoleCode + '/dashboard/candidate-dashboard']);
    setTimeout(() => {
      window.location.reload()
    }, 50);
    // window.location.reload()
  }

  pageReload() {
    window.location.reload()
  }
  getUserAnalytics() {
    this.userService.getUserAnalytics().subscribe((res: any) => {
      this.userAnalytics = res;
      this.userService.setUserAnalyticsData(res);
    });
  }

  toggleSelection(e: any, menu: any) {

    this.userAnalytics.filter((el: any) => el.label === menu.label)[0].isSelected = !menu.isSelected;
    this.saveAnalyticsData();
  }

  saveAnalyticsData() {
    this.userService.saveAnalyticsData(this.userAnalytics).subscribe((res: any) => {
      this.getUserAnalytics();
      this.showSuccess('Dashboard saved successfully!');
    });
  }

  getCssIcon(iconName: any) {
    switch (iconName) {
      case 'open-position-data':
        return faCalendarAlt;
      case 'liked-candidates':
        return faThumbsUp;
      case 'passed-candidates':
        return faThumbsDown;
      case 'interview-not-scheduled':
        return faExclamation;
      case 'interview-scheduled':
        return faCalendarAlt;
      case 'offers-accepted':
        return faHandsHelping;
      case 'offer-pending':
        return faDollarSign;
      case 'position-stalled':
        return faHandPaper;
      case 'canddidates-in-process':
        return faPeopleArrows;
      case 'top-clients-with-hires':
        return faShieldAlt;
      case 'active-clients':
        return faShieldAlt;
      case 'total-hires-this-quater':
        return faIdCard;
      case 'top-clients-with-open-position':
        return faShieldAlt;
      case 'total-candidates':
        return faUsers;
      default:
        return faClock;
    }
  }

  openConnector(con: any) {
    this.seeAllConnectors = false;
    this.selectedConnector = con;
  }

  toggleDarkMode(e: any) {
    this.isDarkMode = !this.isDarkMode;
    const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
    const profileId = currentUser?.profile_id;
    this.authService.setIsDarkMode(this.isDarkMode);
    this.userService.setDarkMode(profileId, this.isDarkMode).subscribe((res: any) => {
      currentUser.dark_mode = this.isDarkMode;
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
    });
  }

  iframeLoaded() {
    setTimeout(() => {
      console.log('Iframe loaded');
      this.isLoading = false;
    }, 1000);
  }

  openSetAvailability() {
    const modalRef = this.modalService.open(SetAvailabilityComponent, { size: 'lg' });
    modalRef.componentInstance.name = 'scheduler';
  }
  openMyCalendar() {
    this.router.navigate([this.currentUserRoleCode + '/my-calender']);
  }
  helpIconForCandidate() {
    window.open('https://www.youtube.com/watch?v=9K58qJbRJVc', "_blank")
  }
}
