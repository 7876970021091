import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EmailEditorComponent } from 'angular-email-editor';
@Component({
  selector: 'app-email-editor-one',
  templateUrl: './email-editor-one.component.html',
  styleUrls: ['./email-editor-one.component.css']
})
export class EmailEditorOneComponent implements OnInit {
  @ViewChild(EmailEditorComponent)
  private emailEditor: EmailEditorComponent;
  @Input() getEmailTemplate
  @Output() emailAction = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    
  }

 
  editorLoaded(){
    // this.emailEditor.editor.loadDesign({
    //   features: {
    //     branding: {
    //       visible: false
    //     }
    //   }
    // });
    const json = this.getEmailTemplate.design
    this.emailEditor.loadDesign(json)
  }
  exportHtml() {
    let obj:any=this.getEmailTemplate;
    // obj.name=type;
    this.emailEditor.saveDesign((design) => {
      console.log('saveDesign', design);
      obj.design = design;
      this.emailEditor.exportHtml((data:any) =>{
        console.log('exportHtml', data)
        obj.data= data;
        this.emailAction.emit(obj)
      }
      );
    })
  }


}
