import { Component, TemplateRef } from '@angular/core';
import { faSmile, faSadCry } from '@fortawesome/free-regular-svg-icons';

import { ToastService } from './toast-service';
import { faRepeat } from '@fortawesome/pro-solid-svg-icons';


@Component({
  selector: 'app-toasts',
  template: `
    <ngb-toast
      *ngFor="let toast of toastService.toasts"
      [class]="toast.classname"
      [autohide]="true"
      [delay]="toast.delay || 5000"
      (hidden)="toastService.remove(toast)"
    >
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

     <ng-template #text >
     <div *ngIf="toast.classname.includes('bg-success')" class="d-flex" style="align-items: center;">
        <div>
          <fa-icon [icon]="faSmile"
              [styles]="{'stroke': '#01AC77', 'color': '#01AC77', 'font-size': '80px'}">
          </fa-icon>
        </div>
        <div class="pl-4 " style="color:'#6B6D6C'">
          <div style="color:'#01AC77'"> Success! </div>
          {{ toast.textOrTpl }}
        </div>
      </div>

      <div *ngIf="toast.classname.includes('bg-warning')" class="d-flex" style="align-items: center;">
        <div>
          <fa-icon [icon]="faRepeat"
              [styles]="{'stroke': '#F5A623', 'color': '#F5A623', 'font-size': '80px'}">
          </fa-icon>
        </div>
        <div class="pl-4 " style="color:'#6B6D6C'">
          <div style="color:'#F5A623'"> Warning! </div>
          {{ toast.textOrTpl }}
        </div>
      </div>

      <div *ngIf="toast.classname.includes('bg-danger')" class="d-flex" style="align-items: center;">
        <div>
          <fa-icon [icon]="faSadCry"
              [styles]="{'stroke': '#FE1313', 'color': '#FE1313', 'font-size': '80px'}">
          </fa-icon>
        </div>
        <div class="pl-4 " style="color:'#6B6D6C'">
          <div style="color:'#FE1313'"> Error! </div>
          {{ toast.textOrTpl }}
        </div>
      </div>

     </ng-template>
    </ngb-toast>
  `,
  host: { 'class': 'toast-container position-fixed  rounded', 'style': 'z-index: 1200; top : 40% ; left : 40%' },
  styleUrls: ['toast.css']
})
export class ToastsContainer {
  faSmile = faSmile;
  faSadCry = faSadCry;
  faRepeat = faRepeat;
  constructor(public toastService: ToastService) { }

  isTemplate(toast: any) { return toast.textOrTpl instanceof TemplateRef; }
}
