<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/pixeden-stroke-7-icon@1.2.3/pe-icon-7-stroke/dist/pe-icon-7-stroke.min.css">
<div class="container padding-bottom-3x mb-1">
    <div class="card mb-3" style="border: none;">
        <!-- <div class="p-4 text-center text-white text-lg bg-dark rounded-top"><span class="text-uppercase">Tracking Order No - </span><span class="text-medium">34VB5540K83</span></div>
        <div class="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2 bg-secondary">
        <div class="w-100 text-center py-1 px-2"><span class="text-medium">Shipped Via:</span> UPS Ground</div>
        <div class="w-100 text-center py-1 px-2"><span class="text-medium">Status:</span> Checking Quality</div>
        <div class="w-100 text-center py-1 px-2"><span class="text-medium">Expected Date:</span> SEP 09, 2017</div>
        </div> -->
        <div class="card-body">
            <div *ngIf="stepName === 'fourth'" class="progress-label">
                <!-- <h4>Your Progress:</h4>
                <p>You have created your account</p>
                <p>You have added one or more Hiring Team Members</p>
                <p>You have added one or more Hiring Teams</p> -->
            </div>

            <div class="steps d-flex flex-wrap justify-content-center align-items-center p-0 flex-sm-nowrap padding-top-2x padding-bottom-1x">
                
                <div class="step completed">
                    <div class="step-icon-wrap">
                        <div class="step-icon">
                           1 <!-- <fa-icon [icon]="faBuilding"></fa-icon> -->
                        </div>
                    </div>
                    <!-- <h4 class="step-title">Add your Company details</h4> -->
                </div>
                <div class="step" [ngClass]="stepName === 'onepoint5' || stepName === 'second' || stepName === 'third' ? 'completed': ''">
                    <div class="step-icon-wrap">
                        <div class="step-icon">
                            2<!-- <fa-icon [icon]="faUsers"></fa-icon> -->
                        </div>
                    </div>
                    <!-- <h4 class="step-title">Create Hiring Team Members</h4> -->
                </div>
                <div class="step" [ngClass]="stepName === 'second' || stepName === 'third' ? 'completed': ''">
                    <div class="step-icon-wrap">
                        <div class="step-icon">
                            3<!-- <fa-icon [icon]="faUsers"></fa-icon> -->
                        </div>
                    </div>
                    <!-- <h4 class="step-title">Create Hiring Team Members</h4> -->
                </div>
                
            </div>
        </div>
    </div>
</div>