<main id="positions-content">
    <app-header style="z-index: 99;position: relative;"></app-header>

    <div class="widget-wrapper">
        <app-detail-header [navlink]="navLinkAction" (tabAction)="actionEmitter($event)"></app-detail-header>

        <div class="page-body widget-box" *ngIf="showProgressTable">
            <!-- *ngIf="showProgressTable && currentUserRoleCode!='/htm'" -->
            <div class="row">
                <div class="col-sm-3 pr-0" style="max-width: 20%;">
                    <div class="widget-col">
                        <div class="headerBox" style="height: 125px;">
                            <span> Select one <span>Candidate</span> and one or more <span>Hiring Team Members</span>
                                to view their Insights.</span>
                        </div>
                        <ul class="widget-user-list" *ngIf="candidateList">
                            <ng-container *ngFor="let can of candidateList; let i = index">
                                <li class="userInfo" style="box-shadow: 0px 3px var(--secondary-light);">
                                    <a href="javascript:void(0)" style="min-height: 142px;" (click)="selectCan(can)"
                                        [class]="{'selected': can.candidate_id == selectedCan?.candidate_id || can.candidate_id == alreadySelectedCand, 'bg-color':can.withdrawed || can.requested || can.hired}">
                                        <!-- [ngStyle]="{'background-color': can.withdrawed || can.requested ? '#bed2e3' : '#ffffff'}" -->
                                        <div class="cand-fig">
                                            <figure>
                                                <img
                                                    [src]="can.profile_photo && can.profile_photo !== 'None' ? imgBaseUrl + can.profile_photo : './../../../assets/images/big-default-avatar.png'" />
                                                <!-- <fa-icon [icon]="faTrophy"
                                                    *ngIf="i<3 && (!can.withdrawed && !can.requested)"></fa-icon> -->
                                            </figure>
                                        </div>
                                        <span class="user-info">
                                            <span class="name b-title pb-1">{{can.name}} </span>
                                            <p *ngIf="can.hired" class="candidate-label" style="color: green;">Candidate
                                                Hired</p>
                                            <p *ngIf="can.withdrawed" class="candidate-label">Withdrawn Candidate</p>
                                            <p *ngIf="can.requested" class="candidate-label">Pending Candidate Approval
                                            </p>
                                            <p *ngIf="!can.withdrawed && !can.requested && !can.hired"
                                                class="candidate-label" style="margin-bottom: 0.3rem;"><span
                                                    class="intend">Interviews:</span>
                                                {{can.marks_given_by+'/'+can.total_hiring_members}} </p>
                                            <p *ngIf="!can.withdrawed && !can.requested && !can.hired && currentUserRoleCode!=='/htm'"
                                                class="candidate-label" style="margin-bottom: 10px;">
                                                <span class="intend">Overall Score</span>
                                                <span class="marks-flag ">
                                                    {{can.final_avg_marks}}
                                                </span>
                                            </p>
                                        </span>
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-10 pl-0 widget" style="max-width: 80%; padding-right: 15px;">
                    <div class="row">
                        <div class="col-sm-12" style="padding-right: 0;">
                            <ul class="widget-user-lists list-htms" *ngIf="allPositionData">
                                <ng-container *ngFor="let htm of allPositionData.data?.members; let i = index">
                                    <li class="userInfo htmInfo" style="box-shadow: 0px 3px var(--secondary-light);">
                                        <a href="javascript:void(0)" style="height: 125px;" (click)="selectHtm(htm)"
                                            [class]="{'selected': selectedHtmArr.indexOf(htm.id) > -1}">
                                            <figure><img
                                                    [src]="htm.profile_pic && htm.profile_pic !== 'None' && htm.profile_pic !== 'null' ? htm.profile_pic : './../../../assets/images/big-default-avatar.png'" />
                                            </figure>
                                            <span class="user-info htm-bloc">
                                                <span class="name b-title">{{htm.name}}</span>
                                                <!-- <fa-icon title="Email"  (click)="openSm(content,htm)" class="withdraw-htm" [icon]="faEnvelope" size="lg"></fa-icon> -->
                                                <!-- <fa-icon title="Weighting"  (click)="openWeightingModal(weighting, htm.id)" class="withdraw-htm ml-3" [icon]="faBalanceScale"></fa-icon> -->
                                                <fa-icon title="Email" (click)="openSm(content,htm)" class="htm-icon"
                                                    [icon]="faEnvelope" size="lg"></fa-icon>
                                                <fa-icon title="Weighting" (click)="openWeightingModal(weighting, htm)"
                                                    class="htm-icon ml-3" [icon]="faBalanceScale"></fa-icon>
                                            </span>
                                        </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                    <div class="row widget-main">
                        <div *ngIf="showProgressSummary" class="col-sm-12" style="padding-right: 0px;">
                            <app-candidate-summary></app-candidate-summary>
                        </div>
                        <div *ngIf="showInsights && candidateDataAgainstPosition" class="col-sm-12"
                            style="padding-right: 0px;">
                            <app-insights (candInfoAction)="insightsAction($event)" [skillset]="skillData"
                                [candidateData]="candidateDataAgainstPosition"
                                [htmMembers]="allPositionData.data?.members"
                                [selectedHtmsQ]="selectedHtmArr"></app-insights>
                        </div>
                        <div *ngIf="showCalendarPage" class="col-sm-12 " style="padding-right: 0px;">
                            <!-- <app-insights [skillsets]="allPositionData.data?.skillsets" [candidateData]="candidateDataAgainstPosition"></app-insights> -->
                            <div class="m-3">
                                <app-angular-calendar [selectedHtmArr]="selectedHtmArrForCalendar"
                                    [htmMembers]="allPositionData.data?.members" [selectedCan]="selectedCan"
                                    [selectedHtm]="selectedHtm" [hrGroupList]="hrGroupList"
                                    [candidateList]="candidateList"></app-angular-calendar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="showProgressSummary" class="row" style="padding: 15px;">
                <div class="col-sm-12">
                    <div class="task-progress-table  widget-inner-box row">
                        <div *ngIf="true" class="deadline-wrapper col-sm-3">
                            <!-- <div *ngIf="percentageCompletion !== null" [attr.data-before-content]="percentageCompletion+ ' % completed'" class="completion-progress" [ngStyle]="{'background': 'radial-gradient(closest-side, white 59%, transparent 60% 100%), conic-gradient(var(--primary) ' +percentageCompletion+'%, #b5b5b5 0)'}">
                                <progress value="{{percentageCompletion}}" min="0" max="100" style="visibility:hidden;height:0;width:0;">{{percentageCompletion}}%</progress>
                            </div> -->
                            <div *ngIf="daysToDeadline !== null"
                                [attr.data-before-content]="daysToDeadline > 0 ? daysToDeadline+ ' days to completion' : 'Deadline Crossssed'"
                                class="deadline-progress"
                                [ngStyle]="{'background': daysToDeadline > 0 ? 'radial-gradient(closest-side, white 69%, transparent 60% 100%), conic-gradient(var(--primary) '+deadlineDatePercentage+'%, #b5b5b5 0)' : 'radial-gradient(closest-side, white 69%, transparent 60% 100%), conic-gradient(#FE0000 '+deadlineDatePercentage+'%, #b5b5b5 0)' }">
                                <progress value="{{deadlineDatePercentage}}" min="0" max="100"
                                    style="visibility:hidden;height:0;width:0;">{{deadlineDatePercentage}}%</progress>
                            </div>
                            <div class="legends">
                                <div class="legend ">
                                    <span class="d-intent"><span class="square kick-off"></span>Kick-off Date</span>:
                                    {{formatDate(kickoffDate) || 'NA'}}
                                </div>
                                <div class="legend ">
                                    <span class="d-intent"><span class="square sourcing"></span>Sourcing Date</span>:
                                    {{formatDate(sourcingDeadlineDate) || 'NA'}}
                                </div>
                                <div class="legend ">
                                    <span class="d-intent"><span class="square target"></span>Target Completion Date
                                    </span>: {{formatDate(targetDeadlineDate) || 'NA'}}
                                </div>
                            </div>
                        </div>
                        <div class="chart-wrapper col-sm-9">
                            <app-htm-timeline-chart></app-htm-timeline-chart>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- <div class="page-body widget-box" *ngIf="currentUserRoleCode=='/htm'">
            <div class="row">
                <div *ngIf="showProgressSummary" class="col-sm-12" >
                    <app-candidate-summary (activeCandidate)="selectCandidateFromSummary($event)"></app-candidate-summary>
                </div>
                <div  *ngIf="showInsights && candidateDataAgainstPosition"  class="col-sm-12" style="padding-right: 0px;">
                    <app-insights [skillsets]="allPositionData.data?.skillsets" [candidateData]="candidateDataAgainstPosition" [htmMembers]="allPositionData.data?.members"></app-insights>
                </div>
                <div *ngIf="showCalendarPage" class="col-sm-12 " style="padding-right: 0px;">
                    <div class="m-3">
                        <app-angular-calendar [selectedHtmArr]="selectedHtmArr" [selectedCan]="selectedCan" [selectedHtm]="selectedHtm" [hrGroupList]="hrGroupList"  [candidateList]="candidateList"></app-angular-calendar>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <router-outlet></router-outlet> -->
    </div>
    <!-- *ngIf="!(CurrentpageURL.indexOf('add-new-candidate') > -1)" -->
</main>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Send Mail</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="mailForm">
            <div class="form-group row">
                <label for="input-jobtitle" class="col-sm-3 col-form-label">To <small>*</small></label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="email">
                    <div *ngIf="mailForm.controls['email'].invalid && (mailForm.controls['email'].dirty || mailForm.controls['email'].touched)"
                        class="alert">
                        <div *ngIf="mailForm.controls['email'].errors?.required">
                            Email is required.
                        </div>
                        <div *ngIf="mailForm.controls['email'].errors?.pattern">
                            Invalid Email, please try again.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="input-jobtitle" class="col-sm-3 col-form-label">Subject <small>*</small></label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="subject">
                    <div *ngIf="mailForm.controls['subject'].invalid && (mailForm.controls['subject'].dirty || mailForm.controls['subject'].touched)"
                        class="alert">
                        <div *ngIf="mailForm.controls['subject'].errors?.required">
                            Subject is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="input-jobtitle" class="col-sm-3 col-form-label">Body</label>
                <div class="col-sm-9">
                    <textarea class="form-control" formControlName="message"></textarea>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="mailForm.invalid" (click)="sentMail()">Send</button>
    </div>
</ng-template>

<ng-template #weighting let-modal>
    <div class="modal-header">
        <h4 class="modal-title" style="margin-bottom: -0.7em;margin-left: 0.8em;">{{currentHTMname}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- <form [formGroup]="skillSetForm"> -->
        <!-- <h4 class="modal-title" style="margin-bottom: -0.7em;margin-left: 0.8em;">Weighting </h4> -->
        <table *ngIf="currentHTMWeightage" class=" table table-borderless ">
            <tr>
                <th> </th>
                <th class="text-center ">Skill Name</th>
                <th class="text-center ">Importance</th>
            </tr>
            <tr *ngFor="let item of currentHTMWeightage; let i=index;">
                <td style=" font-weight: 600;">
                    Skill {{i+1}}
                </td>
                <td class="text-center ">
                    {{item.skillset_name || 'NA'}}
                </td>
                <td class="text-center ">
                    {{item.skillset_weightage || 'NA' }}
                </td>
            </tr>
        </table>
        <p *ngIf="!currentHTMWeightage">No Data available.</p>
        <br>


        <!-- </form> -->
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <!-- <button id="update-weighting" type="button" class="btn btn-primary" (click)="saveHtmWeightage()">Save</button> -->
    </div>
</ng-template>