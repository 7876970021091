import { Component, HostListener, OnInit } from '@angular/core';
import {
  faSort,faMinus,
  faCarrot,faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import {  faMinusSquare } from '@fortawesome/free-regular-svg-icons';
import { CandidateService } from 'src/app/services/candidate.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/core/toast/toast-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.services';

@Component({
  selector: 'app-add-new-candidate',
  templateUrl: './add-new-candidate.component.html',
  styleUrls: ['./add-new-candidate.component.css']
})
export class AddNewCandidateComponent implements OnInit {
  newData: any = [];
  CandidateItem:any = [];
  candData: any;
  sortKey: any;
  reverse: any;
  selectedCand: any = [];
  faSort = faSort
  faMinus = faMinus;
  faCarrot = faCarrot
  faMinusSquare = faMinusSquare
  faInfoCircle = faInfoCircle;
  searchText:any;
  selectedCandidate: any;
  isInviteCandidate: boolean = false;
  createUsersForm !: FormGroup;
  clientId: string | null;
  constructor(private candidateService : CandidateService,
              private modalService: NgbModal,
              private fb: FormBuilder,
              private userserice: UserService,
              private toastService : ToastService,
              private router: Router,
              private authService: AuthService,
              private activatedRoute : ActivatedRoute
  ) { }

  ngOnInit(): void {
    let op_id = this.activatedRoute.snapshot.params['positionId'];
    this.clientId = localStorage.getItem('clientId')
    this.getAllCandidate(op_id)
    this.getSubmittedCandidateData(op_id);
  }
  inviteCandidate(){
    this.createForm()
    this.isInviteCandidate = true;
  }
  createForm() {
    let op_id = this.activatedRoute.snapshot.params['positionId'];
    this.createUsersForm = this.fb.group({
      client: [this.clientId, Validators.required],
      email: ['', [
        Validators.required,
        Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$'),
      ]],
      role: ['is_candidate', Validators.required],
      open_position:[op_id]
    });
  }
  inviteUsers()
  {
    this.userserice.inviteUsers(this.createUsersForm.value).subscribe((val:any)=>{
      if(val){
        this.showSuccess('Invitation Sent')
        this.goBack()
        // setTimeout(() => {
        //     this.router.navigate([this.authService.currentUserRoleCode + '/view-all-users']).then(() => {
        //       window.location.reload();
        //     });
        //   }, 2000);
      }
      else{
        this.showDanger('Something went wrong')
      }
    })
  }
  goBack(){
    this.isInviteCandidate = false;
    this.newData = []
    let op_id = this.activatedRoute.snapshot.params['positionId'];
    this.getAllCandidate(op_id)
  }
  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }
  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }
  getAllCandidate(op_id:any) {
    this.candidateService.getSubmitCandidate(op_id).subscribe((val: any) => {
      if (val) {
        this.candData = val?.data;
      }
    });
  }

  sendTheNewValue(event: any) {

    if(event.target.value == ''){
      this.newData=[];
    }
    else{
      if (event.target.value.indexOf('@') > -1) {
        this.newData = this.candData.filter((v: any) =>
          v.email.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1).slice(0, 10)
        
      }
  
      else {
        this.newData = this.candData.filter((v: any) =>
          v.full_name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 ).slice(0, 10)
      }
    }
   
  }
  getSubmittedCandidateData(op_id:any){
    this.candidateService.getSubmittedCandidateData(op_id).subscribe((val:any)=>{
      if(val){
        this.selectedCand =val.data
        this.getAllCandidate(op_id)
      }
    })

  }
  postCandidateData(cand_id:any){
    let op_id = this.activatedRoute.snapshot.params['positionId'];
    this.candidateService.postSubmittedCandidate(op_id,cand_id).subscribe((val: any) => {
      if (val) {
        this.getSubmittedCandidateData(op_id);
        // this.candData = val?.data;
      }
    });
  }
  deleteCandidateData(cand_id:any){
    let op_id = this.activatedRoute.snapshot.params['positionId'];
    this.candidateService.deleteSubmittedCandidate(op_id,cand_id).subscribe((val: any) => {
      if (val) {
        this.getSubmittedCandidateData(op_id);
        // this.candData = val?.data;
      }
    });
  }
  selectCand(item:any){
    let i=0;
    for(i=0;i<this.selectedCand.length;i++)
    {
      if(this.selectedCand[i].candidate_id==item.candidate_id){
        this.deleteCandidateData(item.candidate_id)
        // this.selectedCand.splice(i, 1);
        return;
      }
    }
    this.postCandidateData(item.candidate_id)
  // this.selectedCand.push(item)
  }
  clearSuggestion(){
    this.searchText = ''
    this.newData = []
    let op_id = this.activatedRoute.snapshot.params['positionId'];
    this.getAllCandidate(op_id)
  }
  sortTable(column: any): void {
    if (this.sortKey == column) {
      // If the same column is clicked again, reverse the order
      this.reverse = !this.reverse;
    } else {
      // If a new column is clicked, set the column and set ascending order
      this.sortKey = column;
      this.reverse = false;
    }

    this.selectedCand.sort((a:any, b:any) => {
      var x = a[column];
      var y = b[column];
      x = x.toString();
      y = y.toString();
      return this.reverse ? y.localeCompare(x) : x.localeCompare(y);
    });
  }
  openSm(content:any,item: any) {
    this.selectedCandidate=''
    this.selectedCandidate = item;
    this.modalService.open(content, { size: 'sm' });
  }
  removeHtm(){
    this.deleteCandidateData(this.selectedCandidate.candidate_id)
    this.selectedCandidate='';
    this.modalService.dismissAll();
    // this.selectedCand.splice(i, 1);
  }
}
