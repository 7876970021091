<div class="row mt-5 pt-3">
    <div class="col-md-12">
        <div class="widget-col" *ngIf="positionData">
            <h2 class="widget-title">Position Summary</h2>
            <div class="widget-box" style="height: auto;">
                <div class="widget-innerbox">
                <h5 class="ml-5 mt-4 mb-0">Summary</h5>
                <div class="row m-0 mx-md-n3">
                <div class="col-md-8">
                <div class="row m-0 mx-md-n3">
                    <div class="col-md-6">
                        <div class="dash-card card" style="border-style: none;">
                            <p>Position Title: <b>{{this.positionData.position_title  || 'N/A'}}</b></p>
                            <p>Number of Openings: <b>{{this.positionData.no_of_open_positions  || 'N/A'}}</b></p>
                            <p>Salary Range: <b>{{this.positionData.salary_range ? ((positionData.currency || '$') + positionData.salary_range) : 'N/A'}}</b></p>
                            <p>Location Preference: <b>{{positionData.candidate_location || 'N/A'}}</b></p>
                        </div>
                
                    </div>

                    <div class="col-md-6">
                        <div class="dash-card card" style="border-style: none;">
                            <p><b>Required Skillset: </b></p>
                            <ng-container  *ngFor="let item of positionData.skillsets; let i = index">
                                <p>Skill {{i}}: <b>{{item.skillset_name || 'N/A'}}</b></p>
                            </ng-container>
                           
                            <!-- <p>Skill 1: <b>{{positionData.init_qualify_ques_1 || 'N/A'}}</b></p>
                            <p>Skill 2: <b>{{positionData.init_qualify_ques_2|| 'N/A'}}</b></p>
                            <p>Skill 3: <b>{{positionData.init_qualify_ques_3 || 'N/A'}}</b></p>
                            <p *ngIf="positionData.init_qualify_ques_4">Skill 4: <b>{{positionData.init_qualify_ques_4 || 'N/A'}}</b></p>
                            <p *ngIf="positionData.init_qualify_ques_5">Skill 5: <b>{{positionData.init_qualify_ques_5|| 'N/A'}}</b></p>
                            <p *ngIf="positionData.init_qualify_ques_6">Skill 6: <b>{{positionData.init_qualify_ques_6 || 'N/A'}}</b></p>
                            <p *ngIf="positionData.init_qualify_ques_7">Skill 7: <b>{{positionData.init_qualify_ques_7 || 'N/A'}}</b></p>
                            <p *ngIf="positionData.init_qualify_ques_8">Skill 8: <b>{{positionData.init_qualify_ques_8|| 'N/A'}}</b></p> -->
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="dash-card card" style="border-style: none; padding-top: 30px; padding-bottom: 30px;">
                            <p><b>Planned Schedule: </b></p>
                            <p>Kick-off Date: <b>{{positionData.kickoff_start_date  || 'N/A'}}</b></p>
                            <p>Sourcing Completion Date: <b>{{positionData.sourcing_deadline  || 'N/A'}}</b></p>
                            <p>Target Completion Date: <b>{{positionData.target_deadline || 'N/A'}}</b></p>
                        </div>
                    </div>
                    </div>
                </div>
                    <div class="col-md-4">
                    
                    
                        <!-- <div class="dash-card card" style="border-style: none;">
                            <p class="mb-3">Hiring Team: <b>{{positionData.hiring_group?.name || "N/A"}}</b></p>
                            <ul class="pl-0 list-unstyled" >
                                <li *ngFor="let head of hiringData">
                                    <div class="mb-3">
                                        
                                            <img style="width: 60px; height: 60px; margin-right: 8px;" class="candidate-profile-pic img-fluid float-left" height="15px" width="15px" [src]="head.profile_pic && head.profile_pic !== 'None' && head.profile_pic !== 'null' ? imageBaseUrl + head.profile_pic : './../../assets/images/big-default-avatar.png'" />                                            
                                        
                                            <span class="user-info cursor-pointer"  style="font-size: 14px;">
                                                <span class="name">{{head.name}}</span>
                                                <p class="htm-label"><ng-container *ngIf="head.isHod">Hiring Manager</ng-container></p>
                                                <p class="htm-label"><ng-container *ngIf="head.isHr">Team Coordinator</ng-container></p>
                                                <p class="htm-label"><ng-container *ngIf="!head.isHr && !head.isHod">Hiring Team Member</ng-container></p>
                                            </span>
                                        </div>
                                </li>
                            </ul>
                            

                        </div> -->
                    
                    </div>
                    </div>
                <!-- <div class="col-md-4">
                        <div class="dash-card card">
                            <p>Hiring Team: <b>{{positionData.hiring_group?.name || "N/A"}}</b></p>
                    <ul class="hiring-team-list">
                        <li class="team-box userInfo col-md-6"style="padding: 0;">
                                <span class="row d-flex">
                                    
                                        <span class="team-name col-md-6" *ngFor="let team of hiringData">
                                            <img style="width: 60px; height: 60px; margin-right: 8px;" class="float-left" [src]="team.profile_pic && team.profile_pic !== 'None' && team.profile_pic !== 'null' ? imageBaseUrl + team.profile_pic : './../../assets/images/big-default-avatar.png'">
                                            <span style="position: relative; top: 12px">
                                                <h6 class="m-0">{{team.name}}</h6>
                                                <span style="font-size: 14px;">
                                                    <ng-container *ngIf="team.isHod">Hiring Manager</ng-container>
                                                    <ng-container *ngIf="team.isHr">Team Coordinator</ng-container>
                                                    <ng-container *ngIf="!team.isHr && !team.isHod">Hiring Team Member</ng-container>
                                                </span>
                                            </span>
                                        </span>
                                    
                                </span>
                            
                        </li>
                    </ul>
                    </div>
                </div> -->
                <div class="row m-0 mx-md-n3">
                    <div class="col-md-12" style="padding-bottom: 5% ;">
                    <app-htm-timeline-chart></app-htm-timeline-chart>
                       
                    </div>
                    </div>

                    
                    
                </div>
            </div>
        </div>
        </div>
    
</div>