import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import {
  faMinus,
  faPlus,
  faEnvelope,
  faTrophy,
  faCalendarAlt
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CandidateService } from 'src/app/services/candidate.service';
import { ToastService } from 'src/app/core/toast/toast-service';
import { faThumbsUp, faThumbsDown, faHandPointDown, faClock, faFile, faExclamation, faCheckCircle, faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import * as baseurl from './../../constants/url';
import { PositionService } from '../../services/position.service';
import * as Url from './../../constants/url';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from 'src/app/services/auth.services';
import { HiringTeamService } from 'src/app/services/hiringteam.service';
import { el } from 'date-fns/locale';

@Component({
  selector: 'app-candidate-summary',
  templateUrl: './candidate-summary.component.html',
  styleUrls: ['./candidate-summary.component.css'],
})
export class CandidateSummaryComponent implements OnInit {
  public baseUrl: string = '';
  public faInfo = faInfo;
  faExclamation = faExclamation;
  public faCalendarAlt = faCalendarAlt;
  public faEnvelope = faEnvelope;
  public faMinus = faMinus;
  public faPlus = faPlus;
  public faThumbsUp = faThumbsUp;
  public faThumbsDown = faThumbsDown;
  public faTrophy = faTrophy;
  faCheckCircle = faCheckCircle;
  faBalanceScale = faBalanceScale
  faFile = faFile;
  public hrGroupList: any = {};
  public candidateList: any[] = [];
  public selectedCandidate: any;
  public selectedRate: any;
  public manipulated: any[] = [];
  public mailForm!: FormGroup;
  public skillSetForm!: FormGroup;
  subscription$: any;
  public imageBaseUrl: any;
  public currentUser: any = JSON.parse(localStorage.getItem('currentUser') || '{}');
  public openPositionDetail: any;
  public faThumbup = faThumbsUp;
  public faThumbdown = faThumbsDown;
  public faThumbhold = faHandPointDown;
  public faClock = faClock;
  @Output() nameEvent = new EventEmitter<boolean>();
  @Output() activeCandidate = new EventEmitter<any>();
  // @Output() activeCandidate = new EventEmitter<any>();
  @ViewChild('example') example!: TemplateRef<any>;
  notificationData: any = [];
  isNotificationShown = false;
  toBeRefreshed = true;
  isHiringManager: any;
  hoveredCandidate: any;
  currentPosition: any;
  interviewTime: any;
  kickoffDate: any;
  sourcingDeadlineDate: any;
  targetDeadlineDate: any;
  openPositionDetailForHTM: any;
  selectedHtmId: any;
  skill_no: any;
  htm_details: any;
  tabWidth: any;
  ht_data: any = [];
  countdownInterval: any;
  remainingTime: string = '';
  candInt_ID: any;

  constructor(
    private candidateService: CandidateService,
    private positionService: PositionService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastService: ToastService,
    private router: Router,
    private _service: NotificationsService,
    private authService: AuthService,
    private hiringTeamService: HiringTeamService
  ) { }

  ngAfterViewInit() {

  }

  getNotificationsByPosition(id: any) {
    this.positionService.getNotificationsByPosition(id).subscribe((val: any) => {
      if (val) {
        val.forEach((element: any) => {
          element.id = element.Text;
          this.notificationData.push(element);
        });
        this.notificationData = this.notificationData.reverse();
        if (this.notificationData.length) {
          this.showNotification();
        }
      }
    });
  }

  ngOnInit(): void {
    this.imageBaseUrl = Url.imgBaseUrl;
    this.baseUrl = baseurl.imgBaseUrl;
    this.createForm();
    this.createSkillForm();
    this.subscription$ = this.candidateService.getSelectedCandidate().subscribe((val) => {

      // if (val.id) {
      this.currentPosition = localStorage.getItem('currentPosition');
      this.positionService.getPositionDataByPositionId(this.currentPosition).subscribe((res: any) => {
        this.ht_data = res.data.members;
        this.tabWidth = (100 / this.ht_data.length) + '%';

      });

      this.isHiringManager = localStorage.getItem('is_hiring_manager');
      this.createSkillForm();
      this.hrGroupList = [];
      this.candidateList = [];
      this.currentPosition = localStorage.getItem('currentPosition');
      this.getPositionSummary();
      this.selectedCandidate = val.client ? val : val?.open_position_data?.filter((el: any) => el?.id == this.currentPosition)[0];
      if (val.open_position_data || val.client || true) {
        this.currentPosition = localStorage.getItem('currentPosition');
        this.getPositionsCandidates(this.currentPosition);
      }
      if (this.selectedCandidate?.hiring_group) {
      }
      if (val.open_positions) {
        this.getPositionById(this.currentPosition);
      }
      if (this.currentUser.role === 'Senior Manager' || this.currentUser.role === 'Account Manager' || this.currentUser.role === 'Hiring Manager' || this.currentUser.role === 'Client Admin' || this.currentUser.role === 'Human Resource') {
        if (this.selectedCandidate?.id) {
          this.getNotificationsByPosition(this.selectedCandidate?.id);
        }
      }
      // }
      //  else {
      //   this.router.navigate([this.authService.currentUserRoleCode + '/view-all-positions']);
      // }

    });

  }

  createSkillForm() {
    this.skillSetForm = this.fb.group({
      init_qualify_ques_1: [''],
      init_qualify_ques_2: [''],
      init_qualify_ques_3: [''],
      init_qualify_ques_4: [''],
      init_qualify_ques_5: [''],
      init_qualify_ques_6: [''],
      init_qualify_ques_7: [''],
      init_qualify_ques_8: [''],
      init_qualify_ques_1_weightage: [0],
      init_qualify_ques_2_weightage: [0],
      init_qualify_ques_3_weightage: [0],
      init_qualify_ques_4_weightage: [0],
      init_qualify_ques_5_weightage: [0],
      init_qualify_ques_6_weightage: [0],
      init_qualify_ques_7_weightage: [0],
      init_qualify_ques_8_weightage: [0],
      htm_id: [this.selectedHtmId],
      op_id: [this.currentPosition]
    });
  }

  increaseWeightage(control: any) {
    if (this.skillSetForm.controls[control].value >= 10) {
      return;
    }
    this.skillSetForm.controls[control].patchValue(this.skillSetForm.controls[control].value + 1);
  }

  dereaseWeightage(control: any) {
    if (this.skillSetForm.controls[control].value <= 1) {
      return;
    }
    this.skillSetForm.controls[control].patchValue(this.skillSetForm.controls[control].value - 1);
  }

  saveHtmWeightage() {
    this.skillSetForm.controls['htm_id'].setValue(this.selectedHtmId);
    this.positionService.saveHtmWeigtage(this.currentPosition, this.selectedHtmId, this.skillSetForm.value).subscribe((val: any) => {
      if (val.error) {
        this.showDanger(val.error);
      } else {
        this.showSuccess('Weightage saved successfully!')
        this.modalService.dismissAll();
      }
    });
  }

  getPositionSummary() {
    this.kickoffDate = null;
    this.sourcingDeadlineDate = null;
    this.targetDeadlineDate = null;
    const op_id = localStorage.getItem('currentPosition');
    this.hiringTeamService.getPositionSummary(op_id).subscribe((res: any) => {
      Object.keys(res.dates).forEach((e: any) => {
        if (res.dates[e].stage === 'kickoff') {
          this.kickoffDate = e;
        }
      });
      Object.keys(res.dates).forEach((e: any) => {
        if (res.dates[e].stage === 'sourcing') {
          this.sourcingDeadlineDate = e;
        }
      });
      Object.keys(res.dates).forEach((e: any) => {
        if (res.dates[e].stage === 'target') {
          this.targetDeadlineDate = e;
        }
      });

    })
  }

  getPositionById(id: any) {
    if (id) {
      this.positionService.getPositionById(id).subscribe((val: any) => {
        if (val) {
          this.openPositionDetail = val.data;
        }
      });
    }
  }

  showListPage(item?: any) {
    if (item.requested || item.withdrawed) {
      return;
    }
    if (item && (this.currentUser.role === 'Hiring Member' || this.currentUser.role === 'Hiring Manager')) {
      this.activeCandidate.emit(item);
    }
  }
  isDisableInt(cand: any) {
    if (this.candInt_ID == cand.candidate_id) {
      setTimeout(() => {
        this.candInt_ID = ''
      }, 300000);
      return true;
    }
    else {
      return false
    }
  }
  startInterview(cand: any, ind: number) {
    this.candInt_ID = cand.candidate_id
    this.candidateService.setCandidateData(cand);
    const interview_id = cand.flag_by_hiring_manager[ind].interview_id
    // localStorage.setItem('candidateData', JSON.stringify(cand))
    // const op_id = localStorage.getItem('currentPosition');
    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree([this.authService.currentUserRoleCode, 'interview-screen', op_id, cand.candidate_id, interview_id])
    // );
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['interview', interview_id])
    );
    window.open(url, "_blank");
  }
  showQuestions(arr: any) {
    return arr.toString();
  }

  @ViewChild('evaluationForm') evaluationForm!: TemplateRef<any>;

  ratingForm = this.fb.group({
    marks: this.fb.array([]),
    thumbs: [],
    thumbs_up: [false],
    thumbs_down: [false],
    hold: [false],
    golden_gloves: [false],
    feedback: ['']
  })
  evaluationSlider: any
  candidateMarks: any;
  options: Options = {
    floor: 0,
    ceil: 10
  };
  voted1: boolean = false;
  voted2: boolean = false;
  voted3: boolean = false;
  disableEvaluate: boolean = false;
  isMarksEmpty: boolean = false;
  isSuggEmpty: boolean = false;
  alertMsg: boolean = false;
  candidate_id: any;

  openEvaluationPopup(candidate: any) {
    this.resetEvaluationForm()
    this.candidate_id = candidate.candidate_id
    this.candidateService.getCandidateRating(this.currentPosition, this.candidate_id).subscribe((data: any) => {
      this.candidateMarks = data[0].marks
      this.addRatingFormFields(this.candidateMarks)
      this.modalService.open(this.evaluationForm, { size: 'lg' });
    })
  }

  addRatingFormFields(marks: any) {
    const marksArray = this.ratingForm.get('marks') as FormArray;
    marks.forEach((mark: any) => {
      marksArray.push(this.createRatingFormMarks(mark.skillset_name, mark.skillset_questions))
    })
    this.createEvaluationSlider(marksArray.value)
  }
  createRatingFormMarks(skillset_name: String, skillset_questions: any) {
    return this.fb.group({
      skillset_name: [skillset_name],
      skillset_questions: [skillset_questions],
      skillset_ans: [''],
      skillset_marks: [],
    })
  }
  getErrorIfNotesEmpty(i: number) {
    const formField = this.getRatingFormMarksFields()[i].get('skillset_ans')
    const required = formField?.hasError('required') && formField?.touched;
    return required
  }
  createEvaluationSlider(marksArray: any) {
    // this.evaluationSlider = marksArray
    this.evaluationSlider = marksArray.map((mark: any, index: any) => {
      return mark =
      {
        ...mark, sliderValue: 0, sliderOptions: {
          floor: 0, ceil: 10,
          disabled: true,
          translate: (value: number, label: LabelType): string => {
            return this.getRatingFeedback(value);
          }
        }
      }
    })
    // console.log(this.evaluationSlider)
  }
  getRatingFormMarksFields() {
    return (this.ratingForm.get('marks') as FormArray).controls;
  }

  onChangeReadOnly(event: Event, i: number) {
    const checkbox = this.evaluationSlider[i].sliderOptions.disabled;
    if (checkbox) {
      this.evaluationSlider[i] = {
        ...this.evaluationSlider[i],
        sliderValue: 5,
        sliderOptions: {
          ...this.evaluationSlider[i].sliderOptions,
          disabled: !checkbox,
          ticksArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          getTickColor: (value: number): string => this.getTickColor(value),
          step: 0.1,
          showTicks: false,
          translate: (value: number, label: LabelType): string => {
            return this.getRatingFeedback(value);
          },
          showTicksValues: false,
        }
      }
    }
    if (!checkbox) {
      this.evaluationSlider[i] = {
        ...this.evaluationSlider[i],
        sliderValue: '',
        sliderOptions: {
          ...this.evaluationSlider[i].sliderOptions, ticksArray: [],
          disabled: !checkbox,
          translate: (value: number, label: LabelType): string => {
            return this.getRatingFeedback(value);
          }
        }
      }
    }
    // this.evaluationSlider[i].sliderOptions.disabled = !checkbox
  }
  onSliderValueChange(event: any, i: number) {
    const marksFormField = this.getRatingFormMarksFields()[i] as FormGroup
    marksFormField.controls.skillset_marks.setValue(event)
  }
  getCheckbox(i: number) {
    if (this.evaluationSlider[i].sliderOptions.disabled) return
    return this.ratingForm.value.marks[i].skillset_ans != '' || this.ratingForm.value.marks[i].skillset_marks != 0
  }
  setForm() {
    this.isSuggEmpty = false
    this.alertMsg = false;
    for (let i = 0; i < this.ratingForm.value.marks.length; i++) {
      if (!this.evaluationSlider[i].sliderOptions.disabled && this.ratingForm.value.marks[i].skillset_marks == 0)
        this.isMarksEmpty = true
      if (!this.evaluationSlider[i].sliderOptions.disabled && this.ratingForm.value.marks[i].skillset_ans == '')
        this.isSuggEmpty = true
    }
    this.ratingForm.value.marks = this.ratingForm.value.marks.map((mark: any, index: any) => {
      if (mark.skillset_ans == '' && (mark.skillset_marks == 0 || mark.skillset_marks == null)) {
        mark.isChecked = false
      } else {
        mark.isChecked = true
      }
      return mark
    })
  }
  saveRating() {
    this.setForm()
    if (this.isMarksEmpty || this.isSuggEmpty
      || (!this.ratingForm.controls['thumbs_down'].value && !this.ratingForm.controls['thumbs_up'].value && !this.ratingForm.controls['golden_gloves'].value)) {
      this.alertMsg = true;
      setTimeout(() => {
        this.isMarksEmpty = false
        this.alertMsg = false;
      }, 4000);
      return
    }
    // console.log(this.ratingForm.value)
    // return
    this.candidateService.postCandidateRating(this.currentPosition, this.candidate_id, this.ratingForm.value).subscribe((res: any) => {
      if (res) {
        this.showSuccess("Evaluation sent")
        this.disableEvaluate = true;
        this.modalService.dismissAll();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    })
  }
  getRatingFeedback(value: any) {
    switch (true) {
      case value < 3:
        return 'Poor';
      case value < 5:
        return 'Below Average';
      case value < 7:
        return 'Ability to learn';
      case value < 8.5:
        return 'Above Average';
      default:
        return 'Excellent';
    }
  }
  getTickColor(value: number) {
    switch (value) {
      case 0:
        return '#ff0000';
      case 1:
        return '#ff6600';
      case 2:
        return '#ff9000';
      case 3:
        return '#ffb400';
      case 4:
        return '#f1d01a';
      case 5:
        return '#e9f11a';
      case 6:
        return '#d0f11a';
      case 7:
        return '#4cd821';
      case 8:
        return '#20bb20';
      case 9:
        return '#197419';
      case 10:
        return '#155815';
      default:
        return '#155815';
    }
  }

  resetEvaluationForm() {
    this.ratingForm.reset();
    (<FormArray>this.ratingForm.get('marks')).clear();
    this.evaluationSlider = []
  }

  getSelectedRating(event: any) {
    if (event === 'thumbs_up') {
      this.ratingForm.controls['thumbs_up'].setValue(true);
      this.ratingForm.controls['thumbs_down'].setValue(false);
      this.ratingForm.controls['golden_gloves'].setValue(false);
      this.ratingForm.controls['thumbs'].setValue(1)
      this.voted1 = true;
      this.voted2 = false;
      this.voted3 = false
    } else if (event === 'thumbs_down') {
      this.ratingForm.controls['thumbs_up'].setValue(false);
      this.ratingForm.controls['thumbs_down'].setValue(true);
      this.ratingForm.controls['golden_gloves'].setValue(false);
      this.ratingForm.controls['thumbs'].setValue(2)

      this.voted2 = true;
      this.voted1 = false;
      this.voted3 = false
    } else if (event === 'golden_gloves') {
      this.ratingForm.controls['thumbs_up'].setValue(false);
      this.ratingForm.controls['thumbs_down'].setValue(false);
      this.ratingForm.controls['golden_gloves'].setValue(true);
      this.ratingForm.controls['thumbs'].setValue(0)

      this.voted2 = false;
      this.voted1 = false
      this.voted3 = true;
    }
  }
  getPositionsCandidates(id: any) {
    if (id) {
      this.candidateService.getPositionsCandidates(id).subscribe((val: any) => {
        if (val) {
          this.candidateList = val.sort(function (x: any, y: any) { return x.requested - y.requested });
          this.candidateList = val.sort(function (x: any, y: any) { return x.withdrawed - y.withdrawed });
          // let cand = val.filter((el: any) => el.id == this.currentPosition )[0]
          // this.getCountdown('')
        }
      });
    }
  }

  getCountdown(endT: any): any {
    const endTime = new Date(endT); // Set your end time here, e.g., new Date('2024-04-09T00:00:00')
    let remainingTime
    this.countdownInterval = setInterval(() => {
      const currentTime = new Date();
      const difference = endTime.getTime() - currentTime.getTime();

      if (difference <= 0) {
        remainingTime = '00:00:00';
        clearInterval(this.countdownInterval);
      } else {
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        remainingTime = `${this.formatTime(hours)}:${this.formatTime(minutes)}:${this.formatTime(seconds)}`;

      }
      return remainingTime;
    }, 1000);
  }
  private formatTime(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  activeMember(can: any, mem: any) {
    const op_id = localStorage.getItem('currentPosition');
    this.candidateService.getCandidateScore(can, mem, op_id).subscribe((val: any) => {
      this.selectedRate = val.flag;
    });
  }

  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

  openSm(content: any, head: any) {
    this.mailForm.controls.email.patchValue(head.email)
    this.modalService.open(content, { size: 'md' });
  }

  createForm() {
    this.mailForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
      subject: ['', Validators.required],
      message: [''],
    });
  }

  sentMail() {
    this.candidateService
      .sendMail(this.mailForm.value)
      .subscribe((val: any) => {
        if (val.error) {
          this.showDanger(val.error);
          this.mailForm.reset();
          this.modalService.dismissAll();
        } else {
          this.showSuccess('Mail sent successfully');
          this.mailForm.reset();
          this.modalService.dismissAll();
        }
      });
  }

  navigateWithState(htm: any) {
    // if(this.currentUser.role === 'Hiring Team Member' || this.currentUser.role === 'Hiring Member' || this.currentUser.role === 'Hiring Manager') {
    //   return;
    // }
    // if(this.currentUser.role === 'Human Resource') {
    //   localStorage.setItem('selectedHtmId', htm.id);
    //   this.router.navigate([this.authService.currentUserRoleCode + '/position/htmWeightage'], { state: { htm: htm, canditate: this.candidateList }});
    //   return;
    // }
    // const navigationExtras: NavigationExtras = {state: {htm: htm}};
    // this.router.navigate([this.authService.currentUserRoleCode + '/dashboard/hiringDepartment'], navigationExtras);
    // localStorage.setItem('currentHtm', JSON.stringify(htm));
  }

  ngOnDestroy() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }

  showNotification() {
    this.openNotification(this.notificationData[0]);
  }

  closeNotification(val: any) {
    const key = this.notificationData[0].id;
    const payload = {
      [key]: val
    }
    this.positionService.saveNotificationData(this.selectedCandidate.id, payload).subscribe((val: any) => {
      if (val) {
        this.notificationData.shift();
        this._service.remove();
        if (this.notificationData.length) {
          setTimeout(() => {
            this.showNotification();
          }, 500);
        } else {
          document.getElementsByClassName('main-content')[0].classList.remove('mask');
          document.getElementsByClassName('simple-notification-wrapper')[0].classList.remove('d-block');
          this.toBeRefreshed = true;
        }
      }
    });
  }

  openNotification(data: any) {
    const options = {
      position: ["center", "center"],
      clickToClose: false,
    }
    this._service.html(this.example, undefined, options, '', null);
    this.isNotificationShown = true;
    document.getElementsByClassName('simple-notification-wrapper')[0].classList.add('d-block');
    document.getElementsByClassName('main-content')[0].classList.add('mask');
    this.toBeRefreshed = false;
  }

  getInterviewTime(data: any) {
    this.interviewTime = data;
  }
  daysToDeadline: any = null;
  percentageCompletion: any = null;
  onHoverHtm = true;
  deadlineDatePercentage: any = null;
  getHtmProgressData(htm: any) {
  }
}
