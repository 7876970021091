<div class="page-header" *ngIf="openPositionAvailable">
    <h2 class="page-title">{{selectedCandidate?.position_title || 'Data not found'}}</h2>
    <span class="sub-title">Completion Date: {{selectedCandidate?.formated_target_deadline || 'Data not found'}}</span>
    <div class="status">Status: <span class="s-delayed">{{selectedCandidate?.deadline ? 'Delayed' : 'On Schedule'
            }}</span>
        <!-- <a href="javascript:void(0)" (click)="showProgress()" *ngIf="(userRole =='Senior Manager') || (userRole =='Account Manager') || (userRole =='Superuser') || isHiringManager || (userRole =='Client Admin') || userRole === 'Human Resource'">
            <fa-icon [icon]="faEye"></fa-icon>
        </a> -->
        <span class="ml-3">
            <position-actions [positionData]="selectedCandidate"
                (actionEmitter)="actionEmitter($event)"></position-actions>
        </span>
    </div>
</div>
<!-- //page-header-->

<div class="widget-wrapper" *ngIf="openPositionAvailable">
    <div id="showSummary" class="row" *ngIf="showSummary">
        <div class="col-md-4">
            <div class="widget-col">
                <h2 class="widget-title">Candidates</h2>
                <div class="widget-box">
                    <ul class="widget-user-list">
                        <li class="userInfo"
                            [ngClass]="{'urgent-notification': noData === true && SelCandidate?.candidate_id === item?.candidate_id, 'can-withdrawed': item.withdrawed}"
                            *ngFor="let item of candidateList; let i = index">
                            <!-- <a href="javascript:void(0)" [ngClass]="{'active': SelCandidate == item}" (click)="activeCandidate(item)"> -->
                            <a href="javascript:void(0)" [ngClass]="{'active': SelCandidate == item}"
                                (dblclick)="(userRole =='Superuser' || userRole =='Client Admin' || userRole =='Senior Manager' || userRole =='Account Manager' || userRole =='Hiring Member' || userRole =='Hiring Manager'  || userRole =='Human Resource') && candidateDetails(item)"
                                (click)="activeCandidate(item)"
                                [ngStyle]="{'background-color': item.withdrawed || item.requested ? '#bed2e3' : '#ffffff'}">
                                <figure><img
                                        [src]="item.profile_photo && item.profile_photo !== 'None' ? imgBaseUrl + item.profile_photo : './../../../assets/images/big-default-avatar.png'" />
                                </figure>
                                <span class="user-info">
                                    <span class="name">{{item?.name}} {{item?.last_name}}</span>
                                    <span [hidden]="this.userRole === 'Hiring Member'" class="r-count">Interviews
                                        {{item.interviews_done ||
                                        0}}/{{item.total_hiring_members || 0}}</span>
                                    <p *ngIf="item.withdrawed" class="candidate-label">Withdrawn Candidate</p>
                                    <p *ngIf="item.requested" class="candidate-label">Pending Candidate Approval</p>

                                    <span class="round-list"
                                        *ngIf="noData && SelCandidate?.candidate_id === item?.candidate_id">
                                        <span class="urgent-info">Urgent: Please interview this candidate and
                                            vote</span>

                                    </span>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-4" [hidden]="isHiringManager === 'false'">
            <div class="widget-col">
                <h2 class="widget-title">Hiring Team</h2>
                <div class="widget-box">
                    <ul class="widget-user-list">
                        <li class="userInfo" *ngIf="hrGroupList[0]?.hiring_manager?.name">
                            <a href="javascript:void(0)"
                                [ngClass]="{'active': selectedHtmsList.includes(hrGroupList[0]?.hiring_manager?.id)}"
                                (dblclick)="(userRole =='Superuser' || userRole =='Senior Manager' || userRole =='Account Manager' || userRole =='Hiring Manager' || isHiringManager || userRole =='Client Admin' || userRole =='Human Resource') && openHtmWeightage(hrGroupList[0]?.hiring_manager)"
                                (click)="activeMember(hrGroupList[0]?.hiring_manager)">
                                <figure><img
                                        [src]="hrGroupList[0]?.hiring_manager?.profile_photo && hrGroupList[0]?.hiring_manager?.profile_photo !== 'None' ? imgBaseUrl + hrGroupList[0]?.hiring_manager?.profile_photo : './../../../assets/images/big-default-avatar.png'" />
                                </figure>
                                <span class="user-info">
                                    <span class="name">{{hrGroupList[0]?.hiring_manager?.name}}(HM)</span>
                                    <!-- <span class="name-role">Senior Java Developer</span> -->
                                    <span class="r-count">Interviews
                                        {{hrGroupList[0]?.hiring_manager?.interview_taken}}/{{hrGroupList[0]?.hiring_manager?.total_candidates}}</span>
                                </span>
                                <!-- <span (click)="withdrawHTM(hrGroupList[0]?.hiring_manage)" class="withdraw-htm btn" href="javascript:void(0)">Withdraw HTM</span> -->
                            </a>
                        </li>
                        <li class="userInfo" *ngFor="let item of hrGroupList[0]?.members"
                            [hidden]="item.id === hrGroupList[0]?.human_resource?.id">
                            <a href="javascript:void(0)" [ngClass]="{'active': selectedHtmsList.includes(item.id)}"
                                (dblclick)="(userRole =='Superuser' || userRole =='Senior Manager' || userRole =='Account Manager' || userRole =='Hiring Manager' || isHiringManager || userRole =='Client Admin' || userRole =='Human Resource') && openHtmWeightage(item)"
                                (click)="activeMember(item)">
                                <figure><img
                                        [src]="item.profile_photo && item.profile_photo !== 'None' ? imgBaseUrl + item.profile_photo : './../../../assets/images/big-default-avatar.png'" />
                                </figure>
                                <span class="user-info">
                                    <span class="name">{{item.name}}</span>
                                    <span class="name-role">Job Title: {{item.job_title}}</span>
                                    <span class="name-role">Phone No: {{item.phone_number}}</span>
                                    <span class="name-role">Email: {{item.email}}</span>
                                    <span class="name-role">LinkedIn: {{item.linkedin}}</span>
                                    <span class="name-role" *ngIf="item.skillsets?.length">Skillset:
                                        <ng-container *ngFor="let skill of item.skillsets; let i = index;">
                                            {{skill.position}}
                                            <ng-container *ngIf="i !== item.skillsets?.length - 1">
                                                ,
                                            </ng-container>
                                        </ng-container>
                                    </span>
                                    <span class="r-count">Interviews
                                        {{item.interview_taken}}/{{item.total_candidates}}</span>
                                </span>

                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-4" [hidden]="isHiringManager === 'false'">
            <div class="widget-col" *ngIf="showTopCandidate">
                <h2 class="widget-title">Top Candidates</h2>
                <div class="widget-box">
                    <ul class="widget-user-list">
                        <li class="userInfo" *ngFor="let item of activeCandidateList | slice: 0:3; let i = index">
                            <a style="cursor: pointer;"
                                (click)="(userRole =='Superuser' || userRole =='Client Admin') && candidateDetails(item)">
                                <figure><img
                                        [src]="item.profile_photo && item.profile_photo !== 'None' ? imgBaseUrl + item.profile_photo : './../../../assets/images/big-default-avatar.png'" />
                                </figure>
                                <span class="user-info">
                                    <span class="name">{{item?.name}} {{item?.last_name}}</span>
                                    <span class="scroll_point">{{item.final_avg_marks | number : '1.1-1'}}</span>
                                    <span class="scroll_name">Overall Score</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="widget-col" *ngIf="!showTopCandidate">
                <h2 class="widget-title">Candidate Interview Results</h2>
                <div class="widget-box">
                    <div class="widget-inner-box">
                        <span class="round-list">
                            <!-- <span class="like-icon iconLink"><a href="#"><i class="fa fa-id-card"></i></a></span>
                        <span class="like-icon rotate like"><a href="#"><i class="fa fa-thumbs-up"></i></a></span>
                        <span class="like-icon iconLink"><a href="#"><i class="fa fa-envelope"></i></a></span> -->
                        </span>
                        <ul class="widget-scors-list candidate-score"
                            *ngIf="candidateAverageData && selectedHtmsList.length === 1">
                            <li *ngIf="SelMember" class="pl-3">
                                <span class="marks-flag thumbs-down" *ngIf="memberThumbVal === 'Thumbs Down'">
                                    <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}" [icon]="faThumbdown"
                                        size="lg"></fa-icon>
                                </span>
                                <span class="marks-flag" *ngIf="memberThumbVal === 'Golden Glove'">
                                    <fa-icon [styles]="{'stroke': '#fff', 'color': '#ffd700', 'font-size': '35px'}"
                                        [icon]="faThumbup"></fa-icon>
                                </span>
                                <span class="marks-flag thumbs-up" *ngIf="memberThumbVal === 'Thumbs Up'">
                                    <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}"
                                        [icon]="faThumbup"></fa-icon>
                                </span> &nbsp;&nbsp;
                                <b>{{SelCandidate?.name}} {{SelCandidate?.last_name}} and {{ SelMember?.name}}</b>
                                <!-- <span class="marks-flag hold" *ngIf="memberThumbVal === 'Hold'">
                                    <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}" [icon]="faThumbup"></fa-icon>
                                </span> -->
                            </li>
                            <ng-container *ngIf="SelCandidate?.marks_given_by && !noData">
                                <li *ngIf="candidateAverageData?.final_avg_marks">
                                    <span class="scroll_point">{{candidateAverageData?.final_avg_marks | number :
                                        '1.1-1'}}</span>
                                    <span class="scroll_name">Overall Score</span>
                                </li>
                                <li *ngIf="SelCandidate?.init_qualify_ques_1 !== ''">
                                    <div class="row">
                                        <div class="text-center" [ngClass]="SelMember ? 'col-md-3': 'col-md-12'"
                                            [ngClass]="SelMember ? 'col-md-3': 'col-md-12'">
                                            <span class="scroll_point">{{candidateAverageData?.init_qualify_ques_1 |
                                                number : '1.1-1'}}</span>
                                            <span class="scroll_name">{{SelCandidate?.init_qualify_ques_1}}</span>
                                            <ng-container *ngIf="SelMember">
                                                <fa-icon
                                                    *ngIf="!cheveron1Show && candidateAverageData.init_qualify_ques_suggestion_1 !== ''"
                                                    (click)="cheveron1Show = !cheveron1Show" class="chevron-icon"
                                                    [styles]="{'stroke': '#000', 'color': '#000'}"
                                                    [icon]="faChevronDown" size="lg"></fa-icon>
                                                <fa-icon *ngIf="cheveron1Show" (click)="cheveron1Show = !cheveron1Show"
                                                    class="chevron-icon" [styles]="{'stroke': '#000', 'color': '#000'}"
                                                    [icon]="faChevronUp" size="lg"></fa-icon>
                                            </ng-container>
                                        </div>
                                        <div *ngIf="SelMember" [hidden]="!cheveron1Show" class="col-md-8">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <p class="suggested-ques">Question :
                                                        {{candidateAverageData.init_qualify_ques_1_ques || ''}}</p>
                                                </div>
                                                <div class="col-sm-12 mt-3">
                                                    <p class="suggested-ans">Answer :
                                                        {{candidateAverageData.init_qualify_ques_1_answer || ''}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li *ngIf="SelCandidate?.init_qualify_ques_2 !== ''">
                                    <div class="row">
                                        <div class="text-center" [ngClass]="SelMember ? 'col-md-3': 'col-md-12'">
                                            <span class="scroll_point">{{candidateAverageData?.init_qualify_ques_2 |
                                                number : '1.1-1'}}</span>
                                            <span class="scroll_name">{{SelCandidate?.init_qualify_ques_2}}</span>
                                            <ng-container *ngIf="SelMember">
                                                <fa-icon
                                                    *ngIf="!cheveron2Show && candidateAverageData.init_qualify_ques_suggestion_2 !== ''"
                                                    (click)="cheveron2Show = !cheveron2Show" class="chevron-icon"
                                                    [styles]="{'stroke': '#000', 'color': '#000'}"
                                                    [icon]="faChevronDown" size="lg"></fa-icon>
                                                <fa-icon *ngIf="cheveron2Show" (click)="cheveron2Show = !cheveron2Show"
                                                    class="chevron-icon" [styles]="{'stroke': '#000', 'color': '#000'}"
                                                    [icon]="faChevronUp" size="lg"></fa-icon>
                                            </ng-container>
                                        </div>
                                        <div *ngIf="SelMember" [hidden]="!cheveron2Show" class="col-md-8">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <p class="suggested-ques">Question :
                                                        {{candidateAverageData.init_qualify_ques_2_ques || ''}}</p>
                                                </div>
                                                <div class="col-sm-12 mt-3">
                                                    <p class="suggested-ans">Answer :
                                                        {{candidateAverageData.init_qualify_ques_2_answer || ''}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li *ngIf="SelCandidate?.init_qualify_ques_3 !== ''">
                                    <div class="row">
                                        <div class="text-center" [ngClass]="SelMember ? 'col-md-3': 'col-md-12'">
                                            <span class="scroll_point">{{candidateAverageData?.init_qualify_ques_3 |
                                                number : '1.1-1'}}</span>
                                            <span class="scroll_name">{{SelCandidate?.init_qualify_ques_3}}</span>
                                            <ng-container *ngIf="SelMember">
                                                <fa-icon
                                                    *ngIf="!cheveron3Show && candidateAverageData.init_qualify_ques_suggestion_3 !== ''"
                                                    (click)="cheveron3Show = !cheveron3Show" class="chevron-icon"
                                                    [styles]="{'stroke': '#000', 'color': '#000'}"
                                                    [icon]="faChevronDown" size="lg"></fa-icon>
                                                <fa-icon *ngIf="cheveron3Show" (click)="cheveron3Show = !cheveron3Show"
                                                    class="chevron-icon" [styles]="{'stroke': '#000', 'color': '#000'}"
                                                    [icon]="faChevronUp" size="lg"></fa-icon>
                                            </ng-container>
                                        </div>
                                        <div *ngIf="SelMember" [hidden]="!cheveron3Show" class="col-md-8">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <p class="suggested-ques">Question :
                                                        {{candidateAverageData.init_qualify_ques_3_ques || ''}}</p>
                                                </div>
                                                <div class="col-sm-12 mt-3">
                                                    <p class="suggested-ans">Answer :
                                                        {{candidateAverageData.init_qualify_ques_3_answer || ''}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li *ngIf="SelCandidate?.init_qualify_ques_4 !== ''">
                                    <div class="row">
                                        <div class="text-center" [ngClass]="SelMember ? 'col-md-3': 'col-md-12'">
                                            <span class="scroll_point">{{candidateAverageData?.init_qualify_ques_4 |
                                                number : '1.1-1'}}</span>
                                            <span class="scroll_name">{{SelCandidate?.init_qualify_ques_4}}</span>
                                            <ng-container *ngIf="SelMember">
                                                <fa-icon
                                                    *ngIf="!cheveron4Show && candidateAverageData.init_qualify_ques_suggestion_4 !== ''"
                                                    (click)="cheveron4Show = !cheveron4Show" class="chevron-icon"
                                                    [styles]="{'stroke': '#000', 'color': '#000'}"
                                                    [icon]="faChevronDown" size="lg"></fa-icon>
                                                <fa-icon *ngIf="cheveron4Show" (click)="cheveron4Show = !cheveron4Show"
                                                    class="chevron-icon" [styles]="{'stroke': '#000', 'color': '#000'}"
                                                    [icon]="faChevronUp" size="lg"></fa-icon>
                                            </ng-container>
                                        </div>
                                        <div *ngIf="SelMember" [hidden]="!cheveron4Show" class="col-md-8">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <p class="suggested-ques">Question :
                                                        {{candidateAverageData.init_qualify_ques_4_ques || ''}}</p>
                                                </div>
                                                <div class="col-sm-12 mt-3">
                                                    <p class="suggested-ans">Answer :
                                                        {{candidateAverageData.init_qualify_ques_4_answer || ''}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li *ngIf="SelCandidate?.init_qualify_ques_5 !== ''">
                                    <div class="row">
                                        <div class="text-center" [ngClass]="SelMember ? 'col-md-3': 'col-md-12'">
                                            <span class="scroll_point">{{candidateAverageData?.init_qualify_ques_5 |
                                                number : '1.1-1'}}</span>
                                            <span class="scroll_name">{{SelCandidate?.init_qualify_ques_5}}</span>
                                            <ng-container *ngIf="SelMember">
                                                <fa-icon
                                                    *ngIf="!cheveron5Show && candidateAverageData.init_qualify_ques_suggestion_5 !== ''"
                                                    (click)="cheveron5Show = !cheveron5Show" class="chevron-icon"
                                                    [styles]="{'stroke': '#000', 'color': '#000'}"
                                                    [icon]="faChevronDown" size="lg"></fa-icon>
                                                <fa-icon *ngIf="cheveron5Show" (click)="cheveron5Show = !cheveron5Show"
                                                    class="chevron-icon" [styles]="{'stroke': '#000', 'color': '#000'}"
                                                    [icon]="faChevronUp" size="lg"></fa-icon>
                                            </ng-container>
                                        </div>
                                        <div *ngIf="SelMember" [hidden]="!cheveron5Show" class="col-md-8">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <p class="suggested-ques">Question :
                                                        {{candidateAverageData.init_qualify_ques_5_ques || ''}}</p>
                                                </div>
                                                <div class="col-sm-12 mt-3">
                                                    <p class="suggested-ans">Answer :
                                                        {{candidateAverageData.init_qualify_ques_5_answer || ''}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li *ngIf="SelCandidate?.init_qualify_ques_6 !== ''">
                                    <div class="row">
                                        <div class="text-center" [ngClass]="SelMember ? 'col-md-3': 'col-md-12'">
                                            <span class="scroll_point">{{candidateAverageData?.init_qualify_ques_6 |
                                                number : '1.1-1'}}</span>
                                            <span class="scroll_name">{{SelCandidate?.init_qualify_ques_6}}</span>
                                            <ng-container *ngIf="SelMember">
                                                <fa-icon
                                                    *ngIf="!cheveron6Show && candidateAverageData.init_qualify_ques_suggestion_6 !== ''"
                                                    (click)="cheveron6Show = !cheveron6Show" class="chevron-icon"
                                                    [styles]="{'stroke': '#000', 'color': '#000'}"
                                                    [icon]="faChevronDown" size="lg"></fa-icon>
                                                <fa-icon *ngIf="cheveron6Show" (click)="cheveron6Show = !cheveron6Show"
                                                    class="chevron-icon" [styles]="{'stroke': '#000', 'color': '#000'}"
                                                    [icon]="faChevronUp" size="lg"></fa-icon>
                                            </ng-container>
                                        </div>
                                        <div *ngIf="SelMember" [hidden]="!cheveron6Show" class="col-md-8">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <p class="suggested-ques">Question :
                                                        {{candidateAverageData.init_qualify_ques_6_ques || ''}}</p>
                                                </div>
                                                <div class="col-sm-12 mt-3">
                                                    <p class="suggested-ans">Answer :
                                                        {{candidateAverageData.init_qualify_ques_6_answer || ''}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li *ngIf="SelCandidate?.init_qualify_ques_7 !== ''">
                                    <div class="row">
                                        <div class="text-center" [ngClass]="SelMember ? 'col-md-3': 'col-md-12'">
                                            <span class="scroll_point">{{candidateAverageData?.init_qualify_ques_7 |
                                                number : '1.1-1'}}</span>
                                            <span class="scroll_name">{{SelCandidate?.init_qualify_ques_7}}</span>
                                            <ng-container *ngIf="SelMember">
                                                <fa-icon
                                                    *ngIf="!cheveron7Show && candidateAverageData.init_qualify_ques_suggestion_7 !== ''"
                                                    (click)="cheveron7Show = !cheveron7Show" class="chevron-icon"
                                                    [styles]="{'stroke': '#000', 'color': '#000'}"
                                                    [icon]="faChevronDown" size="lg"></fa-icon>
                                                <fa-icon *ngIf="cheveron7Show" (click)="cheveron7Show = !cheveron7Show"
                                                    class="chevron-icon" [styles]="{'stroke': '#000', 'color': '#000'}"
                                                    [icon]="faChevronUp" size="lg"></fa-icon>
                                            </ng-container>
                                        </div>
                                        <div *ngIf="SelMember" [hidden]="!cheveron7Show" class="col-md-8">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <p class="suggested-ques">Question :
                                                        {{candidateAverageData.init_qualify_ques_7_ques || ''}}</p>
                                                </div>
                                                <div class="col-sm-12 mt-3">
                                                    <p class="suggested-ans">Answer :
                                                        {{candidateAverageData.init_qualify_ques_7_answer || ''}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li *ngIf="SelCandidate?.init_qualify_ques_8 !== ''">
                                    <div class="row">
                                        <div class="text-center" [ngClass]="SelMember ? 'col-md-3': 'col-md-12'">
                                            <span class="scroll_point">{{candidateAverageData?.init_qualify_ques_8 |
                                                number : '1.1-1'}}</span>
                                            <span class="scroll_name">{{SelCandidate?.init_qualify_ques_8}}</span>
                                            <ng-container *ngIf="SelMember">
                                                <fa-icon
                                                    *ngIf="!cheveron8Show && candidateAverageData.init_qualify_ques_suggestion_8 !== ''"
                                                    (click)="cheveron8Show = !cheveron8Show" class="chevron-icon"
                                                    [styles]="{'stroke': '#000', 'color': '#000'}"
                                                    [icon]="faChevronDown" size="lg"></fa-icon>
                                                <fa-icon *ngIf="cheveron8Show" (click)="cheveron8Show = !cheveron8Show"
                                                    class="chevron-icon" [styles]="{'stroke': '#000', 'color': '#000'}"
                                                    [icon]="faChevronUp" size="lg"></fa-icon>
                                            </ng-container>
                                        </div>
                                        <div *ngIf="SelMember" [hidden]="!cheveron8Show" class="col-md-8">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <p class="suggested-ques">Question :
                                                        {{candidateAverageData.init_qualify_ques_8_ques || ''}}</p>
                                                </div>
                                                <div class="col-sm-12 mt-3">
                                                    <p class="suggested-ans">Answer :
                                                        {{candidateAverageData.init_qualify_ques_8_answer || ''}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li *ngIf="SelCandidate && SelMember">
                                    <div class="row">
                                        <div class="col-sm-12 p-5">
                                            <label>Comments / Feedback</label>
                                            <textarea readonly class="form-control"
                                                [value]="candidateScore?.feedback"></textarea>
                                        </div>
                                    </div>
                                </li>
                            </ng-container>
                        </ul>
                        <div *ngIf="selectedHtmsList.length > 1">
                            <b class="pl-3">{{SelCandidate?.name}} {{SelCandidate?.last_name}}</b>
                            <google-chart [data]="barChartData" (chartSelect)='chartSelect($event)'></google-chart>
                        </div>
                        <div *ngIf="noData" class="px-4">
                            {{SelMember.name}} has not interviewed {{SelCandidate.name}} yet!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-candidate-summary (nameEvent)=showProgressEvent($event) (activeCandidate)="selectCandidate($event)"
        *ngIf="!showSummary && openPositionAvailable && !showDashboard"></app-candidate-summary>
</div>
<div class="widger-wrapper" *ngIf="showDashboard">
    <app-sub-dashboard></app-sub-dashboard>
</div>


<ng-template #modalRoot let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Question and Comments</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Question Prompt: <b>{{selectedQuesData?.quesFor}}</b></p>
        <p>{{selectedQuesData?.quesText}}</p>
        <div *ngFor="let comment of selectedQuesData.comments | keyvalue">
            <p>Comments: <b>{{comment.key}}</b></p>
            <p>{{comment.value || 'No Comments given'}}</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
    </div>
</ng-template>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Trash Position</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Do you want to trash this Position?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="trashPosition()">Yes</button>
    </div>
</ng-template>