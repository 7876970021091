import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.services';
import { UserService } from 'src/app/services/user.service';
import { ToastService } from '../toast/toast-service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      password: ['', Validators.required],
      confPassword: ['', Validators.required]
    }, {validator: this.passwordMatchValidator});
  }

  passwordMatchValidator(frm: FormGroup) {
    return frm.controls['password'].value === frm.controls['confPassword'].value ? null : {'mismatch': true};
  }

  resetPassword() {
    const payload = {
      password: this.userService.encryptPassword(this.resetPasswordForm.value.password)
    }
    
    this.activatedRoute.queryParams.subscribe(params => {
      const uidb64 = params['uidb64'];
      const token = params['token'];
      this.authService.forgotPassword(payload, token, uidb64).subscribe(res => {
        this.showSuccess('Password changed successfully, please your new password to login');
        this.router.navigate(['login']);
      });
    });
    
  }

  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 3000,
    });
  }

  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

}
