import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.services';
import {
  faCoffee,
  faPen,
  faPlus,
  faSearch,
  faTrash,
  faLightbulb,
  faTrashRestore,
  faClone,
  faArchive,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import { faFile, faFileAlt, faPauseCircle, faSave, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Client } from 'src/app/models/dashboard.interface';
import * as Url from './../../constants/url';
import { Router } from '@angular/router';
import { CandidateService } from 'src/app/services/candidate.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PositionService } from 'src/app/services/position.service';
import { ToastService } from '../toast/toast-service';
import { ClientService } from 'src/app/services/client.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  public positionList: any[] = [];
  public clientList: Client[] = [];
  currentUserRoleCode: any;
  currentClient: any;
  userRole: any;
  isshowSearch = false;
  query: any;
  faFile = faFile;
  faFileAlt = faFileAlt;
  faPauseCircle = faPauseCircle;
  faSave = faSave;
  faTrashAlt = faTrashAlt;
  faCoffee = faCoffee;
  faPen = faPen;
  faPlus = faPlus;
  faSearch = faSearch;
  faTrash = faTrash;
  faLightbulb = faLightbulb;
  faTrashRestore = faTrashRestore;
  faClone = faClone;
  faEdit = faPen;
  faDelete = faTrash;
  firstClient: any;
  logo: any;
  selected: any;
  draftedPositionList: any;
  archivedPositionList: any;
  selectedClientId: any;
  protipData: any;
  faArchive = faArchive;
  faExclamationCircle = faExclamationCircle;
  requestedPositionList: any;
  completedPositionList: any;
  trashedPositionList: any;
  activeIds = ['openPositions'];
  isHtmAvailable: boolean=true;
  clientPositionData: any=[];
  constructor(
    private authService: AuthService,
    private dashboardsService: DashboardService,
    private router: Router,
    private candidatesService: CandidateService,
    private modalService: NgbModal,
    private positionService: PositionService,
    private toastService: ToastService,
    private clientservice: ClientService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    let uerdata:any = localStorage.getItem('currentUser');
    uerdata = JSON.parse(uerdata);
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    this.currentClient = uerdata?.client_id;
    if(uerdata) {
      this.userRole = uerdata?.role;
    }
    this.getClientList();
  }

  setProTipData(data: any) {
    if(!data) {
      data = JSON.parse(localStorage.getItem('protip') || '');
    }
    const currentRoute: any = this.router.url;
    const protipData = 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.';
    switch(true) {
      case (currentRoute.indexOf('dashboard') !== -1):
        this.protipData = data.dashboard_protip || protipData;
        break;
      case(currentRoute.indexOf('settings') !== -1):
        this.protipData = data.settings_protip || protipData;
        break;

      default:
        this.protipData = protipData;
        break;

    }
  }

  showSearch() {
    this.isshowSearch = !this.isshowSearch;
  }

  getClientList(isDuplicateOrDelete?:any) {
    this.dashboardsService.getClientList().subscribe((val: any) => {
      if (val) {
        if((this.userRole !=='Account Manager') && (this.userRole !=='Superuser') && (this.userRole !=='Candidate') ){
            this.dashboardsService.getSelectClientData(this.currentClient).subscribe((el:any)=>{
              this.clientPositionData[0] = el.data;
              this.clientList = this.clientPositionData
            //this.clientList = val.clients;
            if (this.clientList?.length > 0) {
              this.firstClient = this.clientList[0];
              localStorage.setItem('activeClient', val.active_clients);
              localStorage.setItem('openPosition', val.total_open_positions);
              this.isActive(this.firstClient);
              this.logo = Url.imgBaseUrl + this.firstClient.logo;
              if(isDuplicateOrDelete) {
                this.collectPositionFromClients(true);
              } else {
                this.collectPositionFromClients();
              }
            }

          })
      }
      else{
            this.clientList = val.clients;
          if (this.clientList?.length > 0) {
            this.firstClient = this.clientList[0];
            localStorage.setItem('activeClient', val.active_clients);
            localStorage.setItem('openPosition', val.total_open_positions);
            this.isActive(this.firstClient);
            this.logo = Url.imgBaseUrl + this.firstClient.logo;
            if(isDuplicateOrDelete) {
              this.collectPositionFromClients(true);
            } else {
              this.collectPositionFromClients();
            }
          }
        }
      }
    });
  }

  isActive(item: any) {
    return this.selected === item;
  }

  collectPositionFromClients(isDuplicateOrDelete?:any) {
    this.clientList.map((el: any) => {
      if (this.currentClient == el.id) {
        if (el.open_position_data.length > 0) {
          if(isDuplicateOrDelete) {
            this.positionList[0] = el.open_position_data;
          } else {
            this.positionList.push(el.open_position_data);
          }
          // this.positionList = el.open_position_data;
        }
        this.draftedPositionList = el.drafted_position_data;
        this.archivedPositionList = el.archieved_position_data;
        this.completedPositionList = el.completed_position_data;
        this.trashedPositionList = el.trashed_position_data;
      } else if((this.userRole === 'Hiring Manager') || (this.userRole === 'Hiring Member') || (this.userRole === 'Client Admin') || this.userRole === 'Human Resource'){
        if(isDuplicateOrDelete) {
          this.positionList[0] = el.open_position_data;
        } else {
          this.positionList.push(el.open_position_data);
        }
      }
    });
    if(this.userRole === 'Candidate') {
      this.positionList[0] = [];
      this.clientList.forEach(el => {
        el.open_position_data?.forEach(pos => {
          this.positionList[0].push(pos);
        });
      });
      this.requestedPositionList = [];
      this.clientList.forEach((el1: any) => {
        el1.requested_positions?.forEach((pos: any) => {
          this.requestedPositionList.push(pos);
        });
      });
      this.positionService.saveRequestedPositionList(this.requestedPositionList);
      // this.select(this.positionList[0][0]);
    }
    if (this.positionList.length > 0) { 
      this.firstClient = this.positionList[0][0];
      // this.select(this.firstClient);
      if(this.userRole === 'Senior Manager' || this.userRole === 'Hiring Manager' || this.userRole === 'Hiring Member') {
        if(this.router.url.indexOf('/candidateProfile/') === -1) {
        // this.select(this.firstClient);
        this.candidatesService.setSelectedCandidate(this.firstClient);
        }
      }
      if(this.userRole === 'Human Resource') {
        this.select(this.firstClient);
        this.candidatesService.setSelectedCandidate(this.firstClient);
      }
      this.isActive(this.firstClient);
    }
  }
  selectedPosition: any;
  select(item: any, e?: any, isClient?: any, isPendingCandidateApproval?:any) {
    this.isHtmAvailable = item.team_exists;
    if(e && e?.target?.parentElement?.classList?.toString()?.indexOf('menu-button-cntr') > -1) {
      return;
    }
    if(this.userRole === 'Candidate') {
      this.router.navigate([this.currentUserRoleCode + '/dashboard/details']);
      this.selectedPosition = item.id;
      if(isPendingCandidateApproval) {
        item.isPendingCandidateApproval = true;
      }
      const clientName = this.clientList.filter((el: any) => el.id === item.client)[0]?.company_name;
      const clientLogo = this.clientList.filter((el: any) => el.id === item.client)[0]?.logo;
      const activeClientDetails = {clientName: clientName, clientLogo: clientLogo};
      localStorage.setItem('activeClientDetails', JSON.stringify(activeClientDetails));
      this.candidatesService.setSelectedCandidate(item);
      return;
    }
    if (item === undefined){
      this.logo = Url.imgBaseUrl + this.firstClient.logo;
    }
    if(item?.position_title) {
      localStorage.setItem('currentPosition', item?.id);
      localStorage.setItem('currentPositionName', item?.position_title);
    } else {
      if(item?.open_position_data?.length) {
        localStorage.setItem('currentPosition', item?.open_position_data[0]?.id);
        localStorage.setItem('currentPositionName', item?.open_position_data[0]?.position_title);
      } else {
        localStorage.removeItem('currentPosition');
        localStorage.removeItem('currentPositionName');
      }
    }
    if(this.currentClient) {
      localStorage.setItem('clientId', this.currentClient);
    } else {
      localStorage.setItem('clientId', item.client || item.id);
    }
    localStorage.setItem('useitOnceCandidateassociated', JSON.stringify(item));
    if(!isClient) {
      if(item?.open_position_data) {
        this.candidatesService.setSelectedCandidate(item);
      } else {
        if(this.userRole === 'Superuser') {
          this.candidatesService.setSelectedCandidate(this.clientList?.filter((el: any) => el.id == this.selected?.client)[0]);
        } else {
          this.candidatesService.setSelectedCandidate(this.clientList[0]);
        }
      }
    }
    this.router.navigate([this.authService.currentUserRoleCode + '/position']);
  }

  editClient(id: any) {
    localStorage.setItem('ediClientId', id);
    this.router.navigate([this.authService.currentUserRoleCode + '/dashboard/addClient']);
  }

  openSm(content: any, id: any) {
    this.selectedClientId = id;
    this.modalService.open(content, { size: 'md' });
  }

  createPosition(e: any, htmModal:any){
    if(this.isHtmAvailable){
      e.stopPropagation();
      localStorage.removeItem('editPosition');
      this.router.navigate([this.authService.currentUserRoleCode + '/position/createPosition1']);
    }
    else{
      this.modalService.open(htmModal, { size: 'sm' });
    }
  }
  moveToHtmPage(){
    this.modalService.dismissAll();
    this.router.navigate([this.authService.currentUserRoleCode + '/dashboard/hiringDepartment']);
  }

  editPosition(id: any, hiringGroupId: any) {
    this.selectedPosition = id;
    localStorage.setItem('clientId', this.currentClient);
    localStorage.setItem('editPosition', id);
    localStorage.setItem('hiringGroupId', hiringGroupId);
    // setTimeout(() => {
      this.router.navigate([this.authService.currentUserRoleCode + '/position/createPosition1']);
    // }, 500);
  }

  restoreOpenPosition(op_id: any) {
    this.positionService.restorePosition(op_id).subscribe(res => {
      this.toastService.show('Position restored successfully.', {
        classname: 'bg-success text-light',
        delay: 5000,
      });
    });
    this.modalService.dismissAll();
    this.getClientList();
  }

  duplicatePosition(id: any) {
    this.positionService.duplicatePosition(id).subscribe((res: any) => {
      if (res.msg === 'success') {
        this.getClientList();
        this.showCloneSuccess();
        this.modalService.dismissAll();
        localStorage.setItem('editPosition', res.id);
        this.selectedPosition = res.id;
        this.activeIds = [];
        this.activeIds = ['draftPosition'];
        this.router.navigate([this.authService.currentUserRoleCode + '/position/createPosition1'], { queryParams: {id: res.id}});
      }
    });
  }

  showCloneSuccess() {
    this.toastService.show('Position added in Draft Positions.', {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  deleteClient(id?: any) {
    if (this.selectedClientId > 0) {
      this.clientservice
        .deleteClient(this.selectedClientId)
        .subscribe((client: any) => {
          if (client) {
            this.modalService.dismissAll();
            alert('Client deleted successfully');
            this.router.navigate([this.authService.currentUserRoleCode + '/dashboard/details']);
            // location.reload();
          }
        });
    }
  }

  archivePosition() {
    const openPositionId = localStorage.getItem('currentPosition');
    this.positionService.archivePosition(openPositionId).subscribe(res => {
      this.toastService.show('Position marked as on hold successfully.', {
        classname: 'bg-success text-light',
        delay: 5000,
      });
      window.location.reload();
    });
  }

  goToAuditDashboard(item: any) {
    this.selected = item;
    localStorage.setItem('currentPosition', item?.id);
    localStorage.setItem('currentPositionName', item?.position_title);
    this.candidatesService.setSelectedCandidate(item);
    this.candidatesService.setSelectedCompletedPosition(item);
    this.router.navigate([this.authService.currentUserRoleCode + '/position/audit-dashboard']);
  }

  trashPosition() {
    this.positionService.trashPosition(this.selectedClientId, true).subscribe((client) => {
      if (client) {
        this.showSuccess('Position trashed successfully.');
        this.modalService.dismissAll();
        this.getClientList(true);
      }
    });
  }

  deletePosition() {
    this.positionService.deletePosition(this.selectedClientId).subscribe((client) => {
      if (client) {
        this.showSuccess('Position permanently deleted successfully.');
        this.modalService.dismissAll();
        this.getClientList(true);
      }
    });
  }

  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  restoreTrashedPosition(id: any) {
    this.positionService.trashPosition(id, false).subscribe((client) => {
      if (client) {
        this.showSuccess('Position restored successfully.');
        this.modalService.dismissAll();
        this.getClientList(true);
      }
    });
  }
  
}

