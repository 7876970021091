import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from 'src/app/services/client.service';

import { AssociateCandidateComponent } from '../associate-candidate/associate-candidate.component';
import { SetAvailabilityComponent } from '../set-availability/set-availability.component';
import * as Url from './../../constants/url';
import { ToastService } from '../../core/toast/toast-service';
import { AuthService } from 'src/app/services/auth.services';
import { UserService } from 'src/app/services/user.service';
import { HiringTeamService } from 'src/app/services/hiringteam.service';
import { Observable } from 'rxjs';
// import {noSpacesValidator} from 'src/app/features/inputField.validation';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css'],
})
export class ClientComponent implements OnInit, OnDestroy {
  public passwordForm!: FormGroup;
  public clientForm!: FormGroup;
  public clientId: any;
  public showUpdateBtn: boolean = false;
  public userRole: string = '';
  public faPlus = faPlus;
  public faCalendar = faCalendarAlt;
  public imageURL: string = '';
  caProfilePic: string = '';
  currentUserRoleCode: any;
  @Input() isSettingsPage: any;
  userName: any;
  showError = false;
  showPassword = false;
  isUppercaseValid: boolean = false;
  isLowecaseValid: boolean = false;
  isNumberValid : boolean= false;
  isSymbolValid: boolean = false;
  initialFormValue: any;
  packageData: any;
  showExpiry: boolean = false;
  formHasError: boolean = false;
  passSubmitted: boolean = false;
  constructor(
    private clientService: ClientService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private router: Router, private modalService: NgbModal,
    private authService: AuthService,
    private userService: UserService,
    private hiringTeamService: HiringTeamService
  ) {}

  // ngDoCheck(){ this.clientId = localStorage.getItem("ediClientId"); console.log("client", this.clientId)}

  ngOnInit(): void {
    let uerdata: any = localStorage.getItem('currentUser');
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    uerdata = JSON.parse(uerdata);
    if (uerdata) {
      this.userRole = uerdata?.role;
    }
    this.createClientForm();
    this.getpackageDetails();
    this.clientId = localStorage.getItem('ediClientId');
    if(this.isSettingsPage) {
      this.clientId = uerdata.client_id;
    }
    this.createpForm();
    setTimeout(() => {
      if (this.clientId !== null) {
        this.getClientDetails(this.clientId);
        this.showUpdateBtn = true;
      }
    }, 2000);
  }

  getpackageDetails(){
    this.userService.getPackageDetails().subscribe((res:any)=>{
      this.packageData = res.data
      this.packageData.sort(compare)
      function compare(a:any,b:any){
        return a.id - b.id
      }
      const trial={
        id:0,
        name:'Trial',
        price:'0'
      }
      this.packageData.unshift(trial)
     
    })
  }

  createpForm() {
    this.passwordForm = this.fb.group({
      username:[''],
      new_password:['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[\/~`!@#$%^&*()_+\-=\[\]{}|\\:;"'<>,.?\//])(?!.*\s)/),
      Validators.minLength(8),Validators.maxLength(16)]],
      re_password:['', Validators.required],
    });
  }

  openforPassword(password: any) {  
    this.modalService.open(password, { size: 'md' });
  }

  changePassword() {
    // Encrypt Password
    this.passSubmitted = true;
    this.modalService.dismissAll();
    this.passwordForm.controls.new_password.setValue(this.userService.encryptPassword(this.passwordForm.value.new_password));
    let payload = this.passwordForm.value;
    payload['username'] = this.userName;
    delete payload['re_password'];
    this.hiringTeamService.changePassword(payload).subscribe(value => {
      if (value) { 
        this.passSubmitted = false
        this.showSuccess('Password changed Successfully');
        
        this.passwordForm.reset();
      } else {
        this.showDanger('Change Password failed, please try again.');
      }
    })
  }

  checkPasswords() {
    let pass = this.passwordForm.controls['new_password'].value;
    let confirmPass = this.passwordForm.controls['re_password'].value;
    if(pass === confirmPass) {
      this.showError = false;
    } else {
      this.showError = true;
    }
    
  }

  getClientDetails(id: any) {
    this.clientService.getClientbyId(id).subscribe((data: any) => {
      if (data) {
        this.userName = data.client?.key_contact_email;
        this.setClientDatainForm(data.client);
      }
    });
  }

  setClientDatainForm(data: any) {
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        if(key ==='logo'){
          if(data[key]!=='None' && data[key]!=='' &&  data[key]){

            this.clientForm.controls['logo']?.setValue(data[key]);
            this.imageURL = Url.imgBaseUrl + data[key];
          }
          else{
            this.imageURL = '';
          }
        } else if(key ==='ca_profile_pic'){
          if(data[key]!=='None' && data[key]!==''  &&  data[key]){
            this.clientForm.controls['ca_profile_pic']?.setValue(data[key]);
            this.caProfilePic = Url.imgBaseUrl + data[key];
          }
          else{
            this.caProfilePic = '';
          }
        } 
        else{
          
          this.clientForm.controls[key]?.setValue(data[key]);
           if(key ==='package' && data[key] ==0){
              this.showExpiry = true;
           }
           if(key ==='trial_expired'){
            let d = this.clientForm.value.trial_expired.split("-");
            let newDate = d[1]+'-'+d[2]+'-'+d[0];
            this.clientForm.controls[key]?.setValue(newDate);
            this.clientForm.value
           }
        }
      }
    }
    this.clientForm.removeControl('key_password');
    this.clientForm.get("key_password")?.clearValidators();
    this.clientForm.get("key_password")?.updateValueAndValidity();
    this.initialFormValue = JSON.stringify(this.clientForm.value);
  }
  showPreview(event: any) {
    const reader = new FileReader();
    
    if(event.target.files && event.target.files.length) {
      // this.accountService.leavePage = true;
      const [file] = event.target.files;
      this.clientForm.controls['logo'].setValue(event.target.files[0]);
      reader.readAsDataURL(file);
      this.clientForm.controls.logo.patchValue(file);
      reader.onload = () => {
   
        this.imageURL = reader.result as string;
   
      };
   
    }
  }
  removeLogo(){
    this.clientForm.controls['logo'].setValue(null);
    this.imageURL= '';
    }
  

  showCAPreview(event: any) {
    const reader = new FileReader();
    
    if(event.target.files && event.target.files.length) {
      // this.accountService.leavePage = true;
      const [file] = event.target.files;
      this.clientForm.controls['ca_profile_pic'].setValue(event.target.files[0]);
      reader.readAsDataURL(file);
      this.clientForm.controls.ca_profile_pic.patchValue(file);
      reader.onload = () => {
   
        this.caProfilePic = reader.result as string;
   
      };
   
    }
  }
  removePhoto(){
    this.clientForm.controls['ca_profile_pic'].setValue(null);
    this.caProfilePic= '';
    }
  onPackageChange(e:any){
      console.log(e.target.value)
      if(e.target.value == 0){
        this.showExpiry = true;
        this.clientForm.get("trial_expired")?.addValidators([Validators.required])
        this.clientForm.get("trial_expired")?.updateValueAndValidity()
      }
      else{
        this.showExpiry = false;
        this.clientForm.controls['trial_expired'].setValue('');
        // this.clientForm.removeControl('trial_expired');
        this.clientForm.get("trial_expired")?.clearValidators();
        this.clientForm.get("trial_expired")?.updateValueAndValidity()
      }
    }

  createClientForm() {
    
    this.clientForm = this.fb.group({
      company_contact_full_name:['', Validators.required],
      company_contact_phone: [
        '',
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
      company_contact_email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
      package:['',Validators.required],
      trial_expired:['',Validators.required],
      company_name: ['', Validators.required],
      company_website: ['',Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')],
      company_linkedin: ['',Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*')],
      addr_line_1:['', Validators.required],
      addr_line_2:[''],
      city:['', Validators.required],
      state:['', Validators.required],
      pincode:['', Validators.required],
      ca_first_name: ['', [Validators.required,Validators.pattern('[a-zA-Z]*$')]],    
      ca_last_name: ['',Validators.required],
      key_contact_phone_no: [
        '',
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
      key_contact_skype_id: ['',Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*')],
      key_contact_email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
      // key_username: ['', Validators.required],
      key_password: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[\/~`!@#$%^&*()_+\-=\[\]{}|\\:;"'<>,.?\//])(?!.*\s)/),
      Validators.minLength(8),Validators.maxLength(16)]],
      ca_profile_pic: [null],
      job_title: ['', Validators.required],
      hr_first_name: ['',Validators.pattern('[a-zA-Z]*$')],
      hr_last_name: [''],
      hr_contact_phone_no: ['',Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      hr_contact_skype_id: ['',Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*')],
      hr_contact_email: ['',Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$')],
      hr_username: [''],
      hr_password: [''],
      cto_first_name: ['',Validators.pattern('[a-zA-Z]*$')],
      cto_last_name: [''],
      cto_phone_no: ['',Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      cto_skype_id: ['',Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*')],
      cto_email: ['',Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$')],
      cto_username: [''],
      cto_password: [''],
      billing_first_name: ['',Validators.pattern('[a-zA-Z]*$')],
      billing_last_name: [''],
      billing_phone_no: ['',Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      billing_email: ['',Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$')],
      billing_addr_line_1:[''],
      billing_addr_line_2:[''],
      billing_city:[''],
      billing_state:[''],
      billing_pincode:[''],
      special_req: [''],
      ae_assigned: [''],
      logo: [null],
      id: [0],
      name:'',
      open_positions: [0],
      disabled: [false],
    });
    this.initialFormValue = JSON.stringify(this.clientForm.value);
  }

  saveClient(data: any) {
    if(this.clientForm.invalid){
      this.formHasError = true;
      alert("Please check required form fields")
      return
    }
    this.formHasError = false;
    if(data.key_password) {
      // Encrypt Password
      data.key_password = this.userService.encryptPassword(data.key_password);
    } else {
      data.key_password =  null;
    }
    if(this.clientForm.value.trial_expired){
        let d = this.clientForm.value.trial_expired.split("-");
        let newDate = d[2]+'-'+d[0]+'-'+d[1];
        data.trial_expired = newDate;
    }
    this.clientService.createCLient(data).subscribe(
      (res) => {
        if (res) {
          this.initialFormValue = JSON.stringify(this.clientForm.value);
          this.showSuccess('Client created successfully.');
          this.router.navigate([this.authService.currentUserRoleCode + '/view-all-clients']).then(() => {
            window.location.reload();
          });;
        }
      },
      (error) => {
        this.showDanger(error);
        //alert('Error:' + error.error);
      }
    );
  }

  getUserProfile(userdata: any) {
    this.userService.getUserProfile(userdata.profile_id).subscribe((data: any)=> {
      if (data) {
        const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
        currentUser.profile_photo = data.profile_photo;
        currentUser.first_name = data.first_name;
        currentUser.last_name = data.last_name;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.userService.setuserDetailsUpdated(data);
      }
    });
  }

  updateClient() {
    if(this.clientForm.invalid){
      this.formHasError = true;
      alert("Please check required form fields")
      return
    }
    this.formHasError = false;
    if(this.clientForm.value.key_password) {
    // Encrypt Password
    this.clientForm.value.key_password = this.userService.encryptPassword(this.clientForm.value.key_password);
    } else {
      this.clientForm.value.key_password = null;
    }
    if(this.clientForm.value.trial_expired){
      let d = this.clientForm.value.trial_expired.split("-");
        let newDate = d[2]+'-'+d[0]+'-'+d[1];
      this.clientForm.controls['trial_expired'].setValue(newDate)
    }
    this.clientService.updateClient(this.clientForm.value).subscribe(
      (res: any) => {
        if (res) {
          const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
          this.getUserProfile(currentUser);
          this.initialFormValue = JSON.stringify(this.clientForm.value);
          this.showSuccess('Client Updated successfully.');
          this.router.navigate([this.authService.currentUserRoleCode + '/view-all-clients']);
        }
      },
      (error) => {
        this.showDanger('Error:' + error.error);
      }
    );
  }

  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

  cancel() {
    this.router.navigate([this.authService.currentUserRoleCode + '/dashboard']);
  }

  ngOnDestroy() {
    localStorage.removeItem('ediClientId');
  }
  openAssociateCandidate() {
    const modalRef = this.modalService.open(AssociateCandidateComponent);
    modalRef.componentInstance.name = 'associate';
  }

  openSetAvailability() {
    const modalRef = this.modalService.open(SetAvailabilityComponent, { size: 'lg' });
    modalRef.componentInstance.name = 'scheduler';
  }

  changeCAPassword() {

  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  
  onPasswordChange() {
    const enteredPassword = this.clientForm.controls.key_password.value || this.passwordForm.controls.new_password.value;
    if (enteredPassword) {
      this.isUppercaseValid = /[A-Z]/.test(enteredPassword);
      this.isLowecaseValid = /[a-z]/.test(enteredPassword);
      this.isNumberValid = /\d/.test(enteredPassword);
      this.isSymbolValid = /^(?=.*[A-Z])(?=.*[\/~`!@#$%^&*()_+\-=\[\]{}|\\:;"'<>,.?\//])(?!.*\s)/.test(enteredPassword);
      } else {
      this.isUppercaseValid = false;
      this.isLowecaseValid = false;
      this.isNumberValid = false;
      this.isSymbolValid = false;
    }
  }
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if(this.initialFormValue !== JSON.stringify(this.clientForm.value)){
      return confirm('You have unsaved changes. Do you really want to leave?');
    }
    return true;
  }
}
