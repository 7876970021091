<!-- <app-navbar></app-navbar> -->
<main>
    <!-- <app-header></app-header> -->
    <div class="widget-wrapper container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="widget-col">
                    <div class="widget-box h-100">
                        <div class="widget-innerbox">
                            <div class="form-section">
                                <h2 class="widget-title">All Users
                                    <!-- <a class="add-user" href="javascript:void(0)" [routerLink]="[currentUserRoleCode + '/create-user']">
                                        <fa-icon [icon]="faPlus" class="fa fa-plus"></fa-icon>
                                    </a> -->
                                </h2>
                                <div class="row m-0">
                                    <div class="search col-sm-12 col-xl-4 p-0">
                                        <i class="fa fa-search"></i>
                                        <input type="text" class="form-control" [(ngModel)]="searchKey"
                                            placeholder="Search user by name, username, job title...">
                                        <fa-icon *ngIf="searchKey" [icon]="faTimes"
                                            (click)="searchKey = ''; searchUser()"></fa-icon>
                                        <button class="btn btn-primary" (click)="searchUser()">Search</button>
                                    </div>
                                    <div class="search-buttons col-sm-12 col-xl-8 p-0">
                                        <button class="add-user btn btn-primary ml-3 h-100"
                                            [routerLink]="[currentUserRoleCode + '/create-user']"><fa-icon
                                                [icon]="faPlus" class="fa fa-plus"></fa-icon></button>
                                        <button class="btn btn-primary ml-3 h-100"
                                            [ngClass]="{'selected': searchFilter.indexOf('is_ca') > -1}"
                                            (click)="filterSearch('is_ca')">Administrators</button>
                                        <button class="btn btn-primary ml-3 h-100"
                                            [ngClass]="{'selected': searchFilter.indexOf('is_sm') > -1}"
                                            (click)="filterSearch('is_sm')">Senior Managers</button>
                                        <button class="btn btn-primary ml-3 h-100"
                                            [ngClass]="{'selected': searchFilter.indexOf('is_hm') > -1}"
                                            (click)="filterSearch('is_hm')">Hiring Managers</button>
                                        <button class="btn btn-primary ml-3 h-100"
                                            [ngClass]="{'selected': searchFilter.indexOf('is_htm') > -1}"
                                            (click)="filterSearch('is_htm')">Hiring Team Members</button>
                                        <button class="btn btn-primary ml-3 h-100"
                                            [ngClass]="{'selected': searchFilter.indexOf('is_tc') > -1}"
                                            (click)="filterSearch('is_tc')">Contributors</button>
                                    </div>
                                </div>
                                <p class="users-count text-right" *ngIf="allUsers?.length">Showing {{allUsers?.length}}
                                    users</p>

                                <div class="row mt-5">
                                    <div class="col-lg-4 col-md-6 col-sm-12 mb-3"
                                        *ngFor="let user of allUsers | keyvalue; let i = index">
                                        <ngb-accordion #acc="ngbAccordion">
                                            <ngb-panel title="{{user.key}} {{user.value?.length === 0 ? '(Empty)': ''}}"
                                                id="{{user.key}}">
                                                <ng-template ngbPanelContent>
                                                    <ng-container *ngFor="let item of user.value, let i= index">
                                                        <div class="card">
                                                            <a class="user-card" href="javascript:void(0)">
                                                                <figure><img class="candidate-profile-pic"
                                                                        [src]="item.profile_photo && item.profile_photo !== 'None' && item.profile_photo !== 'null' ? imageBaseUrl + item.profile_photo : './../../assets/images/big-default-avatar.png'" />
                                                                </figure>
                                                                <span class="user-info">
                                                                    <span class="name"
                                                                        *ngIf="userRole === 'Superuser' || userRole === 'Account Manager'">Company
                                                                        Name: {{item.client_names}}</span>
                                                                    <span class="name">Name: {{item.first_name}}
                                                                        {{item.last_name}}</span>
                                                                    <span class="name-role">Email: {{item.email ||
                                                                        'N/A'}}</span>
                                                                    <span class="name-role">Job Title: {{item.job_title
                                                                        || 'N/A'}}</span>
                                                                    <span class="name-role">Phone: {{item.phone_number
                                                                        || 'N/A'}}</span>
                                                                    <span class="name-role">Role(s):
                                                                        <ul>
                                                                            <li *ngFor="let role of item.roles"> {{role
                                                                                === 'is_htm' ? 'Hiring Team Member' :
                                                                                role === 'is_sm' ? 'Senior Manager' :
                                                                                role === 'is_ca' ? 'Client Admin' : ''}}
                                                                            </li>
                                                                        </ul>
                                                                    </span>
                                                                </span>
                                                                <span *ngIf="userRole !='Hiring Manager'">
                                                                    <fa-icon [id]="'edit-users-'+item.id"
                                                                        class="edit-user"
                                                                        [hidden]="(userRole =='Senior Manager' || userRole =='Hiring Manager') && (item.roles.indexOf('is_sm') > -1 || item.roles.indexOf('is_ca') > -1 )"
                                                                        [icon]="faEdit"
                                                                        (click)="editUser(item)"></fa-icon>
                                                                    <span *ngIf="!(item.roles.indexOf('is_ca') > -1 )">
                                                                        <fa-icon id="delete-users" class="delete-user"
                                                                            [hidden]="(userRole =='Senior Manager' || userRole =='Hiring Manager') && (item.roles.indexOf('is_sm') > -1 || item.roles.indexOf('is_ca') > -1 )"
                                                                            [icon]="faTrash"
                                                                            (click)="openSm(content, item)"></fa-icon></span>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </ng-container>
                                                </ng-template>
                                            </ngb-panel>
                                        </ngb-accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>




<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Delete User</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Are you want to delete {{currentUser?.first_name}} {{currentUser?.last_name}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="deleteUser()">Yes</button>
    </div>
</ng-template>