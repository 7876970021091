<div class="modal-header">
    <h4 class="modal-title" *ngIf="type !== 'open-position'">Submit Candidate to this Open Position</h4>
    <h4 class="modal-title" *ngIf="type === 'open-position'">Submit Candidate to an Open Position</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="associateForm">
        <div class="form-group row">
            <div class="col-12 col-md-6">
                <div class="row" *ngIf="type !== 'open-position'">
                    <label for="input-jobtitle" class="col-sm-4 col-form-label">Candidate Name</label>
                    <!-- <select class="col-sm-8 form-control" formControlName="candidate_id" (change)="selectCandidate($event)">
                        <option value="" selected>Please select</option>
                        <option *ngFor="let item of candidateList" [value]="item.candidate_id">{{item.name}}</option>
                    </select> -->
                    <ng-template #rt let-r="result" let-t="term" >
                        <img [src]="r.flag" class="me-1" style="width: 50px; height: 50px;" />
                        <ngb-highlight [result]="r.name" [term]="t" [id]="'cand-'+r.id"></ngb-highlight>
                        <span style="float: right;margin-top: 12px;" *ngIf="r.associated">Associated</span>
                    </ng-template>
                    <input
                        id="typeahead-template"
                        type="text"
                        class="form-control col"
                        [(ngModel)]="model"
                        (selectItem)="selectCandidate($event)"
                        [ngbTypeahead]="search"
                        [resultTemplate]="rt"
                        [inputFormatter]="formatter"
                        [ngModelOptions]="{standalone: true}"
                    />
                </div>
                <div class="row" *ngIf="type === 'open-position'">
                    <label for="input-jobtitle" class="col-sm-4 col-form-label">Select Positions</label>
                    <select class="col-sm-8 form-control" (change)="selectPosition($event)">
                        <option value="" selected>Please select</option>
                        <option *ngFor="let item of candidateOpenPositions" [value]="item.id" [attr.client_id]="item.client_id">{{item.position_title}}</option>
                    </select>
                </div>
                <figure style="height: 80px; width: 80px;" *ngIf="selectedCandidate">
                    <img *ngIf="!selectedCandidate.profile_pic_url" class="candidate-profile-pic" [src]="selectedCandidate.profile_photo && selectedCandidate.profile_photo !== 'None' ? imageBaseUrl + selectedCandidate.profile_photo : './../../assets/images/big-default-avatar.png'" />
                    <img *ngIf="selectedCandidate.profile_pic_url" class="candidate-profile-pic" [src]="selectedCandidate.profile_pic_url" />
                </figure>
                <div class="row mt-3 candidate-data" *ngIf="selectedCandidate">
                    <div class="col-12 col-md-6 left-panel">
                        <h4>{{selectedCandidate.name || name}}</h4>
                        <div class="row">
                            <label for="input-jobtitle"  class="col-6 col-form-label">Nickname:</label>
                            <input class="col-6 form-control" id="cand-nickname" type="text" formControlName="nickname" />
                        </div>
                        <div class="row">
                            <label for="input-jobtitle"  class="col-6 col-form-label">Location:</label>
                            <input class="col-6 form-control" id="cand-location" type="text" formControlName="location" />
                        </div>
                        <div class="row">
                            <label for="input-jobtitle" class="col-6 col-form-label">Currently:</label>
                            <input class="col-6 form-control" id="cand-currently"  type="text" formControlName="currently" />
                        </div>
                        <div class="row">
                            <label for="input-jobtitle"  class="col-6 col-form-label">Work Authorization:</label>
                            <input class="col-6 form-control" id="cand-autorization" type="text" formControlName="work_auth" />
                        </div>
                        <div class="row">
                            <label for="input-jobtitle" class="col-6 col-form-label">Salary Requirements:</label>
                            <div class="input-group">
                                <select style="float: left;width: initial; width: 40px;" formControlName="currency">
                                    <option value="$">$</option>
                                    <option value="€">€</option>
                                    <option value="£">£</option>
                                    <option value="¥">¥</option>
                                    <option value="₣">₣</option>
                                    <option value="₹">₹</option>
                                  </select>
                                <input class="col-6 form-control" id="cand-salary" type="text" formControlName="salary_req" />
                            </div>
                        </div>
                        <div class="row">
                            <label style="width: 100% !important;max-width: 100%;" for="input-jobtitle" class="col-6 col-form-label">Desired work location:</label>
                            <div class="custom-control custom-checkbox pl-5">
                                <input type="checkbox" class="custom-control-input" id="customCheck1" formControlName="remote_only">
                                <label class="custom-control-label" for="customCheck1">Remote only</label>
                            </div>
                            <div class="custom-control custom-checkbox pl-5">
                                <input type="checkbox" class="custom-control-input" id="customCheck2" formControlName="remote_pref">
                                <label class="custom-control-label" for="customCheck2">Remote preferred</label>
                            </div>
                            <div class="custom-control custom-checkbox pl-5">
                                <input type="checkbox" class="custom-control-input" id="customCheck3" formControlName="some_in_office">
                                <label class="custom-control-label" for="customCheck3">Some in-office</label>
                            </div>
                            <div class="custom-control custom-checkbox pl-5">
                                <input type="checkbox" class="custom-control-input" id="customCheck4" formControlName="office_only">
                                <label class="custom-control-label" for="customCheck4">Office only</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 pl-5 right-panel">
                        <div class="row">
                            <label for="input-jobtitle" class="col-6 col-form-label">
                                <fa-icon [icon]="faEnvelope"></fa-icon>
                            </label>
                            <input class="form-control" id="cand-email" type="email" formControlName="email" />
                        </div>
                        <div class="row">
                            <label for="input-jobtitle" class="col-6 col-form-label">
                                <fa-icon [icon]="faPhoneAlt"></fa-icon>
                            </label>
                            <input class="form-control" id="cand-phone" type="email" formControlName="phone" />
                        </div>
                        <div class="row">
                            <label for="input-jobtitle" class="col-6 col-form-label">
                                <img src="./../../assets/images/linkedin.svg" style="height: 30px;background: #fff;"/>
                            </label>
                            <input class="form-control" id="cand-linkedin" type="email" formControlName="linkedin" />
                        </div>
                        <div class="row border-dashed">
                            <label for="input-jobtitle" class="col-6 col-form-label">
                                <fa-icon [icon]="faFile"></fa-icon>
                            </label>
                            <label for="input-jobtitle" class="col-6 col-form-label pt-3">
                                Resume
                            </label>
                            <label for="input-jobtitle" class="col-6 col-form-label">
                                <fa-icon [icon]="faUpload"></fa-icon>
                            </label>
                            <input type="file" (change)="onResumeChange($event)" formControlName="resume"  multiple="multiple" style="width: 250px; float: left;" class="form-control-file">
                        </div>
                        <div class="row border-dashed">
                            <label for="input-jobtitle" class="col-6 col-form-label">
                                <fa-icon [icon]="faFile"></fa-icon>
                            </label>
                            <label for="input-jobtitle" class="col-6 col-form-label pt-3">
                                References
                            </label>
                            <label for="input-jobtitle" class="col-6 col-form-label">
                                <fa-icon [icon]="faUpload"></fa-icon>
                            </label>
                            <input type="file" (change)="onReferenceChange($event)" formControlName="references"  multiple="multiple" style="width: 250px; float: left;" class="form-control-file">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6" *ngIf="selectedCandidate">
                <textarea class="form-control w-100" placeholder="Your rationale for adding the candidate:" formControlName="generalComments" style="height: 100%;"></textarea>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="button" id="save-cand" class="btn btn-primary" [disabled]="associateForm.invalid"(click)="save()">Save</button>
    <!-- <button type="button" class="btn btn-primary" [disabled]="associateForm.invalid" *ngIf="type === 'open-position'" (click)="save()">Save</button> -->
</div>