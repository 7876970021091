import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/core/toast/toast-service';
import { AuthService } from 'src/app/services/auth.services';
import { DashboardService } from 'src/app/services/dashboard.service';
import { HiringTeamService } from 'src/app/services/hiringteam.service';
import { UserService } from 'src/app/services/user.service';
import * as Url from './../../constants/url';
import { AccountleveldataService } from 'src/app/services/accountleveldata.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  public userForm!: FormGroup;
  userProfilePic: any;
  currentUserRoleCode: any;
  userRole: any;
  clientList: any;
  isNewClient = false;
  userId: any;
  isEditUser = false;
  is_ca = false;
  is_sm = false;
  is_htm = false;
  is_already_htm = false;
  imageBaseUrl: string;
  passwordForm!: FormGroup;
  showError = false;
  showPassword :boolean= false;
  isUppercaseValid: boolean = false;
  isLowecaseValid: boolean = false;
  isNumberValid : boolean= false;
  isSymbolValid: boolean = false;
  showProfilePhoto :  boolean = false;
  @ViewChild('updateUser', { static: true }) accountLevelTemplate: TemplateRef<any> | undefined;
  htmData: any;
  htmCurrentData:any;
  initialFormValue: any;
  formHasErrors: boolean = false;
  
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private dashboardsService: DashboardService,
    private userService: UserService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private hiringTeamService: HiringTeamService,
    private accLevelServ: AccountleveldataService,
  ) {
    this.imageBaseUrl = Url.imgBaseUrl;
  }

  ngOnInit(): void {
    let uerdata: any = localStorage.getItem('currentUser');
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    uerdata = JSON.parse(uerdata);
    if (uerdata) {
      this.userRole = uerdata?.role;
    }
    this.userId = this.route.snapshot.queryParams['id'];
    if(this.userId) {
      this.isEditUser = true;
    }
    this.getClientList();
    this.createForm();
    if(this.userRole === 'Client Admin' || this.userRole === 'Senior Manager') {
      this.userForm.controls.client_id.setValue(uerdata.client_id);
    }
    if(this.isEditUser) {
      this.getUserById(this.userId);
    }
    
  }

  getClientList() {
    this.dashboardsService.getClientList().subscribe((val: any) => {
      if (val) {
        this.clientList = val.clients;
      }
    });
  }

  createForm() {
    this.userForm = this.fb.group({
      company_name: [''],
      company_website: ['',Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')],
      company_linkedin: ['',Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*')],
      first_name: ['', [Validators.required, Validators.pattern('[a-zA-Z]*$')]],
      last_name: ['', Validators.required],
      phone_number: [
        '',
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
      skype_id: ['',Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*')],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
      job_title: [''],
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[\/~`!@#$%^&*()_+\-=\[\]{}|\\:;"'<>,.?\//])(?!.*\s)/),
      Validators.minLength(8),Validators.maxLength(16)]],
      npassword:[''],
      profile_photo: [null],
      roles: [[], Validators.required],
      client_id: ['',Validators.required],
      profile_id:['']
    });
    this.initialFormValue = JSON.stringify(this.userForm.value);
  }

  showCAPreview(event: any) {
    const reader = new FileReader();
    
    if(event.target.files && event.target.files.length) {
      // this.accountService.leavePage = true;
      const [file] = event.target.files;
      this.userForm.controls['profile_photo'].setValue(event.target.files[0]);
      reader.readAsDataURL(file);
      this.userForm.controls.profile_photo.patchValue(file);
      reader.onload = () => {
   
        this.userProfilePic = reader.result as string;
   
      };
      this.showProfilePhoto = true;
    }
  }
  removePhoto(){
    this.userForm.controls['profile_photo'].setValue(null);
    this.showProfilePhoto = false;
  }

  onRoleChange(e: any, role: any) {

    if(e.target.checked) {
      this.userForm.controls.roles.value.push(role);
    } else {
      const updatedArr = this.userForm.controls.roles.value.filter((el: any) => el !== role);
      this.userForm.controls.roles.patchValue(updatedArr);
    }
    console.log(this.userForm.controls.roles.value);
    this.userForm.controls.roles.updateValueAndValidity();
    if(!this.isEditUser) {
      if(this.userForm.controls.roles.value.indexOf('is_ca') > -1) {
        this.is_ca = true;
        this.isNewClient = true;
        const event = {target: {checked: true}};
        this.onCreateClient(event);
      } else {
        this.is_ca = false;
        this.isNewClient = false;
        const event = {target: {checked: false}}
        this.onCreateClient(event);
      }
    }
    if(this.userForm.controls.roles.value.indexOf('is_sm') > -1) {
      this.is_sm = true;
    } else {
      this.is_sm = false;
    }

    if(this.userForm.controls.roles.value.indexOf('is_htm') > -1) {
      this.is_htm = true;
    } else {
      this.is_htm = false;
    }
  }

  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

  createUser() {
    if(this.userForm.invalid){
      alert("Please fill all the required fields")
      this.formHasErrors = true;
      return;
    }
    this.formHasErrors = false;
    this.userForm.controls['npassword'].setValue(this.userForm.value.password)
    if(this.userForm.value.password) {
      // Encrypt Password
      this.userForm.controls.password.patchValue(this.userService.encryptPassword(this.userForm.value.password));
    } else {
      this.userForm.controls.password.patchValue(null);
    }
    if(this.userRole == 'Hiring Manager'){
      this.userForm.controls['client_id'].setValue(this.clientList[0].id)
    }
    this.userService.createUser(this.userForm.value).subscribe((res: any) => {
      if(res.errMsg) {
        this.showDanger(res.errMsg);
        return;
      }
      this.showSuccess('User created successfully!');
      this.initialFormValue = JSON.stringify(this.userForm.value);
      this.router.navigate([this.authService.currentUserRoleCode + '/dashboard/all-users']);
    }, error => {
      this.showDanger('Something went wrong!');
    });
  }

  onCreateClient(e: any) {
    // this.userForm.controls.client_id.patchValue('');
    this.userForm.controls.company_name.patchValue('');
    this.userForm.controls.company_website.patchValue('');
    this.userForm.controls.company_linkedin.patchValue('');
    if(e.target.checked) {
      this.userForm.controls.client_id.clearValidators();
      this.userForm.controls.company_name.setValidators([Validators.required]);
    } else {
      this.userForm.controls.client_id.setValidators([Validators.required]);
      this.userForm.controls.company_name.clearValidators();
    }
    this.userForm.controls.client_id.updateValueAndValidity();
    this.userForm.controls.company_name.updateValueAndValidity();
  }


  getUserById(id: any) {
    this.userService.getUserById(id).subscribe((res: any) => {
      const userData = res?.data;
      this.userForm.patchValue(
        {
          company_name: userData.company_name || '',
          company_website: userData.company_website || '',
          company_linkedin: userData.company_linkedin || '',
          first_name: userData.first_name || '',
          last_name: userData.last_name || '',
          phone_number: userData.phone_number || '',
          skype_id: userData.skype_id || '',
          email: userData.email || '',
          job_title: userData.job_title || '',
          username: userData.username || '',
          password: userData.password || '',
          profile_photo: userData.profile_photo || '',
          // roles: [[], Validators.required],
          client_id: userData.client_id || ''
          
        }
      )
      if(userData.profile_photo!=='null'){
            this.showProfilePhoto = true;
      }
      else
        this.showProfilePhoto = false;
      this.userProfilePic = userData.profile_photo && userData.profile_photo!== 'None' ? this.imageBaseUrl + userData.profile_photo : null;
      this.preSelectUserRoles(userData);
      this.userForm.controls.password.clearValidators();
      this.userForm.controls.password.updateValueAndValidity();
      this.initialFormValue = JSON.stringify(this.userForm.value);
    });
  }

  preSelectUserRoles(user: any) {
    if(user.roles.indexOf('is_ca') > -1) {
      this.userForm.controls.roles.value.push('is_ca');
      this.is_ca = true;
    }
    if(user.roles.indexOf('is_sm') > -1) {
      this.userForm.controls.roles.value.push('is_sm');
      this.is_sm = true;
    }
    if(user.roles.indexOf('is_htm') > -1) {
      this.userForm.controls.roles.value.push('is_htm');
      this.is_htm = true;
      this.is_already_htm=true;
    }
    this.userForm.controls.roles.updateValueAndValidity();
  }

  // updateUser() {
  //   delete this.userForm.value.password;
  //   this.userService.updateUser(this.userForm.value).subscribe((res: any) => {
  //     if(res.errMsg) {
  //       this.showDanger(res.errMsg);
  //       return;
  //     }
  //     this.showSuccess('User updated successfully!');
  //     this.router.navigate([this.authService.currentUserRoleCode + '/all-users']);
  //   }, error => {
  //     this.showDanger('Something went wrong!');
  //   });
  // }


  // open-modal
  updateUser(accountLevel:any) {
    // currentuserId from queryparams
    if(this.userForm.invalid){
      alert("Please fill all the required fields")
      this.formHasErrors = true;
      return;
    }
    this.formHasErrors = false;
    this.route.queryParams.subscribe((params) => {
    const currentAccId = params.id;
    this.getAccLevelData(currentAccId);
    });

    if(this.is_already_htm){
      if(!this.is_htm){
      //const modalRef = this.modalService.open( accountLevel, { size: 'lg' });
          const modalRef = this.modalService.open( accountLevel, { size: 'lg' });
          modalRef.componentInstance.removeAccount = () => {
          modalRef.close('data-changed');
          
          }; 
      }
    }
    this.userForm.controls['profile_id'].setValue(this.userId);
    delete this.userForm.value.password;
    this.userService.updateUser(this.userForm.value).subscribe((res: any) => {
      if(res.errMsg) {
        this.showDanger(res.errMsg);
        return;
      }
      this.showSuccess('User updated successfully!');
      this.initialFormValue = JSON.stringify(this.userForm.value);
      this.router.navigate([this.authService.currentUserRoleCode + '/dashboard/all-users']);
    }, error => {
      this.showDanger('Something went wrong!');
    });
  
    //const modalRef = this.modalService.open( accountLevel, { size: 'lg' });
    // modalRef.componentInstance.removeAccount = () => {
    //   modalRef.close('data-changed');
    //   };
    // modalRef.result.then((result) => {
    //   if (result === 'data-changed') {
    //     delete this.userForm.value.password;
    //     this.userService.updateUser(this.userForm.value).subscribe((res: any) => {
    //       if (res.errMsg) {
    //         this.showDanger(res.errMsg);
    //         return;
    //       }
    //       this.showSuccess('User updated successfully!');
    //       this.router.navigate([this.authService.currentUserRoleCode + '/all-users']);
    //     }, error => {
    //       this.showDanger('Something went wrong!');
    //     });
    //   }
    // })
  }

  createpForm() {
    this.passwordForm = this.fb.group({
      username:[''],
      new_password:['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[\/~`!@#$%^&*()_+\-=\[\]{}|\\:;"'<>,.?\//])(?!.*\s)/),
      Validators.minLength(8),Validators.maxLength(16)]],
      re_password:['', Validators.required],
    })
  }

  openforPassword(password: any) {
    this.createpForm();
    this.modalService.open(password, { size: 'md' });
  }

  changePassword() {
    // Encrypt Password
    this.passwordForm.controls.new_password.setValue(this.userService.encryptPassword(this.passwordForm.value.new_password));
    
    let payload = this.passwordForm.value;    
    payload['username'] = this.userForm.value.username;
    delete payload['re_password'];
    this.hiringTeamService.changePassword(payload).subscribe((value: any) => {
      if (value) { 
        this.showSuccess('Password changed Successfully');
        this.modalService.dismissAll();
        this.passwordForm.reset();
      } else {
        this.showDanger('Change Password failed, please try again.');
      }
    })
  }

  checkPasswords() {
    let pass = this.passwordForm.controls['new_password'].value;
    let confirmPass = this.passwordForm.controls['re_password'].value;
    if(pass === confirmPass) {
      this.showError = false;
    } else {
      this.showError = true;
    }
    
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  
  onPasswordChange() {
    const enteredPassword = this.userForm.controls.password.value || this.passwordForm.controls.new_password.value;
    if (enteredPassword) {
      this.isUppercaseValid = /[A-Z]/.test(enteredPassword);
      this.isLowecaseValid = /[a-z]/.test(enteredPassword);
      this.isNumberValid = /\d/.test(enteredPassword);
      this.isSymbolValid = /^(?=.*[A-Z])(?=.*[\/~`!@#$%^&*()_+\-=\[\]{}|\\:;"'<>,.?\//])(?!.*\s)/.test(enteredPassword);
    } else {
      this.isUppercaseValid = false;
      this.isLowecaseValid = false;
      this.isNumberValid = false;
      this.isSymbolValid = false;
    }
  }
  
  getAccLevelData(accountLevelId:any){
    this.accLevelServ.getAccountData(accountLevelId).subscribe((data) => {
      
      this.htmData= data;
      this.htmCurrentData = this.htmData.data[0];
      console.log(this.htmData.data[0]);
    });
  }

  removeAccount(){
    this.route.queryParams.subscribe((params) => {
    const currentAccId = params.id;
    this.updatePositions(currentAccId);
    });

    delete this.userForm.value.password;
    this.userService.updateUser(this.userForm.value).subscribe((res: any) => {
      if(res.errMsg) {
        this.showDanger(res.errMsg);
        return;
      }
      this.showSuccess('User updated successfully!');
      this.initialFormValue = JSON.stringify(this.userForm.value);
      this.router.navigate([this.authService.currentUserRoleCode + '/all-users']);
    }, error => {
      this.showDanger('Something went wrong!');
    });
  }
  
  updatePositions(accountLevelId:any){
    
    const unselectedPositions = this.htmCurrentData.positions.filter((position: any) => position.active==false);
    const positionsToUpdate = unselectedPositions.map((position: any) => position.id);
    console.log(positionsToUpdate);
    
    this.accLevelServ.updateAccLevelData(accountLevelId,positionsToUpdate).subscribe((data:any) => {
        console.log("Updated Positions",data);
        if (data) { 
        this.showSuccess('Positions updated successfully')
        this.modalService.dismissAll();
      } else {
        this.showDanger('Change failed, please try again.');
      }
    });
    // alert('Positions Updated');
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    // if(this.initialFormValue !== JSON.stringify(this.userForm.value)){
    //   return confirm('You have unsaved changes. Do you really want to leave?');
    // }
    return true;
  }
  
}
