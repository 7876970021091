import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.services';
import { HiringTeamService } from 'src/app/services/hiringteam.service';
import { faPlusCircle, faMinusCircle, faLightbulb, faTrash, faEdit, faInfo } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { ClientService } from 'src/app/services/client.service';
import { ToastService } from 'src/app/core/toast/toast-service';
import * as Url from './../../constants/url';
import { DashboardService } from 'src/app/services/dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PositionService } from 'src/app/services/position.service';
import { AuthPayload } from 'src/app/models/auth.interface';
declare const callStripe: any;

// import { stringify } from 'querystring';

@Component({
  selector: 'app-first-login',
  templateUrl: './first-login.component.html',
  styleUrls: ['./first-login.component.css'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(1500, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class FirstLoginComponent implements OnInit {
  public passwordForm!: FormGroup;
  currentUserRoleCode: any;
  userRole: any;
  stepName = 'first';
  hiringTeamList: any[] = [];
  faPlus = faPlusCircle;
  faMinus = faMinusCircle;
  faLightbulb = faLightbulb;
  faDelete = faTrash;
  faEdit = faEdit;
  faInfo = faInfo;
  selectedHiringTeam: any;
  public imageURL: string = '';
  htmimageURL = '';
  public clientForm!: FormGroup;
  public packageDataForm!: FormGroup;
  public HTMemberForm!: FormGroup;
  public profileImageSizeExceed: any = false;
  currentUser: any;
  public imageBaseUrl: any;
  client_id: any;
  hiringTeamMemberCount = 0;
  isUpdateHtm = false;
  hiringTeams: any[] = [];
  hiringTeamForm!: FormGroup;
  memberList: any = {};
  formMembers: any[] = [];
  selectedClient: any;
  clientList: any[] = [];
  currentPositions: any = [];
  isCreateHiringTeam = false;
  caProfilePic: string = '';
  showError = false;
  clientSummary: any;
  isSeniorManagerCreated: any;
  seniorManagersList: any;
  showSMUpdateBtn = false;
  currentAccount: any = '';
  growthPackage: any = {
    id: '',
    price: '',
    users: '',
    CA: '',
    SM: '',
    HM: '',
    HTM: ''
  }
  starterPackage: any = {
    id: '',
    price: '',
    users: '',
    CA: '',
    SM: '',
    HM: '',
    HTM: ''
  }
  enterprisePackage: any = {
    id: '',
    price: '',
    users: '',
    CA: '',
    SM: '',
    HM: '',
    HTM: ''
  }
  @ViewChild('fileInput') fileInput: ElementRef = {} as ElementRef;
  billingInfoForm!: FormGroup;
  packageData: any;
  selectedPackage: any;
  companyName: any;
  companyWebsite: any;
  companyAdd: any;
  companyLinkedin: any;
  companyCity: any;
  companyState: any;
  companyZip: any;
  CA_name: any;
  CA_phone: any;
  CA_email: any;
  CA_linkedin: any;
  jobTitle: any;
  clientUsername: any;
  billingName: any;
  billingPhone: any;
  billingEmail: any;
  billingAddress: any;
  billingCity: any;
  billingState: any;
  billingzip: any;
  showPassword: boolean = false;
  isUppercaseValid: boolean = false;
  isLowecaseValid: boolean = false;
  isNumberValid: boolean = false;
  isSymbolValid: boolean = false;
  selectedPackagePrice: any;
  firstLoggedUser: boolean = false;
  private token: any;
  backToClient: boolean = false;
  // starterPackage: any;
  // enterprisePackage: any;
  constructor(
    private authService: AuthService,
    private hiringTeamService: HiringTeamService,
    private route: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private clientService: ClientService,
    private toastService: ToastService,
    private dashboardsService: DashboardService,
    private modalService: NgbModal,
    private positionService: PositionService
  ) { }

  ngOnInit(): void {
    this.imageBaseUrl = Url.imgBaseUrl;
    this.currentUserRoleCode = this.authService?.currentUserRoleCode;
    let userdata: any = localStorage.getItem('currentUser');
    userdata = JSON.parse(userdata);
    this.currentUser = userdata;
    this.client_id = userdata?.client_id;
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    this.createPackageForm();
    this.getpackageDetails();
    this.createClientForm();
    this.createpForm();
    this.createBillingInfoForm();
    if (userdata) {
      this.userRole = userdata?.role;
    }
    if (userdata?.payment_done) {
      this.route.navigate([this.authService.currentUserRoleCode + '/dashboard']);
    }
    else {
      if (userdata?.client_data) {
        this.populateClientData(userdata.client_id);
        this.stepName = 'second';
        this.firstLoggedUser = true;
      }
      // if(userdata?.billing_data){
      //   this.getBillingInfo(userdata.client_id)
      //   this.stepName = 'third';
      // }
    }

    // this.gethiringTeamList();
    // this.createSeniorManagerForm();
    // this.createForm();

    //this.populateClientData(userdata.client_id);
  }
  logOut() {
    this.authService.setUserLogin(false);
    this.authService.logout();
    this.route.navigate(['/login']);
    setTimeout(() => {
      window.location.reload();
    }, 500);
    // window.location.reload();
  }

  getSelectedPackageData(ids: any) {
    this.packageDataForm.controls['package_id'].setValue(ids)
    for (let i = 0; i < this.packageData.length; i++) {
      if (this.packageData[i].id == ids) {
        this.selectedPackage = this.packageData[i].name
        this.selectedPackagePrice = this.packageData[i].price
      }
    }
  }
  getBillingInfo(id: any) {
    this.userService.getBillingInfoForSA(id).subscribe((res: any) => {
      for (var key in res?.data) {
        if (res?.data.hasOwnProperty(key)) {
          this.billingInfoForm.controls[key]?.setValue(res?.data[key]);
        }
      }
      // this.billingInfoForm.controls['billing_contact'].setValue(this.companyContactForm.value.billing_first_name+ ' '+ this.companyContactForm.value.billing_last_name)
      console.log(this.billingInfoForm.value)
      this.showBillingDataSummary()
      //this.lastFourDigits ='**** **** **** '+ this.billingInfoForm.value.card_number.substr(-4)
    })
  }

  getpackageDetails() {
    this.userService.getPackageDetails().subscribe((res: any) => {
      this.packageData = res.data
      for (let i = 0; i < this.packageData.length; i++) {
        if (this.packageData[i].name == "Growth") {
          this.growthPackage.id = this.packageData[i].id
          this.growthPackage.price = this.packageData[i].price
          this.growthPackage.CA = "1";
          this.growthPackage.SM = this.packageData[i].senior_managers
          this.growthPackage.HM = this.packageData[i].hiring_managers
          this.growthPackage.HTM = this.packageData[i].hiring_team_members
          this.growthPackage.users = parseInt(this.growthPackage.SM) + parseInt(this.growthPackage.HTM) + parseInt(this.growthPackage.CA)
        }
        if (this.packageData[i].name == "Starter") {
          this.starterPackage.id = this.packageData[i].id
          this.starterPackage.price = this.packageData[i].price
          this.starterPackage.SM = this.packageData[i].senior_managers
          this.starterPackage.HM = this.packageData[i].hiring_managers
          this.starterPackage.HTM = this.packageData[i].hiring_team_members
          this.starterPackage.CA = "1";
          this.starterPackage.users = parseInt(this.starterPackage.SM) + parseInt(this.starterPackage.HTM) + parseInt(this.starterPackage.CA)
        }
        if (this.packageData[i].name == "Enterprise") {
          this.enterprisePackage.id = this.packageData[i].id
          this.enterprisePackage.price = this.packageData[i].price
          this.enterprisePackage.SM = this.packageData[i].senior_managers
          this.enterprisePackage.HM = this.packageData[i].hiring_managers
          this.enterprisePackage.HTM = this.packageData[i].hiring_team_members
          this.enterprisePackage.CA = "1";
          this.enterprisePackage.users = parseInt(this.enterprisePackage.SM) + parseInt(this.enterprisePackage.HTM) + parseInt(this.enterprisePackage.CA)
        }
      }
      let userdata: any = localStorage.getItem('currentUser');
      userdata = JSON.parse(userdata);
      this.getSelectedPackageData(userdata?.package_data.package)
    })
  }
  packageSelected(pack: any) {
    this.selectedPackage = pack;
    if (pack == 'Growth') {
      this.packageDataForm.controls['package_id'].setValue(this.growthPackage.id)
      this.selectedPackagePrice = this.growthPackage.price
    }
    if (pack == 'Starter') {
      this.packageDataForm.controls['package_id'].setValue(this.starterPackage.id)
      this.selectedPackagePrice = this.starterPackage.price
    }
    if (pack == 'Enterprise') {
      this.packageDataForm.controls['package_id'].setValue(this.enterprisePackage.id)
      this.selectedPackagePrice = this.enterprisePackage.price
    }
    this.goToNextStep('first');
  }
  populateClientData(client_id: any) {
    this.clientService.getClientbyId(client_id).subscribe((data: any) => {
      if (data) {
        for (var key in data.client) {
          if (data.client.hasOwnProperty(key)) {
            if (key === 'logo') {
              this.clientForm.controls['logo']?.setValue(data.client[key]);
              this.imageURL = Url.imgBaseUrl + data.client[key];
            } else if (key === 'ca_profile_pic') {
              this.clientForm.controls['ca_profile_pic']?.setValue(data.client[key]);
              this.caProfilePic = Url.imgBaseUrl + data.client[key];
            } else {
              this.clientForm.controls[key]?.setValue(data.client[key] || '');
            }
          }
        }
        // this.clientForm.removeControl('key_password');
        this.clientForm.removeControl('key_password');
        this.clientForm.get("key_password")?.clearValidators();
        this.clientForm.get("key_password")?.updateValueAndValidity();
        this.showClientDataSummary()
        this.showBillingDataSummary()
      }

    });
  }

  removePhoto() {
    this.clientForm.controls['ca_profile_pic'].setValue(null);
    this.caProfilePic = '';
  }
  showCAPreview(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      // this.accountService.leavePage = true;
      const [file] = event.target.files;
      this.clientForm.controls['ca_profile_pic'].setValue(event.target.files[0]);
      reader.readAsDataURL(file);
      this.clientForm.controls.ca_profile_pic.patchValue(file);
      reader.onload = () => {

        this.caProfilePic = reader.result as string;

      };

    }
  }
  showClientDataSummary() {
    this.companyName = this.clientForm.value.company_name
    this.companyWebsite = this.clientForm.value.company_website
    this.companyAdd = this.clientForm.value.addr_line_1 + ' ' + this.clientForm.value.addr_line_2
    this.companyLinkedin = this.clientForm.value.company_linkedin
    this.companyCity = this.clientForm.value.city
    this.companyState = this.clientForm.value.state
    this.companyZip = this.clientForm.value.pincode
    this.CA_name = this.clientForm.value.ca_first_name + ' ' + this.clientForm.value.ca_last_name
    this.CA_phone = this.clientForm.value.key_contact_phone_no
    this.CA_email = this.clientForm.value.key_contact_email
    this.CA_linkedin = this.clientForm.value.key_contact_skype_id
    this.jobTitle = this.clientForm.value.job_title
    this.clientUsername = this.clientForm.value.key_username
  }

  createPackageForm() {
    this.packageDataForm = this.fb.group({
      package_id: [''],
      ext_senior_manager: [''],
      ext_hiring_managers: [''],
      ext_hiring_team_members: [''],
      ext_contributors: [''],
      ext_price: ['']
    })
  }
  createClientForm() {
    this.clientForm = this.fb.group({
      company_contact_full_name: ['', Validators.required],
      company_contact_phone: [
        '',
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
      company_contact_email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
      company_name: ['', Validators.required],
      company_website: ['', Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')],
      company_linkedin: ['', Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*')],
      addr_line_1: ['', Validators.required],
      addr_line_2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      pincode: ['', Validators.required],
      ca_first_name: ['', [Validators.required, Validators.pattern('[a-zA-Z]*$')]],

      ca_last_name: ['', Validators.required],
      key_contact_phone_no: [
        '',
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
      key_contact_skype_id: ['', Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*')],
      key_contact_email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$'),
        ],
      ],
      key_username: ['', Validators.required],
      key_password: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[\/~`!@#$%^&*()_+\-=\[\]{}|\\:;"'<>,.?\//])(?!.*\s)/),
      Validators.minLength(8), Validators.maxLength(16)]],
      ca_profile_pic: [null],
      job_title: ['', Validators.required],
      hr_first_name: ['', Validators.pattern('[a-zA-Z]*$')],
      hr_last_name: [''],
      hr_contact_phone_no: ['', Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      hr_contact_skype_id: ['', Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*')],
      hr_contact_email: ['', Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$')],
      hr_username: [''],
      hr_password: [''],
      cto_first_name: ['', Validators.pattern('[a-zA-Z]*$')],
      cto_last_name: [''],
      cto_phone_no: ['', Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      cto_skype_id: ['', Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*')],
      cto_email: ['', Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$')],
      cto_username: [''],
      cto_password: [''],
      billing_first_name: ['', Validators.pattern('[a-zA-Z]*$')],
      billing_last_name: [''],
      billing_phone_no: ['', Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      billing_email: ['', Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$')],
      billing_addr_line_1: [''],
      billing_addr_line_2: [''],
      billing_city: [''],
      billing_state: [''],
      billing_pincode: [''],
      special_req: [''],
      logo: [null],
      id: [0],
      name: '',
      open_positions: [0],
      disabled: [false],
    });
  }

  createForm() {
    this.HTMemberForm = this.fb.group({
      client: ['', Validators.required],
      first_name: ['', [Validators.required, Validators.pattern('[a-zA-Z]*$')]],
      last_name: ['', [Validators.required, Validators.pattern('[a-zA-Z]*$')]],
      phone_number: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      cell_phone_number: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      skype_id: ['', Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*')],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      job_title: ['', Validators.required],
      username: ['', Validators.required],
      password: [''],
      rankings: [[{}]],
      profile_photo: [null],
      profile_photo_deleted: [false]
    });
  }

  createTeamsForm() {
    this.hiringTeamForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      description: [''],
      client_id: ['', Validators.required],
      // department_id: ['', Validators.required],
      members: [this.formMembers],
      hod: ['', Validators.required],
      hr: ['']
    });
  }

  getSelectedClient(event: any, clientId?: any) {
    this.memberList = {};
    this.formMembers = [];
    if (event && event.target.value) {
      this.selectedClient = event.target.value;
      this.getHirimembers(event.target.value);
    } else {
      this.selectedClient = clientId;
      this.getHirimembers(clientId);
    }
  }

  getHirimembers(client: any) {
    this.hiringTeamService
      .getHiringMembers(client)
      .subscribe((val: any) => {
        if (val) {
          this.memberList = val;
          if (this.memberList?.hiring_members?.length > 0 && this.formMembers?.length > 0) {
            this.formMembers.filter((m: any) => {
              return this.memberList?.hiring_members.filter((rm: any) => {
                if (rm.id === m.id) {
                  rm.checked = true
                }
              })
            })
          } else {
            return this.memberList?.hiring_members?.filter((member: any) => {
              member.checked = false
            })
          }
        }
      });
  }
  addMember(id: any) {
    if (this.memberList?.hiring_members?.length > 0) {
      return this.memberList.hiring_members.filter((member: any) => {
        if (member.id === id) {
          member.checked = true
        }
      })
    }
  }

  removeMember(id: any) {
    if (this.memberList?.hiring_members?.length > 0) {
      return this.memberList.hiring_members.filter((member: any) => {
        if (id === member.id) {
          member.checked = false
        }
      })
    }
  }

  addHr(id: any) {
    this.hiringTeamForm.controls.hr.setValue(id);
  }

  removeHr() {
    this.hiringTeamForm.controls.hr.setValue(null);
  }

  addHod(id: any) {
    this.hiringTeamForm.controls.hod.setValue(id);
  }

  removeHod() {
    this.hiringTeamForm.controls.hod.setValue('');
  }

  getHiringGroup() {
    this.dashboardsService.getClientList().subscribe((val: any) => {
      if (val) {
        this.clientList = val.clients;
        if (this.clientList.length === 1) {
          this.hiringTeamForm.controls.client_id.setValue(this.clientList[0].id);
          this.getSelectedClient(null, this.clientList[0].id);
        }
      }
    });
  }

  onPasswordChange() {
    const enteredPassword = this.clientForm.controls.key_password.value || this.passwordForm.controls.new_password.value;
    if (enteredPassword) {
      this.isUppercaseValid = /[A-Z]/.test(enteredPassword);
      this.isLowecaseValid = /[a-z]/.test(enteredPassword);
      this.isNumberValid = /\d/.test(enteredPassword);
      this.isSymbolValid = /^(?=.*[A-Z])(?=.*[\/~`!@#$%^&*()_+\-=\[\]{}|\\:;"'<>,.?\//])(?!.*\s)/.test(enteredPassword);
    } else {
      this.isUppercaseValid = false;
      this.isLowecaseValid = false;
      this.isNumberValid = false;
      this.isSymbolValid = false;
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  goToNextStep(stepName: any) {
    this.validateStep(stepName);
    switch (stepName) {
      case 'first':
        this.stepName = 'onepoint5';
        break;
      case 'onepoint5':
        this.showClientDataSummary()
        this.showBillingDataSummary()
        if (!this.firstLoggedUser && !this.backToClient)
          this.saveClient(this.clientForm.value);

        if (this.firstLoggedUser || this.backToClient)
          this.updateClient()
        // this.getAllHiringMember();
        this.stepName = 'second';
        break;
      case 'second':
        // this.gethiringTeamList();
        // this.getHiringGroup();

        //this.saveBillingInfo()
        this.stepName = 'third';
        break;
      case 'third':
        //this.getClientSummary();
        this.stepName = 'fourth';
        break;
      // case 'fourth':
      //   this.route.navigate([this.authService.currentUserRoleCode + '/dashboard']);
      //   break;
    }
  }

  goToPreviousStep(stepname: any) {
    switch (stepname) {
      case 'first':
        this.stepName = 'first';
        break;
      case 'onepoint5':
        this.stepName = 'onepoint5';
        this.backToClient = true;
        break;
      case 'second':
        this.stepName = 'second';
        break;
    }
  }

  saveBillingInfo() {
    this.userService.saveBillingInfoForSA(this.billingInfoForm.value, this.client_id).subscribe((val: any) => {
      this.showSuccess('Billing Information saved successfully');

    });
  }

  showBillingDataSummary() {
    this.billingName = this.clientForm.value.billing_first_name + ' ' + this.clientForm.value.billing_last_name;
    this.billingPhone = this.clientForm.value.billing_phone_no;
    this.billingEmail = this.clientForm.value.billing_email;
    this.billingAddress = this.clientForm.value.billing_addr_line_1 + ' ' + this.clientForm.value.billing_addr_line_2;
    this.billingCity = this.clientForm.value.billing_city
    this.billingState = this.clientForm.value.billing_state
    this.billingzip = this.clientForm.value.billing_pincode
  }

  createBillingInfoForm() {
    this.billingInfoForm = this.fb.group({
      billing_contact: ['', Validators.required],
      billing_email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      billing_phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      id: [''],
      addr_line_1: [''],
      addr_line_2: [''],
      city: [''],
      state: [''],
      pincode: [''],
      card_number: ['', Validators.pattern('[0-9]{16}$')],
      name_on_card: [''],
      exp_date: ['', Validators.pattern('(0[1-9]|1[0-2])\/([0-9]{2})$')],
      security: ['', Validators.pattern('[0-9]{3}$')],
    })

  }

  validateStep(stepName: any) {

  }
  getAllHiringMember() {
    this.hiringTeamService.getHiringTeamMemberByClient(this.client_id).subscribe((val: any) => {
      if (val) {
        this.hiringTeamList = val;
        this.hiringTeamMemberCount = Object.keys(val).length;
        for (let key in this.hiringTeamList) {
          let arr = [];
          if (this.hiringTeamList.hasOwnProperty(key)) {
            this.hiringTeamList[key].sort((a: any, b: any) => a.name.localeCompare(b.name));
          }
        }
        // this.getHTMember(this.hiringTeamList[0].username);
      }
    });
  }

  gethiringTeamList() {
    this.hiringTeamService.getAllHiringTeamByClient(this.client_id).subscribe((val: any) => {
      if (val) {
        this.hiringTeams = val;
        for (let key in this.hiringTeams) {
          let arr = [];
          if (this.hiringTeams.hasOwnProperty(key)) {
            this.hiringTeams[key].sort((a: any, b: any) => a.name.localeCompare(b.name));
          }
        }
      }
    });
  }

  showPreview(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      // this.accountService.leavePage = true;
      const [file] = event.target.files;
      this.clientForm.controls['logo'].setValue(event.target.files[0]);
      reader.readAsDataURL(file);
      this.clientForm.controls.logo.patchValue(file);
      reader.onload = () => {

        this.imageURL = reader.result as string;

      };

    }
  }

  showHTMPreview(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      // this.accountService.leavePage = true;
      const [file] = event.target.files;
      if (file.size > 2000000) {
        this.profileImageSizeExceed = true;
        this.HTMemberForm.controls['profile_photo_deleted'].setValue(false);
        this.HTMemberForm.controls.profile_photo.patchValue(null);
        this.imageURL = '';
        return;
      }
      this.profileImageSizeExceed = false;
      reader.readAsDataURL(file);
      this.HTMemberForm.controls.profile_photo.patchValue(file);
      reader.onload = () => {

        this.imageURL = reader.result as string;

      };

    }
  }

  showSMPreview(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      // this.accountService.leavePage = true;
      const [file] = event.target.files;
      this.accountForm.controls['profile_photo'].setValue(event.target.files[0]);
      reader.readAsDataURL(file);
      this.clientForm.controls.profile_photo.patchValue(file);
      reader.onload = () => {

        this.SMimageURL = reader.result as string;

      };

    }
  }

  deleteProfilePic(element: any) {
    this.accountForm.controls['profile_photo_deleted'].setValue(true);
    element.value = '';
    this.accountForm.controls.profile_photo.patchValue(null);
    this.SMimageURL = '';
  }

  deleteSMProfilePic(element: any) {
    this.HTMemberForm.controls['profile_photo_deleted'].setValue(true);
    element.value = '';
    this.HTMemberForm.controls.profile_photo.patchValue(null);
    this.imageURL = '';
  }

  saveClient(data: any) {
    // Encrypt Password
    // data.key_password = this.userService.encryptPassword(data.key_password);
    let Ldata: any = {
      username: this.clientForm.value.key_username,
      password: this.clientForm.value.key_password
    };
    let encryptedData: AuthPayload = { ...Ldata };
    encryptedData['password'] = this.userService.encryptPassword(Ldata['password']);
    this.clientForm.controls.key_password.setValue(this.userService.encryptPassword(this.clientForm.value.key_password));
    this.clientService.signInCLient(this.clientForm.value).subscribe(
      (res: any) => {
        if (res) {
          // const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
          // currentUser.client_id = res.client_id;
          // localStorage.setItem('currentUser', JSON.stringify(currentUser));
          //this.client_id = currentUser.client_id;
          this.client_id = res.client_id;
          this.authService.getLogin(encryptedData).subscribe(
            (res: any) => {
              this.token = res.token
              localStorage.setItem('currentUser', JSON.stringify(res));
              this.clientService.saveClientPackage(this.client_id, this.packageDataForm.value, this.token).subscribe((response: any) => {
                console.log(response)
              })
            })



          //this.HTMemberForm.controls.client.patchValue(this.client_id);
          // this.getHTMember(this.currentUser.username);
          this.showSuccess('Client saved successfully.');
        }
      },
      (error) => {
        // this.stepName = 'second';
        // this.getAllHiringMember();
        // this.getHTMember(this.currentUser.username);
        this.showDanger(error);
        //alert('Error:' + error.error);
      }
    );
  }

  updateClient() {
    if (this.clientForm.value.key_password) {
      // Encrypt Password
      this.clientForm.value.key_password = this.userService.encryptPassword(this.clientForm.value.key_password);
    } else {
      this.clientForm.value.key_password = null;
    }
    // this.clientForm.value.trial_expired = this.clientForm.value.trial_expired?.split("-").reverse().join("-");
    const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
    this.client_id = currentUser.client_id;
    this.clientForm.controls['id'].setValue(this.client_id)
    this.clientService.updateClient(this.clientForm.value).subscribe(
      (res: any) => {
        if (res) {
          this.showSuccess('Client Updated successfully.');
        }

        this.clientService.saveClientPackage(this.client_id, this.packageDataForm.value, this.token).subscribe((response: any) => {
          console.log(response)
        })
      },
      (error) => {
        this.showDanger('Error:' + error.error);
      }
    );
  }

  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

  saveHTMember() {
    // Encrypt Password
    this.HTMemberForm.controls.password.setValue(this.userService.encryptPassword(this.HTMemberForm.value.password));

    this.hiringTeamService
      .createHiringTeamMemberAgainstClient(this.HTMemberForm.value, this.client_id)
      .subscribe((value: any) => {
        if (value) {
          this.getAllHiringMember();
          this.showSuccess('Hiring Team Member created successfully');
          const client = this.HTMemberForm.controls.client.value;
          this.HTMemberForm.reset();
          this.HTMemberForm.controls.client.setValue(client);
        }
      });
  }

  resetHTMember() {
    this.HTMemberForm.reset();
    this.isUpdateHtm = false;
  }

  resetSmForm() {
    this.accountForm.reset();
    this.showSMUpdateBtn = false;
  }

  getHTMember(username: any) {
    this.hiringTeamService
      .getHiringTeamMemberById(username)
      .subscribe((value: any) => {
        if (value) {
          this.isUpdateHtm = true;
          this.HTMemberForm.controls['first_name'].setValue(value.first_name);
          this.HTMemberForm.controls['last_name'].setValue(value.last_name);
          this.HTMemberForm.controls['phone_number'].setValue(
            value.phone_number
          );
          this.HTMemberForm.controls['cell_phone_number'].setValue(
            value.cell_phone_number
          );
          this.HTMemberForm.controls['skype_id'].setValue(value.skype_id);
          this.HTMemberForm.controls['email'].setValue(value.email);
          this.HTMemberForm.controls['job_title'].setValue(value.job_title);
          this.HTMemberForm.controls['username'].setValue(value.username);
          this.HTMemberForm.controls['password'].setValue(value.password);
          this.HTMemberForm.controls['rankings'].setValue(JSON.parse(value.rankings));
          this.HTMemberForm.controls['client'].setValue(value.client);
          // this.fieldArray = JSON.parse(value.rankings);
          this.imageURL = value.profile_photo && value.profile_photo !== 'None' ? Url.imgBaseUrl + value.profile_photo : '';
        }
      });
  }

  UpdateHTMember() {
    this.hiringTeamService
      .updateHiringTeamMember(this.HTMemberForm.value)
      .subscribe((value) => {
        if (value) {
          this.isUpdateHtm = false;
          this.HTMemberForm.reset();
          this.showSuccess('Hiring Team Member updated successfully');
        }
      });
  }

  createHiringTeam() {
    this.filterMemberData();
    let data = this.hiringTeamForm.value;
    data['members'] = this.formMembers;
    this.hiringTeamService.createHiringteam(data).subscribe((val: any) => {
      this.showSuccess('Hiring Team created successfully');
      this.hiringTeamForm.reset();
      this.isCreateHiringTeam = false;
      this.gethiringTeamList();
    });
  }

  filterMemberData() {
    this.formMembers = [];
    if (this.memberList?.hiring_members?.length > 0) {
      return this.memberList.hiring_members.filter((member: any) => {
        if (member.checked === true) {
          this.formMembers.push(member.id);
        }
      })
    }
  }

  createpForm() {
    this.passwordForm = this.fb.group({
      username: [''],
      new_password: ['', Validators.required],
      re_password: ['', Validators.required],
    });
  }

  openforPassword(password: any) {
    this.modalService.open(password, { size: 'md' });
  }

  changePassword() {
    // Encrypt Password
    this.passwordForm.controls.new_password.setValue(this.userService.encryptPassword(this.passwordForm.value.new_password));

    let payload = this.passwordForm.value;
    payload['username'] = JSON.parse(this.currentUser)?.username;
    delete payload['re_password'];
    this.hiringTeamService.changePassword(payload).subscribe(value => {
      if (value) {
        this.showSuccess('Password changed Successfully');
        this.modalService.dismissAll();
        this.passwordForm.reset();
      } else {
        this.showDanger('Change Password failed, please try again.');
      }
    })
  }

  checkPasswords() {
    let pass = this.passwordForm.controls['new_password'].value;
    let confirmPass = this.passwordForm.controls['re_password'].value;
    if (pass === confirmPass) {
      this.showError = false;
    } else {
      this.showError = true;
    }

  }
  SMimageURL: any;
  accountForm!: FormGroup;
  createSeniorManagerForm() {
    this.accountForm = this.fb.group({
      client_id: [''],
      first_name: ['', [Validators.required, Validators.pattern('[a-zA-Z]*$')]],
      last_name: ['', [Validators.required, Validators.pattern('[a-zA-Z]*$')]],
      phone_number: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
      skype_id: ['', Validators.pattern('^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*')],
      job_title: [''],
      email: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$')],
      ],
      username: ['', Validators.required],
      password: ['', Validators.required],
      profile_photo: [null],
      profile_photo_deleted: [false]
    });
  }
  getClientSummary() {
    this.clientService.getClientSummary().subscribe((res: any) => {
      this.clientSummary = res;
    });
  }


  openSm(content: any, username: string) {
    this.currentAccount = username;
    this.modalService.open(content, { size: 'sm' });
  }

  onSubmit(confirm: any) {

    this.modalService.open(confirm, { size: 'md' });
  }

  submitFirstLogin(payModal: any) {
    this.modalService.dismissAll();
    const data = {
      msg: 'initial-payment'
    }
    const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
    this.client_id = currentUser.client_id;
    this.token = currentUser.token;
    this.authService.initiatePayment(this.client_id, data, this.token).subscribe((res: any) => {
      console.log(res)
      const key = res.key;
      const skey = res.payment_secret
      const pUrl = Url.uiBaseUrl + 'thankyou'
      this.modalService.dismissAll();
      this.modalService.open(payModal, { size: 'md' });
      callStripe(key, skey, pUrl);
      //   {
      //     "msg": "payment_required",
      //     "payment_intent_id": "pi_3OEXVnAJWGiW6j8l2CkZ5IoB",
      //     "payment_secret": "pi_3OEXVnAJWGiW6j8l2CkZ5IoB_secret_wP9TYkX83u4QnUuDyeqZo92XO",
      //     "key": "pk_test_51Nlbw0AJWGiW6j8lYXVcPsmIPxRSCBvz6lpsU7eIik8fZJpP9jXTxoc8YqVCr2UysPztt2h2zaZyoHGVQPY8x4ur00u0G7RN9S"
      // }
    })
  }
}
