<main id="positions-content">
    <app-header style="z-index: 99;position: relative;  "></app-header>
    <div class="widget-wrapper">


        <app-detail-header [positionList]="positionList" [positionCount]="positionCount" [positionType]="positionType"
            (searchText)="searchText($event)"></app-detail-header>

        <div class="page-body">
            <div class="sub-navbar">
                <ul class="mt-4 mb-2">
                    <li (click)="clickPos('Open')" class="" [ngClass]="status1 ? 'show' : 'hide'">Open <span
                            class="count-span"> {{openPositionList.length > 9 ? openPositionList.length :
                            '0'+openPositionList.length }} </span></li>
                    <li (click)="clickPos('Draft')" class="" [ngClass]="status2 ? 'show' : 'hide'">Draft <span
                            class="count-span"> {{draftedPositionList.length > 9 ? draftedPositionList.length : '0'+
                            draftedPositionList.length}} </span></li>
                    <li (click)="clickPos('Archive')" class="" [ngClass]="status3 ? 'show' : 'hide'">Archive <span
                            class="count-span"> {{archivedPositionList.length > 9 ? archivedPositionList.length :
                            '0'+archivedPositionList.length }} </span></li>
                    <li (click)="clickPos('Completed')" class="" [ngClass]="status4 ? 'show' : 'hide'">Completed <span
                            class="count-span"> {{completedPositionList.length > 9 ? completedPositionList.length : '0'
                            + completedPositionList.length}} </span></li>
                    <li (click)="clickPos('All')" class="" [ngClass]="status5 ? 'show' : 'hide'">All <span
                            class="count-span"> {{AllTableData.length >9 ? AllTableData.length :
                            '0'+AllTableData.length}} </span></li>
                </ul>
            </div>
            <div class="widget-box">
                <div class="custom-scroll-bar" #scrollDiv style="height: 700px;">
                    <table class="table table-striped table-bordered">
                        <tbody>
                            <tr style="background-color: #EBEBEB;">
                                <td scope="col" [class.active-sorted-table]="sortedTable==='position_title'">
                                    <input class="c-checkbox mr-3" type="checkbox" id="position" [(ngModel)]="selectAll"
                                        (change)="selectAllItems()">
                                    POSITION NAME <span class="float-right cursor-pointer"><fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"
                                            (click)="sortTable('position_title')"></fa-icon> </span>
                                </td>
                                <td *ngIf="col_HM" scope="col"
                                    [class.active-sorted-table]="sortedTable==='senior_manager'">MEMBERS
                                    <span class="float-right cursor-pointer"
                                        (click)="sortTable('senior_manager')"><fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <!-- <th scope="col" (click)="sortTable('hiring_team')">Hiring Team <fa-icon [icon]="faSort"></fa-icon></th> -->
                                <td *ngIf="col_curr_status" scope="col"
                                    [class.active-sorted-table]="sortedTable==='deadline'">CURRENT
                                    STATUS<span class="float-right cursor-pointer"
                                        (click)="sortTable('deadline')"><fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <td *ngIf="col_target" scope="col"
                                    [class.active-sorted-table]="sortedTable==='formated_target_deadline'">
                                    TARGET DATE
                                    <span class="float-right cursor-pointer"
                                        (click)="sortTable('formated_target_deadline')"><fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <td *ngIf="col_total_pos"
                                    [class.active-sorted-table]="sortedTable==='no_of_open_positions'" scope="col">
                                    POSITIONS TO FILL<span class="float-right cursor-pointer"
                                        (click)="sortTable('no_of_open_positions')"> <fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <td *ngIf="col_pos_filled" scope="col"
                                    [class.active-sorted-table]="sortedTable==='position_filled'">HIRES
                                    COMPLETED<span class="float-right cursor-pointer"
                                        (click)="sortTable('position_filled')"> <fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <td *ngIf="col_ac" scope="col"
                                    [class.active-sorted-table]="sortedTable==='position_filled'">
                                    Additional Column<span class="float-right cursor-pointer"
                                        (click)="sortTable('position_filled')"> <fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <td scope="col" *ngIf="isJobBoard==='true'">Job Board</td>
                            </tr>



                            <tr *ngFor="let pos of tableData | searchFilter: query : 'position_title' ; let i = index ">
                                <th>
                                    <input class="c-checkbox mr-3" type="checkbox"
                                        (change)="positionSelect(pos.id, clientsItem[i])" [(ngModel)]="clientsItem[i]"
                                        [checked]="clientsItem[i]" type="checkbox" id="position" [name]="pos.id"
                                        [value]="pos.id">
                                    <a class="" href="javascript:void(0)"
                                        (click)="select(pos)">{{transformText(pos.position_title) | titlecase}}</a>
                                </th>
                                <td *ngIf="col_HM">{{transformText(pos.senior_manager)||'Not Available'| titlecase }}
                                </td>
                                <!-- <td><b>{{pos.hiring_team}}</b></td> -->
                                <td *ngIf="col_curr_status">{{pos.deadline?'Delayed':'On Time'}}</td>
                                <td *ngIf="col_target">{{pos.formated_target_deadline || 'NA'}}</td>
                                <td *ngIf="col_total_pos">{{pos.no_of_open_positions || 'NA'}}</td>
                                <td *ngIf="col_pos_filled">{{pos.position_filled || 'NA'}}</td>
                                <td *ngIf="col_ac">{{pos.position_filled || 'NA'}}</td>
                                <td *ngIf="isJobBoard==='true'"> <ui-switch color="var(--primary)" (change)="toggleJobPosting(pos.id,$event)"  labelOn="Yes" labelOff="No" [checked]="pos.job_position"></ui-switch></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</main>

<ng-template #htmConfirm let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Important</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>To complete this process you must add
            team members and create a Hiring
            Team. Click the Teams button below to
            start the process.</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="moveToHtmPage()">Team</button>
        <div class="pt-4">
            <p class="mb-0">Learn more about Hiring Teams.</p>
            <video width="260" height="170" controls>
                <source type="video/mp4"
                    src="https://qorums.com/wp-content/uploads/2023/08/3rd-Revise-720-8-28-23.mp4" />
            </video>
        </div>
    </div>
</ng-template>