import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';

import {
  faPen,
  faPlus,
  faSearch,
  faTrash,
  faClone,
  faTrashRestore,
  faLightbulb,
  faArchive
} from '@fortawesome/free-solid-svg-icons';
import { faFile, faFileAlt, faPauseCircle, faSave, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PositionService } from 'src/app/services/position.service';

import * as URL from './../../constants/url';
import { Client } from '../../models/dashboard.interface';
import { CandidateService } from '../../services/candidate.service';
import { DashboardService } from '../../services/dashboard.service';
import { ToastService } from '../../core/toast/toast-service';
import { AuthService } from 'src/app/services/auth.services';
import { filter, startWith, switchMap } from 'rxjs/operators';
import { th } from 'date-fns/locale';

@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.css'],
})
export class PositionsComponent implements OnInit, OnDestroy {
  public clientList: Client[] = [];
  public firstClient: any;
  public positionList: any[] = [];
  selected: any;
  faFile = faFile;
  faFileAlt = faFileAlt;
  faPauseCircle = faPauseCircle;
  faSave = faSave;
  faTrashAlt = faTrashAlt;
  faSearch = faSearch;
  faArchive = faArchive;
  faPlus = faPlus;
  faEdit = faPen;
  faDelete = faTrash;
  faClone = faClone;
  faTrashRestore = faTrashRestore;
  faLightbulb = faLightbulb;
  query: any;
  public logo: any;
  public currentClient: any;
  public currentPosition: any;
  isshowSearch: boolean = false;
  public userRole: string = '';
  public companyName: any;
  draftedPositionList: any;
  candidate_id: any;
  op_id: any;
  archivedPositionList: any;
  completedPositionList: any;
  currentUserRoleCode!: string;
  selectedPosition: any;
  trashedPositionList: any;
  activeIds = ['openPositions'];
  isDeleted = false;
  isHtmAvailable: boolean = true;
  clientPositionData: any;
  constructor(
    private dashboardsService: DashboardService,
    private router: Router,
    private toastService: ToastService,
    private candidatesService: CandidateService,
    private modalService: NgbModal,
    private positionService: PositionService,
    private authService: AuthService,
    private candidateService: CandidateService
  ) {
  }

  ngOnInit(): void {
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    let uerdata:any = localStorage.getItem('currentUser');
    uerdata = JSON.parse(uerdata);
    if(uerdata) {
      this.userRole = uerdata?.role;
    }
    this.currentClient = localStorage.getItem('clientId');
    this.getClientList();
    // setTimeout(() => {
    //   this.collectPositionFromClients();
    // }, 4000);
   
    this.candidateService.getSelectedCandidate().subscribe((val) => {
      if (val.id) {
        this.selectedPosition = localStorage.getItem('currentPosition');
      }
    });
    this.positionService.isPositionUpdated$.subscribe(res => {
      this.isDeleted = false;
      if(!res) {
        return;
      }
      
      if(res?.isCloned || res?.isDelete) {
        this.getClientList(true);
        if(res.isDelete) {
          this.isDeleted = true;
        }
        
      } else {
        this.getClientList();
      }
      if(res.id) {
        this.selectedPosition = res.id;
        this.activeIds = [];
        this.activeIds = ['draftPosition'];
      }
    });
  }
  ngOnDestroy() {
    localStorage.removeItem('editPosition');
    localStorage.removeItem('form1Data');
    localStorage.removeItem('form2Data');
    localStorage.removeItem('form3Data');
    localStorage.removeItem('hiringGroupId');
  }
  createPosition(e: any, htmModel:any){
    // if(this.isHtmAvailable){
       this.selectedPosition=null
       this.selected = null
    e.stopPropagation();
    localStorage.removeItem('editPosition');
    this.router.navigate([this.authService.currentUserRoleCode + '/position/createPosition1']);
    // }
    // else
    //   this.modalService.open(htmModel, { size: 'sm' });
  }
  moveToHtmPage(){
    this.modalService.dismissAll();
    this.router.navigate([this.authService.currentUserRoleCode + '/dashboard/hiringDepartment']);
  }

  getClientList(isDuplicateOrDelete?: any) {
    this.dashboardsService.getSelectClientData(this.currentClient).subscribe((el:any)=>{
      this.clientPositionData = el.data;
      this.companyName = el.data.company_name;
      this.isHtmAvailable =el.data.team_exists;
      if(el.data?.logo && el.data?.logo !== 'None' && el.data?.logo !== 'null') {
        this.logo = URL.imgBaseUrl + el.data?.logo;
      } else {
        this.logo = URL.imgBaseUrl + './../../../assets/images/no-image.jpg';
      }

      if(isDuplicateOrDelete) {
        this.collectPositionFromClients(true);
      } else {
        this.collectPositionFromClients();
      }
      this.candidateService.setSelectedCandidate(this.clientPositionData);
    })
  }

  select(item: any, isNavClicked?: any) {
    //this.clientPositionData
    if(this.selected?.id === item?.id) {
      return;
    }
    this.selected = item;
    localStorage.setItem('currentPosition', item?.id);
    localStorage.setItem('currentPositionName', item?.position_title);
    localStorage.setItem('useitOnceCandidateassociated', JSON.stringify(item));
    if(this.userRole === 'Hiring Manager' || this.userRole === 'Hiring Member' || this.userRole === 'Human Resource') {
      localStorage.setItem('is_hiring_manager', item.is_hiring_manager);
      localStorage.setItem('is_hr', item.is_hiring_manager);
    } else {
      localStorage.removeItem('is_hiring_manager');
      localStorage.removeItem('is_hr');
    }
    if(item?.open_position_data) {
      this.candidatesService.setSelectedCandidate(item);
    } else {
      if(this.userRole === 'Superuser') {
        // this.candidatesService.setSelectedCandidate(this.clientList?.filter((el: any) => el.id == this.selected?.client)[0]);
        this.candidatesService.setSelectedCandidate(this.clientPositionData);
      } else {
        if(this.userRole === 'Superuser') {
          // this.candidatesService.setSelectedCandidate(this.clientList?.filter((el: any) => el.id == this.selected?.client)[0]);
          this.candidatesService.setSelectedCandidate(this.clientPositionData);
        } else {
          // this.candidatesService.setSelectedCandidate(this.clientList[0]);
          this.candidatesService.setSelectedCandidate(this.clientPositionData);
        }
      }
    }
    if(isNavClicked || (this.router.url.indexOf('createPosition') === -1 && this.router.url.indexOf('?candidate_id=') === -1)) { 
      this.router.navigate([this.authService.currentUserRoleCode + '/position/clientPosition']);
    }
    // if(item.is_hr) {
    //   this.router.navigate([this.authService.currentUserRoleCode + '/position/htmWeightage']);
    // } else {
    //   this.router.navigate([this.authService.currentUserRoleCode + '/position/clientPosition']);
    // }
  }

  draftPositionSummaryPage(item: any){
    this.selected = item;
    localStorage.setItem('currentPosition', item?.id);
    localStorage.setItem('currentPositionName', item?.position_title);
    this.candidatesService.setSelectedCompletedPosition(item);
    this.candidatesService.setSelectedCandidate(item);
    this.router.navigate([this.authService.currentUserRoleCode + '/position/position-summary']);
  }
  onHoldPositionSummaryPage(item: any){
    this.selected = item;
    localStorage.setItem('currentPosition', item?.id);
    localStorage.setItem('currentPositionName', item?.position_title);
    this.candidatesService.setSelectedCompletedPosition(item);
    this.candidatesService.setSelectedCandidate(item);
    this.router.navigate([this.authService.currentUserRoleCode + '/position/position-summary']);
  }

  goToAuditDashboard(item: any) {
    
    this.selected = item;
    localStorage.setItem('currentPosition', item?.id);
    localStorage.setItem('currentPositionName', item?.position_title);
    this.candidatesService.setSelectedCandidate(item);
    this.candidatesService.setSelectedCompletedPosition(item);
    this.router.navigate([this.authService.currentUserRoleCode + '/position/audit-dashboard']);
  }


  isActive(item: any) {
    return this.selected === item;
  }
  collectPositionFromClients(isDuplicateOrDelete?:any) {
    // let data: any []= [];
    let el = this.clientPositionData
    if (el.open_position_data.length > 0) {
      if(isDuplicateOrDelete) {
        this.positionList[0] = el.open_position_data;
      } else {
        this.positionList.push(el.open_position_data);
      }
      // this.positionList = el.open_position_data;
    }
    this.draftedPositionList = el.drafted_position_data;
    this.archivedPositionList = el.archieved_position_data;
    this.completedPositionList = el.completed_position_data;
    this.trashedPositionList = el.trashed_position_data;
    if((this.userRole === 'Hiring Manager')
       || (this.userRole === 'Hiring Member')
       || (this.userRole === 'Client Admin')
       || (this.userRole === 'Human Resource')) {
      if(isDuplicateOrDelete) {
        this.positionList[0] = el.open_position_data;
      } else {
        this.positionList.push(el.open_position_data);
      }
    }


    // this.clientList.map((el: any) => {
    //   if (this.currentClient == el.id) {
    //     if (el.open_position_data.length > 0) {
    //       if(isDuplicateOrDelete) {
    //         this.positionList[0] = el.open_position_data;
    //       } else {
    //         this.positionList.push(el.open_position_data);
    //       }
    //       // this.positionList = el.open_position_data;
    //     }
    //     this.draftedPositionList = el.drafted_position_data;
    //     this.archivedPositionList = el.archieved_position_data;
    //     this.completedPositionList = el.completed_position_data;
    //     this.trashedPositionList = el.trashed_position_data;
    //   } else if((this.userRole === 'Hiring Manager') || (this.userRole === 'Hiring Member') || (this.userRole === 'Client Admin') || (this.userRole === 'Human Resource')){
    //     if(isDuplicateOrDelete) {
    //       this.positionList[0] = el.open_position_data;
    //     } else {
    //       this.positionList.push(el.open_position_data);
    //     }
    //   }
    // });
    // Auto select first open position
    if (this.positionList.length > 0) { 
      this.firstClient = this.positionList[0][0];
      if(this.isDeleted) {
      this.select(this.firstClient);
      this.activeIds = ['openPositions'];
      }
      if(this.userRole === 'Hiring Manager' || this.userRole === 'Hiring Member' || this.userRole === 'Human Resource') {
        if(this.router.url.indexOf('/candidateProfile/') === -1) {
        // this.select(this.firstClient);
        // this.candidatesService.setSelectedCandidate(this.firstClient);
        }
      }
      this.isActive(this.firstClient);
    }
  }

  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  openSm(content: any, id: any) {
    this.currentPosition = id;
    this.modalService.open(content, { size: 'md' });
  }

  trashPosition() {
    this.positionService.trashPosition(this.currentPosition, true).subscribe((client: any) => {
      if (client) {
        this.showSuccess('Position trashed successfully.');
        this.modalService.dismissAll();
        client.isDelete = true;
        this.positionService.setIsPositionUpdated(client);
      }
    });
  }

  deletePosition() {
    this.positionService.deletePosition(this.currentPosition).subscribe((client) => {
      if (client) {
        this.showSuccess('Position permanently deleted successfully.');
        this.modalService.dismissAll();
        this.getClientList(true);
      }
    });
  }

  editPosition(id: any, hiringGroupId: any) {
    
    this.selectedPosition = id;
    localStorage.setItem('editPosition', id);
    localStorage.setItem('hiringGroupId', hiringGroupId);
    // setTimeout(() => {
      this.router.navigate([this.authService.currentUserRoleCode + '/position/createPosition1'], { queryParams: {id: id}});
    // }, 500);
  }

  duplicatePosition(id: any) {
    this.positionService.duplicatePosition(id).subscribe((res: any) => {
      this.getClientList(true);
      this.showCloneSuccess();
      this.modalService.dismissAll();
      localStorage.setItem('editPosition', res.id);
      this.selectedPosition = res.id;
      this.activeIds = [];
      this.activeIds = ['draftPosition'];
      this.router.navigate([this.authService.currentUserRoleCode + '/position/createPosition1'], { queryParams: {id: res.id}});
    })
  }

  showCloneSuccess() {
    this.toastService.show('Position added in Draft Positions.', {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  restoreOpenPosition(op_id: any) {
    this.positionService.restorePosition(op_id).subscribe(res => {
      this.toastService.show('Position restored successfully.', {
        classname: 'bg-success text-light',
        delay: 5000,
      });
    });
    this.modalService.dismissAll();
    this.getClientList(true);
  }

  archivePosition() {
    const openPositionId = localStorage.getItem('currentPosition');
    this.positionService.archivePosition(openPositionId).subscribe(res => {
      this.toastService.show('Position marked as on hold successfully.', {
        classname: 'bg-success text-light',
        delay: 5000,
      });
      window.location.reload();
    });
  }

  restoreTrashedPosition(id: any) {
    this.positionService.trashPosition(id, false).subscribe((client) => {
      if (client) {
        this.showSuccess('Position restored successfully.');
        this.modalService.dismissAll();
        this.getClientList(true);
      }
    });
  }
}
