<ng-template #navigator let-modal class="nav-modal">
    <div class="modal-header">
        <div class="modal-title question-section ml-4">
            <fa-icon class="pr-1" [icon]="faCompass" [styles]="{'color': 'var(--primary)', 'font-size': '30px'}"></fa-icon>
            QORUMS NAVIGATOR</div>
        <div class="d-flex right-heading">
            <p class="mb-0 sub-heading-text">Step by Step guide to help you hire better!</p>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body custom-scroll-bar">
        <div class="d-flex">
            <div class="steps-section">
                <div class="steps">
                        <div class="list-section" *ngFor="let item of navigators; let i=index">
                            <div class="inner-list-section">
                                <div [ngClass]="{ 'selected-enclosure': item.step_id <= currentStep, 'unselected-enclosure': item.step_id > currentStep }">
                                    <fa-icon *ngIf="item.step_id == '1'" [icon]="faUserPlus" [styles]="{'color': 'white', 'font-size': '25px'}"></fa-icon>
                                    <fa-icon *ngIf="item.step_id == '2'" [icon]="faShoppingBag" [styles]="{'color': 'white', 'font-size': '25px'}"></fa-icon>
                                    <fa-icon *ngIf="item.step_id == '3'" [icon]="faGraduationCap" [styles]="{'color': 'white', 'font-size': '25px'}"></fa-icon>
                                    <fa-icon *ngIf="item.step_id == '4'" [icon]="faDesktop" [styles]="{'color': 'white', 'font-size': '25px'}"></fa-icon>
                                    <fa-icon *ngIf="item.step_id == '5'" [icon]="faHandshake" [styles]="{'color': 'white', 'font-size': '25px'}"></fa-icon>
                                    <fa-icon *ngIf="item.step_id == '6'" [icon]="faComments" [styles]="{'color': 'white', 'font-size': '25px'}"></fa-icon>
                                    <div *ngIf="(i<(navigators.length-1))" class="progress-line" [ngStyle]="{'background-color': item.step_id < currentStep ? 'var(--primary)' : '#989898'}"></div>
                                </div>
                                <span class="cursor-pointer steps-link ml-3" (click)="showStep(i)" [ngStyle]="{'color':currentItem?.step_id==item.step_id ?' var(--primary)':'#676767'}">{{item.title}}</span>
                                <fa-icon *ngIf="item.step_id <= currentStep" class="px-3" [icon]="faCheck" [styles]="{'color': '#02AA76', 'font-size': '25px'}"></fa-icon>
                            </div>
                        </div>
                </div>
            </div>
            <div class="content-section">
                <div class="triangle-left" [ngStyle]="{'top':currentItem?.step_id=='1'?'30px':currentItem?.step_id=='2'?'140px':currentItem?.step_id=='3'?'240px':currentItem?.step_id=='4'?'340px':currentItem?.step_id=='5'?'440px':currentItem?.step_id=='6'?'540px':''}"></div>
               <div class="content-box custom-scroll-bar">
                    <div class="question-section mb-3">
                        How to {{currentItem?.title}}?
                    </div>
                    <div class="video-section my-3" *ngIf="currentItem?.videoLink">
                        <iframe *ngIf="currentItem?.videoLink" allow="camera;microphone;fullscreen;autoplay" style="width: 100%;height: 350px;" [src]="currentItem?.videoLink | safe"></iframe>
                
                    </div>
                    <div class="desc-section" [innerHTML] = "currentItem?.content"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="sub-footer-text">To Turn off this popup permanently. Go to <span class="font-weight-bold"> Profile Menu > Qorums Navigator > Turn Off</span></div>
    </div>
</ng-template>
