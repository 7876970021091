<div class="widget-col createPosition">
    <h2 class="widget-title">
        Home</h2>
        <div>
            <div class="row ">
                <div class="col-sm-3">
                    <div class="d-flex bg-white">
                        <img [src]="this.userdata?.profile_photo ? this.userdata?.profile_photo: './../../assets/images/big-default-avatar.png'" class="prof-pic">
                        <!-- <div>
                            <div>{{this.userdata?.client_name}}</div>
                        </div> -->
                        <div class="w-100">
                            <div class="cand-title-box">
                                <span class="candidate-name">
                                    <span class="c-name"> {{userdata?.name | titlecase}} </span>&nbsp;&nbsp;
                                </span>
                            </div>
                            <div class="cand-cont-box  ">
                                <span class="ml-2">
                                    {{userdata?.role}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white int-list-box custom-scroll-bar my-3">
                        <h6 class="mb-1 mt-2">Upcoming Interviews:</h6>
                        <span *ngIf="upcomingInterviews?.length == 0" class="ml-2">No upcoming interviews</span>
                        <table *ngIf="upcomingInterviews?.length > 0" class="table table-borderless c-data-table">
                            <tr *ngFor="let item of upcomingInterviews">
                                <td class="font-weight-bold">
                                    <div class="p-0">
                                        <div>Interview for {{item?.position_title}} with {{item?.candidate_name}}</div>
                                        <div>{{item?.date + ' ' + item?.time}}</div>
                                        <button class="btn btn-sm save-button" *ngIf="isDateToday(item?.date)" (click)="startInterview(item)">Start QVideo</button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="bg-white  custom-scroll-bar">
                        <h6 class="mb-1 mt-2">Active Positions:</h6>
                        <span *ngIf="activePositions?.length == 0" class="ml-2">No Active Positions</span>
                        <table *ngIf="activePositions?.length > 0" class="table table-borderless c-data-table">
                            <tr *ngFor="let item of activePositions">
                                <td class="font-weight-bold">
                                    {{item?.position_title}} 
                                </td>
                            </tr>
                            <!-- [ngStyle]="{'border-bottom':skillData.marks?'1px solid #BEBEBE':'none'}" -->
                            <!-- <tr>
                                <td style="border-bottom: none" class="font-weight-bold"></td>
                                <td style="border-bottom: none"></td>
                            </tr> -->
                        </table>
                    </div>
                </div>
                <div class="col-sm-9 pr-0">
                    <div class="row widget-box ">
                        <div class="col-sm-9">
                            <app-angular-calendar (HtmData)="htmDataFromCalendar($event)" (posDateData)="posDateDataFromCalendar($event)"></app-angular-calendar>
                        </div>
                        <div class="col-sm-3 pr-0">
                            <div>
                                <div class="select-interview" *ngIf="!showHtmInfo">
                                    <h5>Select an Interview</h5>
                                    <p class="fs-5 font-weight-normal">Click on any event in the calendar to view its details</p>
                                </div>
                                <div class="selected-htm-interview" *ngIf="showHtmInfo && posCalendarData">
                                    <div class="fs-5" *ngIf="posCalendarData?.icon!=='htm-deadline-icon'">
                                        Position: {{posCalendarData?.position}} <br>
                                        {{posCalendarData?.event}} Date: {{posCalendarData?.date}}
                                    </div>
                                    <div  *ngIf="posCalendarData?.icon=='htm-deadline-icon'">
                                        <p class="text-center">
                                            <img src="{{posCalendarData?.photo?posCalendarData?.photo : './../../../assets/images/big-default-avatar.png'}}" class="photo">
                                        </p>
                                        <div class="fs-5 py-2">
                                            Name: {{posCalendarData?.htm_name | titlecase}}<br>
                                            Position: {{posCalendarData?.position}}<br> 
                                            Deadline: {{posCalendarData?.date}}
                                        </div>
                                    </div>
                                </div>
                                <div class="selected-htm-interview" *ngIf="showHtmInfo && htmCalendarData" >
                                    <div class="d-flex mb-4">
                                        <fa-icon [icon]="faCamera"></fa-icon>
                                        <div class="ml-3 fs-5">
                                            <p class="fs-5">Interview with <br><span class="font-weight-bold" style="color: #666666;">{{htmCalendarData.candidate}}</span></p>
                        
                                            Date: {{htmCalendarData?.sep_date}}<br>
                                            Time: {{htmCalendarData?.sep_time}} <br>
                                            Timezone: {{htmCalendarData?.long_timezone}}
                                            <br>
                                            <a (click)="startInterviewFromCalendar()" *ngIf="isDateToday(htmCalendarData.sep_date)"><button class="btn save-button">Start QVideo</button></a>
                                        </div>
                                    </div>
                                
                                </div>
                                <div  class="legends p-3 mt-4">
                                    <div class="legend ">
                                        <!-- \f251 -->
                                    <fa-icon [icon]="faHourglassStart" style="color: black;"></fa-icon>
                                    <span class="legend-name" >Kick-off Date</span>
                                    </div>
                                    <div class="legend ">
                                        <fa-icon [icon]="faUserPlus" style="color: black;"></fa-icon>
                                        <span class="legend-name">Sourcing Target Date</span>
                                    </div>
                                    <div class="legend ">
                                        <fa-icon [icon]="faFlagCheckered" style="color: black;"></fa-icon>
                                        <span class="legend-name">Target Completion Date</span>
                                    </div>
                                    <div class="legend ">
                                    <fa-icon [icon]="faExclamationCircle" style="color: black;"></fa-icon>
                                    <span class="legend-name">Deadlines</span>
                                </div>
                                    <div class="legend ">
                                        <fa-icon [icon]="faCalendarAlt" style="color: Red;"></fa-icon>
                                        <span class="legend-name">Interview Scheduled </span>
                                    </div>
                                    
                                    <div class="legend " >
                                        <fa-icon [icon]="faComments" style="color: black;"></fa-icon>
                                        <span class="legend-name">Interviews Completed</span>
                                    </div>
                                </div>
                                
                                <div class="mt-4 d-flex">
                                    
                                    <!-- <fa-icon class="mr-2" [icon]="faCarrot"  [styles]="{'stroke': '#FF9933', 'color': '#FF9933', 'font-size': '25px'}"></fa-icon>
                                    <p><span style="color:#9C9C9C;font-size: 20px;">Earn 10 Reward Points when you link your work calendar. Click on Add Availability</span>
                                    </p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
