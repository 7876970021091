import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.services';
import { UserService } from 'src/app/services/user.service';
import { WebSocketService } from 'src/app/services/websocket.service';
declare var WonderPush: any;
@Component({
  selector: 'app-sso-callback',
  templateUrl: './sso-callback.component.html',
  styleUrls: ['./sso-callback.component.css']
})
export class SsoCallbackComponent implements OnInit {
  ssoIdToken!: string;
  userRole: any;
  redirectURL: any;
  
  constructor(
    private authService: AuthService,
    private route: Router,
    private websocketService: WebSocketService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.ssoIdToken = location.hash.substring(location.hash.indexOf('=') + 1, location.hash.indexOf('&'))
    localStorage.setItem('id_token_sso', this.ssoIdToken);
    this.cognitoLogin();
  }

  cognitoLogin() {
    this.authService.getCognitoLogin().subscribe(
      (res: any) => {
        this.userRole = res.role;
        if((res['role']==="Hiring Member" || res['role']==="Client Admin" ||  res['role']==="Hiring Manager")) {
          if(this.redirectURL && this.redirectURL !== '/') {
            this.route.navigateByUrl(this.redirectURL);
          } else {
            if(!res?.payment_done) {
              // this.route.navigate([this.authService.currentUserRoleCode + '/dashboard/first-login']);
              //  this.route.navigate([this.authService.currentUserRoleCode + '/position/clientPosition']);
              // this.route.navigate(['/first-login']);
              this.route.navigate(['/signup']);
            } else {
              this.route.navigate([this.authService.currentUserRoleCode + '/position/clientPosition']);
            }
          }
        }
         else {
          setTimeout(() => {
            this.route.navigate([this.authService.currentUserRoleCode + '/dashboard']);
          }, 1000);
        }
        this.updateProtip();
        if(res?.client_id) {
          localStorage.setItem('clientId', res?.client_id);
        }
        localStorage.setItem('currentUser', JSON.stringify(res));
        (window as any).WonderPush = (window as any).WonderPush || [];
        WonderPush.push(['setUserId', res.username]);
        this.websocketService.connect();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  updateProtip() {
    this.userService.getProTip().subscribe((res: any) => {
      localStorage.setItem('protip', JSON.stringify(res.data));
      this.userService.setproTipUpdated(null);
      this.userService.setproTipUpdated(res.data);
    });
  }

}
