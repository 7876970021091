import { AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';

import {
  faCheck, faCalendar, faSort, faMinus, faCheckSquare,
  faPlus, faCarrot, faCalendarDay, faCalendarAlt, faPen,
  faMinusSquare, faPlusSquare, faSync
} from '@fortawesome/free-solid-svg-icons';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/fromEvent';
import { Options } from '@angular-slider/ngx-slider';
import { AuthService } from 'src/app/services/auth.services';
import { HiringTeamService } from 'src/app/services/hiringteam.service';
import { Router } from '@angular/router';
import { PositionService } from 'src/app/services/position.service';
import { ToastService } from 'src/app/core/toast/toast-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faKeyboard, faSparkles, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { MapService, Maps } from 'src/app/services/map.service';

@Component({
  selector: 'app-new-position',
  templateUrl: './new-position.component.html',
  styleUrls: ['./new-position.component.css']
})
export class NewPositionComponent implements OnInit, AfterViewInit {
  @ViewChild('search') public searchElementRef: ElementRef

  faSparkles = faSparkles;
  faKeyboard = faKeyboard;
  faSync = faSync;
  faMinusSquare = faMinusSquare;
  faPlusSquare = faPlusSquare;
  faCheck = faCheck;
  faCalendar = faCalendar;
  faMinus = faMinus;
  faSort = faSort;
  faPlus = faPlus;
  faPen = faPen;
  faDelete = faTrash
  faCarrot = faCarrot;
  faCheckSquare = faCheckSquare
  faCalendarAlt = faCalendarAlt;
  faCalendarDay = faCalendarDay;
  JDparsed = false;
  step1: boolean = true;
  step2: boolean = false;
  step3: boolean = false;
  step4: boolean = false;
  box1selected: boolean = false;
  box2selected: boolean = false;
  box3selected: boolean = false
  progress_percent: any;
  hiringItem: any = []
  public positionFirstForm!: FormGroup;
  public createPosition2Form!: FormGroup;

  newData: any = [];
  public model: any;
  currentUserRoleCode: any;
  hmData: any;
  selectedHM: any = [];
  range1: any = '';
  range2: any = '';
  uploadedFileSize: any;
  currentHTMWeightage: any;
  htmIndex: any;
  sortKey: any;
  reverse: any;
  isEditMode: boolean = false;
  positionId: any;
  deadlineDateValidator: any = [];
  dateValidationError: boolean = false;
  showMore: boolean = false;
  sliderOptions: Options = {
    floor: 1,
    ceil: 10,
    step: 0.1,
    disabled: false,
    ticksArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    translate: (value: number): string => {
      return value.toString()
    },
    getTickColor: (value: number): string => {
      switch (true) {
        case value <= 1:
          return '#ff0000';
        case value < 2:
          return '#ff3300';
        case value < 3:
          return '#ff6600';
        case value < 4:
          return '#ff9900';
        case value < 5:
          return '#ffcc00';
        case value < 6:
          return '#ffff00';
        case value < 7:
          return '#ccff00';
        case value < 8:
          return '#99ff00';
        case value < 9:
          return '#66ff00';
        default:
          return '#33ff00';
      }
    },
  }
  isJobBoard: string | null;
  isSearchTextFilled: boolean = false;
  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private hiringService: HiringTeamService,
    private router: Router,
    private positionService: PositionService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private ngZone: NgZone,
    private apiService: MapService
  ) {}
  initAutocomplete(maps: Maps) {
    let autocomplete = new maps.places.Autocomplete(
      this.searchElementRef.nativeElement
    );
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        this.positionFirstForm.controls['work_location'].setValue(autocomplete.getPlace().formatted_address);
      });
    });
  }
  ngAfterViewInit(){
    this.apiService.api.then((maps) => {
      this.initAutocomplete(maps);
    });
  }
  ngOnInit(): void {
    this.progress_percent = '33%'
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    let id = localStorage.getItem('editPosition');
    this.positionId = id;
    this.isEditMode = id ? true : false;
    this.getHMData();
    localStorage.removeItem('editPosition');
    this.createNewPositionForm()
    this.boxSelected(1);
    this.isJobBoard = localStorage.getItem('isQJobBoardEnabled');
  }
  getPositionById(id: any) {
    if (id) {
      this.positionService.getPositionById(id).subscribe((val: any) => {
        if (val) {
          this.fillFormData(val.data);
        }
      });
    }
  }
  fillFormData(data: any) {
    this.JDparsed = true;
    for (var key in data) {
      this.positionFirstForm.controls['document']?.setValue(data['document']);
      if (data.hasOwnProperty(key)) {
        if (key == 'htm_deadlines') {  
          this.createHTMData(data[key]);
          break;
        }

        if (key == 'position_type') {
          if (data[key] == 'not sure') {
            this.boxSelected(1);
          }
          if (data[key] == 'it') {
            this.boxSelected(2);
          }
          if (data[key] == 'offline') {
            this.boxSelected(3);
          }

        }
        if (key == 'salary_range') {
          if (data[key] == '') {
            this.range1 = '';
            this.range2 = '';
          }
          else {
            let s = data[key].split(",");
            this.range1 = s[0];
            this.range2 = s[1];
          }
        }
        if (key == 'skillsets') {
          const skillsetsControl = this.positionFirstForm.controls[key] as FormArray;
          data[key].forEach((element: any, index: any) => {
            skillsetsControl.push(this.fillSkillsetsData(element));
          });
          console.log(skillsetsControl);
        }
        else {
          this.positionFirstForm.controls[key]?.setValue(data[key]);
        }
      }
    }  
  }
  showMoreSkills() {
    this.showMore = true;
  }
  fillSkillsetsData(data: any) {
    return this.fb.group({
      skillset_name: [data.skillset_name, Validators.required],
      skillset_weightage: [data.skillset_weightage, Validators.required],
      skillset_questions: this.fb.array(data.skillset_questions
        .filter((qs: any) => qs !== '')
        .map((qs: any) => {
          return this.fb.group({
            text: [qs.text, Validators.required],
            fixed: [qs.fixed]
          })
        })
      )
    })
  }
  addSkill() {
    const positionTitle = this.positionFirstForm.controls['position_title'].value;
    this.positionService.getSingleDataFromJD({ jd: positionTitle }).subscribe((res: any) => {
      const skillsets = this.positionFirstForm.get('skillsets') as FormArray;
      skillsets.push(this.addSkillsetsData(res.skill));
    });
  }
  addSkillsetsData(data: any) {
    return this.fb.group({
      skillset_name: [data.skill, Validators.required],
      skillset_weightage: [data.skillset_weightage, Validators.required],
      skillset_questions: this.fb.array(data.questions
        .filter((qs: any) => qs !== '')
        .map((qs: any) => {
          return this.fb.group({
            text: [qs, Validators.required],
            fixed: [false]
          })
        })
      )
    })
  }
  createHTMData(data: any) {
    let i = 0, j = 0;
    for (i = 0; i < this.hmData.length; i++) {
      for (j = 0; j < data.length; j++) {
        if (data[j].htm == this.hmData[i].id) {
          let val: any = this.hmData[i]
          val.deadlineDate = data[j].deadline
          val.skillset = data[j].weightages
          this.selectedHM.push(val)
        }
      }
    }

  }
  increaseCandidates() {
    this.positionFirstForm.controls['no_of_open_positions'].patchValue(this.positionFirstForm.controls['no_of_open_positions'].value + 1);
  }
  decreaseCandidates() {
    if (this.positionFirstForm.controls['no_of_open_positions'].value > 1) {
      this.positionFirstForm.controls['no_of_open_positions'].patchValue(this.positionFirstForm.controls['no_of_open_positions'].value - 1);
    }
  }

  increaseSkillWeitage(i: any) {
    if (this.positionFirstForm.value.skillsets[i].skillset_weightage < 10) {
      this.getSkillsetWeightageControls(i)?.setValue(this.getSkillsetWeightageControls(i)?.value + 1)
    }
  }
  decreaseSkillWeitage(i: any) {
    if (this.positionFirstForm.value.skillsets[i].skillset_weightage > 1) {
      this.getSkillsetWeightageControls(i)?.setValue(this.getSkillsetWeightageControls(i)?.value - 1)
    }
  }
  getSkillsetWeightageControls(i: any) {
    return (this.positionFirstForm.get('skillsets') as FormArray).controls[i].get('skillset_weightage')
  }
  checkSkillSetMarks(i: number, $event: any) {
    this.getSkillsetWeightageControls(i)?.setValue?.($event)
  }
  getSWControls() {
    return (this.positionFirstForm.get('skillsets') as FormArray).controls;
  }
  getHMRole(e: any): any {
    if (e == 'Senior Manager') {
      return 'SM'
    }
    if (e == 'Hiring Member') {
      return 'HM'
    }
    if (e == 'Client Admin') {
      return 'CA'
    }
  }
  onFileChange(event: any) { 
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // if (file.size > 1000000) {
      //   this.uploadedFileSize = file.size;
      //   return;
      // } else {
      //   this.uploadedFileSize = null;
      // }
      this.positionFirstForm.controls['document'].setValue(event.target.files[0]);
      this.positionService.saveUploadDocument(file);
    }
  }
  clearDocument(){
    this.positionFirstForm.controls['document'].setValue('');
  }
  selectHM(item: any, e: any) {
    let i = 0;
    for (i = 0; i < this.selectedHM.length; i++) {
      if (this.selectedHM[i].id == item.id) {
        this.selectedHM.splice(i, 1);
        return;
      }
    }
    this.selectedHM.push(item)
  }

  removeHtm(i: any) {
    this.selectedHM.splice(i, 1);
  }
  sortTable(column: any): void {
    if (this.sortKey == column) {
      this.reverse = !this.reverse;
    } else {
      this.sortKey = column;
      this.reverse = false;
    }

    this.selectedHM.sort((a: any, b: any) => {
      var x = a[column];
      var y = b[column];
      x = x.toString();
      y = y.toString();
      return this.reverse ? y.localeCompare(x) : x.localeCompare(y);
    });
  }

  onDateSelect(e: any) {
    const d = new Date(e.year, e.month - 1, e.day);

    if (new Date(this.positionFirstForm.value.sourcing_deadline) <= new Date(this.positionFirstForm.value.kickoff_start_date)
      || new Date(this.positionFirstForm.value.target_deadline) <= new Date(this.positionFirstForm.value.sourcing_deadline)) {
      this.dateValidationError = true;
      this.createPosition2Form.controls.target_deadline.setErrors({ 'incorrect': true });
      return;
    } else {
      this.dateValidationError = false;
      this.createPosition2Form.controls.target_deadline.setErrors(null);
    }


  }
  onhtmDateSelect(e: any, i: any) {
    this.checkHtmDeadline(e, i);
    const str = e.month + '-' + e.day + '-' + e.year
    this.selectedHM[i].deadlineDate = str;
  }
  checkHtmDeadline(e: any, i: any) {
    const d = new Date(e.year, e.month - 1, e.day);
    if (new Date(d) <= new Date(this.positionFirstForm.value.kickoff_start_date) || new Date(d) >= new Date(this.positionFirstForm.value.target_deadline)) {
      this.deadlineDateValidator[i] = true;
    }
    else {
      this.deadlineDateValidator[i] = false;
    }
  }
  saveHtmWeightage() {
    this.currentHTMWeightage
    this.selectedHM[this.htmIndex].skillset = this.currentHTMWeightage
    this.modalService.dismissAll();
  }
  decreaseWeight(index: any) {
    if (this.currentHTMWeightage[index].skillset_weightage > 1)
      this.currentHTMWeightage[index].skillset_weightage = this.currentHTMWeightage[index].skillset_weightage - 1
  }
  increaseWeight(index: any) {
    if (this.currentHTMWeightage[index].skillset_weightage < 10)
      this.currentHTMWeightage[index].skillset_weightage = this.currentHTMWeightage[index].skillset_weightage + 1
  }
  editWeight(i: any, mod: any) {
    //if block when editing a position
    if (this.selectedHM[i].skillset && this.selectedHM[i].skillset.length > 0) {
      //when skill set added and need to reflect in weightage
      if (this.selectedHM[i].skillset.length < this.positionFirstForm.value.skillsets.length) {
        this.currentHTMWeightage = [];
        this.htmIndex = '';

        for (let j = this.selectedHM[i].skillset.length; j < this.positionFirstForm.value.skillsets.length; j++) {
          this.selectedHM[i].skillset[j] = {
            'skillset_name': '',
            'skillset_weightage': ''
          }
          this.selectedHM[i].skillset[j].skillset_name = this.positionFirstForm.value.skillsets[j].skillset_name;
          this.selectedHM[i].skillset[j].skillset_weightage = 10
        }
      }
      //when skill set deleted and need to reflect in weightage
      if (this.selectedHM[i].skillset.length > this.positionFirstForm.value.skillsets.length) {
        let ss = this.selectedHM[i].skillset;
        this.selectedHM[i].skillset = [];
        for (let j = 0; j < this.positionFirstForm.value.skillsets.length; j++) {
          this.selectedHM[i].skillset[j] = {
            'skillset_name': '',
            'skillset_weightage': ''
          }
          this.selectedHM[i].skillset[j].skillset_name = this.positionFirstForm.value.skillsets[j].skillset_name;
          this.selectedHM[i].skillset[j].skillset_weightage = ss[j].skillset_weightage
        }
      }
      this.currentHTMWeightage = this.selectedHM[i].skillset;
      this.htmIndex = i;
      this.modalService.open(mod, { size: 'md' });
    }
    //else block when creating a position
    else {
      this.currentHTMWeightage = [];
      this.htmIndex = '';

      this.selectedHM[i].skillset = [];
      for (let j = 0; j < this.positionFirstForm.value.skillsets.length; j++) {
        this.selectedHM[i].skillset[j] = {
          'skillset_name': '',
          'skillset_weightage': ''
        }
        this.selectedHM[i].skillset[j].skillset_name = this.positionFirstForm.value.skillsets[j].skillset_name;
        this.selectedHM[i].skillset[j].skillset_weightage = 10
      }
      this.currentHTMWeightage = this.selectedHM[i].skillset
      this.selectedHM[i].skillset = [];
      this.htmIndex = i;
      this.modalService.open(mod, { size: 'md' });
    }

  }
  sendTheNewValue(event: any) {
    if (event.target.value == '') {
      this.newData = [];
      this.isSearchTextFilled = false
    }
    else {
      this.isSearchTextFilled = true
      if (event.target.value.indexOf('@') > -1) {
        this.newData = this.hmData.filter((v: any) =>
          v.email.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 || v.role.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1).slice(0, 10)

      }

      else {
        this.newData = this.hmData.filter((v: any) =>
          v.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 || v.role.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1).slice(0, 10)
      }
    }

  }
  createNewPositionForm() {
    this.positionFirstForm = this.fb.group({
      client_id: [''],
      position_type: [''],
      position_title: ['', Validators.required],
      no_of_open_positions: [1,
        [Validators.required],
      ],
      special_intruction: ['', Validators.required],
      currency: ['$'],
      salary_range: ['', Validators.required],
      work_location: [''],
      document: [''],
      drafted: [false],
      skillsets: this.fb.array([
      ]),

      kickoff_start_date: ['', Validators.required],
      sourcing_deadline: ['', Validators.required],
      target_deadline: ['', Validators.required],
      htm_deadlines: this.fb.array([]),
      job_posting: [false],
      jd_file: ['']
    });
  }

  uploadJD(event: any) {
    let file = event.target.files[0];
    if (!(file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
      this.toastService.show('Invalid file format. Please upload a PDF or Word file', {
        classname: 'bg-danger text-light',
        delay: 3000,
      });
      event.target.value = null;
      return
    }
    const formData = new FormData();
    formData.append("file", file);
    this.positionService.getPositionInfoFromJD(formData).subscribe((res: any) => {
      this.positionFirstForm.controls['position_title'].setValue(res.position_title);
      this.positionFirstForm.controls['special_intruction'].setValue(res.short_jd);
      this.positionFirstForm.controls['jd_file'].setValue(file);
      this.JDparsed = true;
      // this.notificationComponent.showSuccess('JD uploaded successfully');
      this.toastService.show('JD uploaded successfully', {
        classname: 'bg-success text-light',
        delay: 3000,
      });
    }, (err) => {
      event.target.value = null;
      console.log('error', err)
      this.toastService.toasts = [];
      this.toastService.show('Error parsing JD. Please upload again!', {
        classname: 'bg-warning text-light',
        delay: 3000,
      });
    })
  }

  addDummyQuestions() {
    const skillset_qs = ['What is your name?', 'What is your age?', 'What is your qualification?']
    const skillset = this.positionFirstForm.get('skillsets') as FormArray
    const skillset_questions = skillset.controls[0].get('skillset_questions') as FormArray
    skillset_qs.forEach((qs: string) => {
      skillset_questions.push(this.createQuestionFormControl(qs))
    });
    console.log(skillset_questions)
  }

  optionSelected() {
    this.JDparsed = true;
  }

  getSkillsetWeightage(i: number) {
    return (this.positionFirstForm.get('skillsets') as FormArray).controls[i].get('skillset_weightage')?.value
  }
  clearQuestionSuggestion(i: number) {
    const skillset = this.positionFirstForm.get('skillsets') as FormArray
    const skillset_questions = skillset.controls[i].get('skillset_questions') as FormArray
    const checkMarked = skillset_questions.controls.filter((qs: any) => qs.get('fixed')?.value)
    skillset_questions.clear()
    checkMarked.forEach((qs: any) => {
      skillset_questions.push(qs)
    })
  }
  refetch(i: any, j: any) {
    const skillset_questions = this.getQuestionControls(i)
    const skillset_name = this.positionFirstForm.value.skillsets[i].skillset_name
    this.positionService.getSingleQuestionBy(skillset_name).subscribe((res: any) => {
      skillset_questions.controls[j].get('text')?.setValue(res.question)
    });
  }
  getQuestionSuggestions(i: number) {
    const payload = this.positionFirstForm.value.skillsets[i].skillset_name
    if (!payload) {
      this.positionFirstForm.get('skillsets')?.get([i])?.get('skillset_name')?.setErrors({ touchedByQbrains: true })
      this.positionFirstForm.get('skillsets')?.get([i])?.get('skillset_name')?.markAsTouched()
      return
    };
    this.positionService.getSuggestedQuestions(payload).subscribe((res: any) => {
      const skillset = this.positionFirstForm.get('skillsets') as FormArray
      const skillset_questions = skillset.controls[i].get('skillset_questions') as FormArray
      res.questions.forEach((qs: string) => {
        skillset_questions.push(this.createQuestionFormControl(qs))
      });
      console.log(skillset.controls[i].get('skillset_questions'))
    });
  }
  createQuestionFormControl(qs: string) {
    return this.fb.group({
      text: [qs, Validators.required],
      fixed: [false]
    })
  }
  removeSkill(i) {
    const skillset = this.positionFirstForm.get('skillsets') as FormArray
    skillset.removeAt(i)
  }
  saveQuestion(e: any, i: number, j: number) {
    const skillset_questions = this.getQuestionControls(i)
    skillset_questions.controls[j].get('text')?.setValue(e.currentTarget.textContent)
    console.log(skillset_questions)
  }
  addQuestion(i: number) {
    const skillset_Questions = this.getQuestionControls(i)
    const skillset_name = this.positionFirstForm.value.skillsets[i].skillset_name
    this.positionService.getSingleQuestionBy(skillset_name).subscribe((res: any) => {
      skillset_Questions.push(this.createQuestionFormControl(res.question))
    });
  }
  toggleQuestionFix(i: number, j: number) {
    const skillset_questions = this.getQuestionControls(i)
    const fixed = skillset_questions.controls[j].get('fixed')?.value
    skillset_questions.controls[j].get('fixed')?.setValue(!fixed)
  }
  removeQuestion(i: number, j: number) {
    const skillset = this.positionFirstForm.get('skillsets') as FormArray
    const skillset_questions = skillset.controls[i].get('skillset_questions') as FormArray
    skillset_questions.removeAt(j)
  }
  getQuestion(i: any) {
    const skillset = this.positionFirstForm.get('skillsets') as FormArray
    return skillset.controls[i].get('skillset_questions')?.value
  }
  getQuestionControls(i: any) {
    const skillset = this.positionFirstForm.get('skillsets') as FormArray
    return skillset.controls[i].get('skillset_questions') as FormArray
  }


  checkStep2Validations(): boolean {
    let val = (this.positionFirstForm.controls['position_title'].invalid)
    let val2 = ((this.range1 == '' || this.range2 == '') || parseInt(this.range1) >= parseInt(this.range2) || this.range1 == '0' || this.range2 == '0');
    return val || val2
  }
  checkStep3Validations(): boolean {
    if (this.positionFirstForm.controls.skillsets.invalid)
      return true
    else
      return false;
  }
  checkStep4Validations(): boolean {
    let val: boolean = false;
    for (let i = 0; i < this.selectedHM.length; i++) {
      val = this.selectedHM[i].deadlineDate && (this.selectedHM[i].skillset && this.selectedHM[i].skillset.length > 0 && this.selectedHM[i].skillset.length === this.positionFirstForm.value.skillsets.length)
    }
    let tval = (!val || this.positionFirstForm.controls['kickoff_start_date'].invalid || this.positionFirstForm.controls['sourcing_deadline'].invalid
      || this.positionFirstForm.controls['target_deadline'].invalid)
    return tval
  }
  addHtmDetails(): FormGroup {
    return this.fb.group({
      htm: [''],
      deadline: [''],
      weightages: ['']
    })
  }
  getControls() {
    return (this.positionFirstForm.get('skillsets') as FormArray).controls;
  }
  upDateOpenPosition() {
    this.allowNavigation = true;
    this.addDeadlineValues(this.selectedHM)
    let clientId: any = localStorage.getItem('clientId');
    this.positionFirstForm.controls['client_id'].setValue(clientId)
    this.getInDateFormat();
    this.positionFirstForm.controls['drafted'].setValue(false);
    let dataValue: any;
    dataValue = this.positionFirstForm.value;
    console.log(dataValue)
    dataValue.op_id = this.positionId
    this.positionService.updatePosition(dataValue).subscribe(
      (data) => {
        if (data) {
          localStorage.removeItem('editPosition');
          this.showSuccess('Position updated successfully');
          setTimeout(() => { this.router.navigate([this.authService.currentUserRoleCode + '/view-all-positions']); }, 2000);
        }
      },
      (error) => {
        this.showDanger('Position creation failed, Please try again');
        localStorage.removeItem('editPosition');
        setTimeout(() => { this.router.navigate([this.authService.currentUserRoleCode + '/view-all-positions']); }, 2000);
        // setTimeout(() => {this.router.navigate([this.authService.currentUserRoleCode + '/dashboard']);}, 2000);
      }
    );
  }

  allowNavigation = false;
  createOpenPosition(con: boolean) {
    this.allowNavigation = true;
    this.addDeadlineValues(this.selectedHM)
    // this.combineFormData['client_id'] = JSON.parse(clientId);
    let clientId: any = localStorage.getItem('clientId');
    this.positionFirstForm.controls['client_id'].setValue(clientId)
    this.getInDateFormat()
    this.positionFirstForm.controls['job_posting'].setValue(con)
    this.positionFirstForm.controls['drafted'].setValue(false);
    this.positionService.createPosition(this.positionFirstForm.value).subscribe(
      (data: any) => {
        if (data) {
          this.modalService.dismissAll()
          localStorage.removeItem('editPosition');
          this.showSuccess('Position created successfully');
          setTimeout(() => { this.router.navigate([this.authService.currentUserRoleCode + '/view-all-positions']); }, 2000);
        }
      },
      (error: any) => {
        this.modalService.dismissAll()
        localStorage.removeItem('editPosition');
        this.showDanger('Position creation failed, Please try again');
        setTimeout(() => { this.router.navigate([this.authService.currentUserRoleCode + '/view-all-positions']); }, 2000);
      }
    );
  }
  createOpenPositionModal(jobPosting: any) {
    if (this.isJobBoard == 'true')
      this.modalService.open(jobPosting, { size: 'md' });
    else {
      this.createOpenPosition(false)
    }
  }
  getInDateFormat() {
    if (this.positionFirstForm.value.kickoff_start_date) {
      let d = this.positionFirstForm.value.kickoff_start_date.split("-");
      let newDate = d[2] + '-' + d[0] + '-' + d[1];
      this.positionFirstForm.controls['kickoff_start_date'].setValue(newDate)
    }
    else {
      this.positionFirstForm.controls['kickoff_start_date'].setValue('')
    }
    if (this.positionFirstForm.value.sourcing_deadline) {
      let d = this.positionFirstForm.value.sourcing_deadline.split("-");
      let newDate = d[2] + '-' + d[0] + '-' + d[1];
      this.positionFirstForm.controls['sourcing_deadline'].setValue(newDate)
    }
    else {
      this.positionFirstForm.controls['sourcing_deadline'].setValue('')
    }
    if (this.positionFirstForm.value.target_deadline) {
      let d = this.positionFirstForm.value.target_deadline.split("-");
      let newDate = d[2] + '-' + d[0] + '-' + d[1];
      this.positionFirstForm.controls['target_deadline'].setValue(newDate)
    }
    else {
      this.positionFirstForm.controls['target_deadline'].setValue('')
    }
  }
  focusOutFunction1(e: any) {
    this.range1 = e.target.value;
  }
  getSecValue(e: any) {
    this.range2 = e.target.value;
  }
  focusOutFunction2() {
    if ((parseInt(this.range1) > 0) && !(this.calRange())) {
      this.positionFirstForm.controls['salary_range'].setValue(parseInt(this.range1) + ',' + parseInt(this.range2))
    }
  }
  seeNegative(): boolean {
    if (this.range1 == '' && this.range2 == '') {
      return true;
    }
    var pattern = /^\d+$/;
    let a = pattern.test(this.range1);  // returns a boolean
    let b = pattern.test(this.range2);
    return a && b;
  }
  calRange(): boolean {
    return parseInt(this.range1) >= parseInt(this.range2)
  }

  addDeadlineValues(member: any) {
    const htm_deadlines = this.positionFirstForm.controls.htm_deadlines as FormArray;
    for (let i = 0; i < member.length; i++) {
      if (member[i].deadlineDate) {
        let d = member[i].deadlineDate.split("-");
        let newDate = d[2] + '-' + d[0] + '-' + d[1];
        member[i].deadlineDate = newDate;
      }
      htm_deadlines.push(
        this.fb.group({
          htm: [member[i].id, Validators.required],
          deadline: [member[i].deadlineDate || '', Validators.required],
          weightages: [member[i].skillset],
        })
      )
    }

  }
  showSuccess(msg: string) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 3000,
    });
  }

  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }
  boxSelected(value: any) {
    this.box1selected = false;
    this.box2selected = false;
    this.box3selected = false
    if (value == 1) {
      this.box1selected = true;
      this.positionFirstForm.controls['position_type'].setValue('not sure')
    }
    if (value == 2) {
      this.box2selected = true;
      this.positionFirstForm.controls['position_type'].setValue('it')
    }
    if (value == 3) {
      this.box3selected = true;
      this.positionFirstForm.controls['position_type'].setValue('offline')
    }
  }
  goToStepOne() {
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
    this.progress_percent = '33%'
  }
  posDetails() {
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
    this.progress_percent = '50%'
  }
  changeToSkillStep() {
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;
    this.step4 = false;
    this.progress_percent = '66%'
  }
  skillDetails() {
    if (this.positionFirstForm.get('skillsets')?.value.length === 0) {
      const jd = this.positionFirstForm.value.position_title + ': ' + this.positionFirstForm.value.special_intruction;
      this.positionService.getSkillsets({ jd }).subscribe((val: any) => {
        const formdata = val.skill.map((skill: any) => {
          const questions = skill.questions.map((question: any) => {
            return this.fb.group({
              text: question,
              fixed: false
            })
          })
          return this.fb.group({
            skillset_name: skill.skill,
            skillset_weightage: 0,
            skillset_questions: this.fb.array(questions)
          })
        })
        const skillsetsArray = this.positionFirstForm.get('skillsets') as FormArray;
        formdata.forEach((skillset: FormGroup) => {
          skillsetsArray.push(skillset);
        });
        this.changeToSkillStep()
      })
    } else {
      this.changeToSkillStep()
    }
  }

  timelineDetails() {
    this.step1 = false;
    this.step2 = false;
    this.step3 = true;
    this.step4 = false;
    this.progress_percent = '100%'
  }
  step1value(): any {
    if (this.positionFirstForm.value.position_type == 'not sure') {
      return "I'm Not sure"
    }
    if (this.positionFirstForm.value.position_type == 'it') {
      return "IT Related"
    }
    if (this.positionFirstForm.value.position_type == 'offline') {
      return "Offline Interview"
    }
  }
  clearSuggestion() {
    const inputElement = document.getElementById('searchbox') as HTMLInputElement;
    inputElement.value = '';
    this.newData = []
    this.isSearchTextFilled = false;
  }
  getHMData() {
    let cl = localStorage.getItem('clientId')
    this.hiringService.getHiringMembersData(cl).subscribe((val: any) => {
      this.hmData = val.data;
      if (this.isEditMode) {
        let id = this.positionId;
        this.getPositionById(id);
      }
    })
  }
  formatDate(inputDate: any) {
    // Create a new Date object from the input string
    var date = new Date(inputDate);

    // Define months array to convert numeric month to its name
    var months = [
      "January", "February", "March", "April",
      "May", "June", "July", "August",
      "September", "October", "November", "December"
    ];

    // Extract day, month, and year from the Date object
    var day = date.getDate();
    var month = months[date.getMonth()];
    var year = date.getFullYear();

    // Create the formatted date string
    var formattedDate = month + ' ' + day + ',' + ' ' + year;

    return formattedDate;
  }
  @ViewChild('confirmToNavigatePopup') confirmToNavigatePopup: ElementRef;

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.allowNavigation) {
      const modalRef = this.modalService.open(this.confirmToNavigatePopup, { size: 'md' });
      return modalRef.result.then((result) => {
        return result === 'confirm';
      }, (reason) => {
        // console.log(reason)
        return reason === 'confirm';;
      });
    }
    return this.allowNavigation
  }
  saveAsDraft() {
    this.allowNavigation = true;
    this.addDeadlineValues(this.selectedHM)
    let clientId: any = localStorage.getItem('clientId');
    this.positionFirstForm.controls['client_id'].setValue(clientId)
    this.getInDateFormat()
    this.positionFirstForm.controls['drafted'].setValue(true);
    this.positionService.createPosition(this.positionFirstForm.value).subscribe(

      (data: any) => {
        if (data) {
          localStorage.removeItem('editPosition');
          this.showSuccess('Position saved as draft');
          setTimeout(() => { this.router.navigate([this.authService.currentUserRoleCode + '/view-all-positions']); }, 2000);
        }
      },
      (error: any) => {
        localStorage.removeItem('editPosition');
        this.showDanger('Position creation failed, Please try again');
        setTimeout(() => { this.router.navigate([this.authService.currentUserRoleCode + '/view-all-positions']); }, 2000);
      }
    );
  }
  saveAsDraftUpdate() {
    this.positionFirstForm.controls['drafted'].setValue(true);
    this.allowNavigation = true;
    this.addDeadlineValues(this.selectedHM)
    let clientId: any = localStorage.getItem('clientId');
    this.positionFirstForm.controls['client_id'].setValue(clientId)
    this.getInDateFormat();
    let dataValue: any;
    dataValue = this.positionFirstForm.value;
    dataValue.op_id = this.positionId
    this.positionService.updatePosition(dataValue).subscribe(
      (data) => {
        if (data) {
          localStorage.removeItem('editPosition');
          this.showSuccess('Position saved as draft successfully');
          setTimeout(() => { this.router.navigate([this.authService.currentUserRoleCode + '/view-all-positions']); }, 2000);
        }
      },
      (error) => {
        this.showDanger('Position creation failed, Please try again');
        localStorage.removeItem('editPosition');
        setTimeout(() => { this.router.navigate([this.authService.currentUserRoleCode + '/view-all-positions']); }, 2000);
        // setTimeout(() => {this.router.navigate([this.authService.currentUserRoleCode + '/dashboard']);}, 2000);
      }
    );
  }
  getDraftButtonStatus() {
    // return false is position_title is empty
    return this.positionFirstForm.controls.position_title.value === '';
  }
  isSelectedHM(data: any) {
    const isObjectPresent = this.selectedHM.find((o) => o.id === data.id);
    if (isObjectPresent)
      return true
    else
      return false
  }
}
