<div class="page-header">
    <div class="row page-header-status" style="margin-left: 0px;" *ngIf="(CurrentpageURL.indexOf('position-details') > -1) || (CurrentpageURL.indexOf('view-all-positions') > -1)  || (CurrentpageURL.indexOf('new-position') > -1) || userspage
                 || (CurrentpageURL.indexOf('my-calender') > -1) || (CurrentpageURL.indexOf('add-new-candidate') > -1)">
        <div class="col-sm-2 logo-box">
            <figure class="logo-pic" style="margin-bottom: 0;">
                <img class="logo-image" [src]="logo" />
                <!-- <p *ngIf="activeClientName" class="text-center company-name">{{activeClientName}}</p> -->
            </figure>

        </div>

        <div class="col-sm-3 title-box" *ngIf="(CurrentpageURL.indexOf('position-details') > -1) || (CurrentpageURL.indexOf('add-new-candidate') > -1)">
            <p *ngIf="selectPosition?.position_title" class="page-title">{{selectPosition?.position_title}}</p>
            <!-- <div class="status">
                        Status: <span class="s-delayed">{{selectPosition?.deadline ? 'Delayed' : 'On Schedule' }}</span> 
                        <span class="sub-title">Completion Date: {{selectPosition?.formated_target_deadline}}</span>
                    </div> -->
            <p *ngIf="selectPosition?.deadline"> <span style="font-weight: 600;">Status:
                </span><span class=" status-msg-d">{{selectPosition?.deadline ? 'DELAYED' : 'ON SCHEDULE' }}</span>
            </p>
            <p *ngIf="!selectPosition?.deadline"><span style="font-weight: 600;"> Status:
                </span><span class=" status-msg-s">{{selectPosition?.deadline ? 'DELAYED' : 'ON SCHEDULE' }}</span>
            </p>
            <p><span style="font-weight: 600;">Completion Date: </span>
                {{selectPosition?.target_deadline | formatDate}}</p>

        </div>

        <div class="col-sm-3 pos-data-box" *ngIf="(CurrentpageURL.indexOf('position-details') > -1) || (CurrentpageURL.indexOf('add-new-candidate') > -1)">
            <p class="page-sub-title"><span class="intendation">Number of Positions </span><span
                    class="fw-bold">:</span>
                {{(selectPosition?.no_of_open_positions > 9) ? selectPosition?.no_of_open_positions :
                '0'+selectPosition?.no_of_open_positions}}</p>
            <p class="page-sub-title"><span class="intendation">Positions Filled </span><span class="fw-bold">:</span>
                {{(selectPosition?.position_filled > 9) ? selectPosition?.position_filled : '0' +
                selectPosition?.position_filled}}</p>
            <!-- <p class="page-sub-title"><span class="intendation">Hiring Team </span>: {{selectPosition?.hiring_team}}</p> -->
        </div>
    </div>
    <div class="row navbar-row" *ngIf="(CurrentpageURL.indexOf('position-details') > -1) ">
        <div class="col-sm-2 client-name-box">
            <div>{{activeClientName}}</div>
        </div>
        <div class="col-sm-10 nav-bar-col">
            <nav class="navbar navbar-expand-lg navbar-light navigation-bar">
                <!-- <a class="nav-link text-light link-size" href="#" style="padding-top: 0.3rem; font-weight: 600;" >View All Positions</a> -->
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav ms-auto nav-ul">
                        <li class="nav-item " [class]="{'active': current_tab == 'progress-summary' }">
                            <a class="nav-link text-light link-size" (click)="changeTab('progress-summary')"
                                href="javascript:void(0)">Progress Summary <span class="sr-only"></span></a>
                        </li>
                        <li class="nav-item" [class]="{'active': current_tab == 'Calendar' }">
                            <a class="nav-link text-light link-size" (click)="changeTab('Calendar')"
                                href="javascript:void(0)">Calendar</a>
                        </li>
                        <li class="nav-item" [class]="{'active': current_tab == 'Insights' }">
                            <a class="nav-link text-light link-size" (click)="changeTab('Insights')"
                                href="javascript:void(0)">Insights</a>
                        </li>
                        <!-- <li class="nav-item" [ngClass]="{activeLink: isActive('edit')}">
                                <a class="nav-link text-light link-size" (click)="emitAction('edit')" href="javascript:void(0)">Edit Position</a>
                            </li> -->
                        <li *ngIf="userRole!=='Hiring Member'" class="nav-item"
                            [class]="{'active': current_tab == 'add-candidate' }">
                            <a class="nav-link text-light link-size" (click)="changeTab('add-candidate')"
                                href="javascript:void(0)">Candidate Pool</a>
                        </li>
                        <!-- <li class="nav-item" >
                                <a class="nav-link text-light link-size"  href="javascript:void(0)">Position Details</a>
                            </li> -->
                        <li *ngIf="currentUserRoleCode!='/htm'" class="nav-item "
                            style="padding-left: 1em;padding-right: 1em;">
                            <div class="dropdown">
                                <a class="nav-link text-light link-size" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true"
                                    href="javascript:void(0)"><fa-icon
                                        [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '25px'}"
                                        [icon]="faEllipsisH"></fa-icon></a>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style="left: -100px;">
                                    <li><a *ngIf="positionList.length == 1" class="dropdown-item"
                                            (click)="editPosition()" href="javascript:void(0)">Edit</a></li>
                                    <li><a class="dropdown-item" (click)="editPositionSummary()"
                                            href="javascript:void(0)">Edit Position</a></li>
                                    <li><a *ngIf="positionList.length == 1" class="dropdown-item"
                                            (click)="clonePosition()" href="javascript:void(0)">Clone / Copy</a></li>
                                    <li><a class="dropdown-item" (click)="archivePosition()"
                                            href="javascript:void(0)">Move to Archive</a></li>
                                    <li><a class="dropdown-item" (click)="trashPosition()"
                                            href="javascript:void(0)">Move to Trash</a></li>
                                </ul>
                            </div>
                        </li>

                    </ul>


                </div>
            </nav>

        </div>
    </div>

    <div class="row navbar-row" *ngIf="(CurrentpageURL.indexOf('add-new-candidate') > -1) ">
        <div class="col-sm-2 client-name-box">
            <div>{{activeClientName}}</div>
        </div>
        <div class="col-sm-10 nav-bar-col">
            <nav class="navbar navbar-expand-lg navbar-light navigation-bar">
                <!-- <a class="nav-link text-light link-size" href="#" style="padding-top: 0.3rem; font-weight: 600;" >View All Positions</a> -->
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav ms-auto nav-ul">
                        <li class="nav-item " >
                            <a class="nav-link text-light link-size" (click)="changeTab('progress-summary')"
                                href="javascript:void(0)">Progress Summary <span class="sr-only"></span></a>
                        </li>
                        <li class="nav-item" >
                            <a class="nav-link text-light link-size" (click)="changeTab('Calendar')"
                                href="javascript:void(0)">Calendar</a>
                        </li>
                        <li class="nav-item" >
                            <a class="nav-link text-light link-size" (click)="changeTab('Insights')"
                                href="javascript:void(0)">Insights</a>
                        </li>
                        <!-- <li class="nav-item" [ngClass]="{activeLink: isActive('edit')}">
                                <a class="nav-link text-light link-size" (click)="emitAction('edit')" href="javascript:void(0)">Edit Position</a>
                            </li> -->
                        <li *ngIf="userRole!=='Hiring Member'" class="nav-item active">
                            <a class="nav-link text-light link-size" (click)="changeTab('add-candidate')"
                                href="javascript:void(0)">Candidate Pool</a>
                        </li>
                        <!-- <li class="nav-item" >
                                <a class="nav-link text-light link-size"  href="javascript:void(0)">Position Details</a>
                            </li> -->
                        <li *ngIf="currentUserRoleCode!='/htm'" class="nav-item "
                            style="padding-left: 1em;padding-right: 1em;">
                            <div class="dropdown">
                                <a class="nav-link text-light link-size" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true"
                                    href="javascript:void(0)"><fa-icon
                                        [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '25px'}"
                                        [icon]="faEllipsisH"></fa-icon></a>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style="left: -100px;">
                                    <li><a *ngIf="positionList.length == 1" class="dropdown-item"
                                            (click)="editPosition()" href="javascript:void(0)">Edit</a></li>
                                    <li><a class="dropdown-item" (click)="editPositionSummary()"
                                            href="javascript:void(0)">Edit Position</a></li>
                                    <li><a *ngIf="positionList.length == 1" class="dropdown-item"
                                            (click)="clonePosition()" href="javascript:void(0)">Clone / Copy</a></li>
                                    <li><a class="dropdown-item" (click)="archivePosition()"
                                            href="javascript:void(0)">Move to Archive</a></li>
                                    <li><a class="dropdown-item" (click)="trashPosition()"
                                            href="javascript:void(0)">Move to Trash</a></li>
                                </ul>
                            </div>
                        </li>

                    </ul>


                </div>
            </nav>

        </div>
    </div>

    <div class="row navbar-row" style=" clear: both;" *ngIf="(CurrentpageURL.indexOf('view-all-positions') > -1) ">
        <div class="col-sm-2 client-name-box">
            <div>{{activeClientName}}</div>

        </div>
        <div class="col-sm-10 nav-bar-col">
            <nav class="navbar navbar-expand-lg navbar-light navigation-bar">
                <a class="nav-link text-light link-size pe-none" href="javascript:void(0)"><b>{{positionCount.open > 9 ?
                        positionCount.open :
                        '0'+positionCount.open}}</b> Open Positions </a>
                <a class="nav-link text-light link-size pe-none" href="javascript:void(0)"><b>{{positionCount.draft > 9
                        ?
                        positionCount.draft :
                        '0'+positionCount.draft}} </b> Draft Positions</a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav ms-auto nav-ul">
                        <li class="nav-item active">
                            <a class="nav-link text-light link-size" href="javascript:void(0)">All Positions</a>
                        </li>
                        <li class="nav-item"
                            *ngIf="CurrentpageURL.includes('/sm/') || CurrentpageURL.includes('/ca/') || CurrentpageURL.includes('/sa/')">
                            <a class="nav-link text-light link-size"
                                [routerLink]="currentUserRoleCode + '/new-position'" href="javascript:void(0)"> <span
                                    class="plus-icon"><fa-icon [icon]="faPlus"
                                        [styles]="{'stroke': 'var(--secondary)', 'color': 'var(--secondary)'}"></fa-icon></span>
                                Add Position</a>
                        </li>
                        <li class="nav-item">
                            <span class="search-icon">
                                <input type="text" class="" (input)="searchInput($event)" placeholder="Search"
                                    [(ngModel)]="query" id="listSearch">
                                <fa-icon [icon]="faSearch"
                                    [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF'}"></fa-icon>
                            </span>
                        </li>
                        <li *ngIf="currentUserRoleCode!='/htm'" class="nav-item "
                            [class]="{'more-options': positionList.length > 0 , 'no-options':positionList.length != 0}"
                            style="padding-left: 1em;padding-right: 1em;">

                            <div class="dropdown">
                                <a *ngIf="positionList.length == 0" class="nav-link text-light link-size"
                                    id="dropdownMenuButton1" href="javascript:void(0)"><fa-icon
                                        [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '25px'}"
                                        [icon]="faEllipsisH"></fa-icon></a>
                                <a *ngIf="positionList.length != 0" class="nav-link text-light link-size"
                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="true" href="javascript:void(0)"><fa-icon
                                        [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '25px'}"
                                        [icon]="faEllipsisH"></fa-icon></a>
                                <ul *ngIf="positionList.length != 0" class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton1" style="left: -100px;">
                                    <li><a *ngIf="positionList.length == 1" class="dropdown-item"
                                            (click)="editPosition()" href="javascript:void(0)">Edit</a></li>
                                    <li><a *ngIf="positionList.length == 1" class="dropdown-item"
                                            (click)="clonePosition()" href="javascript:void(0)">Clone / Copy</a></li>
                                    <li *ngIf="positionType!='Archive'"><a class="dropdown-item"
                                            (click)="editMulPositions('archieved')"
                                            href="javascript:void(0)">Archive</a></li>
                                    <li *ngIf="positionType!='Draft'"><a class="dropdown-item"
                                            (click)="editMulPositions('drafted')" href="javascript:void(0)">Draft</a>
                                    </li>
                                    <li><a class="dropdown-item" (click)="editMulPositions('trashed')"
                                            href="javascript:void(0)">Trash</a></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>

        </div>
    </div>

    <div class="row navbar-row" style=" clear: both;" *ngIf=" (CurrentpageURL.indexOf('view-all-users') > -1) ">
        <div class="col-sm-2 client-name-box" *ngIf="currentUserRoleCode=='/ca'">
            <div>{{activeClientName}}</div>
        </div>
        <div
            [class]="{'col-sm-10': currentUserRoleCode=='/ca',
                 'nav-bar-col': currentUserRoleCode=='/ca', 'col-sm-12': currentUserRoleCode=='/sa','pl-0': currentUserRoleCode=='/sa' }">
            <!-- <div class="col-sm-12 nav-bar-col"  *ngIf="currentUserRoleCode=='/sa'"> -->
            <nav class="navbar navbar-expand-lg navbar-light navigation-bar"
                *ngIf=" !(CurrentpageURL.indexOf('invite-users') > -1) ">
                <!-- <a class="nav-link text-light link-size" href="#" style="padding-top: 0.3rem; font-weight: 600;" >View All Positions</a> -->

                <!-- <a class="nav-link text-light link-size"  href="javascript:void(0)"><b>{{AllTableData.length > 9 ? AllTableData.length : '0'+AllTableData.length}}</b>  Open Positions </a>
                            <a class="nav-link text-light link-size"   href="javascript:void(0)"><b>{{draftedPositionList.length > 9 ? draftedPositionList.length : '0'+draftedPositionList.length}} </b> Draft Positions</a> -->
                <a class="nav-link text-light link-size pe-none" href="javascript:void(0)"><b>{{UsersCount.cand }}</b>
                    Candidates </a>
                <a class="nav-link text-light link-size pe-none" href="javascript:void(0)"><b>{{UsersCount.smList }}
                    </b>
                    Senior Managers</a>
                <a class="nav-link text-light link-size pe-none" href="javascript:void(0)"><b>{{UsersCount.hmList }}
                    </b>
                    Hiring Members</a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav ms-auto nav-ul">
                        <li class="nav-item active">
                            <a class="nav-link text-light link-size" href="javascript:void(0)">All Users</a>
                        </li>
                        <li *ngIf="currentUserRoleCode!='/htm'" class="nav-item">
                            <a class="nav-link text-light link-size" (click)="goToInvite()" href="javascript:void(0)">
                                <span class="plus-icon"><fa-icon [icon]="faPlus"
                                        [styles]="{'stroke': 'var(--secondary)', 'color': 'var(--secondary)'}"></fa-icon></span>
                                Invite User</a>
                        </li>
                        <li class="nav-item">
                            <span class="search-icon">
                                <input type="text" class="" (input)="searchInput($event)" placeholder="Search"
                                    [(ngModel)]="query" id="listSearch">
                                <fa-icon [icon]="faSearch"
                                    [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF'}"></fa-icon>
                            </span>
                        </li>
                        <li *ngIf="currentUserRoleCode!='/htm'" class="nav-item more-options"
                            [class]="{'more-options': UsersList.length != 0 && checkCurrentUser(), 'no-options':UsersList.length == 0}"
                            style="padding-left: 1em;padding-right: 1em;">

                            <div class="dropdown">
                                <a *ngIf="UsersList.length == 0 || !checkCurrentUser()" class="nav-link text-light link-size"
                                    href="javascript:void(0)"><fa-icon
                                        [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '25px'}"
                                        [icon]="faEllipsisH"></fa-icon></a>
                                <a *ngIf="UsersList.length != 0 && checkCurrentUser()" class="nav-link text-light link-size"
                                    id="dropdownMenuButtonU" data-bs-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="true" href="javascript:void(0)"><fa-icon
                                        [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '25px'}"
                                        [icon]="faEllipsisH"></fa-icon></a>
                                        <!-- *ngIf="UsersList.length != 0 && checkCurrentUser()" -->
                                <ul  class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButtonU" style="left: -100px;">
                                    <li  (click)="deleteUser()"><a class="dropdown-item"
                                            href="javascript:void(0)">Delete</a></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
            <nav class="navbar navbar-expand-lg navbar-light navigation-bar"
                *ngIf=" (CurrentpageURL.indexOf('invite-users') > -1) ">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav ms-auto nav-ul">
                        <li class="nav-item ">
                            <a class="nav-link text-light link-size" (click)="goToAllUsers()"
                                href="javascript:void(0)">All Users</a>
                        </li>
                        <li class="nav-item active">
                            <a class="nav-link text-light link-size" (click)="goToInvite()" href="javascript:void(0)">
                                <span class="plus-icon"><fa-icon [icon]="faPlus"
                                        [styles]="{'stroke': 'var(--secondary)', 'color': 'var(--secondary)'}"></fa-icon></span>
                                Invite User</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>

    <div class="row navbar-row" style=" clear: both;" *ngIf=" (CurrentpageURL.indexOf('new-position') > -1)">
        <div class="col-sm-2 client-name-box">
            <div>{{activeClientName}}</div>
        </div>
        <div class="col-sm-10 nav-bar-col">
            <nav class="navbar navbar-expand-lg navbar-light navigation-bar">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav ms-auto nav-ul">
                        <li class="nav-item ">
                            <a class="nav-link text-light link-size"
                                [routerLink]="currentUserRoleCode + '/view-all-positions'" href="javascript:void(0)">All
                                Positions</a>
                        </li>
                        <li class="nav-item active">
                            <a class="nav-link text-light link-size" href="javascript:void(0)">
                                <span class="plus-icon"><fa-icon [icon]="faPlus"
                                        [styles]="{'stroke': 'var(--secondary)', 'color': 'var(--secondary)'}"></fa-icon></span>
                                Add Position</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>

    <div class="row navbar-row" style=" clear: both;" *ngIf=" (CurrentpageURL.indexOf('my-calender') > -1)">
        <div class="col-sm-2 client-name-box">
            <div>{{activeClientName}}</div>
        </div>
        <div class="col-sm-10 nav-bar-col">
            <nav class="navbar navbar-expand-lg navbar-light navigation-bar">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav ms-auto nav-ul">
                        <li class="nav-item" [class]="{'active': current_tab_cal == '1' }">
                            <a class="nav-link text-light link-size" (click)="addAvailability(1)"
                                href="javascript:void(0)">My Calendar</a>
                        </li>
                        <li class="nav-item " [class]="{'active': current_tab_cal == '2' }">
                            <a class="nav-link text-light link-size" (click)="addAvailability(2)"
                                href="javascript:void(0)">
                                <span class="plus-icon"><fa-icon [icon]="faPlus"
                                        [styles]="{'stroke': 'var(--secondary)', 'color': 'var(--secondary)'}"></fa-icon></span>
                                Add Availability</a>
                        </li>
                        <li class="nav-item " [class]="{'active': current_tab_cal == '3' }">
                            <a class="nav-link text-light link-size" (click)="addAvailability(3)"
                                href="javascript:void(0)">
                                <span class="plus-icon"><fa-icon [icon]="faPlus"
                                        [styles]="{'stroke': 'var(--secondary)', 'color': 'var(--secondary)'}"></fa-icon></span>
                                My Availability</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>

    <div class="row navbar-row" *ngIf="(CurrentpageURL.indexOf('view-all-clients') > -1)">
        <div class="col-sm-12 pl-0">
            <nav class="navbar navbar-expand-lg navbar-light navigation-bar">

                <a class="nav-link text-light link-size pe-none" href="javascript:void(0)"><b>{{clientCount.active}}</b>
                    Active
                    Clients </a>
                <a class="nav-link text-light link-size pe-none"
                    href="javascript:void(0)"><b>{{clientCount.inactive}}</b>
                    Inactive Clients</a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav ms-auto nav-ul">
                        <li class="nav-item active">
                            <a class="nav-link text-light link-size" href="javascript:void(0)">Client List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-light link-size"
                                [routerLink]="currentUserRoleCode + '/dashboard/addClient'" href="javascript:void(0)">
                                <span class="plus-icon"><fa-icon [icon]="faPlus"
                                        [styles]="{'stroke': 'var(--secondary)', 'color': 'var(--secondary)'}"></fa-icon></span>
                                Add Client</a>
                        </li>
                        <li class="nav-item">
                            <span class="search-icon">
                                <input type="text" class="" (input)="searchInput($event)" placeholder="Search"
                                    [(ngModel)]="query" id="listSearch">
                                <fa-icon [icon]="faSearch"
                                    [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF'}"></fa-icon>
                            </span>
                        </li>

                        <li class="nav-item more-options"
                            [class]="{'more-options': clientsList.length > 0 , 'no-options':clientsList.length == 0}"
                            style="padding-left: 1em;padding-right: 1em;">
                            <div class="dropdown">
                                <a *ngIf="clientsList.length == 0" class="nav-link text-light link-size"
                                    href="javascript:void(0)"><fa-icon
                                        [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '25px'}"
                                        [icon]="faEllipsisH"></fa-icon></a>
                                <a *ngIf="clientsList.length != 0" (click)="analyseData()"
                                    class="nav-link text-light link-size" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true"
                                    href="javascript:void(0)"><fa-icon
                                        [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '25px'}"
                                        [icon]="faEllipsisH"></fa-icon></a>
                                <ul *ngIf="clientsList.length != 0" class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton1" style="left: -100px;">
                                    <li><a class="dropdown-item" *ngIf="clientsList.length == 1" (click)="editClient()"
                                            href="javascript:void(0)">Edit</a></li>
                                    <li *ngIf="showInactive"><a class="dropdown-item" (click)="suspendClient()"
                                            href="javascript:void(0)">Suspend</a></li>
                                    <li *ngIf="showActive"><a class="dropdown-item" (click)="activateClient()"
                                            href="javascript:void(0)">Activate</a></li>
                                    <li><a class="dropdown-item" (click)="deleteClient()"
                                            href="javascript:void(0)">Delete</a></li>
                                </ul>
                            </div>
                        </li>

                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>


<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Delete Client</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Do you want to delete client?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="deleteClient()">Yes</button>
    </div>
</ng-template>