<main class="settings-page">

    <div class="widget-wrapper mt-4">
        <div class="row">

            <!-- left navigation -->
            <div class="col-md-3">
                <ngb-accordion #outer="ngbAccordion" [closeOthers]="true" (panelChange)="toggleAccordian($event)"
                    activeIds="userDetails">
                    <ngb-panel id="userDetails" [collapsed]="true">
                        <ng-template ngbPanelTitle class="acc-heading">
                            <span ngbAccordionButton><i class="fa fa-caret-right fa-lg mr-2"
                                    *ngIf="openPanelId === 'userDetails'"></i>User Details</span>
                        </ng-template>
                    </ngb-panel>

                    <ngb-panel id="showAccDetails"
                        *ngIf="userRole =='Client Admin' && userRole !=='Superuser' && userRole !=='Account Manager' && userRole !=='Senior Manager'"
                        [collapsed]="false">
                        <ng-template ngbPanelTitle>
                            <span ngbAccordionButton><i class="fa fa-caret-right fa-lg mr-2"
                                    *ngIf="openPanelId === 'showAccDetails'"></i>Account Details</span>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <a class="link" [ngClass]="{'active': accountDetailsSection === 'companyInformation'}"
                                (click)="filterAccDetailsSection('companyInformation')"><i
                                    class="fa fa-caret-right fa-lg mr-2 text-light"
                                    *ngIf="accountDetailsSection === 'companyInformation'"></i>Company Information</a>
                            <a class="link" [ngClass]="{'active': accountDetailsSection === 'companyContacts'}"
                                (click)="filterAccDetailsSection('companyContacts')"><i
                                    class="fa fa-caret-right fa-lg mr-2 text-light"
                                    *ngIf="accountDetailsSection === 'companyContacts'"></i>Company Contacts</a>
                            <a class="link" [ngClass]="{'active': accountDetailsSection === 'accountLevels'}"
                                (click)="filterAccDetailsSection('accountLevels')"><i
                                    class="fa fa-caret-right fa-lg mr-2 text-light"
                                    *ngIf="accountDetailsSection === 'accountLevels'"></i>Account Levels</a>
                        </ng-template>
                    </ngb-panel>

                    <!-- TURN ON AFTER ADDING BILLING DETAILS ------------------------------- -->
                    <!-- <ngb-panel id="billingDetails"
                        *ngIf="userRole =='Client Admin' && userRole !=='Superuser' && userRole !=='Account Manager' && userRole !=='Senior Manager'"
                        [collapsed]="false">
                        <ng-template ngbPanelTitle>
                            <span ngbAccordionButton><i class="fa fa-caret-right fa-lg mr-2"
                                    *ngIf="openPanelId === 'billingDetails'"></i>Billing Details</span>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <a class="link" [ngClass]="{'active': billingSectionType === 'billingInformation'}"
                                (click)="filterBillingSection('billingInformation')">
                                <i class="fa fa-caret-right fa-lg mr-2 text-light"
                                    *ngIf="billingSectionType === 'billingInformation'"></i>Billing Information</a>
                            <a class="link" [ngClass]="{'active': billingSectionType === 'billingHistory'}"
                                (click)="filterBillingSection('billingHistory')">
                                <i class="fa fa-caret-right fa-lg mr-2 text-light"
                                    *ngIf="billingSectionType === 'billingHistory'"></i>Billing History</a>
                        </ng-template>
                    </ngb-panel> -->

                    <ngb-panel id="emailTempDetails" *ngIf="userRole =='Superuser' || userRole =='Account Manager'"
                        [collapsed]="false">
                        <ng-template ngbPanelTitle>
                            <span ngbAccordionButton><i class="fa fa-caret-right fa-lg mr-2"
                                    *ngIf="openPanelId === 'emailTempDetails'"></i>Email Templates</span>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <a class="link" [ngClass]="{'active': emailTemplateType === 'candidate'}"
                                (click)="filterEmailTemplate('candidate')"><i
                                    class="fa fa-caret-right fa-lg mr-2 text-light"
                                    *ngIf="emailTemplateType === 'candidate'"></i>Emails to Candidates</a>
                            <a class="link" [ngClass]="{'active': emailTemplateType === 'team'}"
                                (click)="filterEmailTemplate('team')"><i class="fa fa-caret-right fa-lg mr-2 text-light"
                                    *ngIf="emailTemplateType === 'team'"></i>Emails to your Team</a>
                            <a class="link" [ngClass]="{'active': emailTemplateType === 'system-email'}"
                                (click)="filterEmailTemplate('system-email')"><i
                                    class="fa fa-caret-right fa-lg mr-2 text-light"
                                    *ngIf="emailTemplateType === 'system-email'"></i>System Email</a>
                        </ng-template>
                    </ngb-panel>

                    <ngb-panel id="navigatorDetails" *ngIf="userRole =='Superuser' || userRole =='Account Manager'"
                        [collapsed]="false">
                        <ng-template ngbPanelTitle>
                            <span ngbAccordionButton><i class="fa fa-caret-right fa-lg mr-2"
                                    *ngIf="openPanelId === 'navigatorDetails'"></i>Navigator</span>
                        </ng-template>
                    </ngb-panel>

                    <ngb-panel id="proTipDetails" *ngIf="userRole =='Superuser'" [collapsed]="false">
                        <ng-template ngbPanelTitle>
                            <span ngbAccordionButton><i class="fa fa-caret-right fa-lg mr-2"
                                    *ngIf="openPanelId === 'proTipDetails'"></i>Pro-Tip Details</span>
                        </ng-template>
                    </ngb-panel>
                    <!-- Mit Code -->
                    <ngb-panel id="gloabal_pricing_setting"
                        *ngIf="userRole =='Superuser'  || userRole =='Account Manager' " [collapsed]="false">
                        <ng-template ngbPanelTitle>
                            <span ngbAccordionButton><i class="fa fa-caret-right fa-lg mr-2"
                                    *ngIf="openPanelId === 'gloabal_pricing_setting'"></i>Global Pricing Settings</span>
                        </ng-template>
                    </ngb-panel>

                    <ngb-panel id="clientAccounts" *ngIf="userRole =='Superuser'  || userRole =='Account Manager' "
                        [collapsed]="false">
                        <ng-template ngbPanelTitle>
                            <span ngbAccordionButton><i class="fa fa-caret-right fa-lg mr-2"
                                    *ngIf="openPanelId === 'clientAccounts'"></i>Client Accounts</span>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <!-- <div class="client_accordion" > -->
                            <ngb-accordion #inner="ngbAccordion" [closeOthers]="true"
                                (panelChange)="ClientAccountToggle($event)">
                                <ngb-panel *ngFor="let list of clientList" id="{{list.id}}" [collapsed]="true">
                                    <ng-template ngbPanelTitle>
                                        <span ngbAccordionButton><i class="fa fa-caret-right fa-lg mr-2"
                                                *ngIf="openPanelId == 'list.id'"></i>{{list.name}}</span>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <a class="link" [ngClass]="{'active': clientDetails === 'companyInformation'}"
                                            (click)="filterClientDetails('companyInformation')"><i
                                                class="fa fa-caret-right fa-lg mr-2 text-light"
                                                *ngIf="clientDetails === 'companyInformation'"></i>Company
                                            Information</a>
                                        <a class="link" [ngClass]="{'active': clientDetails === 'companyContacts'}"
                                            (click)="filterClientDetails('companyContacts')"><i
                                                class="fa fa-caret-right fa-lg mr-2 text-light"
                                                *ngIf="clientDetails === 'companyContacts'"></i>Company Contacts</a>
                                        <a class="link" [ngClass]="{'active': clientDetails === 'accountLevels'}"
                                            (click)="filterClientDetails('accountLevels')"><i
                                                class="fa fa-caret-right fa-lg mr-2 text-light"
                                                *ngIf="clientDetails === 'accountLevels'"></i>Account Level Settings</a>
                                        <a class="link" [ngClass]="{'active': clientDetails === 'billingInformation'}"
                                            (click)="filterClientDetails('billingInformation')">
                                            <i class="fa fa-caret-right fa-lg mr-2 text-light"
                                                *ngIf="clientDetails === 'billingInformation'"></i>Billing
                                            Information</a>
                                        <a class="link" [ngClass]="{'active': clientDetails === 'billingHistory'}"
                                            (click)="filterClientDetails('billingHistory')">
                                            <i class="fa fa-caret-right fa-lg mr-2 text-light"
                                                *ngIf="clientDetails === 'billingHistory'"></i>Billing History</a>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                            <!-- </div> -->
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="openPositionDetails" *ngIf="userRole =='Client Admin'" [collapsed]="false">
                        <ng-template ngbPanelTitle>
                            <span ngbAccordionButton><i class="fa fa-caret-right fa-lg mr-2"
                                    *ngIf="openPanelId === 'openPositionDetails'"></i>Open Position Details</span>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

            </div>

            <!-- Right Section -->
            <div class="col-md-9">
                <h2 class="widget-title">
                    {{showUserDetails ? 'User Details' : showAccDetails ? 'Account Details' : showBillingDetails ?
                    'Billing Details' : showProTipForm ? 'Pro-tip Details' : showEmailTempForm ? 'Email Templates' :
                    showGlobalPricing ? 'Global Pricing': showClientAccounts ? 'Client Accounts': showNavigator ?
                    'Navigator Details' : showopenPositionDetails ? 'Open Position Details':'Choose a option'}}
                </h2>

                <div class="widget-innerbox">
                    <div class="form-section">
                        <form [formGroup]="settingsForm" *ngIf="showUserDetails">
                            <fieldset class="scheduler-border">
                                <!-- <legend *ngIf="this.userRole !='Hiring Member' && userRole =='Superuser' && userRole =='Account Manager'"  class="pt-4">Basic Details</legend> -->
                                <div class="form-group row pt-5">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">First Name
                                        <small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="first_name">
                                        <div *ngIf="settingsForm.controls['first_name'].invalid && (settingsForm.controls['first_name'].dirty || settingsForm.controls['first_name'].touched) || settingFormHasError"
                                            class="alert">
                                            <div *ngIf="settingsForm.controls['first_name'].errors?.required">
                                                First Name is required.
                                            </div>
                                            <div *ngIf="settingsForm.controls['first_name'].errors?.pattern">
                                                Please enter valid name, only alphabets are allowed.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Last Name
                                        <small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="last_name">
                                        <div *ngIf="settingsForm.controls['last_name'].invalid && (settingsForm.controls['last_name'].dirty || settingsForm.controls['last_name'].touched) || settingFormHasError"
                                            class="alert">
                                            <div *ngIf="settingsForm.controls['last_name'].errors?.required">
                                                Last Name is required.
                                            </div>
                                            <div *ngIf="settingsForm.controls['last_name'].errors?.pattern">
                                                Please enter valid name, only alphabets are allowed.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Phone
                                        <small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="phone_number">
                                        <div *ngIf="settingsForm.controls['phone_number'].invalid && (settingsForm.controls['phone_number'].dirty || settingsForm.controls['phone_number'].touched) || settingFormHasError"
                                            class="alert">
                                            <div *ngIf="settingsForm.controls['phone_number'].errors?.required">
                                                Phone number is required.
                                            </div>
                                            <div *ngIf="settingsForm.controls['phone_number'].errors?.pattern">
                                                Phone number must be exactly 10 digit & only number is allowed.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="form-group row" *ngIf="userRole =='Candidate' ">
                                    <label class="col-sm-3 col-form-label">Email <small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="email" readonly>
                                        <div *ngIf="settingsForm.controls['email'].invalid && (settingsForm.controls['email'].dirty || settingsForm.controls['email'].touched) || settingFormHasError"
                                            class="alert">
                                            <div *ngIf="settingsForm.controls['email'].errors?.required">
                                                Email is required.
                                            </div>
                                            <div *ngIf="settingsForm.controls['email'].errors?.pattern">
                                                Invalid Email, please try again.
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                <div class="form-group row">
                                    <label for="input-jobtitle"
                                        class="col-sm-3 col-form-label">Email<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input readonly type="text" class="form-control" formControlName="username">
                                    </div>
                                </div>

                                <div class="form-group row" *ngIf="!showProfilePhoto">
                                    <label class="col-sm-3 col-form-label">Upload Profile Picture</label>
                                    <div class="col-sm-9">
                                        <input #fileInput type="file" style="width: 250px; float: left;"
                                            class="form-control-file" (change)="showPreview($event)">
                                        <input type="hidden" name="fileHidden" formControlNam e="profile_photo" />
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="showProfilePhoto">
                                    <label class="col-sm-3 col-form-label">Profile Picture</label>
                                    <div class="col-sm-9">
                                        <div class="imageLogoPreview"
                                            *ngIf="imageURL && imageURL !== '' && imageURL !== 'None'">
                                            <span id="x" (click)="removePhoto()">
                                                x
                                            </span>
                                            <img [src]="imageURL" [alt]="'Profile Image'">
                                        </div>
                                    </div>
                                </div>

                                <div class="d-inline float-right">
                                    <button type="button" (click)="cancelClicked()"
                                        class="btn btn-secondary f-left ml-2 mr-2">Cancel</button>
                                    <button type="button" *ngIf="userRole !='Superuser'"
                                        (click)="openforPassword(password)" class="btn btn-primary f-left">Change
                                        Password</button>
                                    <button type="button" *ngIf="userRole =='Superuser'" disabled="true"
                                        class="btn btn-primary f-left">Change Password</button>
                                    <button type="button" [disabled]="checkFormChanges()" (click)="updateSettings()"
                                        class="btn btn-primary f-left ml-2">Update</button>
                                </div>
                            </fieldset>
                        </form>
                        <div class="account-details" *ngIf="showAccDetails">
                            <form [formGroup]="companyInfoForm"
                                *ngIf="accountDetailsSection === 'companyInformation' || accountDetailsSection === 'all'">
                                <!-- <form [formGroup]="accInfoForm" *ngIf="accountDetailsSection === 'companyInformation' || accountDetailsSection === ''"> -->
                                <fieldSet class="scheduler-border pb-5">
                                    <h2 class="pt-4 ps-4">Basic Details</h2>
                                    <div class="form-group row">
                                        <label for="company_name" class="col-sm-3 col-form-label">Company Name<small
                                                class="text-danger">*</small></label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="company_name">
                                            <div *ngIf="companyInfoForm.controls['company_name'].invalid && (companyInfoForm.controls['company_name'].dirty || companyInfoForm.controls['company_name'].touched) || companyInfoFormHasError"
                                                class="alert">
                                                <div *ngIf="companyInfoForm.controls['company_name'].errors?.required">
                                                    Company Name is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="company_website" class="col-sm-3 col-form-label">Company
                                            Website</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="company_website">
                                            <!-- <div *ngIf="accInfoForm.controls['companyName'].invalid && (accInfoForm.controls['companyName'].dirty || accInfoForm.controls['companyName'].touched)" class="alert">
                                                <div *ngIf="accInfoForm.controls['companyName'].errors?.required">
                                                    Company Name is required.
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="company_linkedin" class="col-sm-3 col-form-label">Company
                                            LinkedIn</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="company_linkedin">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="addr_line_1" class="col-sm-3 col-form-label">Address Line 1<small
                                                class="text-danger">*</small></label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="addr_line_1">
                                            <div *ngIf="companyInfoForm.controls['addr_line_1'].invalid && (companyInfoForm.controls['addr_line_1'].dirty || companyInfoForm.controls['addr_line_1'].touched) || companyInfoFormHasError"
                                                class="alert">
                                                <div *ngIf="companyInfoForm.controls['addr_line_1'].errors?.required">
                                                    Address Line 1 is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="addr_line_2" class="col-sm-3 col-form-label">Address Line 2</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="addr_line_2">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="city" class="col-sm-3 col-form-label">City<small
                                                class="text-danger">*</small></label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="city">
                                            <div *ngIf="companyInfoForm.controls['city'].invalid && (companyInfoForm.controls['city'].dirty || companyInfoForm.controls['city'].touched) || companyInfoFormHasError"
                                                class="alert">
                                                <div *ngIf="companyInfoForm.controls['city'].errors?.required">
                                                    City is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-6 row ml-0">
                                            <label class="col-sm-6 col-form-label ">State<small
                                                    class="text-danger">*</small></label>
                                            <div class="col-sm-6">
                                                <!-- <input type="text" class="form-control" formControlName="state"> -->
                                                <select class="form-control" formControlName="state">
                                                    <option value="">Please select</option>
                                                    <option *ngFor="let state of stateList" [value]="state.name">
                                                        {{state.name}}</option>
                                                </select>
                                                <div *ngIf="companyInfoForm.controls['state'].invalid && (companyInfoForm.controls['state'].dirty || companyInfoForm.controls['state'].touched) || companyInfoFormHasError"
                                                    class="alert">
                                                    <div *ngIf="companyInfoForm.controls['state'].errors?.required">
                                                        City is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 row">
                                            <label class="col-sm-4 col-form-label">Zip<small
                                                    class="text-danger">*</small></label>
                                            <div class="col-sm-6">
                                                <input type="text" class="form-control" formControlName="pincode">
                                                <div *ngIf="companyInfoForm.controls['pincode'].invalid && (companyInfoForm.controls['pincode'].dirty || companyInfoForm.controls['pincode'].touched) || companyInfoFormHasError"
                                                    class="alert">
                                                    <div *ngIf="companyInfoForm.controls['pincode'].errors?.required">
                                                        City is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row" *ngIf="!showLogo">
                                        <label class="col-sm-3 col-form-label">Upload logo image</label>
                                        <div class="col-sm-9">
                                            <input #fileInput type="file" style="width: 250px; float: left;"
                                                class="form-control-file" (change)="showPreview1($event)">
                                            <input type="hidden" name="fileHidden" formControlName="logo">
                                            <!-- <div class="imageLogoPreview" *ngIf="logoImageURL && logoImageURL !== '' && logoImageURL !== 'None'">
                                                <img [src]="logoImageURL" [alt]="'logo-img'">
                                                <span>{{logoImageName}}</span>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="form-group row" *ngIf="showLogo">
                                        <label class="col-sm-3 col-form-label">Logo image</label>
                                        <div class="col-sm-9">
                                            <!-- <input #fileInput type="file" style="width: 250px; float: left;" class="form-control-file" (change)="showPreview1($event)">
                                            <input type="hidden" name="fileHidden" formControlName="logo"> -->
                                            <div class="imageLogoPreview"
                                                *ngIf="logoImageURL && logoImageURL !== '' && logoImageURL !== 'None'">
                                                <span id="x" (click)="removeLogo()">
                                                    x
                                                </span>
                                                <img [src]="logoImageURL" [alt]="'logo-img'">
                                                <span>{{logoImageName}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-inline float-right">
                                        <button type="button" (click)="cancelClicked()"
                                            class="btn btn-secondary f-left ml-2 mr-2">Cancel</button>
                                        <button type="button" (click)="saveCompanyInfoForm()"
                                            class="btn btn-primary f-left ml-2">Save</button>
                                    </div>
                                </fieldSet>
                            </form>
                            <!-- <form [formGroup]="companyContactForm" *ngIf="accountDetailsSection === 'companyContacts'">
                                <fieldset class="scheduler-border mt-4 py-4">
                                    <div class="accSecAccordian">
                                        <ngb-accordion class="col-sm-12">
                                            <ngb-panel [collapsed]="true">
                                                <ng-template ngbPanelTitle class="border-0">
                                                    <h5 class="mb-0 ms-3 ps-5 pe-1">CTO</h5>
                                                </ng-template>
                                                <ng-template ngbPanelContent>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">First Name</label>
                                                        <div class="col-sm-9">
                                                            <input type="text" class="form-control" formControlName="cto_first_name">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Last Name</label>
                                                        <div class="col-sm-9">
                                                            <input type="text" class="form-control" formControlName="cto_last_name">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Phone</label>
                                                        <div class="col-sm-9">
                                                            <input type="text" class="form-control" formControlName="cto_phone_no">
                                                           
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label for="linkedInAddress" class="col-sm-3 col-form-label">LinkedIn Address</label>
                                                        <div class="col-sm-9">
                                                            <input type="text" class="form-control" formControlName="cto_skype_id">
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Email</label>
                                                        <div class="col-sm-9">
                                                            <input type="text" class="form-control" formControlName="cto_email">
                                                            
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ngb-panel>
                                        </ngb-accordion>
                                        <ngb-accordion class="col-sm-12">
                                            <ngb-panel [collapsed]="true">
                                                <ng-template ngbPanelTitle>
                                                    <h6 class="mb-0 pe-1">Billing contact</h6>
                                                </ng-template>
                                                <ng-template ngbPanelContent>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">First Name</label>
                                                        <div class="col-sm-9">
                                                            <input type="text" class="form-control" formControlName="billing_first_name">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Last Name</label>
                                                        <div class="col-sm-9">
                                                            <input type="text" class="form-control" formControlName="billing_last_name">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Phone</label>
                                                        <div class="col-sm-9">
                                                            <input type="text" class="form-control" formControlName="billing_phone_no">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Email</label>
                                                        <div class="col-sm-9">
                                                            <input type="text" class="form-control" formControlName="billing_email">
                                                            
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ngb-panel>
                                        </ngb-accordion>
                                        <ngb-accordion class="col-sm-12">
                                            <ngb-panel [collapsed]="true">
                                                <ng-template ngbPanelTitle>
                                                    <h6 class="mb-0 ps-4 pe-1">HR contact</h6>
                                                </ng-template>
                                                <ng-template ngbPanelContent>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">First Name</label>
                                                        <div class="col-sm-9">
                                                            <input type="text" class="form-control" formControlName="hr_first_name">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Last Name</label>
                                                        <div class="col-sm-9">
                                                            <input type="text" class="form-control" formControlName="hr_last_name">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Phone</label>
                                                        <div class="col-sm-9">
                                                            <input type="text" class="form-control" formControlName="hr_contact_phone_no">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">LinkedIn Address</label>
                                                        <div class="col-sm-9">
                                                            <input type="text" class="form-control" formControlName="hr_contact_skype_id">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-sm-3 col-form-label">Email</label>
                                                        <div class="col-sm-9">
                                                            <input type="text" class="form-control" formControlName="hr_contact_email">
                                                            
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ngb-panel>
                                        </ngb-accordion>

                                        <div class="form-group row">
                                            <label for="email" class="col-sm-3 col-form-label ps-0">Special requirements</label>
                                            <div class="col-sm-9">
                                                <textarea class="form-control" formControlName="email" rows="2"></textarea>
                                            </div>
                                        </div>
                                        
                                        <div class="row float-right pr-5">
                                            <button type="button" (click)="saveCompanyContact()" class="d-inline btn btn-primary">Save</button>
                                        </div>
                                    </div>
                                </fieldset>
                            </form> -->


                            <form id="addCandidate" [formGroup]="companyContactForm"
                                *ngIf="accountDetailsSection === 'companyContacts'">

                                <!-- <fieldset class="scheduler-border">
                                    <legend class="pt-4">Client Admin</legend>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">First Name<small>*</small></label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="ca_first_name">
                                            <div *ngIf="companyContactForm.controls['ca_first_name'].invalid && (companyContactForm.controls['ca_first_name'].dirty || companyContactForm.controls['ca_first_name'].touched)" class="alert">
                                                <div *ngIf="companyContactForm.controls['ca_first_name'].errors?.required">
                                                    Please enter first name
                                                </div>
                                                <div *ngIf="companyContactForm.controls['ca_first_name'].errors?.pattern">
                                                    Please enter valid name, only alphabets are allowed.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Last Name<small>*</small></label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="ca_last_name">
                                            <div *ngIf="companyContactForm.controls['ca_last_name'].invalid && (companyContactForm.controls['ca_last_name'].dirty || companyContactForm.controls['ca_last_name'].touched)" class="alert">
                                                <div *ngIf="companyContactForm.controls['ca_last_name'].errors?.required">
                                                    Please enter last name
                                                </div>
                                                <div *ngIf="companyContactForm.controls['ca_last_name'].errors?.pattern">
                                                    Please enter valid name, only alphabets are allowed.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Phone<small>*</small></label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="key_contact_phone_no">
                                            <div *ngIf="companyContactForm.controls['key_contact_phone_no'].invalid && (companyContactForm.controls['key_contact_phone_no'].dirty || companyContactForm.controls['key_contact_phone_no'].touched)" class="alert">
                                                <div *ngIf="companyContactForm.controls['key_contact_phone_no'].errors?.required">
                                                    Please enter phone
                                                </div>
                                                <div *ngIf="companyContactForm.controls['key_contact_phone_no'].errors?.pattern">
                                                    Phone number must be exactly 10 digit & only number is allowed.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">LinkedIn Address</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="key_contact_skype_id">
                                            
                                            <div *ngIf="companyContactForm.controls['key_contact_skype_id'].errors?.pattern" class="alert">
                                                Please enter valid LinkedIn url.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Email<small>*</small></label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="key_contact_email">
                                            <div *ngIf="companyContactForm.controls['key_contact_email'].invalid && (companyContactForm.controls['key_contact_email'].dirty || companyContactForm.controls['key_contact_email'].touched)" class="alert">
                                                <div *ngIf="companyContactForm.controls['key_contact_email'].errors?.required">
                                                    Please enter email
                                                </div>
                                                <div *ngIf="companyContactForm.controls['key_contact_email'].errors?.pattern">
                                                    Invalid Email, please try again.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Job Title<small>*</small></label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="job_title">
                                            <div *ngIf="companyContactForm.controls['job_title'].invalid && (companyContactForm.controls['job_title'].dirty || companyContactForm.controls['job_title'].touched)" class="alert">
                                                <div *ngIf="companyContactForm.controls['job_title'].errors?.required">
                                                    Please enter job title
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Username<small>*</small></label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="key_username">
                                            <div *ngIf="companyContactForm.controls['key_username'].invalid && (companyContactForm.controls['key_username'].dirty || companyContactForm.controls['key_username'].touched)" class="alert">
                                                <div *ngIf="companyContactForm.controls['key_username'].errors?.required">
                                                    Please enter username
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </fieldset> -->
                                <fieldset class="scheduler-border">
                                    <legend class="pt-4">Company Contact</legend>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Full Name<small>*</small></label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control"
                                                formControlName="company_contact_full_name">
                                            <div *ngIf="companyContactForm.controls['company_contact_full_name'].invalid && (companyContactForm.controls['company_contact_full_name'].dirty || companyContactForm.controls['company_contact_full_name'].touched)"
                                                class="alert">
                                                <div
                                                    *ngIf="companyContactForm.controls['company_contact_full_name'].errors?.required">
                                                    Please enter name.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Phone<small>*</small></label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control"
                                                formControlName="company_contact_phone">
                                            <div *ngIf="companyContactForm.controls['company_contact_phone'].invalid && (companyContactForm.controls['company_contact_phone'].dirty || companyContactForm.controls['company_contact_phone'].touched)"
                                                class="alert">
                                                <div
                                                    *ngIf="companyContactForm.controls['company_contact_phone'].errors?.required">
                                                    Please enter Phone number.
                                                </div>
                                                <div
                                                    *ngIf="companyContactForm.controls['company_contact_phone'].errors?.pattern">
                                                    Phone number must be exactly 10 digit & only number is allowed.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Email<small>*</small></label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control"
                                                formControlName="company_contact_email">
                                            <div *ngIf="companyContactForm.controls['company_contact_email'].invalid && (companyContactForm.controls['company_contact_email'].dirty || companyContactForm.controls['company_contact_email'].touched)"
                                                class="alert">
                                                <div
                                                    *ngIf="companyContactForm.controls['company_contact_email'].errors?.required">
                                                    Please enter email address.
                                                </div>
                                                <div
                                                    *ngIf="companyContactForm.controls['company_contact_email'].errors?.pattern">
                                                    Invalid Email, please try again.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset class="scheduler-border">
                                    <legend class="pt-4">Coordinator Contact</legend>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">First Name</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="hr_first_name">
                                            <div *ngIf="companyContactForm.controls['hr_first_name'].errors?.pattern"
                                                class="alert">
                                                Please enter valid name, only alphabets are allowed.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Last Name</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="hr_last_name">
                                            <div *ngIf="companyContactForm.controls['hr_last_name'].errors?.pattern"
                                                class="alert">
                                                Please enter valid name, only alphabets are allowed.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Phone</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control"
                                                formControlName="hr_contact_phone_no">
                                            <div *ngIf="companyContactForm.controls['hr_contact_phone_no'].errors?.pattern"
                                                class="alert">
                                                Phone number must be exactly 10 digit & only number is allowed.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">LinkedIn Address</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control"
                                                formControlName="hr_contact_skype_id">
                                            <div *ngIf="companyContactForm.controls['hr_contact_skype_id'].errors?.pattern"
                                                class="alert">
                                                Please enter valid LinkedIn url.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Email</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="hr_contact_email">
                                            <div *ngIf="companyContactForm.controls['hr_contact_email'].errors?.pattern"
                                                class="alert">
                                                Invalid Email, please try again.
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset class="scheduler-border">
                                    <legend class="pt-4">CTO</legend>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">First Name</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="cto_first_name">
                                            <div *ngIf="companyContactForm.controls['cto_first_name'].errors?.pattern"
                                                class="alert">
                                                Please enter valid name, only alphabets are allowed.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Last Name</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="cto_last_name">
                                            <div *ngIf="companyContactForm.controls['cto_last_name'].errors?.pattern"
                                                class="alert">
                                                Please enter valid name, only alphabets are allowed.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Phone</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="cto_phone_no">
                                            <div *ngIf="companyContactForm.controls['cto_phone_no'].errors?.pattern"
                                                class="alert">
                                                Phone number must be exactly 10 digit & only number is allowed.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">LinkedIn Address</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="cto_skype_id">
                                            <div *ngIf="companyContactForm.controls['cto_skype_id'].errors?.pattern"
                                                class="alert">
                                                Please enter valid LinkedIn url.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Email</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="cto_email">
                                            <div *ngIf="companyContactForm.controls['cto_email'].errors?.pattern"
                                                class="alert">
                                                Invalid Email, please try again.
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset class="scheduler-border">
                                    <legend class="pt-4">Billing Contact</legend>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">First Name</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control"
                                                formControlName="billing_first_name">
                                            <div *ngIf="companyContactForm.controls['billing_first_name'].errors?.pattern"
                                                class="alert">
                                                Please enter valid name, only alphabets are allowed.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Last Name</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="billing_last_name">
                                            <div *ngIf="companyContactForm.controls['billing_last_name'].errors?.pattern"
                                                class="alert">
                                                Please enter valid name, only alphabets are allowed.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Phone</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="billing_phone_no">
                                            <div *ngIf="companyContactForm.controls['billing_phone_no'].errors?.pattern"
                                                class="alert">
                                                Phone number must be exactly 10 digit & only number is allowed.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Email</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="billing_email">
                                            <div *ngIf="companyContactForm.controls['billing_email'].errors?.pattern"
                                                class="alert">
                                                Invalid Email, please try again.
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <!-- <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Special requirements</label>
                                    <div class="col-sm-9">
                                        <textarea class="form-control" formControlName="special_req"></textarea>
                                    </div>
                                </div> -->
                                <!-- <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Upload logo image</label>
                                    <div class="col-sm-9">
                                        <input type="file" style="width: 250px; float: left;" class="form-control-file" (change)="showPreview($event)">
                                        <input type="hidden" name="fileHidden" formControlName="logo" />
                                        <div class="imagePreview" *ngIf="imageURL && imageURL !== '' && imageURL !== 'None'">
                                            <img [src]="imageURL" [alt]="companyContactForm.value.name">
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="row float-right pr-5 pb-2">
                                    <button type="button" (click)="cancelClicked()" class="btn btn-secondary f-left ml-2 mr-2">Cancel</button>
                                    <button type="button" (click)='saveCompanyContact()' class="d-inline btn btn-primary">Save</button>
                                </div> -->
                                <div class="d-inline float-right my-3">
                                    <button type="button" (click)="cancelClicked()"
                                        class="btn btn-secondary f-left ml-2 mr-2">Cancel</button>
                                    <button class="btn btn-primary" (click)="saveCompanyContact()">Save</button>
                                </div>
                            </form>


                            <form [formGroup]="accLevelForm" *ngIf="accountDetailsSection === 'accountLevels'">
                                <!-- [formGroup]="accountDetailsForm" -->
                                <!-- <h5 class="mt-3">Permissions</h5> -->
                                <div class="row">
                                    <div class="col-md-0">

                                    </div>
                                    <div class="col-md-12">
                                        <fieldset class="scheduler-border">
                                            <h6 class="mt-3">Your Current Account</h6>
                                            <div class="inputGroup" *ngIf="caTrialSelected">
                                                <input id="Trial" type="radio" formControlName="package1" value="Trial"
                                                    name="package1" />
                                                <label for="Trial">Trial Account - $0</label>

                                            </div>
                                            <div class="inputGroup">
                                                <input id="Starter" type="radio" (change)="PackChanged($event)"
                                                    formControlName="package1" value="Starter" name="package1" />
                                                <label for="Starter">Starter Account - {{'$'+ startPrice}}</label>

                                            </div>
                                            <div class="inputGroup">
                                                <input id="Growth" type="radio" (change)="PackChanged($event)"
                                                    formControlName="package1" value="Growth" name="package1" />
                                                <label for="Growth">Growth Account - {{'$'+ growthPrice}}</label>
                                            </div>
                                            <div class="inputGroup">
                                                <input id="Enterprise" type="radio" (change)="PackChanged($event)"
                                                    formControlName="package1" value="Enterprise" name="package1" />
                                                <label for="Enterprise">Enterprise Account - {{'$'+ enterPrice}}</label>
                                            </div>


                                            <h6 class="mt-5" *ngIf="!caTrialSelected">Account Adjustments:</h6>
                                            <h6 class="mt-5" *ngIf="caTrialSelected">Trial Account - Expires on
                                                {{caTrialExpiryDate}}</h6>
                                            <table class="ml-0 package-table" *ngIf="!caTrialSelected">
                                                <thead>
                                                    <tr>
                                                        <th class="col-sm-3 col-form-label text-end">
                                                            User Level
                                                        </th>
                                                        <th class="col-sm-2 text-center">Package Users</th>
                                                        <th class="col-sm-2 pl-4 text-center">Current Users</th>
                                                        <th class="col-sm-2 text-center">Additional User</th>
                                                        <th class="col-sm-3 pl-4 text-center">Additional monthly charges
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="mt-3">
                                                        <td class="col-sm-3 col-form-label text-end">
                                                            Senior Manager
                                                        </td>
                                                        <td class="col-sm-2 text-center starter_package_col">

                                                            <input readonly class="form-control"
                                                                style="text-align:center;" formControlName="pack_sm" />

                                                        </td>
                                                        <td class="col-sm-2 text-start starter_package_col">

                                                            <input readonly type="number"
                                                                class="form-control text-center"
                                                                formControlName="senior_managers" />

                                                        </td>
                                                        <td class="col-sm-2 text-center starter_package_col">

                                                            <input type="number" class="form-control"
                                                                (change)="valueChanged($event)"
                                                                formControlName="add_sm" />
                                                            <div *ngIf="accLevelForm.controls['add_sm'].invalid && (accLevelForm.controls['add_sm'].dirty || accLevelForm.controls['add_sm'].touched)"
                                                                class="alert">
                                                                <div
                                                                    *ngIf="accLevelForm.controls['add_sm'].errors?.required">
                                                                    Count is required.
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="col-sm-3 text-start ">
                                                            <div class="row">
                                                                <span
                                                                    class="col-sm-2 form-control text-center font-weight-bold">$</span>
                                                                <input readonly type="number"
                                                                    class="col-sm-9 form-control text-center"
                                                                    formControlName="add_sm_price" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="mt-3">
                                                        <td class="col-sm-3 col-form-label text-end">
                                                            Hiring Manager
                                                        </td>
                                                        <td class="col-sm-2 text-center starter_package_col">

                                                            <input readonly class="form-control"
                                                                style="text-align:center;" formControlName="pack_hm" />

                                                        </td>
                                                        <td class="col-sm-2 text-start starter_package_col">

                                                            <input readonly type="number"
                                                                class="form-control text-center"
                                                                formControlName="hiring_managers" />

                                                        </td>
                                                        <td class="col-sm-2 text-center starter_package_col">

                                                            <input type="number" class="form-control"
                                                                (change)="valueChanged($event)"
                                                                formControlName="add_hm" />
                                                            <div *ngIf="accLevelForm.controls['add_hm'].invalid && (accLevelForm.controls['add_hm'].dirty || accLevelForm.controls['add_hm'].touched)"
                                                                class="alert">
                                                                <div
                                                                    *ngIf="accLevelForm.controls['add_hm'].errors?.required">
                                                                    Count is required.
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="col-sm-3 text-start ">
                                                            <div class="row">
                                                                <span
                                                                    class="col-sm-2 form-control text-center font-weight-bold">$</span>
                                                                <input readonly type="number"
                                                                    class="col-sm-9 form-control text-center"
                                                                    formControlName="add_hm_price" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="mt-3">
                                                        <td class="col-sm-3 col-form-label text-end">
                                                            Hiring Team Member
                                                        </td>
                                                        <td class="col-sm-2 text-center starter_package_col">

                                                            <input readonly class="form-control"
                                                                style="text-align:center;" formControlName="pack_htm" />

                                                        </td>
                                                        <td class="col-sm-2 text-start starter_package_col">

                                                            <input readonly type="number"
                                                                class="form-control text-center"
                                                                formControlName="hiring_team_members" />

                                                        </td>
                                                        <td class="col-sm-2 text-center starter_package_col">

                                                            <input type="number" class="form-control"
                                                                (change)="valueChanged($event)"
                                                                formControlName="add_htm" />
                                                            <div *ngIf="accLevelForm.controls['add_htm'].invalid && (accLevelForm.controls['add_htm'].dirty || accLevelForm.controls['add_htm'].touched)"
                                                                class="alert">
                                                                <div
                                                                    *ngIf="accLevelForm.controls['add_htm'].errors?.required">
                                                                    Count is required.
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="col-sm-3 text-start ">
                                                            <div class="row">
                                                                <span
                                                                    class="col-sm-2 form-control text-center font-weight-bold">$</span>
                                                                <input readonly type="number"
                                                                    class="col-sm-9 form-control text-center"
                                                                    formControlName="add_htm_price" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div class="form-group row justify-content-left pl-3"
                                                *ngIf="!caTrialSelected">
                                                <label for="current_charges" class="col-sm-3 text-end">Current
                                                    Charges</label>
                                                <div class="col-sm-4 row ml-2">
                                                    <span
                                                        class="col-sm-2 form-control text-center pl-0 pr-0 font-weight-bold">$</span>
                                                    <input readonly type="text" class="form-control col-sm-8 "
                                                        formControlName="current_charges">
                                                    <span class="col-sm-2 input-group-text cursor-pointer pl-3"
                                                        ngbTooltip="This field reflects changes made in your package."
                                                        placement="right" container="body">
                                                        <fa-icon [icon]="faInfo"></fa-icon>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="form-group row justify-content-left pl-3"
                                                *ngIf="!caTrialSelected">
                                                <label for="additional_charges" class="col-sm-3 text-end">Additional
                                                    Charges</label>
                                                <div class="col-sm-4 row ml-2">
                                                    <span
                                                        class="col-sm-2 form-control text-center pl-0 pr-0 font-weight-bold">$</span>
                                                    <input readonly type="text" class="form-control col-sm-8"
                                                        formControlName="additional_charges">
                                                </div>
                                            </div>
                                            <div class="form-group row justify-content-left pl-3"
                                                *ngIf="!caTrialSelected">
                                                <label for="future_charges" class="col-sm-3 text-end">Future
                                                    Charges</label>
                                                <div class="col-sm-4 row ml-2">
                                                    <span
                                                        class="col-sm-2 form-control text-center pl-0 pr-0 font-weight-bold">$</span>
                                                    <input readonly type="text" class="form-control col-sm-8"
                                                        formControlName="future_charges">
                                                    <span class="col-sm-2 input-group-text cursor-pointer pl-3"
                                                        ngbTooltip="This field reflects changes made in your package."
                                                        placement="right" container="body">
                                                        <fa-icon [icon]="faInfo"></fa-icon>
                                                    </span>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div class="d-inline float-right my-3">
                                    <button type="button" [disabled]="accLevelForm.pristine || accLevelForm.invalid"
                                        (click)="createAccLevelForm()"
                                        class="btn btn-secondary f-left mr-2">Cancel</button>
                                    <button class="btn btn-primary"
                                        [disabled]="accLevelForm.pristine || accLevelForm.invalid"
                                        (click)="openAccLevelModal(confirmAccLevel)">Save</button>
                                </div>
                            </form>
                        </div>
                        <div class="billing-details" *ngIf="showBillingDetails">
                            <div class="row justify-content-center"
                                *ngIf="billingSectionType === 'billingInformation' || billingSectionType ==='all'">
                                <fieldset class="py-4 billContForm">
                                    <form [formGroup]="billingInfoForm">
                                        <div class="form-group row justify-content-center">
                                            <label for="billing-contact" class="col-sm-3 col-form-label">Billing
                                                Contact</label>
                                            <input type="text" class="form-control billContName col-sm-5"
                                                formControlName="billing_contact">
                                            <!-- <div *ngIf="billingForm.controls['billing-contact'].invalid && (billingForm.controls['billing-contact'].dirty || billingForm.controls['billing-contact'].touched)" class="alert">
                                                <div *ngIf="billingForm.controls['billing-contact'].errors?.required">
                                                    Contact is required.
                                                </div>
                                                <div *ngIf="billingForm.controls['billing-contact'].errors?.pattern">
                                                    Please enter valid contact.
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="form-group row justify-content-center">
                                            <div class="offset-4 col-sm-7">
                                                <input type="checkbox" id="billingSameAsCompany"
                                                    [(ngModel)]="billingAddress" (click)="selectAddress($event)"
                                                    class="d-inline-block me-3">
                                                <label for="billingSameAsCompany"
                                                    class="d-inline-block mb-0"><small>Billing address same as company
                                                        address</small></label>
                                            </div>
                                        </div>
                                        <div class="form-group row justify-content-center">
                                            <label for="adressLine1" class="col-sm-3 col-form-label">Address line
                                                1</label>
                                            <input type="text" class="form-control col-sm-5"
                                                formControlName="addr_line_1">
                                        </div>
                                        <div class="form-group row justify-content-center">
                                            <label for="adressLine2" class="col-sm-3 col-form-label">Address Line
                                                2</label>
                                            <input type="text" class="form-control col-sm-5"
                                                formControlName="addr_line_2">
                                        </div>
                                        <div class="form-group row justify-content-center">
                                            <label for="cityName" class="col-sm-3 col-form-label">City</label>
                                            <input type="text" class="form-control col-sm-5" formControlName="city">
                                        </div>
                                        <div class="form-group row justify-content-center">
                                            <label for="state" class="col-sm-3 col-form-label">State</label>
                                            <input type="text" class="form-control col-sm-5" formControlName="state">
                                        </div>
                                        <div class="form-group row justify-content-center">
                                            <label for="zipcode" class="col-sm-3 col-form-label">Zip</label>
                                            <input type="text" class="form-control col-sm-5" formControlName="pincode">
                                        </div>
                                        <div class="form-group row justify-content-center" *ngIf="!showCardDetails">
                                            <label for="cardOnFile" class="col-sm-3 col-form-label">Card on file</label>
                                            <input readonly type="text" class="form-control col-sm-3"
                                                [value]="lastFourDigits" formControlName="cardOnFile">
                                            <div class="col-sm-2">
                                                <button type="button" class="btn btn-primary"
                                                    (click)="updateCard()">Update</button>
                                            </div>
                                        </div>
                                        <div class="form-group row justify-content-center" *ngIf="showCardDetails">
                                            <div class="col-sm-3"></div>
                                            <div class="col-sm-5">
                                                <div class="form-group row">
                                                    <label for="cardOnFile" class="row col-form-label">Card
                                                        Number</label><br>
                                                    <input type="text" class="form-control row ml-0 col-sm-12"
                                                        placeholder="eg. 4242424242424242" maxlength="16" size="16"
                                                        formControlName="card_number">
                                                    <div *ngIf="billingInfoForm.controls['card_number'].invalid && (billingInfoForm.controls['card_number'].dirty || billingInfoForm.controls['card_number'].touched)"
                                                        class="alert">
                                                        <div
                                                            *ngIf="billingInfoForm.controls['card_number'].errors?.pattern">
                                                            Please enter valid 16 digit card number.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row ">
                                                    <label for="cardOnFile" class="row col-form-label">Name on
                                                        card</label><br>
                                                    <input type="text" class="form-control row ml-0 col-sm-12"
                                                        placeholder="eg. Joe Biden" formControlName="name_on_card">
                                                </div>
                                                <div class="form-group row ">
                                                    <div class="col-sm-6  pl-0 ">
                                                        <label for="cardOnFile" class="row pl-3 col-form-label">Expiry
                                                            Date</label>
                                                        <input type="text" class="form-control row ml-0 col-sm-12"
                                                            maxlength="5" size="5" placeholder="MM/YY"
                                                            formControlName="exp_date">
                                                        <div *ngIf="billingInfoForm.controls['exp_date'].invalid && (billingInfoForm.controls['exp_date'].dirty || billingInfoForm.controls['exp_date'].touched)"
                                                            class="alert">
                                                            <div
                                                                *ngIf="billingInfoForm.controls['exp_date'].errors?.pattern">
                                                                Please enter valid MM/YY date.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6  pl-0 ">
                                                        <label for="cardOnFile" class="row pl-3 col-form-label">Security
                                                            Code</label>
                                                        <input type="text" class="form-control row ml-0 col-sm-12"
                                                            placeholder="000" maxlength="3" size="3"
                                                            formControlName="security">
                                                        <div *ngIf="billingInfoForm.controls['security'].invalid && (billingInfoForm.controls['security'].dirty || billingInfoForm.controls['security'].touched)"
                                                            class="alert">
                                                            <div
                                                                *ngIf="billingInfoForm.controls['security'].errors?.pattern">
                                                                Please enter valid 3 digit code.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-inline float-right my-3">
                                            <button type="button" (click)="cancelClicked()"
                                                class="btn btn-secondary f-left ml-2 mr-2">Cancel</button>
                                            <button class="btn btn-primary" (click)="saveBillingInfo()">Save</button>
                                        </div>
                                    </form>
                                </fieldset>
                            </div>
                            <div class="row justify-content-center" *ngIf="billingSectionType === 'billingHistory'">
                                <div class="PaymentHistorySection">
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="col-sm-1 float-right"><button class="border-0"
                                                    (click)="downloadInvoice()" [disabled]="isDownload"><i
                                                        class="fa fa-download fa-lg text-success"></i></button></div>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-sm-12">
                                            <h5 class="mb-0">Payment History</h5>
                                            <p class="text-secondary"><small>See history of your payment plan
                                                    invoice</small></p>
                                        </div>
                                    </div>
                                    <!-- <table class="table-responsive" style="width:100%">
                                        <tr class="mb-1 billData-headers">
                                            <th><input type="checkbox" class="mt-2"></th>
                                            <th class="text-bold"onclick="sortData('Payment Invoice')">Payment Invoice <i class="fas fa-long-arrow-alt-up text-success"></i><i class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i></th>
                                            <th class="text-bold">Invoice Date <i class="fas fa-long-arrow-alt-up text-success"></i><i class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i></th>
                                            <th class="text-bold">Status <i class="fas fa-long-arrow-alt-up text-success"></i><i class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i></th>
                                            <th class="text-bold">Period <i class="fas fa-long-arrow-alt-up text-success"></i><i class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i></th>
                                            <th class="text-bold">Amount <i class="fas fa-long-arrow-alt-up text-success"></i><i class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i></th>
                                        </tr>
                                    </table> -->
                                    <div class="row mb-1">
                                        <div class="col-sm-1 text-end">
                                            <!-- <input type="checkbox" class="mt-2"> -->
                                        </div>
                                        <div class="col-sm-11 border-0">
                                            <div class="billData-headers d-flex">
                                                <div class="col text-bold " (click)="sortAccordions('invoiceNumber')">
                                                    Payment Invoice <i
                                                        class="fas fa-long-arrow-alt-up text-success"></i><i
                                                        class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i>
                                                </div>
                                                <div class="col text-bold pl-3" (click)="sortAccordions('date')">Invoice
                                                    Date <i class="fas fa-long-arrow-alt-up text-success"></i><i
                                                        class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i>
                                                </div>
                                                <div class="col text-bold" (click)="sortAccordions('status')">Status <i
                                                        class="fas fa-long-arrow-alt-up text-success"></i><i
                                                        class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i>
                                                </div>
                                                <div class="col text-bold" (click)="sortAccordions('period')">Period <i
                                                        class="fas fa-long-arrow-alt-up text-success"></i><i
                                                        class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i>
                                                </div>
                                                <div class="col text-bold pl-0" (click)="sortAccordions('amount')">
                                                    Amount <i class="fas fa-long-arrow-alt-up text-success"></i><i
                                                        class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-0 pb-0 billsAccordion" *ngFor="let invoice of invoices">
                                        <div class="col-sm-1 text-end">
                                            <input type="radio" name="Invoices" id="invoice.id_int"
                                                [value]="invoice.id_int" (click)="selectInvoice($event)" class="mt-3">
                                        </div>
                                        <ngb-accordion class="col-sm-11">
                                            <ngb-panel [collapsed]="true">
                                                <ng-template ngbPanelTitle class="justify-content-between px-0">
                                                    <div class="col text-bold">{{invoice.id}}</div>
                                                    <div class="col">{{invoice.date}}</div>
                                                    <div class="col" *ngIf="invoice.status=='succeeded'"><i
                                                            class="fas fa-circle fa-xs pb-2 text-success"></i> Success
                                                    </div>
                                                    <div class="col" *ngIf="invoice.status =='Failed'"
                                                        placement="top-left"
                                                        ngbTooltip="Payment has Failed. please click to take action and not lose your data.">
                                                        <i class="fas fa-circle fa-xs text-danger"></i> Failed
                                                    </div>
                                                    <div class="col" *ngIf="invoice.status=='incomplete'"><i
                                                            class="fas fa-circle fa-xs pb-2 text-secondary"></i>
                                                        Incomplete</div>
                                                    <div class="col">{{invoice.period || ' '}}</div>
                                                    <div class="col">{{invoice.amount}}</div>
                                                </ng-template>
                                                <ng-template ngbPanelContent>
                                                    <div class="row mt-1">
                                                        <div class="col-sm-5 ps-0">
                                                            <div class="ps-3 bill-compName mt-4">
                                                                <h5 class="mb-2">{{invoice.client}}</h5>
                                                                <p>Thank you for subscribing to Qorums, the leading
                                                                    Hiring Process Management System.</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-7 px-0">
                                                            <table class="table border-0 billing-table">
                                                                <tr *ngIf="invoice.amt_breakdown.package">
                                                                    <td>
                                                                        <h6 class="">
                                                                            {{invoice.amt_breakdown.package.name}}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>${{invoice.amt_breakdown.package.price}}
                                                                        </h6>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="invoice.amt_breakdown.senior_managers">
                                                                    <td>
                                                                        <h6 class="mb-0">
                                                                            {{invoice.amt_breakdown.senior_managers[0]}}
                                                                        </h6>
                                                                        <p>{{invoice.amt_breakdown.senior_managers[2]}}
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <h6>${{invoice.amt_breakdown.senior_managers[1]}}
                                                                        </h6>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="invoice.amt_breakdown.hiring_managers">
                                                                    <td>
                                                                        <h6 class="mb-0">
                                                                            {{invoice.amt_breakdown.hiring_managers[0]}}
                                                                        </h6>
                                                                        <p>{{invoice.amt_breakdown.hiring_managers[2]}}
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <h6>${{invoice.amt_breakdown.hiring_managers[1]}}
                                                                        </h6>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="invoice.amt_breakdown.hiring_team_members">
                                                                    <td>
                                                                        <h6 class="mb-0">
                                                                            {{invoice.amt_breakdown.hiring_team_members[0]}}
                                                                        </h6>
                                                                        <p>{{invoice.amt_breakdown.hiring_team_members[2]}}
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        <h6>${{invoice.amt_breakdown.hiring_team_members[1]}}
                                                                        </h6>
                                                                    </td>
                                                                </tr>
                                                                <tr class="mb-0">
                                                                    <td class="pb-0">
                                                                        <h6>Tax</h6>
                                                                    </td>
                                                                    <td class="border-bottom border-dark pb-0">
                                                                        <h6>$0</h6>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="invoice.status == 'succeeded'">
                                                                    <td>
                                                                        <h5 class="pt-4 ps-0">Paid</h5>
                                                                    </td>
                                                                    <td>
                                                                        <h5 class="pt-4 mb-0">
                                                                            {{invoice.amt_breakdown.total.amount}}</h5>
                                                                        <p class="pt-0">for
                                                                            {{invoice.amt_breakdown.total.days}} days
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="invoice.status == 'Failed'">
                                                                    <td>
                                                                        <h5 class="text-danger mb-0 pt-4 ps-0">Payment
                                                                            Failed</h5>
                                                                        <!-- <button class="btn btn-sm takeActBtn py-0" (click)="takeAction(paymentGateway,invoice.key,invoice.payment_secret)" >Take Action</button> -->
                                                                    </td>
                                                                    <td>
                                                                        <h5 class="pt-4 mb-0">
                                                                            {{invoice.amt_breakdown.total.amount}}</h5>
                                                                        <p class="pt-0">for
                                                                            {{invoice.amt_breakdown.total.days}} days
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="invoice.status == 'incomplete'">
                                                                    <td>
                                                                        <h5 class="text-danger mb-0 pt-4 ps-0">Payment
                                                                            Incomplete</h5>
                                                                        <button class="btn btn-sm takeActBtn py-0"
                                                                            (click)="takeAction(paymentGateway,invoice.key,invoice.payment_secret)">Take
                                                                            Action</button>
                                                                    </td>
                                                                    <td>
                                                                        <h5 class="pt-4 mb-0">
                                                                            {{invoice.amt_breakdown.total.amount}}</h5>
                                                                        <p class="pt-0">for
                                                                            {{invoice.amt_breakdown.total.days}} days
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ngb-panel>
                                        </ngb-accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form [formGroup]="proTipForm" *ngIf="showProTipForm">
                            <fieldset class="scheduler-border">
                                <legend class="pt-4">Pro-tip details</legend>
                                <!-- <div class="form-group row pt-4">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Dashboard
                                        Pro-tip</label>
                                    <div class="col-sm-9 row ml-0">
                                        <input class="form-control col-sm-11" formControlName="dashboard_protip" />
                                        <div class="col-sm-1 input-group-text cursor-pointer "
                                            style="padding-left: 3.5%;"
                                            ngbTooltip="This will be shown to Dashboard screen to explain its different parts and functions"
                                            placement="left" container="body">
                                            <fa-icon [icon]="faInfo"></fa-icon>
                                        </div>
                                    </div>

                                </div> -->
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">New Position
                                        Pro-tip</label>
                                    <div class="col-sm-9 row ml-0">
                                        <input class="form-control col-sm-11" formControlName="new_position_protip" />
                                        <div class="col-sm-1 input-group-text cursor-pointer "
                                            style="padding-left: 3.5%;"
                                            ngbTooltip="When creating a new Position this protip is displayed there."
                                            placement="left" container="body">
                                            <fa-icon [icon]="faInfo"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Client Pro-tip</label>
                                    <div class="col-sm-9 row ml-0">
                                        <input class="form-control col-sm-11" formControlName="client_protip" />
                                        <div class="col-sm-1 input-group-text cursor-pointer "
                                            style="padding-left: 3.5%;"
                                            ngbTooltip="Protip shown while creating a new client" placement="left"
                                            container="body">
                                            <fa-icon [icon]="faInfo"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Position Pro-tip</label>
                                    <div class="col-sm-9 row ml-0">
                                        <input class="form-control col-sm-11" formControlName="position_protip" />
                                        <div class="col-sm-1 input-group-text cursor-pointer "
                                            style="padding-left: 3.5%;"
                                            ngbTooltip=" This protip is shown on the position screen, where positions data is shown"
                                            placement="left" container="body">
                                            <fa-icon [icon]="faInfo"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">HTM Pro-tip</label>
                                    <div class="col-sm-9 row ml-0">
                                        <input class="form-control col-sm-11" formControlName="team_members_protip" />
                                        <div class="col-sm-1 input-group-text cursor-pointer "
                                            style="padding-left: 3.5%;" ngbTooltip="Protip for HTM page"
                                            placement="left" container="body">
                                            <fa-icon [icon]="faInfo"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Candidate Dashboard
                                        Pro-tip</label>
                                    <div class="col-sm-9 row ml-0">
                                        <input class="form-control col-sm-11" formControlName="candidate_dash_protip" />
                                        <div class="col-sm-1 input-group-text cursor-pointer "
                                            style="padding-left: 3.5%; height: 62%;"
                                            ngbTooltip="Protip shown for the candidate dashboard at time of evaluation a position when it is closed"
                                            placement="left" container="body">
                                            <fa-icon [icon]="faInfo"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Settings Pro-tip</label>
                                    <div class="col-sm-9 row ml-0">
                                        <input class="form-control col-sm-11" formControlName="settings_protip" />
                                        <div class="col-sm-1 input-group-text cursor-pointer "
                                            style="padding-left: 3.5%;" ngbTooltip=" Settings Page Protip"
                                            placement="left" container="body">
                                            <fa-icon [icon]="faInfo"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Qorums Overview</label>
                                    <div class="col-sm-9 row ml-0">
                                        <input class="form-control col-sm-11" formControlName="qorums_overview" />
                                        <div class="col-sm-1 input-group-text cursor-pointer "
                                            style="padding-left: 3.5%;" ngbTooltip="Protip for the Qorums Overview"
                                            placement="left" container="body">
                                            <fa-icon [icon]="faInfo"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Managing Users</label>
                                    <div class="col-sm-9 row ml-0">
                                        <input class="form-control col-sm-11" formControlName="managing_users" />
                                        <div class="col-sm-1 input-group-text cursor-pointer "
                                            style="padding-left: 3.5%;" ngbTooltip="Protip for the Managing Users"
                                            placement="left" container="body">
                                            <fa-icon [icon]="faInfo"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Hiring Process</label>
                                    <div class="col-sm-9 row ml-0">
                                        <input class="form-control col-sm-11" formControlName="hiring_process" />
                                        <div class="col-sm-1 input-group-text cursor-pointer "
                                            style="padding-left: 3.5%;" ngbTooltip="Protip for the Hiring Process"
                                            placement="left" container="body">
                                            <fa-icon [icon]="faInfo"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Managing
                                        Candidates</label>
                                    <div class="col-sm-9 row ml-0">
                                        <input class="form-control col-sm-11" formControlName="managing_candidates" />
                                        <div class="col-sm-1 input-group-text cursor-pointer "
                                            style="padding-left: 3.5%;" ngbTooltip="Protip for the Managing Candidates"
                                            placement="left" container="body">
                                            <fa-icon [icon]="faInfo"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label">Managing Open
                                        Positions</label>
                                    <div class="col-sm-9 row ml-0">
                                        <input class="form-control col-sm-11" formControlName="managing_positions" />
                                        <div class="col-sm-1 input-group-text cursor-pointer "
                                            style="padding-left: 3.5%;"
                                            ngbTooltip="Protip for the Managing Open Positions" placement="left"
                                            container="body">
                                            <fa-icon [icon]="faInfo"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-inline float-right">
                                    <button type="button" (click)="cancelClicked()"
                                        class="btn btn-secondary f-left ml-2 mr-2">Cancel</button>
                                    <button type="button" (click)="updateProTip()"
                                        class="btn btn-primary f-left ml-2">Update</button>
                                </div>
                            </fieldset>
                        </form>
                        <form [formGroup]="globalPricingForm" *ngIf="showGlobalPricing">
                            <fieldset class="scheduler-border">
                                <!-- <legend class="pt-4">Global Pricing</legend> -->

                                <div class="form-group row pt-5">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label font-weight-bold">Package
                                        Level</label>
                                    <div class="col-sm-9">
                                        <select class="form-control" formControlName="package_level"
                                            (change)="getSelectedPackage($event)">
                                            <option value="">Please select</option>
                                            <option *ngFor="let package of packageDetails" [value]="package.id">
                                                {{package.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label font-weight-bold">Monthly
                                        Package Price</label>
                                    <div class="col-sm-9 row ml-0">

                                        <span class="col-sm-1 form-control text-center font-weight-bold">$</span>
                                        <input class="col-sm-11 form-control" formControlName="package_price" />

                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label font-weight-bold">Current
                                        Charge Tooltip</label>
                                    <div class="col-sm-9 row ml-0">
                                        <input class="col-sm-11 form-control"
                                            formControlName="current_charge_tooltip" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="input-jobtitle" class="col-sm-3 col-form-label font-weight-bold">Future
                                        Charge Tooltip</label>
                                    <div class="col-sm-9 row ml-0">
                                        <input class="col-sm-11 form-control" formControlName="future_charge_tooltip" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <table class="ml-0 package-table">
                                        <thead>
                                            <tr>
                                                <td class="col-sm-3 col-form-label text-end font-weight-bold">
                                                    Account Level
                                                </td>
                                                <th class="col-sm-3 mon_column">Monthly Cost </th>
                                                <th class="col-sm-3 text-left font-weight-bold P_users-column"
                                                    style="padding-left: 3%;">Per User</th>
                                                <th class="col-sm-3 text-center">Default Users in package</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="mt-3">
                                                <td class="col-sm-3 col-form-label text-end">
                                                    Senior Manager
                                                </td>
                                                <td class="col-sm-3 text-center  pl-5 ">
                                                    <div class="row">
                                                        <span
                                                            class="col-sm-2 form-control text-center font-weight-bold">$</span>
                                                        <input class="col-sm-10 form-control" style="text-align:center;"
                                                            formControlName="senior_manager_cost" />
                                                    </div>
                                                </td>
                                                <td class="col-sm-3  P_users-column">

                                                    <input type="number" min="1" max="999"
                                                        class="form-control text-center"
                                                        formControlName="senior_managers" />

                                                </td>

                                                <td class="col-sm-3 text-start users-column">

                                                    <input type="number" class="form-control text-center"
                                                        formControlName="senior_manager_count" />

                                                </td>
                                            </tr>
                                            <tr class="mt-3">
                                                <td class="col-sm-3 col-form-label text-end">
                                                    Hiring Manager
                                                </td>
                                                <td class="col-sm-3 text-center  pl-5 ">
                                                    <div class="row">
                                                        <span
                                                            class="col-sm-2 form-control text-center font-weight-bold">$</span>
                                                        <input class="col-sm-10 form-control" style="text-align:center;"
                                                            formControlName="hiring_manager_cost" />
                                                    </div>
                                                </td>
                                                <td class="col-sm-3  P_users-column">

                                                    <input type="number" class="form-control text-center"
                                                        formControlName="hiring_managers" />

                                                </td>

                                                <td class="col-sm-3  users-column">

                                                    <input type="number" class="form-control text-center"
                                                        formControlName="hiring_manager_count" />

                                                </td>
                                            </tr>
                                            <tr class="mt-3">
                                                <td class="col-sm-3 col-form-label text-end">
                                                    Hiring Team Member
                                                </td>
                                                <td class="col-sm-3 text-center pl-5">
                                                    <div class="row">
                                                        <span
                                                            class="col-sm-2 form-control text-center font-weight-bold">$</span>
                                                        <input class="col-sm-10 form-control" style="text-align:center;"
                                                            formControlName="htm_cost" />
                                                    </div>
                                                </td>
                                                <td class="col-sm-3 P_users-column">

                                                    <input type="number" class="form-control text-center"
                                                        formControlName="hiring_team_members" />

                                                </td>

                                                <td class="col-sm-3 text-start users-column">

                                                    <input type="number" class="form-control text-center"
                                                        formControlName="htm_count" />

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="d-inline float-right">
                                    <button type="button" (click)="cancelClicked()"
                                        class="btn btn-primary f-left ml-2">Cancel</button>
                                    <button type="button" (click)="saveGlobalPricingForm()"
                                        class="btn btn-primary f-left ml-2">Save</button>

                                </div>
                            </fieldset>
                        </form>
                        <div *ngIf='showClientAccounts'>
                            <div *ngIf="selectedClientId && clientIdForSA && selectedClientId === clientIdForSA">
                                <form [formGroup]="companyInfoForm"
                                    *ngIf="clientDetails === 'companyInformation' || clientDetails === 'all'">
                                    <!-- <form [formGroup]="accInfoForm" *ngIf="accountDetailsSection === 'companyInformation' || accountDetailsSection === ''"> -->
                                    <fieldSet class="scheduler-border pb-5">
                                        <h2 class="pt-4 ps-4">Company Information</h2>
                                        <div class="form-group row">
                                            <label for="companyName" class="col-sm-3 col-form-label">Company Name<small
                                                    class="text-danger">*</small></label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" formControlName="company_name">
                                                <div *ngIf="companyInfoForm.controls['company_name'].invalid && (companyInfoForm.controls['company_name'].dirty || companyInfoForm.controls['company_name'].touched) || companyInfoFormHasError"
                                                    class="alert">
                                                    <div
                                                        *ngIf="companyInfoForm.controls['company_name'].errors?.required">
                                                        Company Name is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input-jobtitle" class="col-sm-3 col-form-label">Company
                                                Website</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control"
                                                    formControlName="company_website">
                                                <!-- <div *ngIf="accInfoForm.controls['companyName'].invalid && (accInfoForm.controls['companyName'].dirty || accInfoForm.controls['companyName'].touched)" class="alert">
                                                    <div *ngIf="accInfoForm.controls['companyName'].errors?.required">
                                                        Company Name is required.
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input-jobtitle" class="col-sm-3 col-form-label">Company
                                                LinkedIn</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control"
                                                    formControlName="company_linkedin">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input-jobtitle" class="col-sm-3 col-form-label">Address Line
                                                1<small class="text-danger">*</small></label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" formControlName="addr_line_1">
                                                <div *ngIf="companyInfoForm.controls['addr_line_1'].invalid && (companyInfoForm.controls['addr_line_1'].dirty || companyInfoForm.controls['addr_line_1'].touched) || companyInfoFormHasError"
                                                    class="alert">
                                                    <div
                                                        *ngIf="companyInfoForm.controls['addr_line_1'].errors?.required">
                                                        Address Line 1 is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input-jobtitle" class="col-sm-3 col-form-label">Address Line
                                                2</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" formControlName="addr_line_2">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="input-jobtitle" class="col-sm-3 col-form-label">City<small
                                                    class="text-danger">*</small></label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" formControlName="city">
                                                <div *ngIf="companyInfoForm.controls['city'].invalid && (companyInfoForm.controls['city'].dirty || companyInfoForm.controls['city'].touched) || companyInfoFormHasError"
                                                    class="alert">
                                                    <div *ngIf="companyInfoForm.controls['city'].errors?.required">
                                                        City is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-6 row ml-0">
                                                <label class="col-sm-6 col-form-label ">State<small
                                                        class="text-danger">*</small></label>
                                                <div class="col-sm-6">
                                                    <!-- <input type="text" class="form-control" formControlName="state"> -->
                                                    <select class="form-control" formControlName="state">
                                                        <option value="">Please select</option>
                                                        <option *ngFor="let state of stateList" [value]="state.name">
                                                            {{state.name}}</option>
                                                    </select>
                                                    <div *ngIf="companyInfoForm.controls['state'].invalid && (companyInfoForm.controls['state'].dirty || companyInfoForm.controls['state'].touched) || companyInfoFormHasError"
                                                        class="alert">
                                                        <div *ngIf="companyInfoForm.controls['state'].errors?.required">
                                                            State is required.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 row">
                                                <label class="col-sm-4 col-form-label">Zip<small
                                                        class="text-danger">*</small></label>
                                                <div class="col-sm-6">
                                                    <input type="text" class="form-control" formControlName="pincode">
                                                    <div *ngIf="companyInfoForm.controls['pincode'].invalid && (companyInfoForm.controls['pincode'].dirty || companyInfoForm.controls['pincode'].touched) || companyInfoFormHasError"
                                                        class="alert">
                                                        <div
                                                            *ngIf="companyInfoForm.controls['pincode'].errors?.required">
                                                            Pincode is required.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="!showLogo">
                                            <label class="col-sm-3 col-form-label">Upload logo image</label>
                                            <div class="col-sm-9">
                                                <input #fileInput type="file" style="width: 250px; float: left;"
                                                    class="form-control-file" (change)="showPreview1($event)">
                                                <input type="hidden" name="fileHidden" formControlName="logo">
                                                <!-- <div class="imageLogoPreview" *ngIf="logoImageURL && logoImageURL !== '' && logoImageURL !== 'None'">
                                                    <img [src]="logoImageURL" [alt]="'logo-img'">
                                                    <span>{{logoImageName}}</span>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="showLogo">
                                            <label class="col-sm-3 col-form-label">Logo image</label>
                                            <div class="col-sm-9">
                                                <!-- <input #fileInput type="file" style="width: 250px; float: left;" class="form-control-file" (change)="showPreview1($event)">
                                                <input type="hidden" name="fileHidden" formControlName="logo"> -->
                                                <div class="imageLogoPreview"
                                                    *ngIf="logoImageURL && logoImageURL !== '' && logoImageURL !== 'None'">
                                                    <span id="x" (click)="removeLogo()">
                                                        x
                                                    </span>
                                                    <img [src]="logoImageURL" [alt]="'logo-img'">
                                                    <span>{{logoImageName}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-inline float-right">
                                            <button type="button" (click)="cancelClicked()"
                                                class="btn btn-secondary f-left ml-2 mr-2">Cancel</button>
                                            <button type="button" (click)="saveCompInfoFormForSA()"
                                                class="btn btn-primary f-left ml-2">Save</button>
                                        </div>
                                    </fieldSet>
                                </form>
                                <!-- <form  [formGroup]="companyContactForm" >
                                    <fieldset class="scheduler-border mt-4 py-4">
                                        <h2 class="pt-4 ps-4">Company Contacts</h2>
                                        <div class="accSecAccordian">
                                            <ngb-accordion class="col-sm-12">
                                                <ngb-panel [collapsed]="true">
                                                    <ng-template ngbPanelTitle class="border-0">
                                                        <h5 class="mb-0 ms-3 ps-5 pe-1">CTO</h5>
                                                    </ng-template>
                                                    <ng-template ngbPanelContent>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3 col-form-label">First Name</label>
                                                            <div class="col-sm-9">
                                                                <input type="text" class="form-control" formControlName="cto_first_name">
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3 col-form-label">Last Name</label>
                                                            <div class="col-sm-9">
                                                                <input type="text" class="form-control" formControlName="cto_last_name">
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3 col-form-label">Phone</label>
                                                            <div class="col-sm-9">
                                                                <input type="text" class="form-control" formControlName="cto_phone_no">
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label for="linkedInAddress" class="col-sm-3 col-form-label">LinkedIn Address</label>
                                                            <div class="col-sm-9">
                                                                <input type="text" class="form-control" formControlName="cto_skype_id">
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3 col-form-label">Email</label>
                                                            <div class="col-sm-9">
                                                                <input type="text" class="form-control" formControlName="cto_email">
                                                                
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </ngb-panel>
                                            </ngb-accordion>
                                            <ngb-accordion class="col-sm-12">
                                                <ngb-panel [collapsed]="true">
                                                    <ng-template ngbPanelTitle>
                                                        <h6 class="mb-0 pe-1">Billing contact</h6>
                                                    </ng-template>
                                                    <ng-template ngbPanelContent>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3 col-form-label">First Name</label>
                                                            <div class="col-sm-9">
                                                                <input type="text" class="form-control" formControlName="billing_first_name">
                                                               
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3 col-form-label">Last Name</label>
                                                            <div class="col-sm-9">
                                                                <input type="text" class="form-control" formControlName="billing_last_name">
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3 col-form-label">Phone</label>
                                                            <div class="col-sm-9">
                                                                <input type="text" class="form-control" formControlName="billing_phone_no">
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3 col-form-label">Email</label>
                                                            <div class="col-sm-9">
                                                                <input type="text" class="form-control" formControlName="billing_email">
                                                                
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </ngb-panel>
                                            </ngb-accordion>
                                            <ngb-accordion class="col-sm-12">
                                                <ngb-panel [collapsed]="true">
                                                    <ng-template ngbPanelTitle>
                                                        <h6 class="mb-0 ps-4 pe-1">HR contact</h6>
                                                    </ng-template>
                                                    <ng-template ngbPanelContent>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3 col-form-label">First Name</label>
                                                            <div class="col-sm-9">
                                                                <input type="text" class="form-control" formControlName="hr_first_name">
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3 col-form-label">Last Name</label>
                                                            <div class="col-sm-9">
                                                                <input type="text" class="form-control" formControlName="hr_last_name">
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3 col-form-label">Phone</label>
                                                            <div class="col-sm-9">
                                                                <input type="text" class="form-control" formControlName="hr_contact_phone_no">
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3 col-form-label">LinkedIn Address</label>
                                                            <div class="col-sm-9">
                                                                <input type="text" class="form-control" formControlName="hr_contact_skype_id">
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3 col-form-label">Email</label>
                                                            <div class="col-sm-9">
                                                                <input type="text" class="form-control" formControlName="hr_contact_email">
                                                                
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </ngb-panel>
                                            </ngb-accordion>
    
                                            <div class="form-group row">
                                                <label for="email" class="col-sm-3 col-form-label ps-0">Special requirements</label>
                                                <div class="col-sm-9">
                                                    <textarea class="form-control" formControlName="special_req" rows="2"></textarea>
                                                </div>
                                            </div>
                                            
                                            <div class="row float-right pr-5">
                                                <button type="button" (click)='saveCompContactForSA()' class="d-inline btn btn-primary">Save</button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form> -->

                                <form id="addCandidate" [formGroup]="companyContactForm"
                                    *ngIf="clientDetails === 'companyContacts'">
                                    <fieldset class="scheduler-border">
                                        <legend class="pt-4">Company Contact</legend>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Full Name<small>*</small></label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control"
                                                    formControlName="company_contact_full_name">
                                                <div *ngIf="companyContactForm.controls['company_contact_full_name'].invalid && (companyContactForm.controls['company_contact_full_name'].dirty || companyContactForm.controls['company_contact_full_name'].touched) || compContactForSAHasError"
                                                    class="alert">
                                                    <div
                                                        *ngIf="companyContactForm.controls['company_contact_full_name'].errors?.required">
                                                        Please enter name.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Phone<small>*</small></label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control"
                                                    formControlName="company_contact_phone">
                                                <div *ngIf="companyContactForm.controls['company_contact_phone'].invalid && (companyContactForm.controls['company_contact_phone'].dirty || companyContactForm.controls['company_contact_phone'].touched) || compContactForSAHasError"
                                                    class="alert">
                                                    <div
                                                        *ngIf="companyContactForm.controls['company_contact_phone'].errors?.required">
                                                        Please enter Phone number.
                                                    </div>
                                                    <div
                                                        *ngIf="companyContactForm.controls['company_contact_phone'].errors?.pattern">
                                                        Phone number must be exactly 10 digit & only number is allowed.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Email<small>*</small></label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control"
                                                    formControlName="company_contact_email">
                                                <div *ngIf="companyContactForm.controls['company_contact_email'].invalid && (companyContactForm.controls['company_contact_email'].dirty || companyContactForm.controls['company_contact_email'].touched)  || compContactForSAHasError"
                                                    class="alert">
                                                    <div
                                                        *ngIf="companyContactForm.controls['company_contact_email'].errors?.required">
                                                        Please enter email address.
                                                    </div>
                                                    <div
                                                        *ngIf="companyContactForm.controls['company_contact_email'].errors?.pattern">
                                                        Invalid Email, please try again.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset class="scheduler-border">
                                        <legend class="pt-4">Client Admin</legend>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">First Name<small>*</small></label>
                                            <div class="col-sm-9">
                                                <input readonly type="text" class="form-control"
                                                    formControlName="ca_first_name">
                                                <div *ngIf="companyContactForm.controls['ca_first_name'].invalid && (companyContactForm.controls['ca_first_name'].dirty || companyContactForm.controls['ca_first_name'].touched)"
                                                    class="alert">
                                                    <div
                                                        *ngIf="companyContactForm.controls['ca_first_name'].errors?.required">
                                                        Please enter first name
                                                    </div>
                                                    <div
                                                        *ngIf="companyContactForm.controls['ca_first_name'].errors?.pattern">
                                                        Please enter valid name, only alphabets are allowed.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Last Name<small>*</small></label>
                                            <div class="col-sm-9">
                                                <input readonly type="text" class="form-control"
                                                    formControlName="ca_last_name">
                                                <div *ngIf="companyContactForm.controls['ca_last_name'].invalid && (companyContactForm.controls['ca_last_name'].dirty || companyContactForm.controls['ca_last_name'].touched)"
                                                    class="alert">
                                                    <div
                                                        *ngIf="companyContactForm.controls['ca_last_name'].errors?.required">
                                                        Please enter last name
                                                    </div>
                                                    <!-- <div *ngIf="companyContactForm.controls['ca_last_name'].errors?.pattern">
                                                        Please enter valid name, only alphabets are allowed.
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Phone<small>*</small></label>
                                            <div class="col-sm-9">
                                                <input readonly type="text" class="form-control"
                                                    formControlName="key_contact_phone_no">
                                                <div *ngIf="companyContactForm.controls['key_contact_phone_no'].invalid && (companyContactForm.controls['key_contact_phone_no'].dirty || companyContactForm.controls['key_contact_phone_no'].touched)"
                                                    class="alert">
                                                    <div
                                                        *ngIf="companyContactForm.controls['key_contact_phone_no'].errors?.required">
                                                        Please enter phone
                                                    </div>
                                                    <div
                                                        *ngIf="companyContactForm.controls['key_contact_phone_no'].errors?.pattern">
                                                        Phone number must be exactly 10 digit & only number is allowed.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">LinkedIn Address</label>
                                            <div class="col-sm-9">
                                                <input readonly type="text" class="form-control"
                                                    formControlName="key_contact_skype_id">

                                                <div *ngIf="companyContactForm.controls['key_contact_skype_id'].errors?.pattern"
                                                    class="alert">
                                                    Please enter valid LinkedIn url.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Email<small>*</small></label>
                                            <div class="col-sm-9">
                                                <input readonly type="text" class="form-control"
                                                    formControlName="key_contact_email">
                                                <div *ngIf="companyContactForm.controls['key_contact_email'].invalid && (companyContactForm.controls['key_contact_email'].dirty || companyContactForm.controls['key_contact_email'].touched)"
                                                    class="alert">
                                                    <div
                                                        *ngIf="companyContactForm.controls['key_contact_email'].errors?.required">
                                                        Please enter email
                                                    </div>
                                                    <div
                                                        *ngIf="companyContactForm.controls['key_contact_email'].errors?.pattern">
                                                        Invalid Email, please try again.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Job Title<small>*</small></label>
                                            <div class="col-sm-9">
                                                <input readonly type="text" class="form-control"
                                                    formControlName="job_title">
                                                <div *ngIf="companyContactForm.controls['job_title'].invalid && (companyContactForm.controls['job_title'].dirty || companyContactForm.controls['job_title'].touched)"
                                                    class="alert">
                                                    <div
                                                        *ngIf="companyContactForm.controls['job_title'].errors?.required">
                                                        Please enter job title
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Username<small>*</small></label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" formControlName="key_username">
                                                <div *ngIf="companyContactForm.controls['key_username'].invalid && (companyContactForm.controls['key_username'].dirty || companyContactForm.controls['key_username'].touched)" class="alert">
                                                    <div *ngIf="companyContactForm.controls['key_username'].errors?.required">
                                                        Please enter username
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Password<small>*</small></label>
                                            <div class="col-sm-9">
                                                <input type="password" class="form-control" formControlName="key_password">
                                                <div *ngIf="companyContactForm.controls['key_password'].invalid && (companyContactForm.controls['key_password'].dirty || companyContactForm.controls['key_password'].touched)" class="alert">
                                                    <div *ngIf="companyContactForm.controls['key_password'].errors?.required">
                                                        Please enter password
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Upload profile picture</label>
                                            <div class="col-sm-9">
                                                <input type="file" style="width: 250px; float: left;" class="form-control-file" >
                                                <input type="hidden" name="fileHidden" formControlName="ca_profile_pic" />
                                                <div class="imagePreview" *ngIf="caProfilePic && caProfilePic !== '' && caProfilePic !== 'None'">
                                                    <img [src]="caProfilePic" [alt]="companyContactForm.value.name">
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <button class="btn btn-primary" style="float: right;" (click)="openforPassword(password)">Change Password</button> -->
                                    </fieldset>
                                    <fieldset class="scheduler-border">
                                        <legend class="pt-4">Coordinator Contact</legend>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">First Name</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" formControlName="hr_first_name">
                                                <div *ngIf="companyContactForm.controls['hr_first_name'].errors?.pattern"
                                                    class="alert">
                                                    Please enter valid name, only alphabets are allowed.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Last Name</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" formControlName="hr_last_name">
                                                <div *ngIf="companyContactForm.controls['hr_last_name'].errors?.pattern"
                                                    class="alert">
                                                    Please enter valid name, only alphabets are allowed.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Phone</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control"
                                                    formControlName="hr_contact_phone_no">
                                                <div *ngIf="companyContactForm.controls['hr_contact_phone_no'].errors?.pattern"
                                                    class="alert">
                                                    Phone number must be exactly 10 digit & only number is allowed.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">LinkedIn Address</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control"
                                                    formControlName="hr_contact_skype_id">
                                                <div *ngIf="companyContactForm.controls['hr_contact_skype_id'].errors?.pattern"
                                                    class="alert">
                                                    Please enter valid LinkedIn url.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Email</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control"
                                                    formControlName="hr_contact_email">
                                                <div *ngIf="companyContactForm.controls['hr_contact_email'].errors?.pattern"
                                                    class="alert">
                                                    Invalid Email, please try again.
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset class="scheduler-border">
                                        <legend class="pt-4">CTO</legend>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">First Name</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control"
                                                    formControlName="cto_first_name">
                                                <div *ngIf="companyContactForm.controls['cto_first_name'].errors?.pattern"
                                                    class="alert">
                                                    Please enter valid name, only alphabets are allowed.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Last Name</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" formControlName="cto_last_name">
                                                <div *ngIf="companyContactForm.controls['cto_last_name'].errors?.pattern"
                                                    class="alert">
                                                    Please enter valid name, only alphabets are allowed.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Phone</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" formControlName="cto_phone_no">
                                                <div *ngIf="companyContactForm.controls['cto_phone_no'].errors?.pattern"
                                                    class="alert">
                                                    Phone number must be exactly 10 digit & only number is allowed.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">LinkedIn Address</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" formControlName="cto_skype_id">
                                                <div *ngIf="companyContactForm.controls['cto_skype_id'].errors?.pattern"
                                                    class="alert">
                                                    Please enter valid LinkedIn url.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Email</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" formControlName="cto_email">
                                                <div *ngIf="companyContactForm.controls['cto_email'].errors?.pattern"
                                                    class="alert">
                                                    Invalid Email, please try again.
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset class="scheduler-border">
                                        <legend class="pt-4">Billing Contact</legend>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">First Name</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control"
                                                    formControlName="billing_first_name">
                                                <div *ngIf="companyContactForm.controls['billing_first_name'].errors?.pattern"
                                                    class="alert">
                                                    Please enter valid name, only alphabets are allowed.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Last Name</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control"
                                                    formControlName="billing_last_name">
                                                <div *ngIf="companyContactForm.controls['billing_last_name'].errors?.pattern"
                                                    class="alert">
                                                    Please enter valid name, only alphabets are allowed.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Phone</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control"
                                                    formControlName="billing_phone_no">
                                                <div *ngIf="companyContactForm.controls['billing_phone_no'].errors?.pattern"
                                                    class="alert">
                                                    Phone number must be exactly 10 digit & only number is allowed.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3 col-form-label">Email</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" formControlName="billing_email">
                                                <div *ngIf="companyContactForm.controls['billing_email'].errors?.pattern"
                                                    class="alert">
                                                    Invalid Email, please try again.
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <!-- <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Special requirements</label>
                                        <div class="col-sm-9">
                                            <textarea class="form-control" formControlName="special_req"></textarea>
                                        </div>
                                    </div> -->
                                    <!-- <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Upload logo image</label>
                                        <div class="col-sm-9">
                                            <input type="file" style="width: 250px; float: left;" class="form-control-file" (change)="showPreview($event)">
                                            <input type="hidden" name="fileHidden" formControlName="logo" />
                                            <div class="imagePreview" *ngIf="imageURL && imageURL !== '' && imageURL !== 'None'">
                                                <img [src]="imageURL" [alt]="companyContactForm.value.name">
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="d-inline float-right my-3">
                                        <button type="button" (click)="cancelClicked()"
                                            class="btn btn-secondary f-left ml-2 mr-2">Cancel</button>
                                        <button class="btn btn-primary" (click)="saveCompContactForSA()">Save</button>
                                    </div>
                                </form>

                                <form [formGroup]="saAccLevelForm" *ngIf="clientDetails === 'accountLevels'">
                                    <!-- [formGroup]="accountDetailsForm" -->
                                    <div>
                                        <fieldset class="scheduler-border">
                                            <h2 class="pt-4 ps-4">Account Level Settings</h2>
                                            <div class="inputGroup">
                                                <input id="Trial" type="radio" (click)="trialSelected()"
                                                    formControlName="package1" value="Trial" name="package1" />
                                                <label for="Trial">Trial Account - $0</label>

                                            </div>
                                            <div class="inputGroup">
                                                <input id="Starter" type="radio" (click)="packChangedForSA($event)"
                                                    formControlName="package1" value="Starter" name="package1" />
                                                <label for="Starter">Starter Account - {{'$'+ startPrice}}</label>

                                            </div>
                                            <div class="inputGroup">
                                                <input id="Growth" type="radio" (click)="packChangedForSA($event)"
                                                    formControlName="package1" value="Growth" name="package1" />
                                                <label for="Growth">Growth Account - {{'$'+ growthPrice}}</label>
                                            </div>
                                            <div class="inputGroup">
                                                <input id="Enterprise" type="radio" (click)="packChangedForSA($event)"
                                                    formControlName="package1" value="Enterprise" name="package1" />
                                                <label for="Enterprise">Enterprise Account - {{'$'+ enterPrice}}</label>
                                            </div>

                                            <div class="form-group row" *ngIf="!showExpiryDate">
                                                <table class="ml-0 package-table">
                                                    <thead>
                                                        <tr>
                                                            <th class="col-sm-3 col-form-label text-end">
                                                                User Level
                                                            </th>
                                                            <th class="col-sm-2 text-center">Package Users</th>
                                                            <th class="col-sm-2 pl-4 text-center">Current Users</th>
                                                            <th class="col-sm-2 text-center">Additional User</th>
                                                            <th class="col-sm-3 pl-4 text-center">Additional monthly
                                                                charges</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr class="mt-3">
                                                            <td class="col-sm-3 col-form-label text-end">
                                                                Senior Manager
                                                            </td>
                                                            <td class="col-sm-2 text-center starter_package_col">

                                                                <input readonly class="form-control"
                                                                    style="text-align:center;"
                                                                    formControlName="pack_sm" />

                                                            </td>
                                                            <td class="col-sm-2 text-start starter_package_col">

                                                                <input readonly type="number"
                                                                    class="form-control text-center"
                                                                    formControlName="senior_managers" />

                                                            </td>
                                                            <td class="col-sm-2 text-center starter_package_col">

                                                                <input type="number" class="form-control"
                                                                    (change)="packValueChanged($event)"
                                                                    formControlName="add_sm" />
                                                                <div *ngIf="saAccLevelForm.controls['add_sm'].invalid && (saAccLevelForm.controls['add_sm'].dirty || saAccLevelForm.controls['add_sm'].touched)"
                                                                    class="alert">
                                                                    <div
                                                                        *ngIf="saAccLevelForm.controls['add_sm'].errors?.required">
                                                                        Count is required.
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="col-sm-3  ">
                                                                <div class="row">
                                                                    <span
                                                                        class="col-sm-2 form-control text-center font-weight-bold">$</span>
                                                                    <input readonly type="number"
                                                                        class="col-sm-9 form-control text-center"
                                                                        formControlName="add_sm_price" />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr class="mt-3">
                                                            <td class="col-sm-3 col-form-label text-end">
                                                                Hiring Manager
                                                            </td>
                                                            <td class="col-sm-2 text-center starter_package_col">

                                                                <input readonly class="form-control"
                                                                    style="text-align:center;"
                                                                    formControlName="pack_hm" />

                                                            </td>
                                                            <td class="col-sm-2 text-start starter_package_col">

                                                                <input readonly type="number"
                                                                    class="form-control text-center"
                                                                    formControlName="hiring_managers" />

                                                            </td>
                                                            <td class="col-sm-2 text-center starter_package_col">

                                                                <input type="number" class="form-control"
                                                                    (change)="packValueChanged($event)"
                                                                    formControlName="add_hm" />
                                                                <div *ngIf="saAccLevelForm.controls['add_hm'].invalid && (saAccLevelForm.controls['add_hm'].dirty || saAccLevelForm.controls['add_hm'].touched)"
                                                                    class="alert">
                                                                    <div
                                                                        *ngIf="saAccLevelForm.controls['add_hm'].errors?.required">
                                                                        Count is required.
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="col-sm-3 ">
                                                                <div class="row">
                                                                    <span
                                                                        class="col-sm-2 form-control text-center font-weight-bold">$</span>
                                                                    <input readonly type="number"
                                                                        class="col-sm-9 form-control text-center"
                                                                        formControlName="add_hm_price" />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr class="mt-3">
                                                            <td class="col-sm-3 col-form-label text-end">
                                                                Hiring Team Member
                                                            </td>
                                                            <td class="col-sm-2 text-center starter_package_col">

                                                                <input readonly class="form-control"
                                                                    style="text-align:center;"
                                                                    formControlName="pack_htm" />

                                                            </td>
                                                            <td class="col-sm-2 starter_package_col">

                                                                <input readonly type="number"
                                                                    class="form-control text-center "
                                                                    formControlName="hiring_team_members" />

                                                            </td>
                                                            <td class="col-sm-2 text-center starter_package_col">

                                                                <input type="number" class="form-control"
                                                                    (change)="packValueChanged($event)"
                                                                    formControlName="add_htm" />
                                                                <div *ngIf="saAccLevelForm.controls['add_htm'].invalid && (saAccLevelForm.controls['add_htm'].dirty || saAccLevelForm.controls['add_htm'].touched)"
                                                                    class="alert">
                                                                    <div
                                                                        *ngIf="saAccLevelForm.controls['add_htm'].errors?.required">
                                                                        Count is required.
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="col-sm-3 text-start ">
                                                                <div class="row">
                                                                    <span
                                                                        class="col-sm-2 form-control text-center font-weight-bold">$</span>
                                                                    <input readonly type="number"
                                                                        class="col-sm-9 form-control text-center"
                                                                        formControlName="add_htm_price" />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <br> <br>
                                            <div class="form-group row justify-content-left pl-3"
                                                *ngIf="showExpiryDate">
                                                <label class="col-sm-3 text-end " for="inlineFormInputName3">Trial
                                                    Account Expiry Date</label>
                                                <div class="col-sm-4 row ml-2">
                                                    <input class="form-control date-input mb-2 mr-sm-2"
                                                        placeholder="dd-mm-yyyy" name="dp" ngbDatepicker
                                                        #d3="ngbDatepicker" formControlName="trial_expired"
                                                        container="body" id="inlineFormInputName3" readonly />
                                                    <button class="btn btn-outline-secondary datepicker-btn"
                                                        (click)="d3.toggle()" type="button">
                                                        <fa-icon [icon]="faCalendar"></fa-icon>
                                                    </button>
                                                    <!-- <div *ngIf="saAccLevelForm.controls['trial_expired'].invalid && (saAccLevelForm.controls['trial_expired'].dirty || saAccLevelForm.controls['trial_expired'].touched)" class="alert">
                                                <div *ngIf="saAccLevelForm.controls['trial_expired'].errors?.required">
                                                    Please select an package expiry date
                                                </div>
                                            </div> -->
                                                </div>
                                            </div>

                                            <div class="form-group row justify-content-left pl-3"
                                                *ngIf="!showExpiryDate">
                                                <label for="current_charges" class="col-sm-3 text-end">Current
                                                    Charges</label>
                                                <div class="col-sm-4 row ml-2">
                                                    <span
                                                        class="col-sm-2 form-control text-center pl-0 pr-0 font-weight-bold">$</span>
                                                    <input readonly type="text" class="form-control col-sm-8 "
                                                        formControlName="current_charges">
                                                    <span *ngIf="current_charge_tooltip"
                                                        class="col-sm-2 input-group-text cursor-pointer pl-3"
                                                        ngbTooltip="{{current_charge_tooltip}}" placement="right"
                                                        container="body">
                                                        <fa-icon [icon]="faInfo"></fa-icon>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="form-group row justify-content-left pl-3"
                                                *ngIf="!showExpiryDate">
                                                <label for="additional_charges" class="col-sm-3 text-end">Additional
                                                    Charges</label>
                                                <div class="col-sm-4 row ml-2">
                                                    <span
                                                        class="col-sm-2 form-control text-center pl-0 pr-0 font-weight-bold">$</span>
                                                    <input readonly type="text" class="form-control col-sm-8"
                                                        formControlName="additional_charges">
                                                </div>
                                            </div>
                                            <div class="form-group row justify-content-left pl-3"
                                                *ngIf="!showExpiryDate">
                                                <label for="future_charges" class="col-sm-3 text-end">Future
                                                    Charges</label>
                                                <div class="col-sm-4 row ml-2">
                                                    <span
                                                        class="col-sm-2 form-control text-center pl-0 pr-0 font-weight-bold">$</span>
                                                    <input readonly type="text" class="form-control col-sm-8"
                                                        formControlName="future_charges">
                                                    <span *ngIf="future_charge_tooltip"
                                                        class="col-sm-2 input-group-text cursor-pointer pl-3"
                                                        ngbTooltip="{{future_charge_tooltip}}" placement="right"
                                                        container="body">
                                                        <fa-icon [icon]="faInfo"></fa-icon>
                                                    </span>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div class="d-inline float-right my-3">
                                        <button type="button"
                                            [disabled]="saAccLevelForm.pristine || saAccLevelForm.invalid"
                                            (click)="cancelPackageChangesForSA()"
                                            class="btn btn-secondary f-left mr-2">Cancel</button>
                                        <button class="btn btn-primary"
                                            [disabled]="saAccLevelForm.pristine || saAccLevelForm.invalid"
                                            (click)="openAccLevelModal(confirmAccLevel)">Save</button>
                                    </div>
                                </form>

                                <div class="row justify-content-center" *ngIf="clientDetails === 'billingInformation' ">
                                    <fieldset class="py-4 billContForm">
                                        <h5 class="pt-4 ps-4">Billing Information</h5>
                                        <form [formGroup]="billingInfoForm">
                                            <div class="form-group row justify-content-center">
                                                <label for="billing-contact" class="col-sm-3 col-form-label">Billing
                                                    Contact</label>
                                                <input readonly type="text" value="David Roberts"
                                                    class="form-control billContName col-sm-5"
                                                    formControlName="billing_contact">
                                                <!-- <div *ngIf="billingForm.controls['billing-contact'].invalid && (billingForm.controls['billing-contact'].dirty || billingForm.controls['billing-contact'].touched)" class="alert">
                                                    <div *ngIf="billingForm.controls['billing-contact'].errors?.required">
                                                        Contact is required.
                                                    </div>
                                                    <div *ngIf="billingForm.controls['billing-contact'].errors?.pattern">
                                                        Please enter valid contact.
                                                    </div>
                                                </div> -->
                                            </div>
                                            <div class="form-group row justify-content-center">
                                                <div class="offset-4 col-sm-7">
                                                    <input type="checkbox" id="billingSameAsCompany"
                                                        [(ngModel)]="billingAddress"
                                                        (click)="selectAddressForSA($event)"
                                                        class="d-inline-block me-3">
                                                    <label for="billingSameAsCompany"
                                                        class="d-inline-block mb-0"><small>Billing address same as
                                                            company address</small></label>
                                                </div>
                                            </div>
                                            <div class="form-group row justify-content-center">
                                                <label for="adressLine1" class="col-sm-3 col-form-label">Address line
                                                    1</label>
                                                <input type="text" class="form-control col-sm-5"
                                                    formControlName="addr_line_1">
                                            </div>
                                            <div class="form-group row justify-content-center">
                                                <label for="adressLine2" class="col-sm-3 col-form-label">Address Line
                                                    2</label>
                                                <input type="text" class="form-control col-sm-5"
                                                    formControlName="addr_line_2">
                                            </div>
                                            <div class="form-group row justify-content-center">
                                                <label for="cityName" class="col-sm-3 col-form-label">City</label>
                                                <input type="text" class="form-control col-sm-5" formControlName="city">
                                            </div>
                                            <div class="form-group row justify-content-center">
                                                <label for="cityName" class="col-sm-3 col-form-label">State</label>
                                                <input type="text" class="form-control col-sm-5"
                                                    formControlName="state">
                                            </div>
                                            <div class="form-group row justify-content-center">
                                                <label for="zipcode" class="col-sm-3 col-form-label">Zip</label>
                                                <input type="text" class="form-control col-sm-5"
                                                    formControlName="pincode">
                                            </div>
                                            <div class="form-group row justify-content-center" *ngIf="!showCardDetails">
                                                <label for="cardOnFile" class="col-sm-3 col-form-label">Card on
                                                    file</label>
                                                <input readonly type="text" class="form-control col-sm-3"
                                                    [value]="lastFourDigits" formControlName="cardOnFile">
                                                <div class="col-sm-2">
                                                    <button type="button" class="btn btn-primary"
                                                        (click)="updateCard()">Update</button>
                                                </div>
                                            </div>
                                            <div class="form-group row justify-content-center" *ngIf="showCardDetails">
                                                <div class="col-sm-3"></div>
                                                <div class="col-sm-5">
                                                    <div class="form-group row">
                                                        <label for="cardOnFile" class="row col-form-label">Card
                                                            Number</label><br>
                                                        <input type="text" class="form-control row ml-0 col-sm-12"
                                                            placeholder="eg. 4242424242424242" maxlength="16" size="16"
                                                            formControlName="card_number">
                                                        <div *ngIf="billingInfoForm.controls['card_number'].invalid && (billingInfoForm.controls['card_number'].dirty || billingInfoForm.controls['card_number'].touched)"
                                                            class="alert">
                                                            <div
                                                                *ngIf="billingInfoForm.controls['card_number'].errors?.pattern">
                                                                Please enter valid 16 digit card number.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row ">
                                                        <label for="cardOnFile" class="row col-form-label">Name on
                                                            card</label><br>
                                                        <input type="text" class="form-control row ml-0 col-sm-12"
                                                            placeholder="eg. Joe Biden" formControlName="name_on_card">
                                                    </div>
                                                    <div class="form-group row ">
                                                        <div class="col-sm-6  pl-0 ">
                                                            <label for="cardOnFile"
                                                                class="row pl-3 col-form-label">Expiry Date</label>
                                                            <input type="text" class="form-control row ml-0 col-sm-12"
                                                                maxlength="5" size="5" placeholder="MM/YY"
                                                                formControlName="exp_date">
                                                            <div *ngIf="billingInfoForm.controls['exp_date'].invalid && (billingInfoForm.controls['exp_date'].dirty || billingInfoForm.controls['exp_date'].touched)"
                                                                class="alert">
                                                                <div
                                                                    *ngIf="billingInfoForm.controls['exp_date'].errors?.pattern">
                                                                    Please enter valid MM/YY date.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6  pl-0 ">
                                                            <label for="cardOnFile"
                                                                class="row pl-3 col-form-label">Security Code</label>
                                                            <input type="text" class="form-control row ml-0 col-sm-12"
                                                                placeholder="000" maxlength="3" size="3"
                                                                formControlName="security">
                                                            <div *ngIf="billingInfoForm.controls['security'].invalid && (billingInfoForm.controls['security'].dirty || billingInfoForm.controls['security'].touched)"
                                                                class="alert">
                                                                <div
                                                                    *ngIf="billingInfoForm.controls['security'].errors?.pattern">
                                                                    Please enter valid 3 digit code.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-inline float-right my-3">
                                                <button type="button" (click)="cancelClicked()"
                                                    class="btn btn-secondary f-left ml-2 mr-2">Cancel</button>
                                                <button class="btn btn-primary"
                                                    (click)="saveBillingInfoForSA()">Save</button>
                                            </div>
                                        </form>
                                    </fieldset>
                                </div>
                                <div class="row justify-content-center" *ngIf="clientDetails === 'billingHistory'">
                                    <div class="PaymentHistorySection">
                                        <div class="row mb-3">
                                            <div class="col-sm-12">
                                                <div class="col-sm-1 float-right"><button class="border-0"
                                                        (click)="downloadInvoice()" [disabled]="isDownload"><i
                                                            class="fa fa-download fa-lg text-success"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-sm-12">
                                                <h5 class="mb-0">Billing History</h5>
                                                <p class="text-secondary"><small>See history of your payment plan
                                                        invoice</small></p>
                                            </div>
                                        </div>
                                        <!-- <table class="table-responsive" style="width:100%">
                                            <tr class="mb-1 billData-headers">
                                                <th><input type="checkbox" class="mt-2"></th>
                                                <th class="text-bold"onclick="sortData('Payment Invoice')">Payment Invoice <i class="fas fa-long-arrow-alt-up text-success"></i><i class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i></th>
                                                <th class="text-bold">Invoice Date <i class="fas fa-long-arrow-alt-up text-success"></i><i class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i></th>
                                                <th class="text-bold">Status <i class="fas fa-long-arrow-alt-up text-success"></i><i class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i></th>
                                                <th class="text-bold">Period <i class="fas fa-long-arrow-alt-up text-success"></i><i class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i></th>
                                                <th class="text-bold">Amount <i class="fas fa-long-arrow-alt-up text-success"></i><i class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i></th>
                                            </tr>
                                        </table> -->
                                        <div class="row mb-1">
                                            <div class="col-sm-1 text-end">
                                                <!-- <input type="checkbox" class="mt-2"> -->
                                            </div>
                                            <div class="col-sm-11 border-0">
                                                <div class="billData-headers d-flex">
                                                    <div class="col text-bold pe-0"
                                                        (click)="sortAccordions('invoiceNumber')">Payment Invoice <i
                                                            class="fas fa-long-arrow-alt-up text-success"></i><i
                                                            class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i>
                                                    </div>
                                                    <div class="col text-bold" (click)="sortAccordions('date')">Invoice
                                                        Date <i class="fas fa-long-arrow-alt-up text-success"></i><i
                                                            class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i>
                                                    </div>
                                                    <div class="col text-bold" (click)="sortAccordions('status')">Status
                                                        <i class="fas fa-long-arrow-alt-up text-success"></i><i
                                                            class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i>
                                                    </div>
                                                    <div class="col text-bold" (click)="sortAccordions('period')">Period
                                                        <i class="fas fa-long-arrow-alt-up text-success"></i><i
                                                            class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i>
                                                    </div>
                                                    <div class="col text-bold ps-0" (click)="sortAccordions('amount')">
                                                        Amount <i class="fas fa-long-arrow-alt-up text-success"></i><i
                                                            class="fas fa-long-arrow-alt-up fa-rotate-180 text-success"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0 pb-0 billsAccordion" *ngFor="let invoice of invoices">
                                            <div class="col-sm-1 text-end">
                                                <input type="radio" name="Invoices" id="invoice.id_int"
                                                    [value]="invoice.id_int" (click)="selectInvoice($event)"
                                                    class="mt-3">
                                            </div>
                                            <ngb-accordion class="col-sm-11">
                                                <ngb-panel [collapsed]="true">
                                                    <ng-template ngbPanelTitle class="justify-content-between px-0">
                                                        <div class="col text-bold">{{invoice.id}}</div>
                                                        <div class="col">{{invoice.date}}</div>
                                                        <div class="col" *ngIf="invoice.status=='succeeded'"><i
                                                                class="fas fa-circle fa-xs pb-2 text-success"></i>
                                                            Success</div>
                                                        <div class="col" *ngIf="invoice.status =='Failed'"
                                                            placement="top-left"
                                                            ngbTooltip="Payment has Failed. please click to take action and not lose your data.">
                                                            <i class="fas fa-circle fa-xs text-danger"></i> Failed
                                                        </div>
                                                        <div class="col" *ngIf="invoice.status=='incomplete'"><i
                                                                class="fas fa-circle fa-xs pb-2 text-secondary"></i>
                                                            Incomplete</div>
                                                        <div class="col">{{invoice.period || ' '}}</div>
                                                        <div class="col">{{invoice.amount}}</div>
                                                    </ng-template>
                                                    <ng-template ngbPanelContent>
                                                        <div class="row mt-1">
                                                            <div class="col-sm-5 ps-0">
                                                                <div class="ps-3 bill-compName mt-4">
                                                                    <h5 class="mb-2">{{invoice.client}}</h5>
                                                                    <p>Thank you for subscribing to Qorums, the leading
                                                                        Hiring Process Management System.</p>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-7 px-0">
                                                                <table class="table border-0 billing-table">
                                                                    <tr *ngIf="invoice.amt_breakdown.package">
                                                                        <td>
                                                                            <h6 class="">
                                                                                {{invoice.amt_breakdown.package.name}}
                                                                            </h6>
                                                                        </td>
                                                                        <td>
                                                                            <h6>${{invoice.amt_breakdown.package.price}}
                                                                            </h6>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="invoice.amt_breakdown.senior_managers">
                                                                        <td>
                                                                            <h6 class="mb-0">
                                                                                {{invoice.amt_breakdown.senior_managers[0]}}
                                                                            </h6>
                                                                            <p>{{invoice.amt_breakdown.senior_managers[2]}}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <h6>${{invoice.amt_breakdown.senior_managers[1]}}
                                                                            </h6>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="invoice.amt_breakdown.hiring_managers">
                                                                        <td>
                                                                            <h6 class="mb-0">
                                                                                {{invoice.amt_breakdown.hiring_managers[0]}}
                                                                            </h6>
                                                                            <p>{{invoice.amt_breakdown.hiring_managers[2]}}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <h6>${{invoice.amt_breakdown.hiring_managers[1]}}
                                                                            </h6>
                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        *ngIf="invoice.amt_breakdown.hiring_team_members">
                                                                        <td>
                                                                            <h6 class="mb-0">
                                                                                {{invoice.amt_breakdown.hiring_team_members[0]}}
                                                                            </h6>
                                                                            <p>{{invoice.amt_breakdown.hiring_team_members[2]}}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <h6>${{invoice.amt_breakdown.hiring_team_members[1]}}
                                                                            </h6>
                                                                        </td>
                                                                    </tr>
                                                                    <tr class="mb-0">
                                                                        <td class="pb-0">
                                                                            <h6>Tax</h6>
                                                                        </td>
                                                                        <td class="border-bottom border-dark pb-0">
                                                                            <h6>$0</h6>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="invoice.status == 'succeeded'">
                                                                        <td>
                                                                            <h5 class="pt-4 ps-0">Paid</h5>
                                                                        </td>
                                                                        <td>
                                                                            <h5 class="pt-4 mb-0">
                                                                                {{invoice.amt_breakdown.total.amount}}
                                                                            </h5>
                                                                            <p class="pt-0">for
                                                                                {{invoice.amt_breakdown.total.days}}
                                                                                days</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="invoice.status == 'Failed'">
                                                                        <td>
                                                                            <h5 class="text-danger mb-0 pt-4 ps-0">
                                                                                Payment Failed</h5>
                                                                            <!-- <button class="btn btn-sm takeActBtn py-0" (click)="takeAction(paymentGateway,invoice.key,invoice.payment_secret)" >Take Action</button> -->
                                                                        </td>
                                                                        <td>
                                                                            <h5 class="pt-4 mb-0">
                                                                                {{invoice.amt_breakdown.total.amount}}
                                                                            </h5>
                                                                            <p class="pt-0">for
                                                                                {{invoice.amt_breakdown.total.days}}
                                                                                days</p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="invoice.status == 'incomplete'">
                                                                        <td>
                                                                            <h5 class="text-danger mb-0 pt-4 ps-0">
                                                                                Payment Incomplete</h5>
                                                                            <!-- <button class="btn btn-sm takeActBtn py-0" (click)="takeAction(paymentGateway,invoice.key,invoice.payment_secret)" >Take Action</button> -->
                                                                        </td>
                                                                        <td>
                                                                            <h5 class="pt-4 mb-0">
                                                                                {{invoice.amt_breakdown.total.amount}}
                                                                            </h5>
                                                                            <p class="pt-0">for
                                                                                {{invoice.amt_breakdown.total.days}}
                                                                                days</p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </ngb-panel>
                                            </ngb-accordion>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="all-templates p-3" *ngIf="showopenPositionDetails">
                            <div class="position-privacy">
                                <h5>Set the toggle button to show or hide the open position data from Candidate Dasboard
                                </h5>
                                <table class="table table-borderless">
                                    <tr>
                                        <td>
                                            <h5>Position Title</h5>
                                        </td>
                                        <td>
                                            <ui-switch color="var(--primary)"
                                                (change)="togglePositionPrivacy('position_title')"
                                                [checked]="posPrivacyData?.position_title" labelOn="Yes"
                                                labelOff="No"></ui-switch>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5>No. of Openings</h5>
                                        </td>
                                        <td>
                                            <ui-switch color="var(--primary)"
                                                (change)="togglePositionPrivacy('no_of_position')"
                                                [checked]="posPrivacyData?.no_of_position" labelOn="Yes"
                                                labelOff="No"></ui-switch>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5>Salary Range</h5>
                                        </td>
                                        <td>
                                            <ui-switch color="var(--primary)"
                                                (change)="togglePositionPrivacy('salary_range')"
                                                [checked]="posPrivacyData?.salary_range" labelOn="Yes"
                                                labelOff="No"></ui-switch>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5>Location</h5>
                                        </td>
                                        <td>
                                            <ui-switch color="var(--primary)"
                                                (change)="togglePositionPrivacy('location')"
                                                [checked]="posPrivacyData?.location" labelOn="Yes"
                                                labelOff="No"></ui-switch>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5>Special Intructions</h5>
                                        </td>
                                        <td>
                                            <ui-switch color="var(--primary)"
                                                (change)="togglePositionPrivacy('special_instruction')"
                                                [checked]="posPrivacyData?.special_instruction" labelOn="Yes"
                                                labelOff="No"></ui-switch>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5>Documents</h5>
                                        </td>
                                        <td>
                                            <ui-switch color="var(--primary)"
                                                (change)="togglePositionPrivacy('documents')"
                                                [checked]="posPrivacyData?.documents" labelOn="Yes"
                                                labelOff="No"></ui-switch>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5>Skillsets</h5>
                                        </td>
                                        <td>
                                            <ui-switch color="var(--primary)"
                                                (change)="togglePositionPrivacy('skillsets')"
                                                [checked]="posPrivacyData?.skillsets" labelOn="Yes"
                                                labelOff="No"></ui-switch>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5>Kickoff Date</h5>
                                        </td>
                                        <td>
                                            <ui-switch color="var(--primary)"
                                                (change)="togglePositionPrivacy('kickoff_data')"
                                                [checked]="posPrivacyData?.kickoff_data" labelOn="Yes"
                                                labelOff="No"></ui-switch>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5>Sourcing Date</h5>
                                        </td>
                                        <td>
                                            <ui-switch color="var(--primary)"
                                                (change)="togglePositionPrivacy('sourcing_date')"
                                                [checked]="posPrivacyData?.sourcing_date" labelOn="Yes"
                                                labelOff="No"></ui-switch>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5>Target Deadline Date</h5>
                                        </td>
                                        <td>
                                            <ui-switch color="var(--primary)"
                                                (change)="togglePositionPrivacy('target_date')"
                                                [checked]="posPrivacyData?.target_date" labelOn="Yes"
                                                labelOff="No"></ui-switch>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="all-templates p-4" *ngIf="showNavigator">
                            <ng-container *ngFor="let item of navigators; let i = index">
                                <div class="form-group" style="clear:both">
                                    <div class="mb-2">
                                        <label for="input-jobtitle"><b>Step Name: </b></label>
                                        <input type="text" class="form-control nor-input ml-2" [(ngModel)]="item.title">
                                    </div>
                                    <angular-editor [config]="config" [placeholder]="'Enter text here...'"
                                        [(ngModel)]="item.content"></angular-editor>
                                </div>
                                <button class="btn btn-primary btn-sm float-end mb-3"
                                    (click)="updateNavigator(item)">Update</button>
                            </ng-container>
                        </div>
                        <div class="all-templates p-4" *ngIf="showEmailTempForm">
                            
                            <div class="form-group"
                                *ngIf="candidateAddedForSM && getTypeSelected(candidateAddedForSMObj?.type)">
                                <label for="input-jobtitle"><b>{{candidateAddedForSMObj?.name}}:</b></label>
                                <p> <small>{{candidateAddedForSMObj?.description}}</small></p>
                                <angular-editor [config]="config" [placeholder]="'Enter text here...'"
                                    [(ngModel)]="candidateAddedForSM"></angular-editor>
                            </div>
                            <p class="text-right"
                                *ngIf="candidateAddedForSM && getTypeSelected(candidateAddedForSMObj?.type)"><a
                                    class="savebtn btn"
                                    (click)="updateEmailTemplate('Candidate Added Email for Senior Manager', candidateAddedForSM)">Update</a>
                            </p>

                            <ng-container *ngFor="let item of allEmailTemplates?.data; let i = index">
                                <div class="form-group row"
                                    *ngIf="item?.content && getTypeSelected(item?.type)">
                                    <label for="input-jobtitle"><b>{{item?.name}}:</b></label>
                                    <p> <small>{{item?.description}}</small></p>
                                    <app-email-editor-one [getEmailTemplate]="item"
                                        (emailAction)="emailEdited($event)"></app-email-editor-one>
                                </div>
                            </ng-container>
                            <!-- <div class="form-group row"
                                *ngIf="interviewSetForCan && getTypeSelected(interviewSetForCanObj?.type)">
                                <label for="input-jobtitle"><b>{{interviewSetForCanObj?.name}}:</b></label>
                                <p> <small>{{interviewSetForCanObj?.description}}</small></p>
                                <app-email-editor-two [getEmailTemplate]="interviewSetForCanObj"
                                    (emailAction)="emailEdited($event)"></app-email-editor-two>
                            </div>

                            <div class="form-group row"
                                *ngIf="goldenGloveEmail && getTypeSelected(goldenGloveEmailObj?.type)">
                                <label for="input-jobtitle"><b>{{goldenGloveEmailObj?.name}}:</b></label>
                                <p> <small>{{goldenGloveEmailObj?.description}}</small></p>
                                <app-email-editor-three [getEmailTemplate]="goldenGloveEmailObj"
                                    (emailAction)="emailEdited($event)"></app-email-editor-three>
                            </div>

                            <div class="form-group row"
                                *ngIf="htmAddedEmail && getTypeSelected(htmAddedEmailObj?.type)">
                                <label for="input-jobtitle"><b>{{htmAddedEmailObj?.name}}:</b></label>
                                <p> <small>{{htmAddedEmailObj?.description}}</small></p>
                                <app-email-editor-four [getEmailTemplate]="htmAddedEmailObj"
                                    (emailAction)="emailEdited($event)"></app-email-editor-four>
                            </div>

                            <div class="form-group row"
                                *ngIf="canAddedEmailToHtm && getTypeSelected(canAddedEmailToHtmObj?.type)">
                                <label for="input-jobtitle"><b>{{canAddedEmailToHtmObj?.name}}:</b></label>
                                <p> <small>{{canAddedEmailToHtmObj?.description}}</small></p>
                                <app-email-editor-five [getEmailTemplate]="canAddedEmailToHtmObj"
                                    (emailAction)="emailEdited($event)"></app-email-editor-five>
                            </div>

                            <div class="form-group row"
                                *ngIf="interviewReminderEmail && getTypeSelected(interviewReminderEmailObj?.type)">
                                <label for="input-jobtitle"><b>{{interviewReminderEmailObj?.name}}:</b></label>
                                <p> <small>{{interviewReminderEmailObj?.description}}</small></p>
                                <app-email-editor-six [getEmailTemplate]="interviewReminderEmailObj"
                                    (emailAction)="emailEdited($event)"></app-email-editor-six>
                            </div>

                            <div class="form-group row"
                                *ngIf="passwordResetEmail && getTypeSelected(passwordResetEmailObj?.type)">
                                <label for="input-jobtitle"><b>{{passwordResetEmailObj?.name}}:</b></label>
                                <p> <small>{{passwordResetEmailObj?.description}}</small></p>
                                <app-email-editor-seven [getEmailTemplate]="passwordResetEmailObj"
                                    (emailAction)="emailEdited($event)"></app-email-editor-seven>
                            </div>

                            <div class="form-group row"
                                *ngIf="positionCreatedEmail && getTypeSelected(positionCreatedEmailObj?.type)">
                                <label for="input-jobtitle"><b>{{positionCreatedEmailObj?.name}}:</b></label>
                                <p> <small>{{positionCreatedEmailObj?.description}}</small></p>
                                <app-email-editor-eight [getEmailTemplate]="positionCreatedEmailObj"
                                    (emailAction)="emailEdited($event)"></app-email-editor-eight>
                            </div>

                            <div class="form-group row"
                                *ngIf="qorumsWelcomeEmail && getTypeSelected(qorumsWelcomeEmailObj?.type)">
                                <label for="input-jobtitle"><b>{{qorumsWelcomeEmailObj?.name}}:</b></label>
                                <p> <small>{{qorumsWelcomeEmailObj?.description}}</small></p>
                                <app-email-editor-nine [getEmailTemplate]="qorumsWelcomeEmailObj"
                                    (emailAction)="emailEdited($event)"></app-email-editor-nine>
                            </div>

                            <div class="form-group row"
                                *ngIf="userCreatedEmail && getTypeSelected(userCreatedEmailObj?.type)">
                                <label for="input-jobtitle"><b>{{userCreatedEmailObj?.name}}:</b></label>
                                <p> <small>{{userCreatedEmailObj?.description}}</small></p>
                                <app-email-editor-ten [getEmailTemplate]="userCreatedEmailObj"
                                    (emailAction)="emailEdited($event)"></app-email-editor-ten>
                            </div>

                            <div class="form-group row"
                                *ngIf="candidateSignupEmail?.content && getTypeSelected(candidateSignupEmail?.type)">
                                <label for="input-jobtitle"><b>{{candidateSignupEmail?.name}}:</b></label>
                                <p> <small>{{candidateSignupEmail?.description}}</small></p>
                                <app-email-editor-eleven [getEmailTemplate]="candidateSignupEmail"
                                    (emailAction)="emailEdited($event)"></app-email-editor-eleven>
                            </div>
                            
                            <div class="form-group row"
                                *ngIf="inviteUsersEmail?.content && getTypeSelected(inviteUsersEmail?.type)">
                                <label for="input-jobtitle"><b>{{inviteUsersEmail?.name}}:</b></label>
                                <p> <small>{{inviteUsersEmail?.description}}</small></p>
                                <app-email-editor-twelve [getEmailTemplate]="inviteUsersEmail"
                                    (emailAction)="emailEdited($event)"></app-email-editor-twelve>
                            </div>

                            <div class="form-group row"
                                *ngIf="inviteCandidateEmail?.content && getTypeSelected(inviteCandidateEmail?.type)">
                                <label for="input-jobtitle"><b>{{inviteCandidateEmail?.name}}:</b></label>
                                <p> <small>{{inviteCandidateEmail?.description}}</small></p>
                                <app-email-editor-thirteen [getEmailTemplate]="inviteCandidateEmail"
                                    (emailAction)="emailEdited($event)"></app-email-editor-thirteen>
                            </div>

                            <div class="form-group row"
                                *ngIf="reinviteUserEmail?.content && getTypeSelected(reinviteUserEmail?.type)">
                                <label for="input-jobtitle"><b>{{reinviteUserEmail?.name}}:</b></label>
                                <p> <small>{{reinviteUserEmail?.description}}</small></p>
                                <app-email-editor-fourteen [getEmailTemplate]="reinviteUserEmail"
                                    (emailAction)="emailEdited($event)"></app-email-editor-fourteen>
                            </div> -->
                           
                            <pre>
                                <code>
                                    <p class="mb-2"><b>MACROS Used:</b></p>
                                    <pre ngNonBindable>{{user_name}}</pre> - Name of the user to whom mail is sent
                            <pre ngNonBindable>{{position_title}}</pre> - Name of the position
                            <pre ngNonBindable>{{candidate_name}}</pre> - Name of the candidate
                            <pre ngNonBindable>{{username}}</pre> - Login username
                            <pre ngNonBindable>{{password}}</pre> - Password
                            <pre ngNonBindable>{{company}}</pre> - Name of the company
                            <pre ngNonBindable>{{link}}</pre> - Link of something mostly of Interview
                            <pre ngNonBindable>{{manager}}</pre> - Name of the Client HR/Hiring Manager
                            <pre ngNonBindable>{{manager_contact}}</pre> - Contact of the Client HR/Hiring Manager
                            <pre ngNonBindable>{{manager_email}}</pre> - Email of the Client HR/Hiring Manager
                            <pre ngNonBindable>{{htm_name}}</pre> - Name of the Interviewer
                            <pre ngNonBindable>{{hiring_team_name}}</pre> - Hiring Group name
                            <pre ngNonBindable>{{senior_manager_name}}</pre> - Senior Manager Name
                            </code>
                            </pre>
                        </div>
                        <div
                            *ngIf="!showUserDetails && !showAccDetails && !showBillingDetails && !showProTipForm && !showEmailTempForm && !showGlobalPricing && !showClientAccounts && !showNavigator && !showopenPositionDetails">
                            <p>Please select an option from left panel to see the details...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>


<ng-template #password let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Change Password</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="passwordForm">
            <div class="form-group row">

                <label class="col-sm-4 col-form-label">New Password <small>*</small></label>
                <div class="col-sm-8">
                    <input type="{{ showPassword ? 'text' : 'password' }}" class="form-control input-password"
                        autocomplete="new_password" formControlName="new_password" (input)="onPasswordChange()"
                        [ngStyle]="{'color': passwordForm.controls.new_password.errors? 'red' : 'green'}">
                    <button type="button" (click)="togglePasswordVisibility()" class="password-toggle">
                        <i class="fas" [ngClass]="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                    </button>
                </div>
                <div class="col-sm-8 offset-4">
                    <div *ngIf="passwordForm.controls['new_password'].invalid && (passwordForm.controls['new_password'].dirty || passwordForm.controls['new_password'].touched)"
                        class="alert">
                        <div
                            *ngIf="passwordForm.controls['new_password'].errors?.required && !passwordForm.controls['new_password'].value">
                            Please enter password
                        </div>
                    </div>
                    <div *ngIf="passwordForm.controls['new_password']?.errors && passwordForm.controls['new_password'].value && !(passwordForm.controls['new_password']?.hasError('maxlength'))"
                        class="password-valid-pts">
                        Password must contain at least one of each of the following characters:
                        <div [ngStyle]="{'color': isUppercaseValid ? 'green' : 'red'}">
                            <span>- Uppercase letters: A-Z.</span>
                        </div>
                        <div [ngStyle]="{'color': isLowecaseValid ? 'green' : 'red'}">
                            <span>- Lowercase letters: a-z.</span>
                        </div>
                        <div [ngStyle]="{'color': isNumberValid ? 'green' : 'red'}">
                            <span>- Numbers: 0-9.</span>
                        </div>
                        <div [ngStyle]="{'color': isSymbolValid ? 'green' : 'red'}">
                            <span>- Symbols: ~`! @#$%^&*()_-+={{'{'}}[{{'}'}}]|\:;"'<,>.?/</span>
                        </div>
                    </div>
                    <div *ngIf="(passwordForm.controls['new_password']?.hasError('minlength') || passwordForm.controls['new_password']?.hasError('maxlength')) && (passwordForm.controls['new_password'].dirty || passwordForm.controls['new_password'].touched)"
                        class="alert">
                        <span>Password should contain min 8 and max 16 characters</span>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Re-Password
                    <small>*</small>
                </label>
                <div class="col-sm-8">
                    <input type="password" class="form-control" formControlName="re_password" (blur)="checkPasswords()">
                    <div *ngIf="passwordForm.controls['re_password'].invalid && (passwordForm.controls['re_password'].dirty || passwordForm.controls['re_password'].touched)"
                        class="alert">
                        <div *ngIf="passwordForm.controls['re_password'].errors?.required">
                            Re-Password is required.
                        </div>
                    </div>
                    <label *ngIf="showError" class="alert">Password not matching, try again</label>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="passwordForm.invalid || showError"
            (click)="changePassword()">Change Password</button>
    </div>
</ng-template>

<ng-template #confirmAccLevel let-modal>
    <div class="modal-header">
        <h4 class="modal-title text-center">Please Confirm your account change</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group row justify-content-center" *ngIf="!showExpiryDate">
            <span *ngIf="userRole ==='Client Admin'"
                class="text-center form-control border-1  col-sm-4">{{accLevelForm.value.future_charges}}</span>
            <span *ngIf="userRole !='Client Admin'"
                class="text-center form-control border-1  col-sm-4">{{saAccLevelForm.value.future_charges}}</span>
            <label class="col-form-label col-sm-5">New Monthly Charges</label>

        </div>
        <div class="row justify-content-center">
            <p class="text-center">A confirmation code has been sent to your email address.<br>
                Please copy the code and enter it below to confirm your account change.</p>
            <input type="text text-center" class="form-control col-sm-3 text-center" [(ngModel)]="otpNum">
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button *ngIf="userRole ==='Client Admin'" type="button" class="btn btn-primary"
            (click)="saveAccLevelForm(paymentGateway)">Confirm</button>
        <button *ngIf="userRole !='Client Admin'" type="button" class="btn btn-primary"
            (click)="saveSaAccLevelForm(paymentGateway)">Confirm</button>
    </div>
</ng-template>

<ng-template #paymentGateway let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Pay additional charges</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body payMod">
        <form id="payment-form">
            <div id="link-authentication-element">
                <!--Stripe.js injects the Link Authentication Element-->
            </div>
            <div id="payment-element">
                <!--Stripe.js injects the Payment Element-->
            </div>
            <button id="submit" class="payButton">
                <div class="spinner hidden" id="spinner"></div>
                <span id="button-text">Pay now</span>
            </button>
            <div id="payment-message" class="hidden"></div>
        </form>

    </div>
    <div class="modal-footer">

    </div>
</ng-template>