<div class="page-body">
    <div class="widget-wrapper custom-scroll-bar">
        <div class="col-sm-2 cand-head">
            <div class="profile_photo">
                <img class="prof-pic"
                    [src]="candidateData.profile_photo && candidateData.profile_photo !== 'None' && candidateData.profile_photo !== 'null' ? imgBaseUrl + candidateData.profile_photo : './../../../assets/images/big-default-avatar.png'"
                    alt="Profile photo" />
            </div>
        </div>
        <div class="col-sm-10 cand-details">
            <div class="row">
                <div class="col-sm-12 cand-title-box">
                    <span class="candidate-name">
                        <span class="c-name"> {{candidateData.full_name | titlecase}} </span>&nbsp;&nbsp;
                        <span class="c-nickname" *ngIf="candidateData.nickname"> {{'(' + candidateData.nickname +
                            ')'}}</span>
                    </span>

                    <span class="cand-contact">
                        <span class="mr-4">
                            <fa-icon [icon]="faLinkedin" class="mr-2"
                                [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
                            <a class="text-dark" *ngIf="candidateData.linkedin" target="_blank"
                                [href]="(candidateData.linkedin.startsWith('http://') || candidateData.linkedin.startsWith('https://')) ? candidateData.linkedin : 'https://' + candidateData.linkedin">Visit
                                Linkedin Profile</a>
                            <span class="text-dark" *ngIf="!candidateData.linkedin">{{'Not updated'}}</span>
                        </span>
                        <span class="mr-4">
                            <fa-icon [icon]="faEnvelope" class="mr-2"
                                [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
                            <a class="text-dark" [href]="'mailto:'+candidateData.email"
                                *ngIf="candidateData.email">{{candidateData.email }}</a>
                            <span class="text-dark" *ngIf="!candidateData.email">{{'Not updated'}}</span>
                        </span>
                        <span *ngIf="candidateData.phone">
                            <fa-icon [icon]="faPhone" class="mr-2"
                                [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
                            <a class="text-dark" [href]="'tel:'+candidateData.phone"
                                *ngIf="candidateData.phone">{{candidateData.phone }}</a>
                            <span class="text-dark" *ngIf="!candidateData.phone">{{'Not updated'}}</span>
                        </span>
                    </span>

                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 candidate-rating-box">
                    <div class="candidate-rating-box-left-section">
                        <div class="table-box">
                            <span class="marks-flag i-s-confirm"
                                style="background-color: #00AF78;max-width: 48px;height: 48px;">
                                <fa-icon [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '25px'}"
                                    [icon]="faThumbsUp"></fa-icon>
                            </span>
                            <span class="pl-2">
                                <span class="box-heading">
                                    Has Potential
                                </span><br>
                                <span style="color: #666666;">
                                    Count: {{candidateData.thumbs_up_count || 0}}
                                </span>
                            </span>
                        </div>
                        <div class="table-box">
                            <span class="marks-flag i-s-confirm"
                                style="background-color: #FE0000;max-width: 48px;height: 48px;">
                                <fa-icon [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '25px'}"
                                    [icon]="faThumbsDown"></fa-icon>
                            </span>
                            <span class="pl-2">
                                <span class="box-heading">
                                    Does not have Potential
                                </span> <br>
                                <span style="color: #666666;">
                                    Count: {{candidateData.thumbs_down_count || 0}}
                                </span>
                            </span>
                        </div>
                        <div class="table-box">
                            <span class="marks-flag i-s-confirm"
                                style="background-color: #FBCD3C;max-width: 48px;height: 48px;">
                                <fa-icon [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '25px'}"
                                    [icon]="faThumbsUp"></fa-icon>
                            </span>
                            <span class="pl-2">
                                <span style=" font-weight: 600;" class="box-heading">
                                    Golden Glove
                                </span><br>
                                <span style="color: #666666;">
                                    Count: {{candidateData.golden_glove_count || 0}}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="candidate-rating-box-right-section">
                        <div *ngIf="!candidateData.isHired && currentUserRoleCode!=='/htm'">
                            <button class="btn mr-2 withdraw-button" *ngIf="!candidateData.withrawn"
                                (click)="withdrawCand()">Withdraw</button>
                            <button class="btn mr-2 withdraw-button" *ngIf="candidateData.withrawn"
                                (click)="restoreCand()">Restore</button>
                        </div>
                        <div *ngIf="!candidateData.isHired && currentUserRoleCode!=='/htm' && !candidateData.withrawn">
                            <button class="btn green-button mr-2 " (click)="hireCandidate()">Hire</button>
                        </div>
                        <div *ngIf="!candidateData.isHired">
                            <button *ngIf="!candidateData.withrawn" class="btn  green-button mr-2"
                                (click)="openInterviewModal(interviewPage)">Schedule Interview</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="attachments mt-3 mb-2">
                <span (click)="openFile(candidateData.resume[0])" *ngIf="candidateData.resume[0]"
                    class="cursor-pointer">
                    <fa-icon class="mr-2"
                        [styles]="{'stroke': 'var(--primary-200)', 'color': 'var(--primary-200)', 'font-size': '20px'}"
                        [icon]="faFile"></fa-icon>
                    View Resume
                </span>
                <span *ngIf="!candidateData.resume[0]">
                    <fa-icon class="mr-2" [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"
                        [icon]="faFile"></fa-icon>
                    No Resume
                </span>
            </div>
            <div class="row" *ngIf="!skillset.showGraph">
                <div class="col-sm-4">
                    <table class="table table-borderless c-data-table">
                        <tr>
                            <td class="font-weight-bold">Interviews (Last 30 days)</td>
                            <td>{{candidateData.interviews_last_30 || '0'}}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">Location</td>
                            <td>{{candidateData.location || 'Not Specified'}}</td>
                        </tr>
                        <tr>
                            <td style="border-bottom: none" class="font-weight-bold">Salary Requirements</td>
                            <td style="border-bottom: none">{{candidateData.salary_req ? '$'+ candidateData.salary_req :
                                'Not Specified'}}</td>
                        </tr>
                    </table>
                </div>
                <div class="col-sm-5">
                    <table class="table table-borderless c-data-table "
                        *ngIf="currentUserRoleCode==='/htm' && skillData?.marks">
                        <tr>
                            <td [ngStyle]="{'border-bottom':skillData.marks?'1px solid #BEBEBE':'none'}"
                                class="font-weight-bold v-middle">Vote</td>
                            <td [ngStyle]="{'border-bottom':skillData.marks?'1px solid #BEBEBE':'none'}">
                                <div class="table-box" *ngIf="skillData?.thumbs===1">
                                    <span class="marks-flag i-s-confirm"
                                        style="background-color: #00AF78;max-width: 48px;height: 48px;">
                                        <fa-icon
                                            [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '25px'}"
                                            [icon]="faThumbsUp"></fa-icon>
                                    </span>
                                    <span class="pl-2">
                                        <span class="box-heading">
                                            Has Potential
                                        </span><br>
                                    </span>
                                </div>
                                <div class="table-box" *ngIf="skillData?.thumbs===2">
                                    <span class="marks-flag i-s-confirm"
                                        style="background-color: #FE0000;max-width: 48px;height: 48px;">
                                        <fa-icon
                                            [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '25px'}"
                                            [icon]="faThumbsDown"></fa-icon>
                                    </span>
                                    <span class="pl-2">
                                        <span class="box-heading">
                                            Does not have Potential
                                        </span> <br>
                                    </span>
                                </div>
                                <div class="table-box" *ngIf="skillData?.golden_glove">
                                    <span class="marks-flag i-s-confirm"
                                        style="background-color: #FBCD3C;max-width: 48px;height: 48px;">
                                        <fa-icon
                                            [styles]="{'stroke': '#FFFFFF', 'color': '#FFFFFF', 'font-size': '25px'}"
                                            [icon]="faThumbsUp"></fa-icon>
                                    </span>
                                    <span class="pl-2">
                                        <span style=" font-weight: 600;" class="box-heading">
                                            Golden Glove
                                        </span><br>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <ng-container *ngFor="let skill of skillData.marks;let i = index">
                            <tr>
                                <td colspan="2" class="font-weight-bold">
                                    <span class="pl-0">{{skill?.skillset_name}}</span>
                                    <!-- <span class="grey-ball float-end " *ngIf="currentUserRoleCode!=='/htm'">
                                        {{skill?.skillset_marks}}
                                    </span> -->
                                    <div class="pl-0 tab-width">Questions:<br>
                                        <ng-container *ngFor="let que of skill?.skillset_questions;let j = index">
                                            {{(j+1) +' '+que.text}}<br>
                                        </ng-container>
                                    </div>

                                    <div class="pl-0 tab-width">
                                        Answers:<br>{{skill.skillset_ans || 'NA'}}
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                        <tr *ngIf="skillData.marks">
                            <td colspan="2" class="font-weight-bold" style="border-bottom: none ">
                                <div class="pl-0">Feedback: {{skillData.feedback || 'NA'}}</div>
                            </td>
                        </tr>
                    </table>

                    <table class="table table-borderless c-data-table" *ngIf="currentUserRoleCode!=='/htm'">
                        <tr>
                            <td [ngStyle]="{'border-bottom':skillData.marks?'1px solid #BEBEBE':'none'}"
                                class="font-weight-bold">Overall Score</td>
                            <td [ngStyle]="{'border-bottom':skillData.marks?'1px solid #BEBEBE':'none'}">
                                <span *ngIf="candidateData.avg_marks===-1" class="grey-ball">
                                    NA
                                </span>
                                <span *ngIf="candidateData.avg_marks!==-1" class="grey-ball float-end ">
                                    {{candidateData.avg_marks}}
                                </span>
                            </td>
                        </tr>
                        <ng-container *ngFor="let skill of skillData.marks;let i = index">
                            <tr *ngIf="skill.isChecked">
                                <!-- [ngStyle]="{'border-bottom': i==skillData.marks.length-1?'none':'1px solid #BEBEBE'}" -->
                                <td colspan="2" class="font-weight-bold">
                                    <span class="pl-0">{{skill?.skillset_name | titlecase}}</span>
                                    <span class="grey-ball float-end ">
                                        {{skill?.skillset_marks}}
                                    </span>
                                    <div class="pl-0 tab-width">Questions:<br>
                                        <ng-container *ngFor="let que of skill?.skillset_questions;let j = index">
                                            {{(j+1) +' ' + que.text | titlecase}}<br>
                                        </ng-container>
                                    </div>
                                    <!-- </td>
                                <td [ngStyle]="{'border-bottom': i==skillData.marks.length-1?'none':'1px solid #BEBEBE'}"> -->

                                    <div class="pl-0 tab-width">
                                        Answers:<br>{{skill.skillset_ans || 'NA'}}
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                        <tr *ngIf="skillData.marks">
                            <td colspan="2" class="font-weight-bold" style="border-bottom: none ">
                                <div class="pl-0">Comments/Feedback: {{skillData.feedback || 'NA' | titlecase}}</div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-sm-3" *ngIf="!candidateData.isHired">
                    <div class="" *ngFor="let item of candidateData.candidate_schedule">
                        <ng-container *ngIf="item.interviewer_name!==''">

                            <div *ngIf="item.interview_type==='zoho'" class="btn-group my-2" role="group"
                                aria-label="Basic example">
                                <button type="button" (click)="startInterview(item,candidateData)"
                                    [ngClass]="{'green-button': isIntToday(item),'gray-button': !isIntToday(item)}"
                                    class="btn text-left left-text-button">
                                    <div>
                                        {{item.interview_type=='zoho'?'
                                        QVideo':item.interview_type | uppercase}} -
                                        <span *ngIf="isIntToday(item)">{{item.formated_time}} with
                                            {{item.interviewer_name}}</span>
                                        <span *ngIf="!isIntToday(item)">{{item.formated_date}} with
                                            {{item.interviewer_name}}</span>
                                    </div>
                                </button>
                                <button type="button" (click)="copyInterviewLink(item,candidateData)"
                                    [ngClass]="{'green-button': isIntToday(item),'gray-button': !isIntToday(item)}"
                                    class="btn btn-secondary"><fa-icon [icon]="faShare" class="mr-2"></fa-icon></button>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row htmdata" *ngIf="skillset.showGraph">
                <div class="col-6">
                    <ng-container *ngIf="!skillData.showNoData">
                        <google-chart [data]="barChart"></google-chart>
                    </ng-container>
                    <ng-container *ngIf="skillData.showNoData">
                        <p class="text-danger">Data by HTMs not available</p>
                    </ng-container>
                </div>
                <div class="col-6 htmdata-ai">
                    <div class="custom-scroll-bar">
                        <h3>QBrain Summary</h3>
                        <!-- <p class="mb-3">Know what our HTMs have to say about the {{candidateData.full_name | titlecase}}</p> -->
                        <p *ngIf="!skillData.showNoData">{{qBrainComments || 'Comments Not Available'}}
                        </p>
                        <p *ngIf="skillData.showNoData">
                            {{'Selected HTMs have not yet entered feedback for the candidate.'}}
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #successMsg let-modal>
    <div class="modal-header" style="background-color: #C4F7E8;">
        <h5 class="modal-title" id="modal-basic-title">
            <fa-icon class="mr-1" [icon]="faCheckCircle"
                [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
            Interview Confirmed with {{candidateData.full_name | titlecase}}
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-0" style="background-color: #C4F7E8;">
        <div class=" info-box">
            <p>
                <fa-icon class="mr-1" [icon]="faUser"
                    [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
                Interviewer - <strong class="text-capitalize">{{this.htmNames.toString() | titlecase}}</strong>
            </p>
            <p *ngIf="this.emailForm.value.date">
                <fa-icon class="mr-1" [icon]="faCalendar"
                    [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
                Date - <strong>{{formatDate(this.emailForm.value.date) || 'To be Decided by Candidate'}}</strong>
            </p>
            <p *ngIf="this.emailForm.value.startTime">
                <fa-icon class="mr-1" [icon]="faClock"
                    [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
                Time -
                <strong>{{this.emailForm.value.startTime}} - {{this.emailForm.value.endTime}}</strong>
            </p>
            <p>
                <fa-icon class="mr-1" [icon]="faGlobe"
                    [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
                Timezone - <strong>{{this.emailForm.value.long_timezone}}</strong>
            </p>
            <p *ngIf="this.emailForm.value.interviewType">
                <fa-icon class="mr-1" [icon]="faVideo"
                    [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
                Mode - <strong>{{this.emailForm.value.interviewType=='zoho'? 'QVideo' :
                    this.emailForm.value.interviewType | titlecase}} Interview</strong>
            </p>
            <p *ngIf="!this.emailForm.value.date">
                <fa-icon class="mr-1" [icon]="faCalendar"
                    [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
                Date - <strong>{{'To be Decided by Candidate'}}</strong>
            </p>
            <p *ngIf="!this.emailForm.value.startTime">
                <fa-icon class="mr-1" [icon]="faClock"
                    [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
                Time - <strong>{{'To be Decided by Candidate'}}</strong>
            </p>
        </div>
    </div>
    <div class="modal-footer" style="background-color: #C4F7E8;">
        <button type="button" class="btn save-button" (click)="modal.dismiss()">OK</button>
    </div>
</ng-template>

<ng-template #interviewPage let-modal>
    <div class="modal-header" style="background-color: #E8E8E8;">
        <h4 class="modal-title">Schedule Interview</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body m-body">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 p-3 " [ngClass]="{'choose-slot-box': option1}">
                    <div class="inputGroup">
                        <input id="userone" class="form-check-input" type="radio" (change)="optionChanges(1)"
                            [value]="option1" name="package" checked />
                        <label for="userone" class="form-check-label ml-2" [ngClass]="{'text-success': option1}"> Pick a
                            time to schedule the interview</label>
                    </div>
                </div>
                <div class="col-sm-6 p-3" [ngClass]="{'choose-slot-box': option2}">
                    <div class="inputGroup">
                        <input id="usertwo" class="form-check-input" type="radio" (change)="optionChanges(2)"
                            [value]="option2" name="package" />
                        <label for="usertwo" class="form-check-label ml-2" [ngClass]="{'text-success': option2}"> Let
                            the candidate choose the time of the interview</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="container time-form" [ngClass]="{'main-box-border-1': option1, 'main-box-border-2': option2}">
            <div class="row pt-3">
                <div class="col-sm-12">
                    <p class="font-weight-bold">Open Position:
                        <span class="font-weight-normal">{{currentPositionName | titlecase}}</span>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8">
                    <label>Choose Interviews<small>*</small><small class="ml-1" *ngIf="showHtmCalWarning">The selected
                            user has not yet attached its calendar</small></label>
                    <div class="htms-box p-3">
                        <div *ngFor="let mem of htmMembers;let i = index;" (click)="selectHTMForInterview(mem)"
                            class="htmss text-center mr-2 mb-2"
                            [ngClass]="{'selected-htms': selectedHtmArr.indexOf(mem.email)>-1}">
                            <img [src]="mem.profile_pic || './../../../assets/images/big-default-avatar.png'"
                                class="htm-photo">
                            <p class="my-0 htm-name">{{mem.name}}</p>
                            <p class="my-0" style="color: #6F7372;">{{giveMeRole(mem.roles)}}</p>
                        </div>
                    </div>
                    <label class="mt-1">
                        <fa-icon [icon]="faInfoCircle"
                            [styles]="{'stroke': 'var(--secondary)', 'color': 'var(--secondary)', 'font-size': '20px'}"></fa-icon>
                        - Atleast one SM or CA has to be selected</label>
                </div>
                <div class="col-sm-4 " [formGroup]="emailForm" *ngIf="option1">
                    <div>
                        <label for="date">Pick Date<small>*</small></label>
                        <div class="input-group">
                            <!-- (dateSelect)="generateMailBody()" -->
                            <input class="form-control" [minDate]="yesterday" placeholder="mm-dd-yyyy" name="dp"
                                formControlName="date" #d="ngbDatepicker" readonly ngbDatepicker>
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                    type="button"><fa-icon [icon]="faCalendar"
                                        [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)'}"></fa-icon></button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label class=" col-form-label">Time Zone <small>*</small></label>
                        <div class="">
                            <select class="form-select " (change)="selectTimeZone($event)"
                                aria-label="Default select example">
                                <option selected>Open this select menu</option>
                                <option *ngFor="let item of timeZones" [value]=item.display>{{item.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="my-3">
                        <label for="date">Start Time<small>*</small></label>
                        <div class="input-group">
                            <input class="form-control" type="time" id="st" name="st" formControlName="startTime"
                                placeholder="00:00 AM">
                            <!-- <ngb-timepicker [meridian]="true" [minuteStep]="minuteStep" formControlName="startTime"></ngb-timepicker> -->
                        </div>
                    </div>
                    <div>
                        <label for="date">End Time<small>*</small></label>
                        <div class="input-group">
                            <input class="form-control" type="time" id="et" name="et" formControlName="endTime"
                                placeholder="00:00 AM">
                            <!-- <ngb-timepicker [meridian]="true" [minuteStep]="minuteStep"
                                formControlName="endTime"></ngb-timepicker> -->
                        </div>
                    </div>
                    <small *ngIf="dateError">Please enter proper Start and End time</small>
                </div>
                <div class="col-sm-4 " [formGroup]="emailForm" *ngIf="option2">
                    <div>
                        <label class=" col-form-label">Interview Duration <small>*</small></label>
                        <div class="">
                            <!-- formControlName="duration" -->
                            <select class="form-select " (change)="selectInterviewDuration($event)"
                                aria-label="Default select example">
                                <option selected>Open this select menu</option>
                                <option value="30">30 mins</option>
                                <option value="60">60 mins</option>
                            </select>
                        </div>
                    </div>
                    <div class="my-3">
                        <label class=" col-form-label">Any available time in the next<small>*</small></label>
                        <div class="">
                            <!-- formControlName="next_days" -->
                            <select class="form-select " (change)="onAvailableTime($event)"
                                aria-label="Default select example">
                                <option selected>Open this select menu</option>
                                <option value="3">3 days</option>
                                <option value="5">5 days</option>
                                <option value="10">10 days</option>
                                <option value="15">15 days</option>
                                <option value="20">20 days</option>
                                <option value="25">25 days</option>
                                <option value="30">30 days</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <label class=" col-form-label">Time Zone <small>*</small></label>
                        <div class="">
                            <select class="form-select " (change)="selectTimeZone($event)"
                                aria-label="Default select example">
                                <option selected>Open this select menu</option>
                                <option *ngFor="let item of timeZones" [value]=item.display>{{item.display}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-sm-3">
                    <label>Selected Candidate</label>
                    <div style="height: 125px;">
                        <div class="htmss selected-htms text-center mr-2">
                            <img [src]="candidateData.profile_photo || './../../../assets/images/big-default-avatar.png'"
                                class="htm-photo">
                            <p class="htm-name my-0">{{candidateData.full_name}}</p>
                            <p class="my-0" style="color:var(--primary);">Candidate</p>
                        </div>
                    </div>
                    <label>
                        <fa-icon [icon]="faInfoCircle"
                            [styles]="{'stroke': 'var(--secondary)', 'color': 'var(--secondary)', 'font-size': '20px'}"></fa-icon>
                        - Close this popup to choose another candidate
                    </label>
                </div>
                <div class="col-sm-9" [formGroup]="emailForm">
                    <div>
                        <label for="subject">Interview Subject<small>*</small></label>
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="subject">
                        </div>
                    </div>
                    <div class="my-3">
                        <label for="cc">Additional Recipients Email</label>
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="ccEmails">
                        </div>
                    </div>
                    <div *ngIf="option1">
                        <ng-container
                            *ngIf="!emailForm.controls['date'].invalid && !emailForm.controls['startTime'].invalid && !emailForm.controls['endTime'].invalid && !emailForm.controls['timezone'].invalid">
                            <label for="type_interview">Type of Interview<small>*</small></label>
                            <div class="form-group row">
                                <!-- [ngClass]="{'d-none': !emailForm.value.specific}" -->
                                <!-- <label class="col-sm-1 col-form-label">Type of Interview <small>*</small></label> -->
                                <div class="btn-group col-sm-8" role="group"
                                    aria-label="Radio Buttons with Bootstrap 5">
                                    <input (change)="toggleZohoInterview('zoho')" type="radio"
                                        formControlName="interviewType" [value]="'zoho'" class="btn-check"
                                        id="btnradio1" autocomplete="off">
                                    <label class="btn btn-outline-primary" for="btnradio1">QVideo</label>

                                    <input (change)="toggleZohoInterview('in-person')" type="radio"
                                        formControlName="interviewType" [value]="'in-person'" class="btn-check"
                                        id="btnradio2" autocomplete="off">
                                    <label class="btn btn-outline-primary" for="btnradio2">In-Person</label>

                                    <input (change)="toggleZohoInterview('phone')" type="radio"
                                        formControlName="interviewType" [value]="'phone'" class="btn-check"
                                        id="btnradio3" autocomplete="off">
                                    <label class="btn btn-outline-primary" for="btnradio3">Phone</label><br>

                                </div>
                                <div style="margin-left: 18.5%;"
                                    *ngIf="emailForm.controls['interviewType'].invalid && (emailForm.controls['interviewType'].dirty || emailForm.controls['interviewType'].touched)  "
                                    class="alert">
                                    <div *ngIf="emailForm.controls['interviewType'].errors?.required">
                                        Please Select Interview type
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row pb-4">
                <div class="col-sm-12" [formGroup]="emailForm">
                    <label for="subject">Email Body<small>*</small></label>
                    <div class="input-group">
                        <textarea rows="3" class="form-control" formControlName="mailBody"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <!-- <p class="text-muted" style="flex: auto;">
            <fa-icon class="mr-2" [icon]="faCarrot"
                [styles]="{'stroke': '#FF9933', 'color': '#FF9933', 'font-size': '25px'}"></fa-icon>
            Earn 5 Reward points for finishing interview before target date.
        </p> -->
        <p class="text-muted" style="flex: auto;">
            <!-- <fa-icon class="mr-2" [icon]="faCarrot"
                [styles]="{'stroke': '#FF9933', 'color': '#FF9933', 'font-size': '25px'}"></fa-icon>
            Earn 5 Reward points for finishing interview before target date. -->
        </p>
        <div class="modal-footer">
            <button type="button" class="btn cancel-button" (click)="modal.dismiss('Cross click')">
                Discard and Close
            </button>

            <button *ngIf="!isViewSchedule" type="button" [disabled]="emailForm.invalid || dateError"
                class="btn save-button" (click)="sendEmail(successMsg)">
                Send Interview Invite
            </button>
        </div>
    </div>
</ng-template>