<div class="row" *ngIf="candidateDetailData">
    <div class="col-md-7">
        <div class="widget-profile-box">
            <!-- <div class="widget-profile-head">
                <h3>{{candidateDetailData?.name}}</h3>
                <span>{{candidateDetailData?.email}}</span>
            </div> -->
            <div class="profileContent mt-0">
                <h3 class="mb-1">{{candidateDetailData?.name}} {{candidateDetailData?.last_name}}</h3>
                <!-- <p class="mb-0" [innerText]="candidateDetailData?.comments"></p> -->
                <!-- <p class="mb-0" *ngIf="(userRole =='Superuser') || (userRole =='Account Manager')">Notes: {{candidateDetailData?.personal_notes}}</p> -->
                <!-- <p class="mb-0">LinkedIn: <a target="_blank" [href]="candidateDetailData?.skype_id">{{candidateDetailData?.skype_id}}</a></p> -->
            </div>
        </div>
    </div>
    <div class="col-md-5" *ngIf="(userRole =='Superuser') || (userRole =='Account Manager')">
        <ul class="widget-scors-list candidate-score">
            <ul class="widget-scors-list candidate-score">
                <li *ngIf="candidateDetailData?.final_avg_marks">
                    <span class="scroll_point">{{candidateDetailData?.final_avg_marks | number : '1.1-1'}}</span>
                    <span class="scroll_name">Overall Score</span>
                </li>
                <li *ngIf="candidateDetailData?.init_qualify_ques_1 !== ''">
                    <span class="scroll_point">{{candidateDetailData['marks']?.init_qualify_ques_1}}</span>
                    <span class="scroll_name">{{candidateDetailData?.init_qualify_ques_1}}</span>
                </li>
                <li *ngIf="candidateDetailData?.init_qualify_ques_2 !== ''">
                    <span class="scroll_point">{{candidateDetailData['marks']?.init_qualify_ques_2}}</span>
                    <span class="scroll_name">{{candidateDetailData?.init_qualify_ques_2}}</span>
                </li>
                <li *ngIf="candidateDetailData?.init_qualify_ques_3 !== ''">
                    <span class="scroll_point">{{candidateDetailData['marks']?.init_qualify_ques_3}}</span>
                    <span class="scroll_name">{{candidateDetailData?.init_qualify_ques_3}}</span>
                </li>
                <li *ngIf="candidateDetailData?.init_qualify_ques_4 !== ''">
                    <span class="scroll_point">{{candidateDetailData['marks']?.init_qualify_ques_4}}</span>
                    <span class="scroll_name">{{candidateDetailData?.init_qualify_ques_4}}</span>
                </li>
                <li *ngIf="candidateDetailData?.init_qualify_ques_5 !== ''">
                    <span class="scroll_point">{{candidateDetailData['marks']?.init_qualify_ques_5}}</span>
                    <span class="scroll_name">{{candidateDetailData?.init_qualify_ques_5}}</span>
                </li>
                <li *ngIf="candidateDetailData?.init_qualify_ques_6 !== ''">
                    <span class="scroll_point">{{candidateDetailData['marks']?.init_qualify_ques_6}}</span>
                    <span class="scroll_name">{{candidateDetailData?.init_qualify_ques_6}}</span>
                </li>
                <li *ngIf="candidateDetailData?.init_qualify_ques_7 !== ''">
                    <span class="scroll_point">{{candidateDetailData['marks']?.init_qualify_ques_7}}</span>
                    <span class="scroll_name">{{candidateDetailData?.init_qualify_ques_7}}</span>
                </li>
                <li *ngIf="candidateDetailData?.init_qualify_ques_8 !== ''">
                    <span class="scroll_point">{{candidateDetailData['marks']?.init_qualify_ques_8}}</span>
                    <span class="scroll_name">{{candidateDetailData?.init_qualify_ques_8}}</span>
                </li>
            </ul>
        </ul>
    </div>
</div>
<p class="p-3" *ngIf="!candidateDetailData">Please select a candidate to see the profile details.</p>