<main id="positions-content">
    <app-header style="z-index: 99;position: relative;"></app-header>

    <div class="widget-wrapper">
        <!-- [navlink]="navLinkAction" (tabAction)="actionEmitter($event)" -->
        <app-detail-header></app-detail-header>
        <div class="page-body widget-box">
            <div class="row">
                <div class="col-sm-2 ">
                    <div class="p-4">
                        <p class="cand-title">Add Candidate</p>
                        <p class="text-secondary">You can associate a candidate to an Open Position here.</p>
                        <p class="text-secondary"><span style="color: #FF9900;font-weight: 600;">Note:</span>Once a
                            candidate has been assessed by an interviewer the said candidate cannot be removed
                            from the position.</p>
                    </div>
                </div>
                <div class="col-sm-10">
                    <div *ngIf="!isInviteCandidate" class="widget-inner-box white-box custom-scroll-bar">
                        <div style="padding: 2em;" *ngIf="!this.candData">
                            <p class="text-secondary ">No Candidates added. Please click here to <span
                                    class="text-success font-weight-bold cursor-pointer"><u
                                        (click)="inviteCandidate()">Invite Candidates</u></span></p>
                        </div>
                        <div style="padding: 2em;" *ngIf="this.candData">
                            <p class="text-dark font-weight-bold">Include the Candidates to this Open Position or Please
                                click here to <span class="text-success font-weight-bold cursor-pointer"><u
                                        (click)="inviteCandidate()">Invite new Candidates</u></span></p>
                            <label class="col-form-label">Search Candidate </label>

                            <!-- <div> -->
                            <div class="input-group" style="width: 35%;">
                                <input type="text" id="searchbox" (input)="sendTheNewValue($event)"
                                    class="form-control form-input" [(ngModel)]="searchText"
                                    placeholder="Search by Email ID or First & Last Name" />
                                <button *ngIf="newData.length >0" class="btn ml-3 internal-button"
                                    (click)="clearSuggestion()">Clear</button>
                            </div>

                            <!-- </div> -->
                            <div class="row htm-table-row my-4">
                                <div class="col-sm-12 " *ngIf="selectedCand.length >0">
                                    <table class="table table-striped  htm-table ">
                                        <tbody>
                                            <tr>
                                                <td colspan="2" style="text-align: center;">Candidate Name<span
                                                        (click)="sortTable('name')"
                                                        class="float-right cursor-pointer"><fa-icon [icon]="faSort"
                                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon>
                                                    </span></td>
                                                <td>Date of Submission <span (click)="sortTable('skills')"
                                                        class="float-right cursor-pointer"><fa-icon [icon]="faSort"
                                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon>
                                                    </span></td>
                                                <td>Last Interview </td>
                                                <td>Interviews Completed </td>
                                            </tr>
                                            <tr *ngFor="let data of selectedCand; let i = index">
                                                <td style="border-right: none;text-align: center;width: 8%;">
                                                    <button *ngIf=" data?.status!=='Hired' && data?.status!=='Assessed'"
                                                        (click)="openSm(content,data)"
                                                        class="btn btn-sm btn-danger">Remove</button>
                                                </td>
                                                <td>
                                                    <span class="hm-name mr-2 name-label"> {{ data.name}}</span>
                                                </td>
                                                <td>{{data.submission_date || 'NA'}}</td>
                                                <td>
                                                    {{data.last_interview || 'NA'}}
                                                </td>
                                                <td> {{data.total_interview || 'NA'}}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-sm-6 suggestion-table custom-scroll-bar" *ngIf="newData.length >0">

                                    <span (click)="clearSuggestion()" class="x-mark">
                                        X
                                    </span>
                                    <table class="table ">

                                        <tr *ngFor="let data of newData; let i=index;">
                                            <td style="width: 0%;">
                                                <input *ngIf="data.status!='rejected'" type="checkbox"
                                                    class="c-checkbox" style="vertical-align: sub;"
                                                    (change)="selectCand(data)" [checked]="data.associated" id="hm"
                                                    [name]="data.id" [value]="data.associated">
                                            </td>
                                            <td>
                                                <span class="hm-name"> {{ data.full_name}}</span>
                                                <span *ngIf="data.status=='rejected'" style="color:#FE0A05">(Submission
                                                    Rejected)</span>
                                                <span *ngIf="data.status=='invited'"
                                                    style="color:#676766">(Invited)</span>
                                                <span *ngIf="data.status=='modification'"
                                                    style="color:#FF9903">(Modification Requested)</span>
                                                <span *ngIf="data.status=='added'" style="color:#676766">(Candidate
                                                    Added)</span>
                                                <span *ngIf="data.status=='incomplete'"
                                                    style="color:#676766">(Invited)</span>
                                            </td>
                                            <td class="hm-role text-muted ">{{data.skillset || 'NA'}}</td>
                                        </tr>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isInviteCandidate" class="widget-inner-box white-box custom-scroll-bar">
                        <div style="padding: 2em;">
                            <p>Please enter the email address to invite the candidate.</p>
                            <div class="mt-4">
                                <form [formGroup]="createUsersForm">
                                    <div class="form-group ">
                                        <label for="input-jobtitle" class=" col-form-label">Email Address</label>
                                        <div class="">
                                            <input type="email" class="form-control form-input" formControlName="email"
                                                placeholder="Eg: candidate@gmail.com">
                                            <div *ngIf="createUsersForm.controls['email'].invalid && (createUsersForm.controls['email'].dirty || createUsersForm.controls['email'].touched)"
                                                class="alert">
                                                <div *ngIf="createUsersForm.controls['email'].errors?.required">
                                                    Email is required.
                                                </div>
                                                <div *ngIf="createUsersForm.controls['email'].errors?.pattern">
                                                    Invalid Email, please try again.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="btn cancel-button mr-3" (click)="goBack()">Back</button>
                                    <button class="btn save-button " [disabled]="createUsersForm.invalid"
                                        (click)="inviteUsers()">Invite User</button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row my-4">
                <div class="col-sm-2 ">
                </div>
                <div class="col-sm-10">
                    <div class="text-secondary">
                        <!-- <fa-icon class="mr-2" [icon]="faCarrot"  [styles]="{'stroke': '#FF9933', 'color': '#FF9933', 'font-size': '25px'}"></fa-icon>  Earn 5 Reward points by completing the hire before deadline. <span class="text-success "> Read More</span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row my-4">
        <div class="col-sm-2 ">
        </div>
        <div class="col-sm-10">
            <div class="text-secondary">
                <!-- <fa-icon class="mr-2" [icon]="faCarrot"  [styles]="{'stroke': '#FF9933', 'color': '#FF9933', 'font-size': '25px'}"></fa-icon>  Earn 5 Reward points by completing the hire before deadline. <span class="text-success "> Read More</span> -->
            </div>
        </div>
    </div>
    <!-- </div> -->
</main>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Confirm</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Are you sure you want to remove this candidate from this position?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="removeHtm()">Remove</button>
    </div>
</ng-template>