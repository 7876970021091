import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'skills-chart',
  templateUrl: './skills-chart.component.html',
  styleUrls: ['./skills-chart.component.css']
})
export class SkillsChartComponent implements OnInit {
  @Input() completedPositionData: any;
  @Input() chartData!: any;
  @Input() selectedCandidate: any;
  // chartData = [{"skill":"Python","candidate_score":4,"skill_weightage":8},{"skill":"Django","candidate_score":5,"skill_weightage":7},{"skill":"CSS","candidate_score":9,"skill_weightage":8},{"skill":"Management","candidate_score":10,"skill_weightage":9},{"skill":"Communication","candidate_score":7,"skill_weightage":10},{"skill":"Java","candidate_score":7,"skill_weightage":10}];
  // chartData = [{"skills":"Python","candidate_score":4,"skill_weightage":8},{"skills":"Django","candidate_score":5,"skill_weightage":7},{"skills":"CSS","candidate_score":9,"skill_weightage":8},{"skills":"Management","candidate_score":10,"skill_weightage":9},{"skills":"Communication","candidate_score":7,"skill_weightage":10},{"skills":"Java","candidate_score":7,"skill_weightage":10},{"skills":"Database","candidate_score":8,"skill_weightage":10},{"skills":"React","candidate_score":6,"skill_weightage":10},{"skills":"Vue","candidate_score":4,"skill_weightage":10}];
  constructor() { }

  ngOnInit(): void {
    for (let i = 1; i < 11; i++) {
      // if(this.completedPositionData['init_qualify_ques_' + i]) {
      //   this.chartData.push(
      //     {
      //       skills: this.completedPositionData['init_qualify_ques_' + i],
      //       candidate_score: this.completedPositionData['init_qualify_ques_weightage_' + i],
      //       skill_weightage: 10
      //     }
      //   )
      // }
    }
  }

  ngOnChanges() {
    setTimeout(() => {
      this.attachChartLegendsToBody();
    }, 500);
  }

  getTransform(index: any) {
    // return 'rotate('+360-(360/(index+1))+ 'deg) skew(54deg)'
    // return index + 1 === this.chartData.length ? 360 : 360-(360/(index+1));
    // chart length = 3 => skew = 150, rotate = 120
    // chart length = 4 => skew = 180, rotate = 90
    // chart length = 5 => skew = 198, rotate = 72
    // chart length = 6 => skew = 210, rotate = 60
    // chart length = 7 => skew = , rotate = 51.42
    // return index === 0 ? 60 : index === 1 ? 120 : index === 2 ? 180 : index === 3 ? 240 : index === 4 ? 300 :  360;
    const deg = (360 / this.chartData.length) * (index + 1);
    const skew =
      this.chartData.length === 3 ? 150 :
        this.chartData.length === 4 ? 180 :
          this.chartData.length === 5 ? 198 :
            this.chartData.length === 6 ? 210 :
              this.chartData.length === 7 ? 218 :
                this.chartData.length === 8 ? 225 :
                  this.chartData.length === 9 ? 231 : 360;
    return 'rotate(' + deg + 'deg) skew(' + skew + 'deg)'
  }

  attachChartLegendsToBody() {
    document.querySelectorAll(".chart-legend").forEach(e => e.remove());
    document.querySelectorAll(".legend-tooltip").forEach(e => e.remove());
    const legends = document.querySelectorAll('.skill-name');
    legends.forEach(element => {
      const pos = element.getBoundingClientRect();
      const left = pos.left;
      const top = pos.top;
      const elemDiv = document.createElement('div');
      elemDiv.textContent = element.textContent;
      // elemDiv.classList.add(element.textContent || '');
      elemDiv.classList.add('chart-legend');
      elemDiv.style.cssText = 'cursor: pointer;position:absolute;left:' + left + 'px;top:' + top + 'px;';
      document.body.appendChild(elemDiv);
      const tooltipDiv = document.createElement('div');
      tooltipDiv.classList.add('legend-tooltip');
      tooltipDiv.innerHTML = `<p style="margin: 0">Desired: ` + this.chartData.find((el: any) => el.skill === elemDiv.textContent)?.skill_weightage + `</p>
      <p style="margin: 0">Candidate: `+ this.chartData.find((el: any) => el.skill === elemDiv.textContent)?.candidate_score + `</p>`
      tooltipDiv.style.cssText = 'background-color:var(--primary);padding: 8px;color: #fff;display: none;position:absolute;left:' + left + 'px;top:' + (top + 25) + 'px;';
      document.body.appendChild(tooltipDiv);
      // element
      //   .appendTo('body')
      //   .css({
      //     'position': 'absolute',
      //     'left': left + 'px',
      //     'top': top + 'px'
      //   });
    });

  }

  @HostListener('document:mouseover', ["$event"])
  onMouseEnterLegend(event: any) {
    if (event.target?.classList?.toString()?.indexOf('chart-legend') > -1) {
      setTimeout(() => {
        event.target.nextElementSibling.style.display = 'block';
      }, 500);
    }
  }

  @HostListener('document:mouseout', ["$event"])
  onMouseOutLegend(event: any) {
    if (event.target?.classList?.toString()?.indexOf('chart-legend') > -1) {
      setTimeout(() => {
        event.target.nextElementSibling.style.display = 'none';
      }, 500);
    }
  }

  ngOnDestroy() {
    document.querySelectorAll(".chart-legend").forEach(e => e.remove());
    document.querySelectorAll(".legend-tooltip").forEach(e => e.remove());
  }


}
