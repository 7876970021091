<div
  *ngIf="!isInterviewPage && (currentpageURL.includes('my-calender') || currentpageURL.includes('candidate-dashboard') || currentpageURL.includes('htm-dashboard'))"
  class="row text-center">
  <div class="col-sm-3 calendar-header my-3">
    <div class="btn-group">
      <div class="btn next-prev-button mr-3" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">
        <fa-icon [icon]="faLessThan"></fa-icon>
      </div>
      <div class="btn save-button  mr-3" mwlCalendarToday [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">
        Today
      </div>
      <div class="btn next-prev-button" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">
        <fa-icon [icon]="faGreaterThan"></fa-icon>
      </div>
    </div>
  </div>
  <div class="calendar-header col-sm-3  my-3">
    <h3 class="my-0">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
  </div>
  <div class="calendar-header col-sm-3  my-3">
    <div class="btn-group">
      <div class="btn  next-prev-button mr-3" (click)="setView(CalendarView.Month)"
        [class.active]="view === CalendarView.Month">
        Month
      </div>
      <div class="btn next-prev-button  mr-3" (click)="setView(CalendarView.Week)"
        [class.active]="view === CalendarView.Week">
        Week
      </div>
      <div class="btn next-prev-button" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
        Day
      </div>
    </div>
  </div>
  <div class="calendar-header col-sm-3  my-3">
    <div class=" ">
      <select class="form-select w-100 filter-list" aria-label="Default select example" (change)="onPositionSelect($event)">
        <option selected value="0">Filter by Open Position</option>
        <option value="all">My Calendar</option>
        <option *ngFor="let it of positionList" [value]="it.id">{{it.position_title}}</option>
      </select>
    </div>
  </div>
</div>
<div *ngIf="!isInterviewPage && currentpageURL.includes('position-details')" class="row text-center">
  <div class="col-md-3 calendar-header">
    <div class="btn-group">
      <div class="btn next-prev-button mr-3" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">
        <fa-icon [icon]="faLessThan"></fa-icon>
      </div>
      <div class="btn save-button  mr-3" mwlCalendarToday [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">
        Today
      </div>
      <div class="btn next-prev-button" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()" >
        <fa-icon [icon]="faGreaterThan"></fa-icon>
      </div>
    </div>
  </div>
  <div class="calendar-header col-md-3 ">
    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
  </div>
  <div class="calendar-header col-md-3">
    <div class="btn-group">
      <div class="btn save-button  mr-3" (click)="setView(CalendarView.Month)"
        [class.active]="view === CalendarView.Month">
        Month
      </div>
      <div class="btn next-prev-button  mr-3" (click)="setView(CalendarView.Week)"
        [class.active]="view === CalendarView.Week">
        Week
      </div>
      <div class="btn next-prev-button" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
        Day
      </div>
    </div>
  </div>
  <div *ngIf="candidateList?.length>0" class="calendar-header col-md-3">
    <button class="btn save-button" (click)="openInterviewModal(interviewPage)">Schedule Interview</button>
  </div>

</div>
<br />
<div *ngIf="!isInterviewPage" id="htmAvailability" [ngSwitch]="view">
  <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
    [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventClicked)="eventClick($event.event)"
    (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-month-view>
  <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
    (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-week-view>
  <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
    (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-day-view>
</div>

<ng-template #successMsg let-modal>
  <div class="modal-header" style="background-color: #C4F7E8;">
    <h5 class="modal-title" id="modal-basic-title">
      <fa-icon class="mr-1" [icon]="faCheckCircle"
        [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
      Interview Confirmed with {{candForInterview.name | titlecase}}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body pb-0" style="background-color: #C4F7E8;">
    <div class=" info-box">
      <p>
        <fa-icon class="mr-1" [icon]="faUser"
          [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
        Interviewer - <strong class="text-capitalize">{{this.htmNames.toString() | titlecase}}</strong>
      </p>
      <p *ngIf="this.emailForm.value.date">
        <fa-icon class="mr-1" [icon]="faCalendar"
          [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
        Date - <strong>{{this.emailForm.value.date | formatDate}}</strong>
      </p>
      <p *ngIf="this.emailForm.value.startTime">
        <fa-icon class="mr-1" [icon]="faClock"
          [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
        Time - <strong>{{this.emailForm.value.startTime}} - {{this.emailForm.value.endTime}}</strong>
      </p>
      <p *ngIf="this.emailForm.value.timezone">
        <fa-icon class="mr-1" [icon]="faGlobe"
          [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
        Timezone - <strong>{{this.emailForm.value.long_timezone}}</strong>
      </p>
      <p *ngIf="this.emailForm.value.interviewType">
        <fa-icon class="mr-1" [icon]="faVideo"
          [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
        Mode - <strong>{{this.emailForm.value.interviewType=='zoho'? 'QVideo' : this.emailForm.value.interviewType |
          titlecase}} Interview</strong>
      </p>
      <p *ngIf="!this.emailForm.value.date">
        <fa-icon class="mr-1" [icon]="faCalendar"
          [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
        Date - <strong>{{'To be Decided by Candidate'}}</strong>
      </p>
      <p *ngIf="!this.emailForm.value.startTime">
        <fa-icon class="mr-1" [icon]="faClock"
          [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
        Time - <strong>{{'To be Decided by Candidate'}}</strong>
      </p>
    </div>
  </div>
  <div class="modal-footer" style="background-color: #C4F7E8;">
    <button type="button" class="btn save-button" (click)="modal.dismiss()">OK</button>
  </div>
</ng-template>

<ng-template #interviewPage let-modal>
  <div class="modal-header" style="background-color: #E8E8E8;">
    <h4 class="modal-title">Schedule Interview</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body m-body">
    <div class="container">
      <div class="row">

        <div class="col-sm-6 p-3 " [ngClass]="{'choose-slot-box': option1}">
          <div class="inputGroup">
            <input id="userone" class="form-check-input" type="radio" (change)="optionChanges(1)" [value]="option1"
              name="package" checked />
            <label for="userone" class="form-check-label ml-2" [ngClass]="{'text-success': option1}"> Pick a
              time to schedule the interview</label>
          </div>
        </div>
        <div class="col-sm-6 p-3" [ngClass]="{'choose-slot-box': option2}">
          <div class="inputGroup">
            <input id="usertwo" class="form-check-input" type="radio" (change)="optionChanges(2)" [value]="option2"
              name="package" />
            <label for="usertwo" class="form-check-label ml-2" [ngClass]="{'text-success': option2}"> Let
              the candidate choose the time of the interview</label>
          </div>
        </div>
      </div>
    </div>
    <div class="container time-form" [ngClass]="{'main-box-border-1': option1, 'main-box-border-2': option2}">
      <div class="row pt-3">
        <div class="col-sm-12">
          <p class="font-weight-bold">Open Position:
            <span class="font-weight-normal">{{currentPositionName}}</span>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8">
          <label>Choose Interviews<small>*</small><small class="ml-1" *ngIf="showHtmCalWarning">The selected
              user has not yet attached its calendar</small></label>
          <div class="htms-box p-3">
            <div *ngFor="let mem of htmMembers;let i = index;" (click)="selectHTMForInterview(mem)"
              class="htmss text-center mr-2 mb-2" [ngClass]="{'selected-htms': selectedHtmArr.indexOf(mem.email)>-1}">
              <img [src]="mem.profile_pic || './../../../assets/images/big-default-avatar.png'" class="htm-photo">
              <p class="my-0 htm-name">{{mem.name}}</p>
              <p class="my-0" style="color: #6F7372;">{{giveMeRole(mem.roles)}}</p>
            </div>
          </div>
          <label class="mt-1">
            <fa-icon [icon]="faInfoCircle"
              [styles]="{'stroke': 'var(--secondary)', 'color': 'var(--secondary)', 'font-size': '20px'}"></fa-icon>
            - Atleast one SM or CA has to be selected</label>
        </div>
        <div class="col-sm-4 " [formGroup]="emailForm" *ngIf="option1">
          <div>
            <label for="date">Pick Date<small>*</small></label>
            <div class="input-group">
              <!-- (dateSelect)="generateMailBody()" -->
              <input class="form-control" [minDate]="yesterday" placeholder="mm-dd-yyyy" name="dp"
                formControlName="date" #d="ngbDatepicker" readonly ngbDatepicker>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><fa-icon
                    [icon]="faCalendar"
                    [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)'}"></fa-icon></button>
              </div>
            </div>
          </div>
          <div>
            <label class=" col-form-label">Time Zone <small>*</small></label>
            <div class="">
              <select class="form-select " (change)="selectTimeZone($event)" aria-label="Default select example">
                <option selected>Open this select menu</option>
                <option *ngFor="let item of timeZones" [value]=item.display>{{item.display}}</option>
              </select>
            </div>
          </div>
          <div class="my-3">
            <label for="date">Start Time<small>*</small></label>
            <div class="input-group">
              <input class="form-control" type="time" id="st"  name="st" formControlName="startTime" placeholder="00:00 AM"> 
              <!-- <ngb-timepicker [meridian]="true" [minuteStep]="minuteStep" formControlName="startTime"></ngb-timepicker> -->
            </div>
          </div>
          <div>
            <label for="date">End Time<small>*</small></label>
            <div class="input-group">
              <input class="form-control" type="time" id="et" name="et"  formControlName="endTime" placeholder="00:00 AM"> 
              <!-- <ngb-timepicker [meridian]="true" [minuteStep]="minuteStep" formControlName="endTime"></ngb-timepicker> -->

            </div>
          </div>
          <small *ngIf="dateError">Please enter proper Start and End time</small>
        </div>
        <div class="col-sm-4 " [formGroup]="emailForm" *ngIf="option2">
          <div>
            <label class=" col-form-label">Interview Duration <small>*</small></label>
            <div class="">
              <!-- formControlName="duration" -->
              <select class="form-select " (change)="selectInterviewDuration($event)"
                aria-label="Default select example">
                <option selected>Open this select menu</option>
                <option value="30">30 mins</option>
                <option value="60">60 mins</option>
              </select>
            </div>
          </div>
          <div class="my-3">
            <label class=" col-form-label">Any available time in the next<small>*</small></label>
            <div class="">
              <!-- formControlName="next_days" -->
              <select class="form-select " (change)="onAvailableTime($event)" aria-label="Default select example">
                <option selected>Open this select menu</option>
                <option value="3">3 days</option>
                <option value="5">5 days</option>
                <option value="10">10 days</option>
                <option value="15">15 days</option>
                <option value="20">20 days</option>
                <option value="25">25 days</option>
                <option value="30">30 days</option>
              </select>
            </div>
          </div>
          <div>
            <label class=" col-form-label">Time Zone <small>*</small></label>
            <div class="">
              <select class="form-select " (change)="selectTimeZone($event)" aria-label="Default select example">
                <option selected>Open this select menu</option>
                <option *ngFor="let item of timeZones" [value]=item.display>{{item.display}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-sm-6">
          <label>Select One Candidate</label>
          <div class="htms-box p-3">
            <div *ngFor="let mem of candidateList;let i = index;" (click)="selectCandForInterview(mem)"
              class="htmss text-center mr-2 mb-2"
              [ngClass]="{'selected-htms': mem.candidate_id==candForInterview.candidate_id}">
              <img [src]="mem.profile_photo || './../../../assets/images/big-default-avatar.png'" class="htm-photo">
              <p class="my-0 htm-name">{{mem.name}}</p>
            </div>
          </div>
          <!-- <div style="height: 125px;">
                      <div class="htmss selected-htms text-center mr-2">
                          <img [src]="'candidateData.profile_photo'" class="htm-photo">
                          <p class="htm-name my-0">{{'candidateData.full_name'}}</p>
                          <p class="my-0" style="color:var(--primary);">Candidate</p>
                      </div>
                  </div> -->
        </div>
        <div class="col-sm-6" [formGroup]="emailForm">
          <div>
            <label for="subject">Interview Subject<small>*</small></label>
            <div class="input-group">
              <input type="text" class="form-control" formControlName="subject">
            </div>
          </div>
          <div class="my-3">
            <label for="cc">Additional Recipients Email</label>
            <div class="input-group">
              <input type="text" class="form-control" formControlName="ccEmails">
            </div>
          </div>
          <div *ngIf="option1">
            <ng-container
              *ngIf="!emailForm.controls['date'].invalid && !emailForm.controls['startTime'].invalid && !emailForm.controls['endTime'].invalid && !emailForm.controls['timezone'].invalid && !emailForm.controls['candidate'].invalid">
              <label for="type_interview">Type of Interview<small>*</small></label>
              <div class="form-group row">
                <div class="btn-group col-sm-8" role="group" aria-label="Radio Buttons with Bootstrap 5">
                  <input (change)="toggleZohoInterview('zoho')" type="radio" formControlName="interviewType"
                    [value]="'zoho'" class="btn-check" id="btnradio1" autocomplete="off">
                  <label class="btn btn-outline-primary" for="btnradio1">QVideo</label>

                  <input (change)="toggleZohoInterview('in-person')" type="radio" formControlName="interviewType"
                    [value]="'in-person'" class="btn-check" id="btnradio2" autocomplete="off">
                  <label class="btn btn-outline-primary" for="btnradio2">In-Person</label>

                  <input (change)="toggleZohoInterview('phone')" type="radio" formControlName="interviewType"
                    [value]="'phone'" class="btn-check" id="btnradio3" autocomplete="off">
                  <label class="btn btn-outline-primary" for="btnradio3">Phone</label><br>

                </div>
                <div style="margin-left: 18.5%;"
                  *ngIf="emailForm.controls['interviewType'].invalid && (emailForm.controls['interviewType'].dirty || emailForm.controls['interviewType'].touched)  "
                  class="alert">
                  <div *ngIf="emailForm.controls['interviewType'].errors?.required">
                    Please Select Interview type
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row pb-4">
        <div class="col-sm-12" [formGroup]="emailForm">
          <label for="subject">Email Body<small>*</small></label>
          <div class="input-group">
            <textarea rows="3" class="form-control" formControlName="mailBody"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <p class="text-muted" style="flex: auto;">
      <!-- <fa-icon class="mr-2" [icon]="faCarrot"
              [styles]="{'stroke': '#FF9933', 'color': '#FF9933', 'font-size': '25px'}"></fa-icon>
          Earn 5 Reward points for finishing interview before target date. -->
    </p>
    <div class="modal-footer">
      <button type="button" class="btn cancel-button" (click)="modal.dismiss('Cross click')">
        Discard and Close
      </button>

      <button *ngIf="!isViewSchedule" type="button" [disabled]="emailForm.invalid || dateError" class="btn save-button"
        (click)="sendEmail(successMsg)">
        Send Interview Invite
      </button>
    </div>
  </div>
</ng-template>