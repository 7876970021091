import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ng-modal-lib';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { HeaderComponent } from 'src/app/core/header/header.component';
import { NavigatorComponent } from 'src/app/core/navigator/navigator.component';
import { SignupStepperComponent } from 'src/app/core/signup-stepper/signup-stepper.component';
import { ToastsContainer } from 'src/app/core/toast/toast-container';
import { SafePipe } from 'src/app/pipes/safe.pipe';

const modules = [
  CommonModule,
  RouterModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
  NgbModule,
  FontAwesomeModule,
  ModalModule,
  UiSwitchModule,
  NgxSliderModule
];

const components = [
  ToastsContainer,
  NavigatorComponent,
  SafePipe,
  HeaderComponent,
  SignupStepperComponent,
];

@NgModule({
  declarations: components,
  imports: modules,
  exports: [...modules, ...components]
})
export class AppCommonModule { }
