export const timeZone:any=[
    
        {
            "display": "(GMT-12:00) International Date Line West",
            "value": "Etc/GMT+12",
            "offset": 720
        },
        {
            "display": "(GMT-11:00) American Samoa",
            "value": "Pacific/Pago_Pago",
            "offset": 660
        },
        {
            "display": "(GMT-11:00) Midway Island",
            "value": "Pacific/Midway",
            "offset": 660
        },
        {
            "display": "(GMT-10:00) Hawaii",
            "value": "Pacific/Honolulu",
            "offset": 600
        },
        {
            "display": "(GMT-09:00) Alaska",
            "value": "America/Juneau",
            "offset": 480
        },
        {
            "display": "(GMT-08:00) Pacific Time (US \u0026 Canada)",
            "value": "America/Los_Angeles",
            "offset": 420
        },
        {
            "display": "(GMT-08:00) Tijuana",
            "value": "America/Tijuana",
            "offset": 420
        },
        {
            "display": "(GMT-07:00) Arizona",
            "value": "America/Phoenix",
            "offset": 420
        },
        {
            "display": "(GMT-07:00) Mazatlan",
            "value": "America/Mazatlan",
            "offset": 420
        },
        {
            "display": "(GMT-07:00) Mountain Time (US \u0026 Canada)",
            "value": "America/Denver",
            "offset": 360
        },
        {
            "display": "(GMT-06:00) Central America",
            "value": "America/Guatemala",
            "offset": 360
        },
        {
            "display": "(GMT-06:00) Central Time (US \u0026 Canada)",
            "value": "America/Chicago",
            "offset": 300
        },
        {
            "display": "(GMT-06:00) Chihuahua",
            "value": "America/Chihuahua",
            "offset": 360
        },
        {
            "display": "(GMT-06:00) Guadalajara",
            "value": "America/Mexico_City",
            "offset": 360
        },
        {
            "display": "(GMT-06:00) Mexico City",
            "value": "America/Mexico_City",
            "offset": 360
        },
        {
            "display": "(GMT-06:00) Monterrey",
            "value": "America/Monterrey",
            "offset": 360
        },
        {
            "display": "(GMT-06:00) Saskatchewan",
            "value": "America/Regina",
            "offset": 360
        },
        {
            "display": "(GMT-05:00) Bogota",
            "value": "America/Bogota",
            "offset": 300
        },
        {
            "display": "(GMT-05:00) Eastern Time (US \u0026 Canada)",
            "value": "America/New_York",
            "offset": 240
        },
        {
            "display": "(GMT-05:00) Indiana (East)",
            "value": "America/Indiana/Indianapolis",
            "offset": 240
        },
        {
            "display": "(GMT-05:00) Lima",
            "value": "America/Lima",
            "offset": 300
        },
        {
            "display": "(GMT-05:00) Quito",
            "value": "America/Lima",
            "offset": 300
        },
        {
            "display": "(GMT-04:00) Atlantic Time (Canada)",
            "value": "America/Halifax",
            "offset": 180
        },
        {
            "display": "(GMT-04:00) Caracas",
            "value": "America/Caracas",
            "offset": 240
        },
        {
            "display": "(GMT-04:00) Georgetown",
            "value": "America/Guyana",
            "offset": 240
        },
        {
            "display": "(GMT-04:00) La Paz",
            "value": "America/La_Paz",
            "offset": 240
        },
        {
            "display": "(GMT-04:00) Puerto Rico",
            "value": "America/Puerto_Rico",
            "offset": 240
        },
        {
            "display": "(GMT-04:00) Santiago",
            "value": "America/Santiago",
            "offset": 240
        },
        {
            "display": "(GMT-03:30) Newfoundland",
            "value": "America/St_Johns",
            "offset": 150
        },
        {
            "display": "(GMT-03:00) Brasilia",
            "value": "America/Sao_Paulo",
            "offset": 180
        },
        {
            "display": "(GMT-03:00) Buenos Aires",
            "value": "America/Argentina/Buenos_Aires",
            "offset": 180
        },
        {
            "display": "(GMT-03:00) Montevideo",
            "value": "America/Montevideo",
            "offset": 180
        },
        {
            "display": "(GMT-02:00) Greenland",
            "value": "America/Godthab",
            "offset": 60
        },
        {
            "display": "(GMT-02:00) Mid-Atlantic",
            "value": "Atlantic/South_Georgia",
            "offset": 120
        },
        {
            "display": "(GMT-01:00) Azores",
            "value": "Atlantic/Azores",
            "offset": 0
        },
        {
            "display": "(GMT-01:00) Cape Verde Is.",
            "value": "Atlantic/Cape_Verde",
            "offset": 60
        },
        {
            "display": "(GMT+00:00) Edinburgh",
            "value": "Europe/London",
            "offset": -60
        },
        {
            "display": "(GMT+00:00) Lisbon",
            "value": "Europe/Lisbon",
            "offset": -60
        },
        {
            "display": "(GMT+00:00) London",
            "value": "Europe/London",
            "offset": -60
        },
        {
            "display": "(GMT+00:00) Monrovia",
            "value": "Africa/Monrovia",
            "offset": 0
        },
        {
            "display": "(GMT+00:00) UTC",
            "value": "Etc/UTC",
            "offset": 0
        },
        {
            "display": "(GMT+01:00) Amsterdam",
            "value": "Europe/Amsterdam",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Belgrade",
            "value": "Europe/Belgrade",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Berlin",
            "value": "Europe/Berlin",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Bern",
            "value": "Europe/Zurich",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Bratislava",
            "value": "Europe/Bratislava",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Brussels",
            "value": "Europe/Brussels",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Budapest",
            "value": "Europe/Budapest",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Casablanca",
            "value": "Africa/Casablanca",
            "offset": -60
        },
        {
            "display": "(GMT+01:00) Copenhagen",
            "value": "Europe/Copenhagen",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Dublin",
            "value": "Europe/Dublin",
            "offset": -60
        },
        {
            "display": "(GMT+01:00) Ljubljana",
            "value": "Europe/Ljubljana",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Madrid",
            "value": "Europe/Madrid",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Paris",
            "value": "Europe/Paris",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Prague",
            "value": "Europe/Prague",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Rome",
            "value": "Europe/Rome",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Sarajevo",
            "value": "Europe/Sarajevo",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Skopje",
            "value": "Europe/Skopje",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Stockholm",
            "value": "Europe/Stockholm",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Vienna",
            "value": "Europe/Vienna",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Warsaw",
            "value": "Europe/Warsaw",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) West Central Africa",
            "value": "Africa/Algiers",
            "offset": -60
        },
        {
            "display": "(GMT+01:00) Zagreb",
            "value": "Europe/Zagreb",
            "offset": -120
        },
        {
            "display": "(GMT+01:00) Zurich",
            "value": "Europe/Zurich",
            "offset": -120
        },
        {
            "display": "(GMT+02:00) Athens",
            "value": "Europe/Athens",
            "offset": -180
        },
        {
            "display": "(GMT+02:00) Bucharest",
            "value": "Europe/Bucharest",
            "offset": -180
        },
        {
            "display": "(GMT+02:00) Cairo",
            "value": "Africa/Cairo",
            "offset": -120
        },
        {
            "display": "(GMT+02:00) Harare",
            "value": "Africa/Harare",
            "offset": -120
        },
        {
            "display": "(GMT+02:00) Helsinki",
            "value": "Europe/Helsinki",
            "offset": -180
        },
        {
            "display": "(GMT+02:00) Jerusalem",
            "value": "Asia/Jerusalem",
            "offset": -180
        },
        {
            "display": "(GMT+02:00) Kaliningrad",
            "value": "Europe/Kaliningrad",
            "offset": -120
        },
        {
            "display": "(GMT+02:00) Kyiv",
            "value": "Europe/Kiev",
            "offset": -180
        },
        {
            "display": "(GMT+02:00) Pretoria",
            "value": "Africa/Johannesburg",
            "offset": -120
        },
        {
            "display": "(GMT+02:00) Riga",
            "value": "Europe/Riga",
            "offset": -180
        },
        {
            "display": "(GMT+02:00) Sofia",
            "value": "Europe/Sofia",
            "offset": -180
        },
        {
            "display": "(GMT+02:00) Tallinn",
            "value": "Europe/Tallinn",
            "offset": -180
        },
        {
            "display": "(GMT+02:00) Vilnius",
            "value": "Europe/Vilnius",
            "offset": -180
        },
        {
            "display": "(GMT+03:00) Baghdad",
            "value": "Asia/Baghdad",
            "offset": -180
        },
        {
            "display": "(GMT+03:00) Istanbul",
            "value": "Europe/Istanbul",
            "offset": -180
        },
        {
            "display": "(GMT+03:00) Kuwait",
            "value": "Asia/Kuwait",
            "offset": -180
        },
        {
            "display": "(GMT+03:00) Minsk",
            "value": "Europe/Minsk",
            "offset": -180
        },
        {
            "display": "(GMT+03:00) Moscow",
            "value": "Europe/Moscow",
            "offset": -180
        },
        {
            "display": "(GMT+03:00) Nairobi",
            "value": "Africa/Nairobi",
            "offset": -180
        },
        {
            "display": "(GMT+03:00) Riyadh",
            "value": "Asia/Riyadh",
            "offset": -180
        },
        {
            "display": "(GMT+03:00) St. Petersburg",
            "value": "Europe/Moscow",
            "offset": -180
        },
        {
            "display": "(GMT+03:00) Volgograd",
            "value": "Europe/Volgograd",
            "offset": -180
        },
        {
            "display": "(GMT+03:30) Tehran",
            "value": "Asia/Tehran",
            "offset": -210
        },
        {
            "display": "(GMT+04:00) Abu Dhabi",
            "value": "Asia/Muscat",
            "offset": -240
        },
        {
            "display": "(GMT+04:00) Baku",
            "value": "Asia/Baku",
            "offset": -240
        },
        {
            "display": "(GMT+04:00) Muscat",
            "value": "Asia/Muscat",
            "offset": -240
        },
        {
            "display": "(GMT+04:00) Samara",
            "value": "Europe/Samara",
            "offset": -240
        },
        {
            "display": "(GMT+04:00) Tbilisi",
            "value": "Asia/Tbilisi",
            "offset": -240
        },
        {
            "display": "(GMT+04:00) Yerevan",
            "value": "Asia/Yerevan",
            "offset": -240
        },
        {
            "display": "(GMT+04:30) Kabul",
            "value": "Asia/Kabul",
            "offset": -270
        },
        {
            "display": "(GMT+05:00) Almaty",
            "value": "Asia/Almaty",
            "offset": -300
        },
        {
            "display": "(GMT+05:00) Ekaterinburg",
            "value": "Asia/Yekaterinburg",
            "offset": -300
        },
        {
            "display": "(GMT+05:00) Islamabad",
            "value": "Asia/Karachi",
            "offset": -300
        },
        {
            "display": "(GMT+05:00) Karachi",
            "value": "Asia/Karachi",
            "offset": -300
        },
        {
            "display": "(GMT+05:00) Tashkent",
            "value": "Asia/Tashkent",
            "offset": -300
        },
        {
            "display": "(GMT+05:30) Chennai",
            "value": "Asia/Kolkata",
            "offset": -330
        },
        {
            "display": "(GMT+05:30) Kolkata",
            "value": "Asia/Kolkata",
            "offset": -330
        },
        {
            "display": "(GMT+05:30) Mumbai",
            "value": "Asia/Kolkata",
            "offset": -330
        },
        {
            "display": "(GMT+05:30) New Delhi",
            "value": "Asia/Kolkata",
            "offset": -330
        },
        {
            "display": "(GMT+05:30) Sri Jayawardenepura",
            "value": "Asia/Colombo",
            "offset": -330
        },
        {
            "display": "(GMT+05:45) Kathmandu",
            "value": "Asia/Kathmandu",
            "offset": -345
        },
        {
            "display": "(GMT+06:00) Astana",
            "value": "Asia/Dhaka",
            "offset": -360
        },
        {
            "display": "(GMT+06:00) Dhaka",
            "value": "Asia/Dhaka",
            "offset": -360
        },
        {
            "display": "(GMT+06:00) Urumqi",
            "value": "Asia/Urumqi",
            "offset": -360
        },
        {
            "display": "(GMT+06:30) Rangoon",
            "value": "Asia/Rangoon",
            "offset": -390
        },
        {
            "display": "(GMT+07:00) Bangkok",
            "value": "Asia/Bangkok",
            "offset": -420
        },
        {
            "display": "(GMT+07:00) Hanoi",
            "value": "Asia/Bangkok",
            "offset": -420
        },
        {
            "display": "(GMT+07:00) Jakarta",
            "value": "Asia/Jakarta",
            "offset": -420
        },
        {
            "display": "(GMT+07:00) Krasnoyarsk",
            "value": "Asia/Krasnoyarsk",
            "offset": -420
        },
        {
            "display": "(GMT+07:00) Novosibirsk",
            "value": "Asia/Novosibirsk",
            "offset": -420
        },
        {
            "display": "(GMT+08:00) Beijing",
            "value": "Asia/Shanghai",
            "offset": -480
        },
        {
            "display": "(GMT+08:00) Chongqing",
            "value": "Asia/Chongqing",
            "offset": -480
        },
        {
            "display": "(GMT+08:00) Hong Kong",
            "value": "Asia/Hong_Kong",
            "offset": -480
        },
        {
            "display": "(GMT+08:00) Irkutsk",
            "value": "Asia/Irkutsk",
            "offset": -480
        },
        {
            "display": "(GMT+08:00) Kuala Lumpur",
            "value": "Asia/Kuala_Lumpur",
            "offset": -480
        },
        {
            "display": "(GMT+08:00) Perth",
            "value": "Australia/Perth",
            "offset": -480
        },
        {
            "display": "(GMT+08:00) Singapore",
            "value": "Asia/Singapore",
            "offset": -480
        },
        {
            "display": "(GMT+08:00) Taipei",
            "value": "Asia/Taipei",
            "offset": -480
        },
        {
            "display": "(GMT+08:00) Ulaanbaatar",
            "value": "Asia/Ulaanbaatar",
            "offset": -480
        },
        {
            "display": "(GMT+09:00) Osaka",
            "value": "Asia/Tokyo",
            "offset": -540
        },
        {
            "display": "(GMT+09:00) Sapporo",
            "value": "Asia/Tokyo",
            "offset": -540
        },
        {
            "display": "(GMT+09:00) Seoul",
            "value": "Asia/Seoul",
            "offset": -540
        },
        {
            "display": "(GMT+09:00) Tokyo",
            "value": "Asia/Tokyo",
            "offset": -540
        },
        {
            "display": "(GMT+09:00) Yakutsk",
            "value": "Asia/Yakutsk",
            "offset": -540
        },
        {
            "display": "(GMT+09:30) Adelaide",
            "value": "Australia/Adelaide",
            "offset": -570
        },
        {
            "display": "(GMT+09:30) Darwin",
            "value": "Australia/Darwin",
            "offset": -570
        },
        {
            "display": "(GMT+10:00) Brisbane",
            "value": "Australia/Brisbane",
            "offset": -600
        },
        {
            "display": "(GMT+10:00) Canberra",
            "value": "Australia/Melbourne",
            "offset": -600
        },
        {
            "display": "(GMT+10:00) Guam",
            "value": "Pacific/Guam",
            "offset": -600
        },
        {
            "display": "(GMT+10:00) Hobart",
            "value": "Australia/Hobart",
            "offset": -600
        },
        {
            "display": "(GMT+10:00) Melbourne",
            "value": "Australia/Melbourne",
            "offset": -600
        },
        {
            "display": "(GMT+10:00) Port Moresby",
            "value": "Pacific/Port_Moresby",
            "offset": -600
        },
        {
            "display": "(GMT+10:00) Sydney",
            "value": "Australia/Sydney",
            "offset": -600
        },
        {
            "display": "(GMT+10:00) Vladivostok",
            "value": "Asia/Vladivostok",
            "offset": -600
        },
        {
            "display": "(GMT+11:00) Magadan",
            "value": "Asia/Magadan",
            "offset": -660
        },
        {
            "display": "(GMT+11:00) New Caledonia",
            "value": "Pacific/Noumea",
            "offset": -660
        },
        {
            "display": "(GMT+11:00) Solomon Is.",
            "value": "Pacific/Guadalcanal",
            "offset": -660
        },
        {
            "display": "(GMT+11:00) Srednekolymsk",
            "value": "Asia/Srednekolymsk",
            "offset": -660
        },
        {
            "display": "(GMT+12:00) Auckland",
            "value": "Pacific/Auckland",
            "offset": -720
        },
        {
            "display": "(GMT+12:00) Fiji",
            "value": "Pacific/Fiji",
            "offset": -720
        },
        {
            "display": "(GMT+12:00) Kamchatka",
            "value": "Asia/Kamchatka",
            "offset": -720
        },
        {
            "display": "(GMT+12:00) Marshall Is.",
            "value": "Pacific/Majuro",
            "offset": -720
        },
        {
            "display": "(GMT+12:00) Wellington",
            "value": "Pacific/Auckland",
            "offset": -720
        },
        {
            "display": "(GMT+12:45) Chatham Is.",
            "value": "Pacific/Chatham",
            "offset": -765
        },
        {
            "display": "(GMT+13:00) Nuku'alofa",
            "value": "Pacific/Tongatapu",
            "offset": -780
        },
        {
            "display": "(GMT+13:00) Samoa",
            "value": "Pacific/Apia",
            "offset": -780
        },
        {
            "display": "(GMT+13:00) Tokelau Is.",
            "value": "Pacific/Fakaofo",
            "offset": -780
        }
   
    ]