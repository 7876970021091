<app-navbar></app-navbar>
<main id="main-content">
    <app-header></app-header>
    <div class="widget-wrapper container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="widget-col">
                    <div class="widget-box h-100">
                        <form class="widget-innerbox" [formGroup]="userForm">
                            <div class="form-section">
                                <h2 class="widget-title" *ngIf="!isEditUser">Create New User</h2>
                                <h2 class="widget-title" *ngIf="isEditUser">Edit User</h2>
                                <div class="form-group">
                                    <label class="col-sm-3 col-form-label">Roles<small>*</small></label>
                                    <div class="form-check form-check-inline">
                                        <input [disabled]="userRole !== 'Superuser' || isEditUser"
                                            class="form-check-input" type="checkbox" id="inlineCheckbox1" value="is_ca"
                                            [checked]="is_ca" (change)="onRoleChange($event, 'is_ca')">
                                        <label class="form-check-label" for="inlineCheckbox1">Client Admin</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input id="users-htm" class="form-check-input" type="checkbox" value="is_htm"
                                            [checked]="is_htm" (change)="onRoleChange($event, 'is_htm')">
                                        <label class="form-check-label" for="users-htm">Hiring Team Member</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input id="users-sm"
                                            [disabled]="userRole !== 'Superuser' && userRole !== 'Client Admin'"
                                            class="form-check-input" type="checkbox" value="is_sm" [checked]="is_sm"
                                            (change)="onRoleChange($event, 'is_sm')">
                                        <label class="form-check-label" for="users-sm">Senior Manager</label>
                                    </div>
                                </div>
                                <fieldset class="scheduler-border"
                                    [hidden]="userRole === 'Client Admin' || userRole === 'Senior Manager' || (isEditUser && is_ca)">
                                    <legend class="pt-4">Client Details</legend>
                                    <div *ngIf="!is_ca && userRole != 'Hiring Manager'" class="form-group row">
                                        <label class="col-sm-3 col-form-label">Select Client<small
                                                *ngIf="!isNewClient">*</small></label>
                                        <div class="col-sm-9">
                                            <select [attr.disabled]="isNewClient ? '': null" class="form-control"
                                                formControlName="client_id">
                                                <option value="">Please select</option>
                                                <option *ngFor="let item of clientList" [value]="item.id">
                                                    {{item.company_name}}</option>
                                            </select>
                                            <div *ngIf="!isNewClient && (userForm.controls['client_id'].invalid && (userForm.controls['client_id'].dirty || userForm.controls['client_id'].touched)) || formHasErrors"
                                                class="alert">
                                                <div *ngIf="userForm.controls['client_id'].errors?.required">
                                                    This field is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="userRole === 'Hiring Manager'" class="form-group row">
                                        <label class="col-sm-3 col-form-label">Select Client<small
                                                *ngIf="!isNewClient">*</small></label>
                                        <div class="col-sm-9">
                                            <input [attr.disabled]="!isNewClient ? '': null" type="text"
                                                class="form-control" [(ngModel)]="clientList[0].company_name"
                                                formControlName="client_id">
                                            <!-- <select [attr.disabled]="isNewClient ? '': null" class="form-control" formControlName="client_id">
                                                <option value="">Please select</option>
                                                <option *ngFor="let item of clientList" [value]="item.id">{{item.company_name}}</option>
                                            </select> -->
                                            <div *ngIf="!isNewClient && (userForm.controls['client_id'].invalid && (userForm.controls['client_id'].dirty || userForm.controls['client_id'].touched))  || formHasErrors"
                                                class="alert">
                                                <div *ngIf="userForm.controls['client_id'].errors?.required">
                                                    This field is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="text-center" [hidden]="isNewClient && is_ca && !isEditUser"
                                        *ngIf="userRole !== 'Client Admin' && userRole !== 'Senior Manager' && userRole !== 'Hiring Manager' && !is_sm && !is_htm">
                                        <u>Or</u></p>
                                    <div [hidden]="isNewClient && is_ca && !isEditUser" class="form-group text-center"
                                        *ngIf="userRole !== 'Client Admin' && userRole !== 'Senior Manager' && userRole !== 'Hiring Manager' && !is_sm && !is_htm">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="createClient"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="isNewClient"
                                                (change)="onCreateClient($event)">
                                            <label class="form-check-label" for="createClient">Create a client</label>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="isNewClient">
                                        <div class="form-group row w-100">
                                            <label class="col-sm-3 col-form-label">Company Name<small
                                                    *ngIf="isNewClient">*</small></label>
                                            <div class="col-sm-9">
                                                <input [attr.disabled]="!isNewClient ? '': null" type="text"
                                                    class="form-control" formControlName="company_name">
                                                <div *ngIf="isNewClient && (userForm.controls['company_name'].invalid && (userForm.controls['company_name'].dirty || userForm.controls['company_name'].touched)) || formHasErrors"
                                                    class="alert">
                                                    <div *ngIf="userForm.controls['company_name'].errors?.required">
                                                        Please enter company name
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row w-100">
                                            <label class="col-sm-3 col-form-label">Company Website</label>
                                            <div class="col-sm-9">
                                                <input [attr.disabled]="!isNewClient ? '': null" type="text"
                                                    class="form-control" formControlName="company_website">
                                                <div *ngIf="userForm.controls['company_website'].errors?.pattern"
                                                    class="alert">
                                                    Please enter valid url.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row w-100">
                                            <label class="col-sm-3 col-form-label">Company Linkedin</label>
                                            <div class="col-sm-9">
                                                <input [attr.disabled]="!isNewClient ? '': null" type="text"
                                                    class="form-control" formControlName="company_linkedin">
                                                <div *ngIf="userForm.controls['company_linkedin'].errors?.pattern"
                                                    class="alert">
                                                    Please enter valid LinkedIn url.
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </fieldset>
                                <fieldset class="scheduler-border">
                                    <legend class="pt-4">Personal Details</legend>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">First Name<small>*</small></label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="first_name"
                                                name="someUnusualName" autocomplete="off">
                                            <div *ngIf="(userForm.controls['first_name'].invalid && (userForm.controls['first_name'].dirty || userForm.controls['first_name'].touched)) || formHasErrors"
                                                class="alert">
                                                <div *ngIf="userForm.controls['first_name'].errors?.required">
                                                    Please enter first name
                                                </div>
                                                <div *ngIf="userForm.controls['first_name'].errors?.pattern">
                                                    Please enter valid name, only alphabets are allowed.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Last Name<small>*</small></label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="last_name">
                                            <div *ngIf="(userForm.controls['last_name'].invalid && (userForm.controls['last_name'].dirty || userForm.controls['last_name'].touched)) || formHasErrors"
                                                class="alert">
                                                <div *ngIf="userForm.controls['last_name'].errors?.required">
                                                    Please enter last name
                                                </div>
                                                <!-- <div *ngIf="userForm.controls['last_name'].errors?.pattern">
                                                    Please enter valid name, only alphabets are allowed.
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Phone<small>*</small></label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="phone_number">
                                            <div *ngIf="userForm.controls['phone_number'].invalid && (userForm.controls['phone_number'].dirty || userForm.controls['phone_number'].touched) || formHasErrors"
                                                class="alert">
                                                <div *ngIf="userForm.controls['phone_number'].errors?.required">
                                                    Please enter phone
                                                </div>
                                                <div *ngIf="userForm.controls['phone_number'].errors?.pattern">
                                                    Phone number must be exactly 10 digit & only number is allowed.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">LinkedIn Address</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="skype_id">
                                            <!-- <div *ngIf="userForm.controls['skype_id'].invalid && (userForm.controls['skype_id'].dirty || userForm.controls['skype_id'].touched)" class="alert">
                                                <div *ngIf="userForm.controls['skype_id'].errors?.required">
                                                    Please enter LinkedIn address
                                                </div>
                                            </div> -->
                                            <div *ngIf="userForm.controls['skype_id'].errors?.pattern" class="alert">
                                                Please enter valid LinkedIn url.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Email<small>*</small></label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="email">
                                            <div *ngIf="userForm.controls['email'].invalid && (userForm.controls['email'].dirty || userForm.controls['email'].touched) || formHasErrors"
                                                class="alert">
                                                <div *ngIf="userForm.controls['email'].errors?.required">
                                                    Please enter email
                                                </div>
                                                <div *ngIf="userForm.controls['email'].errors?.pattern">
                                                    Invalid Email, please try again.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Job Title</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control" formControlName="job_title">
                                            <div *ngIf="userForm.controls['job_title'].invalid && (userForm.controls['job_title'].dirty || userForm.controls['job_title'].touched) || formHasErrors"
                                                class="alert">
                                                <div *ngIf="userForm.controls['job_title'].errors?.required">
                                                    Please enter job title
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Username<small>*</small></label>
                                        <div class="col-sm-9">
                                            <input [readonly]="isEditUser" type="text" class="form-control"
                                                formControlName="username" autocomplete="new-username">
                                            <div *ngIf="userForm.controls['username'].invalid && (userForm.controls['username'].dirty || userForm.controls['username'].touched) || formHasErrors"
                                                class="alert">
                                                <div *ngIf="userForm.controls['username'].errors?.required">
                                                    Please enter username
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row" [hidden]="isEditUser">
                                        <label class="col-sm-3 col-form-label">Password<small>*</small></label>
                                        <div class="col-sm-9">
                                            <input type="{{ showPassword ? 'text' : 'password' }}"
                                                class="form-control input-password" autocomplete="password"
                                                formControlName="password" (input)="onPasswordChange()"
                                                [ngStyle]="{'color': userForm.controls.password.errors? 'red' : 'green'}">
                                            <button type="button" (click)="togglePasswordVisibility()"
                                                class="password-toggle">
                                                <i class="fas"
                                                    [ngClass]="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                            </button>
                                        </div>
                                        <div class="col-sm-9 offset-3">
                                            <div *ngIf="userForm.controls['password'].invalid && (userForm.controls['password'].dirty || userForm.controls['password'].touched) || formHasErrors"
                                                class="alert">
                                                <div
                                                    *ngIf="userForm.controls['password'].errors?.required && !userForm.controls['password'].value">
                                                    Please enter password
                                                </div>
                                            </div>
                                            <div *ngIf="userForm.controls['password']?.errors && userForm.controls['password'].value && !(userForm.controls['password']?.hasError('maxlength'))"
                                                class="password-valid-pts">
                                                Password must contain at least one of each of the following characters:
                                                <div [ngStyle]="{'color': isUppercaseValid ? 'green' : 'red'}">
                                                    <span>- Uppercase letters: A-Z.</span>
                                                </div>
                                                <div [ngStyle]="{'color': isLowecaseValid ? 'green' : 'red'}">
                                                    <span>- Lowercase letters: a-z.</span>
                                                </div>
                                                <div [ngStyle]="{'color': isNumberValid ? 'green' : 'red'}">
                                                    <span>- Numbers: 0-9.</span>
                                                </div>
                                                <div [ngStyle]="{'color': isSymbolValid ? 'green' : 'red'}">
                                                    <span>- Symbols: ~`! @#$%^&*()_-+={{'{'}}[{{'}'}}]|\:;"'<,>
                                                            .?/</span>
                                                </div>
                                            </div>
                                            <div *ngIf="(userForm.controls['password']?.hasError('minlength') || userForm.controls['password']?.hasError('maxlength')) && (userForm.controls['password'].dirty || userForm.controls['password'].touched)"
                                                class="alert">
                                                <span>Password should contain min 8 and max 16 characters</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row" *ngIf="!showProfilePhoto">
                                        <label class="col-sm-3 col-form-label">Upload profile picture</label>
                                        <div class="col-sm-9">
                                            <input type="file" style="width: 250px; float: left;"
                                                class="form-control-file" (change)="showCAPreview($event)">
                                            <input type="hidden" name="fileHidden" formControlName="profile_photo" />
                                            <!-- <div class="imagePreview" *ngIf="userProfilePic && userProfilePic !== '' && userProfilePic !== 'None'">
                                                <img [src]="userProfilePic" [alt]="userForm.value.name">
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="form-group row" *ngIf="showProfilePhoto">
                                        <label class="col-sm-3 col-form-label">Profile picture</label>
                                        <div class="col-sm-9">
                                            <!-- <input type="file" style="width: 250px; float: left;" class="form-control-file" (change)="showCAPreview($event)">
                                            <input type="hidden" name="fileHidden" formControlName="profile_photo" /> -->
                                            <div class="imageLogoPreview"
                                                *ngIf="userProfilePic && userProfilePic !== '' && userProfilePic !== 'None'">
                                                <span id="x" (click)="removePhoto()">
                                                    x
                                                </span>
                                                <img [src]="userProfilePic" [alt]="userForm.value.name">
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <button *ngIf="!isEditUser" id="create-users" class="btn btn-primary mb-2"
                                    style="float: right;" (click)="createUser()">Create</button>
                                <button *ngIf="isEditUser" id="update-users" class="btn btn-primary mb-2"
                                    style="float: right;" (click)="updateUser(accountLevel)">Update</button>
                                <button class="btn btn-secondary mb-2 mr-3" href="javascript:void(0)"
                                    style="float: right;"
                                    [routerLink]="currentUserRoleCode + '/dashboard/all-users'">Back</button>
                                <button *ngIf="isEditUser" type="button" (click)="openforPassword(password)"
                                    class="btn btn-primary f-left">Change Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>


<ng-template #password let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Change Password</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="passwordForm">
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">New Password <small>*</small></label>
                <div class="col-sm-8">
                    <input type="{{ showPassword ? 'text' : 'password' }}" class="form-control input-password"
                        autocomplete="new_password" formControlName="new_password" (input)="onPasswordChange()"
                        [ngStyle]="{'color': passwordForm.controls.new_password.errors? 'red' : 'green'}">
                    <button type="button" (click)="togglePasswordVisibility()" class="password-toggle">
                        <i class="fas" [ngClass]="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                    </button>
                </div>
                <!-- <div class="col-sm-12"> -->
                <div class="col-sm-8 offset-4">
                    <div *ngIf="passwordForm.controls['new_password'].invalid && (passwordForm.controls['new_password'].dirty || passwordForm.controls['new_password'].touched)"
                        class="alert">
                        <div
                            *ngIf="passwordForm.controls['new_password'].errors?.required && !passwordForm.controls['new_password'].value">
                            Please enter password
                        </div>
                    </div>
                    <div *ngIf="passwordForm.controls['new_password']?.errors && passwordForm.controls['new_password'].value && !(passwordForm.controls['new_password']?.hasError('maxlength'))"
                        class="password-valid-pts">
                        Password must contain at least one of each of the following characters:
                        <div [ngStyle]="{'color': isUppercaseValid ? 'green' : 'red'}">
                            <span>- Uppercase letters: A-Z.</span>
                        </div>
                        <div [ngStyle]="{'color': isLowecaseValid ? 'green' : 'red'}">
                            <span>- Lowercase letters: a-z.</span>
                        </div>
                        <div [ngStyle]="{'color': isNumberValid ? 'green' : 'red'}">
                            <span>- Numbers: 0-9.</span>
                        </div>
                        <div [ngStyle]="{'color': isSymbolValid ? 'green' : 'red'}">
                            <span>- Symbols: ~`! @#$%^&*()_-+={{'{'}}[{{'}'}}]|\:;"'<,>.?/</span>
                        </div>
                    </div>
                    <div *ngIf="(passwordForm.controls['new_password']?.hasError('minlength') || passwordForm.controls['new_password']?.hasError('maxlength')) && (passwordForm.controls['new_password'].dirty || passwordForm.controls['new_password'].touched)"
                        class="alert">
                        <span>Password should contain min 8 and max 16 characters</span>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Re-Password
                    <small>*</small>
                </label>
                <div class="col-sm-8">
                    <input type="password" class="form-control" formControlName="re_password" (blur)="checkPasswords()">
                    <div *ngIf="passwordForm.controls['re_password'].invalid && (passwordForm.controls['re_password'].dirty || passwordForm.controls['re_password'].touched)"
                        class="alert">
                        <div *ngIf="passwordForm.controls['re_password'].errors?.required">
                            Re-Password is required.
                        </div>
                    </div>
                    <label *ngIf="showError" class="alert">Password not matching, try again</label>
                </div>
            </div>
        </form>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="passwordForm.invalid || showError"
            (click)="changePassword()">Change Password</button>
    </div>
</ng-template>

<ng-template #accountLevel let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Warning</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>This user is currently an active member of one, or more, hiring teams. Changing their account level could
            result in the loss of important hiring data.
            Before confirming this change, please specify what you would like to happen to the hiring data associated
            with this team member for each role that they are assigned to.</p>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Team</th>
                    <th>Role</th>
                    <th>Active Open Positions</th>
                    <th>Data Retention</th>
                </tr>
            </thead>
            <tbody>
                <tr class="text-bold" *ngFor="let position of htmCurrentData.positions, let i= index">
                    <td *ngIf="i == 0" [attr.rowspan]="htmCurrentData.positions.length">{{ htmCurrentData.group_name}}
                    </td>
                    <td>{{ htmCurrentData.role}}</td>
                    <td>{{ position.position_name}}</td>
                    <td>
                        <div class="menu-block form-check form-switch">
                            <label class="pt-2"></label>
                            <input class="form-check-input" type="checkbox" [(ngModel)]="!position.active">
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <!-- <button type="button" class="btn btn-primary">Remove this account</button> -->
        <button type="button" class="btn btn-primary" (click)="removeAccount(); modal.dismiss('Cross click')">Remove
            this account</button>
    </div>
</ng-template>