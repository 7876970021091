<main id="landingpage">
    <app-header></app-header>
    <div class="widget-wrapper">
        <img src="./../../../assets/images/quorums-logo.png" class="logo" />
        <div class="container">
            <div class="row text-center justify-content-center">
                <div class="col-md-8">
                    <h3>Welcome to your Qorums account</h3>
                    <h3>Let's get started.</h3>
                    <div class="row mt-5">
                        <div class="form-label col-md-4 text-lt-sm-left">
                            <h6>First, enter your email:</h6>
                        </div>
                        <div class="col-md-8">
                            <input class="form-control" type="text" />
                            <h6>Already have an account? <a routerLink="/login">Login Here</a></h6>
                        </div>

                        <div class="form-label col-md-4">
                            <h6>Your first name:</h6>
                        </div>
                        <div class="col-md-8">
                            <input class="form-control" type="text" />
                        </div>

                        <div class="form-label col-md-4">
                            <h6>Your last name:</h6>
                        </div>
                        <div class="col-md-8">
                            <input class="form-control" type="text" />
                        </div>

                        <div class="form-label col-md-4">
                            <h6>Create a password:</h6>
                        </div>
                        <div class="col-md-8">
                            <!-- <input class="form-control" type="password" /> -->
                            <input type="{{ showPassword1 ? 'text' : 'password' }}"  class="form-control input-password">
                            <button type="button" (click)="togglePasswordVisibility1()" class="password-toggle">
                                <i class="fas"[ngClass]="{ 'fa-eye-slash': showPassword1, 'fa-eye': !showPassword1 }"></i>
                            </button>
                            <!-- <div [ngStyle]="{'color': isUppercaseValid ? 'green' : 'red'}">
                                <span>- Uppercase letters: A-Z.</span>
                            </div>
                            <div [ngStyle]="{'color': isLowecaseValid ? 'green' : 'red'}">
                                <span>- Lowercase letters: a-z.</span>
                            </div>
                            <div [ngStyle]="{'color': isNumberValid ? 'green' : 'red'}">
                                <span>- Numbers: 0-9.</span>
                            </div>
                            <div [ngStyle]="{'color': isSymbolValid ? 'green' : 'red'}">
                                <span>- Symbols: ~`! @#$%^&*()_-+={{'{'}}[{{'}'}}]|\:;"'<,>.?/</span>
                            </div> -->
                        </div>

                        <div class="form-label col-md-4">
                            <h6>Confirm your password:</h6>
                        </div>
                        <div class="col-md-8">
                            <!-- <input class="form-control" type="password" /> -->
                            <input type="{{ showPassword2 ? 'text' : 'password' }}" class="form-control input-password">
                            <button type="button" (click)="togglePasswordVisibility2()" class="password-toggle">
                                <i class="fas"[ngClass]="{ 'fa-eye-slash': showPassword2, 'fa-eye': !showPassword2 }"></i>
                            </button>
                            <div class="checkbox-wrapper">
                                <label class="checkbox-container">
                                    <input type="checkbox">
                                    <span class="check"></span>
                                    <h6 class="m-0 ml-4">&nbsp;&nbsp;I agree to the <a href="#">Terms of Service</a> and <a href="#">Privacy Policy.</a></h6>
                                </label>
                            </div>
                        </div>

                        <div class="offset-md-4 col-md-4 mt-3">
                            <button class="btn btn-primary" style="float: left;" (click)="next()">Finish</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <img src="./../../../assets/images/roles-final.png" class="fix-img"/>
            </div>
        </div>
    </div>
</main>