<div class="row text-center">
  <div class="col-sm-3 calendar-header my-3">
    <div class="btn-group">
      <div class="btn next-prev-button mr-3" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">
        <fa-icon [icon]="faLessThan"></fa-icon>
      </div>
      <div class="btn save-button  mr-3" mwlCalendarToday [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">
        Today
      </div>
      <div class="btn next-prev-button" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">
        <fa-icon [icon]="faGreaterThan"></fa-icon>
      </div>
    </div>
  </div>
  <div class="calendar-header col-sm-3  my-3">
    <h3 class="my-0">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
  </div>
  <div class="calendar-header col-sm-3  my-3">
    <div class="btn-group">
      <div class="btn  next-prev-button mr-3" (click)="setView(CalendarView.Month)"
        [class.active]="view === CalendarView.Month">
        Month
      </div>
      <div class="btn next-prev-button  mr-3" (click)="setView(CalendarView.Week)"
        [class.active]="view === CalendarView.Week">
        Week
      </div>
      <div class="btn next-prev-button" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
        Day
      </div>
    </div>
  </div>
  <div class="calendar-header col-sm-3  my-3">
    <div class=" ">
      <select class="form-select filter-list" aria-label="Default select example" (change)="onPositionSelect($event)">
        <option selected value="0">Filter by Open Position</option>
        <option value="all">My Calendar</option>
        <option *ngFor="let it of activePositionsForCand" [value]="it.op_id">{{it.position_name}}</option>
      </select>
    </div>
  </div>
</div>
<br />
<div id="candidateCalendar" [ngSwitch]="view">
  <mwl-calendar-month-view
    *ngSwitchCase="CalendarView.Month"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    [activeDayIsOpen]="activeDayIsOpen"
    (dayClicked)="dayClicked($event.day)"
    (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)"
  >
  </mwl-calendar-month-view>
  <mwl-calendar-week-view
    *ngSwitchCase="CalendarView.Week"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)"
  >
  </mwl-calendar-week-view>
  <mwl-calendar-day-view
    *ngSwitchCase="CalendarView.Day"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)"
  >
  </mwl-calendar-day-view>
</div>

<div class="row candidate-schedules mt-4" *ngIf="requestedInterviews?.length && selectedPositionId">
  <p class="text-bold">Requested Interviews: </p>
  <div class="candidate-schedule" *ngFor="let schedule of requestedInterviews;">
    <p class="text-bold">{{schedule.interviewer_name}}</p>
    <p>{{schedule.interview_date_time | date: 'MM/dd/yyyy h:mm a'}}</p>
    <ng-container *ngIf="schedule.interview_type === 'zoho'">
      <p>Video call <small>(Information will be provided via email, prior to the call)</small></p>
    </ng-container>
    <ng-container *ngIf="schedule.interview_type === 'in-person'">
      <p>In Person</p>
    </ng-container>
    <ng-container *ngIf="schedule.interview_type === 'phone'">
      <p>Phone</p>
    </ng-container>
    <div class="btn-group">
      <input
        mdbRadio
        type="radio"
        class="btn-response"
        name="options"
        id="option1"
        autocomplete="off"
        (click)="updateInterviewResponse(schedule, true)"
      />
      <label class="btn btn-secondary btn-green" for="option1">Yes</label>
      <input
        mdbRadio
        type="radio"
        class="btn-response"
        name="options"
        id="option2"
        autocomplete="off"
        (click)="updateInterviewResponse(schedule, false, slotContent)"
      />
      <label class="btn btn-secondary btn-red" for="option2">No</label>
    </div>
  </div>
</div>

<div class="row candidate-schedules mt-4" *ngIf="scheduledInterviews?.length && selectedPositionId">
  <p class="text-bold">Scheduled Interviews: </p>
  <ng-container *ngFor="let schedule of scheduledInterviews;">
    <div class="candidate-schedule" *ngIf="schedule.interview_type !== 'zoho'">
      <p class="text-bold">{{schedule.interviewer_name}}</p>
      <p>{{schedule.interview_date_time | date: 'MM/dd/yyyy h:mm a'}}</p>
      <ng-container *ngIf="schedule.interview_type === 'in-person'">
        <p><b>Type: </b>In Person</p>
      </ng-container>
      <ng-container *ngIf="schedule.interview_type === 'phone'">
        <p><b>Type: </b>Phone</p>
      </ng-container>
    </div>
    <div class="candidate-schedule" *ngIf="schedule.interview_type === 'zoho'">
      <a class="btn btn-primary my-3" href="javascript:void(0)" (click)="fetchZohoMeeting(schedule);modalRoot.show();">
        <fa-icon [icon]="faVideo"></fa-icon>
        <p class="m-0">{{schedule.interviewer_name}}</p>
        <p class="m-0">{{schedule.date}} - {{schedule.time}}</p>
    </a>
    </div>
  </ng-container>
</div>

<app-modal #modalRoot class="modal-demo" [backdrop]="false" [maximizable]="true">
  <ng-container class="app-modal-header">QVideo Meeting</ng-container>
  <ng-container class="app-modal-body">
      <iframe *ngIf="meetingUrl" allow="camera;microphone;fullscreen;autoplay" style="width: 100%;height: 600px;" [src]="meetingUrl | safe"></iframe>
  </ng-container>
</app-modal>

<ng-template #slotContent let-modal>
  <div class="modal-header">
      <h4 class="modal-title">Select available time for interview:</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <form>
          <div class="form-group row p-3">
            <ng-container *ngFor="let slot of availableSlots; let i = index">
              <div class="form-check" *ngFor="let dailySlot of slot.hours; let j = index">
                  <input
                    mdbCheckbox
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    [id]="'flexCheckDefault' + j"
                    (click)="toggleSlotSelection($event, slot)"
                  />
                  <label class="form-check-label" [for]="'flexCheckDefault' + j">
                    {{slot.date}} @ {{dailySlot?.startTime}} - {{dailySlot?.endTime}}
                  </label>
                </div>
              </ng-container>
              <p *ngIf="availableSlots?.length === 0">No available slots found</p>
          </div>
      </form>
  </div>
  <div class="modal-footer">
      <!-- <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button> -->
      <button type="button" class="btn btn-primary" [disabled]="selectedSlots.availableDays.length === 0" (click)="saveCandidateSchedule()">Save</button>
  </div>
</ng-template>
