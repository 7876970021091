import { Component, OnInit } from '@angular/core';
import { CandidateService } from 'src/app/services/candidate.service';
import { Router } from '@angular/router';
import * as Url from './../../constants/url';
import { PositionService } from 'src/app/services/position.service';

@Component({
  selector: 'app-position-summary',
  templateUrl: './position-summary.component.html',
  styleUrls: ['./position-summary.component.css']
})
export class PositionSummaryComponent implements OnInit {

  positionData:any
  hiringData:any;
  imageBaseUrl: any;
  positionID: string | null;
  constructor( private candidateService: CandidateService,private router: Router,
               private positionService: PositionService
  ) { }

  ngOnInit(): void {
    this.imageBaseUrl = Url.imgBaseUrl;
    this.positionID = localStorage.getItem('currentPosition')
    this.getPositionById(this.positionID)
   }
   getPositionById(id: any) {
    if (id) {
      this.positionService.getPositionById(id).subscribe((val: any) => {
        if (val) {
          this.positionData = val.data;
        }
      });
    }
  }

}
