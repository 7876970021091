import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
//  import { HostListener } from '@angular/core';


export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})

export class CanFormDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    //  @HostListener('window:beforeunload')
  canDeactivate(
    component: CanComponentDeactivate
  ): Observable<boolean> | Promise<boolean> | boolean 
  {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
