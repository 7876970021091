import { Component, Input, OnInit } from '@angular/core';
import { faBuilding, faUsers, faPaperclip, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import {  } from '@fortawesome/free-regular-svg-icons';
@Component({
  selector: 'app-signup-stepper',
  templateUrl: './signup-stepper.component.html',
  styleUrls: ['./signup-stepper.component.css']
})
export class SignupStepperComponent implements OnInit {
  faBuilding = faBuilding;
  faUsers = faUsers;
  faPaperclip = faPaperclip;
  faCheckSquare = faCheckSquare;
  @Input() stepName: any;
  constructor() { }

  ngOnInit(): void {
  }

}
