<main id="positions-content">
    <app-header style="z-index: 99;position: relative;  "></app-header>
    <div class="widget-wrapper">

        <app-detail-header (availibilityAction)="availAction($event)"></app-detail-header>

        <div class="page-body">
            <div class="widget-box">
                <div class="row " *ngIf="showOurCalendar">
                    <div class="col-sm-10 ">
                        <div >
                            <app-angular-calendar [calendarData]="calendarData" (HtmData)="htmDataFromCalendar($event)" (posDateData)="posDateDataFromCalendar($event)"></app-angular-calendar>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="select-interview" *ngIf="!showHtmInfo">
                            <h5>Select an Interview</h5>
                            <p class="fs-5 font-weight-normal">Click on any event in the calendar to view its details</p>
                        </div>
                        <div class="selected-htm-interview" *ngIf="showHtmInfo && posCalendarData">
                            <div class="fs-5" *ngIf="posCalendarData?.icon!=='htm-deadline-icon'">
                                Position: {{posCalendarData?.position}} <br>
                                {{posCalendarData?.event}}  Date: {{posCalendarData?.date}}
                            </div>
                            <div  *ngIf="posCalendarData?.icon=='htm-deadline-icon'">
                                <p class="text-center">
                                    <img src="{{posCalendarData?.photo?posCalendarData?.photo : './../../../assets/images/big-default-avatar.png'}}" class="photo">
                                </p>
                                <div class="fs-5 py-2">
                                    Name: {{posCalendarData?.htm_name | titlecase}}
                                    Position: {{posCalendarData?.position}}
                                    Deadline: {{posCalendarData?.date}}
                                </div>
                            </div>
                        </div>
                        <div class="selected-htm-interview" *ngIf="showHtmInfo && htmCalendarData" >
                            <div class="d-flex mb-4">
                                <fa-icon [icon]="faCamera"></fa-icon>
                                <div class="ml-3 fs-5">
                                    <p class="fs-5">Interview with <br><span class="font-weight-bold" style="color: #666666;">{{htmCalendarData.candidate}}</span></p>
                
                                    Date: {{htmCalendarData?.sep_date}}<br>
                                    Time: {{htmCalendarData?.sep_time}}
                                    Timezone: {{htmCalendarData?.long_timezone}}
                                    <br>
                                    <!-- [href]="htmCalendarData.link -->
                                    <a (click)="startInterview()" *ngIf="isDateToday(htmCalendarData.sep_date)"><button class="btn save-button">Start QVideo</button></a>
                                </div>
                            </div>
                            <!-- <div class="d-flex" *ngFor="let it of htmCalendarData.interviewer_names">
                                <img [src]="it.profile_pic" class="photo">
                                <div class="ml-3 fs-5 py-2">
                                    <span class="mr-3" style="color: #666666;">{{giveMeRole(it.role)}}</span>
                                    <span>{{it.name}}</span>
                                </div>
                            </div> -->
                           
                        </div>
                        <div  class="legends p-3 mt-4">
                            <div class="legend ">
                                <!-- \f251 -->
                              <fa-icon [icon]="faHourglassStart" style="color: black;"></fa-icon>
                              <span class="legend-name" >Kick-off Date</span>
                            </div>
                            <div class="legend ">
                                <fa-icon [icon]="faUserPlus" style="color: black;"></fa-icon>
                                <span class="legend-name">Sourcing Target Date</span>
                            </div>
                            <div class="legend ">
                                <fa-icon [icon]="faFlagCheckered" style="color: black;"></fa-icon>
                                <span class="legend-name">Target Completion Date</span>
                            </div>
                            <div class="legend ">
                              <fa-icon [icon]="faExclamationCircle" style="color: black;"></fa-icon>
                              <span class="legend-name">Deadlines</span>
                          </div>
                            <div class="legend ">
                                <fa-icon [icon]="faCalendar" style="color: Red;"></fa-icon>
                                <span class="legend-name">Interview Scheduled </span>
                            </div>
                            
                            <div class="legend " >
                                <fa-icon [icon]="faComments" style="color: black;"></fa-icon>
                                <span class="legend-name">Interviews Completed</span>
                            </div>
                          </div>
                          
                          <div class="mt-4 d-flex">
                            
                             <!-- <fa-icon class="mr-2" [icon]="faCarrot"  [styles]="{'stroke': '#FF9933', 'color': '#FF9933', 'font-size': '25px'}"></fa-icon>
                             <p><span style="color:#9C9C9C;font-size: 20px;">Earn 10 Reward Points when you link your work calendar. Click on Add Availability</span>
                            </p> -->
                          </div>
                    </div>
                </div>
                <span *ngIf="showAvailabilityCalendar">Add Account</span>
                <button *ngIf="showAvailabilityCalendar" class="btn btn-secondary ml-1  " (click)="addGoogleAccount()">Google</button>
                <button *ngIf="showAvailabilityCalendar" class="btn btn-secondary ml-1  " (click)="addAppleAccount()">Apple</button>
                <button *ngIf="showAvailabilityCalendar" class="btn btn-secondary  ml-1  " (click)="addOffice365Account()">Office 365</button>
                <button *ngIf="showAvailabilityCalendar" class="btn btn-secondary  ml-1  " (click)="addExchangeAccount()">Exchange</button>
                <button *ngIf="showAvailabilityCalendar" class="btn btn-secondary  ml-1  " (click)="addOutlookAccount()">Outlook.com</button>
                <br><br><br>
                <!-- <app-angular-calendar [selectedHtmArr]="selectedHtmArr" [selectedCan]="selectedCan" [selectedHtm]="selectedHtm" [hrGroupList]="hrGroupList"  [candidateList]="candidateList"></app-angular-calendar> -->
                <div *ngIf="showAvailabilityCalendar">
                    <div id="cronofy-availability-rules"></div>
                    <!-- <div id="cronofy-availability-viewer"></div> -->
                </div>
                <div *ngIf="showAvalibilityViewer">
                    <div id="cronofy-availability-viewer"></div>
                </div>
            </div>
        </div>
    </div>
</main>