import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpInterceptor, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject } from "rxjs";

import * as Url from '../constants/url';
import { getCandidateById, allcandidateUrl, getPositionByIdUrl, setAvailability } from '../constants/url';
import { UserService } from "./user.service";

@Injectable({ providedIn: 'root' })

export class CandidateService {
    public selectedCandidate$ = new BehaviorSubject<any>({});
    public candidateData$ = new BehaviorSubject<any>({});
    public selectedCompletedPosition$ = new BehaviorSubject<any>({});
    public allInterviewsByCandidate$ = new BehaviorSubject<any>({});
    public positionPageSection$ = new BehaviorSubject<string>('')
    constructor(
        private http: HttpClient,
        private userService: UserService
    ) { }
    setpositionPageSection(section:string){
        this.positionPageSection$.next(section)
    }
    getpositionPageSection(){
        return this.positionPageSection$.asObservable()
    }
    setSelectedCandidate(candidate: any) {
        this.selectedCandidate$.next(candidate);
    }

    getSelectedCandidate() {
        return this.selectedCandidate$.asObservable();
    }

    setCandidateData(candidate: any) {
        this.candidateData$.next(candidate);
    }

    getCandidateData() {
        return this.candidateData$.asObservable();
    }

    setSelectedCompletedPosition(positionData: any) {
        this.selectedCompletedPosition$.next(positionData);
    }

    getSelectedCompletedPosition() {
        return this.selectedCompletedPosition$.asObservable();
    }

    getHireCandidateData(op_id: any, candidate_id: any) {
        return this.http.get(Url.hireCandidate + '/' + op_id + '/' + candidate_id).pipe();
    }

    hireCandidate(op_id: any, candidate_id: any) {
        return this.http.post(Url.hireCandidate + '/' + op_id + '/' + candidate_id, {}).pipe();
    }

    fireCandidate(op_id: any, candidate_id: any) {
        return this.http.put(Url.hireCandidate + '/' + op_id + '/' + candidate_id, {}).pipe();
    }

    offerCandidate(op_id: any, candidate_id: any) {
        return this.http.post(Url.offerCandidate + '/' + op_id + '/' + candidate_id, {}).pipe();
    }

    getOfferCandidate(op_id: any, candidate_id: any) {
        return this.http.get(Url.offerCandidate + '/' + op_id + '/' + candidate_id).pipe();
    }

    WidthdrawOfferCandidate(op_id: any, candidate_id: any) {
        return this.http.put(Url.offerCandidate + '/' + op_id + '/' + candidate_id, {}).pipe();
    }
    getPositionsCandidates(id: any) {
        return this.http.get(Url.positionsCandidateUrl + '/' + id).pipe();
    }
    // getPosCandidates(id: any) {
    //     return this.http.get(Url.positionsCandidateUrl + '/' + id).pipe();
    // }
    getInterviewHTMDetails(id: any) {
        return this.http.get(Url.getInterviewHTMDetails + '/' + id).pipe();
    }
    postInterviewStatus(id: any) {
        return this.http.post(Url.postInterviewStatus + '/' + id, '').pipe();
    }

    getAllCandidate() {
        return this.http.get(Url.allcandidateUrl).pipe();
    }
    getSubmitCandidate(op_id: any) {
        return this.http.get(Url.getSubmitcandidateURL + '/' + op_id).pipe();
    }
    getSubmittedCandidateData(op_id: any) {
        return this.http.get(Url.getSubmitcandidateDataURL + '/' + op_id).pipe();
    }
    postSubmittedCandidate(op_id: any, c_id: any) {
        return this.http.post(Url.postSubmitcandidateDataURL + '/' + op_id + '/' + c_id, '').pipe();
    }
    deleteSubmittedCandidate(op_id: any, c_id: any) {
        return this.http.delete(Url.postSubmitcandidateDataURL + '/' + op_id + '/' + c_id).pipe();
    }

    makeRandomChars(length: number) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    getQbrainsSummary(htms: any, candID: any, opId: any) {
        return this.http.get(Url.qBrainComments + '?htms=' + htms + '&canddidate_id=' + candID + '&op_id=' + opId).pipe()
    }

    getCandidateById(id: any) {
        return this.http.get(Url.getCandidateById + '/' + id).pipe();
    }

    // getCandidateSummary(id: any) {
    //     return this.http.get(Url.getCandidateSummaryId+'/'+id).pipe();
    // }

    getCandidateScore(cId: any, mId: any, op_id: any) {
        return this.http.get(Url.getCandidateScoreUrl + '/' + cId + '/' + mId + '/' + op_id).pipe();
    }

    compareCandidateVoting(op_id: any, candidate_id: any, htmsId: any) {
        return this.http.post(Url.compareCandidateVoting + '/' + op_id + '/' + candidate_id, { htmsId: htmsId }).pipe();
    }

    advanceSearch(data: any) {
        return this.http.post(Url.advanceSearchUrl, data).pipe();
    }

    resendLogInfo(id: any) {
        return this.http.get(Url.candLogInfo + '/' + id);
    }
    withdrawCandidate(pid: any, cid: any, data: any) {
        return this.http.post(Url.withdrawUrl + '/' + pid + '/' + cid, data)
    }

    unwithdrawCandidate(pid: any, cid: any, data: any) {
        return this.http.post(Url.unWithdrawUrl + '/' + pid + '/' + cid, data)
    }

    sendMail(data: any) {
        return this.http.post(Url.sentMailUrl, data);
    }
    sendInterviewMail(data: any) {
        return this.http.post(Url.sendInterviewEmail, data);
    }

    getFileContent(url: any) {
        return this.http.get(url).pipe();
    }
    getCandidateRating(position: any, candidate: any) {
        // let params = new HttpParams().set('candidate_id', candidate)
        return this.http.get(Url.getRatingUrl + '/' + position + '/' + candidate,).pipe();
    }
    postCandidateRating(position: any, candidate: any, data: any) {
        return this.http.post(Url.postRatingUrl + '/' + position + '/' + candidate, data).pipe();
    }

    createCandidateRating(position: any, payload: any) {
        return this.http.post(Url.getRatingUrl + '/' + position, payload).pipe();
    }

    updateCandidateRating(position: any, payload: any) {
        return this.http.put(Url.getRatingUrl + '/' + position, payload).pipe();
    }

    getHMRating(candidate: any, position: any) {
        return this.http.get(Url.hmUrl + '/' + candidate + '/' + position).pipe();
    }

    createHMRating(candidate: any, position: any, payload: any) {
        return this.http.post(Url.hmUrl + '/' + candidate + '/' + position, payload).pipe();
    }

    getPositionToAssociate(position: any) {
        return this.http.get(Url.positiontoAssociateUrl + '/' + position).pipe();
    }

    associateCandidate(client: any, position: any, payload: any, resume?: any, reference?: any) {
        const formData: any = new FormData();
        const Payload = payload;
        Object.keys(Payload).forEach((key) => {
            formData.append(key, Payload[key]);
        });
        resume?.forEach((file: any) => { formData.append('resume[]', file); });
        reference?.forEach((file: any) => { formData.append('reference[]', file); });
        return this.http.post(Url.associateCandidate + '/' + client + '/' + position, formData).pipe();
    }

    getSetAvailability(htm_id: any) {
        return this.http.get(Url.setAvailability + '?htm_id=' + htm_id).pipe();
    }

    setAvailability(data: any) {
        return this.http.post(Url.setAvailability, data).pipe();
    }

    updateAvailability(data: any) {
        return this.http.put(Url.setAvailability, data).pipe();
    }

    withdrawHTM(group_id: any, member_id: any) {
        return this.http.post(Url.withdrawHiringMember + '/' + group_id + '/' + member_id, {}).pipe();
    }

    restoreHTM(group_id: any, member_id: any) {
        return this.http.post(Url.restoreHiringMember + '/' + group_id + '/' + member_id, {}).pipe();
    }

    // fetchLinkedinProfile(url: any) {
    //     return this.http.get(Url.getLinkedinData + '?url=' + url);
    // }

    fetchZohoMeeting(meetingKey: any) {
        return this.http.get(Url.getZohoMeeting + '?meetingKey=' + meetingKey);
    }

    getCandidateSchedules(id: any) {
        return this.http.get(Url.getCandidateInterviewsList + '/' + id)
    }
    getCandidatePositionData() {
        return this.http.get(Url.candidatePositionData)
    }
    getCandidateScheduleCalendar(month: any, year: any) {
        return this.http.get(Url.candidateScheduleCalendar + '?month=' + month + '&year=' + year)
    }
    getCandidateCalendarForPosition(month: any, year: any, op_id: any) {
        return this.http.get(Url.candidateScheduleCalendar + '?month=' + month + '&year=' + year + '&op_id=' + op_id)
    }

    getAvailableOpenPositionsForCandidate(id: any) {
        return this.http.get(Url.getAvailableOpenPositionsForCandidate + '/' + id);
    }

    updateInterviewResponse(interview_id: any, res: any) {
        return this.http.post(Url.updateInterviewResponse + '/' + interview_id, res);
    }

    getIntervieweSlots(htmId: any) {
        return this.http.get(Url.getIntervieweSlots + '/' + htmId);
    }

    setCandidateAvailability(data: any) {
        return this.http.post(Url.setCandidateAvailability, data).pipe();
    }

    getCandidateCalendar(canId: any) {
        const dateTimeFormat = new Intl.DateTimeFormat();
        const resolvedOptions = dateTimeFormat.resolvedOptions();
        const timeZone = resolvedOptions.timeZone;
        let params = new HttpParams().set('timezone', timeZone);
        return this.http.get(Url.getCandidateCalendar + '/' + canId, { params });
    }
    getCandidatePendingActions() {
        const dateTimeFormat = new Intl.DateTimeFormat();
        const resolvedOptions = dateTimeFormat.resolvedOptions();
        const timeZone = resolvedOptions.timeZone;
        let params = new HttpParams().set('timezone', timeZone);
        return this.http.get(Url.getCandidateActions, { params }).pipe();
    }

    detachCandidateFromOpenPosition(op_id: any, can_id: any) {
        return this.http.post(Url.detachCandidateFromOpenPosition + '/' + op_id + '/' + can_id, {}).pipe();
    }

    getEvaluateCommentByPosition(candidate_id: any, op_id: any) {
        return this.http.get(Url.getEvaluateCommentByPosition + '?candidate=' + candidate_id + '&position=' + op_id);
    }

    saveEvaluateCommentByPosition(data: any) {
        return this.http.post(Url.getEvaluateCommentByPosition, data);
    }

    changePassword(payload: any, can_id: any) {
        return this.http.post(Url.candidateResetPwd + '/' + can_id, payload).pipe();
    }

    endMeeting(conference_id: any) {
        return this.http.post(Url.endMeeting + '/' + conference_id, {});
    }

    setAllInterviews(data: any) {
        this.allInterviewsByCandidate$.next(data);
    }

    getAllInterviews() {
        return this.allInterviewsByCandidate$.asObservable();
    }

    associateCandidateToOpenPosition(candidate_id: any, op_id: any, isAccept: boolean) {
        return this.http.put(Url.associateCandidateToOpenPosition + '/' + candidate_id + '/' + op_id, { accept: isAccept });
    }
    updateCandidateBg(data: any) {
        const formData: any = new FormData();
        const Payload = data;
        Object.keys(Payload).forEach((key) => {
         formData.append(key, Payload[key]); 
        });
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.put(Url.updateCandidateBg + '/' + data.candidate_id, formData, { headers: headers }).pipe();
    }

    getCandidateAssociateData(op_id: any, can_id: any) {
        return this.http.get(Url.getCandidateAssociateData + '/' + op_id + '/' + can_id);
    }

    fetchProfileImage(can_id: any) {
        return this.http.get(Url.fetchProfileImage + '/' + can_id);
    }
}
