<div class="row">
    <div class="col-md-12">
        <a class="mr-5 mt-3 u-logo float-right"><img style="width: 100px;"
                src="./../../../assets/images/quorums-logo.png" /></a>
        <div class="widget-col createPosition" [ngClass]="{'height-300': stepName === 'fourth'}">

            <h2 class="widget-title" *ngIf="stepName === 'first'"><span>Select Package</span><span
                    *ngIf="firstLoggedUser" (click)="logOut()" class="float-right logoutButton pr-3">Logout</span></h2>
            <h2 class="widget-title" *ngIf="stepName === 'onepoint5'"><span>Account Details</span><span
                    *ngIf="firstLoggedUser" (click)="logOut()" class="float-right logoutButton pr-3">Logout</span></h2>
            <!-- <h2 class="widget-title" *ngIf="stepName === 'second'"><span>Billing Details</span><span *ngIf="firstLoggedUser" (click)="logOut()" class="float-right logoutButton pr-3">Logout</span></h2> -->
            <h2 class="widget-title" *ngIf="stepName === 'second'"><span>Account Summary</span><span (click)="logOut()"
                    class="float-right logoutButton pr-3">Logout</span></h2>
            <div class="widget-box">
                <div class="widget-inner-box">
                    <app-signup-stepper [stepName]="stepName"></app-signup-stepper>
                    <div class="form-section container mb-5 p-5" id="step1" *ngIf="stepName === 'first'">


                        <table class="table text-center pricing-table">
                            <tr>
                                <td colspan="2" rowspan="3" style="border: none"></td>

                                <td style="color: var(--primary); font-weight: bold;">STARTER</td>
                                <td style="color: var(--primary); font-weight: bold;">GROWTH</td>
                                <td style="color: var(--primary); font-weight: bold;">ENTERPRISE</td>
                            </tr>
                            <tr>
                                <td class="table-secondary">${{starterPackage.price}} Monthly</td>
                                <td class="table-secondary">${{growthPackage.price}} Monthly</td>
                                <td class="table-secondary">${{enterprisePackage.price}} Monthly</td>
                            </tr>
                            <tr>

                                <td>Total Users - {{starterPackage.users}}</td>
                                <td>Total Users - {{growthPackage.users}}</td>
                                <td>Total Users - {{enterprisePackage.users}}</td>
                            </tr>
                            <tr>
                                <td class="rotated" rowspan="5">USER LEVELS</td>
                                <td class="table-secondary rounded text-end CL_AD" style="width: 27%;">CLIENT
                                    ADMINISTRATORS
                                    <span class="tool_tip cursor-pointer text-center" ngbTooltip="The Client Administrator will have the ability to view and modify any-and-all account
                                    information, account settings, user information, as well as having universal access to all
                                    hiring operations within your organization’s Qorums environment." placement="right"
                                        container="body">
                                        <fa-icon [icon]="faInfo"></fa-icon>
                                    </span>
                                </td>
                                <td> 1 </td>
                                <td> 1 </td>
                                <td> 1 </td>
                            </tr>
                            <tr>
                                <td class="table-secondary rounded text-end">SENIOR MANAGERS
                                    <span class="tool_tip cursor-pointer text-center" ngbTooltip="Senior Managers will have universal access to all hiring operations going on within your
                                    organization’s Qorums environment, and will be able to edit any-and-all users and open
                                    positions. Individuals with the Senior Manager user level should be those that are tasked
                                    to drive the hiring process, and often need sufficient access to hiring information in order
                                    to do so." placement="right" container="body">
                                        <fa-icon [icon]="faInfo"></fa-icon>
                                    </span>
                                </td>
                                <td>{{starterPackage.SM}}</td>
                                <td>{{growthPackage.SM}}</td>
                                <td>{{enterprisePackage.SM}}</td>
                            </tr>
                            <tr>
                                <td class="table-secondary rounded text-end">HIRING MANAGERS
                                    <span class="tool_tip cursor-pointer text-center" ngbTooltip="Hiring Managers are users that have been designated to manage a particular hiring
                                    team, and will have full access, and editing capability, for any open position that their
                                    hiring team is assigned to. Hiring Managers will have the ability to associate candidates
                                    with their role, manage hiring activities such as scheduling interviews, and will have
                                    access to their team members’ interview feedback in real-time." placement="right"
                                        container="body">
                                        <fa-icon [icon]="faInfo"></fa-icon>
                                    </span>
                                </td>
                                <td>{{starterPackage.HM}}</td>
                                <td>{{growthPackage.HM}}</td>
                                <td>{{enterprisePackage.HM}}</td>
                            </tr>
                            <tr>
                                <td class="table-secondary rounded text-end">HIRING TEAM MEMBERS
                                    <span class="tool_tip cursor-pointer text-center" ngbTooltip="Hiring Team Members are users that are expected to interview candidates, and provide
                                    feedback to their Hiring Manager, and that’s it! They are involved to support an educated
                                    hiring decision, and are only given access to required information, that way their
                                    feedback is not influenced in any way from factors outside of the interview."
                                        placement="right" container="body">
                                        <fa-icon [icon]="faInfo"></fa-icon>
                                    </span>
                                </td>
                                <td>{{starterPackage.HTM}}</td>
                                <td>{{growthPackage.HTM}}</td>
                                <td>{{enterprisePackage.HTM}}</td>
                            </tr>
                            <tr>
                                <td class="table-secondary rounded text-end">TEAM COORDINATORS
                                    <span class="tool_tip cursor-pointer text-center" ngbTooltip="Team Contributors are users that are not necessarily required for the hiring process, but
                                    that can be included to help move the process along. The contributor title is a
                                    designation, assigned to a hiring team member when making a hiring team, and they are
                                    able to access open positions in order to help with submitting candidates, scheduling
                                    interviews, and managing timelines. They are given just enough access to operate in
                                    support of an efficient hiring process, but are not so involved that they can hold up a
                                    hiring decision." placement="right" container="body">
                                        <fa-icon [icon]="faInfo"></fa-icon>
                                    </span>
                                </td>
                                <td>UNLIMITED</td>
                                <td>UNLIMITED</td>
                                <td>UNLIMITED</td>
                            </tr>
                            <tr>
                                <td style="border: none"></td>
                                <td style="border: none"></td>
                                <td style="border: none" class="text-center">
                                    <p class="pack-intro">Perfect for smaller companies, departments, or groups.
                                        Additional users can be added as
                                        needed.</p>
                                    <button type="button" class="btn btn-primary " style="width: 100px;"
                                        (click)="packageSelected('Starter')">Select</button>


                                </td>
                                <td style="border: none" class="text-center">
                                    <p class="pack-intro">Perfect for mid-sized companies, divisions, etc. Additional
                                        users can be added as
                                        needed.</p>
                                    <button type="button" class="btn btn-primary " style="width: 100px;"
                                        (click)="packageSelected('Growth')">Select</button>


                                </td>
                                <td style="border: none" class="text-center">
                                    <p class="pack-intro">Designed for larger companies that have a number of hiring
                                        events going on
                                        simultaneously. Additional users can be added as needed.</p>
                                    <button type="button" class="btn btn-primary " style="width: 100px;"
                                        (click)="packageSelected('Enterprise')">Select</button>


                                </td>
                            </tr>
                        </table>
                    </div>
                    <div @fade class="form-section container-fluid mb-5 p-5" id="step1-5"
                        *ngIf="stepName === 'onepoint5'">
                        <form id="addCandidate" [formGroup]="clientForm">
                            <fieldset class="scheduler-border">
                                <legend class="pt-4">Company Contacts</legend>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Full Name<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control"
                                            formControlName="company_contact_full_name">
                                        <div *ngIf="clientForm.controls['company_contact_full_name'].invalid && (clientForm.controls['company_contact_full_name'].dirty || clientForm.controls['company_contact_full_name'].touched)"
                                            class="alert">
                                            <div
                                                *ngIf="clientForm.controls['company_contact_full_name'].errors?.required">
                                                Please enter name.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Phone<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="company_contact_phone">
                                        <div *ngIf="clientForm.controls['company_contact_phone'].invalid && (clientForm.controls['company_contact_phone'].dirty || clientForm.controls['company_contact_phone'].touched)"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['company_contact_phone'].errors?.required">
                                                Please enter Phone number.
                                            </div>
                                            <div *ngIf="clientForm.controls['company_contact_phone'].errors?.pattern">
                                                Phone number must be exactly 10 digit & only number is allowed.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Email<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="company_contact_email">
                                        <div *ngIf="clientForm.controls['company_contact_email'].invalid && (clientForm.controls['company_contact_email'].dirty || clientForm.controls['company_contact_email'].touched)"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['company_contact_email'].errors?.required">
                                                Please enter email address.
                                            </div>
                                            <div *ngIf="clientForm.controls['company_contact_email'].errors?.pattern">
                                                Invalid Email, please try again.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="scheduler-border">
                                <legend class="pt-4">Company Details</legend>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Company Name<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="company_name">
                                        <div *ngIf="clientForm.controls['company_name'].invalid && (clientForm.controls['company_name'].dirty || clientForm.controls['company_name'].touched)"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['company_name'].errors?.required">
                                                Please enter company name
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Company Website</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="company_website">
                                        <div *ngIf="clientForm.controls['company_website'].errors?.pattern"
                                            class="alert">
                                            Please enter valid url.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Company LinkedIn</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="company_linkedin">
                                        <div *ngIf="clientForm.controls['company_linkedin'].errors?.pattern"
                                            class="alert">
                                            Please enter valid LinkedIn url.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Address Line 1<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="addr_line_1">
                                        <div *ngIf="clientForm.controls['addr_line_1'].invalid && (clientForm.controls['addr_line_1'].dirty || clientForm.controls['addr_line_1'].touched)"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['addr_line_1'].errors?.required">
                                                Please enter your Address.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Address Line 2</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="addr_line_2">

                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">City<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="city">
                                        <div *ngIf="clientForm.controls['city'].invalid && (clientForm.controls['city'].dirty || clientForm.controls['city'].touched)"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['city'].errors?.required">
                                                Please enter city.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">State<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="state">
                                        <div *ngIf="clientForm.controls['state'].invalid && (clientForm.controls['state'].dirty || clientForm.controls['state'].touched)"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['state'].errors?.required">
                                                Please enter state.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Zip Code<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="pincode">
                                        <div *ngIf="clientForm.controls['pincode'].invalid && (clientForm.controls['pincode'].dirty || clientForm.controls['pincode'].touched)"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['pincode'].errors?.required">
                                                Please enter Zip code.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Upload logo image</label>
                                    <div class="col-sm-9">
                                        <input type="file" style="width: 250px; float: left;" class="form-control-file"
                                            (change)="showPreview($event)">
                                        <input type="hidden" name="fileHidden" formControlName="logo" />
                                        <div class="imagePreview"
                                            *ngIf="imageURL && imageURL !== '' && imageURL !== 'None'">
                                            <img [src]="imageURL" [alt]="clientForm.value.name">
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="scheduler-border">
                                <legend class="pt-4">Client Admin</legend>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">First Name<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="ca_first_name">
                                        <div *ngIf="clientForm.controls['ca_first_name'].invalid && (clientForm.controls['ca_first_name'].dirty || clientForm.controls['ca_first_name'].touched)"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['ca_first_name'].errors?.required">
                                                Please enter first name
                                            </div>
                                            <div *ngIf="clientForm.controls['ca_first_name'].errors?.pattern">
                                                Please enter valid name, only alphabets are allowed.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Last Name<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="ca_last_name">
                                        <div *ngIf="clientForm.controls['ca_last_name'].invalid && (clientForm.controls['ca_last_name'].dirty || clientForm.controls['ca_last_name'].touched)"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['ca_last_name'].errors?.required">
                                                Please enter last name
                                            </div>
                                            <!-- <div *ngIf="clientForm.controls['ca_last_name'].errors?.pattern">
                                                Please enter valid name, only alphabets are allowed.
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Phone<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="key_contact_phone_no">
                                        <div *ngIf="clientForm.controls['key_contact_phone_no'].invalid && (clientForm.controls['key_contact_phone_no'].dirty || clientForm.controls['key_contact_phone_no'].touched)"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['key_contact_phone_no'].errors?.required">
                                                Please enter phone
                                            </div>
                                            <div *ngIf="clientForm.controls['key_contact_phone_no'].errors?.pattern">
                                                Phone number must be exactly 10 digit & only number is allowed.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">LinkedIn Address</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="key_contact_skype_id">
                                        <!-- <div *ngIf="clientForm.controls['key_contact_skype_id'].invalid && (clientForm.controls['key_contact_skype_id'].dirty || clientForm.controls['key_contact_skype_id'].touched)" class="alert">
                                            <div *ngIf="clientForm.controls['key_contact_skype_id'].errors?.required">
                                                Please enter LinkedIn address
                                            </div>
                                        </div> -->
                                        <div *ngIf="clientForm.controls['key_contact_skype_id'].errors?.pattern"
                                            class="alert">
                                            Please enter valid LinkedIn url.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Email<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="key_contact_email">
                                        <div *ngIf="clientForm.controls['key_contact_email'].invalid && (clientForm.controls['key_contact_email'].dirty || clientForm.controls['key_contact_email'].touched)"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['key_contact_email'].errors?.required">
                                                Please enter email
                                            </div>
                                            <div *ngIf="clientForm.controls['key_contact_email'].errors?.pattern">
                                                Invalid Email, please try again.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Job Title<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="job_title">
                                        <div *ngIf="clientForm.controls['job_title'].invalid && (clientForm.controls['job_title'].dirty || clientForm.controls['job_title'].touched)"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['job_title'].errors?.required">
                                                Please enter job title
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Username<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="key_username">
                                        <div *ngIf="clientForm.controls['key_username'].invalid && (clientForm.controls['key_username'].dirty || clientForm.controls['key_username'].touched)"
                                            class="alert">
                                            <div *ngIf="clientForm.controls['key_username'].errors?.required">
                                                Please enter username
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Password<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="password" class="form-control" formControlName="key_password">
                                        <div *ngIf="clientForm.controls['key_password'].invalid && (clientForm.controls['key_password'].dirty || clientForm.controls['key_password'].touched)" class="alert">
                                            <div *ngIf="clientForm.controls['key_password'].errors?.required">
                                                Please enter password
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="form-group row" *ngIf="!firstLoggedUser && !backToClient">
                                    <label class="col-sm-3 col-form-label">Password<small>*</small></label>
                                    <!-- <div class="col-sm-9">
                                        <input type="password" class="form-control" formControlName="key_password">
                                        <div *ngIf="!isSettingsPage && clientForm.controls['key_password'].invalid && (clientForm.controls['key_password'].dirty || clientForm.controls['key_password'].touched)" class="alert">
                                            <div *ngIf="clientForm.controls['key_password'].errors?.required">
                                                Please enter password
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-9">
                                        <input type="{{ showPassword ? 'text' : 'password' }}"
                                            class="form-control input-password" formControlName="key_password"
                                            (input)="onPasswordChange()"
                                            [ngStyle]="{'color': clientForm.controls.key_password.errors? 'red' : 'green'}">
                                        <button type="button" (click)="togglePasswordVisibility()"
                                            class="password-toggle">
                                            <i class="fas"
                                                [ngClass]="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                        </button>
                                    </div>
                                    <div class="col-sm-9 offset-3">
                                        <div *ngIf="clientForm.controls['key_password'].invalid && (clientForm.controls['key_password'].dirty || clientForm.controls['key_password'].touched)"
                                            class="alert">
                                            <div
                                                *ngIf="clientForm.controls['key_password'].errors?.required && !clientForm.controls['key_password'].value">
                                                Please enter password
                                            </div>
                                        </div>
                                        <div *ngIf="clientForm.controls['key_password']?.errors && clientForm.controls['key_password'].value && !(clientForm.controls['key_password']?.hasError('maxlength'))"
                                            class="password-valid-pts">
                                            Password must contain at least one of each of the following characters:
                                            <div [ngStyle]="{'color': isUppercaseValid ? 'green' : 'red'}">
                                                <span>- Uppercase letters: A-Z.</span>
                                            </div>
                                            <div [ngStyle]="{'color': isLowecaseValid ? 'green' : 'red'}">
                                                <span>- Lowercase letters: a-z.</span>
                                            </div>
                                            <div [ngStyle]="{'color': isNumberValid ? 'green' : 'red'}">
                                                <span>- Numbers: 0-9.</span>
                                            </div>
                                            <div [ngStyle]="{'color': isSymbolValid ? 'green' : 'red'}">
                                                <span>- Symbols: ~`! @#$%^&*()_-+={{'{'}}[{{'}'}}]|\:;"'<,>.?/</span>
                                            </div>
                                        </div>
                                        <div *ngIf="(clientForm.controls['key_password']?.hasError('minlength') || clientForm.controls['key_password']?.hasError('maxlength')) && (clientForm.controls['key_password'].dirty || clientForm.controls['key_password'].touched)"
                                            class="alert">
                                            <span>Password should contain min 8 and max 16 charaters</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Upload profile picture</label>
                                    <!-- <div class="col-sm-9">
                                        <input type="file" style="width: 250px; float: left;" class="form-control-file" (change)="showCAPreview($event)">
                                        <input type="hidden" name="fileHidden" formControlName="ca_profile_pic" />
                                        <div class="profileImagePreview" *ngIf="caProfilePic && caProfilePic !== '' && caProfilePic !== 'None'">
                                            <img [src]="caProfilePic" [alt]="clientForm.value.name">
                                        </div>
                                    </div> -->
                                    <div class="col-sm-9">
                                        <div *ngIf="caProfilePic == '' || caProfilePic == 'None'">
                                            <input type="file" style="width: 250px; float: left;"
                                                class="form-control-file" (change)="showCAPreview($event)">
                                            <input type="hidden" name="fileHidden" formControlName="ca_profile_pic" />
                                        </div>
                                        <div class="profileImagePreview"
                                            *ngIf="caProfilePic && caProfilePic !== '' && caProfilePic !== 'None'">
                                            <span id="x" (click)="removePhoto()">
                                                x
                                            </span>
                                            <img [src]="caProfilePic" [alt]="clientForm.value.name">
                                        </div>
                                    </div>
                                </div>
                                <!-- <button *ngIf="firstLoggedUser" class="btn btn-primary" style="float: right;" (click)="openforPassword(password)">Change Password</button> -->
                            </fieldset>
                            <fieldset class="scheduler-border">
                                <legend class="pt-4">Coordinator Contact</legend>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">First Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="hr_first_name">
                                        <div *ngIf="clientForm.controls['hr_first_name'].errors?.pattern" class="alert">
                                            Please enter valid name, only alphabets are allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Last Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="hr_last_name">
                                        <div *ngIf="clientForm.controls['hr_last_name'].errors?.pattern" class="alert">
                                            Please enter valid name, only alphabets are allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Phone</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="hr_contact_phone_no">
                                        <div *ngIf="clientForm.controls['hr_contact_phone_no'].errors?.pattern"
                                            class="alert">
                                            Phone number must be exactly 10 digit & only number is allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">LinkedIn Address</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="hr_contact_skype_id">
                                        <div *ngIf="clientForm.controls['hr_contact_skype_id'].errors?.pattern"
                                            class="alert">
                                            Please enter valid LinkedIn url.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Email</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="hr_contact_email">
                                        <div *ngIf="clientForm.controls['hr_contact_email'].errors?.pattern"
                                            class="alert">
                                            Invalid Email, please try again.
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="scheduler-border">
                                <legend class="pt-4">CTO</legend>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">First Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="cto_first_name">
                                        <div *ngIf="clientForm.controls['cto_first_name'].errors?.pattern"
                                            class="alert">
                                            Please enter valid name, only alphabets are allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Last Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="cto_last_name">
                                        <div *ngIf="clientForm.controls['cto_last_name'].errors?.pattern" class="alert">
                                            Please enter valid name, only alphabets are allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Phone</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="cto_phone_no">
                                        <div *ngIf="clientForm.controls['cto_phone_no'].errors?.pattern" class="alert">
                                            Phone number must be exactly 10 digit & only number is allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">LinkedIn Address</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="cto_skype_id">
                                        <div *ngIf="clientForm.controls['cto_skype_id'].errors?.pattern" class="alert">
                                            Please enter valid LinkedIn url.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Email</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="cto_email">
                                        <div *ngIf="clientForm.controls['cto_email'].errors?.pattern" class="alert">
                                            Invalid Email, please try again.
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="scheduler-border">
                                <legend class="pt-4">Billing Contact</legend>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">First Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="billing_first_name">
                                        <div *ngIf="clientForm.controls['billing_first_name'].errors?.pattern"
                                            class="alert">
                                            Please enter valid name, only alphabets are allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Last Name</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="billing_last_name">
                                        <div *ngIf="clientForm.controls['billing_last_name'].errors?.pattern"
                                            class="alert">
                                            Please enter valid name, only alphabets are allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Phone</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="billing_phone_no">
                                        <div *ngIf="clientForm.controls['billing_phone_no'].errors?.pattern"
                                            class="alert">
                                            Phone number must be exactly 10 digit & only number is allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Email</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="billing_email">
                                        <div *ngIf="clientForm.controls['billing_email'].errors?.pattern" class="alert">
                                            Invalid Email, please try again.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Address Line 1</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="billing_addr_line_1">

                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Address Line 2</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="billing_addr_line_2">

                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">City</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="billing_city">

                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">State</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="billing_state">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">Zip Code</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="billing_pincode">
                                    </div>
                                </div>
                            </fieldset>
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Special requirements</label>
                                <div class="col-sm-9">
                                    <textarea class="form-control" formControlName="special_req"></textarea>
                                </div>
                            </div>

                        </form>
                        <button type="submit" [disabled]="clientForm.invalid" class="btn btn-primary float-right m-3"
                            (click)="goToNextStep('onepoint5')">
                            Next
                        </button>

                        <button type="submit" class="btn btn-secondary float-right m-3"
                            (click)="goToPreviousStep('first')">
                            Back
                        </button>

                    </div>
                    <div @fade class="form-section container-fluid mb-5 p-5" id="step2" *ngIf="stepName === 'xyz'">
                        <fieldset class="py-4 billContForm">
                            <form [formGroup]="billingInfoForm">
                                <div class="form-group row ">
                                    <label for="billing-contact" class="col-sm-3 col-form-label">Billing
                                        Contact<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control billContName"
                                            formControlName="billing_contact">
                                        <div *ngIf="billingInfoForm.controls['billing_contact'].invalid && (billingInfoForm.controls['billing_contact'].dirty || billingInfoForm.controls['billing_contact'].touched)"
                                            class="alert">
                                            <div *ngIf="billingInfoForm.controls['billing_contact'].errors?.required">
                                                Please enter billing contact.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row ">
                                    <label for="billing-contact" class="col-sm-3 col-form-label">Billing
                                        Email<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control billContName"
                                            formControlName="billing_email">
                                        <div *ngIf="billingInfoForm.controls['billing_email'].invalid && (billingInfoForm.controls['billing_email'].dirty || billingInfoForm.controls['billing_email'].touched)"
                                            class="alert">
                                            <div *ngIf="billingInfoForm.controls['billing_email'].errors?.required">
                                                Please enter billing email.
                                            </div>
                                            <div *ngIf="billingInfoForm.controls['billing_email'].errors?.pattern"
                                                class="alert">
                                                Please enter valid email address.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row ">
                                    <label for="billing-contact" class="col-sm-3 col-form-label">Billing
                                        Phone<small>*</small></label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control billContName "
                                            formControlName="billing_phone">
                                        <div *ngIf="billingInfoForm.controls['billing_phone'].invalid && (billingInfoForm.controls['billing_phone'].dirty || billingInfoForm.controls['billing_phone'].touched)"
                                            class="alert">
                                            <div *ngIf="billingInfoForm.controls['billing_phone'].errors?.required">
                                                Please enter billing phone.
                                            </div>
                                            <div *ngIf="billingInfoForm.controls['billing_phone'].errors?.pattern"
                                                class="alert">
                                                Please enter valid 10 digit phone number.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="form-group row justify-content-center">
                                    <div class="offset-4 col-sm-7">
                                        <input type="checkbox" id="billingSameAsCompany" [(ngModel)]="billingAddress" (click)="selectAddress($event)" class="d-inline-block me-3">
                                        <label for="billingSameAsCompany" class="d-inline-block mb-0"><small>Billing address same as company address</small></label>
                                    </div>
                                </div> -->
                                <div class="form-group row ">
                                    <label for="adressLine1" class="col-sm-3 col-form-label">Address line 1</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control " formControlName="addr_line_1">
                                    </div>
                                </div>
                                <div class="form-group row ">
                                    <label for="adressLine2" class="col-sm-3 col-form-label">Address Line 2</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control " formControlName="addr_line_2">
                                    </div>
                                </div>
                                <div class="form-group row ">
                                    <label for="cityName" class="col-sm-3 col-form-label">City</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="city">
                                    </div>
                                </div>
                                <div class="form-group row ">
                                    <label for="state" class="col-sm-3 col-form-label">State</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control " formControlName="state">
                                    </div>
                                </div>
                                <div class="form-group row ">
                                    <label for="zipcode" class="col-sm-3 col-form-label">Zip</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" formControlName="pincode">
                                    </div>
                                </div>
                                <!-- <div class="form-group row justify-content-center">
                                    <label for="cardOnFile" class="col-sm-3 col-form-label">Card on file</label>
                                    <input readonly type="text" class="form-control col-sm-3" [value]="lastFourDigits" formControlName="cardOnFile">
                                    <div class="col-sm-2">
                                        <button type="button" class="btn btn-primary" (click)="updateCard()">Update</button>
                                    </div>
                                </div> -->
                                <div class="form-group row pl-4">
                                    <div class="col-sm-3"></div>
                                    <div class="col-sm-5">
                                        <div class="form-group row">
                                            <label for="cardOnFile" class="row col-form-label">Card Number</label><br>
                                            <input type="text" class="form-control row ml-0 col-sm-12"
                                                placeholder="eg. 4242424242424242" maxlength="16" size="16"
                                                formControlName="card_number">
                                            <div *ngIf="billingInfoForm.controls['card_number'].invalid && (billingInfoForm.controls['card_number'].dirty || billingInfoForm.controls['card_number'].touched)"
                                                class="alert">
                                                <div *ngIf="billingInfoForm.controls['card_number'].errors?.pattern">
                                                    Please enter valid 16 digit card number.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row ">
                                            <label for="cardOnFile" class="row col-form-label">Name on card</label><br>
                                            <input type="text" class="form-control row ml-0 col-sm-12"
                                                placeholder="eg. Joe Biden" formControlName="name_on_card">
                                        </div>
                                        <div class="form-group row ">
                                            <div class="col-sm-6  pl-0 ">
                                                <label for="cardOnFile" class="row pl-3 col-form-label">Expiry
                                                    Date</label>
                                                <input type="text" class="form-control row ml-0 col-sm-12" maxlength="5"
                                                    size="5" placeholder="eg. MM/YY" formControlName="exp_date">
                                                <div *ngIf="billingInfoForm.controls['exp_date'].invalid && (billingInfoForm.controls['exp_date'].dirty || billingInfoForm.controls['exp_date'].touched)"
                                                    class="alert">
                                                    <div *ngIf="billingInfoForm.controls['exp_date'].errors?.pattern">
                                                        Please enter valid MM/YY date.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6  pl-0 ">
                                                <label for="cardOnFile" class="row pl-3 col-form-label">Security
                                                    Code</label>
                                                <input type="text" class="form-control row ml-0 col-sm-12"
                                                    placeholder="eg. 123" maxlength="3" size="3"
                                                    formControlName="security">
                                                <div *ngIf="billingInfoForm.controls['security'].invalid && (billingInfoForm.controls['security'].dirty || billingInfoForm.controls['security'].touched)"
                                                    class="alert">
                                                    <div *ngIf="billingInfoForm.controls['security'].errors?.pattern">
                                                        Please enter valid 3 digit code.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="d-inline float-right my-3">
                                    <button class="btn btn-primary" >Save</button>
                                </div> -->
                            </form>
                        </fieldset>
                        <button type="submit" [disabled]="billingInfoForm.invalid"
                            class="btn btn-primary float-right m-3" (click)="goToNextStep('second')">
                            Next
                        </button>
                        <button type="submit" class="btn btn-secondary float-right m-3"
                            (click)="goToPreviousStep('onepoint5')">
                            Back
                        </button>

                    </div>

                    <div @fade class="form-section container mb-5 p-5" id="step3" *ngIf="stepName === 'second'">
                        <div class="row">
                            <div class="col-sm-6 ">
                                <div class="card">
                                    <h5 class="pl-3 pt-3">Account Details</h5>
                                    <div class="row ml-1">
                                        <span class="col-sm-2">
                                            <figure class="border border-dark">
                                                <img [src]="imageURL && imageURL !== 'None' && imageURL !== 'null' ? imageURL : './../../assets/images/big-default-avatar.png'"
                                                    class="candidate-profile-pic" [alt]="clientForm.value.name" />
                                            </figure>
                                        </span>
                                        <span class="col-sm-10 user-info">
                                            <span class="name">{{companyName}}</span>
                                            <span class="name">{{companyWebsite}}</span>
                                            <span class="name">{{companyLinkedin}}</span>
                                            <span class="name">{{companyAdd}}</span>
                                            <span class="name">{{companyCity}} </span>
                                            <span class="name"> {{companyState}} </span>
                                            <span class="name"> {{companyZip}}</span>
                                        </span>
                                    </div>
                                    <span class="iconHolder">
                                        <fa-icon [icon]="faEdit" (click)="goToPreviousStep('onepoint5')"></fa-icon>
                                        <!-- <fa-icon (click)="openSm(content, item?.username)" [icon]="faDelete"></fa-icon> -->
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="card">
                                    <h5 class="pl-3 pt-3">Account settings</h5>
                                    <span class="ml-3">
                                        <span class="name">Package Selected: {{selectedPackage}} Account</span>
                                        <span class="name">Package Prize: ${{selectedPackagePrice}} per month</span>
                                    </span>

                                    <span class="iconHolder">
                                        <fa-icon [icon]="faEdit" (click)="goToPreviousStep('first')"></fa-icon>
                                        <!-- <fa-icon (click)="openSm(content, item?.username)" [icon]="faDelete"></fa-icon> -->
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-6 pt-4">
                                <div class="card">
                                    <h5 class="pl-3 pt-3">Client Admin</h5>
                                    <div class="row ml-1">
                                        <span class="col-sm-2">
                                            <figure class="border border-dark">
                                                <img [src]="caProfilePic  && caProfilePic !== 'None' && caProfilePic !== 'null'  ? caProfilePic : './../../assets/images/big-default-avatar.png'"
                                                    class="candidate-profile-pic" [alt]="clientForm.value.name" />
                                            </figure>
                                        </span>
                                        <span class="col-sm-10 user-info">
                                            <span class="name">{{CA_name}}</span>
                                            <span class="name">{{CA_phone}}</span>
                                            <span class="name">{{CA_email}}</span>
                                            <span class="name">{{CA_linkedin}}</span>
                                            <span class="name">{{jobTitle}}</span>
                                            <span class="name">{{clientUsername}}</span>
                                        </span>
                                    </div>
                                    <span class="iconHolder">
                                        <fa-icon [icon]="faEdit" (click)="goToPreviousStep('onepoint5')"></fa-icon>
                                        <!-- <fa-icon (click)="openSm(content, item?.username)" [icon]="faDelete"></fa-icon> -->
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-6  pt-4">
                                <div class="card">
                                    <h5 class="pl-3 pt-3">Billing Details</h5>
                                    <div class="row ml-1">
                                        <span class="col-sm-10 user-info">
                                            <span class="name">{{billingName}}</span>
                                            <span class="name">{{billingPhone}}</span>
                                            <span class="name">{{billingEmail}}</span>
                                            <span class="name">{{billingAddress}}</span>
                                            <span class="name">{{billingCity}}</span>
                                            <span class="name">{{billingState}}</span>
                                            <span class="name">{{billingzip}}</span>
                                        </span>
                                    </div>
                                    <span class="iconHolder">
                                        <fa-icon [icon]="faEdit" (click)="goToPreviousStep('onepoint5')"></fa-icon>
                                        <!-- <fa-icon (click)="openSm(content, item?.username)" [icon]="faDelete"></fa-icon> -->
                                    </span>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary float-right m-3" (click)="onSubmit(confirmBox)">
                            Submit
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #password let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Change Password</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="passwordForm">
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">New Password
                    <small>*</small>
                </label>
                <div class="col-sm-8">
                    <input type="password" class="form-control" formControlName="new_password">
                    <div *ngIf="passwordForm.controls['new_password'].invalid && (passwordForm.controls['new_password'].dirty || passwordForm.controls['new_password'].touched)"
                        class="alert">
                        <div *ngIf="passwordForm.controls['new_password'].errors?.required">
                            Password is required.
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Re-Password
                    <small>*</small>
                </label>
                <div class="col-sm-8">
                    <input type="password" class="form-control" formControlName="re_password" (blur)="checkPasswords()">
                    <div *ngIf="passwordForm.controls['re_password'].invalid && (passwordForm.controls['re_password'].dirty || passwordForm.controls['re_password'].touched)"
                        class="alert">
                        <div *ngIf="passwordForm.controls['re_password'].errors?.required">
                            Re-Password is required.
                        </div>
                    </div>
                    <label *ngIf="showError" class="alert">Password not matching, try again</label>
                </div>
            </div>
        </form>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="passwordForm.invalid || showError"
            (click)="changePassword()">Change Password</button>
    </div>
</ng-template>

<ng-template #confirmBox let-modal>
    <div class="modal-header">
        <h4 class="modal-title title">Please Confirm</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="text-center">Upon clicking confirm, the credit card entered will be immediately charged for the amount
            shown below under total charge
            today. Additionally, by agreeing to this charge, you are also agreeing to recurring monthly charges on the
            1st of each month,
            for the amount listed under Package prise, along with any additional charges incurred at a later time. Upon
            submission, the individual designated as Client Admin will also be sent their Username and Password, and
            will be
            able to login to Qorums and make changes to the account at that time.
        </p><br>
        <span class="text-center">
            <p>Package Selected: {{selectedPackage}} Account</p>
        </span>
        <span class="text-center">
            <p>Package Price: ${{selectedPackagePrice}}</p>
        </span>
        <span class="text-center">
            <p>Charge Today: ${{selectedPackagePrice}}</p>
        </span>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="submitFirstLogin(paymentGateway)">Confirm</button>
    </div>
</ng-template>

<ng-template #paymentGateway let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Pay Package charges</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body payMod">
        <form id="payment-form">
            <div id="link-authentication-element">
                <!--Stripe.js injects the Link Authentication Element-->
            </div>
            <div id="payment-element">
                <!--Stripe.js injects the Payment Element-->
            </div>
            <button id="submit" class="payButton">
                <div class="spinner hidden" id="spinner"></div>
                <span id="button-text">Pay now</span>
            </button>
            <div id="payment-message" class="hidden"></div>
        </form>

    </div>
    <div class="modal-footer">

    </div>
</ng-template>