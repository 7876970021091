import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastService } from '../toast/toast-service';
import { AuthService } from './../../services/auth.services';
import { UserService } from './../../services/user.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private userService: UserService,
    public toastService: ToastService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (!navigator.onLine) {
          this.showDanger('No Internet Connection');
          return throwError('No Internet Connection');
        }
        if (err.status === 400) {
          this.showDanger(err.error.msg);
        }
        if (err.status === 401) {
          this.authService.logout();
          this.userService.setLogin(true);
          location.reload();
        }
        if (err.status === 404) {
          this.showDanger('Not Found');
        }
        if (err.error instanceof Error) {
          this.showDanger('An error occurred:' + err.error.message);
        } else {
          this.showDanger('Something went wrong');
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }
}
