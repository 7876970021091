import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public userRole: string = '';
  constructor() { }

  ngOnInit(): void {
    let uerdata:any = localStorage.getItem('currentUser');
    uerdata = JSON.parse(uerdata);
    if(uerdata) {
      this.userRole = uerdata?.role;
    }
  }

}
