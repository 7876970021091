import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faCheck,faUserPlus,faShoppingBag, faGraduationCap,faHandshake, faComments,faDesktop } from '@fortawesome/free-solid-svg-icons';
import { faCompass } from '@fortawesome/free-regular-svg-icons';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.services';
@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.css']
})
export class NavigatorComponent implements OnInit {
  faCheck= faCheck;
  faCompass= faCompass;
  faUserPlus=faUserPlus;
  faShoppingBag=faShoppingBag;
  faGraduationCap = faGraduationCap;
  faHandshake = faHandshake;
  faComments = faComments;
  faDesktop = faDesktop;
  @ViewChild('navigator') navigatorModal : ElementRef
  navigators: any=[];
  currentItem: any;
  currentStep: number;
  currentUserRoleCode: any;
  userRole: any;
  constructor(private modalService: NgbModal,
              private userService: UserService,
              private authService : AuthService
  ) { }

  ngOnInit(): void {
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    this.userRole = this.currentUserRoleCode.split('/')[1];
  }
  openNavigatorModal(){
    let currentURL = window.location.href
    if(!(currentURL.includes('signup-user')) && !(currentURL.includes('interview-screen')))
      this.modalService.open(this.navigatorModal,{size:'xl'})
  }
  closeNavigatorModal(){
    this.modalService.dismissAll()
  }
  getNavigatorList(){
    this.userService.getNavigatorList().subscribe((res:any)=>{
      this.navigators = res.data.sort(function(a, b){return a.step_id - b.step_id});
      if(this.userRole == 'htm'){
        this.navigators = [this.navigators[5]]
      }
      this.showStep(0)
    })
  }
  showStep(index:number){
    let str = this.navigators[index].content 
    if(str.indexOf('href')>0){
      var mySubString = str.substring(
        str.indexOf('<a '), 
        str.lastIndexOf("</a>") +4
    );
      this.navigators[index].content = str.replace(mySubString,"")
      let hylink = mySubString.substring(
        mySubString.indexOf('"')+1,this.getPosition(mySubString,'"',2)
      )
      if(hylink.indexOf('youtube')>0){
        if(hylink.indexOf('&')>0){
          hylink = hylink.substring(0,hylink.indexOf('&'))
        }
        hylink = hylink.replace("watch?v=","embed/")
      }
      if(hylink.indexOf('vimeo')>0){
        let vlink = hylink.substr(hylink.lastIndexOf('/'))
        hylink = 'https://player.vimeo.com/video'+vlink
      }
      this.navigators[index].videoLink = hylink;
    }
      this.currentItem = this.navigators[index];
  }
   getPosition(string:string, subString:string, index:number) {
    return string.split(subString, index).join(subString).length;
  }
  getNavigatorStep(){
    this.userService.getNavigatorStep().subscribe((res:any)=>{
      this.currentStep = res.data.step_id;
    })
  }
}
