<!-- <main id="positions-content">
    <app-header style="z-index: 99;position: relative;  "></app-header>
    <div class="widget-wrapper">
        
        
        <div class="page-body ">
            
        </div>
    </div>
</main> -->
<div class="row mb-2"
    [ngClass]="{ 'mt-4': this.currentUserRoleCode == '/can','pt-4': this.currentUserRoleCode == '/can' }">
    <div class="col-md-12" *ngIf="this.currentUserRoleCode !== '/can'">
        <div class="widget-col createPosition">
            <h2 class="widget-title" *ngIf="this.currentUserRoleCode != '/htm' && this.currentUserRoleCode != '/hm'">
                Dashboard</h2>
            <div *ngIf="dashboardData && this.currentUserRoleCode != '/htm' && this.currentUserRoleCode != '/hr'"
                class="widget-box dashboard-area qorums-dash">
                <ngx-masonry *ngIf="dashboardData">
                    <!-- <div ngxMasonryItem class="col-xl-4 col-lg-6 my-2 col-md-12" *ngIf="dashboardData">
                        <div class="card company-card">
                            <h3 class="mb-0" *ngIf="dashboardData['client-name']">{{dashboardData['client-name']}}</h3>
                            <a href="#">Company Contracts</a>
                            <a href="#">Run Reports</a>
                            <a href="#">Schedule Meetings</a>
                        </div>
                    </div> -->

                    <div ngxMasonryItem class="col-xl-4 col-lg-6 my-2 col-md-12"
                        *ngIf="dashboardData['liked-candidates'] && isAnalyticsSelected('liked-candidates')">
                        <div class="card">
                            <div class="row no-gutters">
                                <div class="col-md-2">
                                    <span class="marks-flag thumbs-up m-flags">
                                        <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}"
                                            [icon]="faThumbsUp"></fa-icon>
                                    </span>
                                </div>
                                <div class="col-md-9">
                                    <h3 class="dash-items">{{dashboardData['liked-candidates'].count}} Liked Candidates
                                    </h3>
                                </div>
                                <div class="col-md-1">
                                    <p *ngIf="!detailedInfoCardIndex.includes('liked-candidates')"><fa-icon
                                            class="info-icon" [icon]="faChartLine"
                                            (click)="showAdditionalInfo('liked-candidates')"></fa-icon></p>
                                    <p *ngIf="detailedInfoCardIndex.includes('liked-candidates')"><fa-icon
                                            class="info-icon" [icon]="faTimes"
                                            (click)="hideAdditionalInfo('liked-candidates')"></fa-icon></p>
                                    <!-- <p class="mt-5"><fa-icon class="info-icon" [icon]="faChartLine"></fa-icon></p> -->
                                </div>
                            </div>
                            <div class="row no-gutters additional-content"
                                [hidden]="!detailedInfoCardIndex.includes('liked-candidates')">
                                <!-- <div class="col-md-1 text-center">
                                    <fa-icon class="info-icon float-none" [icon]="faInfo"></fa-icon>
                                </div> -->
                                <div class="col-md-12">
                                    <google-chart [data]="likedCandidateChart"></google-chart>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div ngxMasonryItem class="col-xl-4 col-lg-6 my-2 col-md-12"
                        *ngIf="dashboardData['passed-candidates'] && isAnalyticsSelected('passed-candidates')">
                        <div class="card">
                            <div class="row no-gutters">
                                <div class="col-md-2">
                                    <span class="marks-flag thumbs-down  m-flags">
                                        <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}" [icon]="faThumbsDown"
                                            size="lg"></fa-icon>
                                    </span>
                                </div>
                                <div class="col-md-9">
                                    <h3 class="dash-items">{{dashboardData['passed-candidates'].count}} Passed
                                        Candidates</h3>
                                </div>
                                <div class="col-md-1">
                                    <p *ngIf="!detailedInfoCardIndex.includes('passed-candidates')"><fa-icon
                                            class="info-icon" [icon]="faChartLine"
                                            (click)="showAdditionalInfo('passed-candidates')"></fa-icon></p>
                                    <p *ngIf="detailedInfoCardIndex.includes('passed-candidates')"><fa-icon
                                            class="info-icon" [icon]="faTimes"
                                            (click)="hideAdditionalInfo('passed-candidates')"></fa-icon></p>
                                    <!-- <p class="mt-5"><fa-icon class="info-icon" [icon]="faChartLine"></fa-icon></p> -->
                                </div>
                            </div>
                            <div class="row no-gutters additional-content"
                                *ngIf="detailedInfoCardIndex.includes('passed-candidates')">
                                <!-- <div class="col-md-1 text-center">
                                    <fa-icon class="info-icon float-none" [icon]="faInfo"></fa-icon>
                                </div> -->
                                <div class="col-md-12">
                                    <google-chart [data]="passedCandidateChart"></google-chart>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div ngxMasonryItem class="col-xl-4 col-lg-6 my-2 col-md-12"
                        *ngIf="dashboardData['open-position-data']['open-position'] && isAnalyticsSelected('open-position-data')">
                        <div class="card">
                            <div class="row no-gutters">
                                <div class="col-md-2">
                                    <fa-icon class="fa-icon" [icon]="faCalendar" size="lg"></fa-icon>
                                </div>
                                <div class="col-md-9">
                                    <h3 class="dash-items">
                                        {{dashboardData['open-position-data']['open-position'].length}} Open Positions
                                    </h3>
                                </div>
                                <div class="col-md-1">
                                    <p *ngIf="!detailedInfoCardIndex.includes('open-position')"><fa-icon
                                            class="info-icon" [icon]="faChartLine"
                                            (click)="showAdditionalInfo('open-position')"></fa-icon></p>
                                    <p *ngIf="detailedInfoCardIndex.includes('open-position')"><fa-icon
                                            class="info-icon" [icon]="faTimes"
                                            (click)="hideAdditionalInfo('open-position')"></fa-icon></p>
                                    <!-- <p class="mt-5"><fa-icon class="info-icon" [icon]="faChartLine"></fa-icon></p> -->
                                </div>
                            </div>
                            <div class="row no-gutters additional-content"
                                *ngIf="detailedInfoCardIndex.includes('open-position')">
                                <!-- <div class="col-md-1 text-center">
                                    <fa-icon class="info-icon float-none" [icon]="faInfo"></fa-icon>
                                </div> -->
                                <div class="col-md-12">
                                    <google-chart [data]="openPositionsChart"></google-chart>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div ngxMasonryItem class="col-xl-4 col-lg-6 my-2 col-md-12"
                        *ngIf="dashboardData['open-position-data']['drafted-position'] && isAnalyticsSelected('open-position-data')">
                        <div class="card">
                            <div class="row no-gutters">
                                <div class="col-md-2">
                                    <fa-icon class="fa-icon" [icon]="faEdit" size="lg"></fa-icon>
                                </div>
                                <div class="col-md-9">
                                    <h3 class="dash-items">
                                        {{dashboardData['open-position-data']['drafted-position'].length}} Drafted
                                        Positions</h3>
                                </div>
                                <div class="col-md-1">
                                    <p *ngIf="!detailedInfoCardIndex.includes('drafted-position')"><fa-icon
                                            class="info-icon" [icon]="faInfo"
                                            (click)="showAdditionalInfo('drafted-position')"></fa-icon></p>
                                    <p *ngIf="detailedInfoCardIndex.includes('drafted-position')"><fa-icon
                                            class="info-icon" [icon]="faTimes"
                                            (click)="hideAdditionalInfo('drafted-position')"></fa-icon></p>
                                    <!-- <p class="mt-5"><fa-icon class="info-icon" [icon]="faChartLine"></fa-icon></p> -->
                                </div>
                            </div>
                            <div class="row no-gutters additional-content"
                                *ngIf="detailedInfoCardIndex.includes('drafted-position')">
                                <!-- <div class="col-md-2">
                                    <fa-icon class="info-icon float-none" [icon]="faInfo"></fa-icon>
                                </div> -->
                                <div class="col-md-10 offset-md-2">
                                    <h4>No additional information available.</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div ngxMasonryItem class="col-xl-4 col-lg-6 my-2 col-md-12"
                        *ngIf="dashboardData['open-position-data']['archived-position'] && isAnalyticsSelected('open-position-data')">
                        <div class="card">
                            <div class="row no-gutters">
                                <div class="col-md-2">
                                    <fa-icon class="fa-icon" [icon]="faArchive" size="lg"></fa-icon>
                                </div>
                                <div class="col-md-9">
                                    <h3 class="dash-items">
                                        {{dashboardData['open-position-data']['archived-position'].length}} On Hold
                                        Positions</h3>
                                </div>
                                <div class="col-md-1">
                                    <p *ngIf="!detailedInfoCardIndex.includes('archived-position')"><fa-icon
                                            class="info-icon" [icon]="faInfo"
                                            (click)="showAdditionalInfo('archived-position')"></fa-icon></p>
                                    <p *ngIf="detailedInfoCardIndex.includes('archived-position')"><fa-icon
                                            class="info-icon" [icon]="faTimes"
                                            (click)="hideAdditionalInfo('archived-position')"></fa-icon></p>
                                    <!-- <p class="mt-5"><fa-icon class="info-icon" [icon]="faChartLine"></fa-icon></p> -->
                                </div>
                            </div>
                            <div class="row no-gutters additional-content"
                                *ngIf="detailedInfoCardIndex.includes('archived-position')">
                                <!-- <div class="col-md-2">
                                    <fa-icon class="info-icon float-none" [icon]="faInfo"></fa-icon>
                                </div> -->
                                <div class="col-md-10 offset-md-2">
                                    <h4>No additional information available.</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div ngxMasonryItem class="col-xl-4 col-lg-6 my-2 col-md-12"
                        *ngIf="dashboardData['interview-scheduled'] && isAnalyticsSelected('interview-scheduled')">
                        <div class="card">
                            <div class="row no-gutters">
                                <div class="col-md-2">
                                    <span class="marks-flag hold  m-flags">
                                        <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}"
                                            [icon]="faCalendar"></fa-icon>
                                    </span>
                                </div>
                                <div class="col-md-9">
                                    <h3 class="dash-items">{{dashboardData['interview-scheduled'].count}} Interviews
                                        Scheduled</h3>
                                </div>
                                <div class="col-md-1">
                                    <p *ngIf="!detailedInfoCardIndex.includes('interview-scheduled')"><fa-icon
                                            class="info-icon" [icon]="faChartLine"
                                            (click)="showAdditionalInfo('interview-scheduled')"></fa-icon></p>
                                    <p *ngIf="detailedInfoCardIndex.includes('interview-scheduled')"><fa-icon
                                            class="info-icon" [icon]="faTimes"
                                            (click)="hideAdditionalInfo('interview-scheduled')"></fa-icon></p>
                                    <!-- <p class="mt-5"><fa-icon class="info-icon" [icon]="faChartLine"></fa-icon></p> -->
                                </div>
                            </div>
                            <div class="row no-gutters additional-content"
                                *ngIf="detailedInfoCardIndex.includes('interview-scheduled')">
                                <!-- <div class="col-md-1 text-center">
                                    <fa-icon class="info-icon float-none" [icon]="faInfo"></fa-icon>
                                </div> -->
                                <div class="col-md-12">
                                    <google-chart [data]="interviewScheduledChart"></google-chart>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div ngxMasonryItem class="col-xl-4 col-lg-6 my-2 col-md-12"
                        *ngIf="dashboardData['interview-not-scheduled'] && isAnalyticsSelected('interview-not-scheduled')">
                        <div class="card">
                            <div class="row no-gutters">
                                <div class="col-md-2">
                                    <span class="marks-flag not-given  m-flags">
                                        <fa-icon [styles]="{'stroke': '#fff', 'color': '#fff'}"
                                            [icon]="faExclamation"></fa-icon>
                                    </span>
                                </div>
                                <div class="col-md-9">
                                    <h3 class="dash-items">{{dashboardData['interview-not-scheduled'].count}} Interviews
                                        Not Scheduled</h3>
                                </div>
                                <div class="col-md-1">
                                    <p *ngIf="!detailedInfoCardIndex.includes('interview-not-scheduled')"><fa-icon
                                            class="info-icon" [icon]="faInfo"
                                            (click)="showAdditionalInfo('interview-not-scheduled')"></fa-icon></p>
                                    <p *ngIf="detailedInfoCardIndex.includes('interview-not-scheduled')"><fa-icon
                                            class="info-icon" [icon]="faTimes"
                                            (click)="hideAdditionalInfo('interview-not-scheduled')"></fa-icon></p>
                                    <!-- <p class="mt-5"><fa-icon class="info-icon" [icon]="faChartLine"></fa-icon></p> -->
                                </div>
                            </div>
                            <div class="row no-gutters additional-content"
                                *ngIf="detailedInfoCardIndex.includes('interview-not-scheduled')">
                                <!-- <div class="col-md-2">
                                    <fa-icon class="info-icon float-none" [icon]="faInfo"></fa-icon>
                                </div> -->
                                <div class="col-md-10 offset-md-2">
                                    <h4>No additional information available.</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div ngxMasonryItem class="col-xl-4 col-lg-6 my-2 col-md-12"
                        *ngIf="dashboardData['offers-accepted'] && isAnalyticsSelected('offers-accepted')">
                        <div class="card">
                            <div class="row no-gutters">
                                <div class="col-md-2">
                                    <fa-icon class="fa-icon" [icon]="faHandsHelping" size="lg"></fa-icon>
                                </div>
                                <div class="col-md-9">
                                    <h3 class="dash-items">{{dashboardData['offers-accepted'].count}} Offers Accepted
                                    </h3>
                                </div>
                                <div class="col-md-1">
                                    <p *ngIf="!detailedInfoCardIndex.includes('offers-accepted')"><fa-icon
                                            class="info-icon" [icon]="faChartLine"
                                            (click)="showAdditionalInfo('offers-accepted')"></fa-icon></p>
                                    <p *ngIf="detailedInfoCardIndex.includes('offers-accepted')"><fa-icon
                                            class="info-icon" [icon]="faTimes"
                                            (click)="hideAdditionalInfo('offers-accepted')"></fa-icon></p>
                                    <!-- <p class="mt-5"><fa-icon class="info-icon" [icon]="faChartLine"></fa-icon></p> -->
                                </div>
                            </div>
                            <div class="row no-gutters additional-content"
                                *ngIf="detailedInfoCardIndex.includes('offers-accepted')">
                                <!-- <div class="col-md-1 text-center">
                                    <fa-icon class="info-icon float-none" [icon]="faInfo"></fa-icon>
                                </div> -->
                                <div class="col-md-12">
                                    <google-chart [data]="offerAcceptedChart"></google-chart>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div ngxMasonryItem class="col-xl-4 col-lg-6 my-2 col-md-12"
                        *ngIf="dashboardData['total-candidates'] && isAnalyticsSelected('total-candidates')">
                        <div class="card">
                            <div class="row no-gutters">
                                <div class="col-md-2">
                                    <fa-icon class="fa-icon" [icon]="faUsers" size="lg"></fa-icon>
                                </div>
                                <div class="col-md-9">
                                    <h3 class="dash-items">{{dashboardData['total-candidates'].count}} Total Candidates
                                    </h3>
                                </div>
                                <div class="col-md-1">
                                    <p *ngIf="!detailedInfoCardIndex.includes('total-candidates')"><fa-icon
                                            class="info-icon" [icon]="faChartLine"
                                            (click)="showAdditionalInfo('total-candidates')"></fa-icon></p>
                                    <p *ngIf="detailedInfoCardIndex.includes('total-candidates')"><fa-icon
                                            class="info-icon" [icon]="faTimes"
                                            (click)="hideAdditionalInfo('total-candidates')"></fa-icon></p>
                                    <!-- <p class="mt-5"><fa-icon class="info-icon" [icon]="faChartLine"></fa-icon></p> -->
                                </div>
                            </div>
                            <div class="row no-gutters additional-content"
                                *ngIf="detailedInfoCardIndex.includes('total-candidates')">
                                <!-- <div class="col-md-1 text-center">
                                    <fa-icon class="info-icon float-none" [icon]="faInfo"></fa-icon>
                                </div> -->
                                <div class="col-md-12">
                                    <google-chart [data]="totalCandidatesChart"></google-chart>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div ngxMasonryItem class="col-xl-4 col-lg-6 my-2 col-md-12"
                        *ngIf="dashboardData['total-hires-this-quater'] && isAnalyticsSelected('total-hires-this-quater')">
                        <div class="card">
                            <div class="row no-gutters">
                                <div class="col-md-2">
                                    <fa-icon class="fa-icon" [icon]="faIdCard" size="lg"></fa-icon>
                                </div>
                                <div class="col-md-9">
                                    <h3 class="dash-items">{{dashboardData['total-hires-this-quater'].count}} Hires This
                                        Quarter</h3>
                                </div>
                                <div class="col-md-1">
                                    <p *ngIf="!detailedInfoCardIndex.includes('total-hires-this-quater')"><fa-icon
                                            class="info-icon" [icon]="faChartLine"
                                            (click)="showAdditionalInfo('total-hires-this-quater')"></fa-icon></p>
                                    <p *ngIf="detailedInfoCardIndex.includes('total-hires-this-quater')"><fa-icon
                                            class="info-icon" [icon]="faTimes"
                                            (click)="hideAdditionalInfo('total-hires-this-quater')"></fa-icon></p>
                                    <!-- <p class="mt-5"><fa-icon class="info-icon" [icon]="faChartLine"></fa-icon></p> -->
                                </div>
                            </div>
                            <div class="row no-gutters additional-content"
                                *ngIf="detailedInfoCardIndex.includes('total-hires-this-quater')">
                                <!-- <div class="col-md-1 text-center">
                                    <fa-icon class="info-icon float-none" [icon]="faInfo"></fa-icon>
                                </div> -->
                                <div class="col-md-12">
                                    <google-chart [data]="totalHiresChart"></google-chart>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div ngxMasonryItem class="col-xl-4 col-lg-6 my-2 col-md-12"
                        *ngIf="dashboardData['active-clients'] && isAnalyticsSelected('active-clients')">
                        <div class="card">
                            <div class="row no-gutters">
                                <div class="col-md-2">
                                    <fa-icon class="fa-icon" [icon]="faShieldAlt" size="lg"></fa-icon>
                                </div>
                                <div class="col-md-9">
                                    <h3 class="dash-items">{{dashboardData['active-clients'].count}} Active Clients</h3>
                                </div>
                                <div class="col-md-1">
                                    <p *ngIf="!detailedInfoCardIndex.includes('active-clients')"><fa-icon
                                            class="info-icon" [icon]="faChartLine"
                                            (click)="showAdditionalInfo('active-clients')"></fa-icon></p>
                                    <p *ngIf="detailedInfoCardIndex.includes('active-clients')"><fa-icon
                                            class="info-icon" [icon]="faTimes"
                                            (click)="hideAdditionalInfo('active-clients')"></fa-icon></p>
                                    <!-- <p class="mt-5"><fa-icon class="info-icon" [icon]="faChartLine"></fa-icon></p> -->
                                </div>
                            </div>
                            <div class="row no-gutters additional-content"
                                *ngIf="detailedInfoCardIndex.includes('active-clients')">
                                <!-- <div class="col-md-1 text-center">
                                    <fa-icon class="info-icon float-none" [icon]="faInfo"></fa-icon>
                                </div> -->
                                <div class="col-md-12">
                                    <google-chart [data]="activeClientsChart"></google-chart>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div ngxMasonryItem class="col-xl-4 col-lg-6 my-2 col-md-12"
                        *ngIf="dashboardData['top-clients-with-open-position']?.data?.length && isAnalyticsSelected('top-clients-with-open-position')">
                        <div class="card">
                            <div class="row no-gutters">
                                <div class="col-md-2">
                                    <fa-icon class="fa-icon" [icon]="faShieldAlt" size="lg"></fa-icon>
                                </div>
                                <div class="col-md-9">
                                    <h3 class="dash-items">
                                        {{dashboardData['top-clients-with-open-position'].data[0].client_name}}</h3>
                                </div>
                                <div class="col-md-1">
                                    <p *ngIf="!detailedInfoCardIndex.includes('top-clients-with-open-position-1')">
                                        <fa-icon class="info-icon" [icon]="faInfo"
                                            (click)="showAdditionalInfo('top-clients-with-open-position-1')"></fa-icon>
                                    </p>
                                    <p *ngIf="detailedInfoCardIndex.includes('top-clients-with-open-position-1')">
                                        <fa-icon class="info-icon" [icon]="faTimes"
                                            (click)="hideAdditionalInfo('top-clients-with-open-position-1')"></fa-icon>
                                    </p>
                                    <!-- <p class="mt-5"><fa-icon class="info-icon" [icon]="faChartLine"></fa-icon></p> -->
                                </div>
                            </div>
                            <div class="row no-gutters additional-content"
                                *ngIf="detailedInfoCardIndex.includes('top-clients-with-open-position-1')">
                                <!-- <div class="col-md-2">
                                    <fa-icon class="info-icon float-none" [icon]="faInfo"></fa-icon>
                                </div> -->
                                <div class="col-md-10 offset-md-2">
                                    <h4>No additional information available.</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div ngxMasonryItem class="col-xl-4 col-lg-6 my-2 col-md-12"
                        *ngIf="dashboardData['top-clients-with-open-position']?.data?.length > 1 && isAnalyticsSelected('top-clients-with-open-position')">
                        <div class="card">
                            <div class="row no-gutters">
                                <div class="col-md-2">
                                    <fa-icon class="fa-icon" [icon]="faStar" size="lg"></fa-icon>
                                </div>
                                <div class="col-md-9">
                                    <h3 class="dash-items">
                                        {{dashboardData['top-clients-with-open-position'].data[1].client_name}}</h3>
                                </div>
                                <div class="col-md-1">
                                    <p *ngIf="!detailedInfoCardIndex.includes('top-clients-with-open-position-2')">
                                        <fa-icon class="info-icon" [icon]="faInfo"
                                            (click)="showAdditionalInfo('top-clients-with-open-position-2')"></fa-icon>
                                    </p>
                                    <p *ngIf="detailedInfoCardIndex.includes('top-clients-with-open-position-2')">
                                        <fa-icon class="info-icon" [icon]="faTimes"
                                            (click)="hideAdditionalInfo('top-clients-with-open-position-2')"></fa-icon>
                                    </p>
                                    <!-- <p class="mt-5"><fa-icon class="info-icon" [icon]="faChartLine"></fa-icon></p> -->
                                </div>
                            </div>
                            <div class="row no-gutters additional-content"
                                *ngIf="detailedInfoCardIndex.includes('top-clients-with-open-position-2')">
                                <!-- <div class="col-md-2">
                                    <fa-icon class="info-icon float-none" [icon]="faInfo"></fa-icon>
                                </div> -->
                                <div class="col-md-10 offset-md-2">
                                    <h4>No additional information available.</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div ngxMasonryItem class="col-xl-4 col-lg-6 my-2 col-md-12"
                        *ngIf="dashboardData['top-clients-with-hires']?.length > 1 && isAnalyticsSelected('top-clients-with-hires')">
                        <div class="card">
                            <div class="row no-gutters">
                                <div class="col-md-2">
                                    <fa-icon class="fa-icon" [icon]="faStar" size="lg"></fa-icon>
                                </div>
                                <div class="col-md-9">
                                    <h3 class="dash-items">{{dashboardData['top-clients-with-hires'][0]['client-name']}}
                                    </h3>
                                </div>
                                <div class="col-md-1">
                                    <p *ngIf="!detailedInfoCardIndex.includes('top-clients-with-hires')"><fa-icon
                                            class="info-icon" [icon]="faInfo"
                                            (click)="showAdditionalInfo('top-clients-with-hires')"></fa-icon></p>
                                    <p *ngIf="detailedInfoCardIndex.includes('top-clients-with-hires')"><fa-icon
                                            class="info-icon" [icon]="faTimes"
                                            (click)="hideAdditionalInfo('top-clients-with-hires')"></fa-icon></p>
                                    <!-- <p class="mt-5"><fa-icon class="info-icon" [icon]="faChartLine"></fa-icon></p> -->
                                </div>
                            </div>
                            <div class="row no-gutters additional-content"
                                *ngIf="detailedInfoCardIndex.includes('top-clients-with-hires')">
                                <!-- <div class="col-md-2">
                                    <fa-icon class="info-icon float-none" [icon]="faInfo"></fa-icon>
                                </div> -->
                                <div class="col-md-10 offset-md-2">
                                    <h4>No additional information available.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-masonry>
            </div>
            <div *ngIf="this.currentUserRoleCode != '/htm' && this.currentUserRoleCode != '/ca' && this.currentUserRoleCode != '/sa' && this.currentUserRoleCode != '/hm' && this.currentUserRoleCode != '/am' && this.currentUserRoleCode != '/sm' && this.currentUserRoleCode != '/hr'"
                class="widget-box dashboard-area">
                Dashboard will be implemented later for {{currentUserRoleCode}}
            </div>
        </div>
    </div>
    <ng-container *ngIf="this.currentUserRoleCode === '/can' && candidateData">
        <div class="col-md-4">
            <div class="col-md-12 mt-2" *ngIf="!selectedPositionDetail">
                <h2 class="widget-title mb-2">Action Items</h2>
                <div class="row no-gutters">
                    <div class="tile-card card col-md-12">
                        <div class="d-inline-block" *ngFor="let item of remainingInterviews">
                            <fa-icon class="pending-action" [icon]="faExclamationCircle"></fa-icon>
                            <span *ngIf="!item.missed">Start Interview for: <b>{{item.position_name}}</b> with
                                {{item.interviewers || 'NA'}}
                                <!-- <a href="item.link" target="_blank"> <button class="btn save-button ml-2"> Start Interview</button></a> -->
                                <button class="btn save-button ml-2" (click)="startInterview(item.link)"> Start
                                    Interview</button>
                            </span>
                            <span *ngIf="item.missed">You missed Interview for: <b>{{item.position_name}}</b> with
                                {{item.interviewers || 'NA'}}

                            </span>
                            <br>
                        </div>
                        <br>
                        <div class="d-inline-block" *ngFor="let item of remainingPositions">
                            <fa-icon class="pending-action" [icon]="faExclamationCircle"></fa-icon>
                            <span>Approve Position <b>{{item.position_name}}</b>
                                <div class="btn-group ml-2">
                                    <input mdbRadio type="radio" class="btn-response" name="options" id="option1"
                                        autocomplete="off" (click)="approveOpenPosition(item.position_id)" />
                                    <label class="btn btn-secondary btn-green" for="option1">Approve</label>
                                    <input mdbRadio type="radio" class="btn-response" name="options" id="option2"
                                        autocomplete="off" (click)="rejectOpenPosition(item.position_id)" />
                                    <label class="btn btn-secondary btn-red" for="option2">Reject</label>
                                </div>
                            </span>
                        </div>
                        <div class="d-inline-block"
                            *ngIf="remainingInterviews?.length === 0 && remainingPositions?.length === 0">
                            No action item available.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-2" [formGroup]="candidateForm">
                <h2 class="widget-title">Your Profile</h2>
                <div class="prof-pic-wrapper">
                    <img *ngIf="imageURL && imageURL !== ''" [src]="imageURL" [alt]="candidateForm.value.name"
                        class="prof-pic">
                    <img *ngIf="!imageURL && candidateData?.profile_photo || (!candidateData?.profile_photo && !candidateData?.profile_pic_url)"
                        class="prof-pic"
                        src="{{candidateData?.profile_photo && candidateData?.profile_photo !== 'None' ? imageBaseUrl + candidateData?.profile_photo : './../../assets/images/big-default-avatar.png'}}"
                        alt="Profile photo" />
                    <img *ngIf="!imageURL && candidateData?.profile_pic_url" class="prof-pic"
                        [src]="candidateData?.profile_pic_url" alt="Profile photo" />
                    <p><b>{{candidateData.name || ''}} {{candidateData.last_name || ''}}</b></p>
                    <p>{{candidateData.job_title || 'N/A'}}</p>
                    <p>{{candidateData.location || 'N/A'}}</p>
                </div>
                <ng-container *ngIf="!selectedPositionDetail">
                    <input #fileInput *ngIf="isCandidateBackgroundEditMode" type="file" class="form-control-file"
                        (change)="showPreview($event)">
                    <input type="hidden" name="fileHidden" formControlName="profile_photo" />
                    <div class="d-block mt-4 background-info">
                        <h6 style="margin-bottom: 4px;">Background
                            <fa-icon style="float:right; cursor: pointer;" *ngIf="!isCandidateBackgroundEditMode"
                                (click)="toggleBackgroundEdit()" [icon]="faPencilAlt"></fa-icon>
                            <!-- <fa-icon style="float:right; cursor: pointer;" *ngIf="isCandidateBackgroundEditMode" (click)="toggleBackgroundEdit()" [icon]="faSave"></fa-icon> -->
                        </h6>
                        <hr style="border-top: 2px solid rgb(18 0 0 / 72%);" class="m-0 mb-4" />
                        <p *ngIf="!isCandidateBackgroundEditMode"><b>{{candidateData.name || ''}}
                                {{candidateData.last_name || ''}}</b></p>
                        <p *ngIf="isCandidateBackgroundEditMode"><b>First Name: </b>
                            <input *ngIf="isCandidateBackgroundEditMode" formControlName="name" type="text"
                                class="form-control" />
                        </p>
                        <p *ngIf="isCandidateBackgroundEditMode"><b>Last Name: </b>
                            <input *ngIf="isCandidateBackgroundEditMode" formControlName="last_name" type="text"
                                class="form-control" />
                        </p>
                        <p>Nickname:
                            <span *ngIf="!isCandidateBackgroundEditMode">{{candidateData.nickname || 'Not
                                Specified'}}</span>
                            <input *ngIf="isCandidateBackgroundEditMode" formControlName="nickname" type="text"
                                class="form-control" />
                        </p>
                        <p>Current role:

                            <span *ngIf="!isCandidateBackgroundEditMode">{{candidateData.job_title || 'Not
                                Specified'}}</span>
                            <input *ngIf="isCandidateBackgroundEditMode" formControlName="job_title" type="text"
                                class="form-control" />
                        </p>
                        <p>Work Authorization:
                            <span *ngIf="!isCandidateBackgroundEditMode">{{candidateData.work_auth || 'Not
                                Specified'}}</span>
                            <input *ngIf="isCandidateBackgroundEditMode" formControlName="work_auth" type="text"
                                class="form-control" />
                        </p>

                        <p>Salary Requirements:
                            <span *ngIf="!isCandidateBackgroundEditMode">{{getCandidateSalary(candidateData.currency,
                                candidateData.salaryRange) || 'Not Specified'}}</span>
                            <ng-container *ngIf="isCandidateBackgroundEditMode">
                                <p>Currency:
                                    <select style="width: 80px;height: 40px;" formControlName="currency">
                                        <option value="$">$</option>
                                        <option value="€">€</option>
                                        <option value="£">£</option>
                                        <option value="¥">¥</option>
                                        <option value="₣">₣</option>
                                        <option value="₹">₹</option>
                                    </select>
                                </p>
                                <p>Minimum: <input type="text" [(ngModel)]="minValue"
                                        [ngModelOptions]="{standalone: true}"></p>
                                <p>Maximum: <input type="text" [(ngModel)]="maxValue"
                                        [ngModelOptions]="{standalone: true}"></p>
                                <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"
                                    formControlName="salaryRange"></ngx-slider>
                            </ng-container>
                        </p>
                        <p>Desired Work Location:
                            <span *ngIf="!isCandidateBackgroundEditMode">{{candidateData.desired_work_location || 'Not
                                Specified'}}</span>
                            <input *ngIf="isCandidateBackgroundEditMode" formControlName="desired_work_location"
                                type="text" class="form-control" />
                        </p>
                        <p>
                            <fa-icon [icon]="faEnvelope" class="pr-2"></fa-icon>
                            <span *ngIf="!isCandidateBackgroundEditMode">{{candidateData.email || 'Not
                                Specified'}}</span>
                            <input *ngIf="isCandidateBackgroundEditMode" formControlName="email" type="text"
                                class="form-control" />
                        </p>
                        <p>
                            <fa-icon [icon]="faPhoneAlt" class="pr-2"></fa-icon>
                            <span *ngIf="!isCandidateBackgroundEditMode">{{candidateData.phone_number || 'Not
                                Specified'}}</span>
                            <input *ngIf="isCandidateBackgroundEditMode" formControlName="phone_number" type="text"
                                class="form-control" />
                        </p>
                        <p>Location:
                            <span *ngIf="!isCandidateBackgroundEditMode">{{candidateData.location || 'Not
                                Specified'}}</span>
                            <input *ngIf="isCandidateBackgroundEditMode" formControlName="location" type="text"
                                class="form-control" />
                        </p>
                        <p>
                            <img src="./../../assets/images/linkedin.svg" style="height: 30px;" class="pr-2" />
                            <a *ngIf="candidateData.skype_id && candidateData.skype_id !== 'null' && !isCandidateBackgroundEditMode"
                                [href]="candidateData.skype_id" target="_blank">LinkedIn Profile</a>
                            <span
                                *ngIf="(!candidateData.skype_id || candidateData.skype_id === 'null')&& !isCandidateBackgroundEditMode">No
                                LinkedIn details provided</span>
                            <input *ngIf="isCandidateBackgroundEditMode" formControlName="skype_id" type="text"
                                class="form-control" />
                        </p>
                        <div class="tile-card" style="word-wrap: break-word;">
                            <p>
                                <fa-icon [icon]="faFile"></fa-icon>
                                Resume:
                                <fa-icon *ngIf="candidateData.documents?.length" [icon]="faDownload"
                                    (click)="toggleResume = !toggleResume;" class="cursor-pointer"></fa-icon>

                            </p>
                            <div *ngIf="isCandidateBackgroundEditMode">
                                <input type="file" (change)="onFileChange($event)" formControlName="documents"
                                    multiple="multiple" class="form-control-file" />
                                <ul>
                                    <li *ngFor="let doc of documentLinks">
                                        <a [href]="imgBaseUrl + doc.url" target="_blank">{{decodeUrl(doc.name)}}</a>
                                        <a class="ml-4" href="javascript:void(0)" (click)="deleteDoc(doc.url)">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div
                                *ngIf="candidateData.documents?.length && toggleResume && !isCandidateBackgroundEditMode">
                                <a *ngFor="let doc of candidateData.documents" [href]="imageBaseUrl + doc.url"
                                    target="_blank" class="cursor-pointer d-block">{{decodeUrl(doc.name)}}</a>
                            </div>
                            <p>
                                <fa-icon [icon]="faFile"></fa-icon>
                                References:
                                <fa-icon *ngIf="candidateData.references?.length" [icon]="faDownload"
                                    (click)="toggleReference = !toggleReference;" class="cursor-pointer"></fa-icon>
                            </p>

                            <div *ngIf="isCandidateBackgroundEditMode">
                                <input type="file" (change)="onReferenceChange($event)" formControlName="references"
                                    multiple="multiple" class="form-control-file">
                                <ul>
                                    <li *ngFor="let doc of referenceLinks">
                                        <a [href]="imgBaseUrl + doc.url" target="_blank">{{decodeUrl(doc.name)}}</a>
                                        <a class="ml-4" href="javascript:void(0)" (click)="deleteRef(doc.url)">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div
                                *ngIf="candidateData.references?.length && toggleReference && !isCandidateBackgroundEditMode">
                                <a *ngFor="let doc of candidateData.references" [href]="imageBaseUrl + doc.url"
                                    target="_blank" class="cursor-pointer d-block">{{decodeUrl(doc.name)}}</a>
                            </div>
                        </div>
                        <p>Personal website:
                            <span *ngIf="!isCandidateBackgroundEditMode">{{candidateData.personal_notes || 'Not
                                Specified'}}</span>
                            <input *ngIf="isCandidateBackgroundEditMode" formControlName="personal_notes" type="text"
                                class="form-control" />
                        </p>
                        <!-- <p>Additional Information: 
                            <span *ngIf="!isCandidateBackgroundEditMode">{{candidateData.additional_info || 'Not Specified'}}</span>
                            <textarea *ngIf="isCandidateBackgroundEditMode" formControlName="additional_info" type="text" class="form-control" ></textarea>
                        </p> -->

                        <button *ngIf="isCandidateBackgroundEditMode" class="btn btn-primary float-right mt-3"
                           >Save</button>
                        <button *ngIf="isCandidateBackgroundEditMode" class="btn btn-secondary float-right mt-3 mr-3"
                            (click)="cancelCandidateUpdate()">Cancel</button>
                    </div>
                </ng-container>
                <ng-container *ngIf="selectedPositionDetail && candidatePositionSpecificData">
                    <div class="d-block mt-4 background-info">
                        <!-- <h6 style="margin-bottom: 4px;">Candidate's data for selected position</h6> -->
                        <hr style="border-top: 2px solid rgb(18 0 0 / 72%);" class="m-0 mb-4" />
                        <p><b>Nickname: </b>{{candidatePositionSpecificData.nickname || 'N/A'}}</p>
                        <p><b>Location: </b>{{candidatePositionSpecificData.location || 'N/A'}}</p>
                        <p><b>Currently: </b>{{candidatePositionSpecificData.currently || 'N/A'}}</p>
                        <p><b>Woth Auth: </b>{{candidatePositionSpecificData.work_auth || 'N/A'}}</p>
                        <p><b>Salary Requirements: </b>{{getCandidateSalary(candidatePositionSpecificData.currency,
                            candidatePositionSpecificData.salary_req) || 'Not Specified'}}</p>
                        <p><b>Desired Work Location: </b>
                            {{candidatePositionSpecificData.remote_only ? 'Remote Only | ' : ''}}
                            {{candidatePositionSpecificData.remote_pref ? 'Remote Preferred | ' : ''}}
                            {{candidatePositionSpecificData.some_in_office ? 'Some in-office | ' : ''}}
                            {{candidatePositionSpecificData.office_only ? 'Office only | ' : ''}}
                        </p>
                        <p>
                            <fa-icon [icon]="faEnvelope" class="pr-2"></fa-icon>
                            <span>{{candidatePositionSpecificData.email || 'Not Specified'}}</span>
                        </p>
                        <p>
                            <fa-icon [icon]="faPhoneAlt" class="pr-2"></fa-icon>
                            <span>{{candidatePositionSpecificData.phone || 'Not Specified'}}</span>
                        </p>
                        <p>
                            <img src="./../../assets/images/linkedin.svg"
                                style="height: 30px;background: #fff; margin-right: 4px;" />
                            <span>{{candidatePositionSpecificData.linkedin || 'Not Specified'}}</span>
                        </p>
                        <div class="tile-card" style="word-wrap: break-word;">
                            <p>
                                <fa-icon [icon]="faFile"></fa-icon>
                                Resume:
                                <fa-icon *ngIf="candidatePositionSpecificData.resume?.length" [icon]="faDownload"
                                    (click)="toggleResume = !toggleResume;" class="cursor-pointer"></fa-icon>
                                <span *ngIf="!candidatePositionSpecificData.resume?.length">No resume attached</span>
                            </p>
                            <div *ngIf="candidatePositionSpecificData.resume?.length && toggleResume">
                                <a *ngFor="let doc of candidatePositionSpecificData.resume"
                                    [href]="imageBaseUrl + doc.url" target="_blank"
                                    class="cursor-pointer d-block">{{decodeUrl(doc.name)}}</a>
                            </div>

                            <p>
                                <fa-icon [icon]="faFile"></fa-icon>
                                References:
                                <fa-icon *ngIf="candidatePositionSpecificData.references?.length" [icon]="faDownload"
                                    (click)="toggleReference = !toggleReference;" class="cursor-pointer"></fa-icon>
                                <span *ngIf="!candidatePositionSpecificData.references?.length">No reference
                                    attached</span>
                            </p>
                            <div *ngIf="candidatePositionSpecificData.references?.length && toggleReference">
                                <a *ngFor="let doc of candidatePositionSpecificData.references"
                                    [href]="imageBaseUrl + doc.url" target="_blank"
                                    class="cursor-pointer d-block">{{decodeUrl(doc.name)}}</a>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="col-md-4" *ngIf="!selectedPositionDetail">
            <h2 class="widget-title mb-2">Your LinkedIn Summary
                <a *ngIf="candidateData.skype_id" class="sync" href="javascript:void(0)"
                    (click)="fetchProfileImage()">Sync</a>
            </h2>
            <div class="prof-pic-wrapper">
                <div class="d-inline">
                    <img *ngIf="linkedInData?.profile_photo || (!linkedInData?.profile_photo && !linkedInData?.profile_pic_url)"
                        class="prof-pic"
                        src="{{linkedInData?.profile_photo && linkedInData?.profile_photo !== 'None' ? imageBaseUrl + linkedInData?.profile_photo : './../../assets/images/big-default-avatar.png'}}"
                        alt="Profile photo" />
                    <img *ngIf="candidateData?.profile_pic_url" class="prof-pic" [src]="candidateData?.profile_pic_url"
                        alt="Profile photo" />
                    <p><b>{{candidateData.name || ''}} {{candidateData.last_name || ''}}</b></p>
                    <p>Job Title: {{candidateData.job_title || 'N/A'}}</p>
                    <p>Location: {{candidateData.location || 'N/A'}}</p>
                    <p></p>
                </div>
            </div>
            <div class="work-details">
                <p><b>About</b></p>
                <p>
                    {{linkedInData?.about || candidateData?.about || 'Not Mentioned'}}
                </p>

                <p><b>Experience</b></p>
                <div class="row experience-container" *ngIf="linkedInData?.experiences?.length">
                    <div class="experience mb-4 col-12" *ngFor="let exp of linkedInData?.experiences">
                        <img [src]="exp?.logo_url" alt="Company logo" style="float: left;margin-right: 12px" />
                        <div class="exp-details">
                            <p><a [href]="exp.company_linkedin_profile_url" target="_blank">{{exp.company}}</a></p>
                            <p>
                                From {{exp?.starts_at?.month + '/' + exp?.starts_at?.day + '/' + exp?.starts_at?.year}}
                                -
                                <ng-container *ngIf="exp?.ends_at?.year">
                                    To {{exp?.ends_at?.month + '/' + exp?.ends_at?.day + '/' + exp?.ends_at?.year}}
                                </ng-container>
                                <ng-container *ngIf="!exp?.ends_at?.year">
                                    To Present
                                </ng-container>
                            </p>
                            <p>{{exp?.title}}</p>
                            <p>{{exp?.description}}</p>
                        </div>
                    </div>
                    <div *ngIf="!linkedInData || !linkedInData?.experiences">
                        Not Specified
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="selectedPositionDetail">
            <h2 class="widget-title mb-2">Open Position Details</h2>
            <div class="widget-box">
                <div class="widget-inner-box p-3">
                    <div class="prof-pic-wrapper m-0" style="max-width: 100px;" *ngIf="!!activeClientDetails">
                        <img *ngIf="activeClientDetails?.clientLogo && activeClientDetails?.clientLogo !== ''"
                            [src]="imgBaseUrl + activeClientDetails?.clientLogo" [alt]="activeClientDetails?.clientName"
                            class="prof-pic">
                        <p><b>Client Name: </b>{{activeClientDetails?.clientName}}</p>
                    </div>
                    <h5 class="my-3">{{selectedPositionDetail.position_title}}</h5>
                    <!-- <p class="m-0">Salary Requirements: {{getCandidateSalary(candidateData.currency, selectedPositionDetail.salary_range) || 'Not Specified'}}</p> -->
                    <p class="m-0">Salary Requirements: {{selectedPositionDetail.salary_range ? candidateData.currency
                        || '$' : ''}}{{selectedPositionDetail.salary_range || 'Not Specified'}}</p>
                    <p class="m-0">Location: {{selectedPositionDetail.local_preference || 'Not Specified'}}</p>
                    <p class="m-0">Target Deadline Date: {{selectedPositionDetail.target_deadline ?
                        formatDate(selectedPositionDetail.target_deadline) : 'Not Specified'}}</p>
                    <h6 class="my-3"><u>Required Skillsets:</u></h6>
                    <ng-container *ngFor="let item of [].constructor(8); let i = index">
                        <ng-container *ngIf="selectedPositionDetail['init_qualify_ques_'+(i+1)]">
                            <p class="m-0">{{selectedPositionDetail['init_qualify_ques_'+(i+1)]}}</p>
                        </ng-container>
                    </ng-container>

                    <h6 class="my-3"><u>Hiring Review Team Members:</u></h6>
                    <div class="htm-lists row">
                        <div class="htm-cont col-sm-12 mb-3"
                            *ngFor="let htm of selectedPositionDetail.members; let i = index;">
                            <div class="prof-pic-wrapper m-0" style="max-width: 100px;">
                                <!-- <img *ngIf="imageURL && imageURL !== ''" [src]="imageURL" [alt]="htm.name" class="prof-pic"> -->
                                <img *ngIf="htm?.profile_pic || (!htm?.profile_pic && !htm?.profile_pic)"
                                    class="prof-pic"
                                    src="{{htm?.profile_pic && htm?.profile_pic !== 'None' ? imageBaseUrl + htm?.profile_pic : './../../assets/images/big-default-avatar.png'}}"
                                    alt="Profile photo" />
                                <img *ngIf="htm?.profile_pic_url" class="prof-pic" [src]="htm?.profile_pic_url"
                                    alt="Profile photo" />
                            </div>
                            <div class="htm-details">
                                <p><b>{{htm.name || ''}} {{htm.last_name || ''}}<span *ngIf="htm.isHod">(HM)</span></b>
                                </p>
                                <p class="grey-text">Job Title: {{htm.job_title || 'N/A'}}</p>
                                <p class="grey-text">Phone: {{!this.scheduledInterviews?.length ? htm.masked_phone :
                                    htm.phone || 'N/A'}}</p>
                                <p class="grey-text">Email: {{!this.scheduledInterviews?.length ? htm.masked_email :
                                    htm.email || 'N/A'}}</p>
                                <p class="grey-text">linkedin: {{htm.skype || 'N/A'}}</p>
                                <div class="grey-text">Role(s):
                                    <ul>
                                        <li *ngFor="let role of htm.roles"> {{role === 'is_htm' ? 'Hiring Team Member' :
                                            role === 'is_sm' ? 'Senior Manager' : role === 'is_ca' ? 'Client Admin' :
                                            ''}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-md-4">
            <h2 class="widget-title mb-2">Your Scheduled Appointments</h2>
            <app-candidate-schedule *ngIf="calendarData" [calendarData]="calendarData"></app-candidate-schedule>
            <div class="approve-position-wrapper mt-3" *ngIf="isPendingCandidateApproval">
                <!-- <h4>Approve/Reject this position:</h4>
                <div class="btn-group">
                    <input
                      mdbRadio
                      type="radio"
                      class="btn-response"
                      name="options"
                      id="option1"
                      autocomplete="off"
                      (click)="approveOpenPosition()"
                    />
                    <label class="btn btn-secondary btn-green" for="option1">Approve</label>
                    <input
                      mdbRadio
                      type="radio"
                      class="btn-response"
                      name="options"
                      id="option2"
                      autocomplete="off"
                      (click)="rejectOpenPosition()"
                    />
                    <label class="btn btn-secondary btn-red" for="option2">Reject</label>
                  </div> -->
            </div>
        </div>
    </ng-container>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Send Mail</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="mailForm">
            <div class="form-group row">
                <label for="input-jobtitle" class="col-sm-3 col-form-label">To <small>*</small></label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="email">
                    <div *ngIf="mailForm.controls['email'].invalid && (mailForm.controls['email'].dirty || mailForm.controls['email'].touched)"
                        class="alert">
                        <div *ngIf="mailForm.controls['email'].errors?.required">
                            Email is required.
                        </div>
                        <div *ngIf="mailForm.controls['email'].errors?.pattern">
                            Invalid Email, please try again.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="input-jobtitle" class="col-sm-3 col-form-label">Subject <small>*</small></label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="subject">
                    <div *ngIf="mailForm.controls['subject'].invalid && (mailForm.controls['subject'].dirty || mailForm.controls['subject'].touched)"
                        class="alert">
                        <div *ngIf="mailForm.controls['subject'].errors?.required">
                            Subject is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="input-jobtitle" class="col-sm-3 col-form-label">Body</label>
                <div class="col-sm-9">
                    <textarea class="form-control" formControlName="message"></textarea>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="mailForm.invalid" (click)="sentMail()">Send</button>
    </div>
</ng-template>