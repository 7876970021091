import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    if (!value) {
      return value;
    }
    // Create a new Date object from the input string
    var date = new Date(value);
    // Define months array to convert numeric month to its name
    var months = [
      "January", "February", "March", "April",
      "May", "June", "July", "August",
      "September", "October", "November", "December"
    ];
    // Extract day, month, and year from the Date object
    var day: any = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    var month = months[date.getMonth()];
    var year = date.getFullYear();
    // Create the formatted date string
    var formattedDate = month +' '+ day + ', ' + year;
    return formattedDate;
  }
}
