import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { Subscription } from 'rxjs';
import WonderPush from 'wonderpush-cordova-sdk';

import { AuthService } from './services/auth.services';
import { LoadingService } from './services/loading.service';
import { CustomAdapter } from './services/ngb.datepicker.adapter';
import { CustomDateParserFormatter } from './services/ngb.datepicker.parser';
import { UserService } from './services/user.service';
import { WebSocketService } from './services/websocket.service';
import { ToastService } from './core/toast/toast-service';
import { NavigatorComponent } from './core/navigator/navigator.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(NavigatorComponent) navigatorComponent!: NavigatorComponent;
  public loginSub!: Subscription;
  public isUserLogin: boolean = false;
  public payment_intent: any;
  isNavigator: boolean = false;
  timeoutId: any;
  constructor(
    public authService: AuthService,
    private router: Router,
    private userService: UserService,
    private websocketService: WebSocketService,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    public loadingService: LoadingService) {
    this.authService.getIsDarkMode().subscribe(res => {
      setTimeout(() => {
        let data: any = localStorage.getItem('currentUser');
        const currentUser = JSON.parse(data);
        if (res || currentUser?.dark_mode) {
          document.getElementsByTagName('body')[0].classList.add('dark-theme');
        } else {
          document.getElementsByTagName('body')[0].classList.remove('dark-theme');
        }
      }, 1000);
    });
    this.authService.getNavMode().subscribe(res => {
      this.isNavigator = res;
    })
    // this.router.events.subscribe((event: any) => {
    //   console.log(event);
    //   if (event instanceof NavigationEnd) {
    //     this.userService.connectZohoChatbot();
    //   }
    // });
  }

  ngOnInit(): void {
    if (window.innerWidth < 1024) {
      document.body.innerHTML = '<h1>This application is not supported on mobile devices or screens smaller than 1024px.</h1>';
    }
    localStorage.setItem('isQJobBoardEnabled', 'false')  //to enable or disbale job baord true is enable and false disable
    this.loginSub = this.authService.isUserLogin$.subscribe(val => {
      if (val) {
        this.isUserLogin = val
      }
    });
    let data: any = localStorage.getItem('currentUser');
    let loginData = JSON.parse(data);
    if (loginData?.token) {
      if (this.authService.currentUserValue.role === 'Hiring Manager') {
        return;
      }
      if (this.authService.currentUserValue.role === 'Hiring Member') {
        return;
      }
      if (this.authService.currentUserValue.role === 'Client Admin') {
        if (!loginData?.payment_done) {
          var y = window.location.href;
          if (y.includes("thankyou")) {
            let ind1 = y.indexOf("=");
            let ind2 = y.indexOf("&")
            let result = y.slice(ind1 + 1, ind2);
            let data: any = {}
            data.payment_intent = result;
            this.userService.confPackPayment(data).subscribe((val: any) => {
              console.log(val)
              if (val.status == 'updated') {
                this.showSuccess('Payment Succesfull')
                this.router.navigate([this.authService.currentUserRoleCode + '/position/clientPosition']);
                let userdata: any = localStorage.getItem('currentUser');
                userdata = JSON.parse(userdata);
                userdata.payment_done = true;
                //const st = true;
                localStorage.setItem('currentUser', JSON.stringify(userdata));
              }
              else if (val.status == 'processing') {
                this.showSuccess('Payment processing....')
                // this.router.navigate(['/first-login']);
                this.router.navigate(['/signup']);
              }
              else if (val.status == 'payment failed') {
                this.showDanger('Payment Failed')
                // this.router.navigate(['/first-login']);
                this.router.navigate(['/signup']);
              }
              else {
                this.showDanger('Something went wrong')
                // this.router.navigate(['/first-login']);
                this.router.navigate(['/signup']);
              }
            })
          }
          else {
            // this.router.navigate([this.authService.currentUserRoleCode + '/dashboard/first-login']);
            //  this.router.navigate([this.authService.currentUserRoleCode + '/position/clientPosition']);
            // this.router.navigate(['/first-login']);
            this.router.navigate(['/signup']);
          }
        } else {
          var y = window.location.href;
          if (y.includes("thankyou")) {
            let ind1 = y.indexOf("=");
            let ind2 = y.indexOf("&")
            let result = y.slice(ind1 + 1, ind2);
            let data: any = {}
            data.payment_intent = result;
            this.userService.confPackPayment(data).subscribe((val: any) => {
              console.log(val)
              if (val.status == 'updated') {
                this.showSuccess('Payment Succesfull')
              }
              else if (val.status == 'processing') {
                this.showSuccess('Payment processing....')
              }
              else if (val.status == 'payment failed') {
                this.showDanger('Payment Failed')
              }
              else {
                this.showDanger('Something went wrong')
              }
            })
          }

          //this.router.navigate([this.authService.currentUserRoleCode + '/position/clientPosition']);
        }
      } else {
        // this.router.navigate([this.authService.currentUserRoleCode + '/dashboard']);
      }
    }
    this.websocketService.connect();
    WonderPush.subscribeToNotifications();
  }
  ngAfterViewInit(): void {
    let data: any = localStorage.getItem('currentUser');
    let loginData = JSON.parse(data);
    if (loginData?.token) {
      let nav = localStorage.getItem('isNavigator');
      this.isNavigator = (nav === 'true')
      if (this.authService.currentUserValue.role !== 'Superuser' && this.authService.currentUserValue.role !== 'Candidate') {
        if (this.isNavigator) {
          this.openNavigator()
        }
        this.startNavigation();
      }
    }
  }
  startNavigation() {
    if (this.isNavigator) {
      this.timeoutId = setInterval(() => {
        this.openNavigator();
      }, 300000);
    }
    // 300000
  }
  openNavigator() {
    if (this.isNavigator) {
      this.navigatorComponent?.closeNavigatorModal();
      this.navigatorComponent?.getNavigatorList();
      this.navigatorComponent?.getNavigatorStep();
      this.navigatorComponent?.openNavigatorModal();
    }
  }
  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }
  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }
  ngOnDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.loginSub.unsubscribe();
  }
}


