<main id="landingpage">
    <app-header></app-header>
    <div class="widget-wrapper">
        <img src="./../../../assets/images/quorums-logo.png" class="logo" />
        <div class="container">
            <div class="row">
                <div class="col-md-4 account-div">
                    <div class="features-div">
                        <h5>Entry Level Account</h5>
                        <p>Online support and onboarding</p>
                        <p>5 Hiring Manager Accounts</p>
                        <p>15 Simultaneous Open Hiring Positions</p>
                        <p>10 Stored and reusable Open Positions</p>
                        <p>HR Administration Account</p>
                        <p>50 Hiring Team Members</p>
                        <p>15 Hiring Teams</p>
                        <p>On demand access to recruitment professionals</p>
                        <p>Linking to any of 30 Applicant Tracking Systems (Greenhouse, etc.)</p>
                        <p>Capability to add candidates from your ATS</p>
                        <h6 class="coming-soon">Coming soon</h6>
                        <p>Daily import of your career opportunities from your website</p>
                        <p>Integral Video Conferencing</p>
                        <p>10 Deep Dive Candidate Reports per month</p>
                        <p>Create your own template hiring schedules</p>
                        <p>Alert when candidate is interviewing elsewhere</p>
                    </div>
                </div>

                <div class="col-md-4 account-div">
                    <div class="features-div">
                        <h5>Corporate Account</h5>
                        <p>Online support and onboarding</p>
                        <p>Unlimited Hiring Manager Accounts</p>
                        <p>Unlimited simultaneous Open Hiring Positions</p>
                        <p>Unlimited Stored and reusable Open Positions</p>
                        <p>HR Administration Account</p>
                        <p>Unlimited Hiring Team Members</p>
                        <p>Unlimited Hiring Teams</p>
                        <p>On demand access to recruitment professionals</p>
                        <p>Create your own template hiring schedules</p>
                        <p>Linking to any of 30 Applicant Tracking Systems (Greenhouse, etc.)</p>
                        <p>Capability to add candidates from your ATS</p>
                        <h6 class="coming-soon">Coming soon</h6>
                        <p>Daily import of your career opportunities from your website</p>
                        <p>Integral Video Conferencing</p>
                        <p>Unlimited Deep Dive Candidate Reports per month</p>
                        <p>Alert when candidate is interviewing elsewhere</p>
                    </div>
                </div>

                <div class="col-md-4 payment-section">
                    <h4>Payment</h4>
                    <div class="entry-level">
                        <h5>Entry Level Account</h5>
                        <div class="checkbox-wrapper">
                            <label class="checkbox-container">
                                <input type="checkbox">
                                <span class="check"></span>
                                <h6 class="m-0 ml-4">&nbsp;&nbsp;Billed monthly $220</h6>
                            </label>
                        </div>

                        <div class="checkbox-wrapper">
                            <label class="checkbox-container">
                                <input type="checkbox">
                                <span class="check"></span>
                                <h6 class="m-0 ml-4">&nbsp;&nbsp;Billed annually $2,376 (You save $264)</h6>
                            </label>
                        </div>
                    </div>

                    <div class="corporate-level mt-4">
                        <h5>Corporate Account</h5>
                        <div class="checkbox-wrapper">
                            <label class="checkbox-container">
                                <input type="checkbox">
                                <span class="check"></span>
                                <h6 class="m-0 ml-4">&nbsp;&nbsp;Billed monthly $480</h6>
                            </label>
                        </div>

                        <div class="checkbox-wrapper">
                            <label class="checkbox-container">
                                <input type="checkbox">
                                <span class="check"></span>
                                <h6 class="m-0 ml-4">&nbsp;&nbsp;Billed annually $5,184 (You save $576)</h6>
                            </label>
                        </div>

                        <h5 class="mt-4">Enterprise Account <a href="#">(Learn more)</a></h5>
                    </div>

                    <div class="payment-section">
                        <div class="row justify-content-center">
                            <div class="col-md-12">
                                <div class="card">
                                    <form onsubmit="event.preventDefault()" class="form-card">
                                        <div class="row justify-content-center mb-4 radio-group">
                                            <div class="col-sm-3 col-5">
                                                <div class='radio mx-auto' data-value="dk"> <img class="fit-image" src="https://i.imgur.com/5TqiRQV.jpg" width="105px" height="55px"> </div>
                                            </div>
                                            <div class="col-sm-3 col-5">
                                                <div class='radio mx-auto' data-value="visa"> <img class="fit-image" src="https://i.imgur.com/OdxcctP.jpg" width="105px" height="55px"> </div>
                                            </div>
                                            <div class="col-sm-3 col-5">
                                                <div class='radio mx-auto' data-value="master"> <img class="fit-image" src="https://i.imgur.com/WIAP9Ku.jpg" width="105px" height="55px"> </div>
                                            </div>
                                            <div class="col-sm-3 col-5">
                                                <div class='radio mx-auto' data-value="paypal"> <img class="fit-image" src="https://i.imgur.com/cMk1MtK.jpg" width="105px" height="55px"> </div>
                                            </div> <br>
                                        </div>
                                        <div class="row justify-content-center form-group">
                                            <div class="col-12 px-auto">
                                                <div class="custom-control custom-radio custom-control-inline"> <input id="customRadioInline1" type="radio" name="customRadioInline1" class="custom-control-input" checked="true"> <label for="customRadioInline1" class="custom-control-label label-radio">Private</label> </div>
                                                <div class="custom-control custom-radio custom-control-inline"> <input id="customRadioInline2" type="radio" name="customRadioInline1" class="custom-control-input"> <label for="customRadioInline2" class="custom-control-label label-radio">Business</label> </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-12">
                                                <div class="input-group"> <input type="text" name="Name" placeholder="John Doe"> <label>Name</label> </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-12">
                                                <div class="input-group"> <input type="text" id="cr_no" name="card-no" placeholder="0000 0000 0000 0000" minlength="19" maxlength="19"> <label>Card Number</label> </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="input-group"> <input type="text" id="exp" name="expdate" placeholder="MM/YY" minlength="5" maxlength="5"> <label>Expiry Date</label> </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="input-group"> <input type="password" name="cvv" placeholder="&#9679;&#9679;&#9679;" minlength="3" maxlength="3"> <label>CVV</label> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-md-12 text-center">
                                                <button class="btn btn-primary">Place your order</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <img src="./../../../assets/images/roles-final.png" class="fix-img"/>
            </div>
        </div>
    </div>
</main>
