import { AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.services';
import { CandidateService } from 'src/app/services/candidate.service';
import { faEnvelope, faPhoneAlt, faFile, faDownload, faPencilAlt, faCamera, faCalendarAlt, faComments, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import * as Url from './../../constants/url';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import { imgBaseUrl } from 'src/app/constants/url';
import { UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/core/toast/toast-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PositionService } from 'src/app/services/position.service';
import { MapService, Maps } from 'src/app/services/map.service';

@Component({
  selector: 'app-candidate-dashboard',
  templateUrl: './candidate-dashboard.component.html',
  styleUrls: ['./candidate-dashboard.component.css']
})
export class CandidateDashboardComponent implements OnInit,AfterViewInit {
  @ViewChild('search') public searchElementRef: ElementRef
  faLinkedin = faLinkedin
  faEnvelope = faEnvelope
  faPhoneAlt = faPhoneAlt
  faFile = faFile
  faDownload = faDownload
  faPencilAlt = faPencilAlt
  faCamera = faCamera
  faCalendarAlt = faCalendarAlt
  faComments = faComments;
  faTrash = faTrash;
  public imgBaseUrl = imgBaseUrl;
  public imageBaseUrl = Url.imgBaseUrl;
  candidateData: any;
  documentLinks: any =[];
  minValue: number;
  referenceLinks: any;
  maxValue: number;
  linkedInData: any;
  isCandidateBackgroundEditMode: boolean = false;
  toggleReference: boolean;
  toggleResume: boolean;
  currentUserRoleCode: string;
  loggedInCandidateId: any;
  candidatePositionSpecificData: any;
  selectedPositionDetail: any;
  imageURL: string;
  showHtmInfo: boolean = false;
  htmCalendarData: any;
  calendarData: any;
  viewDate: Date = new Date();
  options: Options = {
    floor: 0,
    ceil: 500000,
    step: 100,
    enforceStep: false,
    enforceRange: false,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min Salary:</b> $' + (isNaN(value) ? 0 : value);
        case LabelType.High:
          return '<b>Max Salary:</b> $' + (isNaN(value) ? 0 : value);
        default:
          return '$' + (isNaN(value) ? 0 : value);
      }
    }
  };
  activePositions: any = [];
  interviewDate: any;
  currentPosition: any;
  constructor(private candidateService: CandidateService,
    private fb: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private positionService: PositionService,
    private ngZone: NgZone,
    private apiService: MapService
  ) {}
  initAutocomplete(maps: Maps) {
    let autocomplete = new maps.places.Autocomplete(
      this.searchElementRef?.nativeElement
    );
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        this.candidateForm.controls.location.patchValue(autocomplete.getPlace().formatted_address);
      });
    });
  }
  ngAfterViewInit() {
    if( this.searchElementRef){
      this.apiService.api.then((maps) => {
        this.initAutocomplete(maps);
      });
    }
  }

  ngOnInit(): void {
    let uerdata: any = localStorage.getItem('currentUser');
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    uerdata = JSON.parse(uerdata);
    if (uerdata?.role === 'Candidate') {
      this.loggedInCandidateId = uerdata?.candidate_id;
      this.getCandidateById(this.loggedInCandidateId);
      const month = ("0" + (this.viewDate.getMonth() + 1)).slice(-2);
      const year = this.viewDate.getFullYear();
      this.getCandidateScheduleCalendar(month, year)
    }
    this.candidateService.getSelectedCandidate().subscribe((pos) => {
      if (Object.keys(pos).length !== 0) {
        this.selectedPositionDetail = pos;
        this.selectedPositionDetail.members.sort((el1: any, el2: any) => {
          return el2.isHod - el1.isHod
        });
      }
      if (pos.id && this.loggedInCandidateId) {
        this.candidateService.getCandidateAssociateData(pos.id, this.loggedInCandidateId).subscribe((res: any) => {
          this.candidatePositionSpecificData = res.data;
        });
      }
    })
    this.getCandidatePositionData();
  }
  getCandidateById(id: any) {
    this.candidateService.getCandidateById(id).subscribe((res: any) => {
      this.candidateData = res;
      // const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
      this.createCandidateForm();
    });
  }
  candidateForm!: FormGroup;
  createCandidateForm() {
    this.candidateForm = this.fb.group({
      candidate_id: [this.candidateData.candidate_id],
      first_name: [this.candidateData.name],
      last_name: [this.candidateData.last_name],
      work_auth: [this.candidateData.work_auth],
      nickname: [this.candidateData.nickname],
      personal_notes: [this.candidateData.personal_notes],
      location: [this.candidateData.location],
      currency: [this.candidateData.currency],
      salaryRange: [this.candidateData.salaryRange],
      profile_photo: [''],
      profile_photo_deleted: [false],
      skype_id: [this.candidateData.skype_id],
      phone_number: [this.candidateData.phone_number, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      resume:[this.candidateData.resume,Validators.required]
    });
    this.documentLinks = this.candidateData?.resume;
    if(this.searchElementRef)
      this.searchElementRef.nativeElement.value = this.candidateData?.location;
  }
  toggleBackgroundEdit() {
    this.isCandidateBackgroundEditMode = !this.isCandidateBackgroundEditMode
    // setTimeout(() => {
    //   this.candidateForm.controls['location'].setValue('this.candidateData.location');
    //   console.log(this.candidateForm.value.location)
    // }, 1000)
  }
  cancelCandidateUpdate() {
    this.isCandidateBackgroundEditMode = false;
    this.toggleReference = false;
    this.toggleResume = false;
  }
  showPreview(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      // this.accountService.leavePage = true;
      const [file] = event.target.files;
      this.candidateForm.controls.profile_photo_deleted.patchValue(false);
      this.candidateForm.controls['profile_photo'].patchValue(event.target.files[0]);
      reader.readAsDataURL(file);
      reader.onload = () => {

        this.imageURL = reader.result as string;

      };
    }
  }
  decodeUrl(str: any) {
    return str.replace(/%20/g, " ");
  }
  // getCandidateSalary(currency: any, salaryRange: any) {
  //   if (salaryRange && salaryRange !== 'null' && salaryRange !== 'Not Specified') {
  //     if (salaryRange.split(',')[1])
  //       return salaryRange && salaryRange !== 'null' && salaryRange !== 'Not Specified' ? (currency || '$') + (salaryRange.split(',')[0] || 0) + `-` + (currency || '$') + (salaryRange.split(',')[1] || '') : 'N/A';
  //     else {
  //       return (currency || '$') + (salaryRange.split(',')[0] || 0);
  //     }
  //   }
  // }
  startInterview() {
    window.open(this.htmCalendarData.interview_data.button_data.link, "_blank")
  }
  getCandidatePositionData() {
    this.candidateService.getCandidatePositionData().subscribe((res: any) => {
      this.activePositions = res.positions
    })
  }
  openPositionModal(pos: any, posModal: any) {
    this.currentPosition = null
    this.getSelectedPosition(pos.op_id)
    this.modalService.open(posModal, { size: 'lg' });
  }
  getSelectedPosition(op_id: any) {
    this.positionService.getFilteredPosbyId(op_id).subscribe((res: any) => {
      this.currentPosition = res.data;
    })
  }
  getCandidateScheduleCalendar(month: any, year: any) {
    this.candidateService.getCandidateScheduleCalendar(month, year).subscribe((res: any) => {
      this.calendarData = res?.data
    })
  }
  references: string[] = [];
  onReferenceChange(event: any) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.references.push(event.target.files[i]);
    }
  }
  deleteRef(doc: any) {
    this.referenceLinks.splice(this.referenceLinks.indexOf(doc), 1)
  }
  documents: string[] = [];

  onFileChange(event: any) {
    this.candidateForm.controls['resume'].setValue(event.target.files[0])
  }

  deleteDoc(doc: any) {
    this.candidateForm.controls['resume'].setValue('')
    this.documentLinks = [];
  }

  updateCandidateBg() {
    let formdata = this.candidateForm.value;
    this.candidateService.updateCandidateBg(formdata).subscribe((res: any) => {
      if (res) {
        this.showSuccess("Candidate Updated successfully.");
        this.getCandidateById(this.candidateForm.value.candidate_id);
        this.isCandidateBackgroundEditMode = false;
        this.toggleReference = false;
        this.toggleResume = false;
        const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
        currentUser.profile_photo = res.data.temp_profile_photo;
        currentUser.first_name = res.data.name;
        currentUser.last_name = res.data.last_name;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.userService.setuserDetailsUpdated(currentUser);
      }
    })
  }
  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }
  getinterviewData(data: any) {
    this.showHtmInfo = true;
    this.htmCalendarData = data;
    this.interviewDate = new Date(data.date)
  }
  formatDate(inputDate: any) {
    // Create a new Date object from the input string
    var date = new Date(inputDate);

    // Define months array to convert numeric month to its name
    var months = [
      "January", "February", "March", "April",
      "May", "June", "July", "August",
      "September", "October", "November", "December"
    ];

    // Extract day, month, and year from the Date object
    var day: any = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    var month = months[date.getMonth()];
    var year = date.getFullYear();

    // Create the formatted date string
    var formattedDate = month + ' ' + day + ', ' + year;

    return formattedDate;
  }
}
