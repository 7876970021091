import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Url from './../constants/url';

@Injectable({
  providedIn: 'root'
})
export class AccountleveldataService {

  constructor(private http: HttpClient) { }
  
  getAccountData(accountId: any){
        return this.http.get(Url.getCurrentHtmData+ "/" + accountId);
  }

  updateAccLevelData(accountId: any, positions: any){
    const PositionsToDelet= {
      'updated_data': positions
    };
    
    return this.http.put(Url.getCurrentHtmData+ "/" + accountId, PositionsToDelet);
  }

}
