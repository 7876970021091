<main id="positions-content">
    <app-header style="z-index: 99;position: relative;  "></app-header>
    <div class="widget-wrapper">
        <app-detail-header></app-detail-header>
        <div class="page-body">
            <div class="widget-box box-padding-1rem">
                <div class="row">
                    <div class="col-sm-3">
                        <ul class="position-creation-list d-flex padding-top-15x flex-column  gap-2 ">
                            <div class="triangle-left" [class]="{'t1': step1,'t2':step2,'t3':step3,'t4':step4}"></div>
                            <li>
                                <div class="d-flex flex-row gap-3 ">
                                    <div>
                                        <p *ngIf="step1"
                                            class="text-bg-gray rounded-circle mx-auto  px-md-2 text-gray-dark  fs-14px mb-none">
                                            1
                                        </p>
                                        <p *ngIf="!step1"
                                            class="text-bg-green rounded-circle  px-md-2 text-white  fs-14px mb-none check-padding">
                                            <fa-icon [icon]="faCheck"
                                                [styles]="{'stroke': 'white', 'color': 'white', 'font-size': '15px'}"></fa-icon>
                                        </p>
                                    </div>
                                    <div class="d-flex  flex-column gap-2">
                                        <p class=" pb-0 text-gray-dark fs-16px mb-none"
                                            [class]="{'font-weight-bold': step1 }">Position Details &nbsp;&nbsp;
                                            <span *ngIf="!step1" class="editLink"><a (click)="goToStepOne()"
                                                    href="javascript:void(0)">
                                                    <fa-icon [icon]="faPen"></fa-icon>
                                                    Edit</a></span>
                                        </p>
                                        <p *ngIf="step1" class="fs-14px text-gray-light line-height">Add the
                                            details that would be
                                            helpful for your team to understand
                                            requirement</p>
                                        <p *ngIf="!step1" class="mb-1">
                                            Title:{{' ' +positionFirstForm.value.position_title}}
                                            <br>No. of Hires : {{' ' +positionFirstForm.value.no_of_open_positions}}
                                            <br>Salary : {{' $' +positionFirstForm.value.salary_range.split(",")[0]+ ' -
                                            $'+positionFirstForm.value.salary_range.split(",")[1]}}
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex flex-row gap-3 ">
                                    <div>
                                        <p *ngIf="step2 || step1"
                                            class="text-bg-gray rounded-circle mx-auto  px-md-2 text-gray-dark  fs-14px mb-none">
                                            2
                                        </p>
                                        <p *ngIf="!step2 && !step1"
                                            class="text-bg-green rounded-circle  px-md-2 text-white  fs-14px mb-none check-padding">
                                            <fa-icon [icon]="faCheck"
                                                [styles]="{'stroke': 'white', 'color': 'white', 'font-size': '15px'}"></fa-icon>
                                        </p>
                                    </div>
                                    <div class="d-flex  flex-column gap-2">
                                        <p class=" pb-0 text-gray-dark fs-16px mb-none"
                                            [class]="{'font-weight-bold': step2}">Skill Set &nbsp;&nbsp;
                                            <span *ngIf="!step2 && !step1" class="editLink"><a (click)="skillDetails()"
                                                    href="javascript:void(0)">
                                                    <fa-icon [icon]="faPen"></fa-icon>
                                                    Edit</a></span>
                                        </p>
                                        <p *ngIf="step2 || step1" class="fs-14px text-gray-light line-height">
                                            The interview held will be
                                            based on these skill sets that you
                                            define</p>
                                        <p *ngIf="!step2 && !step1 && !showMore" class="mb-1">
                                            Skill 1:{{' '+this.positionFirstForm.value.skillsets[0].skillset_name}}<br>
                                            Skill 2:{{' '+this.positionFirstForm.value.skillsets[1].skillset_name}}<br>
                                            Skill 3:{{' '+this.positionFirstForm.value.skillsets[2].skillset_name}}<br>
                                            <a href="javascript:void(0)" class="cursor-pointer"
                                                (click)="showMoreSkills()"
                                                *ngIf="this.positionFirstForm.value.skillsets.length > 3">and
                                                more....</a>
                                        </p>
                                        <p *ngIf="!step2 && !step1 && showMore" class="mb-1">
                                            <ng-container
                                                *ngFor="let item of this.positionFirstForm.value.skillsets; let i = index">
                                                Skill {{i+1}}:{{' '+item.skillset_name}}<br>
                                            </ng-container>
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex flex-row gap-3 ">
                                    <div>
                                        <p
                                            class="text-bg-gray rounded-circle mx-auto  px-md-2 text-gray-dark  fs-14px mb-none">
                                            3
                                        </p>
                                    </div>
                                    <div class="d-flex  flex-column gap-2">
                                        <p class=" pb-0 text-gray-dark fs-16px mb-none"
                                            [class]="{'font-weight-bold': step3}">Timeline & Team
                                        </p>
                                        <p class="fs-14px text-gray-light line-height">Add the users who will be
                                            conducting the interviews
                                            to hire the perfect candidate</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-9 bg-white  rounded-bottom secondBox-padding-none">
                        <div class="position-body  ">
                            <div class="progress" style="background-color: #CCCCCC;">
                                <div class="progress-bar" role="progressbar" [ngStyle]="{'width': progress_percent}"
                                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"
                                    style="padding-right: 15px;text-align: right;border-radius: 4px;">
                                    {{progress_percent}}</div>
                            </div>

                            <div *ngIf="step1" class=" padding-box ">
                                <div *ngIf="!JDparsed" class="selectbox">
                                    <div>
                                        <p class="selectbox-text text-title">Choose How to Create Your Open Position</p>
                                        <div class="selectbox-options">
                                            <div class="-ai">
                                                <fa-icon [icon]="faSparkles"></fa-icon>
                                                <div class="mx-2">
                                                    <p class="text-head">Upload Your Job Description</p>
                                                    <p class="text-description">Let our AI Powered QBrain create
                                                        position details from the file you
                                                        choose</p>
                                                    <input type="file" accept=".pdf,.doc,.docx"
                                                        (change)="uploadJD($event)">
                                                </div>
                                            </div>
                                            <div class="-jd">
                                                <fa-icon [icon]="faKeyboard"></fa-icon>
                                                <div class="mx-2">
                                                    <p class="text-head">No Job Description? <br /> No Problem. </p>
                                                    <p class="text-description">Copy and paste any position details you
                                                        have into our easy-to-use
                                                        interface.</p>
                                                    <button (click)="optionSelected()"
                                                        class="btn btn-primary btn-sm">Start</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div [ngStyle]="{'display': JDparsed ? 'block':'none'}">
                                    <form id="addPosition" [formGroup]="positionFirstForm">

                                        <!-- <ng-container *ngIf="positionFirstForm"> -->
                                        <div class="row">
                                            <div class="col-md-6">

                                                <div class="form-group ">
                                                    <label class="  col-form-label">Open Position Title
                                                        <small>*</small></label>

                                                    <div class="">

                                                        <div class="input-group">
                                                            <input type="text" placeholder="Eg: Software Engineer"
                                                                class="form-control form-input"
                                                                formControlName="position_title" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group ">
                                                    <label class="  col-form-label">Salary Range
                                                        <small>*</small></label>
                                                    <div class="">
                                                        <div class="input-group dollar-box  ">
                                                            <div style="width: 40%;"
                                                                class="gap-4 justify-content-lg-start align-items-center d-flex salary-range-items">
                                                                <span>
                                                                    Min
                                                                </span>
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend salary-range-input">
                                                                        <span class=" dollar-box-inner">$</span>
                                                                    </div>
                                                                    <input type="number" class=" salary-range-input"
                                                                        style="width: 80%" [value]="range1"
                                                                        (focusout)="focusOutFunction2()"
                                                                        (input)="focusOutFunction1($event)"
                                                                        aria-label="Amount (to the nearest dollar)">
                                                                </div>
                                                            </div>
                                                            <div style="width: 40%;"
                                                                class="gap-4 justify-content-lg-start align-items-center d-flex salary-range-items">
                                                                <span>
                                                                    Max
                                                                </span>

                                                                <div class="input-group">
                                                                    <div class="input-group-prepend salary-range-input">
                                                                        <span class=" dollar-box-inner">$</span>
                                                                    </div>
                                                                    <input type="number" class="salary-range-input "
                                                                        style="width: 80%;" [value]="range2"
                                                                        aria-label="Amount (to the nearest dollar)"
                                                                        (focusout)="focusOutFunction2()"
                                                                        (input)="getSecValue($event)">
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div class="alert">
                                                            <p *ngIf="!seeNegative()">Please enter both Min Max
                                                                salary
                                                                values, only numbers are allowed
                                                            </p>
                                                            <p *ngIf="calRange() ">Max Salary value cannot be less
                                                                than
                                                                Min salary value</p>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="form-group ">
                                                    <label class="  col-form-label">Special Instructions </label>

                                                    <div class="">
                                                        <div class="input-group">
                                                            <textarea class="form-control form-input"
                                                                formControlName="special_intruction"
                                                                placeholder="Is there anything specific the team needs to know?"></textarea>
                                                        </div>
                                                        <div *ngIf="positionFirstForm.controls['special_intruction'].invalid && (positionFirstForm.controls['special_intruction'].dirty || positionFirstForm.controls['special_intruction'].touched)"
                                                            class="alert">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="col-form-label">No. of Candidates to be hired
                                                        <small>*</small>
                                                    </label>
                                                    <div
                                                        class="qty d-flex gap-2 justify-content-lg-start  align-items-center ">
                                                        <p>
                                                            <span class="minus bg-white prevent-select"
                                                                (click)="decreaseCandidates()">
                                                                -
                                                            </span>
                                                        </p>
                                                        <input readonly type="number" class="count "
                                                            formControlName="no_of_open_positions" name="qty"
                                                            value="01">

                                                        <p>
                                                            <span class="plus bg-white prevent-select"
                                                                (click)="increaseCandidates()">
                                                                +
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="form-group ">
                                                    <label class="  col-form-label">Location</label>

                                                    <div class="">

                                                        <div class="input-group">
                                                            <input type="text" class="form-control form-input"
                                                                placeholder="Eg: California"
                                                                formControlName="work_location" #search />
                                                        </div>
                                                        <div *ngIf="positionFirstForm.controls['work_location'].invalid && (positionFirstForm.controls['work_location'].dirty || positionFirstForm.controls['work_location'].touched)"
                                                            class="alert">
                                                            <div
                                                                *ngIf="positionFirstForm.controls['work_location'].errors?.pattern">
                                                                Only alphabets are allowed.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group ">
                                                    <label class="  col-form-label">Documents</label>

                                                    <div class="">

                                                        <div *ngIf="!positionFirstForm.value.document.name"
                                                            class="input-group">
                                                            <input type="file" accept=".pdf,.doc,.docx"
                                                                style="width: 250px; float: left;"
                                                                (change)="onFileChange($event)"
                                                                class="form-control-file">
                                                            <input type="hidden" name="fileHidden"
                                                                formControlName="document" />

                                                        </div>
                                                        <label *ngIf="positionFirstForm.value.document.name"
                                                            [class]="{'text-success': positionFirstForm.value.document?.name }">
                                                            {{positionFirstForm.value.document?.name || 'NA'}}</label>
                                                        <button *ngIf="positionFirstForm.value.document.name"
                                                            class="btn btn-primary btn-small ml-2"
                                                            (click)="clearDocument()">Clear</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div *ngIf="step2">
                                <div class="form-section form-section-skill custom-scroll-bar pt-4 pb-4"
                                    style="height: 500px;">
                                    <form id="addPosition" [formGroup]="positionFirstForm">
                                        <div formArrayName="skillsets"
                                            *ngFor=" let skills of getControls(); let i = index">
                                            <div class="row" [formGroupName]="i">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="row align-items-center">
                                                            <div class="form-group col-md-5">
                                                                <label for="inputEmail4">Skill Name {{i+1}}
                                                                    <small>*</small></label>
                                                                <input type="email" class="form-control form-input"
                                                                    (input)="clearQuestionSuggestion(i)"
                                                                    formControlName="skillset_name" id="inputEmail4"
                                                                    placeholder="Eg: Java">
                                                                <span class="text-danger error-message mt-1"
                                                                    *ngIf="positionFirstForm.controls.skillsets['controls'][i].controls.skillset_name.invalid && (positionFirstForm.controls.skillsets['controls'][i].controls.skillset_name.dirty || positionFirstForm.controls.skillsets['controls'][i].controls.skillset_name.touched)">
                                                                    Skill set name required
                                                                </span>
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                <label for="inputPassword4">Importance
                                                                    <small>*</small></label>
                                                                <div class="form-slider">
                                                                    <ngx-slider
                                                                        [value]="skills.get('skillset_weightage')?.value"
                                                                        (valueChange)="checkSkillSetMarks(i, $event)"
                                                                        [options]="sliderOptions"></ngx-slider>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-2 mt-3">
                                                                <button class="btn btn-primary"
                                                                    (click)="getQuestionSuggestions(i)">
                                                                    QBrain Questions
                                                                </button>
                                                            </div>
                                                            <div *ngIf="getControls().length>3"
                                                                class="ms-auto col-md-1 mt-3">
                                                                <button type="button" class="btn btn-outline-danger"
                                                                    (click)="removeSkill(i)">
                                                                    <fa-icon class="cursor-pointer"
                                                                        [icon]="faDelete"></fa-icon>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="row mt-3 question">
                                                            <div class="question-box col-sm-12 custom-scroll-bar ">
                                                                <ng-container *ngIf="getQuestion(i).length>=1">
                                                                    <div class="question-item"
                                                                        *ngFor="let question of getQuestion(i); let j = index">
                                                                        <div class="question-decision">
                                                                            <fa-icon *ngIf="question.fixed"
                                                                                class="question-item-decision-icon"
                                                                                (click)="toggleQuestionFix(i, j)"
                                                                                [icon]="faCheckSquare"></fa-icon>
                                                                            <fa-icon *ngIf="!question.fixed"
                                                                                class="question-item-decision-icon"
                                                                                (click)="toggleQuestionFix(i, j)"
                                                                                [icon]="faPlusSquare"></fa-icon>
                                                                            <fa-icon *ngIf="!question.fixed"
                                                                                class="question-item-decision-icon"
                                                                                (click)="removeQuestion(i, j)"
                                                                                [icon]="faMinusSquare"></fa-icon>
                                                                            <fa-icon *ngIf="!question.fixed"
                                                                                (click)="refetch(i,j)"
                                                                                class="question-item-decision-icon"
                                                                                [icon]="faSync"></fa-icon>
                                                                        </div>
                                                                        <div class="question-text"
                                                                            [class.editablefixed]="!question.fixed">
                                                                            <div>{{j+1}} - </div>
                                                                            <div class="question-text-content"
                                                                                [attr.contenteditable]="!question.fixed"
                                                                                (focusout)="saveQuestion($event,i,j)">
                                                                                {{question.text}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="question-add btn"
                                                                        (click)="addQuestion(i)">
                                                                        Add Question
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container *ngIf="getQuestion(i).length<1">
                                                                    <div class="text-secondary">
                                                                        Add your
                                                                        <a href="javascript:void(0)"
                                                                            class="link-own-ques"
                                                                            (click)="addQuestion(i)"> own</a>
                                                                        suggested questions or ask
                                                                        <a href="javascript:void(0)" class="link-qbrain"
                                                                            (click)="getQuestionSuggestions(i)">
                                                                            QBrain
                                                                        </a>
                                                                        for AI generated questions.
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                            <!-- <div class="text-danger error-message"
                                                                *ngIf="positionFirstForm.controls.skillsets['controls'][i].controls.skillset_name.errors?.touchedByQbrains">
                                                                Skill set name required
                                                            </div>
                                                        </div>
                                                        <div *ngIf="positionFirstForm.value.skillsets[i].skillset_questions != ''"
                                                            class="row mt-2">
                                                            <!-- <div class="col-sm-12 text-gray-dark">
                                                                <fa-icon class="mr-2" [icon]="faCarrot"
                                                                    [styles]="{'stroke': '#FF9933', 'color': '#FF9933', 'font-size': '25px'}">
                                                                </fa-icon>
                                                                Awesome! You earned 1 Reward Point for this question.
                                                            </div> -->
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="seperator my-3"></div>
                                            </div>
                                        </div>
                                        <div>
                                            <button class="btn internal-button" (click)="addSkill()">Add one more
                                                Skill</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div *ngIf="step3" class=" padding-box ">
                                <div class="form-section pt-0" [formGroup]="positionFirstForm">

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="template-ctnr">
                                                <h6>Set the overall deadline </h6>
                                                <div class="row justify-content-center">
                                                    <div class="col-sm-6 col-xl-4">

                                                        <label class="form-label" for="inlineFormInputName1">Kick-off
                                                            Date<small>*</small></label>
                                                        <input class="form-control date-input mb-2 mr-sm-2"
                                                            placeholder="Beginning Date" name="dp" ngbDatepicker
                                                            #d1="ngbDatepicker" container="body"
                                                            id="inlineFormInputName1"
                                                            formControlName="kickoff_start_date" readonly />
                                                        <button id="inline-button-1"
                                                            class="btn btn-outline-secondary datepicker-btn"
                                                            (click)="d1.toggle()" type="button">
                                                            <fa-icon [icon]="faCalendarAlt"
                                                                [styles]="{'stroke': '#999999', 'color': 'var(--primary-400)', 'font-size': '15px'}"></fa-icon>
                                                        </button>

                                                    </div>

                                                    <div class="col-sm-6 col-xl-4">

                                                        <label class="form-label" for="inlineFormInputName2">Sourcing
                                                            Deadline<small>*</small></label>
                                                        <input class="form-control date-input mb-2 mr-sm-2"
                                                            placeholder="Adding Candidates Date" name="dp" ngbDatepicker
                                                            #d2="ngbDatepicker" container="body"
                                                            id="inlineFormInputName2"
                                                            formControlName="sourcing_deadline"
                                                            (dateSelect)="onDateSelect($event)" readonly />
                                                        <button id="inline-button-2"
                                                            class="btn btn-outline-secondary datepicker-btn"
                                                            (click)="d2.toggle()" type="button">
                                                            <fa-icon [icon]="faCalendarAlt"
                                                                [styles]="{'stroke': '#999999', 'color': 'var(--primary-400)', 'font-size': '15px'}"></fa-icon>
                                                        </button>
                                                    </div>
                                                    <div class="col-sm-6 col-xl-4">
                                                        <label class="form-label" for="inlineFormInputName3">Target
                                                            Completion Date<small>*</small></label>
                                                        <input class="form-control date-input mb-2 mr-sm-2"
                                                            placeholder="Hiring Completion Date" name="dp" ngbDatepicker
                                                            #d3="ngbDatepicker" container="body"
                                                            id="inlineFormInputName3" formControlName="target_deadline"
                                                            (dateSelect)="onDateSelect($event)" readonly />
                                                        <button id="inline-button-3"
                                                            class="btn btn-outline-secondary datepicker-btn"
                                                            (click)="d3.toggle()" type="button">
                                                            <fa-icon [icon]="faCalendarAlt"
                                                                [styles]="{'stroke': '#999999', 'color': 'var(--primary-400)', 'font-size': '15px'}"></fa-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row" *ngIf="dateValidationError">
                                                    <p class="error">Sourcing date should be after Kick-off date and
                                                        Target completion date should be after Sourcing deadline
                                                        date.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row my-4">
                                        <div class=" col-sm-4">
                                            <label class="  col-form-label">Search User </label>

                                            <div class="">
                                                <div class="input-group">
                                                    <input type="text" id="searchbox" (input)="sendTheNewValue($event)"
                                                        class="form-control form-input"
                                                        placeholder="Search by Email ID or First & Last Name or Role" />

                                                    <button *ngIf="isSearchTextFilled" class="btn ml-3 internal-button"
                                                        (click)="clearSuggestion()">Clear</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row htm-table-row my-2">
                                        <div class="col-sm-12 " *ngIf="selectedHM.length >0">
                                            <table class="table table-striped table-bordered htm-table">
                                                <tbody>
                                                    <tr>
                                                        <td>Name<span (click)="sortTable('name')"
                                                                class="float-right cursor-pointer"><fa-icon
                                                                    [icon]="faSort"
                                                                    [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon>
                                                            </span></td>
                                                        <td>Skills <span (click)="sortTable('skills')"
                                                                class="float-right cursor-pointer"><fa-icon
                                                                    [icon]="faSort"
                                                                    [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon>
                                                            </span></td>
                                                        <td>Deadline Date </td>
                                                        <td>Weightage </td>
                                                        <td>QBrain Comments <span (click)="sortTable('interviews_done')"
                                                                class="float-right cursor-pointer"><fa-icon
                                                                    [icon]="faSort"
                                                                    [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon>
                                                            </span></td>
                                                    </tr>
                                                    <tr *ngFor="let data of selectedHM; let i = index">
                                                        <td>
                                                            <span>
                                                                <span (click)="removeHtm(i)"
                                                                    class="minus-button"><fa-icon class=" "
                                                                        [icon]="faMinus"
                                                                        [styles]="{'stroke': '#00AB76', 'color': '#00AB76', 'font-size': '15px'}"></fa-icon></span>
                                                                <span class="hm-role ml-2"> {{getHMRole(data.role)
                                                                    }}</span>
                                                                <span class="hm-name ml-3"> {{ data.name}}</span>
                                                            </span>
                                                        </td>
                                                        <td class="hm-role">{{data.skills}}</td>
                                                        <td style="position: relative;">
                                                            <span>
                                                                <span *ngIf="data.deadlineDate">{{
                                                                    formatDate(data.deadlineDate) }}</span>
                                                                <span *ngIf="!data.deadlineDate"><a class="text-danger "
                                                                        href="javascript:void(0)"
                                                                        (click)="d5.toggle()">Set Date to
                                                                        Proceed</a></span>

                                                                <fa-icon class="ml-4 float-end cursor-pointer"
                                                                    [icon]="faCalendarAlt" (click)="d5.toggle()"
                                                                    [styles]="{'stroke': '#999999', 'color': 'var(--primary-400)', 'font-size': '25px'}"></fa-icon>
                                                                <input class="dateInput" type="text" ngbDatepicker
                                                                    #d5="ngbDatepicker"
                                                                    (dateSelect)="onhtmDateSelect($event,i)">
                                                                <p class="error" *ngIf="deadlineDateValidator[i]">
                                                                    Deadline date should be between <br>Kick-off
                                                                    date
                                                                    and Target completion date.</p>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <a *ngIf="(!data.skillset || data.skillset.length==0) || data.skillset.length!==positionFirstForm.value.skillsets.length"
                                                                class="text-danger " href="javascript:void(0)"
                                                                (click)="editWeight(i,weighting)">Set Weight</a>
                                                            <a *ngIf="(data.skillset && data.skillset.length>0 && data.skillset.length===positionFirstForm.value.skillsets.length)"
                                                                class="text-success " href="javascript:void(0)"
                                                                (click)="editWeight(i,weighting)">Edit Weight</a>
                                                        </td>
                                                        <td class="hm-role" style="font-size: 16px;">On-Time
                                                            Interview -
                                                            {{data.interviews_done}}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-sm-11 suggestion-table custom-scroll-bar"
                                            *ngIf="isSearchTextFilled">
                                            <div class="p-4" *ngIf="newData.length ==0">
                                                <span (click)="clearSuggestion()" class="x-mark">
                                                    X
                                                </span>
                                                No Result found.
                                            </div>
                                            <ng-container *ngIf="newData.length >0">
                                                <span (click)="clearSuggestion()" class="x-mark">
                                                    X
                                                </span>
                                                <table class="table ">

                                                    <tr *ngFor="let data of newData; let i=index;">

                                                        <td>
                                                            <span>
                                                                <input type="checkbox" class="c-checkbox"
                                                                    style="vertical-align: sub;"
                                                                    (change)="selectHM(data,hiringItem[i])"
                                                                    [checked]="isSelectedHM(data)" id="hm"
                                                                    [name]="data.id" [value]="isSelectedHM(data)">
                                                                <span class="hm-role ml-2"> {{getHMRole(data.role)
                                                                    }}</span>
                                                                <span class="hm-name ml-3"> {{ data.name}}</span>
                                                            </span>
                                                        </td>
                                                        <td class="hm-role">{{data.skills}}</td>
                                                        <td class="hm-role">{{'Interview Attended :
                                                            '+data.interviews_done}}
                                                        </td>

                                                    </tr>
                                                </table>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-sm-12 text-gray-dark">
                                            <!-- <fa-icon class="mr-2" [icon]="faCarrot"
                                                [styles]="{'stroke': '#FF9933', 'color': '#FF9933', 'font-size': '25px'}"></fa-icon>
                                            Earn 2 Reward points the team members completed interview before deadline
                                            date. <span class="text-success "> Read More</span> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row button-container mt-4">
                    <div class="col-sm-5 text-gray-dark">
                        <!-- <fa-icon class="mr-2" [icon]="faCarrot"
                            [styles]="{'stroke': '#FF9933', 'color': '#FF9933', 'font-size': '25px'}"></fa-icon> Earn 5
                        Reward points by completing the hire before deadline. <span class="text-success "> Read
                            More</span> -->
                    </div>
                    <div *ngIf="JDparsed" class="col-sm-4 ">
                        <button *ngIf="false " [disabled]="!(box1selected || box2selected || box3selected)"
                            class="btn btn-nav next-button  float-right ml-4" (click)="posDetails()">Go to Position
                            Details</button>
                        <button *ngIf="step1" [disabled]="checkStep2Validations()"
                            class="btn btn-nav next-button  float-right ml-4" (click)="skillDetails()">Go to
                            Skill Set</button>
                        <button *ngIf="step2" [disabled]="checkStep3Validations()"
                            class="btn btn-nav next-button  float-right ml-4" (click)="timelineDetails()">Go
                            to Timeline & Team</button>
                        <button *ngIf="step3 && !isEditMode" [disabled]="checkStep4Validations()"
                            class="btn btn-nav next-button  float-right ml-4"
                            (click)="createOpenPositionModal(confirmJobPosting)">Create Open
                            Position</button>
                        <button *ngIf="step3 && isEditMode" [disabled]="checkStep4Validations()"
                            class="btn btn-nav next-button  float-right ml-4" (click)="upDateOpenPosition()">Update
                            Open
                            Position</button>
                        <button *ngIf="!isEditMode" (click)="saveAsDraft()" [disabled]="getDraftButtonStatus()"
                            class="btn btn-nav cancel-button  float-right ml-4">Save as Draft</button>
                        <button *ngIf="isEditMode" (click)="saveAsDraftUpdate()"
                            class="btn btn-nav cancel-button  float-right ml-4">Save as Draft</button>
                        <button *ngIf="step2" (click)="goToStepOne()"
                            class="btn btn-nav cancel-button  float-right ml-4">Back</button>
                        <button *ngIf="step3" (click)="skillDetails()"
                            class="btn btn-nav cancel-button  float-right ml-4">Back</button>
                        <button [routerLink]="currentUserRoleCode + '/view-all-positions'"
                            class="btn btn-nav cancel-button  float-right ">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<ng-template #weighting let-modal>
    <div class="modal-header">
        <h4 class="modal-title" style="margin-bottom: -0.7em;margin-left: 0.8em;">Weighting </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class=" weighting-table">
            <tr>
                <th colspan="2"></th>
                <th style="text-align: end;padding-right: 2.6em;">Importance</th>
            </tr>
            <tr *ngFor="let item of currentHTMWeightage; let i=index;">
                <td style="width: 15%; font-weight: 600;">
                    Skill {{i+1}}
                </td>
                <td style="width: 15%;">{{item.skillset_name}}
                </td>
                <td style="width: 60%;">
                    <div style="display: flex;align-items: center; justify-content: end; margin-right: 2em">
                        <span class="minus-icon" (click)="decreaseWeight(i)">
                            <fa-icon [icon]="faMinus" class="fa fa-minus"></fa-icon>
                        </span>
                        <span class="skill-score">{{item.skillset_weightage}}</span>
                        <span class="plus-icon" (click)="increaseWeight(i)">
                            <fa-icon [icon]="faPlus" class="fa fa-plus"></fa-icon>
                        </span>
                    </div>

                </td>
            </tr>
        </table>
        <br>
    </div>
    <div class="modal-footer">
        <button id="update-weighting" type="button" class="btn btn-primary" (click)="saveHtmWeightage()">Save</button>
    </div>
</ng-template>

<ng-template #confirmToNavigatePopup let-modal>
    <div class="modal-header">
        <h4 class="modal-title" style="margin-bottom: -0.7em;margin-left: 0.8em;">Are you sure you want to cancel?</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>If you cancel now any changes made to your open position will not be saved.</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button id="update-weighting" type="button" class="btn btn-primary"
            (click)="modal.dismiss('confirm')">Confirm</button>
    </div>
</ng-template>

<ng-template #confirmJobPosting let-modal>
    <div class="modal-header">
        <!-- <h4 class="modal-title" style="margin-bottom: -0.7em;margin-left: 0.8em;">Confirm</h4> -->
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h4>Do you want to list this open position on Qorums Job Board?</h4>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="createOpenPosition(true)">Yes</button>
        <button type="button" class="btn btn-light" (click)="createOpenPosition(false)">No</button>
    </div>
</ng-template>