<nav class="page-sidebar" style="height: 100%;" *ngIf="userRole === 'Superuser' || userRole === 'Account Manager'">
    <div class="leftActionBar">
        <span><strong>Clients</strong></span>
        <a class="searchIcon" href="javascript:void(0)" (click)="showSearch()">
            <fa-icon [icon]="faSearch"></fa-icon>
        </a>
        <a *ngIf="userRole =='Superuser'" id="create-client" class="addLink" [routerLink]="currentUserRoleCode + '/dashboard/addClient'">
            <fa-icon [icon]="faPlus"></fa-icon>
        </a>
    </div>
    <div class="leftActionBar" style="padding: 10px;" *ngIf="isshowSearch">
        <input type="text" style="margin-bottom: 0!important;" class="form-control mb-4" placeholder="Search" [(ngModel)]="query" id="listSearch">
    </div>
    <div class="page-sidebar-wrapper" style="height: calc(100vh - 60px);">
        <ul class="nav nav-sidebar" *ngIf="clientList.length > 0">
            <li *ngFor="let item of clientList | searchFilter: query:'company_name'">
                <a href="javascript:void(0)" (click)="select(item, $event, true)" [id]="'client-'+item.id" [ngClass]="{active: isActive(item)}">
                    {{item.company_name}} 
                </a>
                <fa-icon *ngIf="userRole =='Superuser'" [icon]="faEdit" (click)="editClient(item.id)" style="right:5px; top:12px;"></fa-icon>
                <fa-icon *ngIf="userRole =='Superuser'" (click)="openSm(content, item.id)" [icon]="faDelete" style="right:35px; top:12px;"></fa-icon>
            </li>
        </ul>
        <!-- <app-protip></app-protip> -->
    </div>
</nav>

<nav class="page-sidebar" style="height: 100%;" *ngIf="userRole !== 'Superuser' && userRole !== 'Account Manager'">

    <!-- <div class="coLogo">
        <h2>
            <a *ngIf="logo"><img [src]="logo" /></a>
        </h2>
    </div> -->
    <div class="leftActionBar" [ngClass]="{'p-2': userRole === 'Hiring Member'}">
        <span class="title-asRole"><strong>{{userRole === 'Hiring Member' ? 'Positions to vote on' : userRole === 'Candidate' ? 'Current Opportunities'  : 'Open Positions'}}</strong></span>
        <a class="searchIcon" href="javascript:void(0)" (click)="showSearch()">
            <fa-icon [icon]="faSearch"></fa-icon>
        </a>
        <a id="create-open-position" *ngIf="(userRole =='Superuser') || (userRole =='Account Manager') || (userRole =='Senior Manager') || (userRole =='Client Admin') || (userRole =='Hiring Manager')" class="addLink ml-2" href="javascript:void(0)" (click)="createPosition($event,htmConfirm)">
            <fa-icon [icon]="faPlus"></fa-icon>
        </a>
    </div>
    <div class="page-sidebar-wrapper" style="padding: 0.5rem;height: calc(100vh - 60px)">
        <div class="leftActionBar" style="padding: 10px;">
            <input type="text" style="margin-bottom: 0!important;" class="form-control mb-4" placeholder="Search" [(ngModel)]="query" id="listSearch">
        </div>
        <a *ngIf="(userRole =='Superuser') || (userRole =='Account Manager')" class="addLink ml-2 mb-3 mt-1" href="javascript:void(0)" (click)="createPosition($event,htmConfirm)">
            <fa-icon [icon]="faPlus"></fa-icon>
        </a>
        <ngb-accordion [closeOthers]=true  [activeIds]="activeIds" >
            <ngb-panel id="openPositions">
                <ng-template ngbPanelTitle>
                    <span>
                        <fa-icon class="accordion-image mr-2" [icon]="faFile"></fa-icon>
                        <strong class="position_type">{{userRole === 'Hiring Member' ? 'Positions to vote on' : userRole === 'Candidate' ? 'Your Open Positions'  : 'Open Positions'}}</strong></span>
                    
                    
                </ng-template>
                <ng-template ngbPanelContent>
                    <ul class="nav nav-sidebar" *ngIf="positionList">
                        <li *ngFor="let item of positionList[0] | searchFilter: query : 'position_title' ; let i = index" [ngClass]="{active: isActive(item) || selectedPosition === item.id}">
                            <a [id]="'pos-'+item.id" href="javascript:void(0)" (click)="select(item, $event)">
                                <fa-icon *ngIf="item.has_pending_int" class="pending-action" [icon]="faExclamationCircle"></fa-icon>
                                {{item.position_title}}
                                <!-- <button [hidden]="((userRole =='Hiring Manager' || userRole =='Hiring Member') && !item?.is_hiring_manager) || userRole =='Candidate'" type="button" class="menu-button-cntr" tooltipClass="custom-tooltip" [ngbTooltip]="openPositionmenuButtons" triggers="click:blur" aria-haspopup="true" aria-expanded="false" aria-label="Menu" aria-disabled="false">
                                    <svg viewBox="0 0 20 20" fill="currentColor" width="28" height="28" role="img" aria-hidden="true"><path d="M6 10.5C6 11.3284 5.32843 12 4.5 12 3.67157 12 3 11.3284 3 10.5 3 9.67157 3.67157 9 4.5 9 5.32843 9 6 9.67157 6 10.5zM11.8333 10.5C11.8333 11.3284 11.1618 12 10.3333 12 9.50492 12 8.83334 11.3284 8.83334 10.5 8.83334 9.67157 9.50492 9 10.3333 9 11.1618 9 11.8333 9.67157 11.8333 10.5zM17.6667 10.5C17.6667 11.3284 16.9951 12 16.1667 12 15.3383 12 14.6667 11.3284 14.6667 10.5 14.6667 9.67157 15.3383 9 16.1667 9 16.9951 9 17.6667 9.67157 17.6667 10.5z" fill="currentColor"></path></svg>
                                </button> -->
                                <!-- <ng-template #openPositionmenuButtons>
                                    <fa-icon title="Clone" *ngIf="(userRole =='Senior Manager') || (userRole =='Account Manager') || (userRole =='Superuser') || (userRole =='Hiring Manager') || (userRole =='Client Admin')|| (userRole =='Human Resource')" (click)="duplicatePosition(item.id)" [icon]="faClone"></fa-icon>
                                    <fa-icon title="Trash" *ngIf="(userRole =='Senior Manager') || (userRole =='Account Manager')  || (userRole =='Superuser') || (userRole =='Hiring Manager') || (userRole =='Client Admin') || (userRole =='Hiring Manager')" (click)="openSm(trashPos, item.id)" [icon]="faDelete"></fa-icon>
                                    <fa-icon title="Edit" *ngIf="(userRole =='Senior Manager') || (userRole =='Account Manager')  || (userRole =='Superuser') || (userRole =='Hiring Manager') || (userRole =='Client Admin')" [icon]="faEdit" (click)="editPosition(item.id, item.hiring_group)"></fa-icon>
                                    <fa-icon title="Hold" *ngIf="(userRole =='Senior Manager') || (userRole =='Account Manager')  || (userRole =='Superuser') || (userRole =='Hiring Manager') || (userRole =='Client Admin')" [icon]="faArchive" (click)="archivePosition()"></fa-icon>
                                </ng-template> -->
                            </a>
                        </li>
                    </ul>
                    <p class="p-2" *ngIf="positionList[0] && positionList[0].length < 1">
                        No position available!
                    </p>
                </ng-template>
            </ngb-panel>

            <ngb-panel id="draftPosition" *ngIf="draftedPositionList?.length && userRole !== 'Candidate' && userRole !== 'Hiring Member'">
                <ng-template ngbPanelTitle>
                    <span>
                        <fa-icon class="accordion-image mr-2" [icon]="faFileAlt"></fa-icon>
                        <strong class="mt-4 position_type">Draft Positions</strong>
                    </span>
                </ng-template>
                <ng-template ngbPanelContent>
                    <ul class="nav nav-sidebar" *ngIf="draftedPositionList?.length">
                        <li *ngFor="let item of draftedPositionList | searchFilter: query : 'position_title' ; let i = index" [ngClass]="{active: isActive(item) || selectedPosition === item.id}">
                            <a href="javascript:void(0)">
                                {{item.position_title}}
                                <button [hidden]="(userRole =='Hiring Manager' || userRole =='Hiring Member') && !item?.is_hiring_manager" type="button" class="menu-button-cntr" tooltipClass="custom-tooltip" [ngbTooltip]="draftPositionmenuButtons" triggers="click:blur" aria-haspopup="true" aria-expanded="false" aria-label="Menu" aria-disabled="false">
                                    <svg viewBox="0 0 20 20" fill="currentColor" width="28" height="28" role="img" aria-hidden="true"><path d="M6 10.5C6 11.3284 5.32843 12 4.5 12 3.67157 12 3 11.3284 3 10.5 3 9.67157 3.67157 9 4.5 9 5.32843 9 6 9.67157 6 10.5zM11.8333 10.5C11.8333 11.3284 11.1618 12 10.3333 12 9.50492 12 8.83334 11.3284 8.83334 10.5 8.83334 9.67157 9.50492 9 10.3333 9 11.1618 9 11.8333 9.67157 11.8333 10.5zM17.6667 10.5C17.6667 11.3284 16.9951 12 16.1667 12 15.3383 12 14.6667 11.3284 14.6667 10.5 14.6667 9.67157 15.3383 9 16.1667 9 16.9951 9 17.6667 9.67157 17.6667 10.5z" fill="currentColor"></path></svg>
                                </button>
                                <ng-template #draftPositionmenuButtons>
                                    <fa-icon title="Trash" *ngIf="(userRole =='Account Manager') || (userRole =='Superuser') || (userRole =='Senior Manager') || (userRole =='Client Admin') || (userRole =='Hiring Manager' || userRole =='Hiring Member')" (click)="openSm(trashPos, item.id)" [icon]="faDelete"></fa-icon>
                                        <fa-icon title="Edit" class="edit-position" *ngIf="(userRole =='Account Manager') || (userRole =='Superuser') || (userRole =='Senior Manager') || (userRole =='Client Admin') || (userRole =='Hiring Manager' || userRole =='Hiring Member')" [icon]="faEdit" (click)="editPosition(item.id, item.hiring_group)"></fa-icon>
                                </ng-template>
                            </a>
                        </li>
                    </ul>
                    <p *ngIf="draftedPositionList?.length < 1">
                        No draft position available!
                    </p>
                </ng-template>
            </ngb-panel>

            <ngb-panel id="archivedPosition" *ngIf="archivedPositionList?.length && userRole !== 'Candidate' && userRole !== 'Hiring Member'">
                <ng-template ngbPanelTitle>
                    <span>
                        <fa-icon class="accordion-image mr-2" [icon]="faPauseCircle"></fa-icon>
                        <strong class="mt-4 position_type">On Hold Positions</strong>
                    </span>
                </ng-template>
                <ng-template ngbPanelContent>
                    <ul class="nav nav-sidebar" *ngIf="archivedPositionList?.length">
                        <li *ngFor="let item of archivedPositionList | searchFilter: query : 'position_title' ; let i = index" [ngClass]="{active: isActive(item) || selectedPosition === item.id}">
                            <a href="javascript:void(0)">
                                {{item.position_title}}
                                <button [hidden]="(userRole =='Hiring Manager' || userRole =='Hiring Member') && !item?.is_hiring_manager" type="button" class="menu-button-cntr" tooltipClass="custom-tooltip" [ngbTooltip]="draftPositionmenuButtons" triggers="click:blur" aria-haspopup="true" aria-expanded="false" aria-label="Menu" aria-disabled="false">
                                    <svg viewBox="0 0 20 20" fill="currentColor" width="28" height="28" role="img" aria-hidden="true"><path d="M6 10.5C6 11.3284 5.32843 12 4.5 12 3.67157 12 3 11.3284 3 10.5 3 9.67157 3.67157 9 4.5 9 5.32843 9 6 9.67157 6 10.5zM11.8333 10.5C11.8333 11.3284 11.1618 12 10.3333 12 9.50492 12 8.83334 11.3284 8.83334 10.5 8.83334 9.67157 9.50492 9 10.3333 9 11.1618 9 11.8333 9.67157 11.8333 10.5zM17.6667 10.5C17.6667 11.3284 16.9951 12 16.1667 12 15.3383 12 14.6667 11.3284 14.6667 10.5 14.6667 9.67157 15.3383 9 16.1667 9 16.9951 9 17.6667 9.67157 17.6667 10.5z" fill="currentColor"></path></svg>
                                </button>
                                <ng-template #draftPositionmenuButtons>
                                    <!-- <fa-icon title="Clone" *ngIf="(userRole =='Senior Manager') || (userRole =='Account Manager') || (userRole =='Superuser') || (userRole =='Hiring Manager') || (userRole =='Client Admin')|| (userRole =='Human Resource')" (click)="duplicatePosition(item.id)" [icon]="faClone"></fa-icon> -->
                                    <fa-icon title="Edit" class="edit-position" *ngIf="(userRole =='Account Manager') || (userRole =='Superuser') || (userRole =='Senior Manager') || (userRole =='Client Admin') || (userRole =='Hiring Manager' || userRole =='Hiring Member')" [icon]="faEdit" (click)="editPosition(item.id, item.hiring_group)"></fa-icon>
                                    <fa-icon title="Restore" *ngIf="(userRole =='Account Manager') || (userRole =='Superuser') || (userRole =='Senior Manager') || (userRole =='Client Admin') || (userRole =='Hiring Manager' || userRole =='Hiring Member')" (click)="restoreOpenPosition(item.id)" [icon]="faTrashRestore"></fa-icon>
                                </ng-template>
                            </a>
                        </li>
                    </ul>
                    <p *ngIf="archivedPositionList?.length < 1">
                        No On Hold position available!
                    </p>
                </ng-template>
            </ngb-panel>

            <ngb-panel id="requestedPosition" *ngIf="userRole === 'Candidate'">
                <ng-template ngbPanelTitle>
                    <span><strong class="mt-4 position_type">To Approve</strong></span>
                </ng-template>
                <ng-template ngbPanelContent>
                    <ul class="nav nav-sidebar" *ngIf="requestedPositionList">
                        <li *ngFor="let item of requestedPositionList; let i = index" [ngClass]="{active: isActive(item) || selectedPosition === item.id}">
                            <a href="javascript:void(0)" (click)="select(item, $event, null, true)">
                                {{item.position_title}}
                            </a>
                        </li>
                    </ul>
                    <p class="p-2" *ngIf="!requestedPositionList || requestedPositionList?.length < 1">
                        No position available!
                    </p>
                </ng-template>
            </ngb-panel>

            <ngb-panel id="completedPosition" *ngIf="completedPositionList?.length && userRole !== 'Candidate' && userRole !== 'Hiring Member'">
                <ng-template ngbPanelTitle>
                    <span>
                        <fa-icon class="accordion-image mr-2" [icon]="faSave"></fa-icon>
                        <strong class="mt-4 position_type">Closed Positions</strong>
                    </span>
                </ng-template>
                <ng-template ngbPanelContent>
                    <ul class="nav nav-sidebar" *ngIf="completedPositionList?.length">
                        <li *ngFor="let item of completedPositionList | searchFilter: query : 'position_title' ; let i = index" [ngClass]="{active: isActive(item)}">
                            <a href="javascript:void(0)" (click)="goToAuditDashboard(item)">
                                {{item.position_title}}
                                <button [hidden]="(userRole =='Hiring Manager' || userRole =='Hiring Member') && !item?.is_hiring_manager" type="button" class="menu-button-cntr" tooltipClass="custom-tooltip" [ngbTooltip]="completedPositionmenuButtons" triggers="click:blur" aria-haspopup="true" aria-expanded="false" aria-label="Menu" aria-disabled="false">
                                    <svg viewBox="0 0 20 20" fill="currentColor" width="28" height="28" role="img" aria-hidden="true"><path d="M6 10.5C6 11.3284 5.32843 12 4.5 12 3.67157 12 3 11.3284 3 10.5 3 9.67157 3.67157 9 4.5 9 5.32843 9 6 9.67157 6 10.5zM11.8333 10.5C11.8333 11.3284 11.1618 12 10.3333 12 9.50492 12 8.83334 11.3284 8.83334 10.5 8.83334 9.67157 9.50492 9 10.3333 9 11.1618 9 11.8333 9.67157 11.8333 10.5zM17.6667 10.5C17.6667 11.3284 16.9951 12 16.1667 12 15.3383 12 14.6667 11.3284 14.6667 10.5 14.6667 9.67157 15.3383 9 16.1667 9 16.9951 9 17.6667 9.67157 17.6667 10.5z" fill="currentColor"></path></svg>
                                </button>
                                <ng-template #completedPositionmenuButtons>
                                    <fa-icon title="Clone" *ngIf="(userRole =='Senior Manager') || (userRole =='Account Manager') || (userRole =='Superuser') || (userRole =='Hiring Manager') || (userRole =='Client Admin')|| (userRole =='Human Resource')" (click)="duplicatePosition(item.id)" [icon]="faClone"></fa-icon>
                                    <fa-icon title="Trash" *ngIf="(userRole =='Account Manager') || (userRole =='Superuser') || (userRole =='Senior Manager') || (userRole =='Client Admin') || (userRole =='Hiring Manager' || userRole =='Hiring Member')" (click)="openSm(content, item.id)" [icon]="faDelete"></fa-icon>
                                </ng-template>
                            </a>
                        </li>
                    </ul>
                    <p *ngIf="completedPositionList?.length < 1">
                        No closed position available!
                    </p>
                </ng-template>
            </ngb-panel>

            <ngb-panel id="trashedPosition" *ngIf="trashedPositionList?.length && userRole !== 'Candidate' && userRole !== 'Hiring Member'">
                <ng-template ngbPanelTitle>
                    <span>
                        <fa-icon class="accordion-image mr-2" [icon]="faTrashAlt"></fa-icon>
                        <strong class="mt-4 position_type">Trashed Positions</strong>
                    </span>
                </ng-template>
                <ng-template ngbPanelContent>
                    <ul class="nav nav-sidebar" *ngIf="trashedPositionList?.length">
                        <li *ngFor="let item of trashedPositionList | searchFilter: query : 'position_title' ; let i = index" [ngClass]="{active: isActive(item)}">
                            <a href="javascript:void(0)">
                                {{item.position_title}}
                                <button [hidden]="(userRole =='Hiring Manager' || userRole =='Hiring Member') && !item?.is_hiring_manager" type="button" class="menu-button-cntr" tooltipClass="custom-tooltip" [ngbTooltip]="trashedPositionmenuButtons" triggers="click:blur" aria-haspopup="true" aria-expanded="false" aria-label="Menu" aria-disabled="false">
                                    <svg viewBox="0 0 20 20" fill="currentColor" width="28" height="28" role="img" aria-hidden="true"><path d="M6 10.5C6 11.3284 5.32843 12 4.5 12 3.67157 12 3 11.3284 3 10.5 3 9.67157 3.67157 9 4.5 9 5.32843 9 6 9.67157 6 10.5zM11.8333 10.5C11.8333 11.3284 11.1618 12 10.3333 12 9.50492 12 8.83334 11.3284 8.83334 10.5 8.83334 9.67157 9.50492 9 10.3333 9 11.1618 9 11.8333 9.67157 11.8333 10.5zM17.6667 10.5C17.6667 11.3284 16.9951 12 16.1667 12 15.3383 12 14.6667 11.3284 14.6667 10.5 14.6667 9.67157 15.3383 9 16.1667 9 16.9951 9 17.6667 9.67157 17.6667 10.5z" fill="currentColor"></path></svg>
                                </button>
                                <ng-template #trashedPositionmenuButtons>
                                    <fa-icon title="Restore" *ngIf="(userRole =='Account Manager') || (userRole =='Superuser') || (userRole =='Senior Manager') || (userRole =='Client Admin') || (userRole =='Hiring Manager' || userRole =='Hiring Member')" (click)="restoreTrashedPosition(item.id)" [icon]="faTrashRestore"></fa-icon>
                                    <fa-icon title="Delete Permanently" *ngIf="userRole =='Superuser' || userRole =='Senior Manager' || userRole =='Client Admin'" (click)="openSm(deletePos, item.id)" [icon]="faDelete" style="right:35px; top:12px;"></fa-icon>
                                </ng-template>
                            </a>
                        </li>
                    </ul>
                    <p *ngIf="trashedPositionList?.length < 1">
                        No trashed position available!
                    </p>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
        <!-- <app-protip></app-protip> -->
    </div>
</nav>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Delete Client</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Do you want to delete client?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="deleteClient()">Yes</button>
    </div>
</ng-template>

<ng-template #trashPos let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Trash Position</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Do you want to trash this Position?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="trashPosition()">Yes</button>
    </div>
</ng-template>

<ng-template #deletePos let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Permanently Delete this Position?</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Do you want to permanently delete this Position? You will not be able to restore it later.</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="deletePosition()">Yes</button>
    </div>
</ng-template>

<ng-template #htmConfirm let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Important</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p>To complete this process you must add 
            team members and create a Hiring 
            Team. Click the Teams button below to 
            start the process.</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="moveToHtmPage()">Teams</button>
        <div class="pt-4" >
            <p class="mb-0" >Learn more about Hiring Teams.</p>
            <video width="260" height="170" controls>
                <source type="video/mp4" src="https://qorums.com/wp-content/uploads/2023/08/3rd-Revise-720-8-28-23.mp4" />    
            </video>
        </div>
    </div>
</ng-template>