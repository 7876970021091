import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './core/_guards/auth.guard';
import { ClientsPositionComponent } from './features/clients-position/clients-position.component';
import { ClientComponent } from './features/create-client/client.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { PagenotfoundComponent } from './features/pagenotfound/pagenotfound.component';
import { PositionsComponent } from './features/positions/positions.component';
import { SubDashboardComponent } from './features/sub-dashboard/sub-dashboard.component';
import { SubscriptionComponent } from './core/subscription/subscription.component';
import { FirstLoginComponent } from './features/first-login/first-login.component';
import { SettingsComponent } from './features/settings/settings.component';
import { CreateQorumsSupportComponent } from './features/create-qorums-support/create-qorums-support.component';
import { SsoCallbackComponent } from './features/sso-callback/sso-callback.component';
import { ResetPasswordComponent } from './core/reset-password/reset-password.component';
import { CreateUserComponent } from './features/create-user/create-user.component';
import { AllUsersComponent } from './features/all-users/all-users.component';
import { CanFormDeactivateGuard } from './core/_guards/form.guard';
import { PositionSummaryComponent } from './features/position-summary/position-summary.component';
import { AllPositionsComponent } from './features/all-positions/all-positions.component';
import { PositionDetailsComponent } from './features/position-details/position-details.component';
import { AllClientsComponent } from './features/all-clients/all-clients.component';
// import { InterviewScreenComponent } from './features/interview-screen/interview-screen.component';
import { NewPositionComponent } from './features/new-position/new-position.component';
import { AddNewCandidateComponent } from './features/add-new-candidate/add-new-candidate.component';
import { ViewAllUsersComponent } from './features/view-all-users/view-all-users.component';
import { InviteUsersComponent } from './features/invite-users/invite-users.component';
import { MyCalenderComponent } from './features/my-calender/my-calender.component';
import { CandidateDashboardComponent } from './features/candidate-dashboard/candidate-dashboard.component';
import { HtmDasboardComponent } from './features/htm-dasboard/htm-dasboard.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'interview', loadChildren: () => import('./modules/interview/interview.module').then(m => m.InterviewModule) },
  { path: 'pricing', component: SubscriptionComponent },
  { path: 'job-board', loadChildren: () => import('./modules/qjboard/qjboard.module').then(m => m.QJBoardModule) },
  { path: ':role/view-all-clients', component: AllClientsComponent },
  { path: ':role/my-calender', component: MyCalenderComponent },
  { path: ':role/all-users', component: AllUsersComponent, canActivate: [AuthGuard] },
  { path: ':role/view-all-users', component: ViewAllUsersComponent },
  { path: ':role/view-all-users/invite-users', component: InviteUsersComponent },
  { path: ':role/create-user', component: CreateUserComponent, canActivate: [AuthGuard], canDeactivate: [CanFormDeactivateGuard] },
  { path: ':role/settings', loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule) },
  { path: ':role/view-all-positions', component: AllPositionsComponent },
  // { path: ':role/interview-screen/:positionId/:candidateId/:interviewId', component: InterviewScreenComponent, canDeactivate: [CanFormDeactivateGuard] },
  { path: ':role/new-position', component: NewPositionComponent, canDeactivate: [CanFormDeactivateGuard] },
  { path: ':role/add-new-candidate', component: AddNewCandidateComponent },
  { path: ':role/details', component: SubDashboardComponent },
  {
    path: ':role/dashboard', component: DashboardComponent, canActivate: [AuthGuard], children: [
      { path: 'candidate-dashboard', component: CandidateDashboardComponent },
      { path: 'htm-dashboard', component: HtmDasboardComponent },
      { path: 'details', component: SubDashboardComponent },
      { path: 'addClient', component: ClientComponent, canDeactivate: [CanFormDeactivateGuard] },
      { path: 'createQorumsSupport', component: CreateQorumsSupportComponent, canDeactivate: [CanFormDeactivateGuard] },
      { path: 'first-login', component: FirstLoginComponent },
      { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], canDeactivate: [CanFormDeactivateGuard] },
      { path: '', redirectTo: 'details', pathMatch: 'full' },
    ]
  },
  {
    path: ':role/position', component: PositionsComponent, canActivate: [AuthGuard], children: [
      { path: 'clientPosition', component: ClientsPositionComponent },
      { path: 'position-summary', component: PositionSummaryComponent },
      { path: '', redirectTo: 'clientPosition', pathMatch: 'full' },
    ]
  },
  { path: ':role/position-details/:positionId', component: PositionDetailsComponent },
  { path: ':role/add-new-candidate/:positionId', component: AddNewCandidateComponent },
  { path: 'callback/ac', component: SsoCallbackComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', component: PagenotfoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModules {
}
