import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitString'
})
export class SplitStringPipe implements PipeTransform {

  transform(value: string, delimiter: string): string[] {
    return value.split(delimiter).map(position => position.trim());
  }
}