import { Component, OnInit } from '@angular/core';
import {
  faUserPlus,
  faFlagCheckered,
  faHourglassStart,
  faCalendarCheck,
  faComments,
  faExclamationCircle,
  faCamera
} from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.services';
@Component({
  selector: 'app-htm-dasboard',
  templateUrl: './htm-dasboard.component.html',
  styleUrls: ['./htm-dasboard.component.css']
})
export class HtmDasboardComponent implements OnInit {
  userdata: any;
  posCalendarData: any;
  htmCalendarData: any;
  showHtmInfo: boolean = false;
  faFlagCheckered = faFlagCheckered;
  faHourglassStart = faHourglassStart;
  faCalendarCheck = faCalendarCheck;
  faComments = faComments;
  faExclamationCircle = faExclamationCircle;
  faCamera = faCamera;
  faUserPlus = faUserPlus;
  faCalendarAlt = faCalendarAlt
  activePositions: any;
  upcomingInterviews: any;
  constructor(private userService: UserService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.userdata = localStorage.getItem('currentUser');
    this.userdata = JSON.parse(this.userdata);
    localStorage.removeItem('currentPosition')
    this.getActivePositionsForCalendar()
    this.getUpcomingInterviews();
  }
  htmDataFromCalendar(data: any) {
    this.posCalendarData = null;
    if (data === 'clear') {
      this.showHtmInfo = false;
      this.htmCalendarData = null;
      return
    }
    this.showHtmInfo = true;
    this.htmCalendarData = data
  }
  posDateDataFromCalendar(data: any) {
    this.htmCalendarData = null;
    if (data === 'clear') {
      this.showHtmInfo = false;
      this.posCalendarData = null;
      return
    }
    this.showHtmInfo = true;
    this.posCalendarData = data;
  }
  startInterview(data?: any) {
    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree([this.authService.currentUserRoleCode, 'interview-screen', data.position_id, data.candidate_id, data.interview_id])
    // );
    // window.open(url, "_blank");
    const interviewId = data.interview_id;
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['interview', interviewId])
    );
    window.open(url, "_blank");
  }
  startInterviewFromCalendar(){
    const url = this.router.serializeUrl(
      this.router.createUrlTree([this.authService.currentUserRoleCode, 'interview-screen', this.htmCalendarData.position_id, this.htmCalendarData.candidate_id, this.htmCalendarData.id])
    );
    window.open(url, "_blank");
  }
  isDateToday(d: string) {
    var inputDate = new Date(d);
    var todaysDate = new Date();
    if (inputDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
      return true
    }
    return false
  }
  getActivePositionsForCalendar() {
    this.userService.getActivePositionsForCalendar().subscribe((res: any) => {
      this.activePositions = res.data;
    })
  }
  getUpcomingInterviews() {
    this.userService.getUpcomingInterviews().subscribe((res: any) => {
      this.upcomingInterviews = res.data;
    })
  }
}
