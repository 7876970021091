import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { faVideo,faLessThan,faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { addHours, isSameDay, isSameMonth, startOfDay } from 'date-fns';
import { Subject } from 'rxjs';
import { ToastService } from 'src/app/core/toast/toast-service';
import { AuthService } from 'src/app/services/auth.services';
import { CandidateService } from 'src/app/services/candidate.service';
const pad = (i: number): string => i < 10 ? `0${i}` : `${i}`;
@Component({
  selector: 'app-candidate-schedule',
  templateUrl: './candidate-schedule.component.html',
  styleUrls: ['./candidate-schedule.component.css']
})
export class CandidateScheduleComponent implements OnInit {

  public today = {year:new Date().getFullYear(), month:new Date().getMonth()+1, day: new Date().getDate()};
  public yesterday = {year:new Date().getFullYear(), month:new Date().getMonth()+1, day: new Date().getDate() - 1};
  // public imgBaseUrl = Url.imgBaseUrl;
  // faCalendar = faCalendarAlt;

  faLessThan = faLessThan
  faGreaterThan = faGreaterThan;
  @Output() calendarUpdated = new EventEmitter();
  @Output() interviewData = new EventEmitter();
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData!: {
    action: string;
    event: CalendarEvent;
  };

  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = true;
  @Input() calendarData: any;
  @Input() activePositionsForCand:any
  immutableCalendarData: any;
  allInterviews: any;
  requestedInterviews: any;
  scheduledInterviews: any;
  availableSlots: any;
  selectedPositionId: any;
  meetingUrl: any;
  faVideo = faVideo;
  @ViewChild("modalRoot") modalRoot!: TemplateRef<any>;
positionList: any=[];
  op_idFromDropDown: any;
  constructor(
    private candidateService: CandidateService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.immutableCalendarData = JSON.parse(JSON.stringify(this.calendarData));
    this.createCalendar();
    this.candidateService.getSelectedCandidate().subscribe((pos) => {
      this.refreshCandidateData(pos);
      if(pos?.id) {
        this.createCalendar(pos?.id);
      }
    });
  }

  refreshCandidateData(pos: any) {
    const currentUserRoleCode = this.authService.currentUserRoleCode;
    if(currentUserRoleCode=='/can'){
      this.selectedPositionId = pos.id;
      this.getCandidateSchedules();
    }
  }

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh = new Subject<void>();

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.getAllPositionCalendar()
    this.activeDayIsOpen = false;
    if(this.op_idFromDropDown){
      this.getOP_IDScheduleCalendar(this.op_idFromDropDown)
    }
    else{
      this.getAllPositionCalendar()
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: startOfDay(new Date()),
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    if(action=='Clicked'){
      this.interviewData.emit(event.meta.info)
    }
  }

  getCandidateSchedules() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
    this.candidateService.getCandidateSchedules(currentUser.candidate_id).subscribe((res: any) => {
      this.allInterviews = res.data;
      this.candidateService.setAllInterviews(this.allInterviews);
      this.scheduledInterviews = this.allInterviews.filter((el: any) => el.accepted && this.selectedPositionId === el.op_id);
      this.requestedInterviews = this.allInterviews.filter((el: any) => !el.accepted && this.selectedPositionId === el.op_id);
    });
  }

  createCalendar(op_id?: any) {
    this.events = [];
    if(op_id) {
      this.calendarData = this.immutableCalendarData.filter((el: any) => el.op_id === op_id);
    } else {
      this.calendarData = this.immutableCalendarData;
    }
    for(var key in this.calendarData){
      if (this.calendarData[key].scheduled.length > 0){
        // let htmData = this.calendarData[key].scheduled[0]
        this.calendarData[key].scheduled.forEach(htmData=>{
          const d = key.split("-");
          const dateObj = new Date(d[2] + '/' + d[1] + '/' + d[0]);
          let d1 = new Date();
          d1.setHours(0, 0, 0, 0);
          this.events.push({
            start:startOfDay(dateObj),
            title:htmData.date + ' (' +htmData.long_timezone +')' +'<br /> Interview with ' + htmData.interviewer_names + ' for Position '+htmData.position_name,
            cssClass: dateObj < d1 ? 'icon-calendar-scheduled-past-date' : 'icon-calendar-scheduled',
            actions: this.actions,
            meta: {
              info: htmData
            }
          })
        })
      }
      
    }
  }

  formatTime(time: any) {
    if(time.split(':')[1] === '0') {
      time += '0';
    }
    if(parseInt(time.split(':')[0]) === 0) {
      const hour = 12;
      const min = time.split(':')[1];
      return hour + ':' + min + 'AM';
    } else if(parseInt(time.split(':')[0]) < 12) {
      return time + 'AM';
    } else if(parseInt(time.split(':')[0]) === 12) {
      return time + 'PM';
    } else {
      const hour = pad(parseInt(time.split(':')[0]) - 12);
      const min = time.split(':')[1];
      return hour + ':' + min + 'PM';
    }
  }

  updateInterviewResponse(schedule: any, response: boolean, slotContent?: any) {
    if(!response) {
      this.candidateService.getIntervieweSlots(schedule.interview_id).subscribe((res: any) => {
        this.availableSlots = res.data;
        this.modalService.open(slotContent, { size: 'md' });
      });
    } else {
      this.saveInterviewResponse(schedule, response);
    }
  }
  saveInterviewResponse(schedule: any, response: any) {
    this.candidateService.updateInterviewResponse(schedule.interview_id, {accepted: response}).subscribe((res: any) => {
      this.showSuccess('Your interview is scheduled!')
      this.getCandidateSchedules();
      this.getCandidateCalendar();
    });
  }

  selectedSlots: any = {
    availableDays: []
  };
  toggleSlotSelection(e: any, slot: any) {
    if(e.target.checked) {
      slot.checked = true;
    } else {
      slot.checked = false;
    }
    this.selectedSlots.availableDays = [];
    const checkedSlots = this.availableSlots.filter((el: any) => el.checked);
    checkedSlots.forEach((slot: any) => {
      this.selectedSlots.availableDays.push(slot);
    });
  }

  saveCandidateSchedule() {
    this.candidateService.setCandidateAvailability(this.selectedSlots).subscribe((res: any) => {
      this.showSuccess('Slots saved successfully');
    });
  }

  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  fetchZohoMeeting(link: any) {
    this.meetingUrl = link.meeting_link;
  }

  getCandidateCalendar() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser') || '');
    this.candidateService.getCandidateCalendar(currentUser.candidate_id).subscribe((res: any) => {
      this.calendarData = res.data;
      this.immutableCalendarData = JSON.parse(JSON.stringify(this.calendarData));
      this.candidateService.getSelectedCandidate().subscribe((pos) => {
        this.refreshCandidateData(pos);
        if(pos?.id) {
          this.createCalendar(pos?.id);
        }
      });
    });
  }
  onPositionSelect(e:any){
    if(e.target.value==0){
      return
    }
    else if(e.target.value=='all'){
      this.getAllPositionCalendar()
      this.op_idFromDropDown = null
    }
    else{
      this.getOP_IDScheduleCalendar(e.target.value)
      this.op_idFromDropDown = e.target.value
    }
    
  }
  getAllPositionCalendar(){
    const month = ("0" + (this.viewDate.getMonth() + 1)).slice(-2);
    const year = this.viewDate.getFullYear();
    this.candidateService.getCandidateScheduleCalendar(month,year).subscribe((res:any)=>{
      this.immutableCalendarData = res?.data
      this.createCalendar()
    })
  }
  getOP_IDScheduleCalendar(op_id:any){
    const month = ("0" + (this.viewDate.getMonth() + 1)).slice(-2);
    const year = this.viewDate.getFullYear();
    this.candidateService.getCandidateCalendarForPosition(month,year,op_id).subscribe((res:any)=>{
      this.immutableCalendarData = res?.data
      this.createCalendar()
    })
  }
}
