<main id="positions-content">
    <app-header style="z-index: 99;position: relative"></app-header>
    <div class="widget-wrapper" style="bottom: 0;">
        <app-detail-header [UsersCount]=" userscount" [UsersList]="usersList" (searchText)="searchText($event)"
            (userAction)="userActionFunc()">
        </app-detail-header>
        <div class="page-body">
            <div class="sub-navbar">
                <ul class="mt-4 mb-2">
                    <li (click)="clickPos('Candidates')" class="" [ngClass]="status1 ? 'show' : 'hide'">Candidates <span
                            class="count-span"> {{candList?.length > 9 ? candList?.length : '0'+candList?.length }} </span>
                    </li>
                    <li *ngIf="currentUserRoleCode=='/sa'" (click)="clickPos('CA')" class=""
                        [ngClass]="status6 ? 'show' : 'hide'">Client Admin <span class="count-span"> {{caList?.length > 9
                            ? caList?.length : '0'+ caList?.length}} </span></li>
                    <li (click)="clickPos('SM')" class="" [ngClass]="status2 ? 'show' : 'hide'">Senior Manager <span
                            class="count-span"> {{smList?.length > 9 ? smList?.length : '0'+ smList?.length}} </span></li>
                    <li (click)="clickPos('HM')" class="" [ngClass]="status3 ? 'show' : 'hide'">Hiring Members <span
                            class="count-span"> {{hmList?.length > 9 ? hmList?.length : '0'+hmList?.length }} </span></li>
                    <li (click)="clickPos('Pending')" class="" [ngClass]="status4 ? 'show' : 'hide'">Pending <span
                            class="count-span"> {{completedPositionList?.length > 9 ? completedPositionList?.length : '0'
                            + completedPositionList?.length}} </span></li>
                    <li (click)="clickPos('All')" class="" [ngClass]="status5 ? 'show' : 'hide'">All <span
                            class="count-span"> {{usersListData?.length >9 ? usersListData?.length :
                            '0'+usersListData?.length}} </span></li>

                </ul>
            </div>

            <div class="widget-box">
                <div class="custom-scroll-bar default-rounded-table" #scrollDiv style="height: 700px;">
                    <table class="table table-striped Users-table">
                        <tbody>
                            <tr style="background-color: #EBEBEB;">
                                <td [class.active-sorted-table]="sortedTable==='name'" colspan="3" scope="col">
                                    <input class="c-checkbox mr-3" type="checkbox" id="position" [(ngModel)]="selectAll"
                                        (change)="selectAllItems()">
                                    USER NAME <span class="float-right cursor-pointer"><fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"
                                            (click)="sortTable('name')"></fa-icon> </span>
                                </td>
                                <td [class.active-sorted-table]="sortedTable==='user_id'" *ngIf="col_HM" scope="col">
                                    USER ID <span class="float-right cursor-pointer"
                                        (click)="sortTable('user_id')"><fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <td [class.active-sorted-table]="sortedTable==='status'" *ngIf="col_HM" scope="col">
                                    STATUS <span class="float-right cursor-pointer"
                                        (click)="sortTable('status')"><fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <!-- <th scope="col" (click)="sortTable('hiring_team')">Hiring Team <fa-icon [icon]="faSort"></fa-icon></th> -->
                                <td [class.active-sorted-table]="sortedTable==='user_since'" *ngIf="col_curr_status"
                                    scope="col">User
                                    Since<span class="float-right cursor-pointer"
                                        (click)="sortTable('user_since')"><fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <td [class.active-sorted-table]="sortedTable==='last_activity'" *ngIf="col_target"
                                    scope="col">Last Activity
                                    <span class="float-right cursor-pointer"
                                        (click)="sortTable('last_activity')"><fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <td [class.active-sorted-table]="sortedTable==='open_position'" *ngIf="col_total_pos"
                                    scope="col">Open
                                    Positions<span class="float-right cursor-pointer"
                                        (click)="sortTable('open_position')"> <fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <td [class.active-sorted-table]="sortedTable==='email'" *ngIf="col_pos_filled"
                                    scope="col">Email ID<span class="float-right cursor-pointer"
                                        (click)="sortTable('email')"> <fa-icon [icon]="faSort"
                                            [styles]="{'stroke': '#999999', 'color': '#999999', 'font-size': '20px'}"></fa-icon></span>
                                </td>
                                <td scope="col">Action</td>
                                <td *ngIf="status1" scope="col">Resume</td>
                                <td *ngIf="status1" scope="col">LinkedIn</td>
                            </tr>

                            <tr *ngFor="let val of usersTable | searchFilter: query : 'name' ; let i = index ">
                                <td style="border-right:none ; width: 5.5%;">
                                    <div class="d-flex align-items-center">
                                        <input type="checkbox" class="c-checkbox mr-0 "
                                            (change)="usersSelect(val,usersItem[i])" [checked]="usersItem[i]"
                                            [(ngModel)]="usersItem[i]" id="users" [value]="usersItem[i]">
                                        <span class="ml-2 text-muted ">({{getRole(val.role)}})</span>
                                    </div>
                                </td>
                                <td style="border-right:none; width: 0%;" class="px-0">
                                    <p *ngIf="false" class="pending-mark mb-0"></p>
                                </td>
                                <td style="color:var(--primary-200)">{{val.name | titlecase}}</td>
                                <td>{{val.user_id || 'NA' }}</td>
                                <td>{{val.status=='notstarted'?'Not Started':val.status | titlecase}}</td>
                                <!-- <td>
                                    <button *ngIf="val.status=='completed'" class="btn internal-button"
                                        (click)="openForgotPasswordPopup(forgotPwd,val)">Reset Password</button>
                                    <span *ngIf="val.status=='incomplete'">NA</span>
                                </td> -->
                                <td>{{val.user_since || 'NA'}}</td>
                                <td>{{val.last_activity || 'NA'}}</td>
                                <td>{{val.open_position || 'NA'}}</td>
                                <td style="color: var(--primary-200);">{{val.email | truncate:20 || 'NA'}}</td>
                                <td class="d-flex justify-content-center">
                                    <button *ngIf="val.status=='completed'" class="btn internal-button"
                                        (click)="openForgotPasswordPopup(forgotPwd,val)">Reset Password</button>
                                    <button *ngIf="val.status=='incomplete'" class="btn internal-button"
                                        (click)="openReminderPopup(remindUser,val)">Remind User</button>
                                    <button *ngIf="val.status=='notstarted'" class="btn internal-button"
                                        (click)="openReminderPopup(remindUser,val)">Resend Invite</button>
                                </td>
                                <td *ngIf="status1" scope="col">
                                    <a *ngIf="val.resume[0]" class="" href="javascript:void(0)"
                                        (click)="openFile(val.resume[0])">View Resume</a>
                                    <span *ngIf="!val.resume[0]">No Resume</span>
                                </td>
                                <td *ngIf="status1" scope="col">
                                    <a *ngIf="val.linkedin" class="" href="javascript:void(0)"
                                        (click)="openFile(val.linkedin)">View LinkedIn</a>
                                    <span *ngIf="!val.linkedin">No LinkedIn</span>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</main>


<ng-template #forgotPwd let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Send password reset email to user</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="overflow: auto; max-height: 400px;">
        <p>We will send a passord reset email to - {{currentUserReset.name}}</p>
        <p>Email Id - <strong>{{currentUserReset.email}}</strong></p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">Close</button>
        <button type="button" class="btn btn-primary"
            (click)="sendPasswordResetEmail(currentUserReset.email)">Confirm</button>
    </div>
</ng-template>

<ng-template #remindUser let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Send Reminder email to user</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="overflow: auto; max-height: 400px;">
        <p>We will send a signup reminder email to - {{currentUserReminder.name}}</p>
        <p>Email Id - <strong>{{currentUserReminder.email}}</strong></p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">Close</button>
        <button type="button" class="btn btn-primary"
            (click)="sendReminderEmail(currentUserReminder.email)">Confirm</button>
    </div>
</ng-template>