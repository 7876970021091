import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CandidateService } from 'src/app/services/candidate.service';
import { HiringTeamService } from 'src/app/services/hiringteam.service';
import * as Url from './../../constants/url';

@Component({
  selector: 'app-htm-timeline-chart',
  templateUrl: './htm-timeline-chart.component.html',
  styleUrls: ['./htm-timeline-chart.component.css']
})
export class HtmTimelineChartComponent implements OnInit, OnDestroy {
  @Input() deadlineDates: any;
  chartData: any = [];
  imageBaseUrl = Url.imgBaseUrl;
  kickoffDate: any;
  sourcingDeadlineDate: any;
  targetDeadlineDate: any;
  subscriptions$: any;
  isProgressSummary = false;
  maxDeadlineInASingleDay = 0;
  showLegends: boolean = false;
  interviewDates:any=[];
  allDates:any=[];
  fullKickoffDate: any;
  fullsourcingDate: any;
  fulltargetDate: any;
  constructor(
    private hiringTeamService: HiringTeamService,
    private candidateService: CandidateService
  ) {
      
  }

  ngOnInit(): void {
    this.subscriptions$ = this.candidateService.getSelectedCandidate().subscribe((val) => {
      if (val.id && !this.deadlineDates) {
        this.getPositionSummary();
        this.isProgressSummary = true;
        this.showLegends = false;
      } else if(this.deadlineDates) {
        if(this.deadlineDates.htm_deadlines[0].deadline)
            this.isProgressSummary = true;
        this.getTempPositionSummary();
        this.showLegends = true;
      }
      
    });
    
  }

  getPositionSummary() {
    this.chartData=[];
    this.kickoffDate=null;
    this.sourcingDeadlineDate=null;
    this.targetDeadlineDate=null;
    this.fullKickoffDate = null;
    this.fullsourcingDate = null;
    this.fulltargetDate = null;
    const op_id = localStorage.getItem('currentPosition');
    if(!op_id) {
      return;
    }
    this.hiringTeamService.getPositionSummary(op_id).subscribe((res: any) => {
      this.chartData=[];
      this.maxDeadlineInASingleDay = 0;
      this.chartData = res;
      this.getInterviewDetails();
      if(res.position_status!=='open'){
        this.showLegends = true;
      }
      Object.keys(res.dates).forEach((e: any) => { 
        if(res.dates[e].stage === 'kickoff') {
          this.kickoffDate = e;
          this.fullKickoffDate = res.dates[e].date
        }
      });
      Object.keys(res.dates).forEach((e: any) => { 
        if(res.dates[e].stage === 'sourcing') {
          this.sourcingDeadlineDate = e;
          this.fullsourcingDate = res.dates[e].date
        }
      });
      Object.keys(res.dates).forEach((e: any) => { 
        if(res.dates[e].stage === 'target') {
          this.targetDeadlineDate = e;
          this.fulltargetDate = res.dates[e].date
        }
      });
      this.getDateDiffArray()
    })
  }

  getTempPositionSummary() {
    this.chartData=[];
    this.kickoffDate=null;
    this.sourcingDeadlineDate=null;
    this.targetDeadlineDate=null;
    this.fullKickoffDate = null;
    this.fullsourcingDate = null;
    this.fulltargetDate = null;
    this.hiringTeamService.getTempPositionSummary(this.deadlineDates).subscribe((res: any) => {
      this.chartData=[];
      this.chartData = res;
      //this.getInterviewDetails();
      
      Object.keys(res.dates).forEach((e: any) => { 
        if(res.dates[e].stage === 'kickoff') {
          this.kickoffDate = e;
          this.fullKickoffDate = res.dates[e].date
        }
      });
      Object.keys(res.dates).forEach((e: any) => { 
        if(res.dates[e].stage === 'sourcing') {
          this.sourcingDeadlineDate = e;
          this.fullsourcingDate = res.dates[e].date
        }
      });
      Object.keys(res.dates).forEach((e: any) => { 
        if(res.dates[e].stage === 'target') {
          this.targetDeadlineDate = e;
          this.fulltargetDate = res.dates[e].date
        }
      });
      this.getDateDiffArray()
      this.assignHtmColor(res);
    })
  }

  assignHtmColor(res: any) {
    let form3Data = JSON.parse(localStorage.getItem('form3Data') || '');
    form3Data.members.map((el: any) => {
      el.color = res.htms.filter((htm: any) => el.id === htm.id)[0]?.color;
    });
    localStorage.setItem('form3Data', JSON.stringify(form3Data));
  }

  getBackgroundColor(obj: any) {
    return obj.background;
  }

  getDateColor(obj: any) {
    return obj.stage === 'kickoff' ? '#7296f8' : obj.stage === 'sourcing' ? '#d6ba1c' : obj.background;
  }

  isTargetDeadline(obj: any) {
    return obj.stage === 'target';
  }

  getDeadlines(obj: any) {
    if(obj.deadlines.length > this.maxDeadlineInASingleDay) {
      this.maxDeadlineInASingleDay = obj.deadlines.length;
    }
     return obj.deadlines;
  }

  getInterviews(obj: any) {
    return obj.interviews_list;
  }

  getHtmBackground(obj: any) {
    return this.chartData.htms.filter((el: any) => el.id === obj)[0]?.color;
  }

  getBarHeight(obj: any) {
    return 100/obj.deadlines.length + '%';
  }

  getInterviewBarHeight(obj: any) {
    return 100/obj.interviews_list.length + '%';
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe()
  }
  isPrevious(htm:any,len:any){
    for(let i=0;i<len;i++){
      if(this.chartData.htm_deadlines[i].deadline == htm.deadline)
          return true
    }
    return false
  }

  // To position checkpoints on line percentage wise right or left
  calculatePosition(d: Date): number {
    
    const date = new Date(d); 
    var year = date.getFullYear();
    // var startDate= this.getProperDate(this.kickoffDate,year);
    // var endDate= this.getProperDate(this.targetDeadlineDate,year);
    var startDate = new Date(this.fullKickoffDate);
    var endDate = new Date(this.fulltargetDate);
    const totalDays = (date.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
    const totalTime = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
    return ((totalDays / totalTime) * 100);
  }

  calculateKickoffPosition(): number {
    const date = new Date(this.fullKickoffDate); 
    //var year = date.getFullYear();
    // var startDate= this.getProperDate(this.kickoffDate,year);
    // var endDate= this.getProperDate(this.targetDeadlineDate,year);
    var startDate = new Date(this.fullKickoffDate);
    var endDate = new Date(this.fulltargetDate);
    const totalDays = (date.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
    const totalTime = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
    return ((totalDays / totalTime) * 100);
  }
  calculateSourcingPosition(): number {
    const date = new Date(this.fullsourcingDate); 
    //var year = date.getFullYear();
    // var startDate= this.getProperDate(this.kickoffDate,year);
    // var endDate= this.getProperDate(this.targetDeadlineDate,year);
    var startDate = new Date(this.fullKickoffDate);
    var endDate = new Date(this.fulltargetDate);
    const totalDays = (date.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
    const totalTime = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
    return ((totalDays / totalTime) * 100);
  }
  calculateTargetPosition(): number {
    const date = new Date(this.fulltargetDate); 
    //var year = date.getFullYear();
    // var startDate= this.getProperDate(this.kickoffDate,year);
    // var endDate= this.getProperDate(this.targetDeadlineDate,year);
    var startDate = new Date(this.fullKickoffDate);
    var endDate = new Date(this.fulltargetDate);
    const totalDays = (date.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
    const totalTime = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
    return ((totalDays / totalTime) * 100);
  }
// To get proper date format bcoz dates in API are unstructured
  getProperDate(date:any,year:any){
    var dateString = date;
    var parts = dateString.split('/');
    var month = parseInt(parts[0], 10);
    var day = parseInt(parts[1], 10);
    return  new Date(year, month - 1, day);
  }

  getYear():any{
    if(this.chartData.htm_deadlines.length>0){
    const d=new Date(this.chartData.htm_deadlines[0].deadline);
    return d.getFullYear();
    }
    const d =new Date();
    return d.getFullYear();
  }

  getHtmDeadlineDateForScreen(date:any){
    var parts = date.split('-');
    return parts[1]+"/"+parts[2]
  }

  // getting interview dates of candidates and its details to show in checkpoint 3 and tooltip
  getInterviewDetails(){
    var obj:any={};
    this.interviewDates=[];
    for(let key in this.chartData.dates){
      if(this.chartData.dates[key].interviews_list.length>0)
      { 
        obj.date=key;
        obj.details=this.chartData.dates[key].interviews_list
        this.interviewDates.push(obj)
        obj={};
      }
    }
  }
// calculating date difference for chekpoint 3 and calculating middle date to show connecting vertical lines
  getDateDiffArray(){
    var year=this.getYear();
    if(!year){
      const d=new Date();
      year=d.getFullYear();
    }
    this.allDates=[]
    var dateArray:any=[];
    //this.getProperDate(this.kickoffDate,year);
    // dateArray.push(this.getProperDate(this.kickoffDate,year))
    // dateArray.push(this.getProperDate(this.sourcingDeadlineDate,year))
    // dateArray.push(this.getProperDate(this.targetDeadlineDate,year))
    dateArray.push(new Date(this.fullKickoffDate));
    dateArray.push(new Date(this.fullsourcingDate));
    dateArray.push(new Date(this.fulltargetDate));
    if(this.chartData.htm_deadlines.length>0){
      for(let i=0;i<this.chartData.htm_deadlines.length;i++){
        let d=this.getHtmDeadlineDateForScreen(this.chartData.htm_deadlines[i].deadline)
        dateArray.push(this.getProperDate(d,year))
      }
    }
    dateArray.sort(this.compareDates)
    console.log("All the dates"+dateArray);
    var obj:any={};
    for(let i=0;i<(dateArray.length)-1;i++){
      if(dateArray[i+1].getTime()!=dateArray[i].getTime()){
      obj.date= new Date((dateArray[i+1].getTime() + dateArray[i].getTime()) / 2);
      obj.difference = this.calculateAngularDifference(dateArray[i],dateArray[i+1])
      this.allDates.push(obj);
      obj={};
    }
    }
  }
  compareDates(a:any, b:any) {
    return a - b;
  }
// calculating difference between days between 2 checkpoints
  calculateAngularDifference(d1:any,d2:any):any {
    const timeDiff = Math.abs(new Date(d2).getTime() - new Date(d1).getTime());
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff;
  }

}
