import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.services';
import { CandidateService } from 'src/app/services/candidate.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import * as URL from './../../constants/url';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  faPlus,
  faSearch,
  faSort,
  faEllipsisH,
  faColumns
} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-all-positions',
  templateUrl: './all-positions.component.html',
  styleUrls: ['./all-positions.component.css']
})
export class AllPositionsComponent implements OnInit {
  userRole: any;
  currentPosition: any;
  currentUserRoleCode: any;
  activeClientName: any;
  selectedCandidate: any;
  tableData: any;
  currentClient: any;
  logo: any;
  isHtmAvailable: boolean = false;
  query: any;
  faSearch = faSearch;
  faPlus = faPlus;
  faSort = faSort;
  faColumns = faColumns;
  faEllipsisH = faEllipsisH;
  clientPositionData: any;
  draftedPositionList: any = [];
  archivedPositionList: any = [];
  completedPositionList: any = [];
  trashedPositionList: any = [];
  sortKey: any = '';
  reverse: boolean = false;
  openPositionList: any = [];
  activeIds: any;
  AllTableData: any = [];
  positionType: any = "Open";
  status1: boolean = true;
  status2: boolean = false;
  status3: boolean = false;
  status5: boolean = false;
  status4: boolean = false;
  selectAll: boolean = false;
  clientsItem: any = [];
  total_counter: number = 5;
  col_HM: boolean = true;
  col_curr_status: boolean = true;
  col_target: boolean = true;
  col_total_pos: boolean = true;
  col_pos_filled: boolean = true;
  col_ac: boolean = false;
  positionList: any = [];
  positionCount: any = {
    open: '',
    draft: ''
  };
  sortedTable: string = '';
  isJobBoard: string | null;

  constructor(private authService: AuthService,
    private candidateService: CandidateService,
    private dashboardService: DashboardService,
    private router: Router,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    let userdata: any = localStorage.getItem('currentUser');
    userdata = JSON.parse(userdata);
    if (userdata) {
      this.userRole = userdata?.role;
    }
    this.currentPosition = localStorage.getItem('currentPosition');
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    this.currentClient = localStorage.getItem('clientId');
    this.isJobBoard = localStorage.getItem('isQJobBoardEnabled');
    this.getClientList();
    this.candidateService.getSelectedCandidate().subscribe((val) => {
      if (val.id) {
        // this.getPositionsbyID(val.id)
        if (val.logo && val.logo !== 'None' && val.logo !== 'null') {
          this.logo = URL.imgBaseUrl + val.logo;
        } else {
          this.logo = URL.imgBaseUrl + './../../../assets/images/no-image.jpg';
        }
        this.activeClientName = val.company_name;
        this.selectedCandidate = val?.open_position_data?.filter((el: any) => el?.id == this.currentPosition)[0];
      }
    });
    this.activeIds = ['openPositions', 'draftPositions', 'onHoldPositions', 'completedPositions']

  }

  getPositionsbyID(id: any) {
    this.dashboardService.getAllPositionData(id).subscribe((data: any) => {
      this.AllTableData = data.all_open_positions;
    })

  }
  selectAllItems() {
    for (let i = 0; i < this.tableData.length; i++) {
      this.clientsItem[i] = this.selectAll;
      this.positionSelect(this.tableData[i], this.selectAll)
    }
    if (!this.selectAll) {
      this.positionList = [];
    }
  }
  positionSelect(item: any, value: boolean) {
    if (value)
      this.positionList.push(item)
    else {
      let i = 0;
      for (i = 0; i < this.positionList.length; i++) {
        if (this.positionList[i] == item) {
          break;
        }
      }
      this.positionList.splice(i, 1);
      this.selectAll = false;
    }
  }
  searchText(text: any) {
    this.query = text;
  }

  getClientList(isDuplicateOrDelete?: any) {
    if (!this.currentClient) {
      return
    }
    this.dashboardService.getSelectClientData(this.currentClient).subscribe((el: any) => {
      this.clientPositionData = el.data;
      this.isHtmAvailable = el.data.team_exists;
      this.openPositionList = this.clientPositionData.open_position_data;
      this.tableData = this.openPositionList;
      this.draftedPositionList = this.clientPositionData.drafted_position_data;
      this.archivedPositionList = this.clientPositionData.archieved_position_data;
      this.completedPositionList = this.clientPositionData.completed_position_data;
      this.trashedPositionList = this.clientPositionData.trashed_position_data;
      this.candidateService.setSelectedCandidate(this.clientPositionData);
      this.AllTableData = this.openPositionList;
      this.AllTableData = this.AllTableData.concat(this.draftedPositionList)
      this.AllTableData = this.AllTableData.concat(this.archivedPositionList)
      this.AllTableData = this.AllTableData.concat(this.completedPositionList)
      this.positionCount.open = this.openPositionList.length
      this.positionCount.draft = this.draftedPositionList.length
      this.sortedTable = el.sort_by
      this.sortTable(this.sortedTable)
    })
  }
  transformText(value: any) {
    const limit = 25;
    return value.length > limit ? value.split('').slice(0, limit).join('') + '...' : value;
  }
  createPosition(e: any, htmModel: any) {
    if (this.isHtmAvailable) {
      e.stopPropagation();
      localStorage.removeItem('editPosition');
      this.router.navigate([this.authService.currentUserRoleCode + '/position/createPosition1']);
    }
    else
      this.modalService.open(htmModel, { size: 'sm' });
  }
  moveToHtmPage() {
    this.modalService.dismissAll();
    this.router.navigate([this.authService.currentUserRoleCode + '/dashboard/hiringDepartment']);
  }

  select(item: any) {
    if (item.status == 'Active') {
      localStorage.setItem('currentPosition', item?.id);
      localStorage.setItem('currentPositionName', item?.position_title);
      localStorage.setItem('useitOnceCandidateassociated', JSON.stringify(item));
      if (this.userRole === 'Hiring Manager' || this.userRole === 'Hiring Member' || this.userRole === 'Human Resource') {
        localStorage.setItem('is_hiring_manager', item.is_hiring_manager);
        localStorage.setItem('is_hr', item.is_hiring_manager);
      } else {
        localStorage.removeItem('is_hiring_manager');
        localStorage.removeItem('is_hr');
      }
      this.candidateService.setSelectedCandidate(this.clientPositionData);
      this.router.navigate([this.authService.currentUserRoleCode + '/position-details/'+item?.id]);
    }
    if (item.status == 'Drafted') {
      localStorage.setItem('currentPosition', item?.id);
      localStorage.setItem('currentPositionName', item?.position_title);
      console.log(this.draftedPositionList)
      this.candidateService.setSelectedCompletedPosition(this.draftedPositionList.filter((el: any) => el.id == item?.id)[0]);
      this.candidateService.setSelectedCandidate(this.draftedPositionList?.filter((el: any) => el.id == item?.id)[0]);
      this.router.navigate([this.authService.currentUserRoleCode + '/position/position-summary']);
    }
    if (item.status == 'On Hold') {
      localStorage.setItem('currentPosition', item?.id);
      localStorage.setItem('currentPositionName', item?.position_title);
      console.log(this.draftedPositionList)
      this.candidateService.setSelectedCompletedPosition(this.archivedPositionList.filter((el: any) => el.id == item?.id)[0]);
      this.candidateService.setSelectedCandidate(this.archivedPositionList?.filter((el: any) => el.id == item?.id)[0]);
      this.router.navigate([this.authService.currentUserRoleCode + '/position/position-summary']);
    }
    if (item.status == 'Completed') {
      localStorage.setItem('currentPosition', item?.id);
      localStorage.setItem('currentPositionName', item?.position_title);
      this.candidateService.setSelectedCandidate(this.completedPositionList.filter((el: any) => el.id == item?.id)[0]);
      this.candidateService.setSelectedCompletedPosition(this.completedPositionList.filter((el: any) => el.id == item?.id)[0]);
      // this.router.navigate([this.authService.currentUserRoleCode + '/position/audit-dashboard']);
      this.router.navigate([this.authService.currentUserRoleCode + '/position/position-summary']);
    }
    if (item.status == 'Trashed') {
      return
    }
  }

  formatDate(inputDate: any) {
    // Create a new Date object from the input string
    var date = new Date(inputDate);

    // Define months array to convert numeric month to its name
    var months = [
      "January", "February", "March", "April",
      "May", "June", "July", "August",
      "September", "October", "November", "December"
    ];

    // Extract day, month, and year from the Date object
    var day = date.getDate();
    var month = months[date.getMonth()];
    var year = date.getFullYear();

    // Create the formatted date string
    var formattedDate = month + ' ' + day + ',' + ' ' + year;

    return formattedDate;
  }

  setSortedTable(column: any) {
    this.sortedTable = column
    if (this.reverse) {
      this.sortedTable = "reverse-" + column
    }
    this.authService.updateSortedData({ table: "open_position", column: this.sortedTable }).subscribe((data: any) => {
      // console.log(data)
      if (this.sortedTable.startsWith('reverse-')) {
        this.sortedTable = this.sortedTable.replace('reverse-', '');
      }
    })
  }

  sortTable(column: any): void {
    this.selectAll = false;
    for (let i = 0; i < this.tableData.length; i++) {
      this.clientsItem[i] = false;
    }
    if (this.sortKey == column) {
      // If the same column is clicked again, reverse the order
      this.reverse = !this.reverse;
    } else {
      // If a new column is clicked, set the column and set ascending order
      this.sortKey = column;
      this.reverse = false;
    }
    if (column.startsWith('reverse-')) {
      this.reverse = !this.reverse;
      column = column.replace('reverse-', '');
    }
    if(column=='formated_target_deadline'){
      this.tableData.sort((a: any, b: any) => {
        var x:any = new Date(a[column]);
        var y:any = new Date(b[column]);
        return this.reverse ? y-x : x-y;
      });
    }
    else{
      this.tableData.sort((a: any, b: any) => {
        var x = a[column];
        var y = b[column];
        if(column=='no_of_open_positions' || column=='position_filled'){
          return this.reverse ? y-x : x-y;
        }
        if(column=='deadline'){
         x = x ? 'Delayed' : 'On Time' 
         y = y ? 'Delayed' : 'On Time' 
        }
        if (x == null) x = 0;
        if (y == null) y = 0;
        x = x.toString();
        y = y.toString();
        return this.reverse ? y.localeCompare(x) : x.localeCompare(y);
      });
    }
    this.setSortedTable(column)
  }

  selectColumns() {
    let counter = 0
    if (this.col_HM)
      counter++;
    if (this.col_curr_status)
      counter++;
    if (this.col_target)
      counter++;
    if (this.col_total_pos)
      counter++;
    if (this.col_pos_filled)
      counter++;
    if (this.col_ac)
      counter++;


    this.total_counter = counter;
  }
  @ViewChild('scrollDiv') scrollDiv: ElementRef;
  clickPos(name: any) {
    this.scrollDiv.nativeElement.scrollTop = 0;
    this.selectAll = false;
    for (let i = 0; i < this.tableData.length; i++) {
      this.clientsItem[i] = false;;
    }
    this.positionList = [];
    this.status1 = false;
    this.status2 = false;
    this.status3 = false;
    this.status4 = false;
    this.status5 = false;
    this.positionType = name;
    if (name == 'Open') {
      this.tableData = this.openPositionList;
      this.status1 = true;
    }
    if (name == 'Draft') {
      this.tableData = this.draftedPositionList;
      this.status2 = true;
    }
    if (name == 'Archive') {
      this.tableData = this.archivedPositionList;
      this.status3 = true;
    }
    if (name == 'Completed') {
      this.tableData = this.completedPositionList;
      this.status4 = true;
    }
    if (name == 'All') {
      this.tableData = this.AllTableData;
      this.status5 = true;
    }
  }
  toggleJobPosting(pos_id:string,e:any){
    let job :any={
      job_posting : e
    }
    this.dashboardService.updateJobPosting(pos_id,job).subscribe()
  }

}
