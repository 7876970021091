import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  NgbAccordionModule,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDropdownModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';

import { Ng2GoogleChartsModule } from 'ng2-google-charts';

import { AppComponent } from './app.component';
import { AppRoutingModules } from './app.routing';

import { SearchFilterPipe } from './pipes/search.pipe';
import {
  CustomAdapter,
  CustomDateParserFormatter,
} from './services/ngbootstap.calendarformatter';
import { ErrorInterceptor } from './core/_helpers/error.interceptor';
import { JwtInterceptor } from './core/_helpers/jwt.interceptor';
import { FooterComponent } from './core/footer/footer.component';
import { HeaderComponent } from './core/header/header.component';
import { ToastService } from './core/toast/toast-service';
import { AssociateCandidateComponent } from './features/associate-candidate/associate-candidate.component';
import { CandidateDetailsComponent } from './features/candidate-details/candidate-details.component';
import { CandidateSummaryComponent } from './features/candidate-summary/candidate-summary.component';
import { HMTRatingComponent } from './features/candidateHMTRating/hmtrating.component';
import { ClientsPositionComponent } from './features/clients-position/clients-position.component';
import { ClientComponent } from './features/create-client/client.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { HomeComponent } from './features/home/home.component';
import { PositionsComponent } from './features/positions/positions.component';
import { SetAvailabilityComponent } from './features/set-availability/set-availability.component';
import { SubDashboardComponent } from './features/sub-dashboard/sub-dashboard.component';
import { SummaryTimelineChartComponent } from './features/summary-timeline-chart/summary-timeline-chart.component';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AngularCalendarComponent } from './features/angular-calendar/angular-calendar.component';
import { SignupComponent } from './core/signup/signup.component';
import { SubscriptionComponent } from './core/subscription/subscription.component';
import { WebSocketService } from './services/websocket.service';
import { NavbarComponent } from './core/navbar/navbar.component';
import { ProtipComponent } from './core/protip/protip.component';
import { CreateQorumsSupportComponent } from './features/create-qorums-support/create-qorums-support.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { SwiperModule } from 'swiper/angular';
import { SsoCallbackComponent } from './features/sso-callback/sso-callback.component';
import { CandidateScheduleComponent } from './features/candidate-schedule/candidate-schedule.component';
import { ResetPasswordComponent } from './core/reset-password/reset-password.component';
import { HtmTimelineChartComponent } from './features/htm-timeline-chart/htm-timeline-chart.component';
import { SkillsChartComponent } from './core/skills-chart/skills-chart.component';
import { CreateUserComponent } from './features/create-user/create-user.component';
import { AllUsersComponent } from './features/all-users/all-users.component';
import { HtmAuditTrailCalendarComponent } from './features/htm-audit-trail-calendar/htm-audit-trail-calendar.component';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PositionActionsComponent } from './features/position-actions/position-actions.component';
import { AccountleveldataService } from './services/accountleveldata.service';
import { SplitStringPipe } from './pipes/split-string.pipe';
import { PositionSummaryComponent } from './features/position-summary/position-summary.component';
import { AllPositionsComponent } from './features/all-positions/all-positions.component';
import { InsightsComponent } from './features/insights/insights.component';
import { PositionDetailsComponent } from './features/position-details/position-details.component';
import { SummaryTableComponent } from './features/summary-table/summary-table.component';
import { AllClientsComponent } from './features/all-clients/all-clients.component';
// import { InterviewScreenComponent } from './features/interview-screen/interview-screen.component';
import { DetailHeaderComponent } from './core/detail-header/detail-header.component';
import { NewPositionComponent } from './features/new-position/new-position.component';
import { AddNewCandidateComponent } from './features/add-new-candidate/add-new-candidate.component';
import { ViewAllUsersComponent } from './features/view-all-users/view-all-users.component';
import { InviteUsersComponent } from './features/invite-users/invite-users.component';
import { MyCalenderComponent } from './features/my-calender/my-calender.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { CandidateDashboardComponent } from './features/candidate-dashboard/candidate-dashboard.component';
import { EmailEditorModule } from 'angular-email-editor';
import { HtmDasboardComponent } from './features/htm-dasboard/htm-dasboard.component';
import { FormatDatePipe } from './features/format-date.pipe';
import { AppCommonModule } from './modules/common/appCommon.module';
import { MapService } from './services/map.service';
import { RouterModule } from '@angular/router';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DashboardComponent,
    ClientComponent,
    SubDashboardComponent,
    PositionsComponent,
    FooterComponent,
    ClientsPositionComponent,
    SearchFilterPipe,
    CandidateDetailsComponent,
    HMTRatingComponent,
    CreateQorumsSupportComponent,
    AssociateCandidateComponent,
    SetAvailabilityComponent,
    CandidateSummaryComponent,
    SummaryTimelineChartComponent,
    AngularCalendarComponent,
    SignupComponent,
    SubscriptionComponent,
    NavbarComponent,
    ProtipComponent,
    SsoCallbackComponent,
    CandidateScheduleComponent,
    ResetPasswordComponent,
    HtmTimelineChartComponent,
    SkillsChartComponent,
    CreateUserComponent,
    AllUsersComponent,
    HtmAuditTrailCalendarComponent,
    PositionActionsComponent,
    SplitStringPipe,
    PositionSummaryComponent,
    AllPositionsComponent,
    InsightsComponent,
    PositionDetailsComponent,
    SummaryTableComponent,
    AllClientsComponent,
    // InterviewScreenComponent,
    DetailHeaderComponent,
    NewPositionComponent,
    AddNewCandidateComponent,
    ViewAllUsersComponent,
    InviteUsersComponent,
    MyCalenderComponent,
    TruncatePipe,
    CandidateDashboardComponent,
    HtmDasboardComponent,
    FormatDatePipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModules,
    AppCommonModule,
    EmailEditorModule,
    Ng2GoogleChartsModule,
    NgbAccordionModule,
    NgxMasonryModule,
    SwiperModule,
    NgbDropdownModule,
    AngularEditorModule,
    SimpleNotificationsModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    ToastService,
    WebSocketService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    AccountleveldataService,
    MapService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
