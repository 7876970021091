import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import * as urls from './../constants/url';
import { AuthPayload, UserData } from '../models/auth.interface';
import { routerUrlMapping } from '../constants/routerUrlMapping';
declare var WonderPush: any;
@Injectable({ providedIn: 'root' })
export class AuthService {
  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<UserData>;
  public isUserLogin$ = new BehaviorSubject(false);
  public isDarkMode$ = new BehaviorSubject(false);
  public isNavMode$ = new BehaviorSubject(false);

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<UserData>(
      JSON.parse(localStorage.getItem('currentUser') || '{}')
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserData {
    return this.currentUserSubject.value;
  }

  public get currentUserRoleCode(): string {
    return '/' + (routerUrlMapping[this.currentUserSubject.value?.role] || 'user');
  }

  getLogin(data: AuthPayload) {
    return this.http.post(urls.loginUrl, data).pipe(
      map((auser) => {
        if (auser) {
          localStorage.setItem('currentUser', JSON.stringify(auser));
          this.currentUserSubject.next(auser);
        }
        return auser;
      })
    );
  }

  getCognitoLogin() {
    return this.http.get(urls.cognitoLoginUrl).pipe(
      map((auser) => {
        if (auser) {
          localStorage.setItem('currentUser', JSON.stringify(auser));
          this.currentUserSubject.next(auser);
        }
        return auser;
      })
    );
  }

  getTermCondition(data: boolean, username: string) {
    let payload = { tnc_accepted: data };
    return this.http.post(urls.termconditionUrl + '/' + username, payload).pipe();
  }

  logout() {
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.isNavMode$.next(false)
    WonderPush.unsetUserId();
    //this.currentUserSubject = new BehaviorSubject<User>({});
  }

  setUserLogin(url: boolean) {
    this.isUserLogin$.next(url);
  }

  getUserLogin() {
    return this.isUserLogin$.asObservable();
  }

  requestPasswordReset(payload: any) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'application/json');
    return this.http.post(urls.requestPasswordReset, payload, { headers: headers }).pipe();
  }
  requestUserReminder(payload: any) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'application/json');
    return this.http.post(urls.remindUsers, payload, { headers: headers }).pipe();
  }

  initiatePayment(id: any, payload: any, token: any) {
    let headers = new HttpHeaders();
    headers.append('Authorization', 'Token ' + token);
    return this.http.post(urls.initialPayment + '/' + id, payload, { headers: headers }).pipe();
  }

  forgotPassword(payload: any, token: any, uid: any) {
    return this.http.post(urls.resetPassword + '/' + uid + '/' + token + '/', payload).pipe();
  }

  setIsDarkMode(flag: any) {
    this.isDarkMode$.next(flag);
  }

  getIsDarkMode() {
    return this.isDarkMode$ as Observable<any>;
  }
  setNavMode(flag: boolean) {
    localStorage.setItem('isNavigator',flag.toString())
    this.isNavMode$.next(flag);
  }
  getNavMode() {
    return this.isNavMode$ as Observable<any>;
  }
  updateSortedData(payload: any) {
    return this.http.post(urls.updateSortedData, payload)
  }
}
