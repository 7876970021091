import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastService } from 'src/app/core/toast/toast-service';

import { CandidateService } from '../../services/candidate.service';
import * as Url from './../../constants/url';
import { 
  faEnvelope,
  faPhoneAlt,
  faFile,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
@Component({
  selector: 'app-associate-candidate',
  templateUrl: './associate-candidate.component.html',
  styleUrls: ['./associate-candidate.component.css'],
})
export class AssociateCandidateComponent implements OnInit {
  @Input() name: any;
  @Input() type: any;
  @Input() preSelectedCandidate: any;
  @Input() preSelectedCandidateData: any;
  public associateForm!: FormGroup;
  public candidateList: any[] = [];
  public currentPosition: any;
  selectedCandidate: any;
  public imageBaseUrl = Url.imgBaseUrl;
  faEnvelope = faEnvelope;
  faPhoneAlt = faPhoneAlt;
  faFile = faFile;
  faUpload = faUpload;
  candidateOpenPositions: any;
  associatePosition: any;
  clientId: any;
  candidateData: any;
  filteredCandidateList: any = [];
  public model: any;

	search: OperatorFunction<string, readonly { name: any; flag: any }[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			map((term) =>
				term === ''
					? []
					: this.filteredCandidateList.filter((v: any) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
			),
		);

	formatter = (x: { name: string }) => x.name;
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private candidateService: CandidateService,
    private toastService: ToastService,
    private candidatesService: CandidateService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.currentPosition = localStorage.getItem('currentPosition');
    if (this.currentPosition) {
      this.candidateService
        .getPositionToAssociate(this.currentPosition)
        .subscribe((value: any) => {
          if (value) {
            this.candidateList = value.data;
            this.candidateList.forEach((can: any)=> {
              if(can.profile_photo && can.profile_photo !== 'None') {
                if(can.profile_photo.indexOf('https') > -1) {
                  this.filteredCandidateList.push({name: can.name, flag: can.profile_photo, id: can.candidate_id, associated: can.associated});
                } else {
                  this.filteredCandidateList.push({name: can.name, flag: this.imageBaseUrl + can.profile_photo, id: can.candidate_id, associated: can.associated});
                }
              } else {
                this.filteredCandidateList.push({name: can.name, flag: './../../assets/images/big-default-avatar.png', id: can.candidate_id, associated: can.associated});
              }
            });
          }
        });
    }
    if(this.type === 'open-position') {
      this.associateForm.patchValue({
        nickname: this.preSelectedCandidateData.nickname === 'Not Specified' ? '' : this.preSelectedCandidateData.nickname || '',
        location: this.preSelectedCandidateData.location === 'Not Specified' ? '' : this.preSelectedCandidateData.location || '',
        currently: this.preSelectedCandidateData.current_position === 'Not Specified' ? '' : this.preSelectedCandidateData.current_position || '',
        work_auth: this.preSelectedCandidateData.work_auth === 'Not Specified' ? '' : this.preSelectedCandidateData.work_auth || '',
        salary_req: this.preSelectedCandidateData.salaryRange === 'Not Specified' ? '' : this.preSelectedCandidateData.salaryRange || '',
        email: this.preSelectedCandidateData.email || '',
        phone: this.preSelectedCandidateData.phone_number || '',
        linkedin: this.preSelectedCandidateData.skype_id === 'None' ? '' : this.preSelectedCandidateData.skype_id || ''
      });
      this.candidateService.getAvailableOpenPositionsForCandidate(this.preSelectedCandidate).subscribe((res: any) => {
        this.candidateOpenPositions = res.data;
        this.selectedCandidate = this.preSelectedCandidate
      });
    }
  }

  createForm() {
    this.associateForm = this.fb.group({
      candidate_id: ['', Validators.required],

      generalComments: [''],
      nickname: [''],
      location: [''],
      currently: [''],
      work_auth: [''],
      currency: [''],
      salary_req: [''],
      remote_only: [false],
      remote_pref: [false],
      some_in_office: [false],
      office_only: [false],
      email: [''],
      phone: [''],
      linkedin: [''],
      // resume: [],
      // references: []
    });
  }

  selectCandidate(e: any) {
    if(e.item?.associated) {
      return;
    }
    this.selectedCandidate = this.candidateList.filter(x => x.candidate_id == e.item.id)[0];
    this.associateForm.controls.candidate_id.patchValue(e.item.id);
    this.getCandidateById(this.selectedCandidate.candidate_id);
  }

  getCandidateById(id: any) {
    this.candidateService.getCandidateById(id).subscribe((res: any) => {
      this.candidateData = res;
      this.associateForm.patchValue({
        nickname: res.nickname === 'Not Specified' ? '' : res.nickname,
        location: res.location === 'Not Specified' ? '' : res.location,
        currently: res.current_position === 'Not Specified' ? '' : res.current_position,
        work_auth: res.work_auth === 'Not Specified' ? '' : res.work_auth,
        currency: res.currency === 'Not Specified' ? '' : res.currency,
        salary_req: res.salaryRange === 'Not Specified' ? '' : res.salaryRange,
        email: res.email,
        phone: res.phone_number,
        linkedin: res.skype_id
      });
      console.log(this.associateForm.value)
    });
  }

  save() {
    if(this.type === 'open-position') {
      this.associateCandidate();
      return;
    }
    let currentClient = localStorage.getItem('clientId');
    let selectedClient: any = localStorage.getItem('useitOnceCandidateassociated');
    selectedClient = JSON.parse(selectedClient);
    this.candidateService
      .associateCandidate(
        currentClient,
        this.currentPosition,
        this.associateForm.value,
        this.resume,
        this.reference
      )
      .subscribe((val) => {
        if(val){
          this.showSuccess('Candidate associated successfully');
          this.modalService.dismissAll();
          this.associateForm.reset();
          this.candidatesService.setSelectedCandidate(selectedClient);
        } else {
          this.showDanger('Candidate associated failed, try again');
        }
      });
  }

  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

  selectPosition(e: any) {
    this.associatePosition = e.target.value;
    this.associateForm.controls.candidate_id.setValue(this.selectedCandidate);
    this.clientId = this.candidateOpenPositions.filter((el: any) => el.id == this.associatePosition)[0].client_id;
  }

  associateCandidate() {
    this.candidateService.associateCandidate(this.clientId, this.associatePosition, this.associateForm.value, this.resume, this.reference).subscribe((res: any) => {
      if(res){
        this.showSuccess('Candidate associated successfully');
        this.modalService.dismissAll();
        this.associatePosition = '';
      } else {
        this.showDanger('Candidate associated failed, try again');
      }
    });
  }
  resume: any = [];
  onResumeChange(event: any) {
    this.resume = [];
    for (var i = 0; i < event.target.files.length; i++) { 
      this.resume.push(event.target.files[i]);
    }
  }
  reference: any = [];
  onReferenceChange(event: any) {
    this.reference = [];
    for (var i = 0; i < event.target.files.length; i++) { 
      this.reference.push(event.target.files[i]);
    }
  }
}
