import { LabelType, Options } from '@angular-slider/ngx-slider';

import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faHandPointDown, faPlus, faThumbsDown, faThumbsUp, faChevronDown, faChevronUp, faVideo } from '@fortawesome/free-solid-svg-icons';

import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastService } from 'src/app/core/toast/toast-service';
import { AuthService } from 'src/app/services/auth.services';
import { CandidateService } from 'src/app/services/candidate.service';

import { AssociateCandidateComponent } from '../associate-candidate/associate-candidate.component';
import { SetAvailabilityComponent } from '../set-availability/set-availability.component';

declare var Slider: any;
declare var frames: any;

@Component({
  selector: 'app-hmtrating',
  templateUrl: './hmtrating.component.html',
  styleUrls: ['./hmtrating.component.css'],
})
export class HMTRatingComponent implements OnInit {
  public inputVal1: number = 0;
  public inputVal2: number = 0;
  public inputVal3: number = 0;
  public inputVal4: number = 0;
  public inputVal5: number = 0;
  public inputVal6: number = 0;
  public inputVal7: number = 0;
  public inputVal8: number = 0;
  public ratingForm!: FormGroup;
  public candidateData: any = {};
  public showSaveButton: boolean = true;
  public userRole: string = '';
  public faPlus = faPlus;
  public faCalendar = faCalendarAlt;
  public faThumbup = faThumbsUp;
  public faThumbdown = faThumbsDown;
  public faThumbhold = faHandPointDown;
  public faChevronDown = faChevronDown;
  public faChevronUp = faChevronUp;
  cheveron1Show = false;
  cheveron2Show = false;
  cheveron3Show = false;
  cheveron4Show = false;
  cheveron5Show = false;
  cheveron6Show = false;
  cheveron7Show = false;
  cheveron8Show = false;
  faVideo = faVideo;
  isAnyCheckboxChecked = false;
  conference_id: any;
  @ViewChild("content") modalContent!: TemplateRef<any>;
  @ViewChild("modalRoot") modalRoot!: TemplateRef<any>;
  options1: Options = {
    floor: 0,
    ceil: 10,
    step: 0.1,
    showTicks: false,
    translate: (value: number, label: LabelType): string => {
      return this.getRatingFeedback(value);
    },
    disabled: true,
    showTicksValues: false,
    // stepsArray: this.getStepsArray(),
    // ticksArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    getTickColor: (value: number): string => this.getTickColor(value),
  };
  options2: Options = {
    floor: 0,
    ceil: 10,
    step: 0.1,
    showTicks: false,
    disabled: true,
    translate: (value: number, label: LabelType): string => {
      return this.getRatingFeedback(value);
    },
    showTicksValues: false,
    // stepsArray: this.getStepsArray(),
    // ticksArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    getTickColor: (value: number): string => this.getTickColor(value),
  };
  options3: Options = {
    floor: 0,
    ceil: 10,
    step: 0.1,
    showTicks: false,
    disabled: true,
    translate: (value: number, label: LabelType): string => {
      return this.getRatingFeedback(value);
    },
    showTicksValues: false,
    // stepsArray: this.getStepsArray(),
    // ticksArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    getTickColor: (value: number): string => this.getTickColor(value),
  };
  options4: Options = {
    floor: 0,
    ceil: 10,
    step: 0.1,
    showTicks: false,
    disabled: true,
    translate: (value: number, label: LabelType): string => {
      return this.getRatingFeedback(value);
    },
    showTicksValues: false,
    // stepsArray: this.getStepsArray(),
    // ticksArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    getTickColor: (value: number): string => this.getTickColor(value),
  };
  options5: Options = {
    floor: 0,
    ceil: 10,
    step: 0.1,
    showTicks: false,
    disabled: true,
    translate: (value: number, label: LabelType): string => {
      return this.getRatingFeedback(value);
    },
    showTicksValues: false,
    // stepsArray: this.getStepsArray(),
    // ticksArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    getTickColor: (value: number): string => this.getTickColor(value),
  };
  options6: Options = {
    floor: 0,
    ceil: 10,
    step: 0.1,
    showTicks: false,
    disabled: true,
    translate: (value: number, label: LabelType): string => {
      return this.getRatingFeedback(value);
    },
    showTicksValues: false,
    // stepsArray: this.getStepsArray(),
    // ticksArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    getTickColor: (value: number): string => this.getTickColor(value),
  };
  options7: Options = {
    floor: 0,
    ceil: 10,
    step: 0.1,
    showTicks: false,
    disabled: true,
    translate: (value: number, label: LabelType): string => {
      return this.getRatingFeedback(value);
    },
    showTicksValues: false,
    // stepsArray: this.getStepsArray(),
    // ticksArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    getTickColor: (value: number): string => this.getTickColor(value),
  };
  options8: Options = {
    floor: 0,
    ceil: 10,
    step: 0.1,
    showTicks: false,
    disabled: true,
    translate: (value: number, label: LabelType): string => {
      return this.getRatingFeedback(value);
    },
    showTicksValues: false,
    // stepsArray: this.getStepsArray(),
    // ticksArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    getTickColor: (value: number): string => this.getTickColor(value),
  };
  public overallScore: any;
  currentUserRoleCode: any;
  candidateMarks: any;
  zoom_links: any;
  candidate_id: any;
  op_id: any;
  closeResult: any;
  meetingUrl: any;
  username: any;
  pendingInterviewAcceptance: any;
  marks1: boolean = false;
  marks2: boolean = false;
  marks3: boolean = false;
  suggestedQuestions: any = [];
  voted1: boolean = false;
  voted3: boolean = false;
  voted2: boolean = false;
  constructor(
    private fb: FormBuilder,
    private candidateService: CandidateService,
    private toastService: ToastService,
    private router: Router, 
    private modalService: NgbModal,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    protected _sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    let uerdata:any = localStorage.getItem('currentUser');
    uerdata = JSON.parse(uerdata);
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    if(uerdata) {
      this.username = uerdata?.username;
      this.userRole = uerdata?.role;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.candidate_id = params['candidate_id'];
      this.op_id = params['op_id'];
    });
    this.createForm();
    this.candidateService.getCandidateData().subscribe((val: any) => {
      if (Object.keys(val).length) {
        this.candidateData = val;
        this.getRating(val.op_id, val.candidate_id);
        this.ratingForm.controls['candidate_id'].setValue(val.candidate_id);
      } else if(this.op_id) {
        this.candidateService.getPositionsCandidates(this.op_id).subscribe((val: any) => {
          if (val) {
            this.candidateData = val.filter((el: any) => el.candidate_id == this.candidate_id)[0];
            this.getRating(val.op_id, val.candidate_id);
            this.ratingForm.controls['candidate_id'].setValue(val.candidate_id);
          }
        });
        this.candidateData = {};
      }
    });
    this.candidateService.getCandidateData().subscribe((val) => {
      if (val) {
        this.overallScore = val?.final_avg_marks || 0;
      }
    });
  }

  getRating(position: any, candidate: any) {
    if(!position) {
      this.activatedRoute.params.subscribe(params => {
        candidate = params['candidate_id'];
        position = params['op_id'];
      });
    }
    this.candidateService.getCandidateRating(position, candidate).subscribe((data: any) => {
        if(data){
          this.showSaveButton = !data?.marks_given;
          this.pendingInterviewAcceptance = data.pending_interview_acceptance;
          let fdata: any = data.data;
          this.zoom_links = data.zoom_links;
          this.candidateMarks = fdata;
          this.ratingForm.controls['candidate_id'].setValue(this.candidateData?.candidate_id || 0);
          this.ratingForm.controls['criteria_1_marks'].setValue(fdata?.init_qualify_ques_1 || 0);
          this.inputVal1 = fdata?.init_qualify_ques_1 || 0;
          this.ratingForm.controls['criteria_2_marks'].setValue(fdata?.init_qualify_ques_2 || 0);
          this.inputVal2 = fdata?.init_qualify_ques_2 || 0;
          this.ratingForm.controls['criteria_3_marks'].setValue(fdata?.init_qualify_ques_3 || 0);
          this.inputVal3 = fdata?.init_qualify_ques_3 || 0;
          this.ratingForm.controls['criteria_4_marks'].setValue(fdata?.init_qualify_ques_4 || 0);
          this.inputVal4 = fdata?.init_qualify_ques_4 || 0;
          this.ratingForm.controls['criteria_5_marks'].setValue(fdata?.init_qualify_ques_5 || 0);
          this.inputVal5 = fdata?.init_qualify_ques_5 || 0;
          this.ratingForm.controls['criteria_6_marks'].setValue(fdata?.init_qualify_ques_6 || 0);
          this.inputVal6 = fdata?.init_qualify_ques_6 || 0;
          this.ratingForm.controls['criteria_7_marks'].setValue(fdata?.init_qualify_ques_7 || 0);
          this.inputVal7 = fdata?.init_qualify_ques_7 || 0;
          this.ratingForm.controls['criteria_8_marks'].setValue(fdata?.init_qualify_ques_8 || 0);
          this.inputVal8 = fdata?.init_qualify_ques_8 || 0;
          this.ratingForm.controls['thumbs_up'].setValue(fdata?.thumbs_up);
          this.ratingForm.controls['thumbs_down'].setValue(fdata?.thumbs_down);
          this.ratingForm.controls['hold'].setValue(fdata?.hold || false);
          this.ratingForm.controls['golden_gloves'].setValue(fdata?.golden_gloves);
          this.ratingForm.controls['feedback'].setValue(fdata?.feedback || '');

          // Set Suggested questions
          // this.ratingForm.controls['init_qualify_ques_suggestion_1'].setValue(fdata?.init_qualify_ques_suggestion_1);
          // this.ratingForm.controls['init_qualify_ques_suggestion_2'].setValue(fdata?.init_qualify_ques_suggestion_2);
          // this.ratingForm.controls['init_qualify_ques_suggestion_3'].setValue(fdata?.init_qualify_ques_suggestion_3);
          // this.ratingForm.controls['init_qualify_ques_suggestion_4'].setValue(fdata?.init_qualify_ques_suggestion_4);
          // this.ratingForm.controls['init_qualify_ques_suggestion_5'].setValue(fdata?.init_qualify_ques_suggestion_5);
          // this.ratingForm.controls['init_qualify_ques_suggestion_6'].setValue(fdata?.init_qualify_ques_suggestion_6);
          // this.ratingForm.controls['init_qualify_ques_suggestion_7'].setValue(fdata?.init_qualify_ques_suggestion_7);
          // this.ratingForm.controls['init_qualify_ques_suggestion_8'].setValue(fdata?.init_qualify_ques_suggestion_8);
          this.suggestedQuestions = [];
          [...Array(8)].forEach((_, i) => {
            this.ratingForm.controls['init_qualify_ques_suggestion_'+ (i+1)].setValue(fdata['init_qualify_ques_suggestion_'+ (i+1)]);
            const suggestedQues: any = fdata['init_qualify_ques_suggestion_'+ (i+1)];
            this.suggestedQuestions.push(JSON.parse(suggestedQues));
          });

          // Set Suggested answers
          this.ratingForm.controls['suggestion_1'].setValue(fdata?.suggestion_1 !== 'None' ? fdata?.suggestion_1 : '');
          this.ratingForm.controls['suggestion_2'].setValue(fdata?.suggestion_1 !== 'None' ? fdata?.suggestion_2 : '');
          this.ratingForm.controls['suggestion_3'].setValue(fdata?.suggestion_1 !== 'None' ? fdata?.suggestion_3 : '');
          this.ratingForm.controls['suggestion_4'].setValue(fdata?.suggestion_1 !== 'None' ? fdata?.suggestion_4 : '');
          this.ratingForm.controls['suggestion_5'].setValue(fdata?.suggestion_1 !== 'None' ? fdata?.suggestion_5 : '');
          this.ratingForm.controls['suggestion_6'].setValue(fdata?.suggestion_1 !== 'None' ? fdata?.suggestion_6 : '');
          this.ratingForm.controls['suggestion_7'].setValue(fdata?.suggestion_1 !== 'None' ? fdata?.suggestion_7 : '');
          this.ratingForm.controls['suggestion_8'].setValue(fdata?.suggestion_1 !== 'None' ? fdata?.suggestion_8 : '');

        } else {
          this.ratingForm.reset();
        }
      });
  }

  createForm() {
    this.ratingForm = this.fb.group({
      candidate_id: [0],
      criteria_1_marks: [0],
      criteria_2_marks: [0],
      criteria_3_marks: [0],
      criteria_4_marks: [0],
      criteria_5_marks: [0],
      criteria_6_marks: [0],
      criteria_7_marks: [0],
      criteria_8_marks: [0],
      criteria_1_checked: [false ,Validators.required],
      criteria_2_checked: [false ,Validators.required],
      criteria_3_checked: [false ,Validators.required],
      criteria_4_checked: [false],
      criteria_5_checked: [false],
      criteria_6_checked: [false],
      criteria_7_checked: [false],
      criteria_8_checked: [false],
      init_qualify_ques_suggestion_1: [''],
      init_qualify_ques_suggestion_2: [''],
      init_qualify_ques_suggestion_3: [''],
      init_qualify_ques_suggestion_4: [''],
      init_qualify_ques_suggestion_5: [''],
      init_qualify_ques_suggestion_6: [''],
      init_qualify_ques_suggestion_7: [''],
      init_qualify_ques_suggestion_8: [''],
      suggestion_1: ['', Validators.minLength(30)],
      suggestion_2: ['', Validators.minLength(30)],
      suggestion_3: ['', Validators.minLength(30)],
      suggestion_4: ['', Validators.minLength(30)],
      suggestion_5: ['', Validators.minLength(30)],
      suggestion_6: ['', Validators.minLength(30)],
      suggestion_7: ['', Validators.minLength(30)],
      suggestion_8: ['', Validators.minLength(30)],
      thumbs_up: [false],
      thumbs_down: [false],
      golden_gloves: [false],
      hold: [false],
      feedback: [''],
    });
  }

  getSelectedRating(event: any) {
    if (event === 'thumbs_up') {
      this.ratingForm.controls['thumbs_up'].setValue(true);
      this.ratingForm.controls['thumbs_down'].setValue(false);
      this.ratingForm.controls['golden_gloves'].setValue(false);
      this.voted1 = true;
      this.voted2 = false;
      this.voted3 = false
    } else if (event === 'thumbs_down') {
      this.ratingForm.controls['thumbs_up'].setValue(false);
      this.ratingForm.controls['thumbs_down'].setValue(true);
      this.ratingForm.controls['golden_gloves'].setValue(false);
      this.voted2 = true;
      this.voted1 = false;
      this.voted3 = false
    } else if (event === 'golden_gloves') {
      this.ratingForm.controls['thumbs_up'].setValue(false);
      this.ratingForm.controls['thumbs_down'].setValue(false);
      this.ratingForm.controls['golden_gloves'].setValue(true);
      this.voted2 = false;
      this.voted1 = false
      this.voted3 = true;
    }
    if (event === 'hold') {
      this.ratingForm.controls['thumbs_up'].setValue(false);
      this.ratingForm.controls['thumbs_down'].setValue(false);
      this.ratingForm.controls['golden_gloves'].setValue(false);
      this.ratingForm.controls['hold'].setValue(true);
    }
  }

  isVoteInvalid() {
    return !this.ratingForm.controls['thumbs_down'].value && !this.ratingForm.controls['thumbs_up'].value && !this.ratingForm.controls['golden_gloves'].value;
  }

  submit = false;
  saveRating() {
    this.submit = true;
    if(!this.ratingForm.controls['thumbs_down'].value && !this.ratingForm.controls['thumbs_up'].value && !this.ratingForm.controls['golden_gloves'].value) {
      return;
    }
    this.candidateService
      .createCandidateRating(this.candidateData?.op_id, this.ratingForm.value)
      .subscribe((val) => {
        if (val) {
          this.showSuccess('Candidate rating created successfully');
          this.router.navigate([this.authService.currentUserRoleCode + '/position/clientPosition']);
        } else {
          this.showDanger('Candidate rating creation failed, try again');
        }
      });
  }
  updateRating() {
    this.candidateService
      .updateCandidateRating(this.candidateData?.op_id, this.ratingForm.value)
      .subscribe((val) => {
        if (val) {
          this.showSuccess('Candidate rating updated successfully');
          this.router.navigate([this.authService.currentUserRoleCode + '/position/clientPosition']);
        } else {
          this.showDanger('Candidate rating update failed, try again');
        }
      });
  }

  goBack(){
    this.router.navigate([this.authService.currentUserRoleCode + '/position/clientPosition']);
  }

  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

  openAssociateCandidate() {
    const modalRef = this.modalService.open(AssociateCandidateComponent);
    modalRef.componentInstance.name = 'associate';
  }

  openSetAvailability() {
    const modalRef = this.modalService.open(SetAvailabilityComponent, { size: 'lg' });
    modalRef.componentInstance.name = 'scheduler';
  }

  compulsaryMarksGiven(){
    return !this.marks1 || !this.marks2 || !this.marks3;
  }
  onChangeReadOnly(e: any, sliderOption: any): void {
    this.cheveron1Show = false;
    this.cheveron2Show = false;
    this.cheveron3Show = false;
    this.cheveron4Show = false;
    this.cheveron5Show = false;
    this.cheveron6Show = false;
    this.cheveron7Show = false;
    this.cheveron8Show = false;
    this.options1.showTicksValues = false;
    this.options2.showTicksValues = false;
    this.options3.showTicksValues = false;
    this.options4.showTicksValues = false;
    this.options5.showTicksValues = false;
    this.options6.showTicksValues = false;
    this.options7.showTicksValues = false;
    this.options8.showTicksValues = false;
    // if(e.currentTarget.checked) {
    //   this.isAnyCheckboxChecked = true;
    // } else {
    //   this.isAnyCheckboxChecked = false;
    // }
    if(sliderOption === 'options1') {
      if(e.currentTarget.checked) {
        this.ratingForm.controls.suggestion_1.addValidators([Validators.required, Validators.minLength(30)]);
        this.ratingForm.controls.suggestion_1.updateValueAndValidity();
        this.cheveron1Show = true;
        this.options1 = Object.assign({}, this.options1, {disabled: (!e.currentTarget.checked || !this.showSaveButton), ticksArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]});
        this.options1.showTicksValues = true;
        this.marks1 = true;
      } else {
        // this.ratingForm.controls.suggestion_1.setValue('');
        this.ratingForm.controls.suggestion_1.clearValidators();
        this.ratingForm.controls.suggestion_1.updateValueAndValidity();
        this.cheveron1Show = false;
        this.options1.showTicksValues = false;
        this.options1 = Object.assign({}, this.options1, {disabled: (!e.currentTarget.checked || !this.showSaveButton), ticksArray: []});
        this.ratingForm.controls.criteria_1_marks.reset(0);
        this.marks1 = false;
      }
    } else if (sliderOption === 'options2') {
      if(e.currentTarget.checked) {
        this.ratingForm.controls.suggestion_2.addValidators([Validators.required, Validators.minLength(30)]);
        this.ratingForm.controls.suggestion_2.updateValueAndValidity();
        this.cheveron2Show = true;
        this.options2.showTicksValues = true;
        this.options2 = Object.assign({}, this.options2, {disabled: (!e.currentTarget.checked || !this.showSaveButton), ticksArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]});
        this.marks2 = true;
      } else {
        // this.ratingForm.controls.suggestion_2.setValue('');
        this.ratingForm.controls.suggestion_2.clearValidators();
        this.ratingForm.controls.suggestion_2.updateValueAndValidity();
        this.cheveron2Show = false;
        this.options2.showTicksValues = false;
        this.options2 = Object.assign({}, this.options2, {disabled: (!e.currentTarget.checked || !this.showSaveButton), ticksArray: []});
        this.ratingForm.controls.criteria_2_marks.reset(0);
        this.marks2 =  false
      }
    } else if (sliderOption === 'options3') {
      if(e.currentTarget.checked) {
        this.ratingForm.controls.suggestion_3.addValidators([Validators.required, Validators.minLength(30)]);
        this.ratingForm.controls.suggestion_3.updateValueAndValidity();
        this.cheveron3Show = true;
        this.options3.showTicksValues = true;
        this.options3 = Object.assign({}, this.options3, {disabled: (!e.currentTarget.checked || !this.showSaveButton), ticksArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]});
        this.marks3 = true;
      } else {
        // this.ratingForm.controls.suggestion_3.setValue('');
        this.ratingForm.controls.suggestion_3.clearValidators();
        this.ratingForm.controls.suggestion_3.updateValueAndValidity();
        this.cheveron3Show = false;
        this.options3.showTicksValues = false;
        this.options3 = Object.assign({}, this.options3, {disabled: (!e.currentTarget.checked || !this.showSaveButton), ticksArray: []});
        this.ratingForm.controls.criteria_3_marks.reset(0);
        this.marks3 = false;
      }
    } else if (sliderOption === 'options4') {
      if(e.currentTarget.checked) {
        this.ratingForm.controls.suggestion_4.addValidators([Validators.required, Validators.minLength(30)]);
        this.ratingForm.controls.suggestion_4.updateValueAndValidity();
        this.cheveron4Show = true;
        this.options4.showTicksValues = true;
        this.options4 = Object.assign({}, this.options4, {disabled: (!e.currentTarget.checked || !this.showSaveButton), ticksArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]});
      } else {
        // this.ratingForm.controls.suggestion_4.setValue('');
        this.ratingForm.controls.suggestion_4.clearValidators();
        this.ratingForm.controls.suggestion_4.updateValueAndValidity();
        this.cheveron4Show = false;
        this.options4.showTicksValues = false;
        this.options4 = Object.assign({}, this.options4, {disabled: (!e.currentTarget.checked || !this.showSaveButton), ticksArray: []});
        this.ratingForm.controls.criteria_4_marks.reset(0);
      }
    } else if (sliderOption === 'options5') {
      if(e.currentTarget.checked) {
        this.ratingForm.controls.suggestion_5.addValidators([Validators.required, Validators.minLength(30)]);
        this.ratingForm.controls.suggestion_5.updateValueAndValidity();
        this.cheveron5Show = true;
        this.options5.showTicksValues = true;
        this.options5 = Object.assign({}, this.options5, {disabled: (!e.currentTarget.checked || !this.showSaveButton), ticksArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]});
      } else {
        // this.ratingForm.controls.suggestion_5.setValue('');
        this.ratingForm.controls.suggestion_5.clearValidators();
        this.ratingForm.controls.suggestion_5.updateValueAndValidity();
        this.cheveron5Show = false;
        this.options5.showTicksValues = false;
        this.options5 = Object.assign({}, this.options5, {disabled: (!e.currentTarget.checked || !this.showSaveButton), ticksArray: []});
        this.ratingForm.controls.criteria_5_marks.reset(0);
      }
    } else if (sliderOption === 'options6') {
      if(e.currentTarget.checked) {
        this.ratingForm.controls.suggestion_6.addValidators([Validators.required, Validators.minLength(30)]);
        this.ratingForm.controls.suggestion_6.updateValueAndValidity();
        this.cheveron6Show = true;
        this.options6.showTicksValues = true;
        this.options6 = Object.assign({}, this.options6, {disabled: (!e.currentTarget.checked || !this.showSaveButton), ticksArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]});
      } else {
        // this.ratingForm.controls.suggestion_6.setValue('');
        this.ratingForm.controls.suggestion_6.clearValidators();
        this.ratingForm.controls.suggestion_6.updateValueAndValidity();
        this.cheveron6Show = false;
        this.options6.showTicksValues = false;
        this.options6 = Object.assign({}, this.options6, {disabled: (!e.currentTarget.checked || !this.showSaveButton), ticksArray: []});
        this.ratingForm.controls.criteria_6_marks.reset(0);
      }
    } else if (sliderOption === 'options7') {
      if(e.currentTarget.checked) {
        this.ratingForm.controls.suggestion_7.addValidators([Validators.required, Validators.minLength(30)]);
        this.ratingForm.controls.suggestion_7.updateValueAndValidity();
        this.cheveron1Show = true;
        this.options7.showTicksValues = true;
        this.options7 = Object.assign({}, this.options7, {disabled: (!e.currentTarget.checked || !this.showSaveButton), ticksArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]});
      } else {
        // this.ratingForm.controls.suggestion_7.setValue('');
        this.ratingForm.controls.suggestion_7.clearValidators();
        this.ratingForm.controls.suggestion_7.updateValueAndValidity();
        this.cheveron7Show = false;
        this.options7.showTicksValues = false;
        this.options7 = Object.assign({}, this.options7, {disabled: (!e.currentTarget.checked || !this.showSaveButton), ticksArray: []});
        this.ratingForm.controls.criteria_7_marks.reset(0);
      }
    } else if (sliderOption === 'options8') {
      if(e.currentTarget.checked) {
        this.ratingForm.controls.suggestion_8.addValidators([Validators.required, Validators.minLength(30)]);
        this.ratingForm.controls.suggestion_8.updateValueAndValidity();
        this.cheveron8Show = true;
        this.options8.showTicksValues = true;
        this.options8 = Object.assign({}, this.options8, {disabled: (!e.currentTarget.checked || !this.showSaveButton), ticksArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]});
      } else {
        // this.ratingForm.controls.suggestion_8.setValue('');
        this.ratingForm.controls.suggestion_8.clearValidators();
        this.ratingForm.controls.suggestion_8.updateValueAndValidity();
        this.cheveron8Show = false;
        this.options8.showTicksValues = false;
        this.options8 = Object.assign({}, this.options8, {disabled: (!e.currentTarget.checked || !this.showSaveButton), ticksArray: []});
        this.ratingForm.controls.criteria_8_marks.reset(0);
      }
    }
  }

  calculateOverallScore() {
    let noOfSkills = 0;
    for(let i = 1; i < 9; i++) {
      if(this.candidateMarks['init_qualify_ques_'+1+'_text']) {
        noOfSkills ++;
      }
    }

    this.overallScore = (
      this.ratingForm.controls['criteria_1_marks'].value +
      this.ratingForm.controls['criteria_2_marks'].value +
      this.ratingForm.controls['criteria_3_marks'].value +
      this.ratingForm.controls['criteria_4_marks'].value +
      this.ratingForm.controls['criteria_5_marks'].value +
      this.ratingForm.controls['criteria_6_marks'].value +
      this.ratingForm.controls['criteria_7_marks'].value +
      this.ratingForm.controls['criteria_8_marks'].value ) / noOfSkills;

    console.log(this.overallScore)
  }

  fetchZohoMeeting(link: any) {
    // this.candidateService.fetchZohoMeeting(link.meeting_key).subscribe((res: any) => {
      this.meetingUrl = link.meeting_key;
      this.conference_id = link.conference_id;
      // this.modalRoot.show();
      // this.modalService.open(this.modalRoot, {size: 'xl', ariaLabelledBy: 'modal-basic-title' }).result.then(
      //   (result) => {
      //     console.log(result);
      //   },
      //   (reason) => {
      //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      //   },
      // );
    // });
  }

  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  getTickColor(value: number) {
    if (value === 0) {
      return '#ff0000';
    } else if (value === 1) {
      return '#ff6600';
    } else if (value === 2) {
      return '#ff9000';
    } else if (value === 3) {
      return '#ffb400';
    } else if (value === 4) {
      return '#f1d01a';
    } else if (value === 5) {
      return '#e9f11a';
    } else if (value === 6) {
      return '#d0f11a';
    } else if (value === 7) {
      return '#4cd821';
    } else if (value === 8) {
      return '#20bb20';
    } else if (value === 9) {
      return '#197419';
    } else if (value === 10) {
      return '#155815';
    }
    return '155815';
  }

  getStepsArray() {
    return [
      {value: 0, legend: 'Poor'},
      {value: 1},
      {value: 2},//, legend: 'Below Average'
      {value: 3},
      {value: 4},
      {value: 5},//, legend: 'Ability to Learn'
      {value: 6},
      {value: 7},//, legend: 'Above Average'
      {value: 8},
      {value: 9},
      {value: 10, legend: 'Excellent'}
    ]
  }

  getRatingFeedback(value: any) {
    if (value < 3) {
      return 'Poor';
    } else if (value < 5) {
      return 'Below Average';
    } else if (value < 7) {
        return 'Ability to learn';
    } else if (value < 8.5) {
      return 'Above Average';
    } else {
      return 'Excellent';
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if(event.target.classList.contains('dt-icon-close')) {
      this.candidateService.endMeeting(this.conference_id).subscribe((res: any) => {
        this.showSuccess('You have left the conference!')
      });
    }
  }
}
