import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { BehaviorSubject, Subject } from "rxjs";

import * as Url from './../constants/url';
@Injectable({ providedIn: 'root'})

export class HiringTeamService{
    public showHiringTeamForm$ = new BehaviorSubject<boolean>(false);
    public showDepartmentForm$ = new BehaviorSubject<boolean>(false);
    public showTeamForm$ = new BehaviorSubject<boolean>(false);
    // public openSendEmailPopup$ = new Subject<boolean>();
    constructor(private http: HttpClient){}

    getAllHiringTeam(){
        return this.http.get(Url.gethiringTeamListUrl).pipe();
    }

    getAllHiringTeamByClient(client_id: any){
        return this.http.get(Url.gethiringTeamListUrl+'?client_id='+client_id).pipe();
    }

    getHiringTeamByClient(id: any, isHtm?: any){
        let params;
        if(isHtm) {
            params = new HttpParams().set('htm_id',id);
        } else {
            params = new HttpParams().set('client_id',id);
        }
        return this.http.get(Url.gethiringTeamListUrl, {params}).pipe();
    }

    selectHiringTeamByClient(id: any, isHtm?: any){
        let params;
        if(isHtm) {
            params = new HttpParams().set('htm_id',id);
        } else {
            params = new HttpParams().set('client_id',id);
        }
        return this.http.get(Url.selectHiringGroup, {params}).pipe();
    }

    createHiringteam(data: any){
        return this.http.post(Url.gethiringTeamListUrl, data).pipe();
    }
    updateHiringteam(data: any){
        return this.http.put(Url.gethiringTeamListUrl, data).pipe();
    }

    setShowHiringTeamForm(data: boolean){
        this.showHiringTeamForm$.next(data);
    }

    getShowHiringTeamForm(){
        return this.showHiringTeamForm$.asObservable();
    }

    setShowDepartmentForm(data: boolean){
        this.showDepartmentForm$.next(data);
    }

    getShowDepartmentForm(){
        return this.showDepartmentForm$.asObservable();
    }

    setShowTeamForm(data: boolean){
        this.showTeamForm$.next(data);
    }

    getShowTeamForm(){
        return this.showTeamForm$.asObservable();
    }

    deleteHiringGroup(id: any){
        let params = new HttpParams().set('group_id',id);
        return this.http.delete(Url.gethiringTeamListUrl, {params}).pipe();
    }

    getHiringTeamMemberByClient(client_id: any) {
        return this.http.get(Url.getAllHiringMemberUrl+'?client_id='+client_id+'&first=true').pipe();
    }

    getCaSmHmForOpenPosition(op_id: any) {
        return this.http.get(Url.getCaSmHmForOpenPosition+ '/' + op_id).pipe();
    }

    getHiringTeamMemberById(username: any){
        let params = new HttpParams().set('username', username);
        return this.http.get(Url.HiringMemberUrl, {params}).pipe();
    }

    createHiringTeamMember(payload: any){
        const formData = new FormData();
        const Payload = payload;
        Object.keys(Payload).forEach((key) => {
            if(Array.isArray(Payload[key])) {
                formData.append(key, JSON.stringify(Payload[key]));
            } else {
                formData.append(key, Payload[key]);
            }
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(Url.HiringMemberUrl, formData, {headers: headers}).pipe();
    }

    createHiringTeamMemberAgainstClient(payload: any, client: any){
        const formData = new FormData();
        const Payload = payload;
        Object.keys(Payload).forEach((key) => {
            if(Array.isArray(Payload[key])) {
                formData.append(key, JSON.stringify(Payload[key]));
            } else {
                formData.append(key, Payload[key]);
            }
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.post(Url.HiringMemberUrl+'?client='+client, formData, {headers: headers}).pipe();
    }

    updateHiringTeamMember(payload: any){
        const formData = new FormData();
        const Payload = payload;
        Object.keys(Payload).forEach((key) => {
            if(Array.isArray(Payload[key])) {
                formData.append(key, JSON.stringify(Payload[key]));
            } else {
                formData.append(key, Payload[key]);
            }
        });

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.http.put(Url.HiringMemberUrl, formData, {headers: headers}).pipe();
    }

    deleteTeamMember(username: any){
        let params = new HttpParams().set('username', username);
        return this.http.delete(Url.HiringMemberUrl, {params}).pipe();
    }

    getHiringMembers(client:any){
        return this.http.get(Url.getHiringMembersUrl+'/'+client).pipe();
    }

    getHiringMembersData(cl:any){
        let params = new HttpParams().set('client_id', cl);
        return this.http.get(Url.HiringMemberData,{params}).pipe();
    }

    changePassword(payload:any){
        return this.http.put(Url.changepasswordUrl, payload).pipe();
    }

    getCalendarDataByPosition(positionId:any) {
        return this.http.get(Url.getHtmCalendar + '/' + positionId).pipe();
    }

    getHtmAvailabilities(month:any, year: any, op_id: any, htms: any) {
        return this.http.get(Url.getHtmAvailabilities + '?month=' + month + '&year=' + year + '&op_id=' + op_id + '&htms=' + htms).pipe();
    }
    getOpenPositionCalendar(month:any, year: any, op_id: any, htms: any) {
        return this.http.get(Url.getOpenPositionCalendar + '?month=' + month + '&year=' + year + '&op_id=' + op_id + '&htms=' + htms).pipe();
    }

    // setOpenSendEmailPopup(data: boolean){
    //     this.openSendEmailPopup$.next(data);
    // }

    // getOpenSendEmailPopup(){
    //     return this.openSendEmailPopup$.asObservable();
    // }

    generateMeetingUrl(payload: any){
        return this.http.post(Url.generateMeetingUrl, payload).pipe();
    }

    sendEmailToHtm(data: any) {
        return this.http.post(Url.sendEmailToHtm, data).pipe();
    }

    updateEmailToHtm(data: any) {
        return this.http.post(Url.sendEmailToHtm, data).pipe();
    }

    getScheduledInterviewData(id: any) {
        return this.http.get(Url.sendEmailToHtm+'?id=' +id).pipe();
    }

    proMarketSendMail(body: any) {
        return this.http.post(Url.proMarketSendMail, body).pipe();
    }

    getPositionSummary(op_id: any) {
        return this.http.get(Url.getPositionSummary + '/' + op_id).pipe();
    }
    
    getTempPositionSummary(payload: any) {
        return this.http.post(Url.getTempPositionSummary, payload).pipe();
    }

    getHtmAudit(op_id: any) {
        return this.http.get(Url.getHtmAudit + '/' + op_id).pipe();
    }

    getSingleHtmAudit(op_id: any, htm_id: any) {
        return this.http.get(Url.getSingleHtmAudit + '/' + op_id + '/' + htm_id).pipe();
    }
}