import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import { NgbActiveModal, NgbDateStruct, NgbInputDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/core/toast/toast-service';

import { CandidateService } from 'src/app/services/candidate.service';

@Component({
  selector: 'app-set-availability',
  templateUrl: './set-availability.component.html',
  styleUrls: ['./set-availability.component.css'],
})
export class SetAvailabilityComponent implements OnInit {
  public model!: NgbDateStruct;
  @Input() name: any;
  public faCalendar = faCalendarAlt;
  public faPlus = faPlus;
  public faTrash = faTrash;
  public startTimeModel = { hour: 13, minute: 30 };
  public EndTimeModel = { hour: 13, minute: 30 };   
  public meridian = true;
  public setForm!: FormGroup;
  public scheduleData: any = {};
  public showUpdateBtn: boolean = false;
  public setData: any = {};
  // {"availableDays":[{"day":1,"hours":[{"startTime":{"hour":1,"minute":20,"second":0},"endTime":{"hour":10,"minute":20,"second":0}},{"startTime":{"hour":5,"minute":20,"second":0},"endTime":{"hour":6,"minute":45,"second":0}}],"date":"2021-12-31","checkvalidation":false},{"day":1,"hours":[{"startTime":{"hour":0,"minute":0,"second":0},"endTime":{"hour":0,"minute":0,"second":0}},{"startTime":{"hour":0,"minute":0,"second":0},"endTime":{"hour":0,"minute":0,"second":0}}],"date":"2022-01-07","checkvalidation":false},{"day":1,"hours":[{"startTime":{"hour":10,"minute":10,"second":0},"endTime":{"hour":10,"minute":10,"second":0}}],"date":"2022-01-29","checkvalidation":false}]}
  public today = {year:new Date().getFullYear(), month:new Date().getMonth()+1, day: new Date().getDate()};
  selectedClient: any;
  minuteStep = 30;
  
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder, 
    private candidateService: CandidateService,
    private toastService: ToastService,
    config: NgbInputDatepickerConfig) {
      // setting datepicker popup to close only on click outside
      config.autoClose = false;
      config.placement = 'bottom-start';
    }

  ngOnInit(): void {
    this.createform();
    this.addDays();
    this.getSetAvailability();
    
  }

  createform() {
    let selectedClient: any = localStorage.getItem('useitOnceCandidateassociated');
    this.selectedClient = JSON.parse(selectedClient);
    this.setForm = this.fb.group({
      availableDays:this.fb.array([]),
      htm_id: [this.selectedClient.user_id]
    });
  }
  

  newDay(): FormGroup {
    return this.fb.group({
      day: 1,
      hours: this.fb.array([this.newTime()]),
      date: '',
      checkvalidation: false
    })
  }

  newTime(): FormGroup {
    return this.fb.group({
      startTime: [''],
      endTime:['']
    })
  }

  getAvailableDays(): FormArray {
    return this.setForm.get('availableDays') as FormArray;
  }

  addDays() {
    this.getAvailableDays().push(this.newDay());
  }

  removeDays(empIndex: number) {
    this.getAvailableDays().removeAt(empIndex);
  }

  getDaysHours(empIndex: number ): FormArray {
    return this.getAvailableDays()
      .at(empIndex)
      .get('hours') as FormArray;
  }

  addDayHour(empIndex: number) {
    this.getDaysHours(empIndex).push(this.newTime());
  }

  removeDayHour(empIndex: number, skillIndex: number) {
    this.getDaysHours(empIndex).removeAt(skillIndex);
  }



  setAvailability() {
    this.setForm.value?.availableDays.map((days: any) => {
      days.hours.map((hours: any) => {
        hours.startTime = hours.startTime.hour + ':' + hours.startTime.minute;
        hours.endTime = hours.endTime.hour + ':' + hours.endTime.minute;
      })
    });
    if(this.showUpdateBtn) {
      this.setForm.value.schedule_id = this.scheduleData?.id;
      this.candidateService.setAvailability(this.setForm.value).subscribe(val =>{
        if(val) {
          this.activeModal.close();
          this.toastService.show('Schedule updated successfully!', {
            classname: 'bg-success text-light',
            delay: 5000,
          });
        } else {
          this.toastService.show('Schedule not updated!', {
            classname: 'bg-danger text-light',
            delay: 5000,
          });
        }
      });
    } else {
      this.candidateService.setAvailability(this.setForm.value).subscribe(val =>{
        if(val) {
          this.activeModal.close();
          this.toastService.show('Schedule created successfully!', {
            classname: 'bg-success text-light',
            delay: 5000,
          });
        } else {
          this.toastService.show('Schedule not updated!', {
            classname: 'bg-danger text-light',
            delay: 5000,
          });
        }
      });
    }
    // console.log(this.setForm.value)
  }

  

  clearFormArray() { 
    //Angular 8 +
    this.getAvailableDays().clear();   
    //older Versions of angualar
    //while (this.teachers().length) {
    //  this.teachers().removeAt(0);
    //}
  }

  getSetAvailability(){
    this.candidateService.getSetAvailability(this.selectedClient.user_id).subscribe((val: any) => {
      if(val) {
        this.scheduleData = val;
        if(val?.scheduled_found){
          this.showUpdateBtn = true;
        }
        this.patchForm();
      }
    });
  }

  patchForm(){
    
    if(this.scheduleData.scheduled_found) {
      this.clearFormArray();
      this.setData = {"availableDays": []};
      this.scheduleData?.show_shedule_data.forEach((element: any, index: any) => {
        this.setData.availableDays[index] = [];
        this.setData.availableDays[index].day = index + 1;
        this.setData.availableDays[index].date = element.date;
        this.setData.availableDays[index].checkvalidation = false;
        this.setData.availableDays[index].hours = [];
        element?.hours.forEach((el: any, i: any) => {
          this.setData.availableDays[index].hours.push({
            startTime: {
              hour: parseInt(el.startTime.split(':')[0]),
              minute: parseInt(el.startTime.split(':')[1]),
              second: 0
            },
            endTime: {
              hour: parseInt(el.endTime.split(':')[0]),
              minute: parseInt(el.endTime.split(':')[1]),
              second: 0
            }
          })
        });
      });
      const setData: any = {"availableDays":[{"day":1,"hours":[{"startTime":{"hour":1,"minute":20,"second":0},"endTime":{"hour":10,"minute":20,"second":0}},{"startTime":{"hour":5,"minute":20,"second":0},"endTime":{"hour":6,"minute":45,"second":0}}],"date":"2021-12-31","checkvalidation":false}]};
      if(this.scheduleData?.schedule_data.length === 0) {
        setData?.availableDays.forEach((c: any, i: number) => {
          this.addDays();
 
         if (setData?.availableDays[i]?.hours.length) {
         this.getDaysHours(i).clear();
           
         setData.availableDays[i].hours.forEach((g: any, j: number) => {
            this.addDayHour(i);
          });
         }
       });
      }
    }
    if (this.setData?.availableDays.length) {
      // this._conditionsFormArray.clear();
      this.setData?.availableDays.forEach((c: any, i: number) => {
         this.addDays();

        if (this.setData?.availableDays[i]?.hours.length) {
        this.getDaysHours(i).clear();
          
          this.setData.availableDays[i].hours.forEach((g: any, j: number) => {
            this.addDayHour(i);
          });
        }
      });
    }
  
    


    // this.setData.availableDays.forEach((a: any, i: number) => {
    //   this.addDays();
    //   this.addDayHour(0)
    //   // a.hours.forEach((b: any, j: number) => {
    //   //   this.addDayHour(j)
    //   // })
    //   console.log(this.setForm.value);
    // })
    setTimeout(() => {
      this.setForm.patchValue(this.setData);
    }, 10);
    setTimeout(() => {
      this.openAllDatepicker();
    }, 100)

    console.log(this.setForm.value);

  }

  openAllDatepicker() {
    Array.from(document.querySelectorAll('.input-group-append')).forEach(el => {
      const btn = el.querySelector('button.calendar') as HTMLElement;
      btn.click();
      // el.remove();
    });
    
  }

  beforeClose(e: any) {
    e.preventDefault();
  }

  viewAvailability() {
    window.open(this.scheduleData.i_frame, '_blank');
  }

  isSetFormValid() {
    let isFormValid = true;
    this.setForm.value.availableDays?.forEach((day: any) => {
      if(!day.date) {
        isFormValid = false;
      }
      day.hours.forEach((hour: any) => {
        if(!hour.startTime || !hour.endTime) {
          isFormValid = false;
        }
      });
    });
    return isFormValid;
  }
}
