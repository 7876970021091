<div class="widget-col createPosition">
    <h2 class="widget-title">
        Home</h2>
    <div class="candidate-main-section">
        <div class="row pr-3">
            <div class="col-sm-3">
                <form class=" mt-2 mb-4" *ngIf="candidateForm" [formGroup]="candidateForm">
                    <div class="prof-pic-wrapper d-flex">
                        <ng-container>
                            <img *ngIf="imageURL && imageURL !== ''" [src]="imageURL" [alt]="candidateForm.value.name"
                                class="prof-pic img-position">
                            <img *ngIf="!imageURL && (candidateData?.profile_photo || (!candidateData?.profile_photo && !candidateData?.profile_pic_url))"
                                class="prof-pic img-position"
                                src="{{candidateData?.profile_photo && candidateData?.profile_photo !== 'None' ? imageBaseUrl + candidateData?.profile_photo : './../../assets/images/big-default-avatar.png'}}"
                                alt="Profile photo" />
                            <fa-icon class="edit-pencil" *ngIf="isCandidateBackgroundEditMode"
                                (click)="fileInput.click()" [icon]="faCamera"></fa-icon>
                            <input #fileInput type="file" class="form-control-file" (change)="showPreview($event)"
                                style="display: none;">
                            <input type="hidden" name="fileHidden" formControlName="profile_photo" />
                        </ng-container>
                        <div class="w-100">
                            <div class="cand-title-box">
                                <span class="candidate-name">
                                    <span class="c-name"> {{candidateData?.name | titlecase}} </span>&nbsp;&nbsp;
                                    <span class="c-nickname" *ngIf="candidateData?.nickname"> {{'(' +
                                        candidateData?.nickname + ')'}}</span>
                                </span>
                            </div>
                            <div class="cand-cont-box  ">
                                <span class="float-start mr-3">
                                    <fa-icon [icon]="faEnvelope" class="mr-1"
                                        [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '15px'}"></fa-icon>
                                    <a class="text-dark" [href]="'mailto:'+candidateData?.email"
                                        *ngIf="candidateData?.email">{{candidateData?.email }}</a>
                                    <span class="text-dark" *ngIf="!candidateData?.email">{{'Not updated'}}</span>
                                </span>
                                <span class="float-start ">
                                    <fa-icon [icon]="faPhoneAlt" class="mr-1"
                                        [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '15px','transform': 'rotate(90deg)'}"></fa-icon>
                                    <a class="text-dark " [href]="'tel:'+candidateData?.phone_number"
                                        *ngIf="candidateData?.phone_number">{{candidateData?.phone_number }}</a>
                                    <span class="text-dark" *ngIf="!candidateData?.phone_number">
                                        {{'Not Specified'}}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="!selectedPositionDetail">

                        <div class="d-block mt-4 mb-3 background-info">
                            <h6 style="margin-bottom: 4px;">Background
                                <fa-icon style="float:right; cursor: pointer;" *ngIf="!isCandidateBackgroundEditMode"
                                    (click)="toggleBackgroundEdit()" [icon]="faPencilAlt"></fa-icon>
                            </h6>
                            <hr style="border-top: 2px solid rgb(18 0 0 / 72%);" class="m-0 mb-4" />
                            <p><b>First Name:</b>
                                <span *ngIf="!isCandidateBackgroundEditMode">
                                    {{candidateData?.name || 'Not Specified'}}</span>
                                <input placeholder="eg. Justin" *ngIf="isCandidateBackgroundEditMode"
                                    formControlName="first_name" type="text" class="form-control" />
                            </p>
                            <p><b>Last Name:</b>
                                <span *ngIf="!isCandidateBackgroundEditMode">
                                    {{candidateData?.last_name || 'Not Specified'}}</span>
                                <input placeholder="eg. Langer" *ngIf="isCandidateBackgroundEditMode"
                                    formControlName="last_name" type="text" class="form-control" />
                            </p>
                            <p><b>Nickname:</b>
                                <span *ngIf="!isCandidateBackgroundEditMode">
                                    {{candidateData?.nickname || 'Not Specified'}}</span>
                                <input placeholder="eg. Justy" *ngIf="isCandidateBackgroundEditMode"
                                    formControlName="nickname" type="text" class="form-control" />
                            </p>
                            <p><b>Salary Requirements:</b>
                                <span *ngIf="!isCandidateBackgroundEditMode"> {{candidateData?.currency ||
                                    ''}}{{candidateData?.salaryRange || 'Not Specified'}}</span>
                                <ng-container *ngIf="isCandidateBackgroundEditMode">
                                    <p>Currency:
                                        <select style="width: 80px;height: 40px;" formControlName="currency">
                                            <option value="$">$</option>
                                            <option value="€">€</option>
                                            <option value="£">£</option>
                                            <option value="¥">¥</option>
                                            <option value="₣">₣</option>
                                            <option value="₹">₹</option>
                                        </select>
                                    </p>
                                    <p>Desired Salary :
                                        <input type="text" formControlName="salaryRange" class="form-control" />
                                    </p>
                                </ng-container>
                            </p>
                            <p><b>Location:</b>
                                <span *ngIf="!isCandidateBackgroundEditMode">
                                    {{candidateData?.location || 'Not Specified'}}</span>
                                <input placeholder="eg. California" formControlName="location" type="text"
                                    class="form-control" #search
                                    [style.display]="isCandidateBackgroundEditMode ? 'block' : 'none'" />
                            </p>
                            <p *ngIf="isCandidateBackgroundEditMode">
                                <fa-icon [icon]="faPhoneAlt" class="mr-1"
                                    [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px', 'transform': 'rotate(90deg)' }"></fa-icon>
                                <b>Phone:</b>
                                <input placeholder="eg. 1234567890" *ngIf="isCandidateBackgroundEditMode"
                                    formControlName="phone_number" type="text" class="form-control" />
                            </p>
                            <div *ngIf="isCandidateBackgroundEditMode && candidateForm.controls['phone_number'].errors?.pattern"
                                class="alert">
                                Phone number must be exactly 10 digit & only number is allowed.
                            </div>
                            <p>
                                <fa-icon [icon]="faLinkedin" class="mr-2"
                                    [styles]="{'stroke': 'var(--primary)', 'color': 'var(--primary)', 'font-size': '20px'}"></fa-icon>
                                <a class="text-dark" *ngIf="candidateData?.skype_id" target="_blank"
                                    [href]="(candidateData.skype_id.startsWith('http://') || candidateData.skype_id.startsWith('https://')) ? candidateData.skype_id : 'https://' + candidateData.skype_id">Visit
                                    Linkedin Profile</a>
                                <span *ngIf="!candidateData?.skype_id && !isCandidateBackgroundEditMode">
                                    {{'Not updated'}}</span>
                                <input placeholder="eg. https://linkedin.com" *ngIf="isCandidateBackgroundEditMode"
                                    formControlName="skype_id" type="text" class="form-control" />
                            </p>
                            <div class="tile-card" style="word-wrap: break-word;">
                                <p>
                                    <fa-icon [icon]="faFile"></fa-icon>
                                    <b class="ml-1">Resume<small *ngIf="isCandidateBackgroundEditMode">*</small>:</b>
                                    <span *ngIf="candidateData?.documentLinks?.length==0"> NA </span>
                                    <!-- <fa-icon *ngIf="candidateData?.documentLinks?.length != 0" [icon]="faDownload"
                                        (click)="toggleResume = !toggleResume;" class="cursor-pointer"></fa-icon> -->
                                    <a *ngIf="documentLinks[0] && !isCandidateBackgroundEditMode" [href]="imgBaseUrl + documentLinks[0]?.url" target="_blank">{{decodeUrl(
                                            documentLinks[0]?.name)}}</a>
                                </p>
                                <div *ngIf="isCandidateBackgroundEditMode">
                                    <input type="file" (change)="onFileChange($event)" 
                                        class="form-control-file" />
                                    <ul class="resume-list">
                                        <li *ngIf="documentLinks[0]">
                                            <a [href]="imgBaseUrl + documentLinks[0]?.url" target="_blank">{{decodeUrl(
                                                documentLinks[0]?.name)}}</a>
                                            <a class="ml-4" href="javascript:void(0)"
                                                (click)="deleteDoc( documentLinks[0]?.url)">
                                                <fa-icon [icon]="faTrash"></fa-icon>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <button [disabled]="candidateForm.invalid" *ngIf="isCandidateBackgroundEditMode" class="btn btn-primary float-right mt-3"
                                    (click)="updateCandidateBg()">Save</button>
                                <button *ngIf="isCandidateBackgroundEditMode"
                                    class="btn btn-secondary float-right mt-3 mr-3"
                                    (click)="cancelCandidateUpdate()">Cancel</button>
                            </div>
                        </div>
                    </ng-container>
                </form>
                <div class="position-box">
                    <h6 class="mb-1 mt-2">Active Positions:</h6>
                    <span *ngIf="activePositions.length == 0" class="ml-2">No Active Positions</span>
                    <table *ngIf="activePositions.length > 0" class="table table-borderless c-data-table">
                        <tr *ngFor="let item of activePositions">
                            <td class="font-weight-bold">
                                <span>{{item.client_name}}</span>
                            </td>
                            <td>
                                <a href="javascript:void(0)"
                                    (click)="openPositionModal(item,positionModal)">{{item.position_name}}</a>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-sm-9 widget-box">
                <div class="row">
                    <div class="col-sm-9">
                        <app-candidate-schedule *ngIf="calendarData" [calendarData]="calendarData"
                            [activePositionsForCand]="activePositions"
                            (interviewData)="getinterviewData($event)"></app-candidate-schedule>
                    </div>
                    <div class="col-sm-3">
                        <div class="select-interview" *ngIf="!showHtmInfo">
                            <h5>Select an Interview</h5>
                            <p class="fs-5 font-weight-normal">Click on any event in the calendar to view its details
                            </p>
                        </div>
                        <div class="selected-htm-interview" *ngIf="showHtmInfo">
                            <div class="d-flex mb-4">
                                <fa-icon [icon]="faCamera"></fa-icon>
                                <div class="ml-3 fs-5 int-details">
                                    <p class="fs-5">Interview with <br><span class="font-weight-bold"
                                            style="color: #666666;">{{htmCalendarData?.interviewer_names}}</span></p>
                                    Company : {{htmCalendarData?.client_name}}<br>
                                    Position : {{htmCalendarData?.position_name}}<br>
                                    Date: {{htmCalendarData?.date}}<br>
                                    Timezone:{{htmCalendarData?.long_timezone}}
                                    <br>
                                    <a *ngIf="interviewDate > viewDate" (click)="startInterview()"><button
                                            class="btn save-button">Start QVideo</button></a>
                                </div>
                            </div>
                            <div class="d-flex" *ngFor="let it of htmCalendarData.interviewer_names">
                                <img [src]="it.profile_pic" class="photo">
                                <div class="ml-3 fs-5 py-2">
                                    <span>{{it.name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="legends p-3 mt-4">
                            <div class="legend ">
                                <fa-icon [icon]="faCalendarAlt" style="color: var(--primary);"></fa-icon>
                                <span class="legend-name">Interview Scheduled </span>
                            </div>
                            <div class="legend ">
                                <fa-icon [icon]="faCalendarAlt" style="color: Red;"></fa-icon>
                                <span class="legend-name">Interviews Completed</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<ng-template #positionModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Position Details:</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <div class="pos-details">
                <h5 *ngIf="currentPosition?.position_title">Position Title - {{currentPosition?.position_title}}</h5>
                <h6 *ngIf="currentPosition?.kickoff_start_date">Kick-off Date - {{currentPosition?.kickoff_start_date |
                    formatDate}}</h6>
                <h6 *ngIf="currentPosition?.sourcing_deadline">Sourcing Date - {{currentPosition?.sourcing_deadline |
                    formatDate}}</h6>
                <h6 *ngIf="currentPosition?.target_deadline">Target Completion Date - {{currentPosition?.target_deadline
                    | formatDate}}</h6>
                <h6 *ngIf="currentPosition?.salary_range">Salary Range -
                    {{'$'+currentPosition?.salary_range.split(',')[0] + ' - ' +
                    '$'+currentPosition?.salary_range.split(',')[1]}}</h6>
                <h6 *ngIf="currentPosition?.no_of_open_positions || currentPosition?.no_of_open_positions!==''">No of
                    Open Positions - {{currentPosition?.no_of_open_positions}}</h6>
                <h6 *ngIf="currentPosition?.location || currentPosition?.location!==''">Job Location -
                    {{currentPosition?.location || 'NA'}}</h6>
                <h6 *ngIf="currentPosition?.special_instruction || currentPosition?.special_instruction!==''">Special
                    Instructions -
                    {{currentPosition?.special_instruction || 'NA'}}</h6>
            </div>
            <div class="row">
                <div class="hiring-team-details col-sm-6" *ngIf="currentPosition?.members">
                    <h6>Hiring Team Members -</h6>
                    <div class="hiring-member-card my-2" *ngFor="let item of currentPosition.members">
                        <div class="d-flex center-align">
                            <img [src]="item?.profile_photo ? item?.profile_photo: './../../assets/images/big-default-avatar.png'"
                                class="prof-pic">
                            <div>
                                <div class="htm-title-box">
                                    <span class="c-name"> {{item?.name | titlecase}} </span>&nbsp;&nbsp;
                                </div>
                                <!-- <div class="htm-cont-box ">
                                    <span class="ml-2">
                                        {{item?.job_title}}
                                    </span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="skill-details  col-sm-6" *ngIf="currentPosition?.skillsets">
                    <h6>Skillset -</h6>
                    <table class="table  table-borderless c-data-table">
                        <tr *ngFor="let item of currentPosition.skillsets ; let i = index">
                            <td>
                                Skill {{i+1}}
                            </td>
                            <td>
                                {{item.skillset_name}}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
    </div>
</ng-template>