<main id="positions-content">
    <app-header style="z-index: 99;position: relative;  "></app-header>
    <div class="widget-wrapper" style="bottom:0">

        <app-detail-header></app-detail-header>

        <div class="page-body">
            <div class="widget-box">
                <div class="row">
                    <div class="col-sm-9">
                        <div class="white-box">
                            <p class="text-dark font-weight-bold h5">Please enter the following details to invite the
                                user.</p>
                            <div class="mt-4">
                                <form [formGroup]="createUsersForm">

                                    <div *ngIf="currentUserRoleCode == '/sa' " class="form-group">
                                        <label class="col-form-label">Select Client<small>*</small></label>
                                        <div>
                                            <select class="form-control form-input" formControlName="client">
                                                <option value="">Please select</option>
                                                <option *ngFor="let item of clientList" [value]="item.id">
                                                    {{item.company_name}}</option>
                                            </select>
                                            <div *ngIf=" (createUsersForm.controls['client'].invalid && (createUsersForm.controls['client'].dirty || createUsersForm.controls['client'].touched))"
                                                class="alert">
                                                <div *ngIf="createUsersForm.controls['client'].errors?.required">
                                                    This field is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group ">
                                        <label for="input-jobtitle" class=" col-form-label">Email Address<small>*</small></label>
                                        <div class="">
                                            <input type="email" class="form-control form-input" formControlName="email"
                                                placeholder="Eg: candidate@gmail.com">
                                            <div *ngIf="createUsersForm.controls['email'].invalid && (createUsersForm.controls['email'].dirty || createUsersForm.controls['email'].touched)"
                                                class="alert">
                                                <div *ngIf="createUsersForm.controls['email'].errors?.required">
                                                    Email is required.
                                                </div>
                                                <div *ngIf="createUsersForm.controls['email'].errors?.email">
                                                    Invalid Email, please try again.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="invite-note" class=" col-form-label">Add Note</label>
                                        <textarea type="text" id="invite-note" class="form-control"
                                            formControlName="note" placeholder="Eg: Note">
                                            </textarea>
                                    </div>

                                </form>

                                <!-- <p class="mb-0">Email Address</p>
                                    <input type="text" class="form-control form-input" placeholder="Eg: candidate@gmail.com" > -->
                            </div>
                            <div [formGroup]="createUsersForm" class="mt-4">
                                <p class="mb-2">Select User Role <small>*</small></p>
                                <span>
                                    <input type="radio" #option1Ref (change)="roleChanged('is_candidate')" checked formControlName="role"
                                        value="is_candidate" name="role" id="radio1" autocomplete="off">
                                    <label class="ml-1" for="radio1">Candidate</label>
                                </span>
                                <span class="ml-3">
                                    <input type="radio" (change)="roleChanged('is_htm')" formControlName="role"
                                        value="is_htm" name="role" id="radio2" autocomplete="off">
                                    <label class="ml-1" for="radio2">Hiring Member</label>
                                </span>
                                <span class="ml-3">
                                    <input type="radio" (change)="roleChanged('is_sm')" formControlName="role"
                                        value="is_sm" name="role" id="radio3" autocomplete="off">
                                    <label class="ml-1" for="radio3">Senior Manager</label>
                                </span>
                            </div>
                        </div>
                        <!-- <div class="row">
                            djdjdj
                        </div> -->
                    </div>
                    <div class="col-sm-3">
                        <div class="image-text mt-4 px-4">
                            <h2 class="text-secondary font-weight-bold mb-0">Invite</h2>
                            <h2 class="text-secondary font-weight-bold ">User</h2>
                            <h5 class="text-secondary " style="font-weight: 500;">Please give the email address and role
                                to invite the user.</h5>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-9">
                        <div class="float-end mt-3">
                            <button class="btn cancel-button mr-3" (click)="goBack()">Back</button>
                            <button class="btn save-button " [disabled]="createUsersForm.invalid"
                                (click)="inviteUsers()">Invite User</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</main>