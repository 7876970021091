import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faSort, faColumns } from '@fortawesome/free-solid-svg-icons';
import { UserService } from 'src/app/services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.services';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/core/toast/toast-service';
import { NavigatorComponent } from 'src/app/core/navigator/navigator.component';
@Component({
  selector: 'app-view-all-users',
  templateUrl: './view-all-users.component.html',
  styleUrls: ['./view-all-users.component.css']
})
export class ViewAllUsersComponent implements OnInit {
  faSort = faSort;
  sortedTable: any;
  faColumns = faColumns;
  status1: boolean = true;
  status2: boolean = false;
  status3: boolean = false;
  status4: boolean = false;
  status5: boolean = false;
  selectAll: boolean = false;
  openPositionList: any = [];
  tableData: any = [];
  draftedPositionList: any = [];
  archivedPositionList: any = [];
  completedPositionList: any = [];
  AllTableData: any = [];
  sortKey: any;
  usersList: any = [];
  usersItem: any = [];
  total_counter: any
  reverse: boolean = false;
  query: any;
  col_HM: boolean = true;
  col_curr_status: boolean = true;
  col_target: boolean = true;
  col_total_pos: boolean = true;
  col_pos_filled: boolean = true;
  col_ac: boolean = false;
  showInvitePage: boolean = false;
  showAllUsersPage: boolean = true;
  usersListData: any;
  usersTable: any;
  candList: any;
  smList: any;
  hmList: any;
  caList: any;
  userscount: any = {};
  email = '';
  currentModal: any;
  closeResult = '';
  currentUserReset: UserData
  currentUserReminder: UserData;
  currentUserRoleCode: string;
  status6: boolean = false;


  constructor(
    private userservice: UserService,
    private authService: AuthService,
    private modalService: NgbModal,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    this.getUsersData();
  }

  getUsersData() {
    this.userservice.getUsersListforTable().subscribe((data: any) => {
      this.usersListData = data.data;
      this.usersTable = this.usersListData
      this.candList = this.usersListData.filter((e: any) => (e.role == 'is_candidate' && e.status == 'completed'))
      this.smList = this.usersListData.filter((e: any) => (e.role == 'is_sm' && e.status == 'completed'))
      this.hmList = this.usersListData.filter((e: any) => (e.role == 'is_htm' && e.status == 'completed'))
      this.caList = this.usersListData.filter((e: any) => (e.role == 'is_ca' && e.status == 'completed'))
      this.completedPositionList = this.usersListData.filter((e: any) => (e.status != 'completed'))
      this.userscount.cand = this.candList.length
      this.userscount.smList = this.smList.length
      this.userscount.hmList = this.hmList.length
      this.usersTable = this.candList;
      this.sortedTable = data.sort_by
      this.sortTable(this.sortedTable)
    }
    )
  }
  getRole(str: any): any {
    if (str == 'is_ca') {
      return 'CA'
    }
    if (str == 'is_sm') {
      return 'SM'
    }
    if (str == 'is_htm') {
      return 'HTM'
    }
    if (str == 'is_candidate') {
      return 'CAN'
    }
    else
      return 'OTH'
  }
  sortTable(column: any): void {
    this.selectAll = false;
    for (let i = 0; i < this.usersTable.length; i++) {
      this.usersItem[i] = false;
    }
    if (this.sortKey == column) {
      // If the same column is clicked again, reverse the order
      this.reverse = !this.reverse;
    } else {
      // If a new column is clicked, set the column and set ascending order
      this.sortKey = column;
      this.reverse = false;
    }
    if (column.startsWith('reverse-')) {
      this.reverse = !this.reverse;
      column = column.replace('reverse-', '');
    }
    this.usersTable.sort((a: any, b: any) => {
      if (column == 'user_since') {
        var l: any = new Date(a[column]);
        var m: any = new Date(b[column]);
        return this.reverse ? (m - l) : (l - m)
      }
      else {
        if (a[column] == null) {
          a[column] = ''
        }
        var x = a[column];
        var y = b[column];
        if (column == 'open_position') {
          return this.reverse ? y - x : x - y;
        }
        x = x.toString();
        y = y.toString();
        return this.reverse ? y.localeCompare(x) : x.localeCompare(y);
      }
    });
    this.setSortedTable(column)
  }
  setSortedTable(column: any) {
    this.sortedTable = column
    if (this.reverse) {
      this.sortedTable = "reverse-" + column
    }
    this.authService.updateSortedData({ table: "users", column: this.sortedTable }).subscribe((data: any) => {
      // console.log(data)
      if (this.sortedTable.startsWith('reverse-')) {
        this.sortedTable = this.sortedTable.replace('reverse-', '');
      }
    })
  }
  userActionFunc() {
    this.showInvitePage = true;
    this.showAllUsersPage = false;
  }
  searchText(text: any) {
    this.query = text;
  }

  selectColumns() {

  }
  usersSelect(item: any, value: boolean) {
    if (value) {
      this.usersList.push(item)
    }
    else {
      let i = 0;
      for (i = 0; i < this.usersList.length; i++) {
        if (this.usersList[i].id == item.id) {
          break;
        }
      }
      this.usersList.splice(i, 1);
      this.selectAll = false;
    }
  }
  selectAllItems() {
    for (let i = 0; i < this.usersTable.length; i++) {
      this.usersItem[i] = this.selectAll;
      this.usersSelect(this.usersTable[i], this.selectAll)
    }
    if (!this.selectAll) {
      this.usersList = []
    }
  }
  openFile(file: any) {
    const linkedIn_link = file.startsWith('http://') || file.startsWith('https://') ? file : 'https://' + file;
    window.open(linkedIn_link, '_blank')
  }

  @ViewChild('scrollDiv') scrollDiv: ElementRef;
  clickPos(name: any) {
    this.scrollDiv.nativeElement.scrollTop = 0;
    for (let i = 0; i < this.usersTable.length; i++) {
      this.usersItem[i] = false;
    }
    this.usersList = []
    this.selectAll = false;
    this.status1 = false;
    this.status2 = false;
    this.status3 = false;
    this.status4 = false;
    this.status5 = false;
    this.status6 = false;
    if (name == 'Candidates') {
      this.usersTable = this.candList;
      this.status1 = true;
    }
    if (name == 'SM') {
      this.usersTable = this.smList;
      this.status2 = true;
    }
    if (name == 'HM') {
      this.usersTable = this.hmList;
      this.status3 = true;
    }
    if (name == 'Pending') {
      this.usersTable = this.completedPositionList;
      this.status4 = true;
    }
    if (name == 'All') {
      this.usersTable = this.usersListData;
      this.status5 = true;
    }
    if (name == 'CA') {
      this.usersTable = this.caList;
      this.status6 = true;
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 3000,
    });
  }



  open(content: any) {
    this.currentModal = this.modalService.open(content, { size: 'md' });
    this.currentModal.result.then(
      (result: any) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason: any) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  openForgotPasswordPopup(popup: any, currentUserReset: UserData) {
    this.currentUserReset = currentUserReset
    this.currentModal = this.open(popup)
  }
  openReminderPopup(popup: any, currentUserReminder: UserData) {
    this.currentUserReminder = currentUserReminder
    this.currentModal = this.open(popup)
  }

  sendPasswordResetEmail(email: string) {
    this.authService.requestPasswordReset({ email }).subscribe((res: any) => {
      this.showSuccess('Password reset email sent')
      this.modalService.dismissAll()
    }, (error: HttpErrorResponse) => {
      console.error(error)
    })
  }
  sendReminderEmail(email: string) {
    this.authService.requestUserReminder({ email }).subscribe((res: any) => {
      this.showSuccess('Reminder email sent')
      this.modalService.dismissAll()
    }, (error: HttpErrorResponse) => {
      console.error(error)
    })
  }


}

interface UserData {
  name: string;
  email: string;
}
