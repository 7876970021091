import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.services';
import { CandidateService } from 'src/app/services/candidate.service';
import * as Url from './../../constants/url';
import { timeZone } from './../../constants/timeZones';
import {
  faEnvelope,
  faBalanceScale,
  faThumbsUp,
  faThumbsDown,
  faTrophy,
  faPhone,
  faFile,
  faInfoCircle,
  faCarrot,
  faUser,
  faGlobe,
  faVideo, faClock, faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HiringTeamService } from 'src/app/services/hiringteam.service';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { ToastService } from 'src/app/core/toast/toast-service';
import { PositionService } from 'src/app/services/position.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/services/modal.service';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.css']
})
export class InsightsComponent implements OnInit, OnChanges {

  public today = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
  public yesterday = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
  public imgBaseUrl = Url.imgBaseUrl;
  faCalendar = faCalendarAlt;

  @Input() candidateData: any;
  @Input() skillset: any;
  @Input() htmMembers: any;
  @Input() selectedHtmsQ: any
  @Output() candInfoAction = new EventEmitter();
  userRole: any;
  currentPosition: any;
  selectedHtmId: any;
  currentUserRoleCode: any;
  selectedHtm: any;
  activeClientName: any;
  selectedCandidate: any;
  hrGroupList: any;
  faTrophy = faTrophy;
  faShare = faCopy;
  faPhone = faPhone;
  faCheckCircle = faCheckCircle;
  faEnvelope = faEnvelope;
  faLinkedin = faLinkedin;
  faThumbsUp = faThumbsUp;
  faThumbsDown = faThumbsDown;
  faInfoCircle = faInfoCircle;
  faUser = faUser;
  faVideo = faVideo;
  faClock = faClock;
  faCarrot = faCarrot;
  faGlobe = faGlobe;
  faFile = faFile;
  candidateList: any;
  emailForm!: FormGroup;
  mailBody: any
  isViewSchedule: boolean = false
  zoomUrl: any;
  selectedCandidates: any;
  selectedCan: any;
  option1: boolean = true;
  option2: boolean = false;
  minuteStep = 30;
  selectedHtmArr: any = [];
  clientId: any;
  toHTMIds: any = [];
  showHtmCalWarning: boolean = false;
  dateError: boolean = false;
  allPositionData: any;
  timeZones: any = [];
  skillData: any = {};
  barChart: any;
  isModalOpen = false;
  htmNames: any = [];
  qBrainComments: string = '';
  currentPositionName: string | null;
  constructor(private authService: AuthService,
    private candidateService: CandidateService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private htmService: HiringTeamService,
    private modalOpenService: ModalService,
    private toastService: ToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    console.log(this.candidateData)
    let userdata: any = localStorage.getItem('currentUser');
    userdata = JSON.parse(userdata);
    if (userdata) {
      this.userRole = userdata?.role;
    }
    let clientData: any = localStorage.getItem('clientData');
    clientData = JSON.parse(clientData);
    this.activeClientName = clientData.client_name;
    this.clientId = clientData.clientId;
    this.currentPosition = this.activatedRoute.snapshot.params['positionId'];
    this.currentPositionName = localStorage.getItem('currentPositionName');
    this.selectedHtmId = this.selectedHtm?.id;
    this.currentUserRoleCode = this.authService.currentUserRoleCode;
    this.option1 = true;
    this.option2 = false;
    this.timeZones = timeZone;
    // this.candidateService.getSelectedCandidate().subscribe((val) => {
    //   if (val.id) {
    //     this.activeClientName = val.company_name;
    //     this.clientId = val.id;
    //     this.selectedCandidate = val?.open_position_data?.filter((el: any) => el?.id == this.currentPosition)[0];
    //     if (this.selectedCandidate?.hiring_group !== undefined) {
    //       this.getPositionsCandidates(this.selectedCandidate.id);
    //     }
    //   }
    // });
  }

  @ViewChild('interviewPage') interviewPage: any;

  barChartColors = ['#a7a1a1', '#c16060', '#2f76d1', '#ec9a25', '#25c4ec', '#252bec', '#000000', '#ec25ce', '#b0bbde', '#009688', '#9c27b0', '#ffc107', '#766943', '#795548', '#d18a85', '#d1b86b'];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.skillset?.currentValue) {
      this.skillData = changes?.skillset?.currentValue
      this.barChart = {
        chartType: 'BarChart',
        dataTable: this.skillset.graphData,
        options: {
          title: 'Voting Analysis',
          height: 320,
          width: 450,
          legend: { position: 'top', maxLines: 3, fontSize: 13 },
          colors: this.barChartColors,
          // vAxis: { title: 'Required Skills', titleTextStyle: { color: '#FF0000' } },
          hAxis: {
            title: '',
            titleTextStyle: { fontSize: 13 },
            ticks: [{ v: 0, f: 'Poor' }, { v: 2.5, f: 'Below Average' }, { v: 5, f: 'Ability To Learn' }, { v: 7.5, f: 'Above Average' }, { v: 10, f: 'Excellent' }],
            viewWindow: {
              max: 10,
              min: 0
            }
          }
        }
      }
    }
    if (this.selectedHtmsQ.length > 1) {
      this.getQbrainSummary()
    }
  }
  getQbrainSummary() {
    let op_id = this.activatedRoute.snapshot.params['positionId'];
    let htm = this.selectedHtmsQ.toString()
    this.candidateService.getQbrainsSummary(htm, this.candidateData.candidate_id, op_id).subscribe((res: any) => {
      this.qBrainComments = res.ai_summary;
    })
  }
  startInterview(item: any, cand: any) {
    // console.log(item, cand)
    this.candidateService.setCandidateData(cand);
    const op_id = this.activatedRoute.snapshot.params['positionId'];
    const interviewId = item.interview_id;
    // console.log(op_id)
    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree([this.authService.currentUserRoleCode, 'interview-screen', op_id, cand.candidate_id, interviewId])
    // );
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['interview', interviewId])
    );
    window.open(url, "_blank");
  }
  copyInterviewLink(item: any, cand: any) {
    // const rootUrl = window.location.origin;
    // const op_id = localStorage.getItem('currentPosition');
    // const interviewId = item.interview_id;
    const url = item.link
    // const url = `${rootUrl}${this.authService.currentUserRoleCode}/interview-screen/${op_id}/${cand.candidate_id}/${interviewId}`;
    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree([rootUrl, 'interview-screen', op_id, cand.candidate_id, interviewId])
    // );
    navigator.clipboard.writeText(url).then(() => {
      this.toastService.show('Interview link copied to clipboard', {
        classname: 'bg-success text-light',
        delay: 1300,
      });
    });
  }
  isIntToday(cand: any) {
    cand.formated_date
    var inputDate = new Date(cand.formated_date);
    var todaysDate = new Date();
    if (inputDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
      return true;
    }
    else
      return false
  }
  selectHTMForInterview(htm: any) {
    if (this.option2) {
      if (!htm.has_cronofy_calendar) {
        this.showHtmCalWarning = true;
        return;
      }
    }
    this.showHtmCalWarning = false
    if (this.selectedHtmArr.indexOf(htm.email) > -1) {
      const index = this.selectedHtmArr.indexOf(htm.email);
      this.selectedHtmArr.splice(index, 1); // 2nd parameter means remove one item only
      this.toHTMIds.splice(index, 1);
      this.htmNames.splice(index, 1)
    }
    else {
      this.selectedHtmArr.push(htm.email)
      this.htmMembers.forEach((htms: any, index: any) => {
        if (htm.email == htms.email) {
          this.toHTMIds.push(htms.id);
          this.htmNames.push(htms.name)
        }
      });
    }
    this.emailForm.controls.toEmails.patchValue(this.selectedHtmArr.toString());
    this.emailForm.controls.htms.patchValue(this.toHTMIds);
  }

  optionChanges(op: any) {
    if (op == 1) {
      this.option1 = true;
      this.option2 = false;
      this.showHtmCalWarning = false
      this.emailForm.controls['specific'].setValue(true)
    }
    if (op == 2) {
      this.option1 = false;
      this.option2 = true;
      this.emailForm.controls['specific'].setValue(false)
      this.emailForm.controls.date.clearValidators();
      this.emailForm.controls.date.updateValueAndValidity();
      this.emailForm.controls.endTime.clearValidators();
      this.emailForm.controls.endTime.updateValueAndValidity();
      this.emailForm.controls.startTime.clearValidators();
      this.emailForm.controls.startTime.updateValueAndValidity();
      this.emailForm.controls.meetingKey.clearValidators();
      this.emailForm.controls.mailBody.setValue('')
    }
    this.selectedHtmArr = []
    this.toHTMIds = []
    this.htmNames = []
    this.emailForm.controls.toEmails.patchValue(this.selectedHtmArr.toString());
    this.emailForm.controls.htms.patchValue(this.toHTMIds);
  }

  getPositionsCandidates(id: any) {
    this.candidateService.getPositionsCandidates(id).subscribe((val: any) => {
      // console.log(val)
      if (val) {
        this.candidateList = val;
      }
    });
  }
  openInterviewModal(interviewPage: any) {
    this.createEmailForm()
    this.selectedHtmArr = [];
    this.toHTMIds = [];
    this.htmNames = [];
    this.option1 = true;
    this.option2 = false;
    this.showHtmCalWarning = false
    this.modalService.open(interviewPage, { size: 'lg' });
  }
  giveMeRole(roles: any): any {
    if (roles[0] == "is_htm") {
      return 'HM'
    }
    if (roles[0] == "is_sm") {
      return 'SM'
    }
    if (roles[0] == "is_ca") {
      return 'CA'
    }
    else {
      return 'NA'
    }
  }
  createEmailForm() {
    this.emailForm = this.fb.group({
      toEmails: ['', Validators.required],
      ccEmails: [''],
      subject: ['Your interview with ' + this.activeClientName, Validators.required],
      mailBody: [this.mailBody, Validators.required],
      date: ['', Validators.required],
      timezone: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      interviewType: [''],
      candidate: [this.candidateData.candidate_id],
      htms: [''],
      zoom_link: [''],
      specific: [true],
      meetingKey: ['', Validators.required],
      conference_id: [''],
      op_id: [this.activatedRoute.snapshot.params['positionId']],
      client_id: [this.clientId],
      next_days: [''],
      duration: [''],
      long_timezone: ['']
    });
  }

  toggleZohoInterview(type?: any) {
    if (type) {
      if (type === 'zoho') {
        this.emailForm.controls.meetingKey.addValidators(Validators.required);
        this.emailForm.controls.meetingKey.updateValueAndValidity();
        const payload = {
          presenter: JSON.parse(localStorage.getItem('currentUser') || '')?.email,
          start: this.emailForm.controls.date.value,
          subject: this.emailForm.value.subject,
          toEmails: this.emailForm.value.toEmails,
          timezone: this.emailForm.value.timezone,
          date: this.emailForm.value.date,
          startTime: this.emailForm.value.startTime,
          endTime: this.emailForm.value.endTime
        }
        this.htmService.generateMeetingUrl(payload).subscribe((res: any) => {
          this.zoomUrl = res?.meeting_link;
          this.emailForm.controls.conference_id.patchValue(res.conference_id);
          this.emailForm.controls.meetingKey.patchValue(res.meeting_link);
          this.emailForm.controls.mailBody.patchValue(this.generateMailBody());
        });
      } else {
        this.emailForm.controls.meetingKey.clearValidators();
        this.emailForm.controls.meetingKey.updateValueAndValidity();
        this.zoomUrl = null;
        this.emailForm.controls.mailBody.patchValue(this.generateMailBody());
      }
    }
  }
  formatDate(inputDate: any) {
    // Create a new Date object from the input string
    var date = new Date(inputDate);

    // Define months array to convert numeric month to its name
    var months = [
      "January", "February", "March", "April",
      "May", "June", "July", "August",
      "September", "October", "November", "December"
    ];

    // Extract day, month, and year from the Date object
    var day: any = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    var month = months[date.getMonth()];
    var year = date.getFullYear();

    // Create the formatted date string
    var formattedDate = month + ' ' + day + ', ' + year;

    return formattedDate;
  }


  isinterViewTypeEnabled() {
    return this.emailForm.controls.toEmails.valid
      && this.emailForm.controls.subject.valid
      && this.emailForm.controls.mailBody.valid
      && this.emailForm.controls.date.valid
      && this.emailForm.controls.startTime.valid
      && this.emailForm.controls.endTime.valid;
  }
  selectTimeZone(e: any) {
    if (e.target.value != "Open this select menu") {
      let obj = this.timeZones.find(o => o.display === e.target.value);
      this.emailForm.controls['timezone'].setValue(obj?.value)
      this.emailForm.controls['long_timezone'].setValue(e.target.value)
    }
    else {
      this.emailForm.controls['timezone'].setValue('')
      this.emailForm.controls['long_timezone'].setValue('')
    }
    // console.log(this.emailForm.controls['timezone'].value)
    // console.log(this.emailForm.controls['long_timezone'].value)
    console.log(this.getCurrentTime(this.emailForm.controls['long_timezone'].value))
  }
  getCurrentTime(timeZoneStr: string) {
    // Extract GMT offset part "(GMT-11:00)"
    const gmtOffsetMatch = timeZoneStr.match(/\(GMT([+-]\d{2}):(\d{2})\)/);

    if (!gmtOffsetMatch) {
      throw new Error("Invalid time zone format");
    }

    const sign = gmtOffsetMatch[1].startsWith('-') ? -1 : 1; // Determine if it's positive or negative
    const hoursOffset = parseInt(gmtOffsetMatch[1].slice(1), 10); // Extract hour offset
    const minutesOffset = parseInt(gmtOffsetMatch[2], 10); // Extract minute offset

    // Calculate total offset in minutes
    const totalOffsetMinutes = sign * (hoursOffset * 60 + minutesOffset);

    // Get current UTC time
    const now = new Date();
    const utcTime = now.getTime() + now.getTimezoneOffset() * 60000;

    // Adjust by the GMT offset
    const timeInZone = new Date(utcTime + totalOffsetMinutes * 60000);

    return timeInZone;
  }
  selectInterviewDuration(e: any) {
    if (e.target.value != "Open this select menu")
      this.emailForm.controls['duration'].setValue(e.target.value)
    else
      this.emailForm.controls['duration'].setValue('')
  }
  onAvailableTime(e: any) {
    if (e.target.value != "Open this select menu")
      this.emailForm.controls['next_days'].setValue(e.target.value)
    else
      this.emailForm.controls['next_days'].setValue('')
  }
  openFile(file: any) {
    window.open(file, '_blank')
  }
  generateMailBody() {

    if (this.isViewSchedule) {
      return;
    }
    let mailBody = ``;

    if (this.zoomUrl) {
      mailBody += `\nYour QVideo interview link is: ` + this.zoomUrl + `\n\n`;
    }
    let candidateContent = '';
    this.selectedCan = this.candidateData
    if (this.selectedCan) {
      candidateContent += this.selectedCan.name + ' ' + this.selectedCan.last_name;
      // if(htm.linkedin_id && htm.linkedin_id !== '') {
      candidateContent += `\nLinkedIn: ` + (this.selectedCan.linkedin || 'N/A');
      // }
      if (this.selectedCan.phone_number && this.selectedCan.phone_number !== '') {
        candidateContent += `\nPhone number: ` + this.selectedCan.phone_number + `\n\n`;
      }
    }
    mailBody += candidateContent;
    this.emailForm.controls.mailBody.patchValue(mailBody);
    return mailBody;
  }
  showSuccess(msg: any) {
    this.toastService.show(msg, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  showDanger(dangerTpl: string) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }


  checkTime() {
    let d1 = new Date(this.emailForm.value.date)
    d1.setHours(this.emailForm.value.startTime.split(":")[0])
    d1.setMinutes(this.emailForm.value.startTime.split(":")[1]);
    let d2 = new Date(this.emailForm.value.date)
    d2.setHours(this.emailForm.value.endTime.split(":")[0])
    d2.setMinutes(this.emailForm.value.endTime.split(":")[1]);
    let d3 = this.getCurrentTime(this.emailForm.controls['long_timezone'].value);
    if (this.emailForm.value.startTime == this.emailForm.value.endTime) {
      this.dateError = true
      return true
    }
    if (d1 < d3) {
      this.dateError = true
      return true
    }
    if (d2 < d1) {
      this.dateError = true
      return true
    }
    else {
      this.dateError = false
      return false
    }
  }

  sendEmail(successMsg: any) {
    if (this.option1 && this.checkTime()) {
      setTimeout(() => { this.dateError = false }, 3000)
      return
    }
    this.emailForm.controls.zoom_link.patchValue(this.zoomUrl);
    this.emailForm.controls.duration.patchValue(parseInt(this.emailForm.value.duration));
    this.emailForm.controls.next_days.patchValue(parseInt(this.emailForm.value.next_days));
    this.candidateService.sendInterviewMail(this.emailForm.value).subscribe((res: any) => {
      this.modalService.dismissAll();
      this.modalService.open(successMsg, { size: 'md' });
      this.candInfoAction.emit();
    })
  }

  updateEmail() {

  }
  withdrawCand() {
    let op_id = this.activatedRoute.snapshot.params['positionId'];
    this.candidateService.withdrawCandidate(op_id, this.candidateData.candidate_id, {}).subscribe((res: any) => {
      this.candInfoAction.emit();
      this.showSuccess("Candidate Withdrwn");
    })
  }
  restoreCand() {
    let op_id = this.activatedRoute.snapshot.params['positionId'];
    this.candidateService.unwithdrawCandidate(op_id, this.candidateData.candidate_id, {}).subscribe((res: any) => {
      this.candInfoAction.emit();
      this.showSuccess("Candidate Restored");

    })
  }
  hireCandidate() {
    let op_id = this.activatedRoute.snapshot.params['positionId'];
    this.candidateService.hireCandidate(op_id, this.candidateData.candidate_id).subscribe((res: any) => {
      this.candInfoAction.emit();
      this.showSuccess("Candidate Hired");
    })
  }
  show12Format(hr: any) {
    return hr % 12 || 12;
  }
  showTimeSuffix(hr: number) {
    return hr >= 12 ? "PM" : "AM";
  }
}
