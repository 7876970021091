<div class="legends mb-5" *ngIf="chartData.length > 0">
    <div class="d-inline mr-3"><span class="perfect-score"></span> <span>Your Perfect Score</span></div>
    <div class="d-inline"><span class="candidate-score"></span> <span>{{selectedCandidate}}'s Score</span></div>
</div>

<!-- <div id="pie-menu" class="pie-outer">
    <div class="pie" *ngFor="let item of [].constructor(10); let i = index"  [ngStyle]="{'width': 10*(10-i) + '%'}">
        <ul>
            <li *ngFor="let item of [].constructor(10); let j = index"><a href="#">{{10-i}}</a></li>
        </ul>
    </div>
</div> -->

<div id="pie-menu" class="pie-outer mt-5 pt-5" *ngIf="chartData.length > 0">
    <div class="pie mt-5 skills-{{chartData.length}}" *ngFor="let item of [].constructor(10); let i = index"  [ngStyle]="{'width': 10*(10-i) + '%'}">
        <ul>
            <ng-container *ngFor="let item of chartData; let j = index">
                <li [ngClass]="{'scored': chartData[j].candidate_score > 10-(i+1), 
                    'desired': chartData[j].skill_weightage > 10-(i+1), 
                    'over-score': (10-i) > chartData[j].skill_weightage && chartData[j].candidate_score > 10-(i+1), 'inner-circle': i===9}" 
                    [ngStyle]="{'transform': getTransform(j)}">
                    <a href="javascript: void(0);" class="pointer-{{10-i}}">{{10-i}}</a>
                    <span class="skill-name" *ngIf="i===0">{{item.skill}}</span>
                </li>
            </ng-container>
        </ul>
    </div>
</div>

<div *ngIf="!chartData || chartData?.length === 0"> Please select a candidate to see the Fit Analysis chart.</div>

<!-- <div id="pie-menu" class="pie-outer">
    <div class="pie teal">
        <ul>
            <li><a href="#">one</a></li>
            <li><a href="#">two</a></li>
            <li><a href="#">three</a></li>
            <li><a href="#">four</a></li>
            <li><a href="#">five</a></li>
            <li><a href="#">six</a></li>
            <li><a href="#">seven</a></li>
            <li><a href="#">eight</a></li>
            <li><a href="#">nine</a></li>
            <li><a href="#">ten</a></li>
        </ul>
    </div>
    <div class="pie blue">
        <ul>
            <li><a href="#">one</a></li>
            <li><a href="#">two</a></li>
            <li><a href="#">three</a></li>
            <li><a href="#">four</a></li>
            <li><a href="#">five</a></li>
            <li><a href="#">six</a></li>
            <li><a href="#">seven</a></li>
            <li><a href="#">eight</a></li>
            <li><a href="#">nine</a></li>
            <li><a href="#">ten</a></li>
        </ul>
    </div>
    <div class="pie gray">
        <ul>
            <li><a href="#">one</a></li>
            <li><a href="#">two</a></li>
            <li><a href="#">three</a></li>
            <li><a href="#">four</a></li>
            <li><a href="#">five</a></li>
            <li><a href="#">six</a></li>
            <li><a href="#">seven</a></li>
            <li><a href="#">eight</a></li>
            <li><a href="#">nine</a></li>
            <li><a href="#">ten</a></li>
        </ul>
    </div>
    <div class="pie orange">
        <ul>
            <li><a href="#">one</a></li>
            <li><a href="#">two</a></li>
            <li><a href="#">three</a></li>
            <li><a href="#">four</a></li>
            <li><a href="#">five</a></li>
            <li><a href="#">six</a></li>
            <li><a href="#">seven</a></li>
            <li><a href="#">eight</a></li>
            <li><a href="#">nine</a></li>
            <li><a href="#">ten</a></li>
        </ul>
    </div>
</div> -->