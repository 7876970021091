import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, limit = 25) {
    if (value.length < limit) {
      //limit = value.substr(0, limit).lastIndexOf(' ');
      return value;
    }
    return `${value.substring(0, limit)}...`;
    //    return `${value.substr(0, limit)}${ellipsis}`;
  }


}
